import React from "react";
import StylesConfig from "../../../config/styles-config";
import {
  type IImageButtonFabricProps,
  ImageButtonFabric,
} from "../../image-button/fabric/image-button-fabric";
import {
  type HipChallengeNativeMenuItem,
  type HipChallengeNativeProps,
} from "../hip-challenge-native-types";

export type HipChallengeNativeMenusProps = Pick<HipChallengeNativeProps, "menuItems">;

/**
 * Represents the native hip menus. Each menu is an image with a click handler
 * @param props the native hip menus params
 * @returns the native hip menus
 */
export const HipChallengeNativeMenusFabric: React.FC<HipChallengeNativeMenusProps> =
  function HipChallengeNativeMenusFabric(props) {
    const { menuItems } = props;
    const { useCaptchaViewStyles } = StylesConfig.instance;
    const nativeHipStyles = useCaptchaViewStyles();

    return (
      <div className={nativeHipStyles.menuItems}>
        {menuItems.map((menuItem: HipChallengeNativeMenuItem) => {
          const { image, key: dataTestId, text } = menuItem;
          const menuItemProps: IImageButtonFabricProps = {
            image: { dataTestId, urls: image },
            className: nativeHipStyles.imageButton,
            onClick: menuItem.onClick,
          };

          return (
            <div key={dataTestId}>
              <ImageButtonFabric {...menuItemProps}>
                <div className={nativeHipStyles.imageButtonText}>{text}</div>
              </ImageButtonFabric>
            </div>
          );
        })}
      </div>
    );
  };
