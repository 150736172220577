import { ViewId } from "../../../../constants";
import GlobalConfig from "../../../../global-config";
import { useGlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { useNavigateDirection } from "../../../../hooks";
import { cleanseUsername } from "../../../../model/user";
import { useTelemetry } from "../../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../../telemetry-helpers/user-action-name";

/**
 * Hook for the sign up click handler for the "For my child" selection
 * @returns Click handler for the "for my child" link
 */
export const useSignupForMyChildHandler = () => {
  const { activeFlavor, telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow },
  } = useGlobalContext();
  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();
  const navigate = useNavigateDirection();

  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  return (unsafeUsername: string) => {
    logUserAction({ actionName: UserActionName.SignupForMyChildClicked });

    const cleansedUnsafeUsername = cleanseUsername(
      unsafeUsername,
      true /* preserve leading plus sign */,
    );
    dispatchGlobal({
      type: GlobalActionType.SetUser,
      payload: { username: cleansedUnsafeUsername },
    });

    dispatchGlobal({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.Username,
      destination: ViewId.Benefits,
      displayOptions: { navigationDirection: "forward" },
    });

    navigate(ViewId.Username, ViewId.Benefits);
  };
};
