import { type UserCredential, CredentialType } from "../../../model/credential";
import { type ICredentialSwitchLinkProperties } from "./credential-switch-links-types";

/**
 * @param availableCredentials credentials available to the user
 * @param currentCredential credential the user is currently using
 * @param supportedCredentialMap map for all the supported credentials
 * @returns a list of alternative credentials (available credentials - current credential) can be used by the user
 */
export const getAlternativeCredentials = (
  availableCredentials: UserCredential[],
  currentCredential: UserCredential,
  supportedCredentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties },
) => {
  const alternativeCredentials: UserCredential[] = [];

  // go through the list of credentials available to the user
  // and remove the credential user is currently using
  availableCredentials.forEach((credential) => {
    if (supportedCredentialMap[credential.credentialType]) {
      const credentialMatched = credential.credentialType === currentCredential?.credentialType;
      const isCredTypeOtc = credential.credentialType === CredentialType.OneTimeCode;
      const proofDataMatch =
        credentialMatched &&
        isCredTypeOtc &&
        credential.proof!.data === currentCredential.proof!.data;
      const proofTypesMatch =
        credentialMatched &&
        isCredTypeOtc &&
        credential.proof!.type === currentCredential.proof!.type;

      // there could be multiple one time code credentials available, need to check
      // proof data to verify that it's not the one user is currently using
      if (
        !credentialMatched ||
        (isCredTypeOtc && !proofDataMatch) ||
        (isCredTypeOtc && !proofTypesMatch)
      ) {
        alternativeCredentials.push(credential);
      }
    }

    if (credential.credentialType === CredentialType.OfflineAccount) {
      alternativeCredentials.push({
        credentialType: CredentialType.OfflineAccount,
        shownOnlyOnPicker: true,
      });
    }
  });

  return alternativeCredentials;
};
