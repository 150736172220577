import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { type FlowId, ViewId } from "../../constants";
import { useActivateView, useNavigateDirection } from "../../hooks";
import { type AgreementType } from "./agreement-view-interface";
import { isExternalAgreementLink } from "./agreement-view-util";
import { useGetAgreementHtml } from "./use-get-agreement-html";

export type UseAgreementViewParams = {
  footerA11yConformeUrl: string;
  footerImpressumUrl: string;
  generalVisitLink: string;
  hostingFlow: FlowId;
  privacyUrl: string;
  termsOfUseUrl: string;
  isWideAgreementView: (agreementType: AgreementType) => boolean;
  showIdentityBanner?: boolean;
  showCloseButtonOnActiveView?: boolean;
};

export type UseAgreementViewResult = {
  agreementType: AgreementType;
  downloadError: boolean;
  agreementHtml: string;
  onBack: () => void;
};

export const useAgreementView = (params: UseAgreementViewParams): UseAgreementViewResult => {
  const {
    footerA11yConformeUrl,
    footerImpressumUrl,
    generalVisitLink,
    hostingFlow,
    privacyUrl,
    termsOfUseUrl,
    isWideAgreementView,
    showCloseButtonOnActiveView = true,
  } = params;

  const navigate = useNavigateDirection();

  const onBack = () => {
    navigate(ViewId.ViewAgreement, -1, true);
  };

  const [downloadError, setDownloadError] = useState(false);
  const [agreementHtml, setAgreementHtml] = useState("");

  const agreementType = useParams()["*"] as AgreementType;

  const isExternalLink = isExternalAgreementLink(agreementType);

  const getAgreementHtml = useGetAgreementHtml({
    agreementType,
    footerA11yConformeUrl,
    footerImpressumUrl,
    generalVisitLink,
    privacyUrl,
    termsOfUseUrl,
    isExternalLink,
    setDownloadError,
    setAgreementHtml,
  });

  useActivateView(
    ViewId.ViewAgreement,
    hostingFlow,
    {
      closeButtonHandler: onBack,
      disableHeightAnimation: true,
      isWideView: isWideAgreementView(agreementType),
      loadingData: true,
      showCloseButtonOnActiveView,
      showIdentityBanner: false,
      showPageTitle: false,
    },
    { restoreShowPageTitle: true },
  );

  useEffect(() => {
    // Reset the view component to its default values. This is necessary because the agreements share the same route via the react-router link component.
    // If a user navigates between agreements or within same agreement, react-router does not unmount the component and mount a new instance.
    setDownloadError(false);
    setAgreementHtml("");
    getAgreementHtml();
  }, [getAgreementHtml]);

  return {
    agreementType,
    downloadError,
    agreementHtml,
    onBack,
  };
};
