import { useContext } from "react";
import LoginConfig from "../../flows/login/login-config";
import { GlobalContext } from "../../global-context";
import { GlobalActionType } from "../../global-reducer";
import { handleOnSkip } from "../../utilities/cxh-handlers/cxh-handler";

/**
 * @param showProgressIndicator Whether to show the progress indicator while skipping
 * @returns A click handler for a skip link or button
 */
export const useHandleOnSkip = (showProgressIndicator: boolean) => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);
  const { isOobe, noLossAversion, preferAssociate } = LoginConfig.instance;

  return () => {
    if (showProgressIndicator) {
      dispatchGlobalStateChange({
        type: GlobalActionType.SetShowProgressIndicator,
        payload: true,
      });
    }

    handleOnSkip(isOobe, noLossAversion, preferAssociate);
  };
};
