import React from "react";
import { mergeClasses } from "@griffel/react";
import { SecondaryContentContainerFabric } from "../../../../../components";
import { DescriptionFabric } from "../../../../../components/description/fabric/description-fabric";
import { InputFabric as Input } from "../../../../../components/inputs/input/fabric/input-fabric";
import { InputContainer } from "../../../../../components/inputs/input-container";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../../components/title/fabric/title-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants/routing-constants";
import { useActivateView } from "../../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../../hooks/use-document-title";
import { CredentialType } from "../../../../../model/credential";
import { isProofEncrypted } from "../../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import { CredentialSwitchContentFabric } from "../../../components/fabric/credential-switch-content-fabric";
import {
  commonLoginStringsFabric,
  loginInputValidationErrorStringsFabric,
} from "../../../fabric/common-login-strings-fabric";
import { useSelectAccount, useShowCredentialSwitchContent } from "../../../hooks/login-hooks";
import { useInputWithServerDataErrorsFabric } from "../../../hooks/use-input-with-server-data-errors-fabric";
import { LoginFlowPostHiddenInputs } from "../../../login-flow-post-hidden-inputs";
import {
  useGetLoginPostProps,
  useOneTimeCodeViewProperties,
  useOtcSubmitHandler,
  usePhoneDisambiguationLinkProps,
} from "../hooks/one-time-code-view-hooks";
import { otcInputValidation } from "../one-time-code-view-util";
import { oneTimeCodeViewStringsFabric } from "./one-time-code-view-strings-fabric";

/**
 * OneTimeCode view component
 * @returns A rendered instance of this component
 */
export const OneTimeCodeViewFabric: React.FC = function OneTimeCodeViewFabric() {
  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  const {
    brandingDescriptionProperties: {
      brandingDescription,
      brandingDescriptionId,
      renderBrandingDescription,
    },
    canGoBack,
    credentialSwitchLinksProps,
    documentTitle,
    enterCodeAriaDescribedBy,
    enterCodeAriaLabel,
    otcCredential,
    otcInputName,
    otcMaxLength,
    placeholder,
    primaryButtonLabel,
    proofConfirmation,
    proofData,
    postUrl,
    showChangeUserLink,
    title,
    unsafePageDescription,
  } = useOneTimeCodeViewProperties({
    commonLoginStrings: commonLoginStringsFabric,
    oneTimeCodeViewStrings: oneTimeCodeViewStringsFabric,
  });

  useActivateView(ViewId.OneTimeCode, FlowId.Login, { showBackButtonOnActiveView: canGoBack });
  useDocumentTitle(documentTitle);

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  const loginPostProps = useGetLoginPostProps(otcCredential);

  const { showSwitchToEvictedCredPicker, showCredentialSwitchContent } =
    useShowCredentialSwitchContent();

  const { switchToPhoneDisambiguationOnClick, showPhoneDisambiguationLink } =
    usePhoneDisambiguationLinkProps();

  const otcInputValidationFunc = otcInputValidation(
    otcCredential.credentialType === CredentialType.OneTimeCode,
    oneTimeCodeViewStringsFabric,
  );
  const inputState = useInputWithServerDataErrorsFabric({
    validationMethod: otcInputValidationFunc,
    hasInitialFocus: true,
    loginInputValidationErrorStrings: loginInputValidationErrorStringsFabric,
  });
  const {
    hasFocus,
    value,
    onChange,
    onBlur,
    onFocus,
    error: { showErrorMessage, errorMessage },
  } = inputState;

  const onSubmit = useOtcSubmitHandler(postUrl, inputState);

  const selectAccount = useSelectAccount(ViewId.OneTimeCode);

  return (
    <form
      name="oneTimeCodeForm"
      data-testid="oneTimeCodeForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      onSubmit={onSubmit}
      action={postUrl}
    >
      <TitleFabric titleId="oneTimeCodeTitle" title={title} />

      {renderBrandingDescription && (
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
          <div id={brandingDescriptionId} className={commonStyles.wrapContent}>
            {brandingDescription}
          </div>
        </div>
      )}
      <DescriptionFabric descriptionId="oneTimeCodeDescription">
        {unsafePageDescription}
      </DescriptionFabric>

      {/* AAD-TODO: add PublicIdentifierAuth hidden input */}

      <input
        type="hidden"
        name={isProofEncrypted(otcCredential) ? "SentProofIDE" : "SentProofID"}
        value={proofData}
        data-testid="sentProofInput"
      />
      {proofConfirmation && (
        <input
          type="hidden"
          name="ProofConfirmation"
          value={proofConfirmation}
          data-testid="proofConfirmationInput"
        />
      )}
      {otcCredential?.proof && otcCredential.proof.type && (
        <input
          type="hidden"
          name="ProofType"
          value={otcCredential.proof.type}
          data-testid="proofTypeInput"
        />
      )}

      <LoginFlowPostHiddenInputs {...loginPostProps} />

      <Input
        id="idTxtBx_OTC_Password"
        name={otcInputName}
        type="tel"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        showErrorMessage={showErrorMessage}
        errorMessage={errorMessage}
        aria-label={enterCodeAriaLabel}
        aria-describedby={enterCodeAriaDescribedBy}
        hasFocus={hasFocus}
        hasInitialFocus
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={otcMaxLength}
      />

      {/* AAD-TODO: add resend code button */}

      <SecondaryContentContainerFabric>
        {showCredentialSwitchContent && (
          <CredentialSwitchContentFabric
            credentialSwitchProps={credentialSwitchLinksProps}
            showSwitchToEvictedCredPicker={showSwitchToEvictedCredPicker}
          />
        )}

        {showPhoneDisambiguationLink && (
          <div className={commonStyles.row}>
            <div className={commonStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.phoneDisambiguationLinkText}
                onClick={switchToPhoneDisambiguationOnClick}
              />
            </div>
          </div>
        )}

        {showChangeUserLink && (
          <div className={commonStyles.row}>
            <div className={commonStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.signInDifferentAccount}
                linkId="signInDifferentAccount"
                onClick={selectAccount}
              />
            </div>
          </div>
        )}
      </SecondaryContentContainerFabric>

      <InputContainer>
        <div className={commonStyles.winButtonPinBottom}>
          <div className={commonStyles.row}>
            <TextButtonContainer>
              <TextButtonFabric
                buttonId="primaryButton"
                label={primaryButtonLabel}
                isPrimary
                type="submit"
                ariaLabel={primaryButtonLabel}
              />
            </TextButtonContainer>
          </div>
        </div>
      </InputContainer>
    </form>
  );
};
