import { FlowId } from "../constants";

export enum ExperimentFeature {
  AAExperiment = "activeactiveexperiment",
  LoginAAExperiment = "login_activeactiveexperiment",
  EnableJsPublicKeyDeprecationExperiment = "enablejspublickeydeprecationexperiment",
  EnableIdentityBannerResponsiveExperiment = "enableidentitybannerresponsiveexperiment",
  AddPrivateBrowsingTextToFabricFooter = "addprivatebrowsingtexttofabricfooter",

  // Used for unit-tests
  Parallax1 = "Parallax1",
  Parallax2 = "Parallax2",
  Parallax3 = "Parallax3",
}

export enum ControlFlight {
  AAExperimentControl = "activeactiveexperiment_control",
  LoginAAExperimentControl = "login_activeactiveexperiment_control",
  EnableJsPublicKeyDeprecationExperimentControl = "enablejspublickeydeprecationexperiment_control",
  EnableIdentityBannerResponsiveExperimentControl = "enableidentitybannerresponsiveexperiment_control",
  AddPrivateBrowsingTextToFabricFooterControl = "addprivatebrowsingtexttofabricfooter_control",

  // Used for unit-tests
  Control1 = "Control1",
  Control2 = "Control2",
  Control3 = "Control3",
}

export enum TreatmentFlight {
  AAExperimentTreatment = "activeactiveexperiment_treatment",
  LoginAAExperimentTreatment = "login_activeactiveexperiment_treatment",
  EnableJsPublicKeyDeprecationExperimentTreatment = "enablejspublickeydeprecationexperiment_treatment",
  EnableIdentityBannerResponsiveExperimentTreatment = "enableidentitybannerresponsiveexperiment_treatment",
  AddPrivateBrowsingTextToFabricFooterTreatment = "addprivatebrowsingtexttofabricfooter_treatment",

  // Used for unit-tests
  Treatment1 = "Treatment1",
  Treatment2 = "Treatment2",
  Treatment1a = "Treatment1a",
  Treatment3b = "Treatment3b",
  Treatment1b = "Treatment1b",
  Treatment2a = "Treatment2a",
  Treatment2b = "Treatment2b",
  Treatment3a = "Treatment3a",
}

const clientExperiments: IFlowDefinition = {
  [FlowId.OwnershipChallenge]: [
    {
      parallaxFeature: ExperimentFeature.AAExperiment,
      controlFlight: ControlFlight.AAExperimentControl,
      treatmentFlights: [TreatmentFlight.AAExperimentTreatment],
    },
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
    // Add more experiment definitions here
  ],
  [FlowId.Kmsi]: [
    {
      parallaxFeature: ExperimentFeature.LoginAAExperiment,
      controlFlight: ControlFlight.LoginAAExperimentControl,
      treatmentFlights: [TreatmentFlight.LoginAAExperimentTreatment],
    },
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
    // Add more experiment definitions here
  ],
  [FlowId.Signup]: [
    {
      parallaxFeature: ExperimentFeature.EnableJsPublicKeyDeprecationExperiment,
      controlFlight: ControlFlight.EnableJsPublicKeyDeprecationExperimentControl,
      treatmentFlights: [TreatmentFlight.EnableJsPublicKeyDeprecationExperimentTreatment],
    },
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.ResetPassword]: [
    {
      parallaxFeature: ExperimentFeature.EnableJsPublicKeyDeprecationExperiment,
      controlFlight: ControlFlight.EnableJsPublicKeyDeprecationExperimentControl,
      treatmentFlights: [TreatmentFlight.EnableJsPublicKeyDeprecationExperimentTreatment],
    },
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.AliasAccrual]: [
    {
      parallaxFeature: ExperimentFeature.EnableJsPublicKeyDeprecationExperiment,
      controlFlight: ControlFlight.EnableJsPublicKeyDeprecationExperimentControl,
      treatmentFlights: [TreatmentFlight.EnableJsPublicKeyDeprecationExperimentTreatment],
    },
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.Login]: [
    {
      parallaxFeature: ExperimentFeature.EnableIdentityBannerResponsiveExperiment,
      controlFlight: ControlFlight.EnableIdentityBannerResponsiveExperimentControl,
      treatmentFlights: [TreatmentFlight.EnableIdentityBannerResponsiveExperimentTreatment],
    },
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.AccountTransfer]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.Acw]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.AppConsent]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.AppStoreRedirect]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.AuthMethodCollection]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.CredentialCollection]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.ParentalConsent]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.ResetPasswordSignInName]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.ServiceAbuse]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.SsoConsent]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.Tfa]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.TOUAccrual]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.TransferLogin]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.VerifyEmail]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.UsernameRecovery]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.PasskeyInterrupt]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
  [FlowId.UnifiedRecovery]: [
    {
      parallaxFeature: ExperimentFeature.AddPrivateBrowsingTextToFabricFooter,
      controlFlight: ControlFlight.AddPrivateBrowsingTextToFabricFooterControl,
      treatmentFlights: [TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment],
    },
  ],
};

export default clientExperiments;
export interface IExperimentDefinition {
  parallaxFeature: ExperimentFeature;
  controlFlight: ControlFlight;
  treatmentFlights: Array<TreatmentFlight>;
}

export type IFlowDefinition = {
  [key in FlowId]?: Array<IExperimentDefinition>;
};
