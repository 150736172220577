import { NonNumericRegex, PhoneNumberRegex } from "../../components/inputs/input-regex-constants";
import { type PhoneNumberValidationProps } from "../../components/inputs/phone-number/hooks/use-phone-number";
import { type ICountryInfo } from "../../utilities/country-helper";
import { isEmailAddress, isPhoneNumber } from "./alias";

/**
 * @param nationalNumber National number
 * @param country Country data
 * @returns A string representation of the supplied phone number
 */
export const getFullyQualifiedPhoneNumber = (
  nationalNumber: string,
  country?: ICountryInfo | null,
): string => {
  const trimmedNumber = nationalNumber.trim();

  let countryPrefix = "";
  if (trimmedNumber.charAt(0) === "+") {
    countryPrefix = "+";
  } else if (country) {
    countryPrefix = `+${country.code}`;
  }

  return `${countryPrefix}${trimmedNumber.replace(/\D+/g, "")}`;
};

/**
 * @param phoneNumber The phone number entered by the user (excluding the country code)
 * @param countryCode The country code for the country selected by the user from the country dropdown
 * @returns String representation of the entered phone number with no validation or normalization performed
 */
export const buildRawPhoneNumberFromPhoneInput = (phoneNumber: string, countryCode: string) =>
  `+${countryCode}${phoneNumber.trim()}`;

/**
 * Provides a function for inline validation of a phone number.
 * @param error The error string to display
 * @returns Method for validating phone number input
 */
export const getPhoneInputValidation =
  (error: string) =>
  (params: PhoneNumberValidationProps): string => {
    const { phoneNumber } = params;
    if (
      !phoneNumber ||
      isEmailAddress(phoneNumber) ||
      !isPhoneNumber(phoneNumber) ||
      !phoneNumber.match(PhoneNumberRegex)
    ) {
      return error;
    }

    return "";
  };

/**
 * Provides a function for inline validation of a phone number with custom regex and error messages
 * @param regex Regex to match the phone number against
 * @param validationError Error string if phone number does not match the regex
 * @param emptyInputError Error string if phone number is empty
 * @returns Inline validation method for a phone number with custom regex and error messages
 */
export const getCustomPhoneInputValidation =
  (regex: string, validationError: string, emptyInputError: string) =>
  (params: PhoneNumberValidationProps): string => {
    const { dropdownValue: countryInfo, inputValue } = params;

    if (!inputValue || inputValue.trim() === "") {
      return emptyInputError;
    }

    const rawPhoneNumber = buildRawPhoneNumberFromPhoneInput(inputValue, countryInfo.code);
    const isValid = rawPhoneNumber.match(regex);
    const errorMessage = !isValid ? validationError : "";
    return errorMessage;
  };

/**
 * Creating a phone ID with 0 leads to SAPI partition issues, this code removes extra zero from the left.
 * If the number starts with 000, that means it is a test account and we should not remove the leading 0.
 * @param phoneNumber Original phone number typed by the user
 * @returns Phone number without left zero
 */
export const stripLeadingZero = (phoneNumber: string): string => {
  if (phoneNumber && !phoneNumber.startsWith("000")) {
    return phoneNumber.replace(/^0/, "");
  }

  return phoneNumber;
};

/**
 * Normalizes the phone number and make it ready to be sent to our backend
 * @param phoneNumber Original phone number typed by the user
 * @returns Phone number ready to be sent to backend
 */
export const normalizePhoneNumber = (phoneNumber: string): string => stripLeadingZero(phoneNumber);

/**
 * Extracts the last n digits of a phone number
 * @param phoneNumber the phone number to extract digits from
 * @param n number of digits to extract
 * @returns the last n digits of the phone number
 */
export const extractNLastPhoneDigits = (phoneNumber: string, n: number) =>
  phoneNumber.substring(phoneNumber.length - n, phoneNumber.length);

/**
 * Removes phone formatting characters for a given phone number (ex: -, +).
 * @param phoneNumber - A valid phone number.
 * @returns - An unformatted phone number.
 */
export const getUnformattedPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.replace(NonNumericRegex, "");

/**
 * Removes the country code and formatting characters for a given international phone number.
 * @param phoneNumber - A valid phone number.
 * @param countryCode - A valid unformatted country code
 * @returns - An unformatted national phone number
 */
export const getNationalPhoneNumber = (phoneNumber: string, countryCode: string) => {
  const phone = getUnformattedPhoneNumber(phoneNumber);

  if (phone.indexOf(countryCode) === 0) {
    const formattingRegex = new RegExp(`^${countryCode}`);
    return phone.replace(formattingRegex, "");
  }

  return phone;
};
