import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { useBackButtonHandlerWithCxh } from "../../../hooks/win11-oobe-fabric/use-back-button-handler";
import { arrowLeft, arrowRight } from "../../../utilities/image-helpers/accessible-images";
import { type AccessibleImageProps } from "../../accessible-image";
import { ImageButtonFabric } from "../../image-button/fabric/image-button-fabric";

export type IBackArrowButtonPropsFabric = {
  /** The unique button id */
  buttonId?: string;
  /** The ids of the labels or elements that are lengthier describes the button */
  ariaDescribedBy?: string;
  /** If the button has initial focus or not (false by default) */
  hasFocus?: boolean;
  /** If the market is right-to-left */
  isRtlMarket?: boolean;
  /** The button click event handler */
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
};

/**
 * Back Arrow Button component
 * @param backArrowButtonProps IBackArrowButtonProps
 * @returns an instance of the shared back-arrow button component
 */
export const BackArrowButtonFabric = React.forwardRef<
  HTMLButtonElement,
  IBackArrowButtonPropsFabric
>((props, forwardRef) => {
  const { useBackArrowButtonStyles } = StylesConfig.instance;
  const { buttonId = "idBtn_Back", ariaDescribedBy, hasFocus, isRtlMarket, onClick } = props;
  const backArrowButtonStyles = useBackArrowButtonStyles();
  const mergedClasses = mergeClasses(
    backArrowButtonStyles.default,
    backArrowButtonStyles.highContrast,
  );
  const image: AccessibleImageProps = {
    urls: isRtlMarket ? arrowRight : arrowLeft,
    role: "presentation",
  };

  useBackButtonHandlerWithCxh(onClick);

  return (
    <ImageButtonFabric
      ref={forwardRef}
      image={image}
      buttonId={buttonId}
      ariaLabel={getLocalString("General_Back")}
      ariaDescribedBy={ariaDescribedBy}
      className={mergedClasses}
      hasFocus={hasFocus}
      onClick={onClick}
    />
  );
});
