import { type ForwardedRef, useEffect, useRef } from "react";
import { FOCUS_TIMEOUT } from "../../../styles/fabric/layout-animate-fabric.styles";

/**
 * React hook to delay focus on an HTML button element before returning a ref to said element.
 *  Since React components render asynchronously, interacting with the DOM immediately after rendering can cause timing issues.
 *  By using a timeout, we allow the DOM to catch up, ensuring consistent onClick behavior.
 *
 * - For Fabric we delay focus until animation is nearly completed. This prevents the browser from immediately switching to the focused element and pulling
 *    it into view, which causes the identity banner and logo to pull right and interrupts the animation.
 * - For Xbox we delay focus to ensure proper button onClick behavior during page load.
 *
 *  Note: Providing a `forwardRef` and `hasFocus` would mean `hasFocus` isn't honored.
 *   Future changes to this file should address this potential for misuse.
 * @param forwardRef an object that forwards a reference to an HTML button.
 * @param hasFocus boolean indicating if the HTML button should have focus.
 * @returns elementReference, a ref to an HTML button element that allows direct interaction with the DOM node.
 */

export const useSetFocusWithDelay = (
  forwardRef: ForwardedRef<HTMLButtonElement> | null,
  hasFocus: boolean,
) => {
  const elementReference = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!forwardRef && hasFocus) {
      setTimeout(() => {
        elementReference.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  }, [hasFocus, forwardRef]);

  return elementReference;
};
