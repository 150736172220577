export enum UserActionName {
  None = "", // Keep None at the top, alphabetize below

  AccountSecurityInfoLearnMore = "AccountSecurityInfo_LearnMore",
  AccountTransferErrorOk = "AccountTransferError_Ok",
  AccountTransferOtcCancelled = "AccountTransfer_OtcCancelled",
  AccountTransferQrCancelled = "AccountTransfer_QrCancelled",
  AccountTransferQrCodeScanned = "AccountTransfer_QrCodeScanned",
  AccountTransferQrSkipped = "AccountTransfer_QrSkipped",
  AcwAddEmailBackClicked = "Acw_AddEmail_BackClicked",
  AcwAddEmailCancelClicked = "Acw_AddEmail_CancelClicked",
  AcwAddEmailPageLoad = "Acw_AddEmail_PageLoad",
  AcwAddEmailPrivacyAndCookiesClicked = "Acw_AddEmail_PrivacyAndCookiesClicked",
  AcwAddEmailVerificationComplete = "Acw_AddEmail_VerificationComplete",
  AcwAddPhoneBackClicked = "Acw_AddPhone_BackClicked",
  AcwAddPhoneCancelClicked = "Acw_AddPhone_CancelClicked",
  AcwAddPhonePageLoad = "Acw_AddPhone_PageLoad",
  AcwAddPhonePrivacyAndCookiesClicked = "Acw_AddPhone_PrivacyAndCookiesClicked",
  AcwAddPhoneVerificationComplete = "Acw_AddPhone_VerificationComplete",
  AcwAddPhoneGoToEmailClicked = "Acw_AddPhone_GoToEmailClicked",
  AcwAddPhoneBlockedCountrySelected = "Acw_AddPhone_BlockedCountrySelected",
  AliasAccrualCancel = "AliasAccrual_Cancel",
  AliasAccrualSkip = "AliasAccrual_Skip",
  AliasAccrualSubmit = "AliasAccrual_Submit",
  AliasAccrualSuggestions = "AliasAccrual_Suggestions",
  AppConsentAccept = "AppConsent_Accept",
  AppConsentDeny = "AppConsent_Deny",
  AppStoreRedirectSubmit = "AppStoreRedirect_Submit",
  BeginNavigation = "BeginNavigation",
  BenefitsViewBackClicked = "BenefitsViewBackClicked",
  BenefitsViewNextClicked = "BenefitsViewNextClicked",
  ChallengeBack = "ChallengeBack",
  ChallengeDataMissing = "ChallengeDataMissing",
  ChallengeLoaded = "ChallengeLoaded",
  ChallengeSolved = "ChallengeSolved",
  ChallengeValidate = "ChallengeValidate",
  DomainSelectClicked = "DomainSelect_Clicked",
  KmsiAccepted = "Kmsi_Accepted",
  KmsiDeclined = "Kmsi_Declined",
  ParentalHandoverViewLearnMoreClicked = "ParentalHandover_LearnMore",
  ParentalHandoverViewSignInClicked = "ParentalHandover_ParentSignIn",
  PasskeyEnrollClicked = "PasskeyEnroll_Clicked",
  PasskeyEnrollSkip = "PasskeyEnroll_Skip",
  PasskeyEnrollSkipOnCancel = "PasskeyEnroll_SkipOnCancel",
  PasskeyEnrollTryAgain = "PasskeyEnroll_TryAgain",
  PasskeyEnrollSuccessRedirect = "PasskeyEnroll_SuccessRedirect",
  PinChangeSubmit = "PinChange_Submit",
  ResetPasswordSignInNameCancelClicked = "ResetPasswordSignInNameCancelClicked",
  ResetPasswordSignInNameSubmitClicked = "ResetPasswordSignInNameSubmitClicked",
  ResetPwdAbuseBlockCancel = "ResetPwd_AbuseBlock_Cancel",
  ResetPwdAddContactEmailCancel = "ResetPwd_AddContactEmail_Cancel",
  ResetPwdAddContactEmailSubmit = "ResetPwd_AddContactEmail_Submit",
  ResetPwdAddPwdCancel = "ResetPwd_AddPwd_Cancel",
  ResetPwdAddPwdSubmit = "ResetPwd_AddPwd_Submit",
  ResetPwdCaptchaCancel = "ResetPwd_Captcha_Cancel",
  ResetPwdCaptchaSubmit = "ResetPwd_Captcha_Submit",
  ResetPwdEnterRecoveryCodeCancel = "ResetPwd_EnterRecoveryCode_Cancel",
  ResetPwdEnterRecoveryCodeSubmit = "ResetPwd_EnterRecoveryCode_Submit",
  ResetPwdNewPwdBannedPassword = "ResetPwd_NewPwd_BannedPassword",
  ResetPwdNewPwdCancel = "ResetPwd_NewPwd_Cancel",
  ResetPwdNewPwdPasswordsDontMatch = "ResetPwd_NewPwd_PasswordsDontMatch",
  ResetPwdNewPwdSubmit = "ResetPwd_ResetYourPassword_Submit",
  ResetPwdProofPickerAlreadyHaveCode = "ResetPwd_ProofPicker_AlreadyHaveCode",
  ResetPwdProofPickerCancel = "ResetPwd_ProofPicker_Cancel",
  ResetPwdProofPickerLostProofs = "ResetPwd_ProofPicker_LostProofs",
  ResetPwdProofPickerShowMoreOptions = "ResetPwd_ProofPicker_ShowMoreOptions",
  ResetPwdProofPickerSubmit = "ResetPwd_ProofPicker_Submit",
  ResetPwdSetSinglePwdCancel = "ResetPwd_SetSinglePwd_Cancel",
  ResetPwdSetSinglePwdSubmit = "ResetPwd_SetSinglePwd_Submit",
  ResetPwdSetupAuthenticatorSkip = "ResetPwd_SetupAuthenticator_Skip",
  ResetPwdSetupAuthenticatorSubmit = "ResetPwd_SetupAuthenticator_Submit",
  ResetPwdSkypeGoodNewsCancel = "ResetPwd_SkypeGoodNews_Cancel",
  ResetPwdSkypeGoodNewsSubmit = "ResetPwd_SkypeGoodNews_Submit",
  ResetPwdSummarySignIn = "ResetPwd_Summary_SignIn",
  ResetPwdVerifyCodeAnotherOption = "ResetPwd_VerifyCode_AnotherOption",
  ResetPwdVerifyCodeCancel = "ResetPwd_VerifyCode_Cancel",
  ResetPwdVerifyCodeEnforceTfa = "ResetPwd_VerifyCode_EnforceTfa",
  ResetPwdVerifyCodeGoBack = "ResetPwd_VerifyCode_GoBack",
  ResetPwdVerifyCodeSubmit = "ResetPwd_VerifyCode_Submit",
  ResetPwdVerifyContactEmailCancel = "ResetPwd_VerifyContactEmail_Cancel",
  ResetPwdVerifyContactEmailSubmit = "ResetPwd_VerifyContactEmail_Submit",
  ResetPwdVerifyIdentitySubmit = "ResetPwd_VerifyIdentity_Submit",
  SigninOptionsClicked = "Signin_OptionsClicked",
  SignupForMyChildClicked = "Signup_ForMyChildClicked",
  SignupForMyselfClicked = "Signup_ForMyselfClicked",
  SignupLinkClicked = "Signup_LinkClicked",
  Signup_ChinaPiplConsent = "Signup_ChinaPIPLConsent",
  SsoConsentAccepted = "SsoConsent_Accepted",
  SsoConsentDeclined = "SsoConsent_Declined",
  SsoConsentLearnMore = "SsoConsent_LearnMore",
  TFASessionApprovalDenied = "TFASession_ApprovalFailure",
  TFASessionApprovalError = "TFASession_ApprovalError",
  TFASessionApprovalTimeout = "TFASession_ApprovalTimeout",
  TFASessionApprovalSuccess = "TFASession_ApprovalSuccess",
  TFASessionApprovalVisited = "TFASession_ApprovalVisited",
  TransferLoginDone = "TransferLogin_Done",
  UsernameCollectionSwitchInputTypeClicked = "UsernameCollection_SwitchInputType",
  UsernameCollectionInputTypeSubmit = "UsernameCollection_InputType_Submit",
}
