export const NoValidationRegEx = "/.*/";
export const AtLeastOneCharacterRegex = ".+";
export const PwdValidInput = "^[\\x20-\\x7e]{8,127}$";
export const AltEmailRegEx =
  "^[a-zA-Z0-9!#$%\\x26'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%\\x26'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$";
export const PhoneNumberFormat =
  "^[\\+]{0,1}([\\(\\)\\- \\*\\.#/\\[\\]]*\\d[\\(\\)\\- \\*\\.#/\\[\\]]*){4,20}$";
export const SkypeRegex = "^[a-zA-Z][a-zA-Z0-9.,\\-_:']{0,128}$";
export const PhoneNumberRegex = /^[0-9 ()[\].\-#*/+]+$/;
export const MembernameRegex =
  "^[a-zA-Z0-9_-]+(?:\\.[a-zA-Z0-9_-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$";
export const LiveMembernameRegex =
  "^(?:(?:[a-zA-Z])|(?:[\\w\\-](?:(?![\\.]{2,})[\\w\\.\\-]){0,62}[\\w\\-]))$";
export const StartsWithALetterRegex = "^[a-zA-Z]";
export const InvalidNameCharactersRegex =
  "[\x00-\x1f]|[\x21-\x26]|[\x28-\x2c]|\x2f|[\x3a-\x40]|[\x5b-\x5e]|\x60|[\x7b-\x7f]|\x81|\xa0|\xad";
export const IsNumericRegex = /^\d+$/;
// TODO: In a separate PR, we will rename `OttCodeRegex` to indicate it should not be used
// The "^0$" should be for INT only
export const OttCodeRegex = "^0$|^\\d{0,10}$";
export const OneTimeTokenRegex = "^\\d{0,10}$";
export const OttServiceAbuseRegex = "^(\\d{4}|\\d{6})$";
export const MagicOneTimeCodeRegex = "^0$";
export const NonNumericRegex = /[^0-9]/g;
export const TestPhoneNumberRegEx = /^000\d+/;
export const LeadingZerosRegex = /^0/;
