import React, { useContext, useEffect, useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { ExperimentFeature, TreatmentFlight } from "../../../config/experiments";
import StylesConfig from "../../../config/styles-config";
import { LayoutTemplateType } from "../../../constants";
import { useCustomizationContext } from "../../../context/customization-context";
import GlobalConfig from "../../../global-config";
import { type IDebugInfo, GlobalContext } from "../../../global-context";
import { useExperiment } from "../../../hooks";
import { getTextAreaAndCopyToClipboard } from "../../../utilities/clipboard-helper";
import { checkMarkIcon, closeIcon } from "../../../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "../../accessible-image";
import { ImageButtonFabric } from "../../image-button/fabric/image-button-fabric";
import { DebugTrace } from "./debug-trace";

type DebugDetailsBannerPropsFabric = {
  debugInfo: IDebugInfo;
  onDebugCloseClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  focusOnCopyLink?: boolean;
};

/**
 * DebugDetailsBanner component
 * @param props The properties for this component
 * @param props.debugInfo The debug info to render in the details pane
 * @param props.onDebugCloseClick The callback to execute when the debug details pane close button is clicked
 * @param props.focusOnCopyLink Whether focus is set on the copy details link. Default is false.
 * @returns an instance of this component
 */
const DebugDetailsBannerFabric: React.FC<DebugDetailsBannerPropsFabric> =
  function DebugDetailsBannerFabric({ debugInfo, onDebugCloseClick, focusOnCopyLink = false }) {
    const { sessionId, correlationId, showCopyDebugDetailsLink } = GlobalConfig.instance;
    const { useDebugDetailsBannerStyles } = StylesConfig.instance;

    const debugDetailsBannerStyles = useDebugDetailsBannerStyles();
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const { errorCode, errorMessage: exceptionMessage, timeStamp } = debugInfo;
    const {
      globalState: { isWideView },
    } = useContext(GlobalContext);

    const {
      customizationState: {
        styles: { layoutTemplate },
      },
    } = useCustomizationContext();

    /** @private  */
    function clickCopyTextToClipboard(e: React.SyntheticEvent<HTMLElement>) {
      e.preventDefault();
      getTextAreaAndCopyToClipboard("debugDetailsText");
      setShowCopyMessage(true);
    }

    const { treatment } = useExperiment(ExperimentFeature.AddPrivateBrowsingTextToFabricFooter);
    const usePrivateBrowsingTextFooter =
      treatment === TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment;

    const bannerStyles = mergeClasses(
      debugDetailsBannerStyles.banner,
      usePrivateBrowsingTextFooter ? debugDetailsBannerStyles.bannerExtendedFooter : "",
      layoutTemplate === LayoutTemplateType.VerticalSplit
        ? debugDetailsBannerStyles.bannerVerticalSplit
        : "",
      isWideView ? debugDetailsBannerStyles.wide : "",
    );

    // Default action is to set focus on the copy details link when pressing enter on the debug button (ellipsis).
    // If the copy link element is not available, check if close debug button is available and set focus on that instead.
    const copyLinkRef = useRef<HTMLAnchorElement>(null);
    const closeDebugRef = useRef<HTMLButtonElement>(null);
    const getFocusableItem = () => {
      const element = copyLinkRef?.current ?? closeDebugRef?.current;
      return element;
    };

    useEffect(() => {
      if (focusOnCopyLink) {
        const elementRef = getFocusableItem();
        elementRef?.focus();
      }
    }, [focusOnCopyLink]);

    return (
      <div data-testid="debugDetailsBanner" className={bannerStyles}>
        <div className={debugDetailsBannerStyles.tableRow}>
          <div className={debugDetailsBannerStyles.tableCell}>
            {showCopyDebugDetailsLink && (
              <div className={debugDetailsBannerStyles.header}>
                <div
                  id="debugDetailsHeader"
                  className={debugDetailsBannerStyles.headingText}
                  role="heading"
                  aria-level={2}
                >
                  {getLocalString("DebugDetails_Title")}
                </div>
                <div>{getLocalString("DebugDetails_Desc")}</div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={clickCopyTextToClipboard}
                  role="button"
                  className={debugDetailsBannerStyles.copyLink}
                  aria-describedby="debugDetailsHeader"
                  aria-label={getLocalString("DebugDetails_CopyLink")}
                  ref={copyLinkRef}
                >
                  {getLocalString("DebugDetails_CopyLink")}
                </a>
                {showCopyMessage && (
                  <span
                    id="debugDetailsCopyMessage"
                    className={debugDetailsBannerStyles.detailsNotification}
                    aria-live="assertive"
                  >
                    <AccessibleImage urls={checkMarkIcon} role="presentation" />
                    <span
                      role="alert"
                      className={debugDetailsBannerStyles.detailsNotification}
                      aria-label={getLocalString("DebugDetails_Notification_ScreenReader")}
                    >
                      {getLocalString("DebugDetails_Notification_CopySuccessful")}
                    </span>
                  </span>
                )}
              </div>
            )}
            <div id="debugDetailsText" className={debugDetailsBannerStyles.overrideLtr}>
              {errorCode && (
                <div>
                  <span className={debugDetailsBannerStyles.bold}>Error Code: </span>
                  <span>{errorCode}</span>
                </div>
              )}
              {sessionId && (
                <div>
                  <span className={debugDetailsBannerStyles.bold}>Request Id: </span>
                  <span>{sessionId}</span>
                </div>
              )}
              <div>
                <span className={debugDetailsBannerStyles.bold}>Correlation Id: </span>
                <span>{correlationId}</span>
              </div>
              <div>
                <span className={debugDetailsBannerStyles.bold}>Timestamp: </span>
                <span data-testid="timeStamp">{timeStamp}</span>
              </div>
              {exceptionMessage && (
                <div>
                  <span className={debugDetailsBannerStyles.bold}>Message: </span>
                  <span>{exceptionMessage}</span>
                </div>
              )}
            </div>
            <DebugTrace />
          </div>
          <div>
            <ImageButtonFabric
              image={{
                urls: closeIcon,
                alt: getLocalString("DebugDetails_Close_AlternateText"),
              }}
              buttonId="errorBannerClose"
              className={debugDetailsBannerStyles.closeButton}
              ariaLabel={getLocalString("DebugDetails_Close_AlternateText")}
              onClick={onDebugCloseClick}
              ref={closeDebugRef}
            />
          </div>
        </div>
      </div>
    );
  };

export default DebugDetailsBannerFabric;
