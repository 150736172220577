import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import ErrorContainerFabric from "../../../../../components/error-container/fabric/error-container-fabric";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import { TileListFabric } from "../../../../../components/tile-list/fabric/tile-list-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants/routing-constants";
import GlobalConfig from "../../../../../global-config";
import { useActivateView, useEffectOnce, useNavigateOrRedirectBack } from "../../../../../hooks";
import { LoginTitleDescriptionFabric } from "../../../components/fabric/login-title-description-fabric";
import { loginInputValidationErrorStringsFabric } from "../../../fabric/common-login-strings-fabric";
import { useSetServerDataErrorOnViewFabric } from "../../../hooks/use-server-data-error-fabric";
import { LoginContext } from "../../../login-context";
import {
  useAccountPickerBuilder,
  useOnSignOutOrForgetEventListener,
  useShowBackArrowButton,
} from "../hooks";
import { accountPickerStringsFabric } from "./account-picker-strings-fabric";

/**
 * AccountPickerViewFabric component
 * Note: This was formerly known as the "TilesView" in Knockout
 * @returns A rendered instance of this component
 */
export const AccountPickerViewFabric: React.FC = function AccountPickerViewFabric() {
  const { allowCancel } = GlobalConfig.instance;
  const { useCommonStyles, useStaticCommonStyles, useTileStyles } = StylesConfig.instance;

  const {
    viewState: { credentials },
  } = useContext(LoginContext);

  const { accountPickerTitle } = accountPickerStringsFabric;

  useActivateView(ViewId.AccountPicker, FlowId.Login, {
    showIdentityBanner: false,
  });

  useShowBackArrowButton();

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();
  const tileStyles = useTileStyles();

  const backButtonClickHandler = useNavigateOrRedirectBack();

  const signOutOrForgetEvent = useOnSignOutOrForgetEventListener();

  // listens for sign out/forget message event from iframe
  useEffectOnce(() => {
    window.addEventListener("message", signOutOrForgetEvent);

    return () => {
      window.removeEventListener("message", signOutOrForgetEvent);
    };
  });

  const { accountPickerList, error, setError, iFrameSource } = useAccountPickerBuilder(
    credentials.sessions,
    accountPickerStringsFabric,
  );

  // If there is an error from server in server data, show it on initialize
  useSetServerDataErrorOnViewFabric(
    {
      strings: loginInputValidationErrorStringsFabric,
    },
    setError,
  );

  return (
    <div>
      <LoginTitleDescriptionFabric titleId="accountPickerTitle" title={accountPickerTitle} />

      {error && (
        <ErrorContainerFabric
          id="accountPickerError"
          className={mergeClasses(tileStyles.tileError, commonStyles.alertError)}
        >
          {error}
        </ErrorContainerFabric>
      )}

      <div className={allowCancel ? commonStyles.positionButtonsWithBottomMargin : undefined}>
        <div className={commonStyles.row}>
          <div className={commonStyles.formGroup}>
            <TileListFabric
              ariaLabelledBy={accountPickerTitle}
              ariaDescribedBy={accountPickerTitle}
              tileList={accountPickerList}
            />
          </div>
        </div>
      </div>

      {allowCancel && (
        <div className={commonStyles.row}>
          <TextButtonContainer customCss={commonStyles.noMarginBottom}>
            <TextButtonFabric
              buttonId="backButton"
              label={getLocalString("General_Back")}
              isPrimary={false}
              onClick={backButtonClickHandler}
            />
          </TextButtonContainer>
        </div>
      )}

      {iFrameSource && ( // inject iframe for logout/forget
        <iframe
          style={{
            visibility: "hidden",
            width: "0px",
            height: "0px",
            display: "none",
          }}
          src={iFrameSource}
          title="accountPickerIframe"
          id="accountPickerIframeId"
          data-testid="accountPickerIframeTestId"
        />
      )}
    </div>
  );
};
