import React from "react";
import ReactDOM from "react-dom";
import { LoginFabric } from "@msidentity/SISU/apps/login/login-fabric";

/**
 * The app encapsulating the Login flow. This is the outermost component
 * and will be rendered onto the page using ReactDOM.render.
 * @returns An instance of the Login app.
 * @deprecated This component is deprecated and should not be used in new code. This will be eventually replaced by ./login-fabric.tsx
 */
ReactDOM.render(
  <React.StrictMode>
    <LoginFabric />
  </React.StrictMode>,
  document.getElementById("root"),
);
