import { useCallback } from "react";
import { isPhoneNumber } from "../../../../model/alias";
import { AltEmailRegEx, PhoneNumberRegex, SkypeRegex } from "../../input-regex-constants";
import { type InputValue } from "../input-types";

export type UsernameInputProps = {
  allowSkype: boolean;
  allowPhone: boolean;
  errorStringInvalidMemberName: string;
  errorStringInvalidPhoneNumber: string;
};

/**
 * @returns method for validating username input
 * @param props UseUsernameInputProps
 */
export const useUsernameInputValidation = (props: UsernameInputProps) => {
  const { allowSkype, allowPhone, errorStringInvalidMemberName, errorStringInvalidPhoneNumber } =
    props;

  return useCallback(
    (rawValue: InputValue): string => {
      const value = String(rawValue);

      if (!value || value.trim() === "") {
        return errorStringInvalidMemberName;
      }

      const skypeValueMatch = !!value.match(SkypeRegex);
      const altEmailMatch = !!value.match(AltEmailRegEx);
      const phoneNumberMatch = !!value.match(PhoneNumberRegex);
      const valueIsPhone = isPhoneNumber(value);

      if (
        altEmailMatch ||
        (allowSkype && skypeValueMatch) ||
        (allowPhone && valueIsPhone && phoneNumberMatch)
      ) {
        return "";
      }

      if (
        !altEmailMatch &&
        (!allowSkype || !skypeValueMatch) &&
        allowPhone &&
        valueIsPhone &&
        !phoneNumberMatch
      ) {
        return errorStringInvalidPhoneNumber;
      }

      return errorStringInvalidMemberName;
    },
    [allowSkype, allowPhone, errorStringInvalidPhoneNumber, errorStringInvalidMemberName],
  );
};
