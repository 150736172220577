import { createContext, useContext } from "react";
import { type HipType, type RemoteNgcType, BindProvider } from "../../constants";
import { ViewId } from "../../constants/routing-constants";
import { type ILoginCredentials, CredentialType } from "../../model/credential";
import { type ISession } from "../../model/user";
import {
  type FederationRedirectParams,
  type FidoParams,
  type RedirectPostParams,
  transformCredentialResponse,
} from "../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { getFidoSupport } from "../../utilities/browser-helper";
import { type ServerData } from "../../utilities/server-data";
import { setUntrustedExternalInputText } from "../../utilities/untrusted-external-input-text";
import { type IViewContext, createViewProvider } from "../view-context";
import { LoginState as PostedLoginState, transformCredentialFlags } from "./login-constants";
import loginReducer, { type LoginActions } from "./login-reducer";

export interface IDeviceProperties {
  name: string;
  application: string;
}

export interface IRemoteLoginPollingParams {
  oneTimeCode: string;
  deviceIdentifier: string;
}

export interface IRemoteNgcParams {
  showAnimatedGifWhilePolling: boolean;
  defaultType: RemoteNgcType | null;
  entropy: string;
  sessionIdentifier: string;
  requestSent: boolean;
  styleCredSwitchLinkAsButton: boolean;
  devices: IDeviceProperties[];
}

export interface IAssociateAccountParams {
  daExpires: string;
  daStartTime: string;
  daToken: string;
  stsInlineFlowToken: string;
  signinName: string;
}

export type ChallengeData = {
  challengeType: HipType;
  arkoseDataExchangeBlob: string;
  phoneRepMetadata: string;
};

export type LoginCredentialsAndParams = Pick<LoginState, "credentials" | "remoteNgcParams"> & {
  fedRedirectParams?: FederationRedirectParams;
  fidoParams?: FidoParams;
  location: string;
  remoteLoginPollingParams?: IRemoteLoginPollingParams;
};

export type LoginState = {
  credentials: ILoginCredentials;
  desktopSsoEnabled: boolean;
  desktopSsoExecuted: boolean;
  fidoAllowList: string[];
  hasIdpDisambigError: boolean;
  idpRedirectPostParams: RedirectPostParams;
  idpRedirectProvider: BindProvider;
  idpRedirectUrl: string;
  initialViewId: ViewId;
  location: string;
  isSignupPost?: boolean;
  otherIdpRedirectUrl: string;
  remoteLoginPollingParams: IRemoteLoginPollingParams;
  remoteNgcParams: IRemoteNgcParams;
  serverErrorShown: boolean;
  showCredViewBrandingDesc: boolean;
  unsafeDesktopSsoDomainToUse: string;
  associateAccountParams: IAssociateAccountParams;
};

export const defaultCredentials: ILoginCredentials = {
  availableCredentials: [],
  evictedCredentials: [],
  preferredCredential: CredentialType.Password,
  proofConfirmation: "",
  sessions: [],
  useEvictedCredentials: false,
};

export const defaultRemoteNgcParams = {
  showAnimatedGifWhilePolling: false,
  defaultType: null,
  entropy: "",
  sessionIdentifier: "",
  requestSent: false,
  styleCredSwitchLinkAsButton: false,
  devices: [],
};

export const defaultAssociateAccountData: IAssociateAccountParams = {
  daExpires: "",
  daStartTime: "",
  daToken: "",
  stsInlineFlowToken: "",
  signinName: "",
};

export const defaultLoginState: LoginState = {
  credentials: {
    ...defaultCredentials,
  },
  desktopSsoEnabled: false,
  desktopSsoExecuted: false,
  fidoAllowList: [],
  hasIdpDisambigError: false,
  idpRedirectPostParams: {},
  idpRedirectProvider: BindProvider.Unknown,
  idpRedirectUrl: "",
  initialViewId: ViewId.None,
  location: "",
  otherIdpRedirectUrl: "",
  remoteLoginPollingParams: {
    deviceIdentifier: "",
    oneTimeCode: "",
  },
  remoteNgcParams: {
    ...defaultRemoteNgcParams,
  },
  showCredViewBrandingDesc: false,
  serverErrorShown: false,
  unsafeDesktopSsoDomainToUse: "",
  associateAccountParams: {
    ...defaultAssociateAccountData,
  },
};

export const LoginContext = createContext<IViewContext<LoginState, LoginActions>>({
  viewState: defaultLoginState,
  dispatchStateChange: () => {
    throw new Error("Login Context not initialized");
  },
});

export const useLoginContext = () => useContext(LoginContext);

export const LoginProvider: React.FC<{ initialState: LoginState }> = createViewProvider<
  LoginState,
  LoginActions
>(LoginContext, loginReducer);

/* ********* ServerData helpers  ********** */

/**
 * This method is used to initialize the LoginContext associate acccount params
 * If a response is provided in ServerData, that will be transformed into the associated state
 * @param serverData The ServerData object
 * @returns The LoginContext.associateAccountParams
 */
export const getAssociateAccountData = function getAssociateAccountData(
  serverData: ServerData,
): IAssociateAccountParams {
  const associateAccountParams = { ...defaultAssociateAccountData };

  if (serverData?.sDAExpires) {
    associateAccountParams.daExpires = serverData.sDAExpires;
  }

  if (serverData?.sDAStartTime) {
    associateAccountParams.daStartTime = serverData.sDAStartTime;
  }

  if (serverData?.sDAToken) {
    associateAccountParams.daToken = serverData.sDAToken;
  }

  if (serverData?.sSTSInlineFlowToken) {
    associateAccountParams.stsInlineFlowToken = serverData.sSTSInlineFlowToken;
  }

  if (serverData?.sSigninName) {
    associateAccountParams.signinName = serverData.sSigninName;
  }

  return associateAccountParams;
};

/**
 * This method is used to initialize the LoginContext credentials and login parameters specific to
 * different credential types.
 * If a GCT response is provided in ServerData, that will be transformed into the associated state
 * If sessions are provided in ServerData, those will be transformed similarly
 * @param serverData The ServerData object
 * @returns The LoginContext.credentials and login parameters such as LoginContext.remoteNgcParams
 * for the initial state
 */
export const getCredentialsAndLoginParams = function getCredentialsAndLoginParams(
  serverData: ServerData,
): LoginCredentialsAndParams {
  if (serverData.oGetCredTypeResult) {
    // TODO: get some of these props from LoginConfig when generating together
    const {
      availableCredentials,
      evictedCredentials,
      preferredCredential,
      useEvictedCredentials,
      otcCredential,
      remoteNgcParams,
      fedRedirectParams,
      fidoParams,
      // The location from GCT response is later used to set the initial value on the country code dropdown
      // component on the phone disambiguation view. If one is not specified in the response, we will use
      // sPrefSMSCountry from ServerData instead.
      location,
      remoteLoginPollingParams,
    } = transformCredentialResponse(
      serverData.oGetCredTypeResult,
      {
        postProofType: serverData.sProofType ? parseInt(serverData.sProofType, 10) : 0,
        showSignup: serverData.fCBShowSignUp,
        signupUrl: serverData.urlSignUp,
        improvePhoneDisambiguation: serverData.fImprovePhoneDisambig,
        loginStateForPostBack: serverData.sPOST_PaginatedLoginState
          ? parseInt(serverData.sPOST_PaginatedLoginState, 10)
          : PostedLoginState.Unknown,
      },
      getFidoSupport(!!serverData.fIsFidoSupported, !!serverData.fUseWebviewFidoCustomProtocol),
      false,
      transformCredentialFlags,
    );

    return {
      credentials: {
        ...defaultCredentials,
        availableCredentials,
        evictedCredentials,
        preferredCredential,
        useEvictedCredentials,
        otcCredential,
      },
      remoteNgcParams,
      fedRedirectParams,
      fidoParams,
      location,
      remoteLoginPollingParams,
    };
  }

  return {
    credentials: { ...defaultCredentials },
    location: "",
    remoteNgcParams: { ...defaultRemoteNgcParams },
  };
};

/**
 * Create a Login state object from ServerData
 * @param serverData The IDP-specific server data object that should be used to create the Login state
 * @returns The IDP-agnostic Login state object created from the server data
 */
export function createLoginState(serverData: ServerData): LoginState {
  const loginState = { ...defaultLoginState };

  if (serverData?.urlGoToAADError) {
    loginState.otherIdpRedirectUrl = serverData.urlGoToAADError;
  }

  if (serverData?.desktopSsoConfig) {
    const hintedDomainName = serverData.desktopSsoConfig?.hintedDomainName || "";

    loginState.unsafeDesktopSsoDomainToUse = serverData.desktopSsoConfig?.startDesktopSsoOnPageLoad
      ? hintedDomainName
      : "";
  }

  const {
    credentials,
    fedRedirectParams,
    fidoParams,
    location,
    remoteLoginPollingParams,
    remoteNgcParams,
  } = getCredentialsAndLoginParams(serverData);

  loginState.credentials = credentials;
  loginState.fidoAllowList =
    fidoParams?.AllowList ?? serverData?.arrFidoAllowList ?? loginState.fidoAllowList;
  loginState.idpRedirectPostParams =
    fedRedirectParams?.idpRedirectPostParams ?? loginState.idpRedirectPostParams;
  loginState.idpRedirectProvider =
    fedRedirectParams?.idpRedirectProvider ?? loginState.idpRedirectProvider;
  loginState.idpRedirectUrl = fedRedirectParams?.idpRedirectUrl ?? loginState.idpRedirectUrl;
  loginState.location = location;
  loginState.remoteLoginPollingParams =
    remoteLoginPollingParams ?? loginState.remoteLoginPollingParams;
  loginState.remoteNgcParams = remoteNgcParams;

  loginState.associateAccountParams = getAssociateAccountData(serverData);

  if (serverData?.arrSessions) {
    const sessions = serverData.arrSessions;

    loginState.credentials.sessions = sessions.map((session) => {
      const configSession = {} as ISession;

      if (session?.displayName) {
        configSession.displayName = setUntrustedExternalInputText(session.displayName);
      }

      if (session?.fullName) {
        configSession.fullName = setUntrustedExternalInputText(session.fullName);
      }

      if (session?.id) {
        configSession.id = session.id;
      }

      if (session?.isSamsungSso !== undefined) {
        configSession.isSamsungSso = session.isSamsungSso;
      }

      if (session?.isSignedIn !== undefined) {
        configSession.isSignedIn = session.isSignedIn;
      }

      if (session?.name) {
        configSession.name = setUntrustedExternalInputText(session.name);
      }

      return configSession;
    });
  }

  if (serverData?.oAppCobranding?.showDescOnCredViews !== undefined) {
    loginState.showCredViewBrandingDesc = serverData.oAppCobranding.showDescOnCredViews;
  }

  if (serverData?.sProofConfirm) {
    loginState.credentials.proofConfirmation = serverData.sProofConfirm;
  }

  if (serverData?.fPOST_IsSignupPost !== undefined) {
    loginState.isSignupPost = serverData?.fPOST_IsSignupPost;
  }

  return loginState;
}
