import { makeStyles } from "@griffel/react";

export const useLearnAboutConsentViewStylesFabric = makeStyles({
  manageConsentLink: {
    fontSize: "13px",
    marginBottom: "48px",
  },
});

export default useLearnAboutConsentViewStylesFabric;

export type LearnAboutConsentViewStylesFabric = ReturnType<
  typeof useLearnAboutConsentViewStylesFabric
>;
