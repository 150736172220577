import React, { useState } from "react";
import { UserFlowType } from "../../../../constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import featuresConfig from "../../../../features-config";
import { useGlobalContext } from "../../../../global-context";
import { useEffectOnce } from "../../../../hooks/use-effect-once";
import { type ILoginCredentials } from "../../../../model/credential";
import { AgreementViewFabric } from "../../../../views";
import { FetchSessionsProgressViewFabric } from "../../fetch-sessions-progress/fabric/fetch-sessions-progress-view-fabric";
import { useFidoPostRedirect, useLoginFlowRedirect } from "../../hooks/login-hooks";
import { IdpDisambiguationViewFabric } from "../../idp-disambiguation/fabric/idp-disambiguation-view-fabric";
import { IdpRedirectViewFabric } from "../../idp-redirect/fabric/idp-redirect-view-fabric";
import { IdpRedirectSpeedbumpViewFabric } from "../../idp-redirect-speedbump/fabric/idp-redirect-speedbump-view-fabric";
import LoginConfig from "../../login-config";
import { Error, LoginMode, LoginState } from "../../login-constants";
import { useLoginContext } from "../../login-context";
import { LoginActionType } from "../../login-reducer";
import {
  type LoginFlowRedirectParams,
  getNextActionFromPostedState,
  isForceSignIn,
} from "../../login-util";
import { PushNotificationsViewFabric } from "../../push-notifications/fabric/push-notifications-view-fabric";
import { RemoteConnectCanaryValidationViewFabric } from "../../remote-connect-canary-validation/fabric/remote-connect-canary-validation-view-fabric";
import { AccountPickerViewFabric } from "../../views/account-picker/fabric";
import { BenefitsViewFabric } from "../../views/benefits/fabric/benefits-view-fabric";
import { ConfirmSendViewFabric } from "../../views/confirm-send/fabric";
import { CredentialPickerViewFabric } from "../../views/credential-picker/fabric";
import { ErrorViewFabric } from "../../views/error/fabric";
import { OneTimeCodeViewFabric } from "../../views/one-time-code/fabric/one-time-code-view-fabric";
import { PasswordViewFabric } from "../../views/password/fabric";
import { PhoneDisambiguationViewFabric } from "../../views/phone-disambiguation/fabric/phone-disambiguation-view-fabric";
import { ProofConfirmationViewFabric } from "../../views/proof-confirmation/fabric/proof-confirmation-view-fabric";
import { UsernameViewFabric } from "../../views/username/fabric";
import { isInitialViewAccountPicker } from "../model/is-initial-view-account-picker";
import { loadAsyncIPv6Image } from "../model/load-async-ipv6-image";
import { redirectOnLoadIfNeeded } from "../model/redirect-on-load-if-needed";
import { useValidateExternalCanary } from "../model/use-validate-external-canary";

export const defaultActionFromPostedState = {
  initialViewId: ViewId.None,
  doLoginFlowRedirect: false,
  loginFlowRedirectParams: {} as LoginFlowRedirectParams,
};

/**
 * The view ID returned by the LoginStateToViewIdMap map is used by the initial view picker to choose the
 * corresponding View Component from this map. Please ensure that this map is complete, i.e. it contains
 * an entry for every view ID in the LoginStateToViewIdMap map where appropriate.
 */
export const ViewIdToViewMapFabric: { [key: string]: JSX.Element } = {
  [ViewId.Username]: <UsernameViewFabric />,
  [ViewId.Password]: <PasswordViewFabric />,
  [ViewId.OneTimeCode]: <OneTimeCodeViewFabric />,
  [ViewId.PushNotifications]: <PushNotificationsViewFabric />,
  [ViewId.PhoneDisambiguation]: <PhoneDisambiguationViewFabric />,
  [ViewId.IdpDisambiguation]: <IdpDisambiguationViewFabric />,
  [ViewId.IdpRedirect]: <IdpRedirectViewFabric />,
  [ViewId.ViewAgreement]: <AgreementViewFabric hostingFlow={FlowId.Login} />,
  [ViewId.AccountPicker]: <AccountPickerViewFabric />,
  [ViewId.ConfirmSend]: <ConfirmSendViewFabric />,
  [ViewId.CredentialPicker]: <CredentialPickerViewFabric />,
  [ViewId.Error]: <ErrorViewFabric />,
  [ViewId.IdpRedirectSpeedbump]: <IdpRedirectSpeedbumpViewFabric />,
  [ViewId.ProofConfirmation]: <ProofConfirmationViewFabric />,
  [ViewId.FetchSessionsProgress]: <FetchSessionsProgressViewFabric />,
  [ViewId.Benefits]: <BenefitsViewFabric />,
};

/**
 * Logic to determine initial view, to be used in useEffectOnce so that we can update the state
 * @param loginMode the login mode from server data
 * @param credentials the credentials from server data
 * @param userFlowType indicates the type of user going through the account creation flow, e.g. adult, child etc.
 * @param errorCode error code returned by server
 * @returns initial view ID
 */
const determineInitialView = (
  loginMode: number,
  credentials: ILoginCredentials,
  userFlowType: UserFlowType,
  errorCode?: string,
) => {
  const isForceSignin = isForceSignIn();
  const { isSimplifiedChildAccountCreation } = featuresConfig.instance;

  // Default to either Account Picker or Username view
  const isNextViewAccountPicker = isInitialViewAccountPicker(
    loginMode,
    credentials.sessions.length,
    isForceSignin,
  );
  let nextViewId = isNextViewAccountPicker ? ViewId.AccountPicker : ViewId.Username;
  let actionFromPostedState = defaultActionFromPostedState;

  // This flag is to ensure that LoginMode has higher priority and certain views indicated
  // by LoginMode should be prioritized instead of trying to get next action from posted state
  let viewIdLocked = false;

  if (loginMode === LoginMode.Tiles) {
    // If the login mode was tiles, nextViewId will already be set to ViewId.AccountPicker
    viewIdLocked = true;
  }

  if (errorCode === Error.PP_E_PASSKEY_AUTH_INTERRUPTED) {
    nextViewId =
      credentials.availableCredentials.length > 0 ? ViewId.CredentialPicker : ViewId.Username;
    viewIdLocked = true;
  }

  if (
    loginMode === LoginMode.UserCredentialPolicyBlocked ||
    loginMode === LoginMode.CredentialPicker
  ) {
    nextViewId = ViewId.CredentialPicker;
    viewIdLocked = true;
  }

  if (loginMode === LoginMode.Fido) {
    nextViewId = ViewId.Fido;
    viewIdLocked = true;
  }

  if (loginMode === LoginMode.FetchSessionsProgress) {
    nextViewId = ViewId.FetchSessionsProgress;
  }

  if (
    loginMode === LoginMode.GenericError ||
    loginMode === LoginMode.GenericErrorMobile ||
    loginMode === LoginMode.GenericErrorHost ||
    loginMode === LoginMode.SwitchUser ||
    loginMode === LoginMode.SwitchUserMobile ||
    loginMode === LoginMode.SwitchUserHost ||
    loginMode === LoginMode.InviteBlocked ||
    loginMode === LoginMode.ServiceBlocked ||
    loginMode === LoginMode.IDPFailed ||
    loginMode === LoginMode.HIP_Lockout ||
    loginMode === LoginMode.HIP_LockoutMobile ||
    loginMode === LoginMode.HIP_LockoutHost ||
    loginMode === LoginMode.BindFailed
  ) {
    nextViewId = ViewId.Error;
    viewIdLocked = true;
  }

  if (!viewIdLocked) {
    actionFromPostedState = getNextActionFromPostedState(nextViewId);
    const viewFromPostedState = actionFromPostedState.initialViewId;

    if (viewFromPostedState !== ViewId.None) {
      nextViewId = viewFromPostedState;
    }
  }

  if (isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Child) {
    nextViewId = ViewId.Benefits;
  }

  return { nextViewId, actionFromPostedState };
};

/**
 * Inital view picker for the login flow
 * @returns The first view instance for the login flow
 */
export const LoginInitialViewPickerFabric: React.FC = function LoginInitialViewPickerFabric() {
  const {
    viewState: { credentials },
    dispatchStateChange: dispatchLoginStateChange,
  } = useLoginContext();

  const { loginMode, externalCanary, signupUrl, signupUrlPostParams } = LoginConfig.instance;
  const fidoRedirectCallback = useFidoPostRedirect();
  const loginFlowRedirect = useLoginFlowRedirect();

  const [initialViewId, setInitialViewId] = useState(ViewId.None);

  // External canary validation happens asynchronously, so we need to use a state variable
  // to store the result and track when we're ready to render.
  const invokeExternalCanaryValidation = useValidateExternalCanary();
  const hasExternalCanary = !!externalCanary;
  const [readyToRender, setReadyToRender] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [showRemoteConnectCanaryValidationView, setShowRemoteConnectCanaryValidationView] =
    useState(false);
  const {
    globalState: {
      userFlowType,
      debugInfo: { errorCode },
    },
  } = useGlobalContext();

  // useEffectOnce is used to only run this async process once, so we don't do it every time the
  // invokeExternalCanaryValidation callback is regenerated in useValidateExternalCanary in each render
  useEffectOnce(() => {
    loadAsyncIPv6Image();

    redirectOnLoadIfNeeded();

    const { nextViewId, actionFromPostedState } = determineInitialView(
      loginMode,
      credentials,
      userFlowType,
      errorCode,
    );

    if (nextViewId === ViewId.Fido) {
      setShouldRedirect(true);
      fidoRedirectCallback();
    }

    if (actionFromPostedState.doLoginFlowRedirect) {
      setShouldRedirect(true);
      const redirectParams = actionFromPostedState.loginFlowRedirectParams;
      loginFlowRedirect(
        redirectParams.redirectUrl || "",
        redirectParams.redirectPostParams,
        redirectParams.isIdpRedirect,
      );
    }

    if (hasExternalCanary) {
      invokeExternalCanaryValidation().then((nextView) => {
        if (nextView === LoginState.PostRedirect) {
          setShouldRedirect(true);
          loginFlowRedirect(signupUrl, signupUrlPostParams, false, false);
        }

        setShowRemoteConnectCanaryValidationView(
          nextView === LoginState.RemoteConnectCanaryValidation,
        );
        setReadyToRender(true);
      });
    } else {
      setReadyToRender(true);
    }

    // Update state once it is decided that no redirects are necessary
    setInitialViewId(nextViewId);
    dispatchLoginStateChange({
      type: LoginActionType.SetInitialViewId,
      payload: nextViewId,
    });
  });

  if (!readyToRender) {
    return <div data-testid="initial-view-placeholder" />;
  }

  if (shouldRedirect) {
    return <div />;
  }

  if (showRemoteConnectCanaryValidationView) {
    return <RemoteConnectCanaryValidationViewFabric nextView={initialViewId} />;
  }

  // We did not need to do canary validation, we can return the initial evaluated view
  return ViewIdToViewMapFabric[initialViewId] || <UsernameViewFabric />;
};
