import { makeStyles, shorthands } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type ProgressIndicatorStylesFabric, useProgressIndicatorStylesFabric } from "../fabric";

const useOverrideProgressIndicatorStyles = makeStyles({
  slidingLineAnimation: {
    position: "relative",
    width: "calc(100% - 19px)",
    height: "5px",
    top: "2px",
    left: "2px",
    ...shorthands.overflow("hidden", "hidden"),
  },

  subline: {
    position: "absolute",
    backgroundColor: "#005FB7",
    height: "3px",
    ...shorthands.borderRadius("3px"),
  },

  inc: {
    animationDuration: "2.4s",
    animationIterationCount: "infinite",
    animationName: { from: { left: "-5%", width: "5%" }, to: { left: "130%", width: "100%" } },
  },

  dec: {
    animationDelay: "0.6s",
    animationDuration: "2.4s",
    animationIterationCount: "infinite",
    animationName: { from: { left: "-80%", width: "80%" }, to: { left: "110%", width: "10%" } },
  },
});

export type ProgressIndicatorStylesWin11OobeFabric = ProgressIndicatorStylesFabric &
  Partial<ReturnType<typeof useOverrideProgressIndicatorStyles>>;

export const useProgressIndicatorStylesWin11OobeFabric = () =>
  useMergedStyles(
    useProgressIndicatorStylesFabric(),
    useOverrideProgressIndicatorStyles(),
  ) as ProgressIndicatorStylesWin11OobeFabric;
