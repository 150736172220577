import React, { createContext, useContext, useMemo, useReducer } from "react";
import { type FlowId, LayoutTemplateType } from "../constants";
import {
  defaultAccentColors,
  getBrandingStyles,
  getMergedTenantBranding,
} from "../utilities/customization-helper";
import { type ICustomizableStyles, type TenantBranding } from "../utilities/customization-types";
import {
  type CustomString,
  type RenderPromises,
  getRenderPromises,
} from "../utilities/render-promises-helper";
import { type ServerData } from "../utilities/server-data";
import { filterLinks } from "../utilities/strings-helper";
import customizationReducer, { type CustomizationActions } from "./customization-reducer";

export type CustomizationState = {
  readyToRender: boolean;
  renderPromises: RenderPromises;
  styles: ICustomizableStyles;
  strings: CustomString[];
};

export interface ICustomizationContext {
  customizationState: CustomizationState;
  dispatchStateChange: React.Dispatch<CustomizationActions>;
}

export const defaultStyles: ICustomizableStyles = {
  accessRecoveryUrl: "",
  accentColors: defaultAccentColors,
  alwaysShowBackground: false,
  backgroundColor: "#f2f2f2",
  backgroundImageUrl: "",
  backgroundLogoUrl: "",
  bannerLogoUrl: "",
  bannerLogoText: "",
  boilerPlateText: "",
  cantAccessYourAccountText: "", // TODO: Get localized string when ready.
  customizationFiles: {},
  faviconUrl: "",
  forgotPasswordText: "", // TODO: Get localized string when ready.
  friendlyAppName: "",
  headerLogoUrl: "",
  layoutTemplate: LayoutTemplateType.Lightbox,
  partnerTag: "",
  privacyText: getLocalString("General_PrivacyAndCookies"), // @TODO: Base strings should be moved to a flavored, common strings file with branding strings applied overtop.
  privacyUrl: "",
  showAccountResetCredentials: true,
  showFooter: true,
  showHeader: false,
  showPrivacy: true,
  showTermsOfUse: true,
  termsOfUseText: getLocalString("General_TermsOfUse"), // @TODO: Base strings should be moved to a flavored, common strings file with branding strings applied overtop.
  termsOfUseUrl: "",
  useBackgroundImageMask: false,
  useDarkFooter: false,
  useTransparentLightbox: false,
};

export const initialCustomizationState: CustomizationState = {
  readyToRender: false,
  renderPromises: {
    loadPromises: [],
    stringPromises: [],
  },
  styles: defaultStyles,
  strings: [],
};

export const CustomizationContext = createContext<ICustomizationContext>({
  customizationState: initialCustomizationState,
  dispatchStateChange: () => {
    throw new Error("CustomizationContext not initialized");
  },
});

export const useCustomizationContext = () => useContext(CustomizationContext);

/**
 * The customization provider needs to be used in every app/flow since it provides with different customization settings that users or applications can configure
 * to customize their SISU experience, which will impact how different components are rendered and could change dynamically throughout the flow.
 * @param props The input props for the string customization provider.
 * @param props.initialState The initial state that the customization provider should be initialized with. Note that this is NOT the default state
 * (fallback values provided at design time) but rather the state extracted from ServerData or otherwise representing the initial state of
 * the application at runtime
 * @param props.children The child components to render inside this provider
 * @returns The instantiated Provider component
 */
export const CustomizationProvider: React.FC<{ initialState: CustomizationState }> =
  function CustomizationProvider({ initialState, children }) {
    const [state, dispatch] = useReducer(customizationReducer, initialState);
    const value: ICustomizationContext = useMemo(
      () => ({
        customizationState: state,
        dispatchStateChange: dispatch,
      }),
      [state],
    );

    return <CustomizationContext.Provider value={value}>{children}</CustomizationContext.Provider>;
  };

/* ********* ServerData helpers ********** */

/**
 * @private
 */
export const createStyles = function createStyles(
  serverData: ServerData,
  fallbackToResourceBranding?: boolean,
  userTenantBranding?: TenantBranding,
) {
  const {
    dynamicTenantBranding: dynamicBranding,
    staticTenantBranding: staticBranding,
    isGlobalTenant,
    fIsCiamUserFlowUx: isCiamUserFlow,
    fUseNonMicrosoftDefaultBrandingForCiam: useDefaultCiamBranding,
  } = serverData;

  // Merge the optional dynamic branding that belongs to the user's home tenant to override branding customizations in the sign in box.
  // This is retrieved when executing async home realm discovery (GCT), and helps ensure strings and links are the ones the user is familiar with.
  const mergedTenantBranding = getMergedTenantBranding(
    staticBranding,
    userTenantBranding || dynamicBranding,
    isGlobalTenant,
    isCiamUserFlow,
    useDefaultCiamBranding,
    fallbackToResourceBranding,
  );

  const brandingStyles = getBrandingStyles(serverData, mergedTenantBranding);

  const styles: Partial<ICustomizableStyles> = {
    accessRecoveryUrl: brandingStyles.accessRecoveryUrl || serverData?.urlResetPassword,
    accentColors: brandingStyles.accentColors,
    alwaysShowBackground: brandingStyles.alwaysShowBackground,
    showAccountResetCredentials: brandingStyles.showAccountResetCredentials,
    customizationFiles: brandingStyles.customizationFiles,
    faviconUrl: brandingStyles.faviconUrl || serverData?.urlDefaultFavicon || "",
    friendlyAppName: brandingStyles.friendlyAppName,
    layoutTemplate: brandingStyles.layoutTemplate,
    partnerTag: brandingStyles.partnerTag,
    privacyUrl:
      brandingStyles.privacyUrl || serverData?.urlFooterPrivacy || serverData?.urlHostedPrivacyLink,
    showFooter: brandingStyles.showFooter,
    showHeader: brandingStyles.showHeader,
    showPrivacy: brandingStyles.showPrivacy,
    showTermsOfUse: brandingStyles.showTermsOfUse,
    termsOfUseUrl:
      brandingStyles.termsOfUseUrl || serverData?.urlFooterTOU || serverData?.urlHostedTOULink,
    useBackgroundImageMask: brandingStyles.useBackgroundImageMask,
    useDarkFooter: brandingStyles.useDarkFooter,
    useTransparentLightbox: brandingStyles.useTransparentLightbox,
  };

  if (brandingStyles.backgroundColor) {
    styles.backgroundColor = brandingStyles.backgroundColor;
  }

  if (brandingStyles.backgroundImageUrl) {
    styles.backgroundImageUrl = brandingStyles.backgroundImageUrl;
  }

  if (brandingStyles.backgroundLogoUrl) {
    styles.backgroundLogoUrl = brandingStyles.backgroundLogoUrl;
  }

  if (brandingStyles.bannerLogoText) {
    styles.bannerLogoText = brandingStyles.bannerLogoText;
  }

  if (brandingStyles.bannerLogoUrl) {
    styles.bannerLogoUrl = brandingStyles.bannerLogoUrl;
  }

  if (brandingStyles.boilerPlateText) {
    styles.boilerPlateText = brandingStyles.boilerPlateText;

    if (serverData.fIsHosted) {
      styles.boilerPlateText = filterLinks(styles.boilerPlateText);
    }
  }

  if (brandingStyles.cantAccessYourAccountText) {
    styles.cantAccessYourAccountText = brandingStyles.cantAccessYourAccountText;
  }

  if (brandingStyles.forgotPasswordText) {
    styles.forgotPasswordText = brandingStyles.forgotPasswordText;
  }

  if (brandingStyles.headerLogoUrl) {
    styles.headerLogoUrl = brandingStyles.headerLogoUrl;
  }

  if (brandingStyles.termsOfUseText) {
    styles.termsOfUseText = brandingStyles.termsOfUseText;
  }

  // @TODO: Base strings should be moved to a flavored, common strings file with branding strings applied overtop.
  if (brandingStyles.privacyText) {
    styles.privacyText = brandingStyles.privacyText;
  } else if (serverData.fShowUpdatedKoreanPrivacyFooter) {
    styles.privacyText = getLocalString("General_PrivacyAndCookies_AltKorean");
  }

  return styles;
};

/**
 * Create a styles state object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * Any properties that are used inside a React component's lifecycle (hook or render function)
 * AND can change during the component's lifecycle should go into the state. Constant properties
 * or properties that are not used by a React component can be stored in config instead to improve
 * performance and prevent unnecessary re-rendering.
 * @param serverData The IDP-specific server data object that should be used to create the styles state object.
 * @param flowId The flow ID to determine which custom string files are supported.
 * @returns The IDP-agnostic styles state object created from the server data.
 */
export function createCustomizationState(
  serverData: ServerData,
  flowId?: FlowId,
): CustomizationState {
  const serverDataStyles = createStyles(serverData);
  const styles = {
    ...defaultStyles,
    ...serverDataStyles,
    customizationFiles: {
      ...serverDataStyles.customizationFiles,
    },
  };

  // Get all the render promises that need to be executed before the initial view is rendered.
  // Render promises are used to load async content and update the customization state with the loaded content.
  // We need to start the promises as soon as possible, so we do it right after getting the customization files.
  const renderPromises = getRenderPromises(styles.customizationFiles, flowId);

  const readyToRender =
    renderPromises.loadPromises.length === 0 && renderPromises.stringPromises.length === 0;

  return {
    ...initialCustomizationState,
    readyToRender,
    renderPromises,
    styles,
  };
}
