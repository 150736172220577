import { type EffectCallback, useEffect, useRef, useState } from "react";

/**
 * React hook that runs only once during mount and not on re-renders. Optionally, the effect callback
 * may return a cleanup function that runs only once on unmount.
 *
 * This hook is used when we do not want the effect to run again when the dependencies change.
 *
 * @param effect The effect function to run once
 */
export const useEffectOnce = function useEffectOnce(effect: EffectCallback) {
  const cleanup = useRef<ReturnType<EffectCallback>>();
  const [useEffectRan, setUseEffectRan] = useState<boolean>(false);

  useEffect(() => {
    if (!useEffectRan) {
      cleanup.current = effect();
      setUseEffectRan(true);

      // The change to `useEffectRan` above should trigger a final re-render of this effect callback
      // so we want to return a no-op callback for now and return the actual cleanup callback during
      // the final re-render so cleanup only occurs during unmount.
      return () => {};
    }

    return cleanup.current;
  }, [effect, useEffectRan]);
};
