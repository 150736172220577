import GlobalConfig from "../../global-config";
import { isHighContrastDark, isHighContrastLight } from "../browser-helper";
import { type AccessibleImageUrls } from "./accessible-images";

/**
 * Returns light image for high contrast dark or if hasDefaultDarkBackground is true and
 * high contrast light theme is not enabled.
 * @param name The image name(s) for different background types
 * @returns A path to the appropriate accessible image or empty string
 */
export function getAccessibleImagePath(name?: AccessibleImageUrls): string {
  if (!name) {
    return "";
  }

  const { hasDefaultDarkBackground } = GlobalConfig.instance;

  return isHighContrastDark() || (hasDefaultDarkBackground && !isHighContrastLight())
    ? name.light
    : name.dark;
}
