import { doubleJoin, serializeObject } from "./object-helper";
import { doubleSplit, replaceTokens, trim } from "./strings-helper";

const expireDate = "Thu, 30-Oct-1980 16:00:00 GMT";
const persistTTLDays = 390; // 13 months in days
const useSameSite = !!window.ServerData?.fUseSameSite;

/**
 * @returns the expiration date for persistent cookies starting from the current date
 */
const getPersistDate = (): string => {
  const date = new Date();
  date.setDate(date.getDate() + persistTTLDays);
  return date.toUTCString();
};

/**
 * @param secure Whether to make the cookie secure
 * @returns The default same site attribute
 */
export const getDefaultSameSiteAttribute = (secure: boolean): string => {
  if (secure && useSameSite) {
    return ";SameSite=None";
  }

  return "";
};

/**
 * Removes the cookie specified by the name, domain and path parameters.
 * @param name The name of the cookie
 * @param domain Domain attribute to use for the cookie
 * @param path The path of the cookie. If not passed, the default "/" is used
 */
export const removeCookie = (name: string, domain?: string, path?: string) => {
  const domainName = domain || document.location.hostname;
  const hostParts = domainName.split(".");
  const partCount = hostParts.length;

  const cookieDomain = `${hostParts[partCount - 2]}.${hostParts[partCount - 1]}`;
  const cookiePath = path || "/";
  const secure = document.location.protocol === "https:";
  const secureContent = secure ? ";secure" : "";
  const sameSiteContent = getDefaultSameSiteAttribute(secure);

  document.cookie = replaceTokens(
    "{0}= ;domain=.{1};path={2};expires={3}{4}{5}",
    name,
    cookieDomain,
    cookiePath,
    expireDate,
    secureContent,
    sameSiteContent,
  );
  document.cookie = replaceTokens(
    "{0}= ;domain=.{1};path={2};expires={3}{4}{5}",
    name,
    domainName,
    cookiePath,
    expireDate,
    secureContent,
    sameSiteContent,
  );
};

/**
 * Writes a cookie to the domain passed as a parameter using the provided expiration date.
 * Blank cookies will be assumed to be cookie deletes, and will expire in the past.</summary>
 * @param name The name of the cookie
 * @param value The value of the cookie
 * @param secure Whether to make the cookie secure
 * @param expiresOn A string object representing the date when the cookie expires (in local time).
 * @param domain domain attribute to use for the cookie.
 * @param path The path of the cookie. If not passed, the default "/" is used
 * @param sameSite The SameSite attribute of the cookie. If not passed, None is used for https requests and no value is used for http requests
 * @param chunk Whether to write multiple cookies if the value is too large for one cookie. Defaults to false.
 */
export const writeCookieWithExpiration = (
  name: string,
  value: string | Record<string, unknown>,
  secure?: boolean,
  expiresOn?: string,
  domain?: string,
  path?: string,
  sameSite?: string,
  chunk?: boolean,
) => {
  if (value === "") {
    removeCookie(name, domain);
  } else {
    const valueStr = typeof value === "object" ? doubleJoin(value, "&", "=") : value;

    const expiration = expiresOn ? `;expires=${expiresOn}` : "";
    const cookieDomain = domain ? `;domain=${domain}` : "";
    const cookiePath = path || "/";
    const secureContent = secure ? ";secure" : "";

    // SameSite=None should trigger the getDefaultSameSiteAttribute path to handle iOS, where SameSite=None is an error and so SameSite should be omitted
    let sameSiteContent;
    if (!sameSite || sameSite.toLowerCase() === "none") {
      sameSiteContent = getDefaultSameSiteAttribute(!!secure);
    } else {
      sameSiteContent = `;SameSite=${sameSite}`;
    }

    const attributes = replaceTokens(
      "{0};path={1}{2}{3}{4}",
      cookieDomain,
      cookiePath,
      expiration,
      secureContent,
      sameSiteContent,
    );

    if (!chunk) {
      const cookieToWrite = replaceTokens("{0}={1}{2}", name, valueStr, attributes);
      document.cookie = cookieToWrite;
    } else {
      // The browser limit here is 4096 by RFC, but some older browsers use lower limits (like 4093). Use 4000 to leave some margin
      const maxValueLen = 4000 - attributes.length - name.length - 1;
      const chunks = Math.ceil(valueStr.length / maxValueLen);
      const cookies = doubleSplit(document.cookie, ";", "=", false, trim);

      let i = 0;
      for (; i < chunks; i += 1) {
        const suffix = i === 0 ? "" : i.toString();
        const chunkValue = valueStr.substring(i * maxValueLen, (i + 1) * maxValueLen);
        const chunkToWrite = replaceTokens("{0}{1}={2}{3}", name, suffix, chunkValue, attributes);
        document.cookie = chunkToWrite;
      }

      // Remove old chunks
      for (; ; i += 1) {
        const chunkName = name + i.toString();
        if (!cookies[chunkName]) {
          break;
        }

        removeCookie(chunkName, domain, path);
      }
    }
  }
};

/**
 * Writes a cookie to the domain passed as a parameter.
 * Persistent cookies will have an expiration in 2037.
 * Blank cookies will be assumed to be cookie deletes, and will expire in the past.</summary>
 * @param name The name of the cookie
 * @param value The value of the cookie
 * @param secure Whether to make the cookie secure
 * @param persist Whether to make the cookie persistent
 * @param topLevel Whether the cookie should be written to the top level domain or the current domain
 * @param addDomainPrefix Whether to add a "." as a prefix to the cookie domain
 * @param path The path of the cookie. If not passed, the default "/" is used
 * @param sameSite The SameSite attribute of the cookie. If not passed, None is used for https requests and no value is used for http requests
 * @param chunk Whether to write multiple cookies if the value is too large for one cookie. Defaults to false.
 */
export const writeCookie = (
  name: string,
  value: string | Record<string, unknown>,
  secure?: boolean,
  persist?: boolean,
  topLevel?: boolean,
  addDomainPrefix?: boolean,
  path?: string,
  sameSite?: string,
  chunk?: boolean,
) => {
  const prefix = addDomainPrefix ? "." : "";
  const parts = document.location.hostname.split(".");

  if (topLevel) {
    parts.splice(0, Math.max(0, parts.length - 2));
  }

  const cookieDomain = prefix + parts.join(".");

  writeCookieWithExpiration(
    name,
    value,
    secure,
    persist ? getPersistDate() : undefined,
    cookieDomain,
    path,
    sameSite,
    chunk,
  );
};

/**
 * This method is used to write a cookie to the domain passed as a parameter.
 * Unlike writeCookie, the domain for the cookie is not derived from the current domain.
 * @param name the name of the cookie
 * @param value the value of the cookie
 * @param domain the domain for the cookie
 * @param skipEncoding whether to skip encoding for the cookie value
 * @param path the path for the cookie
 * @param expires the date the cookie expires
 */
export const setCookie = (
  name: string,
  value: string,
  domain: string,
  skipEncoding: boolean,
  path?: string,
  expires?: Date,
) => {
  const sameSiteContent = getDefaultSameSiteAttribute(true);

  const wrapperObj = { [name]: value };

  document.cookie = replaceTokens(
    "{0};path={1};{2}{3}secure{4}",
    serializeObject(wrapperObj, skipEncoding),
    path || "/",
    `domain=${domain};`,
    expires ? `expires=${expires.toUTCString()};` : "",
    sameSiteContent,
  );
};
