export const postType = {
  password: 11,
  federation: 13,
  sha1: 15,
  strongAuth: 18,
  strongAuthTotp: 19,
  lwaConsent: 30,
  passwordInline: 20,
  remoteNGC: 21,
  sessionApproval: 22,
  ngc: 23,
  otcNoPassword: 24,
  remoteConnect_nativePlatform: 25,
  otc: 27,
  kmsi: 28,
  transferTokenOtc: 31,
  ssoConsent: 33,
  pinChange: 34,
};

export const loginOption = {
  doNotRemember: 0,
  rememberPwd: 1,
  nothingChecked: 3,
};

export enum ScenarioId {
  Account_CheckUpWizard = 100228,
  Reset_Password = 100103,
  Account_PasskeyInterrupt = 100234,
}

export enum HighContrastTheme {
  none = "",
  dark = "dark",
  light = "light",
}

export enum ColorTheme {
  dark = "dark",
  light = "light",
}

export enum MarketDirection {
  ltr = "ltr",
  rtl = "rtl",
}

export type MappedKeys<T, U = string> = {
  [prop in keyof T]: U;
};

export type DataApiError<CodeType = number> = {
  data?: string;
  code: CodeType;
};

export type ApiResponseError<CodeType = number> = { error?: string | DataApiError<CodeType> };

export type ResponseBody<CodeType = number> = Record<string, unknown> & ApiResponseError<CodeType>;

export interface ErrorWithResponse extends Error {
  response?: Response;
}

/**
 * Represents an error that occurs during an OAuth response.
 */
export class OAuthResponseError extends Error {
  /**
   * An array of error codes associated with the error.
   */
  error_codes?: number[];

  /**
   * The timestamp when the error occurred.
   */
  timestamp?: string;

  /**
   * The trace ID associated with the error.
   */
  trace_id?: string;

  /**
   * The correlation ID associated with the error.
   */
  correlation_id?: string;

  /**
   * A suberror code associated with the error.
   */
  suberror?: string;

  /**
   * Creates a new instance of the OAuthResponseError class.
   * @param name - The name of the error.
   * @param message - The error message.
   */
  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}

export interface ResponseError<CodeType = number> extends ErrorWithResponse {
  responseBody?: ResponseBody<CodeType>;
}

export enum LayoutTemplateType {
  Lightbox = 0,
  VerticalSplit = 1,
}

export enum ExternalClassName {
  backgroundAlwaysVisible = "ext-background-always-visible",
  backgroundImage = "ext-background-image",
  backgroundOverlay = "ext-background-overlay",
  bannerLogo = "ext-banner-logo",
  boilerPlateText = "ext-boilerplate-text",
  button = "ext-button",
  buttonFieldContainer = "ext-button-field-container",
  buttonItem = "ext-button-item",
  error = "ext-error",
  debugItem = "ext-debug-item",
  footer = "ext-footer",
  footerContent = "ext-footer-content",
  footerItem = "ext-footer-item",
  footerLinks = "ext-footer-links",
  hasBackground = "ext-has-background",
  header = "ext-header",
  headerLogo = "ext-header-logo",
  middle = "ext-middle",
  primary = "ext-primary",
  secondary = "ext-secondary",
  signInBox = "ext-sign-in-box",
  verticalSplitMainSection = "ext-vertical-split-main-section",
  verticalSplitBackgroundImageContainer = "ext-vertical-split-background-image-container",
}

// API Framework custom error codes
export enum ApiErrorCodes {
  // From ApiErrorCode.cs
  GeneralError = 6000,
  AuthFailure = 6001,
  InvalidArgs = 6002,

  // From ApiRequest.js
  Generic = 8000,
  Timeout = 8001,
  Aborted = 8002,
}

export enum IfExistsResult {
  Unknown = -1,
  Exists = 0,
  NotExist = 1,
  Throttled = 2,
  Error = 4,
  ExistsInOtherMicrosoftIDP = 5,
  ExistsBothIDPs = 6,
}

export enum ApiNames {
  Unknown = "Unknown",
  CreateAccount = "CreateAccount",
  GetOtc = "GetOtc",
  SendOtt = "SendOtt",
}

export enum HipType {
  Unknown = "unknown",
  Audio = "audio",
  Block = "block",
  Enforcement = "enforcement",
  NativeAudio = "native-audio",
  NativeVisual = "native-visual",
  Sms = "sms",
  Visual = "visual",
}

export enum DefaultBrandingStyles {
  BackgroundColor = "#FAF9F8",
}

export enum RemoteNgcType {
  PushNotification = 1,
  ListSessions = 3,
}

/* Updates to this enum should also be reflected in flavors-regex.js */
export enum Flavors {
  None = "none",
  Fabric = "fabric", // Converged
  Fluent = "fluent",
  TwoUI = "twoUi", // account.microsoft.com
  Win11OobeFabric = "win11OobeFabric", // Win10HostOOBEDesktop, aka Win11 OOBE
  WindowsFabric = "windowsFabric", // Win10Host
  Xbox = "xbox",
}

export enum BindProvider {
  Unknown = -1,
  LinkedIn = 0,
  GitHub = 1,
  Google = 2,
  Samsung = 3,
  Facebook = 4,
}

export enum ThrottleStatus {
  NotThrottled = 0,
  AadThrottled = 1,
  MsaThrottled = 2,
}

// Indicates the type of user going through the account creation flow.
export enum UserFlowType {
  Unknown = 0,
  Adult = 1,
  Parent = 2,
  Child = 3,
  AdultWithChild = 4,
}

// Migrated from Core/Constants.js
export enum KeyCode {
  Tab = 9,
  Enter = 13,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  ArrowUp = 38,
  ArrowDown = 40,
  WinKeyLeft = 91,
  F6 = 117,
  GamePadB = 196,
}

// TODO: update all references to FOCUS_TIMEOUT to use this shared constant
export const defaultFocusTimeoutMs = 150;

/** Element attributes used for gamepad navigation module */
export const gamepadMainContentAttributes = {
  /** Defines main scrollable content */
  "data-scrollable": "true",
};
