import { makeStyles, shorthands } from "@griffel/react";

export const useHelpButtonStylesFabric = makeStyles({
  helpButton: {
    verticalAlign: "middle",
    "padding-inline": "5px",
  },
  helpButtonImageStyle: {
    backgroundColor: "transparent",
    ...shorthands.borderWidth("0px"),
    cursor: "pointer",
    minWidth: "fit-content",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export type HelpButtonStylesFabric = ReturnType<typeof useHelpButtonStylesFabric>;
