import React from "react";
import { type IErrorViewLinkProperties } from "../../../login-types";

interface IManageCredentialsLinkProps {
  /** The unique id for the manage credentials link */
  id?: string;
  /** The ids of the labels or elements that describe this link component */
  ariaDescribedBy?: string;
  /** The url that this link takes the user to */
  href?: string;
}

/**
 * ManageCredentialsLink component that is only used in the login error view
 * @returns A rendered instance of this component
 */
const ManageCredentialsLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<IManageCredentialsLinkProps>
>((props, forwardRef) => {
  const { children, id, ariaDescribedBy, href } = props;
  return (
    <a ref={forwardRef} id={id} aria-describedby={ariaDescribedBy} href={href}>
      {children}
    </a>
  );
});

/**
 * @param props properties to use for the embedded ManageCredentialsLink component
 * @returns a function that can be used to render the ManageCredentialsLink component embedded within a formatted string
 */
export const useRenderManageCredentialsLink = (props: IErrorViewLinkProperties) => {
  const { ref, id, ariaDescribedBy, href } = props;
  return function renderLink(linkText: string) {
    return (
      <ManageCredentialsLink ref={ref} id={id} ariaDescribedBy={ariaDescribedBy} href={href}>
        {linkText}
      </ManageCredentialsLink>
    );
  };
};
