import LoginConfig from "../../login-config";

/**
 * This experiment is to add an image tag on the DOM
 * it's data is used by the ISP team for their ML for MSA
 */
export const loadAsyncIPv6Image = () => {
  const { ipv6ExperimentUrl } = LoginConfig.instance;
  if (ipv6ExperimentUrl) {
    const img = new Image();
    img.src = ipv6ExperimentUrl;
    img.setAttribute("style", "visibility: hidden");
    document.body.appendChild(img);
  }
};
