import { useCallback } from "react";
import { RemoteNgcType } from "../../../../constants";
import GlobalConfig from "../../../../global-config";
import { type IUser } from "../../../../model/user";
import { SessionLookupKeyType } from "../../../../utilities/polling/session-polling-util";
import { useSessionPolling } from "../../../../utilities/polling/use-session-polling";
import { replaceTokens } from "../../../../utilities/strings-helper";
import { type IPushNotificationsViewStrings } from "../push-notifications-view-interface";

/**
 * This hook is used to setup session polling with the required callbacks.
 * @param remoteNgcType The remote ngc type
 * @param sessionIdentifier The session identifier
 * @param defaultPollingTitle The title to display when polling
 * @param user The IUser object
 * @param formRef The reference object for the form
 * @param setTitle The method to set the title
 * @param setError The method to set the error
 * @param setIsRequestPendingAndHideBackButton The method to update the isRequestPending state and back arrow button
 * @param viewStrings The strings to use for the view
 * @returns A method that will start the session polling.
 */
export const useStartPolling = (
  remoteNgcType: RemoteNgcType,
  sessionIdentifier: string,
  defaultPollingTitle: string,
  user: IUser,
  formRef: React.RefObject<HTMLFormElement>,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsRequestPendingAndHideBackButton: (isPending: boolean) => void,
  viewStrings: IPushNotificationsViewStrings,
) => {
  const { requestTimeoutTitle, requestTimeoutError, requestDeniedTitle, requestDeniedError } =
    viewStrings;

  // Global config
  const { maxPollingErrors, pollingIntervalMs, pollingTimeoutMs, sessionStateUrl } =
    GlobalConfig.instance;

  const onSessionTimeout = () => {
    if (remoteNgcType === RemoteNgcType.PushNotification) {
      setTitle(requestTimeoutTitle);
      // TO-DO: Remove replaceTokens and user when push notifications feature is fully rolled-out
      setError(replaceTokens(requestTimeoutError, user.displayUsername.unsafeUnescapedString));
    }
    // TO-DO: AAD/ESTS - Add logic to update title and error strings when the remote ngc type is ListSessions (ESTS scenario)
  };

  const onSessionDenied = () => {
    setTitle(requestDeniedTitle);
    setError(replaceTokens(requestDeniedError, user.displayUsername.unsafeUnescapedString));
  };

  const onSessionApproved = () => {
    setTitle("");
    setIsRequestPendingAndHideBackButton(true);
    formRef.current?.submit();
  };

  const { startPolling: startSessionPolling } = useSessionPolling({
    pollingUrl: sessionStateUrl,
    onSessionApproved,
    onSessionDenied,
    onSessionTimeout,
    sessionLookupKey: sessionIdentifier,
    sessionLookupKeyType: SessionLookupKeyType.RemoteNgc,
    maxPollingErrors,
    pollingIntervalMs,
    pollingTimeoutMs,
  });

  const startPolling = useCallback(() => {
    setTitle(defaultPollingTitle);
    startSessionPolling();
  }, [setTitle, startSessionPolling, defaultPollingTitle]);

  return startPolling;
};
