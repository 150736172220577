import { FlowId } from "../constants/routing-constants";
import { getJSON } from "./request/request-helper";
import { type StringCustomizationFiles } from "./customization-types";
import { type CustomString } from "./render-promises-helper";

/**
 * This function is used to load the customer defined strings which will override certain strings from the page.
 * @param fileUrl The URL of the custom strings file to be loaded.
 * @returns A Promise that resolves when the custom strings array that has been loaded.
 */
export const loadStrings = async (fileUrl: string): Promise<CustomString[]> => {
  const STRING_FILE_REQUEST_TIMEOUT = 30000; // 30 seconds

  if (!fileUrl) {
    return Promise.resolve([]);
  }

  try {
    // Fetch the custom strings from the file.
    const responseBody = await getJSON<CustomString[]>(fileUrl, {
      timeout: STRING_FILE_REQUEST_TIMEOUT,
      useApiCanary: false,
      processingStartTime: new Date(),
    });

    // Map the response to a custom string array to ensure there's always an array.
    return responseBody.map((customString) => ({
      key: customString?.key || "",
      value: customString?.value || "",
    }));
  } catch (error) {
    return [];
  }
};

/**
 * This function is used to get the customer defined strings which will override certain strings from the page depending on the flow.
 * @param customStringsFiles The files where custom strings should be fetched from.
 * @param flowId The flow ID of the interrupt that is being loaded.
 * @returns A Promise that resolves when the custom strings for all applicable files have been loaded.
 */
export const getStringCustomizationPromises = (
  customStringsFiles: StringCustomizationFiles,
  flowId: FlowId,
): Promise<CustomString[]>[] => {
  const stringFilesToLoad: Array<string> = [];

  // Resolves the correct file endpoint(s) to be loaded depending on the flowId.
  switch (flowId) {
    case FlowId.Login:
    case FlowId.AttributeCollection:
      if (customStringsFiles.attributeCollection) {
        stringFilesToLoad.push(customStringsFiles.attributeCollection);
      }

      break;

    default:
      break;
  }

  const numStringsToLoad = stringFilesToLoad.length;
  const stringPromises = [];
  if (numStringsToLoad) {
    for (let idx = 0; idx < numStringsToLoad; idx += 1) {
      stringPromises.push(loadStrings(stringFilesToLoad[idx]));
    }
  }

  return stringPromises;
};
