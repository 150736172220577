import { ConfigWrapper } from "../../../utilities/config-wrapper";

export const agreementViewStringsFabric = {
  generalVisitLink: getLocalString("General_Visit_Link"),
  genericErrorNoResponseTryAgain: getLocalString("Generic_Error_NoResponse_TryAgain"),
  generalBack: getLocalString("General_Back"),
};

export type AgreementViewStringsFabric = typeof agreementViewStringsFabric;

// eslint-disable-next-line deprecation/deprecation
export const AgreementViewStringsConfigFabric = new ConfigWrapper({
  agreementViewStrings: agreementViewStringsFabric,
});
