import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName } from "../../../constants";
import { useCustomizationContext } from "../../../context/customization-context";
import GlobalConfig from "../../../global-config";
import { msLogoIcon } from "../../../utilities/image-helpers/accessible-images";
import { htmlUnescape } from "../../../utilities/strings-helper";
import { AccessibleImage } from "../../accessible-image";

/**
 * BannerLogo component
 * @returns Returns a logo component used in the banner.
 * If no banner logo URL is supplied, the banner logo text is displayed.
 * If no banner logo URL or text is supplied (and we're not a CIAM tenant or China DC), the Microsoft logo is displayed.
 */
const BannerLogoFabric: React.FC = function BannerLogoFabric() {
  const { isChinaDc, isCiamUserFlow } = GlobalConfig.instance;
  const { useBannerLogoStyles } = StylesConfig.instance;

  const logoStyles = useBannerLogoStyles();
  const {
    customizationState: {
      styles: { bannerLogoUrl, bannerLogoText },
    },
  } = useCustomizationContext();

  if (bannerLogoUrl) {
    const bannerLogoClassNames = mergeClasses(logoStyles.bannerLogo, ExternalClassName.bannerLogo);
    return (
      <img data-testid="bannerLogo" className={bannerLogoClassNames} src={bannerLogoUrl} alt="" />
    );
  }

  if (bannerLogoText) {
    return (
      <div data-testid="bannerLogoText" className={logoStyles.bannerLogoText}>
        {htmlUnescape(bannerLogoText)}
      </div>
    );
  }

  if (!isChinaDc && !isCiamUserFlow) {
    return (
      <AccessibleImage
        className={logoStyles.logo}
        urls={msLogoIcon}
        alt={getLocalString("General_Microsoft_Brand")}
      />
    );
  }

  return null;
};

export default BannerLogoFabric;
