import { useContext } from "react";
import { ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { type RedirectPostParams } from "../../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { trimQsParams } from "../../../../utilities/strings-helper";
import { useLoginFlowRedirect } from "../../hooks/login-hooks";

export interface IPrimaryButtonProperties {
  primaryButtonAriaDescribedBy: string;
  primaryButtonClickHandler: () => void;
}

/**
 * Hook that returns properties for the primary button shown on the IDP redirect speedbump view
 * @param lightboxTitleId The id of the lightbox title
 * @param lightboxDescriptionId The id of the lightbox description
 * @param renderBrandingDescription Whether the branding description is being rendered
 * @param brandingDescriptionId The id of the branding description
 * @param redirectUrl The url to redirect to when the primary button is clicked
 * @param redirectPostParams The params for a POST redirect request
 * @param cxhCallback optional Cxhcallback
 * @returns Properties for the primary button shown on the IDP redirect speedbump view
 */
export const usePrimaryButtonProperties = (
  lightboxTitleId: string,
  lightboxDescriptionId: string,
  renderBrandingDescription: boolean,
  brandingDescriptionId: string,
  redirectUrl: string,
  redirectPostParams: RedirectPostParams | undefined,
  cxhCallback?: () => Promise<void>,
): IPrimaryButtonProperties => {
  // Global context data
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);

  let primaryButtonAriaDescribedBy: string = "";
  if (lightboxTitleId) {
    primaryButtonAriaDescribedBy += `${lightboxTitleId} `;
  }

  if (brandingDescriptionId && renderBrandingDescription) {
    primaryButtonAriaDescribedBy += `${brandingDescriptionId} `;
  }

  if (lightboxDescriptionId) {
    primaryButtonAriaDescribedBy += `${lightboxDescriptionId}`;
  }

  primaryButtonAriaDescribedBy = primaryButtonAriaDescribedBy.trim();

  const loginFlowRedirectHandler = useLoginFlowRedirect();
  const primaryButtonClickHandler = () => {
    if (cxhCallback) {
      cxhCallback();
    }

    dispatchGlobalStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.IdpRedirectSpeedbump,
      destination: trimQsParams(redirectUrl),
    });

    loginFlowRedirectHandler(redirectUrl, redirectPostParams, true, false);
  };

  return {
    primaryButtonAriaDescribedBy,
    primaryButtonClickHandler,
  };
};
