import { useAuthContext } from "../../../../authentication-context";
import {
  loginOption as LoginOption,
  postType as PostType,
  RemoteNgcType,
} from "../../../../constants";
import GlobalConfig from "../../../../global-config";
import { useGlobalContext } from "../../../../global-context";
import { cleanseUsername } from "../../../../model/user";
import { getFidoSupport } from "../../../../utilities/browser-helper";
import LoginConfig from "../../login-config";
import { LoginState } from "../../login-constants";
import { useLoginContext } from "../../login-context";
import { type IPushNotificationsViewLoginPostProps } from "../push-notifications-view-interface";

/**
 * Returns properties for the login post form. This includes the properties used by the LoginFlowPostHiddenInputs component
 * and properties for hidden inputs that are specific to the push notifications view.
 * @returns Login post form properties
 */
export const useLoginPostFormProperties = (): IPushNotificationsViewLoginPostProps => {
  // Global config
  const {
    context,
    canaryTokenValue: canary,
    flowTokenName,
    showCookieBanner,
    unauthenticatedSessionId,
  } = GlobalConfig.instance;

  // Login config
  const {
    defaultLoginOptions,
    foundMsas,
    isFidoSupportedHint,
    postedForceSignIn,
    randomBlob,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  // Global state data
  const {
    globalState: { user },
  } = useGlobalContext();

  // Login state data
  const {
    viewState: { remoteNgcParams },
  } = useLoginContext();
  const { defaultType, entropy: displaySign, sessionIdentifier } = remoteNgcParams;

  // Authentication state data
  const {
    authState: { flowTokenValue },
  } = useAuthContext();

  const cleansedUsername = cleanseUsername(user.username?.unsafeUnescapedString);
  const displayUsername = user.displayUsername.unsafeUnescapedString;
  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  const isKmsiChecked = defaultLoginOptions === LoginOption.rememberPwd;
  const loginOption = isKmsiChecked ? LoginOption.rememberPwd : LoginOption.nothingChecked;
  const postType = PostType.remoteNGC;

  return {
    loginPostProps: {
      canary,
      cleansedUsername,
      context,
      flowTokenName,
      flowTokenValue,
      foundMsas,
      isFidoSupported,
      isKmsiChecked,
      loginOption,
      paginatedState: LoginState.RemoteNGC,
      postType,
      postedForceSignIn,
      randomBlob,
      showCookieBanner,
      displayUsername,
      rngcDefaultType: defaultType || RemoteNgcType.PushNotification,
      rngcEntropy: displaySign,
      rngcSessionIdentifier: sessionIdentifier,
    },
    unauthenticatedSessionId,
    rngcSessionIdentifier: sessionIdentifier,
  };
};
