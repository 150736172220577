import { useCallback, useContext } from "react";
import { AuthenticationContext } from "../../../../../authentication-context";
import { AuthenticationActionType } from "../../../../../authentication-reducer";
import { ApiNames, ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { useNavigateDirection } from "../../../../../hooks";
import {
  type OneTimeCodeCredential,
  CredentialType,
} from "../../../../../model/credential/credential-types";
import { proofTypeToChannel } from "../../../../../utilities/api-helpers/one-time-code/get-one-time-code-form";
import {
  type GetOtcParams,
  getOneTimeCode,
  getProofConfirmation,
  isChallengeViewSupported,
} from "../../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcPurposes,
  OtcStatus,
} from "../../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { useOtcTelemetry } from "../../../../../utilities/api-helpers/one-time-code/use-one-time-code-telemetry";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";
import { useTriggerChallenge } from "../../../../../views/challenge/hooks/use-trigger-challenge";
import { LoginContext } from "../../../login-context";
import { LoginActionType } from "../../../login-reducer";
import { type ICredentialPickerStrings } from "../credential-picker-interface";

/**
 * @returns a method for making a OneTimeCode request
 * @param credentialPickerStrings Strings for the credential picker view
 */
// TODO (send-otc): Rename this hook to indicate that the OTC request is for credential-picker (useSendOtcForCredentialPicker),
// And use the generic OTC callback: useSendOneTimeCode() (from ..\sisu-shared\hooks\use-send-otc.ts) to make the OneTimeCode API call.
export const useSendOneTimeCode = (credentialPickerStrings: ICredentialPickerStrings) => {
  const { activeFlavor } = GlobalConfig.instance;

  // Global state
  const {
    globalState: { user },
    dispatchStateChange: dispatchGlobalStateChange,
  } = useContext(GlobalContext);

  // Login state
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  // Auth state
  const {
    authState: { flowTokenValue },
    dispatchStateChange: dispatchAuthStateChange,
  } = useContext(AuthenticationContext);

  const { sessionExpiredError, sendFailedError } = credentialPickerStrings;

  const navigate = useNavigateDirection();

  const telemetryCallback = useOtcTelemetry();

  const triggerChallenge = useTriggerChallenge();

  const sendOneTimeCode = useCallback(
    (
      selectedCredential: OneTimeCodeCredential,
      setError: React.Dispatch<React.SetStateAction<string | JSX.Element>>,
    ) => {
      const { proof } = selectedCredential;
      const { isEncrypted } = proof;
      const channel = proofTypeToChannel(proof.type)!;

      const onSuccess = (responseParams: OtcSuccessParams) => {
        if (responseParams.flowToken) {
          dispatchAuthStateChange({
            type: AuthenticationActionType.SetFlowTokenValue,
            payload: responseParams.flowToken,
          });
        }

        dispatchGlobalStateChange({
          type: GlobalActionType.SetShowProgressIndicator,
          payload: false,
        });
        dispatchLoginStateChange({
          type: LoginActionType.UpdateCredentials,
          payload: {
            otcCredential: {
              credentialType: CredentialType.OneTimeCode,
              proof: selectedCredential.proof,
            },
          },
        });
        navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.OneTimeCode));
      };

      const onFailure = (responseParams: OtcFailureParams) => {
        const errorId = responseParams.otcStatus;

        if (responseParams.flowToken) {
          dispatchAuthStateChange({
            type: AuthenticationActionType.SetFlowTokenValue,
            payload: responseParams.flowToken,
          });
        }

        dispatchGlobalStateChange({
          type: GlobalActionType.SetShowProgressIndicator,
          payload: false,
        });

        if (errorId === OtcStatus.ftError) {
          setError(sessionExpiredError);
        } else if (
          errorId === OtcStatus.challengeRequiredError ||
          errorId === OtcStatus.userBlocked
        ) {
          dispatchLoginStateChange({
            type: LoginActionType.UpdateCredentials,
            payload: {
              otcCredential: {
                credentialType: CredentialType.OneTimeCode,
                proof: selectedCredential.proof,
              },
            },
          });

          triggerChallenge({ response: responseParams, apiName: ApiNames.GetOtc });
        } else {
          setError(sendFailedError);
        }
      };

      const otcParams: GetOtcParams = {
        username: user.username,
        proofData: proof.data,
        proofType: proof.type,
        purpose: proof.isNopa ? OtcPurposes.noPassword : OtcPurposes.otcLogin,
        flowToken: flowTokenValue,
        isEncrypted,
        channel,
        onSuccess,
        onFailure,
        challengeViewSupported: isChallengeViewSupported(activeFlavor),
        telemetryCallback,
      };

      if (isEncrypted) {
        otcParams.proofConfirmation = getProofConfirmation(selectedCredential, proof.type);
      }

      getOneTimeCode(otcParams);
    },
    [
      user.username,
      flowTokenValue,
      activeFlavor,
      telemetryCallback,
      dispatchGlobalStateChange,
      dispatchLoginStateChange,
      navigate,
      dispatchAuthStateChange,
      triggerChallenge,
      sessionExpiredError,
      sendFailedError,
    ],
  );

  return sendOneTimeCode;
};
