import { type ICustomizableStyles } from "../utilities/customization-types";
import { type CustomString } from "../utilities/render-promises-helper";
import { type CustomizationState } from "./customization-context";

/**
 * Action types that are accepted by the customization reducer.
 */
export enum CustomizationActionType {
  SetReadyToRender,
  SetStringPromisesResult,
  SetCustomizableStyles,
}

/**
 * Action used to set the `readyToRender` property in CustomizationContext which is used to
 * decide if the view is ready to render.
 */
type SetReadyToRenderAction = {
  type: CustomizationActionType.SetReadyToRender;
  readyToRender: boolean;
};

/**
 * Action used to set the `strings` and `isLoadFailure` property in CustomizationContext which are used to
 * override the localized strings in the page.
 */
type SetStringPromisesResultAction = {
  type: CustomizationActionType.SetStringPromisesResult;
  strings: CustomString[];
};

/**
 * Action used to set the `styles` property in CustomizationContext which is used to
 * decide the look and feel of the page.
 */
type SetCustomizableStylesAction = {
  type: CustomizationActionType.SetCustomizableStyles;
  styles: Partial<ICustomizableStyles>;
};

/**
 * Union of all actions that can be dispatched to the styles reducer to update styles state.
 */
export type CustomizationActions =
  | SetReadyToRenderAction
  | SetStringPromisesResultAction
  | SetCustomizableStylesAction;

/**
 * Sets the readyToRender property in the customization state object.
 * @param state The current customization state.
 * @param action SetReadyToRenderAction
 * @returns The updated customization state.
 */
function setReadyToRender(
  state: CustomizationState,
  action: SetReadyToRenderAction,
): CustomizationState {
  return {
    ...state,
    readyToRender: action.readyToRender,
  };
}

/**
 * Sets the string customization state result inside the customization state object.
 * @param state The current customization state
 * @param action SetRenderPromiseResultAction,
 *  payload is the StringCustomizationState with the custom strings for the flow,
 *  as well as the load complete and failure flags.
 * @returns The updated customization state.
 */
function setStringPromisesResult(
  state: CustomizationState,
  action: SetStringPromisesResultAction,
): CustomizationState {
  return {
    ...state,
    strings: action.strings,
  };
}

/**
 * Sets the customizable styles inside the customization state object.
 * @param state The current customization state
 * @param action SetCustomizationAction,
 *  payload is the StringCustomizationState with the custom strings for the flow,
 *  as well as the load complete and failure flags.
 * @returns The updated customization state.
 */
function setCustomizationStyles(
  state: CustomizationState,
  action: SetCustomizableStylesAction,
): CustomizationState {
  return {
    ...state,
    styles: {
      ...state.styles,
      ...action.styles,
    },
  };
}

/**
 * Customization state reducer.
 * @param state The current state.
 * @param action The action to perform on the current state.
 * @returns The new state.
 */
export default function customizationReducer(
  state: CustomizationState,
  action: CustomizationActions,
): CustomizationState {
  const actionType = action.type;
  switch (actionType) {
    case CustomizationActionType.SetReadyToRender:
      return setReadyToRender(state, action);
    case CustomizationActionType.SetStringPromisesResult:
      return setStringPromisesResult(state, action);
    case CustomizationActionType.SetCustomizableStyles:
      return setCustomizationStyles(state, action);
    default:
      throw new Error(`CustomizationReducer received unexpected action ${actionType}`);
  }
}
