/**
 * @deprecated Use the `ConfigWrapper` class from `sisu-shared/config`.
 * This class creates a singleton wrapper around a config object, ensuring that the object is not modified after initialization. Config Wrappers should be
 * initialized near the root of the app, outside of the component render cycle
 */
export class ConfigWrapper<T extends Record<string, unknown>> {
  private isInitialized: boolean = false;

  private defaultConfigValues: T = {} as T;

  /**
   * Construct a ConfigWrapper instance
   * @param config The *default* config object that should be used until initialize is called
   */
  constructor(private config: T) {
    this.defaultConfigValues = { ...config };
  }

  /**
   * Initialize the config wrapper
   * @param config The config object that should be wrapped (replacing the default object passed to the constructor)
   */
  initialize(config: Partial<T>) {
    // Don't throw on Storybook since multiple apps are loaded in the one webpack bundle of Storybook
    if (!IS_STORYBOOK && this.isInitialized) {
      throw new Error("Config already initialized");
    }

    this.isInitialized = true;

    this.config = { ...this.config, ...config };
  }

  /**
   * Get the current config. Config properties are READ-ONLY unless you are very sure you know what you are doing.
   * @returns The wrapped config object
   */
  get instance(): T {
    return this.config;
  }

  /**
   * Clean up the config object. This should be called once the config is no longer needed.
   */
  cleanup() {
    this.config = this.defaultConfigValues; // Create an instance of type T
    this.isInitialized = false;
  }
}
