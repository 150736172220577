/**
 * The Fabric flavor will map to this set of gct error strings
 */

export const gctStringsFabric = {
  accountDoesNotExistError: getLocalString("CouldntFindMSA_Error"),
  badUsername: getLocalString("Login_Error_LoginFailure_BadUsername"),
  invalidNumber: getLocalString("Login_PhoneNumber_InvalidNumber_Error"),
  usernameNotExistPhoneDisambig: getLocalString("Login_Error_PhoneUser_UsernameDoesNotExist"),
  usernameNotExistSignup: getLocalString("Login_Error_LoginFailure_UsernameNotExist"),
  emptyOrInvalidEmail: getLocalString("SignIn_Error_EmailMissing"),
  federationNotAllowed: getLocalString("Login_FederationNotAllowed_Error"),
  noPaUserNeedsPassword: getLocalString("Login_NopaUser_PasswordRequiredError"),
  disabledAlias: getLocalString("Login_Error_LoginFailure_AliasDisabled"),
  genericGctFailure: getLocalString("Generic_Error_GetCredentialTypeFailure"),
};
