import { type ResponseBody, type ResponseError } from "../../../constants";
import { type IDeviceProperties } from "../../../flows/login/login-context";
import { type ResponseDeviceProperties } from "../get-credential-type/get-credential-type-helper";

export interface OtcResponse extends ResponseBody {
  SasParams?: {
    Success?: boolean;
  };
  FlowToken?: string;
}

export interface OneTimeCodeJsonResponse extends OtcResponse {}

export interface OneTimeCodeJsonError extends ResponseError {
  responseBody?: OneTimeCodeJsonResponse;
}

export interface OneTimeCodeFormResponse extends OtcResponse {
  FlowToken?: string;
  State?: OtcStatus;
  DisplaySignForUI?: string;
  Devices?: ResponseDeviceProperties[];
  SessionLookupKey?: string;
  UserCode?: string;
  ChallengeType?: string;
  ArkoseDataExchangeBlob?: string;
  PhoneRepMetadata?: string;
}

export type OtcSuccessParams = {
  flowToken?: string;
  response: OneTimeCodeJsonResponse | OneTimeCodeFormResponse;
  displaySign?: string;
  devices?: IDeviceProperties[];
  sessionLookupKey?: string;
  userCode?: string;
};

export type OtcFailureParams = ResponseError & {
  flowToken?: string;
  otcStatus?: number;
  challengeType?: string;
  arkoseDataExchangeBlob?: string;
  phoneRepMetadata?: string;
};

export type BaseOtcHelperParams = {
  flowToken: string;
};

export enum OtcRequestParams {
  username = "login",
  purpose = "purpose",
  flowToken = "flowtoken",
  canaryFlowToken = "canaryFlowToken",
  channel = "channel",
  uiMode = "UIMode",
  phoneCountry = "MobileCountry",
  phoneCountryCode = "MobileCC",
  unauthSessionId = "uaid",
  proofConfirmation = "ProofConfirmation",
  challengeSolution = "ChallengeSolution",
  challengeType = "ChallengeType",
  challengeId = "ChallengeId",
  phoneRepMetadata = "PhoneRepMetadata",
  challengeViewSupported = "ChallengeViewSupported",
  SAPId = "SAPId",
}

export enum OtcPurposes {
  password = "eOTT_OneTimePassword",
  remoteNGC = "eOTT_RemoteNGC",
  noPassword = "eOTT_NoPasswordAccountLoginCode",
  otcLogin = "eOTT_OtcLogin",
  xboxRemoteConnect = "RemoteSignInWithUserCode",
}

export enum OtcChannel {
  authenticator = "Authenticator",
  mobileSms = "SMS",
  emailAddress = "Email",
  voiceCall = "Voice",
  pushNotifications = "PushNotifications",
}

export enum OtcType {
  emailAddress = "AltEmail",
  emailAddressEncrypted = "AltEmailE",
  mobile = "MobileNum",
  mobileEncrypted = "MobileNumE",
  sessionApprover = "SAPId",
}

export enum OtcProperties {
  state = "State",
  sessionLookupKey = "SessionLookupKey",
  displaySignForUI = "DisplaySignForUI",
  flowToken = "FlowToken",
  devices = "Devices",
  userCode = "UserCode",
  challengeType = "ChallengeType",
  arkoseDataExchangeBlob = "ArkoseDataExchangeBlob",
  phoneRepMetadata = "PhoneRepMetadata",
}

export enum OtcStatus {
  none = 0,
  error = 200,
  success = 201,
  hipError = 202,
  ftError = 203,
  inputError = 204,
  destinationError = 205,
  timeout = 300,
  challengeRequiredError = 2147759525,
  challengeVerificationError = 2147759526,
  userBlocked = 2147759527,
  metaDataExpired = 2147759537,
}
