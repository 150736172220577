import React from "react";
import { type AccessibleImageProps, AccessibleImage } from "../../accessible-image";
import { type IButtonProps } from "../../button/button-interface-fabric";
import { ButtonFabric } from "../../button/fabric/button-fabric";
import { type IButtonFabricProps } from "../../button/fabric/button-fabric-interface";

export type IImageButtonFabricProps = IButtonProps &
  IButtonFabricProps & {
    /** The props for the accessible image */
    image: AccessibleImageProps;
    /** Whether or not the image appears after the content */
    imgAfterContent?: boolean;
  };

/**
 * Image Button component for fabric
 * @param iImageButtonFabricProps The properties for this component
 * @returns an instance of the image button component
 */
export const ImageButtonFabric = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<IImageButtonFabricProps>
>((props, forwardRef) => {
  const { children, image, imgAfterContent = false, ...buttonProps } = props;

  return (
    <ButtonFabric {...buttonProps} ref={forwardRef}>
      {!imgAfterContent && <AccessibleImage {...image} />}
      {children}
      {imgAfterContent && <AccessibleImage {...image} />}
    </ButtonFabric>
  );
});
