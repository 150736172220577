import { type ResponseError } from "../constants";
import GlobalConfig from "../global-config";
import type { TelemetryState } from "../global-context";
import { type TelemetryEvents, TelemetryEventType } from "./telemetry-helper";

export enum GenericErrorCodes {
  BlankPageError = "BlankPageError",
}

export type LoggedError = (Error | ErrorEvent) & {
  isLogged?: boolean;
};

export const ExceptionHelper = {
  clientErrors: [] as string[],

  /** @private */
  isResponseError(error: ErrorEvent | Error | string): error is ResponseError {
    return (error as ResponseError) !== undefined;
  },

  /**
   * Creates 1ExceptionEvent object from an error object.
   * @param {ErrorEvent | Error | string} error Error object to extract exception data.
   * @param {TelemetryState} telemetryState Current telemetry state for logging.
   * @param {string} name error name to override the name in error object.
   * @returns TelemetryEvents data of type 1ExceptionEvent.
   */
  getExceptionData(
    error: ErrorEvent | Error | string,
    telemetryState?: TelemetryState,
    name?: string,
  ): TelemetryEvents {
    // Unused vars to be used once PII scrubbing enabled
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { dimensions } = telemetryState || {};

    let errorType = "initial";
    let errorName = "";
    let message = "";
    let stack = "";
    let source = "";

    if (error instanceof ErrorEvent) {
      message = error.error?.message || error.message;
      errorName = name || error.error?.name || error.message;
      stack = error.error?.stack || "";
      source = `${error.filename}:${error.lineno}:${error.colno}`;
    } else if (error instanceof Error) {
      message = error.message;
      errorName = name || error.name;
      stack = error.stack || "";
    } else {
      message = error;
      errorName = name || error;
    }

    if (
      message &&
      (message.toLowerCase().indexOf("failed to load external resource") !== -1 ||
        message.toLowerCase().indexOf("failed to load resource") !== -1)
    ) {
      errorType = "CDN download error";
    }

    if (ExceptionHelper.clientErrors.some((msg: string) => msg === message)) {
      errorType = "subsequent";
    } else {
      ExceptionHelper.clientErrors.push(message);
    }

    const data: TelemetryEvents = {
      _table: TelemetryEventType.Exception,
      message,
      errorName,
      stack,
      source,
      currentState: { errorType },
      dimensions: dimensions!,
    };

    if (this.isResponseError(error)) {
      try {
        data.currentState.responseBody = JSON.stringify(error.responseBody);
      } catch (err: unknown) {
        data.currentState.responseBody = `Error parsing response body: ${(err as Error).message}`;
      }
    }

    return data;
  },

  /**
   * Detetemines whether to ignore an exception and not send telemetry data.
   * Exceptions to ignore result from browser, plugins or other external code.
   * @param {Error} error Error object to check if to ignore.
   * @param {string} [src] String containing source or url of error.
   * @returns {boolean} True if exception is to be ignored.
   */
  ignoreException(error: Error, src?: string | undefined): boolean {
    if (src) {
      const url = new URL(src);

      if (url.host === window.location.host) {
        return false;
      }
    } else if (error.stack === undefined || error.stack?.includes(window.location.host)) {
      return false;
    }

    return true;
  },

  /**
   * This method is used to determine if an exception has already been noted as being logged and shouldn't be logged again
   * @param error Exception to check
   * @returns indicator the parameter has a "isLogged" property that is true, meaning it's already been logged
   */
  isErrorLogged(error: ErrorEvent | Error | LoggedError) {
    return "isLogged" in error && !!error.isLogged;
  },

  /**
   * Logs an exception
   * @param {ErrorEvent | Error | string} error Exception to log
   * @param {TelemetryState} telemetryState Current telemetry state for logging.
   * @param {string} errorName error name to override the name in error object.
   */
  logException(
    error: ErrorEvent | Error | LoggedError | string,
    telemetryState?: TelemetryState,
    errorName?: string,
  ) {
    const exceptionData: TelemetryEvents = ExceptionHelper.getExceptionData(
      error,
      telemetryState,
      errorName,
    );
    GlobalConfig.instance.telemetry.addEvent(exceptionData);
  },

  /**
   * Logs telemetry when a page is found to be blank.
   * @param {TelemetryState} telemetryState Current telemetry state for logging.
   */
  logPageIsBlankError(telemetryState: TelemetryState) {
    const error = new Error(GenericErrorCodes.BlankPageError);
    const exceptionData: TelemetryEvents = ExceptionHelper.getExceptionData(error, telemetryState);
    GlobalConfig.instance.telemetry.addEvent(exceptionData);
  },
};
