import { makeStyles, shorthands } from "@griffel/react";

export const useDfpIframeStyles = makeStyles({
  iframe: {
    ...shorthands.border("0"),
    color: "#000000",
    visibility: "hidden",
    position: "absolute",
    width: "1px",
    height: "1px",
    left: "-10000px",
    top: "-10000px",

    ":global([dir='rtl'])": {
      left: "unset",
      right: "-10000px",
    },
  },
});
