import { makeStyles } from "@griffel/react";

export const useInlineImageStylesFabric = makeStyles({
  icon: {
    position: "relative",
    width: "8px",
    height: "8px",
  },
});

export type InlineImageStylesFabric = ReturnType<typeof useInlineImageStylesFabric>;
