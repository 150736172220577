import {
  type AuthenticationState,
  createAuthenticationState,
} from "../../../authentication-context";
import { type ChallengeState, createChallengeState } from "../../../challenge-context";
import { initAcmaConfig } from "../../../config/acma-config";
import { type Flavors, FlowId } from "../../../constants";
import { type CustomizationState } from "../../../context/customization-context";
import { initBaseSisuAppDataFabric } from "../../../context/sisu-providers";
import featuresConfig from "../../../features-config";
import { initLoginStrings } from "../../../flows/login/init-login-strings";
import { initLoginConfig } from "../../../flows/login/login-config";
import { type LoginState, createLoginState } from "../../../flows/login/login-context";
import { type GlobalState } from "../../../global-context";
import { getUpdatedUserFlowType } from "../../../global-reducer";
import { type HistoryState } from "../../../history-context";
import { initGctStrings } from "../../../utilities/api-helpers/get-credential-type/init-gct-strings";
import { extractQueryStringParams } from "../../../utilities/query-params-helper";
import type { ServerData } from "../../../utilities/server-data";
import { getUserOptions } from "./get-user-options";

/**
 * Parse the ServerData object sent from the IDP and return the initial config/state for the app contexts.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The IDP-specific ServerData object sent from the server and read from the window object.
 * @param activeFlavor Rendered flavor
 * @returns The initial state for the login, global and authentication contexts.
 */
export function initLoginAppFabric(
  serverData: ServerData,
  activeFlavor: Flavors,
): {
  initialAuthenticationState: AuthenticationState;
  initialGlobalState: GlobalState;
  initialLoginState: LoginState;
  initialHistoryState: HistoryState;
  initialCustomizationState: CustomizationState;
  initialChallengeState: ChallengeState;
} {
  const { initialGlobalState, initialHistoryState, initialCustomizationState } =
    initBaseSisuAppDataFabric(serverData, activeFlavor, FlowId.Login, true, getUserOptions);

  const { isSimplifiedChildAccountCreation } = featuresConfig.instance;
  if (isSimplifiedChildAccountCreation) {
    const queryParams = { userFlowType: "uft" };
    const { userFlowType } = extractQueryStringParams(queryParams);
    initialGlobalState.userFlowType = getUpdatedUserFlowType(
      initialGlobalState.userFlowType,
      userFlowType,
    );
  }

  initAcmaConfig();
  initLoginConfig(serverData);
  initGctStrings(activeFlavor);
  initLoginStrings(activeFlavor);

  const initialLoginState = createLoginState(serverData);
  const initialAuthenticationState = createAuthenticationState(serverData);
  const initialChallengeState = createChallengeState(serverData);

  return {
    initialAuthenticationState,
    initialGlobalState,
    initialHistoryState,
    initialLoginState,
    initialCustomizationState,
    initialChallengeState,
  };
}
