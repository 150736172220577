import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import {
  type FloatingDropdownFabricProps,
  FloatingDropdownFabric,
} from "../../../../components/inputs/dropdown/fabric/floating-dropdown-fabric";
import StylesConfig from "../../../../config/styles-config";
import GlobalConfig from "../../../../global-config";
import { GlobalContext } from "../../../../global-context";
import { useTelemetry } from "../../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../../telemetry-helpers/user-action-name";
import { FormattedTextWithBindings } from "../../../../utilities/formatted-text-with-bindings";
import { useSignupClickHandler } from "../../hooks/login-hooks";
import { usernameViewStringsFabric as usernameViewStrings } from "../../views/username/fabric/username-view-strings-fabric";
import { useSignupForMyChildHandler } from "../hooks/use-signup-for-my-child-handler";

export type CreateAccountDropdownProps = {
  /** The username for the sign up link */
  username: string;
  /** The create account dropdown properties */
  createAccountProps?: FloatingDropdownFabricProps;
  /** Additional css classes that can be passed to the input. */
  customMenuCss?: string;
  /** Additional css classes that can be passed to the input. */
  customChevronCss?: string;
  /** Whether or not focus is set on the component. (default is false) */
  hasInitialFocus?: boolean;
  /** Whether or not open is set on the component. (default is false) */
  isInitialOpen?: boolean;
};

/**
 * CreateAccountDropdownFabric component
 * @param props The properties for this component
 * @returns A dropdown component for selection account creation flow path for Fabric
 */
export const CreateAccountDropdownFabric: React.FC<CreateAccountDropdownProps> =
  function CreateAccountDropdownFabric(props) {
    const {
      customMenuCss,
      customChevronCss,
      hasInitialFocus,
      isInitialOpen,
      createAccountProps,
      username,
    } = props;
    const {
      loginSignupLink: signupLinkText,
      signupForMyselfLink,
      signupForMyChildLink,
      createAccountDropdownAriaLabel,
    } = usernameViewStrings;
    const onForMyselfClicked = useSignupClickHandler();
    const onClickForMyself = () => onForMyselfClicked(username);

    const onForMyChildClicked = useSignupForMyChildHandler();
    const onClickForMyChild = () => onForMyChildClicked(username);

    const createAccountOptions = [
      {
        text: signupForMyselfLink,
        value: "myself",
        onClick: onClickForMyself,
      },
      {
        text: signupForMyChildLink,
        value: "child",
        onClick: onClickForMyChild,
      },
    ];

    const { useLinkButtonStyles } = StylesConfig.instance;
    const linkStyles = useLinkButtonStyles();
    const signupLink = (chunks: string[]) => (
      <span
        className={mergeClasses(linkStyles.link, linkStyles.noPadding, linkStyles.highContrast)}
      >
        {chunks[0]}
      </span>
    );

    const { activeFlavor, telemetry } = GlobalConfig.instance;
    const {
      globalState: { activeView, activeFlow, userFlowType },
    } = useContext(GlobalContext);

    const { logUserAction } = useTelemetry(telemetry, {
      activeView,
      activeFlow,
      activeFlavor,
    });

    const onSignupClicked = () => {
      logUserAction({
        actionName: UserActionName.SignupLinkClicked,
        actionValue: `UserFlowType: ${userFlowType}`,
      });
    };

    return (
      <FloatingDropdownFabric
        {...createAccountProps}
        dataTestId="createAccountDropdown"
        dropdownMenu={{
          selectOptions: createAccountOptions,
          isInitialOpen,
          "aria-describedby": "createAccountDropdownMenuError",
          customCss: customMenuCss,
          "aria-label": createAccountDropdownAriaLabel,
          id: "createAccountDropdownMenu",
        }}
        dropdownButton={{
          className: customChevronCss,
          onClick: onSignupClicked,
          hasFocus: hasInitialFocus,
        }}
      >
        <div>
          <FormattedTextWithBindings text={signupLinkText} embeddedBindings={{ signupLink }} />
        </div>
      </FloatingDropdownFabric>
    );
  };
