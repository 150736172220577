import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../config/styles-config";
import { useCustomizationContext } from "../../context/customization-context";

/**
 * @param root Root props
 * @param root.children Input components to contain with dynamic styling
 * @returns A div containing the CSS required based on the usage of BoilerPlateText
 */
export const InputContainer: React.FC = function InputContainer({ children }) {
  const {
    customizationState: {
      styles: { boilerPlateText },
    },
  } = useCustomizationContext();
  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const buttonPositionClasses = mergeClasses(
    commonStyles.positionButtons,
    boilerPlateText
      ? commonStyles.inputContainerWithBoilerPlate
      : commonStyles.inputContainerWithoutBoilerPlate,
  );

  return (
    <div data-testid="inputContainer" className={buttonPositionClasses}>
      {children}
    </div>
  );
};
