import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName, LayoutTemplateType } from "../../../constants";
import { useCustomizationContext } from "../../../context/customization-context";

/**
 * This method adds necessary app branding overrides to styles as needed to avoid redundant logic
 * @param alwaysShowBackground - Provide customizationState.styles.useAppBranding
 * @param layoutTemplate - Provide customizationState.styles.layoutTemplate
 * @returns Background styles to use directly on background elements
 */
export const useBackgroundStylesFabric = function useBackgroundStylesFabric(
  alwaysShowBackground: boolean,
  layoutTemplate: LayoutTemplateType,
) {
  const { useBackgroundStyles: useBaseBackgroundStyles } = StylesConfig.instance;
  const styles = useBaseBackgroundStyles();
  return {
    ...styles,
    background: mergeClasses(
      styles.baseBackground,
      layoutTemplate === LayoutTemplateType.VerticalSplit
        ? styles.verticalSplitBackground
        : styles.normalBackground,
      alwaysShowBackground ? styles.alwaysShow : "",
    ),
  };
};

/**
 * Background image component
 *  - Returns null if no `backgroundImageUrl` is defined in `customizationState.styles`
 *  - Contains a background image div which flexes styling based on `customizationState.styles.alwaysShowBackground`
 *  - May contain a background overlay / image mask based on `customizationState.styles.userBackgroundImageMask`
 * @returns The React component or null
 */
export const BackgroundImageFabric: React.FC = function BackgroundImageFabric() {
  const {
    customizationState: {
      styles: { backgroundImageUrl, alwaysShowBackground, layoutTemplate, useBackgroundImageMask },
    },
  } = useCustomizationContext();
  const backgroundStyles = useBackgroundStylesFabric(alwaysShowBackground, layoutTemplate);

  if (!backgroundImageUrl) {
    return null;
  }

  const backgroundImageClassNames = mergeClasses(
    backgroundStyles.background,
    ExternalClassName.backgroundImage,
  );
  const overlayClassNames = mergeClasses(
    backgroundStyles.overlay,
    ExternalClassName.backgroundOverlay,
  );

  return (
    <div
      data-testid="backgroundImage"
      id="background-image"
      className={backgroundImageClassNames}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      {useBackgroundImageMask ? (
        <div data-testid="backgroundOverlay" className={overlayClassNames} />
      ) : null}
    </div>
  );
};
