import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

export const useDebugDetailsBannerStylesFabric = makeStyles({
  banner: {
    width: "100%",
    display: "table",
    tableLayout: "fixed",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#1B1B1B",
    backgroundColor: "#ffffff",
    ...shorthands.padding(
      `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      `${styleConstants.LIGHTBOX_PADDING}px`,
    ),
    fontSize: "13px",
    maxWidth: `${styleConstants.LIGHTBOX_MAX_WIDTH}px`,
    minWidth: `${styleConstants.LIGHTBOX_MIN_WIDTH}px`,
    WebkitBoxShadow: styleConstants.LIGHTBOX_SHADOW,
    "-moz-box-shadow": styleConstants.LIGHTBOX_SHADOW,
    boxShadow: styleConstants.LIGHTBOX_SHADOW,
    marginBottom: `${styleConstants.FOOTER_HEIGHT}px`,

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "#f2f2f2",
      ...shorthands.padding(`${styleConstants.LIGHTBOX_MOBILE_PADDING}px`),
      WebkitBoxShadow: "none",
      "-moz-box-shadow": "none",
      boxShadow: "none",
      tableLayout: "auto",
    },
  },
  bannerExtendedFooter: {
    marginBottom: `${styleConstants.EXTENDED_FOOTER_HEIGHT}px`,
  },
  bannerVerticalSplit: {
    paddingLeft: "14px",
    paddingRight: "14px",
    tableLayout: "auto",
  },
  tableRow: {
    display: "table-row",
  },
  tableCell: {
    display: "table-cell",
    verticalAlign: "middle",
    ":first-child": {
      width: "100%",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  overrideLtr: {
    "text-align": "left",
  },
  header: {
    marginBottom: "10px",
  },
  headingText: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  traceSection: {
    marginTop: "10px",
  },
  detailsNotification: {
    marginLeft: "5px",
    color: "#107C10",
  },
  copyLink: {
    "text-decoration": "none",
    fontSize: "13px",
  },
  closeButton: {
    ...shorthands.border("0"),
    backgroundColor: "transparent",
    cursor: "pointer",
    minWidth: "fit-content",
    ...shorthands.padding("0"),
  },
  wide: {
    maxWidth: `${styleConstants.LIGHTBOX_WIDE_MAX_WIDTH}px`,
  },
});

export type DebugDetailsBannerStylesFabric = ReturnType<typeof useDebugDetailsBannerStylesFabric>;
