import { useContext } from "react";
import { GlobalContext } from "../../../../../global-context";
import {
  type ConfirmSendCredential,
  type ConfirmSendCredentialTypes,
  type UserCredential,
  CredentialType,
} from "../../../../../model/credential";
import { ProofType } from "../../../../../model/proof";
import { type IUser } from "../../../../../model/user";
import { htmlEscape, replaceTokens } from "../../../../../utilities/strings-helper";
import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";
import { getDescriptionString } from "./get-dynamic-strings";

/**
 * This hook is used to determine the description text to use in the ConfirmSendView
 * @param currentCredential The current credential being used
 * @param preferredCredential The preferred credential for the user
 * @param user The reference to the user
 * @param hasError Whether there has been an error using the GetOneTimeCode API
 * @param proofType The proof type of the user's current credential
 * @param confirmSendViewStrings Strings for the confirm send view
 * @returns The description text to use in the ConfirmSendView
 */
export const useDescription = (
  currentCredential: ConfirmSendCredential,
  preferredCredential: ConfirmSendCredentialTypes,
  user: IUser,
  hasError: boolean,
  proofType: ProofType,
  confirmSendViewStrings: ConfirmSendViewStrings,
): string => {
  const { confirmSendDescriptionOtcSendErrorEmail, confirmSendDescriptionOtcSendError } =
    confirmSendViewStrings;

  const isEmailDestination = proofType === ProofType.Email;
  const {
    globalState: {
      debugInfo: { errorCode },
    },
  } = useContext(GlobalContext);

  if (currentCredential.credentialType === CredentialType.OneTimeCode) {
    if (hasError) {
      if (isEmailDestination) {
        return confirmSendDescriptionOtcSendErrorEmail;
      }

      return confirmSendDescriptionOtcSendError;
    }

    return replaceTokens(
      getDescriptionString(
        confirmSendViewStrings,
        preferredCredential,
        isEmailDestination,
        errorCode,
      ),
      currentCredential.proof.display,
    );
  }

  return replaceTokens(
    getDescriptionString(
      confirmSendViewStrings,
      preferredCredential,
      isEmailDestination,
      errorCode,
    ),
    htmlEscape(user.displayUsername.unsafeUnescapedString),
  );
};

export type UseOneTimeCodeParams = {
  availableCredentials: Array<UserCredential>;
  currentCredential: ConfirmSendCredential;
  preferredCredential: ConfirmSendCredentialTypes;
  proofType: ProofType;
  requestIsPending: boolean;
  setButtonText: React.Dispatch<React.SetStateAction<string>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setRequestIsPending: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUser;
};
