/**
 * Helper function to get a UTC string
 * @returns The current time in UTC (ISO formatted)
 */
export function getUTCString(): string {
  return new Date().toISOString();
}

/**
 * Helper function to retrieve the current time
 * @returns The current time in milliseconds
 */
export function getCurrentTimeMs(): number {
  return new Date().getTime();
}

/**
 * Determine if a year is a leap year
 * @param year The year to check
 * @returns true if the year is a leap year, false otherwise
 */
export const isLeapYear = (year: number) =>
  year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

/**
 * Determine the number of days a month had in a given year.
 * Year is required to check for leap years.
 * @param year The year the month is in in the format YYYY
 * @param month The month to get the number of days
 * @returns The number of day the month had in the given year
 */
export const getDaysInMonth = (year: number, month: number) => {
  if (month < 1 || month > 12) {
    throw new Error("Month must be between 1 and 12");
  }

  const daysInFebruary = isLeapYear(year) ? 29 : 28;
  return [31, daysInFebruary, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
};
