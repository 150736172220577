import React, { useEffect, useRef } from "react";
import { mergeClasses } from "@griffel/react";
import FooterConfig from "../../../components/footer/footer-config";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../components/text-button/fabric/text-button-fabric";
import StylesConfig from "../../../config/styles-config";
import { useCustomizationContext } from "../../../context/customization-context";
import { useBackButtonHandlerWithCxh } from "../../../hooks/win11-oobe-fabric/use-back-button-handler";
import { FOCUS_TIMEOUT } from "../../../styles/fabric/layout-animate-fabric.styles";
import { type AgreementViewProps, AgreementType } from "../agreement-view-interface";
import {
  getButtonUsage,
  hasChildStyling,
  isChinaAgreementType,
  isKoreaAgreementType,
} from "../agreement-view-util";
import { useAgreementView } from "../use-agreement-view";
import { AgreementViewStringsConfigFabric } from "./agreement-view-strings-config-fabric";

/**
 * A shared view that displays the agreement text for hosted screens. Accessed via a link on the lightbox-footer component.
 * @param props The props for this component
 * @param props.hostingFlow The flow that this agreement view is hosted in
 * @returns A rendered instance of this shared view
 */
export const AgreementViewFabric: React.FC<AgreementViewProps> = function AgreementViewFabric({
  hostingFlow,
}) {
  const { footerImpressumUrl, footerA11yConformeUrl } = FooterConfig.instance;

  const {
    customizationState: {
      styles: { termsOfUseUrl, privacyUrl },
    },
  } = useCustomizationContext();

  const agreementDiv = "idDiv_iAgreement";

  const {
    agreementViewStrings: { generalVisitLink, genericErrorNoResponseTryAgain, generalBack },
  } = AgreementViewStringsConfigFabric.instance;

  const { agreementType, downloadError, agreementHtml, onBack } = useAgreementView({
    footerA11yConformeUrl,
    footerImpressumUrl,
    generalVisitLink,
    hostingFlow,
    privacyUrl,
    termsOfUseUrl,
    isWideAgreementView: (agreeType) =>
      !isKoreaAgreementType(agreeType) && !isChinaAgreementType(agreeType),
  });

  const { useAgreementViewStyles, useCommonStyles, useTextButtonContainerStyles } =
    StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const agreementViewStyles = useAgreementViewStyles();
  const { agreementLayout } = useTextButtonContainerStyles();

  const { showTopButton, showBottomButton } = getButtonUsage(agreementType);
  const bottomButtonReference = useRef<HTMLButtonElement>(null);
  const topButtonReference = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Reset focus on the button. This is necessary because the agreements share the same route via the react-router link component.
    // If a user navigates between agreements or within same agreement, react-router does not unmount the component and mount a new instance.
    setTimeout(() => {
      const elementRef = showTopButton
        ? topButtonReference?.current
        : bottomButtonReference?.current;
      elementRef?.focus();
    }, FOCUS_TIMEOUT);
  }, [showTopButton, topButtonReference, bottomButtonReference]);

  useBackButtonHandlerWithCxh(onBack);

  return (
    <div className={commonStyles.section} data-testid="agreements">
      {downloadError && (
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
          <div
            id="error"
            role="alert"
            aria-live="assertive"
            aria-relevant="text"
            aria-atomic="true"
          >
            {genericErrorNoResponseTryAgain}
          </div>
        </div>
      )}
      {showTopButton && (
        <TextButtonContainer customCss={agreementLayout}>
          <TextButtonFabric
            label={generalBack}
            onClick={onBack}
            hasFocus
            ariaDescribedBy={agreementDiv}
            ref={topButtonReference}
            dataTestId="viewAgreementTopBack"
          />
        </TextButtonContainer>
      )}
      {agreementHtml && (
        <>
          <pre
            className={mergeClasses(
              commonStyles.textBody,
              commonStyles.textBlockBody,
              agreementViewStyles.layout,
              hasChildStyling(agreementType) ? agreementViewStyles.childStyling : "",
              agreementType === AgreementType.ChinaPiplLearnMore
                ? agreementViewStyles.chinaPipl
                : "",
            )}
            id={agreementDiv}
            role="alert"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: agreementHtml }}
          />

          {showBottomButton && (
            <div className={commonStyles.winButtonPinBottom}>
              <TextButtonContainer customCss={showTopButton ? agreementLayout : ""}>
                <TextButtonFabric
                  label={generalBack}
                  onClick={onBack}
                  ariaDescribedBy={agreementDiv}
                  hasFocus={!showTopButton}
                  buttonId="viewAgreementBack"
                  ref={bottomButtonReference}
                  dataTestId="viewAgreementBottomBack"
                />
              </TextButtonContainer>
            </div>
          )}
        </>
      )}
    </div>
  );
};
