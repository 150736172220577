import React from "react";
import StylesConfig from "../../../config/styles-config";
import { type AccessibleImageProps, AccessibleImage } from "../../accessible-image";
import type { ITitleProps } from "../title-interface-fabric";

export type ITitleFabricProps = ITitleProps & {
  /** The subtitle beneath the title */
  subtitle?: string;
  /** Whether to display the subtitle */
  showSubtitle?: boolean;
  /** Icon to show to the side of the title */
  inlineIcon?: AccessibleImageProps;
  /** Choose the style of title is bold or not */
  isSecondary?: boolean;
};

/**
 * Title component for Fabric
 * @param props ITitleFabricProps properties for this component
 * @returns An instance of this component
 */
export const TitleFabric: React.FC<ITitleFabricProps> = function TitleFabric(props) {
  const {
    title,
    titleId,
    role = "heading",
    ariaLevel = 1,
    subtitle,
    showSubtitle,
    inlineIcon,
    isSecondary,
  } = props;
  const { useTitleStyles } = StylesConfig.instance;
  const titleStyles = useTitleStyles();
  const showSubtitleFlag = showSubtitle !== false && subtitle;
  const titleClassName = isSecondary ? titleStyles.secondaryTitle : titleStyles.title;

  return (
    <div className={titleClassName}>
      {inlineIcon && (
        <div className={titleStyles.inlineIcon}>
          <AccessibleImage {...inlineIcon} />
        </div>
      )}
      <div className={inlineIcon && titleStyles.inlineTitle}>
        <div role={role} aria-level={ariaLevel} id={titleId}>
          {title}
        </div>
        {showSubtitleFlag && <div className={titleStyles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
};
