import { ViewId } from "../../../../../constants/routing-constants";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";

/**
 * @param setInputValue the React dispatch function used to control the value of the proof confirmation input textbox
 * @returns a click handler for the link that says "I have a code" and that takes the user to the one time code view.
 */
export const useSwitchToOtcLinkClickHandler = (setInputValue: (value: string) => void) => {
  const navigate = useNavigateDirection();

  return () => {
    setInputValue("");

    navigate(ViewId.ProofConfirmation, getRouteFromViewId(ViewId.OneTimeCode));
  };
};
