import { useLocation } from "react-router-dom";

export const locationCache: Map<string, unknown> = new Map();

/**
 * This is used to clear the cache for Storybook and testing purposes.
 * You should NEVER call this in production code.
 */
export const clearLocationCache = function clearLocationCache() {
  locationCache.clear();
};

/**
 * This method is used to cache a value using the location key of the current location (an instance of a Route/View).
 * This can only be done once for a Route/View using only the location key, so if multiple values need to be stored you can provide an additional key to avoid collisions.
 * This should be used when a certain View is updating context on navigation that is used by the View, but the change is meant only for Views navigating forward.
 * This will retain the value for the View when a user navigates back.
 * @param stateToCache The value to cache
 * @param additionalKey An additional key to use to avoid collisions caching multiple values on the same location.
 * @returns the cached state
 */
export const useLocationCache = function useLocationCache<T>(
  stateToCache: T,
  additionalKey: string = "",
): T {
  const location = useLocation();
  const key = location.key + additionalKey;

  if (!locationCache.has(key)) {
    locationCache.set(key, stateToCache);
  }

  return locationCache.get(key) as T;
};
