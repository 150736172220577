import { appendOrReplaceQueryStringParams } from "../../../../../utilities/strings-helper";

/**
 * Appends the needed parameters to the iFrameUrl from server data
 * @param iFrameUrl url of Iframe
 * @param sessionId id of session
 * @param shouldForgetUser should user be forgotten or not
 * @returns account picker frame source
 */
export const getAccountPickerIframeSource = (
  iFrameUrl: string,
  sessionId?: string,
  shouldForgetUser?: boolean,
) => {
  let iFrameSrc = iFrameUrl;
  if (sessionId) {
    iFrameSrc = appendOrReplaceQueryStringParams(
      iFrameSrc,
      {
        sessionId: encodeURIComponent(sessionId),
        ...(shouldForgetUser ? { shouldForgetUser: shouldForgetUser.toString() } : {}),
      },
      true,
    );
  }

  return iFrameSrc;
};
