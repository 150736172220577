import { useCallback, useContext } from "react";
import { GlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";

export const useUpdateBackArrowButton = () => {
  const { dispatchStateChange: dispatchGlobal } = useContext(GlobalContext);

  return useCallback(
    (isSupported) =>
      dispatchGlobal({
        type: GlobalActionType.HideBackArrowButton,
        payload: !isSupported,
      }),
    [dispatchGlobal],
  );
};
