import { makeStyles } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type SecondaryContentStylesFabric, useSecondaryContentStylesFabric } from "../fabric";

const useOverrideSecondaryContentStyles = makeStyles({
  containerLayout: { paddingLeft: "0px", paddingRight: "0px" },
});

export type SecondaryContentStylesWin11OobeFabric = SecondaryContentStylesFabric &
  Partial<ReturnType<typeof useOverrideSecondaryContentStyles>>;

export const useSecondaryContentStylesWin11OobeFabric = () =>
  useMergedStyles(
    useSecondaryContentStylesFabric(),
    useOverrideSecondaryContentStyles(),
  ) as SecondaryContentStylesWin11OobeFabric;
