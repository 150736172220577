import React, { useContext, useEffect } from "react";
import { mergeClasses } from "@griffel/react";
import { SecondaryContentContainerFabric } from "../../../../components";
import { LinkButton } from "../../../../components/link-button";
import ProgressIndicatorFabric, {
  ProgressIndicatorContainerFabric,
} from "../../../../components/loading-progress/fabric/progress-indicator-fabric";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import StylesConfig from "../../../../config/styles-config";
import { FlowId, RemoteNgcType, ViewId } from "../../../../constants";
import { GlobalContext } from "../../../../global-context";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import { useEffectOnce } from "../../../../hooks/use-effect-once";
import { FOCUS_TIMEOUT } from "../../../../styles/fabric/layout-animate-fabric.styles";
import { CredentialSwitchContentFabric } from "../../components/fabric/credential-switch-content-fabric";
import {
  commonLoginStringsFabric,
  loginInputValidationErrorStringsFabric,
} from "../../fabric/common-login-strings-fabric";
import {
  useBrandingDescriptionProperties,
  useShowCredentialSwitchContent,
} from "../../hooks/login-hooks";
import { LoginContext } from "../../login-context";
import { LoginFlowPostHiddenInputs } from "../../login-flow-post-hidden-inputs";
import { getServerErrorText } from "../../login-util";
import { useCredentialSwitchProperties } from "../hooks/use-credential-switch-properties";
import { useDialogProperties } from "../hooks/use-dialog-properties";
import { useLoginPostFormProperties } from "../hooks/use-login-post-form-properties";
import { useLostAuthenticatorLink } from "../hooks/use-lost-authenticator-link";
import { usePushNotificationsViewProperties } from "../hooks/use-push-notifications-view-properties";
import { getAriaDescribedByProperties } from "../push-notifications-view-util";
import { PushNotificationsDialogFabric } from "./push-notifications-dialog-fabric";
import { pushNotificationsViewStringsFabric } from "./push-notifications-view-strings-fabric";

/**
 * PushNotificationsView component
 * @returns A rendered instance of this component
 */
export const PushNotificationsViewFabric: React.FC = function PushNotificationsViewFabric() {
  // Styles config data
  const { useCommonStyles, usePushNotificationsViewStyles } = StylesConfig.instance;

  const {
    devices,
    displaySign,
    displaySignRef,
    documentTitle,
    error,
    formRef,
    gifUrl,
    handleServerError,
    isRequestPending,
    lostAppAccessLinkText,
    primaryButtonLabel,
    pollingDescription,
    postUrl,
    remoteNgcType,
    resendNotificationText,
    seeAllDevicesLinkText,
    serverErrorCode,
    seeAllDevicesLinkFocus,
    setIsRequestPending,
    setSeeAllDevicesLinkFocus,
    setupAndStartPolling,
    showAnimatedGifWhilePolling,
    showBackArrowButton,
    showButtons,
    showSeeAllDevicesLink,
    showSeeAllDevicesLinkBelowGif,
    showLostAppAccessLink,
    title,
  } = usePushNotificationsViewProperties({
    pushNotificationsViewStrings: pushNotificationsViewStringsFabric,
    commonLoginStrings: commonLoginStringsFabric,
  });

  const { brandingDescription, brandingDescriptionId, renderBrandingDescription } =
    useBrandingDescriptionProperties(commonLoginStringsFabric);

  const { onDialogClose, seeAllDevicesLinkOnClick, showDialog } =
    useDialogProperties(setSeeAllDevicesLinkFocus);
  const { loginPostProps, rngcSessionIdentifier, unauthenticatedSessionId } =
    useLoginPostFormProperties();

  const {
    descriptionId: pollingDescriptionId,
    displaySignId,
    errorId,
    titleId,
    initialFocusElementAriaDescribedBy,
    primaryButtonAriaDescribedBy,
  } = getAriaDescribedByProperties(title, renderBrandingDescription, brandingDescriptionId, error);

  const {
    globalState: { user },
  } = useContext(GlobalContext);

  const {
    viewState: { serverErrorShown },
  } = useContext(LoginContext);

  const onLostAppAccessLinkClicked = useLostAuthenticatorLink(user);

  const { showSwitchToEvictedCredPicker, showCredentialSwitchContent } =
    useShowCredentialSwitchContent();

  const credSwitchLinkProps = useCredentialSwitchProperties(
    error,
    displaySign,
    setIsRequestPending,
    initialFocusElementAriaDescribedBy,
    showSeeAllDevicesLink,
  );

  // Set initial focus on the displaySign (number match) when it is shown (i.e, when it's an unfamiliar device). Otherwise, when the displaySign is
  // not shown (i.e., when it's a familiar device or when there's an error), we set focus on the credential switch link.
  useEffect(() => {
    if (displaySign) {
      setTimeout(() => {
        displaySignRef?.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  }, [displaySign, displaySignRef]);

  useActivateView(ViewId.PushNotifications, FlowId.Login, {
    showBackButtonOnActiveView: showBackArrowButton,
  });

  useDocumentTitle(documentTitle);

  const commonStyles = useCommonStyles();
  const viewStyles = usePushNotificationsViewStyles();

  // If there is an error from the server, show it and don't start polling
  const serverError = getServerErrorText({
    strings: loginInputValidationErrorStringsFabric,
    errorCode: serverErrorCode,
  });

  useEffectOnce(() => {
    if (serverError && !serverErrorShown) {
      handleServerError(serverError);
    } else {
      setupAndStartPolling();
    }
  });

  const displaySignContent = (
    <div className={commonStyles.displaySign}>
      <span
        data-testid="displaySign"
        aria-describedby={initialFocusElementAriaDescribedBy}
        ref={displaySignRef}
        tabIndex={-1}
        id={displaySignId}
      >
        {displaySign}
      </span>
    </div>
  );

  const seeAllDevicesLink = showSeeAllDevicesLink && (
    <SecondaryContentContainerFabric>
      <div
        className={commonStyles.row}
        data-testid={
          showSeeAllDevicesLinkBelowGif ? "seeAllDevicesLinkBelowGif" : "seeAllDevicesLinkAboveGif"
        }
      >
        <div className={commonStyles.formGroup}>
          <LinkButton
            text={seeAllDevicesLinkText}
            onClick={seeAllDevicesLinkOnClick}
            hasFocus={seeAllDevicesLinkFocus}
            ariaDescribedBy={initialFocusElementAriaDescribedBy}
          />
          {showDialog && (
            <PushNotificationsDialogFabric onDialogClose={onDialogClose} devices={devices} />
          )}
        </div>
      </div>
    </SecondaryContentContainerFabric>
  );

  const animatedGif = (
    <div className={commonStyles.section}>
      <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
        <img
          role="presentation"
          data-testid="gifInfo"
          src={gifUrl}
          alt=""
          className={mergeClasses(commonStyles.centerBlock, viewStyles.authenticatorImage)}
        />
      </div>
    </div>
  );

  const contentWithGif = (
    <div className={commonStyles.section}>
      {displaySign && (
        <div className={mergeClasses(commonStyles.section, viewStyles.pullLeft)}>
          <div className={commonStyles.textBody}>{displaySignContent}</div>
        </div>
      )}
      <div
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        id={pollingDescriptionId}
        className={mergeClasses(
          commonStyles.textBlockBody,
          commonStyles.overFlowHidden,
          commonStyles.formGroup,
          displaySign ? viewStyles.descriptionPadding : "",
        )}
      >
        {pollingDescription}
      </div>
      {seeAllDevicesLink}
      {animatedGif}
    </div>
  );

  const contentWithoutGif = (
    <>
      <div className={commonStyles.section}>
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            id={pollingDescriptionId}
            className={mergeClasses(commonStyles.textBlockBody, commonStyles.overFlowHidden)}
          >
            {pollingDescription}
          </div>
        </div>
      </div>
      {displaySign && (
        <div className={commonStyles.section}>
          <div
            className={mergeClasses(
              commonStyles.row,
              commonStyles.textBody,
              commonStyles.displaySignContainer,
            )}
          >
            {displaySignContent}
          </div>
        </div>
      )}
    </>
  );

  const errorContent = (
    <div className={commonStyles.section}>
      <div className={commonStyles.formGroup}>
        <div
          id={errorId}
          className={mergeClasses(
            commonStyles.row,
            commonStyles.textBody,
            commonStyles.textBlockBody,
            commonStyles.alertError,
          )}
        >
          {error}
        </div>
        {remoteNgcType === RemoteNgcType.PushNotification && (
          <div
            className={mergeClasses(
              commonStyles.row,
              commonStyles.textBody,
              commonStyles.textBlockBody,
            )}
          >
            {resendNotificationText}
          </div>
        )}
      </div>
      {showAnimatedGifWhilePolling && animatedGif}
    </div>
  );

  const secondaryContent = (
    <div>
      {showSeeAllDevicesLinkBelowGif && seeAllDevicesLink}
      <SecondaryContentContainerFabric>
        {showCredentialSwitchContent && (
          <CredentialSwitchContentFabric
            credentialSwitchProps={credSwitchLinkProps}
            showSwitchToEvictedCredPicker={showSwitchToEvictedCredPicker}
          />
        )}
        {showLostAppAccessLink && (
          <div className={commonStyles.row}>
            <div className={commonStyles.formGroup}>
              <LinkButton text={lostAppAccessLinkText} onClick={onLostAppAccessLinkClicked} />
            </div>
          </div>
        )}
      </SecondaryContentContainerFabric>
    </div>
  );

  const pollingContent = showAnimatedGifWhilePolling ? contentWithGif : contentWithoutGif;

  return (
    <form
      name="f1"
      id="i0281"
      data-testid="remoteNgcForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      ref={formRef}
      action={postUrl}
    >
      <TitleFabric titleId={titleId} title={title} />

      {renderBrandingDescription && (
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
          <div id={brandingDescriptionId} className={commonStyles.wrapContent}>
            {brandingDescription}
          </div>
        </div>
      )}

      <input type="hidden" name="slk" value={rngcSessionIdentifier} data-testid="slk" />
      <input type="hidden" name="uaid" value={unauthenticatedSessionId} data-testid="uaid" />

      <LoginFlowPostHiddenInputs {...loginPostProps} />

      {isRequestPending && (
        <ProgressIndicatorContainerFabric>
          <ProgressIndicatorFabric />
        </ProgressIndicatorContainerFabric>
      )}

      {!isRequestPending && error ? errorContent : !isRequestPending && pollingContent}
      {!isRequestPending && secondaryContent}

      <div className={commonStyles.winButtonPinBottom}>
        {!isRequestPending && error && showButtons && (
          <div className={commonStyles.buttonMargin}>
            <div className={commonStyles.row}>
              <TextButtonContainer>
                <TextButtonFabric
                  isPrimary
                  buttonId="primaryButton"
                  label={primaryButtonLabel}
                  ariaDescribedBy={primaryButtonAriaDescribedBy}
                  onClick={setupAndStartPolling}
                />
              </TextButtonContainer>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};
