import React from "react";
import StylesConfig from "../../../config/styles-config";
import EnvironmentBanner from "../environment-banner";

/**
 * EnvironmentBanner component
 * @returns Returns a div container the a different banner based on environment
 */
const EnvironmentBannerFabric: React.FC = function EnvironmentBannerFabric() {
  const envBannerStyles = StylesConfig.instance.useEnvironmentBannerStyles();

  return <EnvironmentBanner styles={envBannerStyles} />;
};

export default EnvironmentBannerFabric;
