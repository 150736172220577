import { makeStyles } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type TitleStylesFabric, useTitleStylesFabric } from "../fabric";
import * as commonStyleConstants from "./constants-win11-oobe-fabric.styles";

const useOverrideTitleStyles = makeStyles({
  title: { fontSize: "1.25rem", lineHeight: "1.5rem" },
  pageTitle: {
    "& h1": {
      "-ms-user-select": "none",
      fontSize: "1.75rem",
      fontWeight: "500",
      lineHeight: "2rem",
      paddingBottom: "3.3628px",
      paddingTop: "3.3628px",
    },
  },
  pageDescription: {
    "-ms-user-select": "none",
    color: "#666",
    fontSize: "1rem",
    lineHeight: "1.33rem",

    // Hide description text on low resolution devices to prevent terms of use statements from being out of view
    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
      display: "none",
    },
  },
});

export type TitleStylesWin11OobeFabric = TitleStylesFabric &
  Partial<ReturnType<typeof useOverrideTitleStyles>>;

export const useTitleStylesWin11OobeFabric = () =>
  useMergedStyles(useTitleStylesFabric(), useOverrideTitleStyles()) as TitleStylesWin11OobeFabric;
