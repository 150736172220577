import React from "react";
import StylesConfig from "../../../config/styles-config";
import { HipType } from "../../../constants";
import { challengeSpeakerIcon } from "../../../utilities/image-helpers/accessible-images";
import { AudioPlayerButtonFabric } from "../../audio-player-button/fabric/audio-player-button-fabric";
import { type HipChallengeNativeProps } from "../hip-challenge-native-types";

export type HipChallengeNativeTargetProps = Pick<
  HipChallengeNativeProps,
  "nativeHipTarget" | "nativeHipType" | "strings" | "onClick"
>;

/**
 * Represents the native hip target. The target is an image with or without an audio player.
 * @param props the native hip target params
 * @returns the native hip target
 */
export const HipChallengeNativeTargetFabric: React.FC<HipChallengeNativeTargetProps> =
  function HipChallengeNativeTargetFabric(props) {
    const { nativeHipTarget, nativeHipType, strings, onClick } = props;
    const { placeholderForAudio, placeholderForImage, playAudioTooltip } = strings;
    const { useCaptchaViewStyles } = StylesConfig.instance;
    const nativeHipStyles = useCaptchaViewStyles();

    const source = nativeHipTarget;
    const className = nativeHipStyles.targetImage;
    const dataTestId = "nativeHip-target-id";

    return (
      <div className={nativeHipStyles.targetImageContainer}>
        {nativeHipType === HipType.NativeAudio && (
          <AudioPlayerButtonFabric
            audioUrl={source}
            ariaLabel={placeholderForAudio}
            image={{
              dataTestId,
              urls: challengeSpeakerIcon,
            }}
            className={className}
            onClick={onClick}
            tooltipText={playAudioTooltip}
          />
        )}

        {nativeHipType === HipType.NativeVisual && (
          <img
            data-testid={dataTestId}
            src={nativeHipTarget}
            alt={placeholderForImage}
            className={className}
          />
        )}
      </div>
    );
  };
