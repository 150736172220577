import { type ArkoseEnforceProps } from "../../components/arkose-enforcement";
import { type HipChallengeProps } from "../../components/hip-challenge/hip-challenge-types";
import { type HipChallengeNativeProps } from "../../components/hip-challenge-native";
import { type ViewContainerProps } from "../../components/view-container/fabric/view-container-fabric";
import { type ApiNames, type HipType } from "../../constants";
import { type ActivateViewOptions } from "../../hooks/use-activate-view";

export type ChallengeSubmitParams = {
  solution: string;
  type: HipType;
  token: string;
  reloadHIP?: () => void;
};

export type CreatePassportsRepMapRequest = {
  dfpRequestId?: string;
  encAttemptToken?: string;
  RiskAssessmentDetails?: string;
  PhoneRepRiskScoreDetails?: string;
  RecommendedChallengeRiskScore?: string;
  RepMapRequestIdentifierDetails?: string;
};

export type SendOttRepMapRequest = {
  RecommendedChallengeRiskScore?: string;
  RepMapRequestIdentifierDetails?: string;
};

export type CreatePassportsRepMapErrorResponse = {
  dfpRequestId?: string;
  encAttemptToken?: string;
  riskAssessmentDetails?: string;
  phoneRepRiskScoreDetails?: string;
  repMapRequestIdentifierDetails?: string;
};

export type SendOttRepMapSuccessResponse = {
  phoneRepRiskScoreDetails?: string;
};

export type SendOttRepMapErrorResponse = {
  recommendedChallengeRiskScore?: string;
};

export type GetOtcChallengeRequest = {
  challengeSolution: string;
  challengeType: string;
  challengeId: string;
  challengeViewSupported: string;
};

export type CreatePassportsChallengeRequest = {
  /** Hip flow Id */
  HFId?: string;
  /** Hip Id */
  HId?: string;
  /** Hip Arkose Partner Id */
  HPId?: string;
  /** Hip scenario Id */
  HSId?: string;
  /** Hip solution */
  HSol?: string;
  /** Hip type */
  HType?: string;
};

export type SendOttChallengeRequest = CreatePassportsChallengeRequest;

export type AccountParams = (CreatePassportsChallengeRequest | SendOttChallengeRequest) &
  Partial<CreatePassportsRepMapRequest & SendOttRepMapRequest>;

export type TriggerChallengeParams<ResponseType> = {
  response: ResponseType;
  apiName?: ApiNames;
  reloadHIP?: () => void;
};

export type ChallengeSwitchPropsFabric = {
  arkoseProps?: ArkoseEnforceProps;
  challengeType: HipType;
  container?: ViewContainerProps;
  errorContainerMessage: string;
  hipProps?: HipChallengeProps;
  iteration?: number;
  nativeHipProps?: HipChallengeNativeProps;
  activateViewOptions?: Omit<ActivateViewOptions, "showBackButtonOnActiveView">;
};

export const HipCommonErrors = {
  HipValidationError: "1043",
  FraudBlocked: "1346",
};

export const HipErrorsSendOtt = {
  HipCaptchaNeededOnSendOTT: "1339",
  HipEnforcementNeededOnSendOTT: "1340",
  ...HipCommonErrors,
};

export const HipErrorsCreate = {
  HipNeeded: "1040",
  HipEnforcementNeeded: "1041",
  ...HipCommonErrors,
};

export const HipErrors = {
  ...HipCommonErrors,
  ...HipErrorsCreate,
  ...HipErrorsSendOtt,
};
