import LoginConfig from "../../../../flows/login/login-config";
import { useRedirect } from "../../../../hooks/use-redirect";

/**
 * Event handlers for the promoted credentials component.
 * @returns the properties needed for the promoted credentials component.
 */
export const usePromotedCredsHandlers = () => {
  const { linkedInFedUrl, githubFedUrl, googleFedUrl, facebookFedUrl } = LoginConfig.instance;
  const redirect = useRedirect();

  const onClickLinkedIn = () => {
    redirect(linkedInFedUrl, true);
  };

  const onClickGitHub = () => {
    redirect(githubFedUrl, true);
  };

  const onClickGoogle = () => {
    redirect(googleFedUrl, true);
  };

  const onClickFacebook = () => {
    redirect(facebookFedUrl, true);
  };

  return {
    onClickLinkedIn,
    onClickGitHub,
    onClickGoogle,
    onClickFacebook,
  };
};
