import { type GctStrings, defaultGctStrings } from "../gct-strings-config";

/**
 * The Xbox flavor will map to this set of gct error strings
 */
export const gctStringsXbox: GctStrings = {
  ...defaultGctStrings,
  badUsername: getLocalString("SignIn_Error_EmailMissing"),
  invalidNumber: getLocalString("Login_PhoneNumber_ForbiddenCharacters_Error"),
  federationNotAllowed: getLocalString("Xbox_Login_FederationNotAllowed_Error"),
  disabledAlias: getLocalString("Xbox_Login_AliasDisabledError"),
};
