import { useContext } from "react";
import { ViewId } from "../../../../../constants";
import { GlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { useNavigateDirection } from "../../../../../hooks";
import { type OneTimeCodeCredential, CredentialType } from "../../../../../model/credential";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";
import { LoginContext } from "../../../login-context";
import { LoginActionType } from "../../../login-reducer";
import { type ICredentialPickerStrings } from "../credential-picker-interface";
import { useSendOneTimeCode } from "./use-send-otc";

/**
 * @param credentialPickerStrings Strings for the credential picker view
 * @returns the click handler for when a OneTimeCode credential type is selected
 */
export const useOneTimeCodeClickHandler = (credentialPickerStrings: ICredentialPickerStrings) => {
  const navigate = useNavigateDirection();
  const sendOneTimeCode = useSendOneTimeCode(credentialPickerStrings);

  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  return (
    selectedCredential: OneTimeCodeCredential,
    setError: React.Dispatch<React.SetStateAction<string | JSX.Element>>,
  ) => {
    setError("");
    if (selectedCredential.proof.clearDigits) {
      dispatchLoginStateChange({
        type: LoginActionType.UpdateCredentials,
        payload: {
          otcCredential: {
            credentialType: CredentialType.OneTimeCode,
            proof: selectedCredential.proof,
          },
        },
      });
      navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.ProofConfirmation));
    } else {
      dispatchGlobalStateChange({
        type: GlobalActionType.SetShowProgressIndicator,
        payload: true,
      });
      sendOneTimeCode(selectedCredential, setError);
    }
  };
};
