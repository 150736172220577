import React, { useState } from "react";
import { mergeClasses } from "@griffel/react";
import { SecondaryContentContainerFabric } from "../../../../../components";
import { InputFabric as Input } from "../../../../../components/inputs/input/fabric/input-fabric";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useActivateView } from "../../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../../hooks/use-document-title";
import { CredentialType } from "../../../../../model/credential";
import { ProofType } from "../../../../../model/proof";
import { CredentialSwitchContentFabric } from "../../../components/fabric/credential-switch-content-fabric";
import { LoginTitleDescriptionFabric } from "../../../components/fabric/login-title-description-fabric";
import { commonLoginStringsFabric as commonLoginStrings } from "../../../fabric/common-login-strings-fabric";
import { useShowCredentialSwitchContent } from "../../../hooks/login-hooks";
import { useLoginContext } from "../../../login-context";
import { useInputValidationFunction } from "../hooks/use-input-validation-function";
import { useProofConfirmationInputForm } from "../hooks/use-proof-confirmation-input-form";
import { useProofConfirmationViewProperties } from "../hooks/use-proof-confirmation-view-properties";
import { useSendOtcForProofConfirmation } from "../hooks/use-send-otc-for-proof-confirmation";
import { useShowBackArrowButton } from "../hooks/use-show-back-arrow-button";
import { useSwitchToOtcLinkClickHandler } from "../hooks/use-switch-to-otc-link-click-handler";
import { proofConfirmationStringsFabric as proofConfirmationStrings } from "./proof-confirmation-view-strings-fabric";

/**
 * ProofConfirmationView component
 *
 * This view asks the user to confirm their one-time code proof prior to showing them the OneTimeCode view.
 * Upon successful confirmation of their proof, the one-time code server request is made and the code is
 * sent to their email address or phone number (SMS).
 *
 * @returns A rendered instance of this component
 */
export const ProofConfirmationViewFabric: React.FC = function ProofConfirmationViewFabric() {
  // Global config data
  const { showButtons } = GlobalConfig.instance;

  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  // Login context data
  const {
    viewState: {
      credentials: {
        availableCredentials,
        otcCredential = {
          credentialType: CredentialType.OneTimeCode,
          proof: { display: "", type: ProofType.SMS },
        },
      },
    },
  } = useLoginContext();

  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

  const {
    documentTitle,
    lightboxTitleId,
    title,
    lightboxDescriptionId,
    description,
    inputAriaLabel,
    inputAriaDescribedBy,
    maxLength,
    placeholder,
    type: inputType,
  } = useProofConfirmationViewProperties(
    otcCredential,
    commonLoginStrings,
    proofConfirmationStrings,
  );

  const submitTask = useSendOtcForProofConfirmation(
    otcCredential,
    isRequestPending,
    setIsRequestPending,
    proofConfirmationStrings,
  );
  const inputValidationFunction = useInputValidationFunction(
    otcCredential,
    proofConfirmationStrings,
  );
  const {
    hasFocus,
    onBlur,
    onFocus,
    value,
    onChange,
    onSubmit,
    error: { showErrorMessage, errorMessage },
  } = useProofConfirmationInputForm(submitTask, inputValidationFunction);

  const switchToOtcLinkClickHandler = useSwitchToOtcLinkClickHandler(onChange);

  const { showSwitchToEvictedCredPicker, showCredentialSwitchContent } =
    useShowCredentialSwitchContent();
  const credSwitchLinkProps = {
    availableCredentials,
    currentCredential: otcCredential,
    setRequestPendingFlag: setIsRequestPending,
    sourceViewId: ViewId.ProofConfirmation,
    shouldUpdateOtcCredential: true,
  };

  const showBackButton = useShowBackArrowButton();

  useActivateView(ViewId.ProofConfirmation, FlowId.Login, {
    showBackButtonOnActiveView: showBackButton,
    showIdentityBanner: true,
  });
  useDocumentTitle(documentTitle);
  useStaticCommonStyles();

  const commonStyles = useCommonStyles();

  return (
    <div>
      <form
        name="f1"
        id="i0281"
        data-testid="proofConfirmationForm"
        noValidate
        spellCheck="false"
        method="post"
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <LoginTitleDescriptionFabric titleId={lightboxTitleId} title={title} />

        <div className={mergeClasses(commonStyles.row, commonStyles.formGroup)}>
          <div id={lightboxDescriptionId} className={commonStyles.formGroup}>
            {description}
          </div>

          <Input
            id="proofConfirmationText"
            name="ProofConfirmation"
            placeholder={placeholder}
            type={inputType || "text"}
            showErrorMessage={showErrorMessage}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            aria-label={inputAriaLabel}
            aria-describedby={inputAriaDescribedBy}
            hasFocus={hasFocus}
            hasInitialFocus
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete="off"
            maxLength={maxLength}
          />
        </div>

        <div className={commonStyles.buttonMargin}>
          <div className={commonStyles.row}>
            <SecondaryContentContainerFabric>
              {showCredentialSwitchContent && (
                <CredentialSwitchContentFabric
                  credentialSwitchProps={credSwitchLinkProps}
                  showSwitchToEvictedCredPicker={showSwitchToEvictedCredPicker}
                />
              )}

              <div className={commonStyles.formGroup}>
                <LinkButton
                  linkId="proofConfirmationToggle"
                  text={proofConfirmationStrings.alreadyHaveCode}
                  onClick={switchToOtcLinkClickHandler}
                />
              </div>
            </SecondaryContentContainerFabric>
          </div>
        </div>

        <div className={commonStyles.winButtonPinBottom}>
          <div className={commonStyles.row}>
            <TextButtonContainer>
              {showButtons && (
                <TextButtonFabric
                  type="submit"
                  isPrimary
                  label={proofConfirmationStrings.sendCode}
                />
              )}
            </TextButtonContainer>
          </div>
        </div>
      </form>
    </div>
  );
};
