import { type AcmaLinksObject } from "../api/acma-types";
import { type OneTimeCodeProof, type OneTimeCodeProofWithDisplay } from "../proof";
import { type ISession } from "../user";

export enum CredentialType {
  None = 0,
  Password = 1,
  RemoteNGC = 2,
  OneTimeCode = 3,
  Federation = 4,
  CloudFederation = 5,
  OtherMicrosoftIdpFederation = 6,
  Fido = 7,
  GitHub = 8,
  PublicIdentifierCode = 9,
  LinkedIn = 10,
  RemoteLogin = 11,
  Google = 12,
  AccessPass = 13,
  Facebook = 14,
  Certificate = 15,
  OfflineAccount = 16,

  // add new credential types above this
  NoPreferredCredential = 1000,
}

export interface OneTimeCodeCredential extends UserCredential {
  proof: OneTimeCodeProofWithDisplay;
  credentialType: CredentialType.OneTimeCode;
}

export interface RemoteNgcCredential extends UserCredential {
  credentialType: CredentialType.RemoteNGC;
}

export interface PublicIdentifierCodeCredential extends UserCredential {
  credentialType: CredentialType.PublicIdentifierCode;
}

export type ConfirmSendCredential =
  | OneTimeCodeCredential
  | RemoteNgcCredential
  | PublicIdentifierCodeCredential;

export type ConfirmSendCredentialTypes =
  | CredentialType.OneTimeCode
  | CredentialType.RemoteNGC
  | CredentialType.PublicIdentifierCode;

export const ExpectedConfirmSendCredentialTypes = [
  CredentialType.OneTimeCode,
  CredentialType.RemoteNGC,
  CredentialType.PublicIdentifierCode,
];

export const FederationCredentialTypes = [
  CredentialType.Facebook,
  CredentialType.GitHub,
  CredentialType.Google,
  CredentialType.LinkedIn,
  CredentialType.OtherMicrosoftIdpFederation,
];

/**
 * This type is used to represent the user credentials provided by the Server.
 * Each credential has an associated type that correspond to ways the user can authenticate.
 * A user may have multiple credential options to choose from.
 * This type is used in places such as LoginContext to list the available and evicted credentials.
 */
export interface UserCredential {
  credentialType: CredentialType;
  redirectUrl?: string;
  shownOnlyOnPicker?: boolean;
  proof?: OneTimeCodeProof;
  isDefault?: boolean;
  redirectPostParams?: object;
}

export interface UserCredentials {
  availableCredentials: Array<UserCredential>;
  evictedCredentials: Array<UserCredential>;
  useEvictedCredentials: boolean;
  otcCredential?: OneTimeCodeCredential;
  preferredCredential: CredentialType;
  proofConfirmation: string;
}

export type ILoginCredentials = UserCredentials & {
  sessions: Array<ISession>;
};

export interface OtcUserCredentials extends ILoginCredentials {
  otcCredential: OneTimeCodeCredential;
  preferredCredential: CredentialType.OneTimeCode;
}

export interface NonOtcUserCredentials extends ILoginCredentials {
  preferredCredential: Exclude<ConfirmSendCredentialTypes, CredentialType.OneTimeCode>;
}

export type ConfirmSendUserCredentials = OtcUserCredentials | NonOtcUserCredentials;

// Section ACMA. EC compatible contracts.
export const enum AuthenticationMethodType {
  Email = "email",
  Password = "password",
  Sms = "sms",
  VerifiedId = "verifiedId",
  Voice = "voice",
}

export type AuthenticationMethod = {
  id: string;
  isDefault?: boolean;
  hint: string;
  priority?: number;
  type: AuthenticationMethodType;
  _links?: {
    recover?: AcmaLinksObject;
  };
};

export type ChallengeAuthenticationMethod = AuthenticationMethod & {
  _links: {
    challenge: AcmaLinksObject;
    verify: AcmaLinksObject;
    poll?: AcmaLinksObject;
  };
};

export type OneTimeCodeAuthenticationMethod = ChallengeAuthenticationMethod & {
  codeLength?: number;
};

export type FederationAuthenticationMethod = AuthenticationMethod & {
  _links: {
    signInRedirect: AcmaLinksObject;
    signUpRedirect: AcmaLinksObject;
  };
};
