import { shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

export const dropdownBaseStyle: Record<string, string | string[]> = {
  borderTopWidth: "0px",
  borderLeftWidth: "0px",
  borderRightWidth: "0px",
  borderBottomWidth: "1px",
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingLeft: "0px",
  paddingRight: "0px",
  height: "36px",
  outlineStyle: "none",
  backgroundColor: "transparent",
  ...shorthands.borderStyle("solid"),
  ...shorthands.borderColor("rgba(0, 0, 0, 0.6)"),
};

export const hasError: Record<string, string | string[]> = {
  ...shorthands.borderColor(styleConstants.ERROR_COLOR),
};

export const hasFocus: Record<string, string | string[]> = {
  backgroundColor: "#eee",
  ...shorthands.borderWidth("1px"),
  ...shorthands.borderStyle("solid"),
  ...shorthands.borderColor("#0067b8"),
};

export const hasErrorFocusBox: Record<string, string | string[]> = {
  backgroundColor: "#eee",
  ...shorthands.borderStyle("solid"),
  ...shorthands.borderWidth("1px"),
  ...shorthands.borderColor(styleConstants.ERROR_COLOR),
};
