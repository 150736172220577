import { useCallback } from "react";
import { useGlobalContext } from "../global-context";
import {
  hideProgressIndicator as hideProgressIndicatorAction,
  showProgressIndicator as showProgressIndicatorAction,
} from "../global-reducer";

interface UseProgressIndicator {
  showProgressIndicator: () => void;
  hideProgressIndicator: () => void;
}

export const useProgressIndicator = (): UseProgressIndicator => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();

  return {
    showProgressIndicator: useCallback(
      () => dispatchGlobalStateChange(showProgressIndicatorAction()),
      [dispatchGlobalStateChange],
    ),

    hideProgressIndicator: useCallback(
      () => dispatchGlobalStateChange(hideProgressIndicatorAction()),
      [dispatchGlobalStateChange],
    ),
  };
};
