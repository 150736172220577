import React from "react";
import { HipType } from "../../../constants";
import { type ChallengeSwitchPropsFabric } from "../../../views/challenge/challenge-types";
import { ArkoseEnforceFabric } from "../../arkose-enforcement/fabric/arkose-enforce-fabric";
import ErrorContainerFabric from "../../error-container/fabric/error-container-fabric";
import { HipChallengeFabric } from "../../hip-challenge/fabric/hip-challenge-fabric";
import { HipChallengeNativeFabric } from "../../hip-challenge-native";
import { ChallengeBlockFabric } from "./challenge-block-fabric";

/**
 * A component to switch between challenge types
 * @param props the props of the component
 * @param props.arkoseProps  the arkose props
 * @param props.hipProps the hip props
 * @param props.nativeHipProps the native hip props
 * @param props.challengeType the challengeType from idp
 * @param props.iteration  the iteration of the challenge
 * @param props.errorContainerMessage the message for the error container
 * @returns the Challenge switch component
 */
export const ChallengeSwitchFabric: React.FC<ChallengeSwitchPropsFabric> =
  function ChallengeSwitchFabric({
    arkoseProps,
    hipProps,
    nativeHipProps,
    challengeType,
    iteration,
    errorContainerMessage,
  }: ChallengeSwitchPropsFabric) {
    switch (challengeType) {
      case HipType.NativeAudio:
      case HipType.NativeVisual:
        if (!nativeHipProps) {
          break;
        }

        return (
          <div data-iteration={iteration} data-testid="switch-native-hip">
            <HipChallengeNativeFabric {...nativeHipProps} />
          </div>
        );

      case HipType.Audio:
      case HipType.Visual:
        if (!hipProps) {
          break;
        }

        return (
          <div data-iteration={iteration} data-testid="switch-hip">
            <HipChallengeFabric {...hipProps} />
          </div>
        );

      case HipType.Enforcement:
        if (!arkoseProps) {
          break;
        }

        return (
          <div data-iteration={iteration} data-testid="switch-arkose">
            <ArkoseEnforceFabric {...arkoseProps} />
          </div>
        );

      case HipType.Block:
        return (
          <div data-testid="switch-block">
            <ChallengeBlockFabric />
          </div>
        );

      default:
        break;
    }

    return (
      <ErrorContainerFabric errorCode={errorContainerMessage}>
        {errorContainerMessage}
      </ErrorContainerFabric>
    );
  };
