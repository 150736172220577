import { useCallback } from "react";
import { ApiNames, ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useNavigateDirection, useProgressIndicator } from "../../../../../hooks";
import { type BaseOtcParams, useSendOneTimeCode } from "../../../../../hooks/use-send-otc";
import { CredentialType } from "../../../../../model/credential";
import { getChannel, getProofData } from "../../../../../model/proof";
import {
  getProofConfirmation,
  isChallengeViewSupported,
  isNoPassword,
  isProofEncrypted,
} from "../../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  OtcPurposes,
  OtcStatus,
} from "../../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { useOtcTelemetry } from "../../../../../utilities/api-helpers/one-time-code/use-one-time-code-telemetry";
import { useTriggerChallenge } from "../../../../../views/challenge/hooks/use-trigger-challenge";
import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";
import { type UseOneTimeCodeParams } from "./use-description";

/**
 * This hook is used to provide a callback to make a OneTimeCode API call with parameters unique to the Confirm Send View.
 * @param params The parameters required for the hook. These contain values to use as well as callbacks required for success/failure.
 * @param confirmSendStrings Strings for the Confirm Send View
 * @returns A callback used to make a OneTimeCode API call on the Confirm Send View.
 * This callback has "debouncing", so subsequent calls won't trigger another request while the current request is in progress.
 */
export const useSendOtcForConfirmSend = (
  params: UseOneTimeCodeParams,
  confirmSendStrings: ConfirmSendViewStrings,
) => {
  const {
    user,
    availableCredentials,
    currentCredential,
    proofType,
    setButtonText,
    setHasError,
    requestIsPending,
    setRequestIsPending,
    preferredCredential,
  } = params;
  const { confirmSendNextButton } = confirmSendStrings;
  const { activeFlavor } = GlobalConfig.instance;

  const navigate = useNavigateDirection();
  const { hideProgressIndicator, showProgressIndicator } = useProgressIndicator();

  const isEncrypted = isProofEncrypted(currentCredential);
  const purpose = isNoPassword(currentCredential, availableCredentials)
    ? OtcPurposes.noPassword
    : OtcPurposes.otcLogin;
  const proofData = getProofData(currentCredential, user);
  const proofConfirmation = isEncrypted
    ? getProofConfirmation(currentCredential, proofType)
    : undefined;

  const telemetryCallback = useOtcTelemetry();

  const triggerChallenge = useTriggerChallenge();

  const otcFailure = useCallback(
    (failureParams: Partial<OtcFailureParams>) => {
      setButtonText(confirmSendNextButton);
      setRequestIsPending(false);
      hideProgressIndicator();
      const otcStatus = failureParams?.otcStatus;

      if (otcStatus === OtcStatus.challengeRequiredError || otcStatus === OtcStatus.userBlocked) {
        triggerChallenge({ response: failureParams as OtcFailureParams, apiName: ApiNames.GetOtc });
      }
    },
    [
      triggerChallenge,
      setButtonText,
      confirmSendNextButton,
      setRequestIsPending,
      hideProgressIndicator,
    ],
  );

  const otcSuccess = useCallback(() => {
    hideProgressIndicator();
    navigate(ViewId.ConfirmSend, ViewId.OneTimeCode);
  }, [navigate, hideProgressIndicator]);

  const otcParams: BaseOtcParams = {
    errorOnPublicIdentifier: preferredCredential === CredentialType.PublicIdentifierCode,
    channel: getChannel(proofType),
    proofType,
    purpose,
    user,
    isEncrypted,
    proofData,
    proofConfirmation,
    setHasError,
    onSuccess: otcSuccess,
    onFailure: otcFailure,
    challengeViewSupported: isChallengeViewSupported(activeFlavor),
    telemetryCallback,
    requestIsPending,
    setRequestIsPending,
  };

  // 'otcSuccess' callback will be called from useSendOneTimeCode() hook, which will navigate to the next view.
  const sendOneTimeCodeThenNavigate = useSendOneTimeCode(otcParams);

  return useCallback(() => {
    showProgressIndicator();
    sendOneTimeCodeThenNavigate();
  }, [sendOneTimeCodeThenNavigate, showProgressIndicator]);
};
