import { ChallengeActionType, useChallengeContext } from "../../../challenge-context";
import {
  type DataApiError,
  type ResponseBody,
  type ResponseError,
  ApiNames,
  FlowId,
  HipType,
  ViewId,
} from "../../../constants";
import { useGlobalContext } from "../../../global-context";
import { useNavigateDirection } from "../../../hooks";
import {
  type OtcFailureParams,
  OtcStatus,
} from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { type TriggerChallengeParams, HipErrors } from "../challenge-types";
import { challengeStringsFabric } from "../fabric/challenge-strings-fabric";
import { errorResponseAggregator, useSaveRepMapResponse } from "./use-repmap-params";

const {
  HipValidationError,
  HipNeeded,
  HipEnforcementNeeded,
  HipCaptchaNeededOnSendOTT,
  HipEnforcementNeededOnSendOTT,
  FraudBlocked,
} = HipErrors;

/**
 * @returns Trigger challenge from any views or any apis
 */
export const useTriggerChallenge = () => {
  const {
    globalState: { activeView, activeFlow },
  } = useGlobalContext();
  const { dispatchChallengeStateChange, challengeState: { type } = {} } = useChallengeContext();
  const navigate = useNavigateDirection();
  const saveRepmapResponse = useSaveRepMapResponse();

  return (props: TriggerChallengeParams<ResponseError | Partial<OtcFailureParams>>) => {
    const { response, apiName, reloadHIP } = props;
    const isChallengeView = activeView === ViewId.Challenge;

    dispatchChallengeStateChange({
      type: ChallengeActionType.SetCommonAction,
      payload: { apiName },
    });

    // The apis are separated by if because the response structure is significantly diferent between IDPs affecting how we extract what we need. Therefore is safer to isolate these apis by condition
    if (apiName === ApiNames.SendOtt || apiName === ApiNames.CreateAccount) {
      const { responseBody = {} as ResponseBody } = response;
      const errorCode = String((responseBody?.error as DataApiError)?.code);
      const data = (responseBody?.error as DataApiError)?.data || "";
      // replace history if the challenge is triggered by send ott for signup
      // this is to skip over the verification view that the user was sent to (not shown)
      // before send ott responds with challenge
      const replaceHistory = activeFlow === FlowId.Signup && apiName === ApiNames.SendOtt;
      let dataExchangeBlob = "";

      /** data is either a string or a json string ex. "abcd", '{"anyProp": "abcd"}' */
      if (data[0] === "{") {
        dataExchangeBlob = JSON.parse(data).arkoseBlob;
      } else {
        dataExchangeBlob = data;
      }

      saveRepmapResponse(errorResponseAggregator(responseBody, apiName));

      switch (errorCode) {
        case FraudBlocked: {
          dispatchChallengeStateChange({
            type: ChallengeActionType.SetAbuseBlockAction,
            payload: {},
          });
          navigate(activeView, ViewId.Challenge, false, { replace: replaceHistory });
          break;
        }

        case HipEnforcementNeeded:
        case HipEnforcementNeededOnSendOTT: {
          dispatchChallengeStateChange({
            type: ChallengeActionType.SetArkoseAction,
            payload: {
              dataExchangeBlob,
            },
          });
          navigate(activeView, ViewId.Challenge, false, { replace: replaceHistory });
          break;
        }

        case HipNeeded:
        case HipCaptchaNeededOnSendOTT: {
          dispatchChallengeStateChange({
            type: ChallengeActionType.SetHipAction,
            payload: {},
          });
          navigate(activeView, ViewId.Challenge, false, { replace: replaceHistory });
          break;
        }

        case HipValidationError: {
          if (isChallengeView && type === HipType.Enforcement) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetArkoseAction,
              payload: {
                dataExchangeBlob,
              },
            });
          } else if (isChallengeView && reloadHIP) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetCommonAction,
              payload: {
                errorMessage: challengeStringsFabric.hipInvalidAnswer,
              },
            });
            reloadHIP();
          } else {
            throw new Error(`${errorCode} should be thrown from the challenge view instead`);
          }

          break;
        }

        default:
          if (isChallengeView) {
            // this loads a generic error view (not the block view)
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetCommonAction,
              payload: {
                type: HipType.Unknown,
              },
            });
          } else {
            throw new Error(`${errorCode} is not an error that starts a challenge`);
          }
      }
    } else if (apiName === ApiNames.GetOtc) {
      const { arkoseDataExchangeBlob, challengeType, otcStatus, phoneRepMetadata } =
        response as OtcFailureParams;
      switch (otcStatus) {
        case OtcStatus.userBlocked: {
          dispatchChallengeStateChange({
            type: ChallengeActionType.SetAbuseBlockAction,
            payload: { phoneRepMetadata },
          });
          navigate(activeView, ViewId.Challenge);
          break;
        }

        case OtcStatus.challengeRequiredError: {
          if (isChallengeView) {
            // @mikebendorf: todo remove this and old functional tests once confirmed with MSA the response is the same as reloading Arkose
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetCommonAction,
              payload: {
                errorMessage: challengeStringsFabric.hipInvalidAnswer,
                apiName: ApiNames.GetOtc,
              },
            });
            if (reloadHIP) {
              reloadHIP();
            }
          } else if (challengeType === HipType.Visual) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetHipAction,
              payload: { phoneRepMetadata },
            });
            navigate(activeView, ViewId.Challenge);
          } else if (challengeType === HipType.Enforcement) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetArkoseAction,
              payload: {
                dataExchangeBlob: arkoseDataExchangeBlob,
                phoneRepMetadata,
              },
            });
            navigate(activeView, ViewId.Challenge);
          } else {
            throw new Error("Not a proper combination of challengeType/otcStatus");
          }

          break;
        }

        case OtcStatus.challengeVerificationError: {
          if (challengeType === HipType.Enforcement && arkoseDataExchangeBlob) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetArkoseAction,
              payload: {
                dataExchangeBlob: arkoseDataExchangeBlob,
                phoneRepMetadata,
              },
            });
          } else if (challengeType === HipType.Visual && reloadHIP) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetCommonAction,
              payload: {
                errorMessage: challengeStringsFabric.hipInvalidAnswer,
              },
            });
            reloadHIP();
          } else {
            throw new Error("Challenge validation parameters missing");
          }

          break;
        }

        case OtcStatus.ftError:
        case OtcStatus.metaDataExpired: {
          if (isChallengeView) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetCommonAction,
              payload: {
                errorMessage: challengeStringsFabric.sessionExpiredError,
              },
            });
          }

          break;
        }

        default: {
          if (isChallengeView) {
            dispatchChallengeStateChange({
              type: ChallengeActionType.SetCommonAction,
              payload: {
                errorMessage: challengeStringsFabric.sendFailedError,
              },
            });
          }
        }
      }
    }
  };
};
