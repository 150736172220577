import React from "react";
import { mergeClasses } from "@griffel/react";
import { SecondaryContentContainerFabric } from "../../../../../components";
import { InputContainer } from "../../../../../components/inputs/input-container";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants";
import { useActivateView, useDocumentTitle } from "../../../../../hooks";
import { CredentialType } from "../../../../../model/credential";
import { FormattedTextWithBindings } from "../../../../../utilities/formatted-text-with-bindings";
import { CredentialSwitchContentFabric } from "../../../components/fabric/credential-switch-content-fabric";
import { LoginTitleDescriptionFabric } from "../../../components/fabric/login-title-description-fabric";
import { commonLoginStringsFabric } from "../../../fabric/common-login-strings-fabric";
import { useShowCredentialSwitchContent } from "../../../hooks/login-hooks";
import { useConfirmSendViewProperties } from "../hooks";
import { confirmSendViewStringsFabric } from "./confirm-send-view-strings-fabric";

/**
 * This view is shown to users to confirm they want to send a code to a certain source for authentication.
 * It presents options for using evicted credentials and switching to different credentials as well.
 * It can be an initial view for the Login flow or navigated into from another view.
 * @returns ConfirmSendView component
 */
export const ConfirmSendViewFabric: React.FC = function ConfirmSendViewFabric() {
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;
  const {
    availableCredentials,
    currentCredential,
    description,
    buttonText,
    navigateToPhoneDisambig,
    onClickPrimaryButton,
    selectAccount,
    setRequestIsPending,
    preferredCredential,
    documentTitle,
    showChangeUserLink,
    showButton,
    headerText,
  } = useConfirmSendViewProperties({
    commonLoginStrings: commonLoginStringsFabric,
    confirmSendViewStrings: confirmSendViewStringsFabric,
  });

  useActivateView(ViewId.ConfirmSend, FlowId.Login);
  useDocumentTitle(documentTitle);
  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  const phoneDisambigLink = (chunks: string[]) => (
    <LinkButton text={chunks[0]} onClick={navigateToPhoneDisambig} />
  );

  const descriptionContent = (
    <div className={commonStyles.formGroup} data-testid="confirm-send-description">
      <div
        className={mergeClasses(
          commonStyles.row,
          commonStyles.textBody,
          commonStyles.textBlockBody,
        )}
      >
        <FormattedTextWithBindings
          text={description}
          embeddedBindings={{
            phoneDisambigLink,
          }}
        />
      </div>
    </div>
  );

  const { showSwitchToEvictedCredPicker, showCredentialSwitchContent } =
    useShowCredentialSwitchContent();
  const credSwitchLinkProps = {
    availableCredentials,
    currentCredential,
    setRequestPendingFlag: setRequestIsPending,
    sourceViewId: ViewId.ConfirmSend,
    shouldUpdateOtcCredential: true,
  };

  const showChangeUserLinkContent = (
    <div className={commonStyles.formGroup}>
      <LinkButton text={confirmSendViewStringsFabric.changeUserLinkText} onClick={selectAccount} />
    </div>
  );

  const usingRemoteNgc = preferredCredential === CredentialType.RemoteNGC;
  const buttonContainerClass =
    !usingRemoteNgc && !showChangeUserLink ? commonStyles.noMarginBottom : undefined;
  const titleId = "confirmSendTitle";

  return (
    <form noValidate spellCheck="false" autoComplete="false" onSubmit={onClickPrimaryButton}>
      <LoginTitleDescriptionFabric titleId={titleId} title={headerText} />
      {descriptionContent}
      <InputContainer>
        <div className={commonStyles.row}>
          <SecondaryContentContainerFabric>
            {showCredentialSwitchContent && (
              <CredentialSwitchContentFabric
                credentialSwitchProps={credSwitchLinkProps}
                showSwitchToEvictedCredPicker={showSwitchToEvictedCredPicker}
              />
            )}
            {showChangeUserLink && showChangeUserLinkContent}
          </SecondaryContentContainerFabric>
        </div>
      </InputContainer>
      <div className={commonStyles.winButtonPinBottom}>
        {showButton && (
          <div className={commonStyles.row}>
            <TextButtonContainer customCss={buttonContainerClass}>
              <TextButtonFabric
                label={buttonText}
                isPrimary
                hasFocus
                type="submit"
                ariaDescribedBy={titleId}
              />
            </TextButtonContainer>
          </div>
        )}
      </div>
    </form>
  );
};
