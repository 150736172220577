import { makeStyles, shorthands } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type TextButtonStylesFabric, useTextButtonStylesFabric } from "../fabric";
import * as textButtonStyleConstants from "./text-button-constants-win11-oobe-fabric.styles";

const useOverrideTextButtonStyles = makeStyles({
  default: {
    backgroundColor: textButtonStyleConstants.DEFAULT_BACKGROUND_COLOR,
    color: textButtonStyleConstants.DEFAULT_COLOR,
    ...shorthands.border("none"),
    minHeight: "40px",
    minWidth: "128px",
    width: "auto !important",
    ...shorthands.padding("8px", "12px", "8px", "12px"),
    lineHeight: "normal",

    ":active": {
      backgroundColor: textButtonStyleConstants.DEFAULT_BACKGROUND_COLOR,
      color: textButtonStyleConstants.DEFAULT_COLOR,
      outlineColor: "none",
      outlineStyle: "none",
      outlineWidth: "none",
    },

    ":focus": {
      backgroundColor: textButtonStyleConstants.DEFAULT_BACKGROUND_COLOR,
      boxShadow: "0px 0px 0px 1px #FFF, 0px 0px 0px 3px #000",
      color: textButtonStyleConstants.DEFAULT_COLOR,
      outlineColor: "none",
      outlineStyle: "none",
      outlineWidth: "none",

      ":active": {
        backgroundColor: textButtonStyleConstants.DEFAULT_BACKGROUND_COLOR,
        color: textButtonStyleConstants.DEFAULT_COLOR,
      },
    },

    ":hover": {
      backgroundColor: textButtonStyleConstants.DEFAULT_BACKGROUND_COLOR,
      color: "#001A68",
      textDecorationLine: "underline",
      textDecorationColor: "underline",
      textDecorationThickness: "underline",
    },
  },

  buttonItem: {
    ":last-child": {
      marginRight: "3px",
    },

    ":global([dir='rtl'])": {
      ":last-child": {
        marginLeft: "30px",
      },
    },
  },

  primary: {
    backgroundColor: textButtonStyleConstants.PRIMARY_BACKGROUND_COLOR,
    color: textButtonStyleConstants.PRIMARY_COLOR,

    ":active": {
      backgroundColor: textButtonStyleConstants.PRIMARY_ACTIVE_BACKGROUND_COLOR,
      color: textButtonStyleConstants.PRIMARY_COLOR,
      outlineColor: "none",
      outlineStyle: "none",
      outlineWidth: "none",
      textDecorationLine: "none",
      textDecorationColor: "none",
      textDecorationThickness: "none",
      "-ms-transform": "scale(1)",
      "-webkit-transform": "scale(1)",
      transform: "scale(1)",
    },

    ":disabled": {
      backgroundColor: textButtonStyleConstants.PRIMARY_DISABLED_BACKGROUND_COLOR,
      ":hover": { backgroundColor: textButtonStyleConstants.PRIMARY_DISABLED_BACKGROUND_COLOR },
    },

    ":focus": {
      backgroundColor: textButtonStyleConstants.PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR,
      color: textButtonStyleConstants.PRIMARY_COLOR,
      textDecorationLine: "none",
      textDecorationColor: "none",
      textDecorationThickness: "none",

      ":active": {
        backgroundColor: textButtonStyleConstants.PRIMARY_ACTIVE_BACKGROUND_COLOR,
        color: textButtonStyleConstants.PRIMARY_COLOR,
      },
    },

    ":hover": {
      backgroundColor: textButtonStyleConstants.PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR,
      color: textButtonStyleConstants.PRIMARY_COLOR,
      textDecorationLine: "none",
      textDecorationColor: "none",
      textDecorationThickness: "none",

      ":disabled": { backgroundColor: textButtonStyleConstants.PRIMARY_DISABLED_BACKGROUND_COLOR },
    },
  },

  winButton: {
    "-ms-high-contrast-adjust": "none",
    "-ms-user-select": "none",
    ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.2169)"),
    ...shorthands.borderColor(textButtonStyleConstants.DEFAULT_BACKGROUND_COLOR),
    ...shorthands.borderRadius("4px"),
    boxSizing: "border-box",
    fontSize: "1rem",
    fontWeight: "400",
    ...shorthands.margin(0),
    minHeight: "40px",
    whiteSpace: "normal",
    width: "144px",

    "&[disabled]": { ":hover": { ...shorthands.border(0) } },

    ":focus": {
      ":focus-visible": { boxShadow: "0px 0px 0px 1px #FFF, 0px 0px 0px 3px #000" },

      boxShadow: "none",
      outlineColor: "none",
      outlineStyle: "none",
      outlineWidth: "none",

      ":active": {
        outlineColor: "none",
        outlineStyle: "none",
        outlineWidth: "none",
      },

      ":hover": {
        ...shorthands.border("none"),

        ":active": { outlineColor: "none", outlineStyle: "none", outlineWidth: "none" },
      },
    },
  },
});

export type TextButtonStylesWin11OobeFabric = TextButtonStylesFabric &
  Partial<ReturnType<typeof useOverrideTextButtonStyles>>;

export const useTextButtonStylesWin11OobeFabric = () =>
  useMergedStyles(
    useTextButtonStylesFabric(),
    useOverrideTextButtonStyles(),
  ) as TextButtonStylesWin11OobeFabric;
