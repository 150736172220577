import { type RefObject, useEffect } from "react";
import { useGlobalContext } from "../global-context";

export const useGamepadFocusTrap = (ref: RefObject<HTMLElement> | undefined) => {
  const {
    globalState: { gamepadManager },
  } = useGlobalContext();

  useEffect(() => {
    const currentContext = ref?.current;

    if (currentContext && gamepadManager) {
      gamepadManager.pushFocusContext(currentContext);
    }

    return () => {
      if (currentContext && gamepadManager) {
        gamepadManager.popFocusContext(currentContext);
      }
    };
  }, [ref, gamepadManager]);
};
