import { type ResponseBody, type ResponseError } from "../../constants";
import { postApiRequest } from "../request/request-helper";

export interface ICanaryValidationRequest {
  PageId: string;
  ActionId: string;
  ConfirmationViewId: number;
  Canary: string;
}

/**
 * This a wrapper method that makes a POST request to the external canary validation API.
 * @param requestUrl The external canary validation request URL.
 * @param requestParams The external canary validation request parameters.
 * @returns A promise that either resolves to the parsed response body or rejects with an error.
 */
export const invokeCanaryValidation = function invokeCanaryValidation(
  requestUrl: string,
  requestParams: ICanaryValidationRequest,
): Promise<ResponseBody | ResponseError> {
  const { PageId, ActionId, ConfirmationViewId, Canary } = requestParams;

  const requestBody = {
    PageId,
    ActionId,
    ConfirmationViewId,
    Canary,
  };

  return postApiRequest(requestUrl, { body: JSON.stringify(requestBody) });
};
