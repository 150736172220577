import { makeStyles, shorthands } from "@griffel/react";

const useFloatingDropdownStylesFabric = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "max-content",
  },

  topButton: {
    ...shorthands.border("none"),
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    ...shorthands.padding("0px"),
    ...shorthands.gap("8px"),
  },

  topButtonImg: {
    display: "flex",
    width: "12px",
    height: "16px",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },

  menuFocusLocker: {
    position: "relative",
  },

  menu: {
    position: "absolute",
    backgroundColor: "#fff",
    backgroundClip: "paddingBox",
    ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.1)"),
    zIndex: 2,
    top: "3px",
    right: 0,
    marginTop: "0px",
    marginBottom: "0px",
    ...shorthands.padding("0px"),
    ":global([dir='rtl'])": {
      left: 0,
      right: "unset",
    },
  },

  menuList: {
    ...shorthands.margin("0px"),
    ...shorthands.padding("0px"),
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    backgroundColor: "#fff",
  },

  menuMargin: {
    ...shorthands.margin(0),
  },

  menuLink: {
    ...shorthands.borderWidth("0px"),
    width: "100%",
    minHeight: "32px",
    textAlign: "left",
    paddingLeft: "14px",
    paddingRight: "14px",
    verticalAlign: "middle",
    display: "block",
    cursor: "pointer",
    lineHeight: "32px",
    color: "inherit",
    ":hover": {
      backgroundColor: "#e6e6e6;",
      cursor: "pointer",
      color: "inherit",
      textDecorationLine: "none !important",
    },
    ":focus": {
      backgroundColor: "rgba(0,0,0,.1)",
      outlineWidth: "1px",
      outlineColor: "#000",
      outlineStyle: "dashed",
    },
    ":active": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
});

export default useFloatingDropdownStylesFabric;

export type FloatingDropdownStylesFabric = ReturnType<typeof useFloatingDropdownStylesFabric>;
