import { useRef, useState } from "react";
import { useAuthContext } from "../../../../authentication-context";
import { RemoteNgcType, UserFlowType } from "../../../../constants";
import { useCustomizationContext } from "../../../../context/customization-context";
import FeaturesConfig from "../../../../features-config";
import GlobalConfig from "../../../../global-config";
import { useGlobalContext } from "../../../../global-context";
import { useBackButtonControl } from "../../../../hooks/use-navigate-direction";
import { getImageSource } from "../../../../utilities/images-helper";
import { copyQueryStringParameters, replaceTokens } from "../../../../utilities/strings-helper";
import LoginConfig from "../../login-config";
import { useLoginContext } from "../../login-context";
import { type CommonLoginStrings } from "../../login-interface";
import { LoginActionType } from "../../login-reducer";
import { getCommonDocumentTitle, getParentSignInPostUrl } from "../../login-util";
import { pushNotificationsViewStringsFabric } from "../fabric/push-notifications-view-strings-fabric";
import {
  type IPushNotificationsViewProperties,
  type IPushNotificationsViewStrings,
} from "../push-notifications-view-interface";
import { getPollingDescription } from "../push-notifications-view-util";
import { useGetOtcForPushNotifications } from "./use-get-otc-for-push-notifications";
import { useIsRequestPendingAndHideBackButton } from "./use-is-request-pending-and-hide-back-button";
import { useSetupAndStartPolling } from "./use-setup-and-start-polling";
import { useStartPolling } from "./use-start-polling";

/**
 * @param strings Flavored strings that are used by this hook
 * @param strings.pushNotificationsViewStrings Strings that are specific to the push notification view
 * @param strings.commonLoginStrings Strings that are common to all login views
 * @returns Push notification view properties
 */
export const usePushNotificationsViewProperties = (strings: {
  pushNotificationsViewStrings: IPushNotificationsViewStrings;
  commonLoginStrings: CommonLoginStrings;
}): IPushNotificationsViewProperties => {
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  const { showButtons, postUrl } = GlobalConfig.instance;

  // Login config
  const { loginMode, lostAuthenticatorUrl, rawQueryString } = LoginConfig.instance;

  // Global state data
  const {
    globalState: {
      debugInfo: { errorCode: serverErrorCode },
      user,
      userFlowType,
    },
  } = useGlobalContext();

  // Customization state data
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  // Authentication state data
  const {
    authState: { flowTokenValue },
  } = useAuthContext();

  // Login state data
  const {
    viewState: { remoteNgcParams },
    dispatchStateChange: dispatchLoginStateChange,
  } = useLoginContext();

  // Remote NGC params from login state
  const {
    showAnimatedGifWhilePolling,
    defaultType,
    entropy: displaySign,
    devices,
    sessionIdentifier,
  } = remoteNgcParams;

  const { commonLoginStrings, pushNotificationsViewStrings } = strings;
  const {
    seeAllDevicesLinkText,
    lostAppLinkText,
    primaryButtonLabel,
    heading,
    headingGenericAppName,
    resendNotificationText,
    lostAppLinkTextGenericAppName,
  } = pushNotificationsViewStrings;

  const documentTitle = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);
  const remoteNgcType = defaultType || RemoteNgcType.PushNotification;

  // The hero app is the most recently used credential and the first item in the devices array
  const heroApp = devices.length > 0 ? devices[0] : { name: "", application: "" };

  let defaultTitle = replaceTokens(heading, heroApp.application);
  let lostAppAccessLinkText = replaceTokens(lostAppLinkText, heroApp.application);
  // In the unlikely scenario that the app name is unknown or not provided, use strings with generic app name instead
  if (!heroApp.application) {
    defaultTitle = headingGenericAppName;
    lostAppAccessLinkText = lostAppLinkTextGenericAppName;
  }

  const [title, setTitle] = useState(defaultTitle);
  const [error, setError] = useState("");
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [seeAllDevicesLinkFocus, setSeeAllDevicesLinkFocus] = useState(!displaySign || !!error);

  const pollingDescription = getPollingDescription(
    remoteNgcType,
    displaySign,
    pushNotificationsViewStringsFabric,
    heroApp,
  );

  // The seeAllDevicesLink is displayed above the animated gif during session polling or below the gif when there's an error.
  const showSeeAllDevicesLink = devices.length > 1;
  const showSeeAllDevicesLinkBelowGif = !!error || !showAnimatedGifWhilePolling;
  const showBackArrowButton = useBackButtonControl() && showButtons;
  const showLostAppAccessLink = !!lostAuthenticatorUrl;

  const gifUrl = getImageSource("generic_app", "gif");
  const updatedPostUrl = rawQueryString
    ? copyQueryStringParameters(rawQueryString, postUrl)
    : postUrl;

  const isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;

  const parentUpdatedPostUrl = getParentSignInPostUrl(
    updatedPostUrl,
    user.displayUsername?.safeHtmlEscapedString || "",
    userFlowType,
  );

  const displaySignRef = useRef<HTMLSpanElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const handleServerError = (serverError: string) => {
    dispatchLoginStateChange({
      type: LoginActionType.SetServerErrorShown,
      payload: true,
    });

    setError(serverError);
  };

  const setIsRequestPendingAndHideBackButton = useIsRequestPendingAndHideBackButton(
    setIsRequestPending,
    showBackArrowButton,
  );

  const startPolling = useStartPolling(
    remoteNgcType,
    sessionIdentifier,
    defaultTitle,
    user,
    formRef,
    setTitle,
    setError,
    setIsRequestPendingAndHideBackButton,
    pushNotificationsViewStrings,
  );

  const triggerOtcRequest = useGetOtcForPushNotifications(
    flowTokenValue,
    sessionIdentifier,
    user,
    startPolling,
    setTitle,
    setError,
    setIsRequestPendingAndHideBackButton,
    pushNotificationsViewStrings,
  );

  const setupAndStartPolling = useSetupAndStartPolling(
    setTitle,
    setError,
    triggerOtcRequest,
    startPolling,
  );

  return {
    devices,
    displaySign,
    displaySignRef,
    documentTitle,
    error,
    formRef,
    gifUrl,
    handleServerError,
    isRequestPending,
    lostAppAccessLinkText,
    pollingDescription,
    postUrl: isParentFlow ? parentUpdatedPostUrl : updatedPostUrl,
    primaryButtonLabel,
    remoteNgcType,
    resendNotificationText,
    seeAllDevicesLinkText,
    seeAllDevicesLinkFocus,
    serverErrorCode,
    showAnimatedGifWhilePolling,
    showBackArrowButton,
    showButtons,
    showSeeAllDevicesLink,
    showSeeAllDevicesLinkBelowGif,
    showLostAppAccessLink,
    setIsRequestPending,
    setSeeAllDevicesLinkFocus,
    setupAndStartPolling,
    title,
  };
};
