/**
 * Function can take any number of arguments and joins classes together.
 * Classnames can be of any length. Must be of type string.
 *
 * Invalid classnames (which are excluded in the final output) are:
 * - not of type 'string'
 * - an empty string
 * - a duplicate of another inputted className
 *
 * @param {...any} classNames a list of strings, each correlated to a css class
 * @returns a single string combining the valid classNames
 *
 * Input:
 * ```
 * mergeClasses('activeControl', 'floatLeft')
 * ```
 *
 * Output:
 * ```
 * 'activeControl floatLeft'
 * ```
 */
export function mergeClasses(...classNames: (string | null | undefined)[]): string {
  if (arguments.length < 1) {
    return "";
  }

  // Iterate through the input arguments. Place valid classNames into finalSet[].
  const finalSet = new Set();

  for (let i = 0; i < classNames.length; i += 1) {
    // removing leading and trailing whitespace
    const className = classNames[i]?.trim();
    // Ensuring that the new className is not already in the array
    if (className && !finalSet.has(className)) {
      finalSet.add(className);
    }
  }

  // Combining array elements into one string (separated by spaces)
  return Array.from(finalSet).join(" ").trim();
}
