import { type UserFlowType } from "../constants";
import { useGlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";

/**
 * This hook handles changing uft before calling navigate so the user can return to the Benefits view.
 *
 * In the "For myself" flow where the user enters a child birthdate:
 * 1. The user is returned to Login with UserFlowType (uft) of Child.
 * 2. initial-view-picker will send the user to the Benefits view.
 * 3. When the user leaves the Benefits view, the active uft will change to Parent.
 * 4. If the user clicks back on the Username view,
 *    initial-view-picker will send uft Parent to the Username,
 *    so the user can not return to the initial Benefits view.
 *
 * @param navigate The function to call when the back button is clicked.
 * @param userFlowType The UserFlowType to set when the back button is clicked.
 * @returns A function that updates the global UserFlowType then calls a navigate method.
 */
export const useBackButtonWithUserFlowTypeChangeHandler = (
  navigate: VoidFunction,
  userFlowType: UserFlowType,
) => {
  const { dispatchStateChange } = useGlobalContext();
  return () => {
    dispatchStateChange({
      type: GlobalActionType.SetUserFlowType,
      payload: userFlowType.toString(),
    });

    navigate();
  };
};
