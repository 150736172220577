/* eslint-disable deprecation/deprecation */
import React from "react";
import { type ViewId } from "../../../constants/routing-constants";
import { type UserCredential } from "../../../model/credential/credential-types";
import ErrorContainerFabric from "../../error-container/fabric/error-container-fabric";
import { LinkButton } from "../../link-button";
import { useCredentialSwitchLinksFabric } from "./credential-switch-links-hooks-fabric-deprecated";

export interface ICredentialSwitchLinkComponentProperties {
  sourceViewId: ViewId;
  availableCredentials: UserCredential[];
  currentCredential: UserCredential;
  setRequestPendingFlag: (isRequestPending: boolean) => void;
  showForgotUsername?: boolean;
  linkAriaDescribedBy?: string;
  hasFocus?: boolean;
  shouldUpdateOtcCredential?: boolean;
}

/**
 * Credential switch links component that provides the necessary convenient
 * links for users to change the credential they would like to use. The component
 * can return either a link to the credential picker view that allows the user to choose
 * from the list of the credentials available to them, or a direct link to the alternative
 * credential if there is only one alternative credential available.
 * TODO: Refactor this component to take in flavor-specific strings as props instead of directly using `getLocalString`
 * NOTE: The component currently has dependencies on configs, contexts and hooks specific to the Login flow.
 * See `credential-switch-links-hooks` module for logic to set the props required for the component.
 * @param props properties for rendering the component
 * @returns A rendered instance of this component
 */
export const CredentialSwitchLinksComponent: React.FC<ICredentialSwitchLinkComponentProperties> =
  function CredentialSwitchLinksComponent(props) {
    const {
      sourceViewId,
      availableCredentials,
      currentCredential,
      setRequestPendingFlag,
      showForgotUsername,
      linkAriaDescribedBy,
      hasFocus,
      shouldUpdateOtcCredential,
    } = props;

    const {
      showCredentialSwitchLink,
      credentialSwitchLinkId,
      credentialSwitchLinkText,
      credentialSwitchLinkOnClickHandler,
      showCredentialPickerLink,
      credentialPickerLinkText,
      credentialPickerLinkOnClickHandler,
      showForgotUsernameLink,
      forgotUsernameLinkId,
      forgotUsernameLinkText,
      forgotUsernameLinkOnClick,
      errorMessage,
      readyToRender,
    } = useCredentialSwitchLinksFabric(
      sourceViewId,
      availableCredentials,
      currentCredential,
      setRequestPendingFlag,
      showForgotUsername,
      shouldUpdateOtcCredential,
    );

    if (readyToRender) {
      return (
        <>
          {showCredentialPickerLink && (
            <LinkButton
              linkDataTestId="credentialPickerLink"
              linkId="idA_PWD_SwitchToCredPicker"
              text={credentialPickerLinkText}
              ariaDescribedBy={linkAriaDescribedBy}
              onClick={credentialPickerLinkOnClickHandler}
              hasFocus={hasFocus}
            />
          )}

          {showCredentialSwitchLink && (
            <LinkButton
              linkId={credentialSwitchLinkId}
              text={credentialSwitchLinkText}
              ariaDescribedBy={linkAriaDescribedBy}
              onClick={credentialSwitchLinkOnClickHandler}
              hasFocus={hasFocus}
            />
          )}

          {showForgotUsernameLink && (
            <LinkButton
              linkId={forgotUsernameLinkId}
              text={forgotUsernameLinkText}
              ariaDescribedBy={linkAriaDescribedBy}
              onClick={forgotUsernameLinkOnClick}
              hasFocus={hasFocus}
            />
          )}

          {errorMessage && (
            <ErrorContainerFabric errorCode="credential-switch-error">
              {errorMessage}
            </ErrorContainerFabric>
          )}
        </>
      );
    }

    return <div data-testid="credential-switch-links-placeholder" />;
  };
