import { useContext, useState } from "react";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import { useBackButtonControl } from "../../../../../hooks";
import { getProofType } from "../../../../../model/proof";
import { getShowChangeUserLink } from "../../../login-util";
import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";
import { getButtonText } from "./get-dynamic-strings";
import { type ConfirmSendCredentials } from "./use-credentials";
import { useDescription } from "./use-description";

/**
 * This hook is used to generate the display properties for the ConfirmSendView
 * @param credentials The ConfirmSendView credentials
 * @param confirmSendViewStrings The strings for the ConfirmSendView
 * @returns The display properties to use for the ConfirmSendView
 */
export const useDisplayProperties = (
  credentials: ConfirmSendCredentials,
  confirmSendViewStrings: ConfirmSendViewStrings,
) => {
  const [requestIsPending, setRequestIsPending] = useState(false);
  const [hasError, setHasError] = useState(false);
  const {
    globalState: { user },
  } = useContext(GlobalContext);
  const { currentCredential, preferredCredential } = credentials;
  const { showButtons } = GlobalConfig.instance;
  const isInitialView = !useBackButtonControl();
  const showChangeUserLink = getShowChangeUserLink(isInitialView);

  const proofType = getProofType(currentCredential, user);
  const [buttonText, setButtonText] = useState(
    getButtonText(confirmSendViewStrings, preferredCredential),
  );

  const description = useDescription(
    currentCredential,
    preferredCredential,
    user,
    hasError,
    proofType,
    confirmSendViewStrings,
  );

  return {
    showChangeUserLink,
    buttonText,
    setButtonText,
    hasError,
    setHasError,
    description,
    requestIsPending,
    setRequestIsPending,
    showButton: showButtons && !requestIsPending,
  };
};
