import React, { useRef } from "react";
import {
  type IImageButtonFabricProps,
  ImageButtonFabric,
} from "../../image-button/fabric/image-button-fabric";

export type IAudioPlayerButtonFabricProps = IImageButtonFabricProps & {
  /** The props for the audio url */
  audioUrl: string;
  /** The props for the tooltip text */
  tooltipText?: string;
  /** The props for the Not-Supported text */
  notSupportedText?: string;
};

/**
 * Audio Player Button component
 * @param props audioPlayerButtonProps
 * @returns an instance of the shared audio-player button component
 */
export const AudioPlayerButtonFabric: React.FC<IAudioPlayerButtonFabricProps> =
  function AudioPlayerButtonFabric(props) {
    const audioRef = useRef<HTMLAudioElement>(null);

    const {
      audioUrl,
      buttonId = "idBtn_AudioPlayer",
      dataTestId = "audioPlayerButton",
      ariaLabel,
      ariaDescribedBy,
      className,
      image,
      notSupportedText,
      tooltipText,
      onClick,
      children,
      ...imageProps
    } = props;

    const playAudio = (event: React.SyntheticEvent<HTMLElement>) => {
      audioRef.current?.play?.();

      if (onClick) {
        onClick(event);
      }
    };

    return (
      <div
        /** allow the browser to display a tooltip when the mouse hovers over the image/button */
        title={tooltipText}
      >
        <ImageButtonFabric
          {...imageProps}
          image={image}
          buttonId={buttonId}
          ariaLabel={ariaLabel}
          ariaDescribedBy={ariaDescribedBy}
          className={className}
          onClick={playAudio}
        >
          {children}
          <audio ref={audioRef} src={audioUrl} data-testid={dataTestId}>
            {notSupportedText}
            <track kind="captions" label={ariaLabel} />
          </audio>
        </ImageButtonFabric>
      </div>
    );
  };
