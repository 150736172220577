import React, { useRef, useState } from "react";
import StylesConfig from "../../config/styles-config";
import { mergeClasses } from "../../utilities/merge-classes";
import { type AccessibleImageProps } from "../accessible-image";
import { FocusLocker } from "../focus-locker";
import { ImageButtonFabric } from "../image-button/fabric/image-button-fabric";
import { LinkButton } from "../link-button";
import { useCloseOnOutsideClick } from "./hooks/use-close-on-outside-click";

export interface IOptionsMenuItemPropsDeprecated {
  itemId: string;
  itemText: string;
  isVisible?: boolean;
  hasFocus?: boolean;
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
}

export interface IOptionsMenuButtonPropsDeprecated {
  icon: AccessibleImageProps;
  menuButtonIconClassName?: string;
  menuItemList: IOptionsMenuItemPropsDeprecated[];
  menuTestId?: string;
  ariaLabel?: string;
}

/**
 * @deprecated
 *
 * @param props The properties required for this component
 * @returns OptionsMenuButton component
 */
export const OptionsMenuButtonDeprecated: React.FC<IOptionsMenuButtonPropsDeprecated> =
  function OptionsMenuButtonDeprecated(props) {
    const { icon, menuButtonIconClassName, menuItemList, menuTestId, ariaLabel } = props;
    const { useOptionsMenuButtonStyles } = StylesConfig.instance;
    const menuButtonStyles = useOptionsMenuButtonStyles();
    const optionsMenu = useRef<HTMLInputElement>(null);
    const menuButton = useRef<HTMLButtonElement>(null);
    const [openMenu, setOpenMenu] = useState(false);
    icon.className = mergeClasses(icon?.className || "", menuButtonStyles.accessibleImageAlign);

    // call hook that closes menu when user clicks outside of it
    useCloseOnOutsideClick(optionsMenu, setOpenMenu);

    /**
     * Handle when Escape is pressed on the menu items pop-up
     * @param ev the keyboard event that triggered this function
     */
    const handleEscape = (ev: React.KeyboardEvent<HTMLUListElement>) => {
      if (ev.key === "Escape") {
        setOpenMenu(false);
        menuButton.current?.focus();
      }
    };

    const menuItems = menuItemList.map((item) => (
      <div key={item.itemId}>
        {item.isVisible && (
          <li role="presentation" className={menuButtonStyles.menuMargin}>
            <LinkButton
              buttonRole="menuitem"
              linkId={item.itemId}
              className={menuButtonStyles.menuLink}
              text={item.itemText}
              onClick={item.onClick}
              hasFocus={item.hasFocus}
            />
          </li>
        )}
      </div>
    ));

    return (
      <div ref={optionsMenu}>
        <span className={menuButtonIconClassName} data-testid="iconImage">
          <ImageButtonFabric
            ref={menuButton}
            buttonId="menubutton"
            dataTestId={menuTestId || "options-menu-button"}
            ariaHasPopup="menu"
            ariaLabel={ariaLabel}
            ariaExpanded={openMenu}
            className={menuButtonStyles.menuButtonImageStyle}
            image={icon}
            onClick={() => setOpenMenu(!openMenu)}
          />
        </span>

        {openMenu && (
          <FocusLocker>
            <ul
              className={menuButtonStyles.menu}
              role="menu"
              aria-labelledby="menubutton"
              onKeyDown={handleEscape}
            >
              {menuItems}
            </ul>
          </FocusLocker>
        )}
      </div>
    );
  };
