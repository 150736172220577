import { AadErrorCode } from "../../../../../constants";
import { type ConfirmSendCredentialTypes, CredentialType } from "../../../../../model/credential";
import LoginConfig from "../../../login-config";
import { getCommonLightboxTitle, isForceSignIn } from "../../../login-util";
import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";

/**
 * Note: This method should NOT be used outside the `useDescription` hook, which implements the entirety of the description logic
 * @param confirmSendViewStrings Strings for the confirm send view
 * @param preferredCredential The preferred credential for the user
 * @param isEmailDestination Whether the destination for the code is email
 * @param errorCode A potential error code from the Server
 * @returns A description string based on the preferred credential, whether it's an email destination, or has an error from the Server.
 */
export const getDescriptionString = (
  confirmSendViewStrings: ConfirmSendViewStrings,
  preferredCredential: ConfirmSendCredentialTypes,
  isEmailDestination: boolean,
  errorCode?: string,
) => {
  const forceSignIn = isForceSignIn();
  const {
    confirmSendDescriptionOtcASLP,
    confirmSendDescriptionOtcEmail,
    confirmSendDescriptionOtcForceSignin,
    confirmSendDescriptionPhoneDisambig,
    confirmSendDescriptionRemoteNgc,
    confirmSendDescriptionRemoteNgcASLP,
    confirmSendDescriptionRemoteNgcForceSignin,
  } = confirmSendViewStrings;

  if (preferredCredential === CredentialType.RemoteNGC) {
    if (errorCode === AadErrorCode.SsoArtifactExpiredDueToConditionalAccess) {
      return confirmSendDescriptionRemoteNgcASLP;
    }

    if (forceSignIn) {
      return confirmSendDescriptionRemoteNgcForceSignin;
    }

    return confirmSendDescriptionRemoteNgc;
  }

  // OneTimeCode or PublicIdentifierCode
  if (errorCode === AadErrorCode.SsoArtifactExpiredDueToConditionalAccess) {
    return confirmSendDescriptionOtcASLP;
  }

  if (forceSignIn) {
    return confirmSendDescriptionOtcForceSignin;
  }

  if (isEmailDestination) {
    return confirmSendDescriptionOtcEmail;
  }

  return confirmSendDescriptionPhoneDisambig;
};

/**
 * @param confirmSendViewStrings Strings for the confirm send view
 * @param preferredCredential The preferred credential for the user
 * @returns The correct text to use in the ConfirmSendView's primary (only) button
 */
export const getButtonText = function getButtonText(
  confirmSendViewStrings: ConfirmSendViewStrings,
  preferredCredential: ConfirmSendCredentialTypes,
) {
  const {
    confirmSendDescriptionRemoteNgcContinueSignin,
    confirmSendDescriptionRemoteNgcSendNotification,
    tfaSendCodeButtonText,
  } = confirmSendViewStrings;

  const { riskySignIn } = LoginConfig.instance;

  if (preferredCredential === CredentialType.RemoteNGC) {
    return riskySignIn
      ? confirmSendDescriptionRemoteNgcContinueSignin
      : confirmSendDescriptionRemoteNgcSendNotification;
  }

  // OneTimeCode or PublicIdentifierCode
  return tfaSendCodeButtonText;
};

/**
 * @returns Some localized strings that are used by the ConfirmSendView
 */
export const getHeaderText = (): string => {
  const { appBrandedSignInHeader, defaultSignInHeader, loginStringsVariant } = LoginConfig.instance;
  return getCommonLightboxTitle(loginStringsVariant, defaultSignInHeader, appBrandedSignInHeader);
};
