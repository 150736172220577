import React from "react";
import { getAccessibleImageUrls } from "../../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "../accessible-image";

export type CriticalErrorPageProps = {
  errorTitle: string;
  outerClassName?: string;
  containerStyleName?: string;
  titleStyleName?: string;
  errorDescription?: string;
  errorCode?: string;
};

/**
 * @param props CriticalErrorPageProps
 * @returns a component displaying a critical error that's displayed when the expected UX
 * cannot render due to an error.
 */
const CriticalErrorPage: React.FC<CriticalErrorPageProps> = function CriticalErrorPage(props) {
  const {
    errorTitle,
    outerClassName = "",
    containerStyleName = "",
    titleStyleName = "",
    errorDescription,
    errorCode,
  } = props;

  return (
    <div className={outerClassName}>
      <div className={containerStyleName}>
        <h1>
          <AccessibleImage urls={getAccessibleImageUrls("microsoft_logo")} />
        </h1>

        <div>
          <div className={titleStyleName}>{errorTitle}</div>
          <p>
            {{ errorCode } && <span>{errorCode} </span>}
            {{ errorDescription } && <span>{errorDescription}</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CriticalErrorPage;
