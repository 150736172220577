import React from "react";
import { ChallengeSwitchFabric } from "../../../components/challenge/fabric/challenge-switch-fabric";
import {
  type ViewContainerProps,
  ViewContainerFabric,
} from "../../../components/view-container/fabric/view-container-fabric";
import { type FlowId, HipType, ViewId } from "../../../constants";
import { useActivateViewWithDocumentTitle } from "../../../hooks";
import { type ChallengeSwitchPropsFabric } from "../challenge-types";
import { challengeStringsFabric as strings } from "./challenge-strings-fabric";

/**
 * Shared Challenge view that can be consumed by any fabric flows.
 * @param props the props of the component
 * @param props.flowId the id of the flow hosting the challenge
 * @param props.challengeViewProps props needed to decide which challenge to launch
 * @returns the Challenge View
 */
export const ChallengeViewRendererFabric = function ChallengeViewRendererFabric(props: {
  challengeViewProps: ChallengeSwitchPropsFabric;
  flowId: FlowId;
}) {
  const { challengeViewProps, flowId } = props;
  const { challengeType, container, activateViewOptions } = challengeViewProps;

  const viewContainerProps: ViewContainerProps =
    challengeType !== HipType.Block
      ? {
          title: {
            title: strings.solveTitle,
            titleId: "challengeViewTitle",
          },
          ...container,
        }
      : {
          title: {
            title: strings.blockTitle,
            titleId: "SmsBlockTitle",
          },
          description: {
            description: strings.blockDescription,
            descriptionId: "SmsBlockDescription",
          },
          ...container,
        };

  useActivateViewWithDocumentTitle(
    viewContainerProps.title?.title || strings.solveTitle,
    ViewId.Challenge,
    flowId,
    { showBackButtonOnActiveView: true, ...activateViewOptions },
  );

  return (
    <ViewContainerFabric {...viewContainerProps} key="challenge-view">
      <ChallengeSwitchFabric {...challengeViewProps} />
    </ViewContainerFabric>
  );
};
