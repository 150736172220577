import React from "react";
import { FlowId } from "../../../../constants";
import { type OneTimeCodeCredential } from "../../../../model/credential";
import { ProofType } from "../../../../model/proof";
import { OneTimeCodeUrl } from "../../../../utilities/api-helpers/one-time-code/one-time-code-constants";
import { OtcPurposes } from "../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { ChallengeViewRendererFabric } from "../../../../views/challenge/fabric/challenge-view-renderer-fabric";
import { useChallengeViewPropertiesFabric } from "../../../../views/challenge/fabric/hooks/use-challenge-view-properties-fabric";
import {
  getOtcRequest,
  useGetOtcOnSuccess,
  useGetOtcParams,
} from "../../../../views/challenge/hooks/use-get-otc-request";
import { useTriggerChallenge } from "../../../../views/challenge/hooks/use-trigger-challenge";
import { useValidateChallenge } from "../../../../views/challenge/hooks/use-validate-challenge";
import { useLoginContext } from "../../login-context";

/**
 * @returns the LoginChallengeViewFabric component
 */
export const LoginChallengeViewFabric = function LoginChallengeViewFabric() {
  const getOtcSuccess = useGetOtcOnSuccess();
  const triggerChallenge = useTriggerChallenge();
  const defaultParams = useGetOtcParams();
  const {
    viewState: {
      credentials: {
        otcCredential = {
          proof: { type: ProofType.SMS, data: "", isEncrypted: false, isNopa: false },
        },
      },
    },
  } = useLoginContext();
  const {
    proof: { type: proofType, data: proofData, isEncrypted, isNopa },
  } = otcCredential as OneTimeCodeCredential;
  const purpose = isNopa ? OtcPurposes.noPassword : OtcPurposes.otcLogin;

  const challengeValidateHandler = useValidateChallenge({
    requestHandler: getOtcRequest,
    validationSuccessHandler: getOtcSuccess,
    validationErrorHandler: triggerChallenge,
    handoverErrorHandler: undefined, // login challenge does not forward errors to main flow
    url: OneTimeCodeUrl,
    flowRequestParams: { ...defaultParams, proofType, proofData, isEncrypted, purpose },
  });

  const challengeViewProperties = useChallengeViewPropertiesFabric(challengeValidateHandler);
  return (
    <ChallengeViewRendererFabric
      challengeViewProps={challengeViewProperties}
      flowId={FlowId.Login}
    />
  );
};
