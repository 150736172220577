import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteAnimation from "../../../components/route-animation/fabric/route-animation-fabric";
import { AgreementViewRoute, FlowId, ViewId } from "../../../constants/routing-constants";
import { getRouteFromViewId } from "../../../utilities/routing-helper";
import { AgreementViewFabric } from "../../../views";
import { FetchSessionsProgressViewFabric } from "../fetch-sessions-progress/fabric/fetch-sessions-progress-view-fabric";
import { FidoViewFabric } from "../fido/fabric/fido-view-fabric";
import { IdpDisambiguationViewFabric } from "../idp-disambiguation/fabric/idp-disambiguation-view-fabric";
import { IdpRedirectViewFabric } from "../idp-redirect/fabric/idp-redirect-view-fabric";
import { IdpRedirectSpeedbumpViewFabric } from "../idp-redirect-speedbump/fabric/idp-redirect-speedbump-view-fabric";
import { LoginInitialViewPickerFabric } from "../initial-view-picker/fabric/initial-view-picker-fabric";
import { LearnMoreOfflineAccountViewFabric } from "../learn-more-offline-account/fabric/learn-more-offline-account-view-fabric";
import { PasskeyInterstitialViewFabric } from "../passkey-interstitial/fabric/passkey-interstitial-view-fabric";
import { PushNotificationsViewFabric } from "../push-notifications/fabric/push-notifications-view-fabric";
import { RemoteConnectCanaryValidationViewFabric } from "../remote-connect-canary-validation/fabric/remote-connect-canary-validation-view-fabric";
import { SeeHowDataIsManagedViewFabric } from "../see-how-data-is-managed/fabric/see-how-data-is-managed-view-fabric";
import { SignInOptionsViewFabric } from "../signin-options/fabric/signin-options-view-fabric";
import { BenefitsViewFabric } from "../views/benefits/fabric/benefits-view-fabric";
import { LoginChallengeViewFabric } from "../views/challenge/login-challenge-view-fabric";
import {
  AccountPickerViewFabric,
  ConfirmSendViewFabric,
  CredentialPickerViewFabric,
  ErrorViewFabric,
  PasswordViewFabric,
  UsernameViewFabric,
} from "../views/fabric";
import { OneTimeCodeViewFabric } from "../views/one-time-code/fabric/one-time-code-view-fabric";
import { PhoneDisambiguationViewFabric } from "../views/phone-disambiguation/fabric/phone-disambiguation-view-fabric";
import { ProofConfirmationViewFabric } from "../views/proof-confirmation/fabric/proof-confirmation-view-fabric";

/**
 * The Login flow component
 * @returns An instance of this component.
 * NOTE: This will be eventually replaced by ../login-flow-fabric.tsx
 */
export const LoginFlowFabric: React.FC = function LoginFlowFabric() {
  return (
    <Routes>
      <Route path="*" element={<LoginInitialViewPickerFabric />} />
      <Route path={getRouteFromViewId(ViewId.Username)} element={<UsernameViewFabric />} />
      <Route path={getRouteFromViewId(ViewId.Password)} element={<PasswordViewFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.PhoneDisambiguation)}
        element={<PhoneDisambiguationViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignInOptions)}
        element={<SignInOptionsViewFabric />}
      />
      <Route path={getRouteFromViewId(ViewId.Error)} element={<ErrorViewFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.IdpDisambiguation)}
        element={<IdpDisambiguationViewFabric />}
      />
      <Route path={getRouteFromViewId(ViewId.IdpRedirect)} element={<IdpRedirectViewFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.PushNotifications)}
        element={<PushNotificationsViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.AccountPicker)}
        element={<AccountPickerViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.RemoteConnectCanaryValidation)}
        element={<RemoteConnectCanaryValidationViewFabric nextView={ViewId.Username} />}
      />
      <Route
        path={getRouteFromViewId(ViewId.CredentialPicker)}
        element={<CredentialPickerViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.IdpRedirectSpeedbump)}
        element={<IdpRedirectSpeedbumpViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.FetchSessionsProgress)}
        element={<FetchSessionsProgressViewFabric />}
      />
      <Route path={getRouteFromViewId(ViewId.Fido)} element={<FidoViewFabric />} />
      <Route path={getRouteFromViewId(ViewId.OneTimeCode)} element={<OneTimeCodeViewFabric />} />
      <Route path={getRouteFromViewId(ViewId.ConfirmSend)} element={<ConfirmSendViewFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.ProofConfirmation)}
        element={<ProofConfirmationViewFabric />}
      />
      <Route path={getRouteFromViewId(ViewId.Challenge)} element={<LoginChallengeViewFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.LearnMoreOfflineAccount)}
        element={<LearnMoreOfflineAccountViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SeeHowDataIsManaged)}
        element={<SeeHowDataIsManagedViewFabric />}
      />
      <Route path={getRouteFromViewId(ViewId.Benefits)} element={<BenefitsViewFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.LoginPasskeyInterstitial)}
        element={<PasskeyInterstitialViewFabric />}
      />
    </Routes>
  );
};

/**
 * A wrapper component for the routes defined for LoginApp. Includes Login Flow.
 * @returns An instance of this component
 */
export const LoginRoutesFabric: React.FC = function LoginRoutesFabric() {
  return (
    <RouteAnimation>
      <Route path="*" element={<LoginFlowFabric />} />
      <Route
        path={AgreementViewRoute}
        element={<AgreementViewFabric hostingFlow={FlowId.Login} />}
      />
    </RouteAnimation>
  );
};
