import { makeStyles, shorthands } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type DebugDetailsBannerStylesFabric, useDebugDetailsBannerStylesFabric } from "../fabric";
import * as commonStyleConstants from "./constants-win11-oobe-fabric.styles";

const useOverrideDebugDetailsBannerStyles = makeStyles({
  banner: {
    backgroundColor: "transparent",
    ...shorthands.padding(
      `${commonStyleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      `${commonStyleConstants.LIGHTBOX_PADDING}px`,
    ),
    maxWidth: `${commonStyleConstants.LIGHTBOX_MAX_WIDTH}px`,

    [commonStyleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "#f2f2f2",
      ...shorthands.padding(`${commonStyleConstants.LIGHTBOX_MOBILE_PADDING}px`),
      WebkitBoxShadow: "none",
      "-moz-box-shadow": "none",
      boxShadow: "none",
      tableLayout: "auto",
    },
  },

  bannerVerticalSplit: {
    backgroundColor: "transparent",
  },
});

export type DebugDetailsBannerStylesWin11OobeFabric = DebugDetailsBannerStylesFabric &
  Partial<ReturnType<typeof useOverrideDebugDetailsBannerStyles>>;

export const useDebugDetailsBannerStylesWin11OobeFabric = () =>
  useMergedStyles(
    useDebugDetailsBannerStylesFabric(),
    useOverrideDebugDetailsBannerStyles(),
  ) as DebugDetailsBannerStylesWin11OobeFabric;
