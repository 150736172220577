import { useCustomizationContext } from "../../../../../context/customization-context";
import { type OneTimeCodeCredential } from "../../../../../model/credential/credential-types";
import { isCredentialEmailProofType } from "../../../../../utilities/credential-helper";
import LoginConfig from "../../../login-config";
import { type CommonLoginStrings } from "../../../login-interface";
import { getCommonDocumentTitle } from "../../../login-util";
import { type IProofConfirmationStrings } from "../proof-confirmation-interface";
import {
  type ITextInputAttributes,
  type ITitleDescStrings,
  getTextInputAttributes,
  getTitleAndDescription,
} from "../proof-confirmation-view-util";

export type IProofConfirmationViewProperties = ITitleDescStrings &
  ITextInputAttributes & {
    documentTitle: string;
    lightboxTitleId: string;
    lightboxDescriptionId: string;
    inputAriaLabel: string;
    inputAriaDescribedBy: string;
  };

/**
 * @param otcCredential the one time code credential
 * @param commonLoginStrings Common login strings
 * @param proofConfirmationStrings Strings for the Proof Confirmation View

 * @returns Proof Confirmation view properties
 */
export const useProofConfirmationViewProperties = (
  otcCredential: OneTimeCodeCredential,
  commonLoginStrings: CommonLoginStrings,
  proofConfirmationStrings: IProofConfirmationStrings,
): IProofConfirmationViewProperties => {
  // Global context data
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  // Login config data
  const { loginMode } = LoginConfig.instance;

  const {
    proof: { clearDigits = "", display },
  } = otcCredential;

  const documentTitle = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);

  const lightboxTitleId = "proofConfirmationTitle";
  const lightboxDescriptionId = "proofConfirmationDesc";
  const inputAriaDescribedBy = `${lightboxTitleId} ${lightboxDescriptionId}`;

  const isEmailProof = isCredentialEmailProofType(otcCredential);
  const lightboxStrings = getTitleAndDescription(
    isEmailProof,
    display,
    clearDigits,
    proofConfirmationStrings,
  );
  const textInputAttributes = getTextInputAttributes(isEmailProof, proofConfirmationStrings);

  return {
    ...lightboxStrings,
    ...textInputAttributes,
    documentTitle,
    lightboxTitleId,
    lightboxDescriptionId,
    inputAriaLabel: proofConfirmationStrings.inputAriaLabel,
    inputAriaDescribedBy,
  };
};
