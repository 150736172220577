import { useEffect, useMemo, useState } from "react";
import { type FlowId, ViewId } from "../constants/routing-constants";
import GlobalConfig from "../global-config";
import { useGlobalContext } from "../global-context";
import { type ViewOptions, defaultViewOptions, GlobalActionType } from "../global-reducer";
import { FOCUS_TIMEOUT } from "../styles/fabric/layout-animate-fabric.styles";
import { getStandardDimensions, TelemetryEventType } from "../telemetry-helpers/telemetry-helper";
import { setDefaultFocus } from "../utilities/browser-helper";
import { useDocumentTitle } from "./use-document-title";

export type ActivateViewOptions = Partial<ViewOptions>;
export type DeactivateViewOptions = { restoreShowPageTitle?: boolean };

/**
 * React hook to put focus on the preferred HTML Element
 * @param prefEleRef HTML Element reference
 */
export const useSetDefaultFocus = (prefEleRef: HTMLElement | null) => {
  useEffect(() => {
    if (prefEleRef == null) {
      return () => {};
    }

    const focusTimeout = setTimeout(() => {
      setDefaultFocus(prefEleRef);
    }, FOCUS_TIMEOUT);

    return () => clearTimeout(focusTimeout);
  }, [prefEleRef]);
};

/**
 * React hook used to indicate that a view has activated. Should be called from within the view's render function.
 * @param view The view to activate
 * @param flow The flow that this view belongs to
 * @param activateViewOptions The optional view properties to apply during view activation
 * @param deactivateViewOptions The optional view properties to apply during view deactivation
 */
export function useActivateView(
  view: ViewId,
  flow: FlowId,
  activateViewOptions?: ActivateViewOptions,
  deactivateViewOptions?: DeactivateViewOptions,
) {
  const { activeFlavor } = GlobalConfig.instance;
  const {
    globalState: { showPageTitle },
    dispatchStateChange: dispatchGlobalStateChange,
  } = useGlobalContext();

  const [originalShowPageTitle] = useState<boolean>(showPageTitle);

  const viewOptions: ViewOptions = useMemo(() => ({ ...defaultViewOptions }), []);
  const updatedViewOptions: Partial<ViewOptions> = useMemo(() => ({}), []);

  // View options to apply during view activation
  if (activateViewOptions?.backButtonHandler !== undefined) {
    viewOptions.backButtonHandler = activateViewOptions.backButtonHandler;
  }

  if (activateViewOptions?.focusEleRef !== undefined) {
    viewOptions.focusEleRef = activateViewOptions.focusEleRef;
  }

  if (activateViewOptions?.hideBannerLogo !== undefined) {
    viewOptions.hideBannerLogo = activateViewOptions.hideBannerLogo;
  }

  if (activateViewOptions?.isWideView !== undefined) {
    viewOptions.isWideView = activateViewOptions.isWideView;
  }

  if (activateViewOptions?.loadingData !== undefined) {
    viewOptions.loadingData = activateViewOptions.loadingData;
  }

  if (activateViewOptions?.showBackButtonOnActiveView !== undefined) {
    viewOptions.showBackButtonOnActiveView = activateViewOptions.showBackButtonOnActiveView;
  }

  if (activateViewOptions?.showCloseButtonOnActiveView !== undefined) {
    viewOptions.showCloseButtonOnActiveView = activateViewOptions.showCloseButtonOnActiveView;
  }

  if (activateViewOptions?.backButtonHandler !== undefined) {
    viewOptions.backButtonHandler = activateViewOptions.backButtonHandler;
  }

  if (activateViewOptions?.closeButtonHandler !== undefined) {
    viewOptions.closeButtonHandler = activateViewOptions.closeButtonHandler;
  }

  if (activateViewOptions?.hideBannerLogo !== undefined) {
    viewOptions.hideBannerLogo = activateViewOptions.hideBannerLogo;
  }

  if (activateViewOptions?.loadingData !== undefined) {
    viewOptions.loadingData = activateViewOptions.loadingData;
  }

  if (activateViewOptions?.showIdentityBanner !== undefined) {
    viewOptions.showIdentityBanner = activateViewOptions.showIdentityBanner;
  }

  if (activateViewOptions?.showMoreOptions !== undefined) {
    viewOptions.showMoreOptions = activateViewOptions.showMoreOptions;
  }

  if (activateViewOptions?.showPageTitle !== undefined) {
    viewOptions.showPageTitle = activateViewOptions.showPageTitle;
  }

  if (activateViewOptions?.disableHeightAnimation !== undefined) {
    viewOptions.disableHeightAnimation = activateViewOptions.disableHeightAnimation;
  }

  if (activateViewOptions?.setDefaultFocusInFooter !== undefined) {
    viewOptions.setDefaultFocusInFooter = activateViewOptions.setDefaultFocusInFooter;
  }

  // View options to apply during view deactivation
  if (deactivateViewOptions?.restoreShowPageTitle) {
    updatedViewOptions.showPageTitle = originalShowPageTitle;
  }

  useSetDefaultFocus(viewOptions.focusEleRef);

  useEffect(() => {
    dispatchGlobalStateChange({
      type: GlobalActionType.ActivateView,
      view,
      flow,
      flavor: activeFlavor,
      viewOptions,
    });

    if (view !== ViewId.None) {
      GlobalConfig.instance?.telemetry?.addEvent({
        _table: TelemetryEventType.ServiceDiag,
        metricName: "ViewActivation",
        metricValue: "1",
        dimensions: getStandardDimensions({ activeView: view, activeFlow: flow, activeFlavor }),
      });
    }

    return () => {
      dispatchGlobalStateChange({
        type: GlobalActionType.DeactivateView,
        view,
        viewOptions: updatedViewOptions,
      });
    };
  }, [dispatchGlobalStateChange, view, flow, activeFlavor, viewOptions, updatedViewOptions]);
}

export const useActivateViewWithDocumentTitle = (
  documentTitle: string,
  view: ViewId,
  flow: FlowId,
  activateViewOptions?: ActivateViewOptions,
  deactivateViewOptions?: DeactivateViewOptions,
) => {
  const [setDocumentTitle] = useDocumentTitle(documentTitle);
  useActivateView(view, flow, activateViewOptions, deactivateViewOptions);

  return [documentTitle, setDocumentTitle];
};
