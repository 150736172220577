import { Flavors } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useEffectOnce } from "../../../../../hooks";
import {
  handleBackButtonEvent,
  isBackButtonVisible,
} from "../../../../../utilities/cxh-handlers/cxh-handler";
import { handleFinishState } from "../../../../../utilities/cxh-handlers/windows/cxh-handler-windows";
import LoginConfig from "../../../login-config";

export const useHandleFinishState = () => {
  const { isScoobe, isTokenBroker, preferAssociate } = LoginConfig.instance;

  return async () => {
    handleFinishState(isScoobe, isTokenBroker, preferAssociate);
  };
};

/**
 * Username view CXH back button handler
 */
export const useUsernameViewBackButtonHandlerWithCxh = () => {
  const handleBackFinishState = useHandleFinishState();

  useEffectOnce(() => {
    const { activeFlavor } = GlobalConfig.instance;
    const { isOobe, postedForceSignIn, lockUsername, isOverTheShoulder } = LoginConfig.instance;

    // if Windows, register CXH back button event listener
    if (activeFlavor === Flavors.Win11OobeFabric) {
      isBackButtonVisible(
        isOobe,
        postedForceSignIn,
        lockUsername,
        isOverTheShoulder,
        (backButtonVisible: boolean) => {
          if (backButtonVisible) {
            handleBackButtonEvent(handleBackFinishState);
          } else {
            handleBackButtonEvent();
          }
        },
      );
    }
  });
};
