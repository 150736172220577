import type * as GamepadNavigationModule from "@gaming/react-gamepad-navigation-lite";
import { type FlowId, ViewId } from "./constants/routing-constants";
import { type IUser } from "./model/user";
import { getStandardDimensions, TelemetryEventType } from "./telemetry-helpers/telemetry-helper";
import {
  type UntrustedExternalInputText,
  isTextUntrustedExternalInputText,
  setUntrustedExternalInputText,
} from "./utilities/untrusted-external-input-text";
import { type Flavors, UserFlowType } from "./constants";
import GlobalConfig from "./global-config";
import type {
  GlobalState,
  IDebugInfo,
  IMoreOptionsProps,
  NavigationDirection,
} from "./global-context";

/**
 * Action types that are accepted by the global reducer
 */
export enum GlobalActionType {
  ActivateView,
  BeginNavigate,
  DataLoaded,
  DeactivateView,
  HideBackArrowButton,
  SetDebugDetails,
  SetShowProgressIndicator,
  SetUser,
  SetUserFlowType,
  ShowCloseInBanner,
  ToggleDebugMode,
  SetGamepadNavigationModule,
}

export interface ViewOptions {
  focusEleRef: HTMLElement | null;
  hideBannerLogo: boolean;
  isWideView: boolean;
  loadingData: boolean;
  showBackButtonOnActiveView: boolean;

  /* Fluent flavors only - defaults to false */
  showCloseButtonOnActiveView: boolean;
  disableHeightAnimation: boolean;

  setDefaultFocusInFooter: boolean;
  showIdentityBanner: boolean;
  showMoreOptions?: IMoreOptionsProps;
  showPageTitle?: boolean;
  backButtonHandler?: () => void;

  /* Fluent flavors only */
  closeButtonHandler?: (event: React.SyntheticEvent<HTMLElement>) => void;
}

export const defaultViewOptions: ViewOptions = {
  disableHeightAnimation: false,
  focusEleRef: null,
  hideBannerLogo: false,
  isWideView: false,
  loadingData: false,
  setDefaultFocusInFooter: false,
  showBackButtonOnActiveView: false,
  showCloseButtonOnActiveView: false,
  showIdentityBanner: true,
};

export interface UserOptions {
  username?: string | UntrustedExternalInputText;
  displayUsername?: string | UntrustedExternalInputText;
}

export type GamepadNavigationManager = typeof GamepadNavigationModule;

type ActivateViewAction = {
  type: GlobalActionType.ActivateView;
  view: ViewId;
  flow: FlowId;
  flavor: Flavors;
  viewOptions: ViewOptions;
};

type BeginNavigateAction = {
  type: GlobalActionType.BeginNavigate;
  source: ViewId | string;
  destination?: ViewId | string;
  displayOptions?: {
    navigationDirection?: NavigationDirection;
  };
};

type DataLoadedViewAction = {
  type: GlobalActionType.DataLoaded;
  view: ViewId;
};

type DeactivateViewAction = {
  type: GlobalActionType.DeactivateView;
  view: ViewId;
  viewOptions: Partial<ViewOptions>;
};

/**
 * Action used to set the `showBackButtonOnActiveView` property in GlobalContext which is used to decide whether to show the back arrow button
 * in the upper left corner of the lightbox.
 */
type HideBackArrowButtonAction = {
  type: GlobalActionType.HideBackArrowButton;
  payload: boolean;
};

type SetDebugDetails = {
  type: GlobalActionType.SetDebugDetails;
  payload: IDebugInfo;
};

/**
 * Action used to set the `showProgressIndicator` property in GlobalContext which is used to
 * decide whether to show the marching ants progress indicator on the upper edge of the lightbox
 */
type SetShowProgressIndicatorAction = {
  type: GlobalActionType.SetShowProgressIndicator;
  payload: boolean;
};

type SetUserAction = {
  type: GlobalActionType.SetUser;
  payload: UserOptions;
};

type SetUserFlowTypeAction = {
  type: GlobalActionType.SetUserFlowType;
  payload: string;
};

/**
 * **FLUENT FLAVORS ONLY** Action used to set the `showArrowButtonOnActiveView` property in GlobalContext which dictates if we show an "X"
 * cancel button in the banner (instead of the back arrow button) in fluent-based flavors.
 * in the upper left corner of the lightbox.
 */
type ShowCloseInBanner = {
  type: GlobalActionType.ShowCloseInBanner;
  payload: boolean;
};

type ToggleDebugMode = { type: GlobalActionType.ToggleDebugMode };

type SetGamepadNavigationModule = {
  type: GlobalActionType.SetGamepadNavigationModule;
  payload: GamepadNavigationManager;
};

/**
 * Union of all actions that can be dispatched to the global reducer to update global state
 */
export type GlobalActions =
  | ActivateViewAction
  | BeginNavigateAction
  | DataLoadedViewAction
  | DeactivateViewAction
  | HideBackArrowButtonAction
  | SetDebugDetails
  | SetShowProgressIndicatorAction
  | SetUserAction
  | SetUserFlowTypeAction
  | SetShowProgressIndicatorAction
  | ShowCloseInBanner
  | ToggleDebugMode
  | SetGamepadNavigationModule;

/**
 * @returns Action creators for hide/show progress indicators
 */
export const hideProgressIndicator = (): SetShowProgressIndicatorAction => ({
  type: GlobalActionType.SetShowProgressIndicator,
  payload: false,
});

export const showProgressIndicator = (): SetShowProgressIndicatorAction => ({
  type: GlobalActionType.SetShowProgressIndicator,
  payload: true,
});

/**
 * Creates a user object from the username or displayUsername
 * @param currentUser Existing user object from state
 * @param options UserOptions passed in for setting the username and/or displayUsername
 * @returns An IUser object
 */
export function getUpdatedUser(currentUser: IUser, options: UserOptions): IUser {
  const updatedUser = currentUser;

  if (options.username) {
    updatedUser.username = isTextUntrustedExternalInputText(options.username)
      ? (options.username as UntrustedExternalInputText)
      : setUntrustedExternalInputText(options.username);
  }

  if (options.displayUsername) {
    updatedUser.displayUsername = isTextUntrustedExternalInputText(options.displayUsername)
      ? (options.displayUsername as UntrustedExternalInputText)
      : setUntrustedExternalInputText(options.displayUsername);
  }

  return updatedUser;
}

/**
 * Parse userflowType string and convert it to UserFlowType
 * @param currentUserFlowType Existing userFlowType from state
 * @param strUserFlowType string value of userFlowType
 * @returns An IUser object
 */
export function getUpdatedUserFlowType(
  currentUserFlowType: UserFlowType,
  strUserFlowType: string,
): UserFlowType {
  let updatedUser = currentUserFlowType;

  if (strUserFlowType && Number.isInteger(+strUserFlowType) && +strUserFlowType in UserFlowType) {
    updatedUser = parseInt(strUserFlowType, 10);
  }

  return updatedUser;
}

/**
 * Sets the username or displayUsername inside the user object
 * @param state The current state
 * @param action SetUsernameAction
 *      payload is the UserOptions with the username/displayName to be set
 * @returns The updated state
 */
function setUser(state: GlobalState, action: SetUserAction): GlobalState {
  const user = getUpdatedUser(state.user, action.payload);
  return {
    ...state,
    user,
  };
}

/**
 * Sets the userFlowType
 * @param state The current state
 * @param action SetUsernameAction
 *      payload is the UserOptions with the username/displayName to be set
 * @returns The updated state
 */
function setUserFlowType(state: GlobalState, action: SetUserFlowTypeAction): GlobalState {
  const userFlowType = getUpdatedUserFlowType(state.userFlowType, action.payload);
  return {
    ...state,
    userFlowType,
  };
}

/**
 * Handle an ActivateView action dispatched to the global reducer
 * @param state The current state
 * @param action The action object representing the parameters for this action
 * @returns The updated state
 */
function handleActivateView(state: GlobalState, action: ActivateViewAction): GlobalState {
  const {
    setDefaultFocusInFooter,
    showIdentityBanner,
    showBackButtonOnActiveView,
    showCloseButtonOnActiveView,
    backButtonHandler,
    closeButtonHandler,
    isWideView,
    hideBannerLogo,
    loadingData,
    showMoreOptions,
    disableHeightAnimation,
  } = action.viewOptions;

  // Update showPageTitle only if a value was specified for the flag; otherwise use the current state value
  const showPageTitle = action.viewOptions.showPageTitle ?? state.showPageTitle;

  return {
    ...state,
    setDefaultFocusInFooter,
    showProgressIndicator: loadingData,
    activeView: action.view,
    previousView: state.activeView !== ViewId.None ? state.activeView : state.previousView,
    activeFlow: action.flow,
    showIdentityBanner,
    showBackButtonOnActiveView,
    showCloseButtonOnActiveView,
    backButtonHandler,
    closeButtonHandler,
    isWideView,
    hideBannerLogo,
    showMoreOptions,
    showPageTitle,
    disableHeightAnimation,
  };
}

/**
 * Handle an ActivateView action dispatched to the global reducer
 * @param state The current state
 * @param action The action object representing the parameters for this action
 * @returns The updated state
 */
function handleDeactivateView(state: GlobalState, action: DeactivateViewAction): GlobalState {
  return {
    ...state,
    ...action.viewOptions,
  };
}

/**
 * Handle a BeginNavigate action dispatched to the global reducer.
 * @param state The current global state
 * @param action The action object representing the parameters for this action
 * @returns The updated global state
 */
export const handleBeginNavigate = function handleBeginNavigate(
  state: GlobalState,
  action: BeginNavigateAction,
): GlobalState {
  const { activeFlavor, telemetry } = GlobalConfig.instance;

  telemetry?.addEvent({
    _table: TelemetryEventType.UserAction,
    actionName: "BeginNavigation",
    actionValue: action.destination || "",
    dimensions: getStandardDimensions({ ...state, activeFlavor }),
  });

  const navigationDirection =
    action.displayOptions?.navigationDirection ?? state.navigationDirection;

  return {
    ...state,
    showProgressIndicator: true,
    navigationDirection,
  };
};

/**
 * Global state reducer
 * @param state The current state
 * @param action The action to perform on the current state
 * @returns The new state
 */
export default function globalReducer(state: GlobalState, action: GlobalActions): GlobalState {
  const actionType = action.type;
  switch (actionType) {
    case GlobalActionType.BeginNavigate:
      return handleBeginNavigate(state, action);

    case GlobalActionType.ActivateView: // this is called from use-activate-view hook
      return handleActivateView(state, action);

    case GlobalActionType.DeactivateView:
      // perform any required cleanup that should happen before the new view activates
      return handleDeactivateView(state, action);

    case GlobalActionType.DataLoaded:
      return { ...state, showProgressIndicator: false };

    case GlobalActionType.HideBackArrowButton:
      return { ...state, showBackButtonOnActiveView: !action.payload };

    case GlobalActionType.SetDebugDetails:
      return { ...state, debugInfo: action.payload };

    case GlobalActionType.ShowCloseInBanner:
      return { ...state, showCloseButtonOnActiveView: action.payload };

    case GlobalActionType.ToggleDebugMode:
      return {
        ...state,
        debugInfo: { ...state.debugInfo, debugModeActive: !state.debugInfo.debugModeActive },
      };

    case GlobalActionType.SetUser:
      return setUser(state, action);

    case GlobalActionType.SetUserFlowType:
      return setUserFlowType(state, action);

    case GlobalActionType.SetShowProgressIndicator:
      return {
        ...state,
        showProgressIndicator: action.payload,
      };

    case GlobalActionType.SetGamepadNavigationModule:
      return {
        ...state,
        gamepadManager: action.payload,
      };

    default:
      throw new Error(`GlobalReducer received unexpected action ${actionType}`);
  }
}
