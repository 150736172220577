import { useContext, useRef, useState } from "react";
import { AuthenticationContext } from "../../../../../authentication-context";
import {
  loginOption as LoginOption,
  postType as PostType,
  UserFlowType,
  ViewId,
} from "../../../../../constants";
import { useCustomizationContext } from "../../../../../context/customization-context";
import FeaturesConfig from "../../../../../features-config";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import {
  useBackButtonControl,
  useNavigateDirection,
} from "../../../../../hooks/use-navigate-direction";
import { CredentialType } from "../../../../../model/credential";
import { cleanseUsername } from "../../../../../model/user";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import { copyQueryStringParameters } from "../../../../../utilities/strings-helper";
import {
  useBrandingDescriptionProperties,
  useResetPasswordUrl,
  useSelectAccount,
} from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import { LoginState } from "../../../login-constants";
import { LoginContext } from "../../../login-context";
import { type CommonLoginStrings } from "../../../login-interface";
import {
  getCommonDocumentTitle,
  getParentSignInPostUrl,
  getShowChangeUserLink,
  getShowPhoneDisambigLink,
} from "../../../login-util";
import {
  type IPasswordViewPropertiesFabric,
  type IPasswordViewStrings,
} from "./password-view-interface-fabric";

/**
 * @returns password view properties
 * @param strings Flavored strings that are used by this hook
 * @param strings.commonLoginStrings Common login strings
 * @param strings.passwordViewStrings Password view strings
 */
export const usePasswordViewPropertiesFabric = (strings: {
  commonLoginStrings: CommonLoginStrings;
  passwordViewStrings: IPasswordViewStrings;
}): IPasswordViewPropertiesFabric => {
  const {
    commonLoginStrings,
    passwordViewStrings: {
      loginPasswordPageTitle,
      primaryButtonText,
      noPasswordError,
      forgotPasswordLinkText,
      passwordHint,
      passwordAriaLabel,
    },
  } = strings;

  const {
    globalState: { user, userFlowType },
  } = useContext(GlobalContext);

  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  const {
    context,
    canaryTokenValue: canary,
    flowTokenName,
    showCookieBanner,
    postUrl,
  } = GlobalConfig.instance;

  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  const {
    passwordViewHeader,
    loginMode,
    randomBlob,
    foundMsas,
    postedForceSignIn,
    isFidoSupportedHint,
    defaultLoginOptions,
    hasWizardBehavior,
    rawQueryString,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  const {
    viewState: {
      credentials: { availableCredentials },
      remoteNgcParams,
    },
  } = useContext(LoginContext);

  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  const resetPasswordUrl = useResetPasswordUrl();

  const title = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);

  const formRef = useRef<HTMLFormElement>(null);

  const header = passwordViewHeader || loginPasswordPageTitle;
  const headerId = "loginHeader";

  const { renderBrandingDescription, brandingDescriptionId, brandingDescription } =
    useBrandingDescriptionProperties(commonLoginStrings);

  const ariaDescribedBy = [headerId, renderBrandingDescription ? brandingDescriptionId : ""].join(
    " ",
  );
  const ariaLabel = passwordAriaLabel.replace("{0}", user.displayUsername.safeHtmlEscapedString);

  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  const isKmsiChecked = defaultLoginOptions === LoginOption.rememberPwd;
  const cleansedUsername = cleanseUsername(user.username.unsafeUnescapedString);
  const displayName = user.displayUsername.safeHtmlEscapedString;
  const postType = hasWizardBehavior ? PostType.passwordInline : PostType.password;
  const loginOption = isKmsiChecked ? LoginOption.rememberPwd : LoginOption.nothingChecked;
  const updatedPostUrl = rawQueryString
    ? copyQueryStringParameters(rawQueryString, postUrl)
    : postUrl;
  const parentSignInPostUrl = getParentSignInPostUrl(updatedPostUrl, displayName, userFlowType);
  const isInitialView = !useBackButtonControl();

  const [isRequestPending, setIsRequestPending] = useState(false);

  const credentialSwitchLinksProps = {
    sourceViewId: ViewId.Password,
    availableCredentials,
    currentCredential: { credentialType: CredentialType.Password },
    setRequestPendingFlag: setIsRequestPending,
    shouldUpdateOtcCredential: true,
  };

  const allowPhoneDisambiguation = getShowPhoneDisambigLink(displayName);
  const navigate = useNavigateDirection();
  const navigateToPhoneDisambig = () => {
    navigate(ViewId.Password, ViewId.PhoneDisambiguation);
  };

  const showChangeUserLink = getShowChangeUserLink(isInitialView);
  const selectAccount = useSelectAccount(ViewId.Password);

  const isParentSignIn = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;

  return {
    title,
    formRef,
    header,
    headerId,
    descriptionProperties: {
      renderDescription: renderBrandingDescription,
      descriptionId: brandingDescriptionId,
      description: brandingDescription,
    },
    ariaDescribedBy,
    ariaLabel,
    allowPhoneDisambiguation,
    navigateToPhoneDisambig,
    showChangeUserLink,
    selectAccount,
    credentialSwitchLinksProps,
    noPasswordErrorMessage: noPasswordError,
    placeholder: passwordHint,
    postUrl: isParentSignIn ? parentSignInPostUrl : updatedPostUrl,
    primaryButtonLabel: primaryButtonText,
    resetPasswordUrl,
    forgotPasswordLinkText,
    loginPostProps: {
      canary,
      cleansedUsername,
      context,
      flowTokenName,
      flowTokenValue,
      foundMsas,
      isFidoSupported,
      isKmsiChecked,
      loginOption,
      paginatedState: LoginState.Password,
      postType,
      postedForceSignIn,
      randomBlob,
      showCookieBanner,
      displayUsername: user.displayUsername.unsafeUnescapedString,
      rngcDefaultType: remoteNgcParams.defaultType,
      rngcEntropy: remoteNgcParams.entropy,
      rngcSessionIdentifier: remoteNgcParams.sessionIdentifier,
    },
    isRequestPending,
    isParentSignIn,
  };
};
