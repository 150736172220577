import { ChallengeActionType, useChallengeContext } from "../../../challenge-context";
import { type ResponseError, ApiNames, HipType, ViewId } from "../../../constants";
import { useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { type OtcFailureParams } from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import {
  type AccountParams,
  type ChallengeSubmitParams,
  type TriggerChallengeParams,
  HipErrors,
} from "../challenge-types";
import { useBuildRepMapRequest } from "./use-repmap-params";

/**
 * A reusable method to safely access the Error code for the given response.
 * @param response The response from the API call
 * @returns The error code if it exists, otherwise an empty string
 */
export const getErrorCode = function getErrorCode<ResponseErrorType extends ResponseError>(
  response: ResponseErrorType,
) {
  const error = response.responseBody?.error;
  if (error && typeof error !== "string" && error.code !== undefined) {
    return String(error.code);
  }

  return "";
};

/**
 * Used during challenge validation, takes the handlers for success and error validations and optionally forwards
 * the response to the main flow
 * @param props the props of the hook
 * @param props.requestHandler the api request handler
 * @param props.validationSuccessHandler the action to take on validation success
 * @param props.validationErrorHandler the action to take on error validation
 * @param props.handoverErrorHandler the action to take when the error is to be handled by the main flow
 * @param props.url the generated url of the api
 * @param props.flowRequestParams the request params that belong to the owning flow
 * @returns a validation function to be used by the chalenngeViewProperties hook
 */
export const useValidateChallenge = <
  ResponseSuccessType,
  ResponseErrorType,
  RequestParamsType,
>(props: {
  requestHandler: (url: string, params: RequestParamsType) => Promise<ResponseSuccessType>;
  validationSuccessHandler: (response: ResponseSuccessType) => void;
  validationErrorHandler: (props: TriggerChallengeParams<ResponseErrorType>) => void;
  handoverErrorHandler: ((response: ResponseErrorType, currentView: ViewId) => void) | undefined;
  url: string;
  flowRequestParams: RequestParamsType;
}) => {
  const {
    requestHandler,
    validationSuccessHandler,
    validationErrorHandler,
    handoverErrorHandler,
    url,
    flowRequestParams,
  } = props;
  const {
    challengeState: { flowId, scenarioId, apiName, arkose },
    dispatchChallengeStateChange,
  } = useChallengeContext();
  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();
  const buildRepMapRequest = useBuildRepMapRequest();

  return async ({ solution, type, token, reloadHIP }: ChallengeSubmitParams) => {
    // build the request based on which api does the validation
    let finalSolutionParams;
    switch (apiName) {
      case ApiNames.CreateAccount:
      case ApiNames.SendOtt: {
        const params: AccountParams = {
          HFId: flowId,
          HPId: arkose.partnerId,
          HSol: solution,
          HType: type,
          HId: token,
          ...buildRepMapRequest(apiName),
        };

        // for hip the scenario is best obtained from server data rather than url
        if (scenarioId && (type === HipType.Audio || type === HipType.Visual)) {
          params.HSId = scenarioId;
        }

        finalSolutionParams = params;
        break;
      }

      case ApiNames.GetOtc:
        finalSolutionParams = {
          challengeSolution: solution,
          challengeType: type,
          challengeId: token,
        };
        break;
      default:
        finalSolutionParams = {};
    }

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: true,
    });

    // send the api request using the requestHandler
    await requestHandler(url, { ...flowRequestParams, ...finalSolutionParams })
      .then((response) => {
        dispatchChallengeStateChange({
          type: ChallengeActionType.SetCommonAction,
          payload: { errorMessage: "" },
        });
        validationSuccessHandler(response);
      })

      // validation failed
      .catch((response: ResponseError) => {
        let errorCode;
        switch (apiName) {
          case ApiNames.CreateAccount:
          case ApiNames.SendOtt: {
            // For signup and username recovery flows some errors will be handled by the main flow
            errorCode = getErrorCode(response as ResponseError);
            if (Object.values(HipErrors).includes(errorCode)) {
              validationErrorHandler({
                response: response as ResponseErrorType,
                apiName,
                reloadHIP,
              });
            } else if (handoverErrorHandler) {
              handoverErrorHandler(response as ResponseErrorType, ViewId.Challenge);
            } else {
              throw new Error("Challenge view does not have a handle for this error");
            }

            break;
          }

          case ApiNames.GetOtc: {
            // For the login and TFA flows all errors are handled by the challenge view
            errorCode = (response as OtcFailureParams).otcStatus?.toString() || "";
            validationErrorHandler({ response: response as ResponseErrorType, apiName, reloadHIP });
            break;
          }

          default:
            throw new Error("Api not onboarded to challenge");
        }
      })

      .finally(() => {
        dispatchGlobalStateChange({
          type: GlobalActionType.SetShowProgressIndicator,
          payload: false,
        });
      });
  };
};
