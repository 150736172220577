/** @private */
function fallbackCopyTextToClipboard(text: string) {
  // Function to copy to clipboard on IE and some older browser versions
  const textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.select();

  try {
    // eslint-disable-next-line deprecation/deprecation
    document.execCommand("copy");
    // eslint-disable-next-line no-empty
  } catch (err) {}

  document.getElementsByTagName("body")[0].removeChild(textArea);
}

/**
 * Copy the given text to the clipboard
 * @param text Text to be copied to the clipboard
 */
export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    // navigator.clipboard is not supported on IE and some other older browser versions
    fallbackCopyTextToClipboard(text);
    return;
  }

  // Allow navigator.clipboard reference here as we short circuit on the previous if blocked for browsers that don't support it
  // eslint-disable-next-line compat/compat
  navigator.clipboard.writeText(text);
}

/**
 * Copy a given text area to the clipboard
 * @param textAreaId The ID of the text area to extract text from for copy
 */
export function getTextAreaAndCopyToClipboard(textAreaId: string) {
  const textarea = document.getElementById(textAreaId);
  if (!textarea) {
    return;
  }

  copyTextToClipboard(textarea.innerText);
}
