import React from "react";
import { mergeClasses } from "@griffel/react";
import { SecondaryContentContainerFabric } from "../../../../components";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import StylesConfig from "../../../../config/styles-config";
import { Flavors } from "../../../../constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import GlobalConfig from "../../../../global-config";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import { setBackNavigationCheckpointBeforeRedirect } from "../../../../utilities/cxh-handlers/cxh-handler";
import { CredentialSwitchContentFabric } from "../../components/fabric/credential-switch-content-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import {
  useBrandingDescriptionProperties,
  useResetRemoteNgcRequestSent,
  useShowBackButton,
  useShowCredentialSwitchContent,
} from "../../hooks/login-hooks";
import { useIdpRedirectSpeedbumpViewProperties } from "../hooks/use-idp-redirect-speedbump-view-properties";
import { usePrimaryButtonProperties } from "../hooks/use-primary-button-properties";

/**
 * IdpRedirectSpeedbumpView component
 *
 * If the user has more than 1 available credential or at least 1 evicted credential,
 * this view is shown instead of the IDP redirect view.
 *
 * This view is very similar to the IDP redirect view in that it is shown when
 * 1. the user is switching IDPs such as changing from AAD to MSA or vice versa
 * 2. redirecting the user to a federated login page
 *
 * The differences between this view and the IDP redirect view are that this view
 * 1. offers a Next button that initiates the redirect request when clicked instead of
 * starting the redirect after a 2 second timeout.
 * 2. shows a few links that allow the user to switch their preferred credential.
 *
 * @returns A rendered instance of this component
 */
export const IdpRedirectSpeedbumpViewFabric: React.FC = function IdpRedirectSpeedbumpViewFabric() {
  // Global config data
  const { activeFlavor, showButtons } = GlobalConfig.instance;

  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  useResetRemoteNgcRequestSent();

  const {
    documentTitle,
    lightboxTitleId,
    lightboxTitle,
    lightboxDescriptionId,
    lightboxDescription,
    redirectUrl,
    redirectPostParams,
    credentialSwitchLinksProps,
  } = useIdpRedirectSpeedbumpViewProperties({ commonLoginStrings: commonLoginStringsFabric });

  const { renderBrandingDescription, brandingDescriptionId, brandingDescription } =
    useBrandingDescriptionProperties(commonLoginStringsFabric);

  const { primaryButtonAriaDescribedBy, primaryButtonClickHandler } = usePrimaryButtonProperties(
    lightboxTitleId,
    lightboxDescriptionId,
    renderBrandingDescription,
    brandingDescriptionId,
    redirectUrl,
    redirectPostParams,
    activeFlavor === Flavors.Win11OobeFabric
      ? setBackNavigationCheckpointBeforeRedirect
      : undefined,
  );

  const showBackButton = useShowBackButton();

  useActivateView(ViewId.IdpRedirectSpeedbump, FlowId.Login, {
    showBackButtonOnActiveView: showBackButton,
    showIdentityBanner: true,
  });
  useDocumentTitle(documentTitle);
  useStaticCommonStyles();

  const commonStyles = useCommonStyles();

  const { showSwitchToEvictedCredPicker, showCredentialSwitchContent } =
    useShowCredentialSwitchContent();

  return (
    <>
      {/* @AAD-TODO: add external title class when we tackle ESTS */}
      <TitleFabric titleId={lightboxTitleId} title={lightboxTitle} />

      {renderBrandingDescription && (
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
          <div id={brandingDescriptionId} className={commonStyles.wrapContent}>
            {brandingDescription}
          </div>
        </div>
      )}

      {lightboxDescription && (
        <div className={commonStyles.formGroup}>
          <div
            id={lightboxDescriptionId}
            className={mergeClasses(
              commonStyles.row,
              commonStyles.textBody,
              commonStyles.textBlockBody,
            )}
          >
            {lightboxDescription}
          </div>
        </div>
      )}

      <div className={commonStyles.buttonMargin}>
        <div className={commonStyles.row}>
          <SecondaryContentContainerFabric>
            {showCredentialSwitchContent && (
              <CredentialSwitchContentFabric
                credentialSwitchProps={credentialSwitchLinksProps}
                showSwitchToEvictedCredPicker={showSwitchToEvictedCredPicker}
              />
            )}
          </SecondaryContentContainerFabric>
        </div>
      </div>

      <div className={commonStyles.winButtonPinBottom}>
        <div className={commonStyles.row}>
          <TextButtonContainer>
            {showButtons && (
              <TextButtonFabric
                isPrimary
                hasFocus
                ariaDescribedBy={primaryButtonAriaDescribedBy}
                label={getLocalString("General_Buttons_Next")}
                onClick={primaryButtonClickHandler}
              />
            )}
          </TextButtonContainer>
        </div>
      </div>
    </>
  );
};
