import React from "react";
import {
  type IDescriptionFabricProps,
  DescriptionFabric,
} from "../../../../components/description/fabric/description-fabric";
import {
  type ITitleFabricProps,
  TitleFabric,
} from "../../../../components/title/fabric/title-fabric";
import { useCustomizationContext } from "../../../../context/customization-context";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import LoginConfig from "../../login-config";
import { getCommonSubtitle } from "../../login-util";

/**
 * Title component specifically for Login.
 * This defaults the subtitle to the CommonSubtitle if not defined.
 * @param props The properties for this component
 * @returns Title component
 */
export const LoginTitleDescriptionFabric: React.FC<
  ITitleFabricProps & Omit<IDescriptionFabricProps, "children"> & { description?: string }
> = function LoginTitleDescriptionFabric(props) {
  const { subtitle, description, descriptionClass, descriptionId } = props;
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();
  const { breakBrandingSigninString } = LoginConfig.instance;
  const titleProps: ITitleFabricProps = {
    ...props,
    subtitle:
      subtitle ||
      getCommonSubtitle(friendlyAppName, breakBrandingSigninString, commonLoginStringsFabric),
  };
  return (
    <>
      <TitleFabric {...titleProps} />
      {description && (
        <DescriptionFabric descriptionId={descriptionId} descriptionClass={descriptionClass}>
          {description}
        </DescriptionFabric>
      )}
    </>
  );
};
