import React, { useEffect, useRef, useState } from "react";
import StylesConfig from "../config/styles-config";
import FeaturesConfig from "../features-config";
import { useGlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";
import { useGamepadFocusTrap } from "../hooks/use-gamepad-focus-trap";

/**
 * @param root Root properties
 * @param root.children Child components to render in the direction wrapper
 * @returns DirectionWrapper component to provide `rtl` functionality
 */
const GamepadNavigationWrapper: React.FC = function GamepadNavigationWrapper({ children }) {
  const { loadGamepadNavigationModule } = FeaturesConfig.instance;
  const { dispatchStateChange } = useGlobalContext();
  const { useCommonStyles } = StylesConfig.instance;
  const { gamepadNavigationWrapper } = useCommonStyles();
  const [moduleLoaded, setModuleLoaded] = useState(false);

  useEffect(() => {
    /**
     * Lazy loading Gamepad Navigation Module
     */
    async function loadModule() {
      if (loadGamepadNavigationModule && !moduleLoaded) {
        const gamepadModule = await import(
          /* webpackChunkName: "gamepad-navigation" */ "@gaming/react-gamepad-navigation-lite"
        );
        await gamepadModule.initGamepadNavigation();
        dispatchStateChange({
          type: GlobalActionType.SetGamepadNavigationModule,
          payload: gamepadModule,
        });
      }

      setModuleLoaded(true);
    }

    if (document.readyState === "complete") {
      loadModule();
    } else {
      window.addEventListener("load", loadModule);
    }

    return () => {
      window.removeEventListener("load", loadModule);
    };
  }, [loadGamepadNavigationModule, dispatchStateChange, moduleLoaded]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useGamepadFocusTrap(wrapperRef);

  return (
    <div
      className={gamepadNavigationWrapper}
      ref={wrapperRef}
      data-testid="gamepadNavigationWrapper"
    >
      {children}
    </div>
  );
};

export default GamepadNavigationWrapper;
