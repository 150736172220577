import { type InputStateOptions, useInput } from "../../../hooks/use-input";
import { type PhoneNumberOptions, usePhoneNumber } from "../../hooks/use-phone-number";

export type PhoneNumberOptionsFabric = Omit<PhoneNumberOptions, "inputState"> & {
  input: Pick<InputStateOptions, "hasInitialFocus" | "initialValue" | "useElementRef">;
};

/**
 * This hook combines `useInput` and `usePhoneNumber` to implement the state for the PhoneNumberFabric component
 * @param props The properties for this hook
 * @returns PhoneNumberState, used to render the PhoneNumberFabric component
 */
export const usePhoneNumberFabric = function usePhoneNumberFabric(props: PhoneNumberOptionsFabric) {
  const inputState = useInput(props.input);
  const phoneNumberState = usePhoneNumber({ ...props, inputState });
  return phoneNumberState;
};
