import { ViewId } from "../../../constants";
import { useNavigateDirection } from "../../../hooks";

/**
 * @param sourceViewId view where the credential switch links component resides
 * @param isUserKnown whether the user has been identified; determined by checking if the current credential is known
 * @returns click handler for when credential picker link is clicked
 */
export const useCredentialPickerClickHandler = (sourceViewId: ViewId, isUserKnown: boolean) => {
  const navigator = useNavigateDirection();
  const destinationViewId = isUserKnown ? ViewId.CredentialPicker : ViewId.SignInOptions;

  return () => {
    navigator(sourceViewId, destinationViewId);
  };
};
