import { useContext } from "react";
import { useCustomizationContext } from "../../../../context/customization-context";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { type CommonLoginStrings } from "../../login-interface";
import { getCommonDocumentTitle } from "../../login-util";
import { getBindProviderProperties } from "../idp-redirect-view-util";

export interface IIdpRedirectViewProperties {
  documentTitle: string;
  lightboxTitle: string;
}

/**
 * Hook that returns important properties for the IDP redirect view
 * @returns Login IDP redirect view properties
 * @param strings - Common login strings
 * @param strings.commonLoginStrings - Common login strings
 */
export const useIdpRedirectViewProperties = (strings: {
  commonLoginStrings: CommonLoginStrings;
}): IIdpRedirectViewProperties => {
  const { commonLoginStrings } = strings;

  // Global context data
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  // Login context data
  const {
    viewState: { idpRedirectPostParams, idpRedirectProvider, idpRedirectUrl },
  } = useContext(LoginContext);

  // Login config data
  const { facebookFedUrl, githubFedUrl, googleFedUrl, linkedInFedUrl, loginMode } =
    LoginConfig.instance;

  const documentTitle = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);

  const { title: lightboxTitle } = getBindProviderProperties(
    idpRedirectUrl,
    idpRedirectPostParams,
    idpRedirectProvider,
    {
      linkedInFedUrl,
      githubFedUrl,
      googleFedUrl,
      facebookFedUrl,
    },
  );

  return { documentTitle, lightboxTitle };
};
