import type { AcmaLinksObject } from "../api/acma-types";

export const enum ProofTypeAsString {
  AccessPass = "AccessPass",
  AlternateAuthenticator = "TOTPAuthenticator",
  Authenticator = "TOTPAuthenticatorV2",
  Certificate = "Certificate",
  CompanionAppsNotification = "CompanionAppsNotification",
  ConsolidatedTelephony = "ConsolidatedTelephony",
  Email = "Email",
  ExternalAuth = "ExternalAuth",
  FidoKey = "FidoKey",
  OneWaySMS = "OneWaySMS",
  OneWayVoiceMobileOTP = "OneWayVoiceMobileOTP",
  OneWayVoiceOfficeOTP = "OneWayVoiceOfficeOTP",
  OneWayVoiceAlternateMobileOTP = "OneWayVoiceAlternateMobileOTP",
  PhoneAppNotification = "PhoneAppNotification",
  PhoneAppOTP = "PhoneAppOTP",
  SMS = "Sms",
  SecurityQuestion = "SQSA",
  TwoWaySMS = "TwoWaySMS",
  TwoWaySMSAlternateMobile = "TwoWaySMSAlternateMobile",
  TwoWayVoiceAlternateMobile = "TwoWayVoiceAlternateMobile",
  TwoWayVoiceMobile = "TwoWayVoiceMobile",
  TwoWayVoiceOffice = "TwoWayVoiceOffice",
  Unknown = "",
}

export type ProofMethod = {
  approver: number;
  autoProofEntry?: string;
  channel: string;
  epid: string;
  lastUsed: number;
  name: string;
  partialSelectValue: string;
  requiresReentry: number;
  sendNotifications: number;
  type: ProofTypeAsString;
  used: number;
};

export const enum SmsActionType {
  Unknown = "Unknown",
  Compliance = "Compliance",
  CompromiseRecovery = "CompromiseRecovery",
  Confirm = "Confirm",
  Eviction = "Eviction",
  FamiliarLocationHard = "FamiliarLocationHard",
  MoveAlias = "MoveAlias",
  MoveAliasPhone = "MoveAliasPhone",
  ReclaimEvictedPrimaryAlias = "ReclaimEvictedPrimaryAlias",
  ReclaimEvictedSecondaryAlias = "ReclaimEvictedSecondaryAlias",
  Recover = "Recover",
  Remove = "Remove",
  Rename = "Rename",
  Reset = "Reset",
  SignUp = "SignUp",
  SkypePasswordConvergence = "SkypePasswordConvergence",
  TierRestore = "TierRestore",
  UsernameRecovery = "UsernameRecovery",
  VerifyAlias = "VerifyAlias",
  VerifyAutoCsrContactEmail = "VerifyAutoCsrContactEmail",
  VerifyNewAlias = "VerifyNewAlias",
  VerifyNewProof = "VerifyNewProof",
  VerifyProof = "VerifyProof",
}

export const enum OttPurpose {
  Invalid = "Invalid",
  ChangePassword = "ChangePassword",
  Compliance = "Compliance",
  CompromiseRecovery = "CompromiseRecovery",
  DelegationCode = "DelegationCode",
  FinalRecoverUser = "FinalRecoverUser",
  InvitedManagedRegistration = "InvitedManagedRegistration",
  ManageProofs = "ManageProofs",
  NopaAccountLogin = "NopaAccountLogin",
  OneTimePassword = "OneTimePassword",
  RecoverAccount = "RecoverAccount", // Recover from account close
  RecoverAccountInt = "RecoverAccountInt", // Recover from account close, IPT purpose
  RecoverLostProofs = "RecoverLostProofs",
  RecoverUser = "RecoverUser", // Two-creds to change a cred, Reset Password
  RemoteNGC = "RemoteNGC",
  ShortLivedToken = "ShortLivedToken",
  SkypePasswordConvergence = "SkypePasswordConvergence",
  SubmitRecovery = "SubmitRecovery",
  TOTP = "TOTP",
  TierRestore = "TierRestore",
  TierUpgrade = "TierUpgrade",
  UnfamiliarLocationEasy = "UnfamiliarLocationEasy",
  UnfamiliarLocationHard = "UnfamiliarLocationHard",
  UsernameRecovery = "UsernameRecovery",
  VerificationCode = "VerificationCode",
  VerificationCode2Way = "VerificationCode2Way",
  VetoEA = "VetoEA",
}

export const enum OttAction {
  CreateSLT = "CreateSLT",
  OnlyVerifyNoConsume = "OnlyVerifyNoConsume",
  SetVerifyState = "SetVerifyState",
}

export const enum ChannelType {
  Unknown = "Unknown",
  SMS = "SMS",
  Email = "Email",
  Voice = "Voice",
  SLT = "SLT",
  PushNotifications = "PushNotifications",
}

export const enum AssociationType {
  None = "None",
  Alias = "Alias",
  EASI = "EASI",
  Proof = "Proof",
}

export const enum ProofType {
  Email = 1,
  AltEmail = 2,
  SMS = 3,
  DeviceId = 4,
  CSS = 5,
  SQSA = 6,
  Certificate = 7,
  HIP = 8,
  Birthday = 9,
  TOTPAuthenticator = 10,
  RecoveryCode = 11,
  StrongTicket = 13,
  TOTPAuthenticatorV2 = 14,
  TwoWayVoice = 15,
  TwoWaySMS = 16,
  FidoKey = 17,
  AccessPass = 18,
  TransferToken = 19,
  VerifiableCredentialsHackathon = 20,
  CompanionApp = 21,
  Voice = -3,
}

export const enum PhoneAppOTPType {
  HardwareTokenBasedTOTP = "HardwareTokenBasedTOTP",
  SoftwareTokenBasedTOTP = "SoftwareTokenBasedTOTP",
  MicrosoftAuthenticatorBasedTOTP = "MicrosoftAuthenticatorBasedTOTP",
  OutlookBasedTOTP = "OutlookBasedTOTP",
}

export type OneTimeCodeProof = {
  clearDigits?: string;
  ctryISO?: string;
  data?: string;
  display?: string;
  isDefault?: boolean;
  isEncrypted?: boolean;
  isLost?: boolean;
  isNopa?: boolean;
  isSADef?: boolean;
  isSleeping?: boolean;
  isVoiceOnly?: boolean;
  otcEnabled?: boolean;
  otcSent?: boolean;
  pushEnabled?: boolean;
  type: ProofType;
  voiceEnabled?: boolean;
  acmaOtcLength?: number;
};

export type OneTimeCodeProofWithDisplay = OneTimeCodeProof & {
  display: string;
};

export type AuthMethod = OneTimeCodeProofWithDisplay & {
  authMethodId?: ProofTypeAsString;
  priority?: number;
  acmaLinks?: {
    challenge?: AcmaLinksObject;
    resend?: AcmaLinksObject;
    verify?: AcmaLinksObject;
  };
};

export type TfaAuthMethod = AuthMethod & {
  externalAuthenticationMethodId?: string;
  isAddressable?: boolean;
  isLocationAware?: boolean;
  darkIcon?: string;
  lightIcon?: string;
  phoneAppOtpTypes?: PhoneAppOTPType[];
};
