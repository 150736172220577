import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./text-button-constants-fabric.styles";

export const useTextButtonStylesFabric = makeStyles({
  default: {
    minHeight: `${styleConstants.MINHEIGHT}px`,
    ...shorthands.borderStyle("none"),
    minWidth: `${styleConstants.MINWIDTH}px`,
    lineHeight: "normal",
    display: "inline-block",
    ...shorthands.padding("4px", "12px"),
    position: "relative",
    maxWidth: "100%",
    textAlign: "center",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    verticalAlign: "middle",
    textOverflow: "ellipsis",
    touchAction: "manipulation",
    color: styleConstants.DEFAULT_COLOR,
    backgroundColor: styleConstants.DEFAULT_BACKGROUND_COLOR,
    ":hover": {
      backgroundColor: styleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
      cursor: "pointer",
    },
    ":focus": {
      backgroundColor: styleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
      textDecorationLine: "underline",
      outlineColor: "#000",
      outlineWidth: "2px",
      outlineStyle: "solid",
    },
    ":active": {
      outlineStyle: "none",
      textDecorationLine: "none",
      transform: "scale(0.98)",
      "-ms-transform": "scale(0.98)",
      "-webkit-transform": "scale(0.98)",
      backgroundColor: styleConstants.DEFAULT_ACTIVE_BACKGROUND_COLOR,
      ...shorthands.borderColor("transparent"),
    },
    ":disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
      outlineStyle: "none",
      color: styleConstants.DEFAULT_DISABLED_COLOR,
      ...shorthands.borderColor("transparent"),
      backgroundColor: styleConstants.DEFAULT_DISABLED_COLOR,
    },
  },
  primary: {
    backgroundColor: styleConstants.PRIMARY_BACKGROUND_COLOR,
    ...shorthands.borderColor(styleConstants.PRIMARY_BACKGROUND_COLOR),
    color: styleConstants.PRIMARY_COLOR,
    ":hover": {
      backgroundColor: styleConstants.PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR,
    },
    ":focus": {
      backgroundColor: styleConstants.PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR,
    },
    ":active": {
      backgroundColor: styleConstants.PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR,
    },
  },
  secondaryHighContrast: {
    ...shorthands.borderWidth("0px"),
    outlineWidth: "0px",
  },
  buttonItem: {
    display: "inline-block",
  },
});

export type TextButtonStylesFabric = ReturnType<typeof useTextButtonStylesFabric>;

export const useTextButtonContainerStylesFabric = makeStyles({
  lightboxLayout: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    textAlign: "right",
    ":global([dir='rtl'])": {
      textAlign: "left",
      "& .ext-button-item": {
        marginLeft: "4px",
      },
    },
    "& .ext-button-item:not(:last-child)": {
      marginRight: "4px",
    },
  },

  agreementLayout: {
    position: "relative",
    bottom: "auto",
    right: "auto",
  },

  movedButtons: {
    bottom: "auto",
  },
});

export type TextButtonContainerStylesFabric = ReturnType<typeof useTextButtonContainerStylesFabric>;
