import React, { useContext } from "react";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TileListFabric } from "../../../../components/tile-list/fabric/tile-list-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import StylesConfig from "../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { getFidoSupport } from "../../../../utilities/browser-helper";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import LoginConfig from "../../login-config";
import { getAvailableCredsWithoutUsername } from "../../login-util";
import { useSignInOptionsBuilder } from "../hooks/signin-options-view-hooks";
import { signInOptionsStringsFabric } from "./signin-options-strings-fabric";

/**
 * SignInOptionsView component
 * @returns A rendered instance of this component
 */
export const SignInOptionsViewFabric: React.FC = function SignInOptionsViewFabric() {
  const { backLabel, signInOptionsTitle } = signInOptionsStringsFabric;

  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;
  const { isFidoSupportedHint, useWebviewFidoCustomProtocol } = LoginConfig.instance;
  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  const credentials = getAvailableCredsWithoutUsername(isFidoSupported);

  const navigate = useNavigateDirection();

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  useActivateView(ViewId.SignInOptions, FlowId.Login, { showIdentityBanner: false });
  useContext(GlobalContext);

  const tileList = useSignInOptionsBuilder(credentials, {
    commonLoginStrings: commonLoginStringsFabric,
    signInOptionsStrings: signInOptionsStringsFabric,
  });

  return (
    <div>
      <TitleFabric titleId="signInOptionsTitle" title={signInOptionsTitle} />
      <div className={commonStyles.formGroup}>
        <TileListFabric
          ariaLabelledBy={signInOptionsTitle}
          ariaDescribedBy={signInOptionsTitle}
          tileList={tileList}
        />
      </div>
      <div className={commonStyles.winButtonPinBottom}>
        <div className={commonStyles.buttonMargin}>
          <TextButtonContainer>
            <TextButtonFabric
              buttonId="backButton"
              label={backLabel}
              isPrimary={false}
              ariaLabel={backLabel}
              onClick={() => navigate(ViewId.Username, -1)}
            />
          </TextButtonContainer>
        </div>
      </div>
    </div>
  );
};
