import React, { useContext } from "react";
import { SecondaryContentContainerFabric } from "../../../../../components";
import { DescriptionFabric } from "../../../../../components/description/fabric/description-fabric";
import { useFormSubmission } from "../../../../../components/inputs/hooks/use-form-submission";
import { usePhoneNumberFabric } from "../../../../../components/inputs/phone-number/fabric/hooks/use-phone-number-fabric";
import { PhoneNumberFabric } from "../../../../../components/inputs/phone-number/fabric/phone-number-fabric";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../../components/title/fabric/title-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants";
import { GlobalContext } from "../../../../../global-context";
import { useNavigateOrRedirectBack } from "../../../../../hooks";
import { useActivateView } from "../../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../../hooks/use-document-title";
import { useBackButtonHandlerWithCxh } from "../../../../../hooks/win11-oobe-fabric/use-back-button-handler";
import { getPhoneInputValidation } from "../../../../../model/alias/phone-number";
import { commonLoginStringsFabric } from "../../../fabric/common-login-strings-fabric";
import { useSignupClickHandler } from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import {
  useGctRequest,
  useGetDefaultCountry,
  usePhoneDisambiguationViewPropertiesFabric,
  useSignInDifferentUserHandler,
} from "../hooks/phone-disambiguation-view-hooks";
import { phoneDisambiguationViewStringsFabric } from "./phone-disambiguation-view-strings-fabric";

const hasInitialFocus = true;

/**
 * PhoneDisambiguationView component
 * @returns A rendered instance of this component
 */
export const PhoneDisambiguationViewFabric: React.FC = function PhoneDisambiguationViewFabric() {
  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  const {
    documentTitle,
    errorMessage: errorMessageString,
    title,
    description,
    placeholder,
    ariaLabel,
    signInWithDifferentUser,
    createNewAccountLabel,
    backLabel,
    nextLabel,
  } = usePhoneDisambiguationViewPropertiesFabric({
    commonLoginStrings: commonLoginStringsFabric,
    phoneDisambiguationStrings: phoneDisambiguationViewStringsFabric,
  });

  const defaultCountry = useGetDefaultCountry();

  useActivateView(ViewId.PhoneDisambiguation, FlowId.Login, { showIdentityBanner: false });
  useDocumentTitle(documentTitle);
  useStaticCommonStyles();

  const { useInlinePhoneNumber, countryList, signupUrl, showSignup } = LoginConfig.instance;

  const {
    globalState: { user },
  } = useContext(GlobalContext);

  const phoneNumberState = usePhoneNumberFabric({
    defaultCountry,
    validationMethod: getPhoneInputValidation(errorMessageString),
    input: { hasInitialFocus, initialValue: user.username.unsafeUnescapedString },
  });

  const {
    dropdownValue,
    onChangeDropdown,
    value: phoneNumberValue,
    inputState,
    error: { showErrorMessage, errorMessage, setServerError },
    onChangeInput,
  } = phoneNumberState;

  const submitParams = {
    value: phoneNumberValue,
    inputValue: String(inputState.value),
    dropdownValue,
    errorHandler: setServerError,
  };

  const onSubmit = useFormSubmission({
    inputState: phoneNumberState,
    viewId: ViewId.PhoneDisambiguation,
    submitTask: useGctRequest(),
    submitParams,
  });

  const commonStyles = useCommonStyles();
  const onSignInDifferentUserClick = useSignInDifferentUserHandler();
  const onSignupClicked = useSignupClickHandler();

  const backButtonOnClick = useNavigateOrRedirectBack();

  useBackButtonHandlerWithCxh(backButtonOnClick);

  return (
    <form
      name="phoneDisambiguationForm"
      data-testid="phoneDisambiguationForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      onSubmit={onSubmit}
    >
      <TitleFabric titleId="phoneDisambiguationTitle" title={title} />
      <DescriptionFabric descriptionId="phoneDisambiguationDescription">
        {description}
      </DescriptionFabric>
      <PhoneNumberFabric
        errorMessage={errorMessage}
        dropdownProps={{
          useInlinePhoneNumber,
          value: dropdownValue,
          countryData: countryList,
          onChange: onChangeDropdown,
        }}
        inputProps={{
          id: "i0116",
          name: "loginfmt",
          placeholder,
          type: "tel",
          onChange: onChangeInput,
          value: inputState.value,
          "aria-label": ariaLabel,
          "aria-describedby": "phoneDisambiguationTitle phoneDisambiguationDescription",
          hasFocus: inputState.hasFocus,
          hasInitialFocus,
          onFocus: inputState.onFocus,
          onBlur: inputState.onBlur,
        }}
        showErrorMessage={showErrorMessage}
      />

      <div className={commonStyles.winButtonPinBottom}>
        <div className={commonStyles.positionButtonsWithBottomMargin}>
          <div className={commonStyles.row}>
            <SecondaryContentContainerFabric>
              <div className={commonStyles.formGroup}>
                <LinkButton
                  linkId="signinWithDifferentUsername"
                  text={signInWithDifferentUser}
                  onClick={() => onSignInDifferentUserClick(String(inputState.value))}
                />
              </div>
              {showSignup && !!signupUrl && (
                <div className={commonStyles.formGroup}>
                  <LinkButton
                    text={createNewAccountLabel}
                    onClick={() => onSignupClicked(phoneNumberValue)}
                    linkId="createAccount"
                  />
                </div>
              )}
            </SecondaryContentContainerFabric>
          </div>

          <TextButtonContainer>
            <TextButtonFabric
              buttonId="backButton"
              label={backLabel}
              isPrimary={false}
              type="button"
              ariaLabel={backLabel}
              onClick={backButtonOnClick}
            />
            <TextButtonFabric
              buttonId="nextButton"
              label={nextLabel}
              isPrimary
              type="submit"
              ariaLabel={nextLabel}
            />
          </TextButtonContainer>
        </div>
      </div>
    </form>
  );
};
