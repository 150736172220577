import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { mergeClasses } from "@griffel/react";
import { ExperimentFeature, TreatmentFlight } from "../../../config/experiments";
import StylesConfig from "../../../config/styles-config";
import {
  ExternalClassName,
  LayoutTemplateType,
  PrivateBrowsingLearnMoreUrl,
} from "../../../constants";
import { ViewId } from "../../../constants/routing-constants";
import { useCustomizationContext } from "../../../context/customization-context";
import GlobalConfig from "../../../global-config";
import { useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { useExperiment } from "../../../hooks";
import { FormattedTextWithBindings } from "../../../utilities/formatted-text-with-bindings";
import { getAgreementViewRoute } from "../../../utilities/routing-helper";
import { htmlUnescape } from "../../../utilities/strings-helper";
import { AgreementType } from "../../../views";
import FooterConfig from "../footer-config";
import { type IFooterPropsFabric } from "./footer-interface-fabric";

/**
 * LightboxFooter component
 * @param props The properties for this component
 * @param props.isDebugInfoShown Whether the debug details info pane should be shown
 * @param props.onDebugItemClick Callback to execute when the debug item is clicked
 * @param props.focusOnEllipsis Whether focus is set on the ellipsis button. Default is false.
 * @returns an instance of this component
 */
const FooterFabric: React.FC<IFooterPropsFabric> = function FooterFabric({
  isDebugInfoShown = false,
  onDebugItemClick,
  focusOnEllipsis = false,
}) {
  const { isHosted, isChinaDc, logoutUrl, hideDebugDetails, showLogoutOnFooter } =
    GlobalConfig.instance;

  const { footerImpressumUrl, footerA11yConformeUrl } = FooterConfig.instance;

  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();
  const {
    customizationState: {
      styles: {
        alwaysShowBackground,
        termsOfUseUrl,
        termsOfUseText,
        privacyUrl,
        privacyText,
        showFooter,
        showTermsOfUse,
        showPrivacy,
        useDarkFooter,
        layoutTemplate,
      },
    },
  } = useCustomizationContext();

  const { treatment } = useExperiment(ExperimentFeature.AddPrivateBrowsingTextToFabricFooter);
  const usePrivateBrowsingTextFooter =
    treatment === TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment;

  const { useFooterStyles } = StylesConfig.instance;
  const styles = useFooterStyles();
  const shouldUseDarkFooter = useDarkFooter && layoutTemplate !== LayoutTemplateType.VerticalSplit;
  const useWhiteFooterForMobile = !alwaysShowBackground && shouldUseDarkFooter;
  const useWhiteTextForMobile = alwaysShowBackground && shouldUseDarkFooter;
  const footerStyles = mergeClasses(
    styles.footer,
    shouldUseDarkFooter ? mergeClasses(styles.darkFooter, ExternalClassName.hasBackground) : "",
    useWhiteFooterForMobile ? styles.whiteMobileFooter : "",
    alwaysShowBackground ? ExternalClassName.backgroundAlwaysVisible : "",
    ExternalClassName.footer,
  );
  const footerItemStyles = mergeClasses(
    styles.footerItem,
    shouldUseDarkFooter
      ? mergeClasses(styles.darkFooterFooterItem, ExternalClassName.hasBackground)
      : "",
    useWhiteTextForMobile ? styles.whiteTextMobileFooterItem : "",
    alwaysShowBackground ? ExternalClassName.backgroundAlwaysVisible : "",
    ExternalClassName.footerItem,
    ExternalClassName.footerContent,
  );
  const footerLinksStyles = mergeClasses(
    usePrivateBrowsingTextFooter ? styles.extendedFooterNode : styles.footerNode,
    ExternalClassName.footerLinks,
  );
  const mergedDebugItemClasses = mergeClasses(
    footerItemStyles,
    styles.debugItem,
    ExternalClassName.debugItem,
  );

  const darkFooterLearnMoreLinkStyles = mergeClasses(
    styles.darkFooterFooterItem,
    useWhiteTextForMobile ? styles.whiteTextMobileFooterItem : "",
    styles.darkFooterPrivateBrowsingLinkStyles,
  );

  const learnMoreLink = (chunks: string[]) => (
    <a
      id="learnMoreLink"
      data-testid="learnMoreLink"
      href={PrivateBrowsingLearnMoreUrl}
      className={shouldUseDarkFooter ? darkFooterLearnMoreLinkStyles : ""}
    >
      {chunks[0]}
    </a>
  );

  const location = useLocation();
  const prevPath = location.pathname;
  // Resets focus on the debug banner button when the debug details component is closed with pressing enter.
  // The onDebugCloseClick onClick handler in the lightbox-layout updates this value.
  const debugLinkRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (focusOnEllipsis && !isDebugInfoShown) {
      debugLinkRef?.current?.focus();
    }
  }, [focusOnEllipsis, isDebugInfoShown]);

  const createAgreementLinks = () => {
    // show links that navigate to the agreement view component
    if (isHosted && !isChinaDc) {
      return (
        <>
          {showTermsOfUse && !!termsOfUseUrl && (
            <Link
              to={getAgreementViewRoute(AgreementType.TermsOfUse)}
              id="ftrTerms"
              className={footerItemStyles}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {htmlUnescape(termsOfUseText)}
            </Link>
          )}
          {showPrivacy && !!privacyUrl && (
            <Link
              to={getAgreementViewRoute(AgreementType.Privacy)}
              id="ftrPrivacy"
              className={footerItemStyles}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {htmlUnescape(privacyText)}
            </Link>
          )}
          {!!footerImpressumUrl && (
            <Link
              to={getAgreementViewRoute(AgreementType.Impressum)}
              id="ftrImpressum"
              className={footerItemStyles}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {getLocalString("Impressum_Link")}
            </Link>
          )}
          {!!footerA11yConformeUrl && (
            <Link
              to={getAgreementViewRoute(AgreementType.A11yConforme)}
              id="ftrA11yConforme"
              className={footerItemStyles}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {getLocalString("A11y_Conformance_Footer_Url_Text")}
            </Link>
          )}
        </>
      );
    }

    // show external links to the agreements
    return (
      <>
        {showTermsOfUse && !!termsOfUseUrl && (
          <a
            id="ftrTerms"
            href={termsOfUseUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {htmlUnescape(termsOfUseText)}
          </a>
        )}
        {showPrivacy && !!privacyUrl && (
          <a
            id="ftrPrivacy"
            href={privacyUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {htmlUnescape(privacyText)}
          </a>
        )}
        {!!footerImpressumUrl && (
          <a
            id="ftrImpressum"
            href={footerImpressumUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {getLocalString("Impressum_Link")}
          </a>
        )}
        {!!footerA11yConformeUrl && (
          <a
            id="ftrA11yConforme"
            href={footerA11yConformeUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {getLocalString("A11y_Conformance_Footer_Url_Text")}
          </a>
        )}
      </>
    );
  };

  const logoutUrlContent = showLogoutOnFooter && logoutUrl && (
    <a id="logoutUrl" data-testid="logoutUrl" href={logoutUrl} className={footerItemStyles}>
      {getLocalString("SignOut_Text")}
    </a>
  );

  const privateBrowsingTextStyles = mergeClasses(
    footerItemStyles,
    styles.privateBrowsingTextStyles,
  );

  return (
    <div id="footer" data-testid="footer" role="contentinfo" className={footerStyles}>
      <div id="footerLinks" className={footerLinksStyles}>
        {showFooter && (
          <>
            {logoutUrlContent}
            {createAgreementLinks()}
          </>
        )}
        {
          // Disabling role rule since we want this to look like a link, however on click the action does not take the user
          // out of the context of what they were doing, an instead behaves as a button
          !hideDebugDetails && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              id="moreOptions"
              href="#"
              role="button"
              onClick={onDebugItemClick}
              className={mergedDebugItemClasses}
              aria-label={getLocalString("Troubleshooting_Ellipsis_AriaLabel")}
              aria-expanded={isDebugInfoShown}
              ref={debugLinkRef}
            >
              ...
            </a>
          )
        }
      </div>
      {!isHosted && usePrivateBrowsingTextFooter && (
        <div className={footerLinksStyles}>
          <div className={privateBrowsingTextStyles}>
            <FormattedTextWithBindings
              text={getLocalString("Private_Browsing_Information_Footer_Text")}
              embeddedBindings={{
                learnMoreLink,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FooterFabric;
