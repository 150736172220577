import React, { useEffect, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { SecondaryContentContainerFabric as SecondaryContentContainer } from "../../../../../components";
import { HelpButton } from "../../../../../components/help-button";
import { InputFabric } from "../../../../../components/inputs/input/fabric/input-fabric";
import { InputContainer } from "../../../../../components/inputs/input-container";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants";
import { isPlatformAuthenticatorAvailable } from "../../../../../utilities/browser-helper";
import { FormattedTextWithBindings } from "../../../../../utilities/formatted-text-with-bindings";
import { documentationIcon } from "../../../../../utilities/image-helpers/accessible-images";
import { CreateAccountDropdownFabric } from "../../../components/fabric/create-account-dropdown-fabric";
import { CredentialSwitchContentFabric } from "../../../components/fabric/credential-switch-content-fabric";
import { FormattedSignupMessageFabric as FormattedSignupMessage } from "../../../components/fabric/formatted-signup-message-fabric";
import { LoginTitleDescriptionFabric as LoginTitle } from "../../../components/fabric/login-title-description-fabric";
import { InlineLegalMessagingFabric } from "../../../components/inline-legal-messaging/fabric/inline-legal-messaging-fabric";
import {
  commonLoginStringsFabric,
  loginInputValidationErrorStringsFabric,
} from "../../../fabric/common-login-strings-fabric";
import {
  useFidoPostRedirect,
  useSetMsaConsentStringDisplayed,
  useShowGraphicAnimation,
} from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import { UsernameMaxLength } from "../../../login-constants";
import { useUsernameLogEvents } from "../hooks/use-username-log-events";
import { useUsernameViewBackButtonHandlerWithCxh } from "../hooks/use-username-view-back-button-handler-with-cxh";
import { useUsernameErrorBindingsFabric } from "./hooks/use-username-error-bindings-fabric";
import { useUsernameViewFabric } from "./hooks/use-username-view-fabric";
import { useSecondaryButtonPropertiesFabric } from "./use-secondary-button-properties-fabric";
import { useSignInOptionsPropertiesFabric } from "./use-sign-in-options-properties-fabric";
import { useUsernameViewPropertiesFabric } from "./use-username-view-properties-fabric";
import { usernameViewStringsFabric } from "./username-view-strings-fabric";

/**
 * Fabric UsernameView component
 * @returns A rendered instance of this component
 */
export const UsernameViewFabric: React.FC = function UsernameViewFabric() {
  const { useCommonStyles, useHelpButtonStyles, useStaticCommonStyles } = StylesConfig.instance;

  const {
    showLegalMessagingInline,
    useWebviewFidoCustomProtocol,
    enableAriaLiveUpdates,
    allowPhone,
    allowSkype,
  } = LoginConfig.instance;

  const {
    fidoDialogDescription,
    fidoDialogHeader,
    fidoLinkText,
    header,
    headerId,
    descriptionProperties: { renderDescription, descriptionId, description },
    ariaDescribedBy,
    loginHelpFidoAriaLabel,
    loginHelpHelloOrFidoAriaLabel,
    placeholder,
    signupLinkAriaLabel,
    signupLinkText,
    title,
    username,
    isPasskeySupported,
    showCreateAccountDropdown,
    isParentSignIn,
  } = useUsernameViewPropertiesFabric({
    usernameViewStrings: usernameViewStringsFabric,
    commonLoginStrings: commonLoginStringsFabric,
  });

  const {
    renderSecondaryButton,
    secondaryButtonClickHandler,
    secondaryButtonId,
    secondaryButtonLabel,
  } = useSecondaryButtonPropertiesFabric(usernameViewStringsFabric);

  const { showSignInOptions, signInOptionsLinkProperties, signInOptionsButtonProperties } =
    useSignInOptionsPropertiesFabric(usernameViewStringsFabric);

  useUsernameViewBackButtonHandlerWithCxh();

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();
  const helpButtonStyles = useHelpButtonStyles();

  const getEmbeddedLinkBindings = useUsernameErrorBindingsFabric();
  const getErrorMessage = (unsafeUsername: string, gctError: string) => {
    const { ...bindings } = getEmbeddedLinkBindings(unsafeUsername);
    return <FormattedTextWithBindings text={gctError} embeddedBindings={{ ...bindings }} />;
  };

  const [helpButtonAriaLabel, setHelpButtonAriaLabel] = useState("");

  useEffect(() => {
    isPlatformAuthenticatorAvailable(useWebviewFidoCustomProtocol).then((isAvailable) => {
      setHelpButtonAriaLabel(isAvailable ? loginHelpFidoAriaLabel : loginHelpHelloOrFidoAriaLabel);
    });
  });

  /** These functions are for handling OOBE-specific behavior */
  useSetMsaConsentStringDisplayed();
  useShowGraphicAnimation();
  useUsernameLogEvents();

  const { inputState, onSubmit, usernameString } = useUsernameViewFabric({
    strings: {
      documentTitle: title,
      errorStringInvalidMemberName: usernameViewStringsFabric.errorInvalidMemberNameEmailPhoneSkype,
      errorStringInvalidPhoneNumber: usernameViewStringsFabric.badPhoneNumberErrorMessage,
      loginInputValidationErrorStrings: loginInputValidationErrorStringsFabric,
    },
    activateViewOptions: {
      showIdentityBanner: false,
      showMoreOptions: showSignInOptions ? signInOptionsButtonProperties : undefined,
    },
    activeFlowId: FlowId.Login,
    getErrorMessage,
    allowPhone,
    allowSkype,
    username,
  });

  const {
    hasFocus,
    onFocus,
    onBlur,
    value,
    onChange,
    error: { showErrorMessage, errorMessage },
  } = inputState;

  const fidoRedirectCallback = useFidoPostRedirect();
  const fidoLinkClickHandler = () => {
    fidoRedirectCallback({ username: usernameString });
  };

  return (
    <div>
      <form
        name="f1"
        id="i0281"
        data-testid="usernameForm"
        noValidate
        spellCheck="false"
        method="post"
        autoComplete="false"
        onSubmit={onSubmit}
      >
        <LoginTitle titleId={headerId} title={header} />

        {renderDescription && (
          <div
            className={mergeClasses(
              commonStyles.row,
              commonStyles.textBody,
              commonStyles.noMarginTop,
            )}
          >
            <div id={descriptionId}>{description}</div>
          </div>
        )}

        <InputFabric
          id="i0116"
          name="loginfmt"
          placeholder={placeholder}
          type="email"
          showErrorMessage={showErrorMessage}
          enableAriaLiveUpdates={enableAriaLiveUpdates}
          errorMessage={errorMessage}
          value={value}
          onChange={onChange}
          aria-describedby={ariaDescribedBy}
          aria-label={usernameViewStringsFabric.usernameAriaLabel}
          hasFocus={hasFocus}
          hasInitialFocus
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete="username"
          maxLength={UsernameMaxLength}
        />

        <SecondaryContentContainer>
          <div className={commonStyles.row}>
            <div className={commonStyles.formGroup}>
              {showCreateAccountDropdown && (
                <CreateAccountDropdownFabric username={usernameString} />
              )}
              {!showCreateAccountDropdown && signupLinkText && (
                <FormattedSignupMessage
                  id={isParentSignIn ? "signupOnParentSignin" : "signup"}
                  username={usernameString}
                  arialLabel={signupLinkAriaLabel}
                  text={signupLinkText}
                  linkTestId={isParentSignIn ? "parentSignUpLink" : "signUpLink"}
                />
              )}
            </div>
          </div>

          {fidoLinkText && !isPasskeySupported && (
            <div className={commonStyles.row}>
              <div className={commonStyles.formGroup}>
                <div>
                  <LinkButton text={fidoLinkText} linkId="fidoUrl" onClick={fidoLinkClickHandler} />
                  <HelpButton
                    helpDialogIcon={{ urls: documentationIcon, dataTestId: "help-button" }}
                    helpDialogHeader={fidoDialogHeader}
                    helpDialogDescription={fidoDialogDescription}
                    helpDialogIconClassName={helpButtonStyles.helpButton}
                    helpDialogAriaLabel={helpButtonAriaLabel}
                  />
                </div>
              </div>
            </div>
          )}

          {showSignInOptions && signInOptionsLinkProperties && (
            <CredentialSwitchContentFabric
              credentialSwitchProps={signInOptionsLinkProperties}
              showSwitchToEvictedCredPicker={false}
            />
          )}
        </SecondaryContentContainer>

        {showLegalMessagingInline && <InlineLegalMessagingFabric sourceViewId={ViewId.Username} />}

        <InputContainer>
          <div className={commonStyles.winButtonPinBottom}>
            <div className={commonStyles.row}>
              <TextButtonContainer>
                {renderSecondaryButton && (
                  <TextButtonFabric
                    buttonId={secondaryButtonId}
                    label={secondaryButtonLabel}
                    isPrimary={false}
                    type="button"
                    onClick={secondaryButtonClickHandler}
                  />
                )}
                <TextButtonFabric
                  buttonId="idSIButton9"
                  label={getLocalString("General_Buttons_Next")}
                  isPrimary
                  type="submit"
                />
              </TextButtonContainer>
            </div>
          </div>
        </InputContainer>
      </form>
    </div>
  );
};
