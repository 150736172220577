import { trim } from "../../utilities/strings-helper";

/**
 * Checks if string is an email address
 * @param str The string to check
 * @returns TRUE if valid email address, FALSE otherwise
 */
export function isEmailAddress(str: string) {
  const newStr = trim(str);
  // Cannot Start with invalid char or contain spaces
  if (newStr.charAt(0) > "~" || newStr.indexOf(" ") !== -1) {
    return false;
  }

  // Must contain @ and text after the @ must have a .
  const atIndex = newStr.indexOf("@");
  if (atIndex === -1 || str.indexOf(".", atIndex) === -1) {
    return false;
  }

  // Can only contain 1 @ and text before + after @ must be longer than length 1
  const parts = newStr.split("@");
  if (parts.length > 2 || parts[0].length < 1 || parts[1].length < 2) {
    return false;
  }

  // Check if the character is an ascii char between 21 to 7E inclusive
  const asciiRegex = /^[\x21-\x7E]+$/;
  if (!newStr.match(asciiRegex)) {
    return false;
  }

  return true;
}

/**
 * Check if string is valid skype name
 * @param str The string to check
 * @returns TRUE if string is valid skype name false otherwise
 */
export function isSkypeName(str: string) {
  const newStr = trim(str);
  const skypeRegex = /^[a-zA-Z][a-zA-Z0-9.,\-_:']{0,128}$/;
  return !!newStr.match(skypeRegex);
}

/**
 * Check if string is valid phone number
 * @param str The string to check
 * @returns TRUE if string is valid phone number false otherwise
 */
export function isPhoneNumber(str: string) {
  const digits = str.replace(/\D+/g, "");

  return digits.length >= 4 && digits.length <= 50;
}

/**
 * Returns domain portion of the given e-mail address.
 * @param str - The email address for which to extract the domain.
 * @param removeDomainSuffix - If true, removes all characters after the last '.'. If false, returns the full domain.
 * @param includeDomainSeparator - If true, includes the '@' in the domain. If false, returns the domain without the '@'.
 * @returns The domain portion of this e-mail address formatted string. If not a valid e-mail address, return this string as is.
 */
export const extractDomain = (
  str: string,
  removeDomainSuffix: boolean,
  includeDomainSeparator: boolean,
): string => {
  if (!isEmailAddress(str)) {
    return str;
  }

  const domain = trim(str).split("@")[1];
  const separator = includeDomainSeparator ? "@" : "";

  if (removeDomainSuffix) {
    return separator + domain.slice(0, domain.lastIndexOf(".") + 1);
  }

  return separator + domain;
};

/**
 * Validates the email initials.
 * @param input - the input value
 * @param email - the email value
 * @returns boolean indicating if the email initials are valid
 */
export const validateEmailInitials = (input: string, email: string): boolean => {
  const emailInitials = email.substring(0, 2);
  const inputInitials = input.substring(0, 2);

  return inputInitials === emailInitials;
};

/**
 * Validates the email domains.
 * @param input - the input value
 * @param email - the email value
 * @returns boolean indicating if the email domains are the same
 */
export const validateEmailDomains = (input: string, email: string): boolean => {
  const emailDomain = extractDomain(email, false, true);
  const inputDomain = extractDomain(input, false, true);

  return emailDomain === inputDomain;
};
