import { makeStyles, shorthands } from "@griffel/react";
import * as dropdownConstants from "./dropdown-fabric.styles";

export const usePhoneCountryDropdownStylesFabric = makeStyles({
  phoneCountryBoxNotInline: {
    width: "100%",
    float: "left",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
    "& select.has-error": {
      ...dropdownConstants.hasError,
    },
    "& select.has-error:focus": {
      ...dropdownConstants.hasErrorFocusBox,
    },
  },
  phoneCountryBoxInline: {
    display: "inline-block",
    width: "20.83333%",
    float: "left",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
    "& select": {
      left: 0,
      opacity: 0,
      cursor: "pointer",
    },
    ":global([dir='rtl'])": {
      float: "right",
    },
    "@media only screen and (max-width: 320px)": {
      width: "100%",
      display: "block",
    },
  },
  phoneCountryCodeMenuButtonStyle: {
    height: "36px",
  },
  phoneCountryCodeLabel: {
    ...dropdownConstants.dropdownBaseStyle,
    width: "100%",
    left: 0,
    paddingLeft: "4px",
    paddingRight: "4px",
    marginTop: 0,
    marginBottom: 0,
    textAlign: "left",
    ":global([dir='rtl'])": { textAlign: "right" },
  },
  labelHasError: { ...dropdownConstants.hasError },
  labelHasFocus: { ...dropdownConstants.hasFocus },
  labelHasErrorFocusBox: { ...dropdownConstants.hasErrorFocusBox },
  dropdown: {
    position: "absolute",
    width: "100%",
    ...shorthands.padding("0"),
    maxHeight: "150px",
    backgroundColor: "#fff",
    backgroundClip: "paddingBox",
    ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.1)"),
    ...shorthands.overflow("auto"),
    zIndex: 2,
  },
  downArrow: {
    position: "absolute",
    top: 0,
    right: "-6px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "0px",
    paddingLeft: "0px",
    height: "36px",
    ":global([dir='rtl'])": {
      left: "-6px",
      right: "auto",
    },
  },
});

export type PhoneCountryDropdownStylesFabric = ReturnType<
  typeof usePhoneCountryDropdownStylesFabric
>;
