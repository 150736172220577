import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../../config/styles-config";
import featuresConfig from "../../../../features-config";
import ErrorContainerFabric from "../../../error-container/fabric/error-container-fabric";
import { InputFabric } from "../../input/fabric/input-fabric";
import { handleDefaultErrorParams } from "../../utilities/handle-default-error-params";
import {
  type PhoneNumberInputProps,
  type PhoneNumberInputPropsFabric,
} from "../phone-number-types";
import { PhoneCountryDropdownFabric } from "./phone-country-dropdown-fabric";
import { PhoneCountryDropdownFabricDeprecated } from "./phone-country-dropdown-fabric-deprecated";

export type PhoneNumberFabricProps = Omit<PhoneNumberInputProps, "inputProps"> & {
  inputProps: PhoneNumberInputPropsFabric;
};

/**
 * PhoneNumber component for Fabric
 * @param props The properties for this component.
 * @param props.dropdownProps The props to pass to the phone country dropdown component.
 * @param props.inputProps The props to pass to the text input component.
 * @param props.errorMessage The message to display when there is a validation or external error.
 * @param props.phoneNumberChangeHandler Callback for when the country dropdown or phone number input is updated.
 * @returns A shared component that includes the phone country dropdown and phone number input field
 */
export const PhoneNumberFabric: React.FC<PhoneNumberFabricProps> = function PhoneNumberFabric(
  props,
) {
  const { errorMessage, displayErrorStyling, showErrorMessage } = handleDefaultErrorParams(props);
  const { dropdownProps, inputProps, appendedLabel = "" } = props;

  const { useCommonStyles, usePhoneNumberStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const phoneNumberStyles = usePhoneNumberStyles();

  const { loadGamepadNavigationModule: useNewCountryDropdown } = featuresConfig.instance;
  const className = useNewCountryDropdown
    ? mergeClasses(phoneNumberStyles.phoneNumberContainer, commonStyles.row)
    : commonStyles.row;
  const countryDropdown = useNewCountryDropdown ? (
    <PhoneCountryDropdownFabric {...dropdownProps} displayErrorStyling={displayErrorStyling} />
  ) : (
    // eslint-disable-next-line deprecation/deprecation
    <PhoneCountryDropdownFabricDeprecated
      {...dropdownProps}
      displayErrorStyling={displayErrorStyling}
    />
  );

  return (
    <div className={className}>
      {showErrorMessage && (
        <ErrorContainerFabric
          id={`${inputProps.id}Error`}
          className={mergeClasses(phoneNumberStyles.errorContainer, commonStyles.alertError)}
        >
          {errorMessage}
        </ErrorContainerFabric>
      )}
      <div>
        {countryDropdown}
        <div
          data-testid="phone-number-input"
          className={
            dropdownProps.useInlinePhoneNumber
              ? phoneNumberStyles.inlineInput
              : phoneNumberStyles.nonInlineInput
          }
        >
          <InputFabric {...inputProps} displayErrorStyling={displayErrorStyling} />
        </div>
        {appendedLabel && <div>{appendedLabel}</div>}
      </div>
    </div>
  );
};
