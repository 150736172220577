import { RemoteNgcType } from "../../../constants";
import { replaceTokens } from "../../../utilities/strings-helper";
import { type IDeviceProperties } from "../login-context";
import {
  type IAriaDescribedByProperties,
  type IPushNotificationsDialogStrings,
  type IPushNotificationsViewStrings,
} from "./push-notifications-view-interface";

/**
 * Selects the appropriate description strings. The description is determined by whether the device is familiar or not and whether the device name and application name are known.
 * @param remoteNgcType The remote ngc type
 * @param displaySign The display sign
 * @param pushNotificationsViewStringsFabric Strings that are specific to the push notification view
 * @param heroAppProperties The hero app (MRU) properties
 * @returns The polling description
 */
export const getPollingDescription = (
  remoteNgcType: RemoteNgcType,
  displaySign: string,
  pushNotificationsViewStringsFabric: IPushNotificationsViewStrings,
  heroAppProperties: IDeviceProperties,
): string => {
  const {
    descriptionFamiliarDevice,
    descriptionFamiliarDeviceGenericAppName,
    descriptionFamiliarDeviceGenericDevice,
    descriptionFamiliarDeviceGenericAppNameGenericDevice,
    descriptionUnfamiliarDevice,
    descriptionUnfamiliarDeviceGenericAppName,
    descriptionUnfamiliarDeviceGenericDevice,
    descriptionUnfamiliarDeviceGenericAppNameGenericDevice,
  } = pushNotificationsViewStringsFabric;

  let description = "";
  const { name: deviceName, application } = heroAppProperties;
  if (remoteNgcType === RemoteNgcType.PushNotification) {
    if (displaySign) {
      // Sets the description for unfamiliar device sign-in when the device name is known
      if (heroAppProperties.name) {
        // Set the description with the app name when known or generic app name when the app name is unknown
        description = heroAppProperties.application
          ? replaceTokens(descriptionUnfamiliarDevice, application, deviceName)
          : replaceTokens(descriptionUnfamiliarDeviceGenericAppName, deviceName);
      }
      // Sets the description for unfamiliar device sign-in when the device name is unknown
      else {
        // Set the description with the app name when known or generic app name when the app name is unknown
        description = heroAppProperties.application
          ? replaceTokens(descriptionUnfamiliarDeviceGenericDevice, application)
          : descriptionUnfamiliarDeviceGenericAppNameGenericDevice;
      }
    }
    // Sets the description for familiar device sign-in when the device name is known
    else if (heroAppProperties.name) {
      // Set the description with the app name when known or generic app name when the app name is unknown
      description = heroAppProperties.application
        ? replaceTokens(descriptionFamiliarDevice, application, deviceName)
        : replaceTokens(descriptionFamiliarDeviceGenericAppName, deviceName);
    }
    // Sets the description for familiar device sign-in when the device name is unknown
    else {
      // Set the description with the app name when known or generic app name when the app name is unknown
      description = heroAppProperties.application
        ? replaceTokens(descriptionFamiliarDeviceGenericDevice, application)
        : descriptionFamiliarDeviceGenericAppNameGenericDevice;
    }
  }

  // TO-DO: ESTS - Add the condition for strings when remoteNgcType is ListSessions.
  return description;
};

/**
 * Returns the ariaDescribedBy properties used by the primary button, credentialSwitchLink, and displaySign.
 * @param title The title displayed in the view.
 * @param renderBrandingDescription Whether to render the branding description.
 * @param brandingDescriptionId The id attribute value when rendering the branding description.
 * @param error The error message displayed in the view.
 * @returns Returns the ariaDescribedBy used by the primary button, credentialSwitchLink, and displayCode elements.
 */
export const getAriaDescribedByProperties = (
  title: string,
  renderBrandingDescription: boolean,
  brandingDescriptionId: string,
  error: string,
): IAriaDescribedByProperties => {
  const titleId = "pushNotificationsTitle";
  const errorId = "errorDescription";
  const descriptionId = "pollingDescription";
  const displaySignId = "displaySign";

  let primaryButtonAriaDescribedBy = title ? titleId : "";
  primaryButtonAriaDescribedBy += renderBrandingDescription ? ` ${brandingDescriptionId}` : "";
  primaryButtonAriaDescribedBy += error ? ` ${errorId}` : "";

  let initialFocusElementAriaDescribedBy = title ? titleId : "";
  initialFocusElementAriaDescribedBy += renderBrandingDescription
    ? ` ${brandingDescriptionId}`
    : "";
  initialFocusElementAriaDescribedBy += error
    ? ` ${errorId}`
    : ` ${descriptionId} ${displaySignId}`;

  return {
    descriptionId,
    displaySignId,
    errorId,
    titleId,
    initialFocusElementAriaDescribedBy,
    primaryButtonAriaDescribedBy,
  };
};

/**
 * When the "See all devices" link is clicked, a modal dialog is displayed with a list of apps and devices that the push notification was
 * sent to. This returns the string for a given list item (e.g., "Outlook app on iPhone 14").
 * @param deviceProperty An item from the devices list.
 * @param pushNotificationsDialogStrings The strings used in the push notifications dialog.
 * @returns The string indicating what device and app the push notification was sent to.
 */
export const getDialogListItemText = (
  deviceProperty: IDeviceProperties,
  pushNotificationsDialogStrings: IPushNotificationsDialogStrings,
) => {
  const {
    listItem,
    listItemGenericAppName,
    listItemGenericDevice,
    listItemGenericAppNameGenericDevice,
  } = pushNotificationsDialogStrings;

  const { application, name } = deviceProperty;
  let deviceItemString = "";

  if (name) {
    deviceItemString = application
      ? replaceTokens(listItem, application, name)
      : replaceTokens(listItemGenericAppName, name);
  } else {
    deviceItemString = application
      ? replaceTokens(listItemGenericDevice, application)
      : listItemGenericAppNameGenericDevice;
  }

  return deviceItemString;
};
