import { ConfigWrapper } from "./config-wrapper";

export type AcmaConfigType = {
  acmaServerPath: string;
  acmaPollingInterval: number;
  amcaResendCodeDelay: number;
  acmaRiskVerifyPath: string;
};

export const defaultAcmaConfig: AcmaConfigType = {
  acmaServerPath: "",
  acmaRiskVerifyPath: "/api/v0.1/risk/verify",
  /*
   * TODO: Define with EC team whether the next 2 values will be defined by ACMA APIs.
   * Otherwise, move to a flow specific permament config file.
   */
  acmaPollingInterval: 1000,
  amcaResendCodeDelay: 90000,
};

const AcmaConfig = new ConfigWrapper(defaultAcmaConfig);

/**
 * The config wrapper that should be used to access the config properties
 */
export default AcmaConfig;

/**
 * Initialize the config object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * @param { string } tenantId (optional) The tenantId to use for endpoint paths
 */
export function initAcmaConfig(tenantId?: string) {
  // TODO: this is where we need to map the server data to the config object, when needed
  // TODO: manage versioning for api paths
  AcmaConfig.initialize({
    acmaRiskVerifyPath: tenantId
      ? `/${tenantId}${defaultAcmaConfig.acmaRiskVerifyPath}`
      : defaultAcmaConfig.acmaRiskVerifyPath,
  });
}
