/* eslint-disable deprecation/deprecation */

import { Flavors } from "../constants";
import {
  useAccessibleImageStylesFabric,
  useAgreementViewStylesFabric,
  useArkoseEnforcementStylesFabric,
  useBackArrowButtonStylesFabric,
  useBackgroundLogoStylesFabric,
  useBackgroundStylesFabric,
  useBannerLogoStylesFabric,
  useBenefitsViewStylesFabric,
  useBoilerPlateStylesFabric,
  useButtonStylesFabric,
  useCaptchaViewStylesFabric,
  useCheckboxStylesFabric,
  useCommonStylesFabric,
  useCriticalErrorPageStylesFabric,
  useDebugDetailsBannerStylesFabric,
  useDebugTraceStylesFabric,
  useDialogStylesFabric,
  useEnvironmentBannerStylesFabric,
  useFloatingDropdownStylesFabric,
  useFooterStylesFabric,
  useHeaderStylesFabric,
  useHelpButtonStylesFabric,
  useHipChallengeStylesFabric,
  useHipChallengeTwoUiStylesFabric,
  useIdentityBannerStylesFabric,
  useInlineImageStylesFabric,
  useLayoutAnimateStylesFabric,
  useLearnAboutConsentViewStylesFabric,
  useLinkButtonStylesFabric,
  useOptionsMenuButtonStylesFabric,
  useOptionsMenuButtonStylesFabricDeprecated,
  usePhoneCountryDropdownStylesFabric,
  usePhoneCountryDropdownStylesFabricDeprecated,
  usePhoneNumberStylesFabric,
  useProgressIndicatorStylesFabric,
  useProofImageStylesFabric,
  usePushNotificationsViewStylesFabric,
  useRadioButtonStylesFabric,
  useRouteAnimationStylesFabric,
  useSecondaryContentStylesFabric,
  useStaticCommonStylesFabric,
  useStaticHipChallengeStylesFabric,
  useStaticHipChallengeTwoUiStylesFabric,
  useTextButtonContainerStylesFabric,
  useTextButtonStylesFabric,
  useTextInputStylesFabric,
  useTileStylesFabric,
  useTitleStylesFabric,
  useVerticalSplitStylesFabric,
} from "./fabric";
import type { StylesConfig } from "./types.styles";
import {
  useBackArrowButtonStylesWin11OobeFabric,
  useBackgroundStylesWin11OobeFabric,
  useBoilerPlateStylesWin11OobeFabric,
  useCommonStylesWin11OobeFabric,
  useDebugDetailsBannerStylesWin11OobeFabric,
  useDialogStylesWin11OobeFabric,
  useProgressIndicatorStylesWin11OobeFabric,
  useSecondaryContentStylesWin11OobeFabric,
  useStaticCommonStylesWin11OobeFabric,
  useTextButtonStylesWin11OobeFabric,
  useTileStylesWin11OobeFabric,
  useTitleStylesWin11OobeFabric,
} from "./win11-oobe-fabric";

export const StylesConfigFabric: StylesConfig = {
  useAccessibleImageStyles: useAccessibleImageStylesFabric,
  useAgreementViewStyles: useAgreementViewStylesFabric,
  useArkoseEnforcementStyles: useArkoseEnforcementStylesFabric,
  useBackArrowButtonStyles: useBackArrowButtonStylesFabric,
  useBackgroundLogoStyles: useBackgroundLogoStylesFabric,
  useBackgroundStyles: useBackgroundStylesFabric,
  useBannerLogoStyles: useBannerLogoStylesFabric,
  useBenefitsViewStyles: useBenefitsViewStylesFabric,
  useBoilerPlateStyles: useBoilerPlateStylesFabric,
  useButtonStyles: useButtonStylesFabric,
  useCheckboxStyles: useCheckboxStylesFabric,
  useCaptchaViewStyles: useCaptchaViewStylesFabric,
  useCommonStyles: useCommonStylesFabric,
  useCriticalErrorPageStyles: useCriticalErrorPageStylesFabric,
  useDebugDetailsBannerStyles: useDebugDetailsBannerStylesFabric,
  useDebugTraceStyles: useDebugTraceStylesFabric,
  useDialogStyles: useDialogStylesFabric,
  useEnvironmentBannerStyles: useEnvironmentBannerStylesFabric,
  useFloatingDropdownStyles: useFloatingDropdownStylesFabric,
  useFooterStyles: useFooterStylesFabric,
  useHeaderStyles: useHeaderStylesFabric,
  useHelpButtonStyles: useHelpButtonStylesFabric,
  useHipChallengeStyles: useHipChallengeStylesFabric,
  useHipChallengeTwoUiStyles: useHipChallengeTwoUiStylesFabric,
  useIdentityBannerStyles: useIdentityBannerStylesFabric,
  useLayoutAnimateStyles: useLayoutAnimateStylesFabric,
  useLearnAboutConsentViewStyles: useLearnAboutConsentViewStylesFabric,
  useLinkButtonStyles: useLinkButtonStylesFabric,
  useOptionsMenuButtonStyles: useOptionsMenuButtonStylesFabric,
  useOptionsMenuButtonStylesDeprecated: useOptionsMenuButtonStylesFabricDeprecated,
  usePhoneCountryDropdownStyles: usePhoneCountryDropdownStylesFabric,
  usePhoneCountryDropdownStylesDeprecated: usePhoneCountryDropdownStylesFabricDeprecated,
  usePhoneNumberStyles: usePhoneNumberStylesFabric,
  useProgressIndicatorStyles: useProgressIndicatorStylesFabric,
  useProofImageStyles: useProofImageStylesFabric,
  useInlineImageStyles: useInlineImageStylesFabric,
  usePushNotificationsViewStyles: usePushNotificationsViewStylesFabric,
  useRadioButtonStyles: useRadioButtonStylesFabric,
  useRouteAnimationStyles: useRouteAnimationStylesFabric,
  useSecondaryContentStyles: useSecondaryContentStylesFabric,

  // Static styles
  useStaticCommonStyles: useStaticCommonStylesFabric,
  useStaticHipChallengeStyles: useStaticHipChallengeStylesFabric,
  useStaticHipChallengeTwoUiStyles: useStaticHipChallengeTwoUiStylesFabric,

  useTextButtonContainerStyles: useTextButtonContainerStylesFabric,
  useTextButtonStyles: useTextButtonStylesFabric,
  useTextInputStyles: useTextInputStylesFabric,
  useTileStyles: useTileStylesFabric,
  useTitleStyles: useTitleStylesFabric,
  useVerticalSplitStyles: useVerticalSplitStylesFabric,
};

export const StylesConfigWin11OobeFabric: StylesConfig = {
  ...StylesConfigFabric,
  useBackArrowButtonStyles: useBackArrowButtonStylesWin11OobeFabric,
  useBackgroundStyles: useBackgroundStylesWin11OobeFabric,
  useBoilerPlateStyles: useBoilerPlateStylesWin11OobeFabric,
  useCommonStyles: useCommonStylesWin11OobeFabric,
  useDebugDetailsBannerStyles: useDebugDetailsBannerStylesWin11OobeFabric,
  useDialogStyles: useDialogStylesWin11OobeFabric,
  useProgressIndicatorStyles: useProgressIndicatorStylesWin11OobeFabric,
  useSecondaryContentStyles: useSecondaryContentStylesWin11OobeFabric,

  // Static styles
  useStaticCommonStyles: useStaticCommonStylesWin11OobeFabric,

  useTextButtonStyles: useTextButtonStylesWin11OobeFabric,
  useTileStyles: useTileStylesWin11OobeFabric,
  useTitleStyles: useTitleStylesWin11OobeFabric,
};

export const FlavorToStylesConfigMap = new Map<Flavors, StylesConfig>([
  [Flavors.Fabric, StylesConfigFabric],
  [Flavors.WindowsFabric, StylesConfigFabric],
  [Flavors.Win11OobeFabric, StylesConfigWin11OobeFabric],
]);
