import GlobalConfig, { signInNameUserOptions } from "../../global-config";
import { type UserOptions } from "../../global-reducer";

export const getInitialUser = (getUserOptions?: () => UserOptions) => {
  if (getUserOptions) {
    return getUserOptions();
  }

  const { signInName, postUsername } = GlobalConfig.instance;
  return signInNameUserOptions(signInName?.unsafeUnescapedString ? signInName : postUsername);
};
