import React from "react";
import { DescriptionFabric } from "../../../../../components";
import { AccessibleImage } from "../../../../../components/accessible-image";
import { ListContainer } from "../../../../../components/list-container";
import { TitleFabric } from "../../../../../components/title/fabric/title-fabric";
import { ViewContainerFabric } from "../../../../../components/view-container/fabric/view-container-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants";
import { useActivateView, useBackButtonControl, useDocumentTitle } from "../../../../../hooks";
import {
  multiplayerJoinIcon,
  peopleShield,
  settingsIcon,
  timerIcon,
} from "../../../../../utilities/image-helpers/accessible-images";
import { commonLoginStringsFabric as commonLoginStrings } from "../../../fabric/common-login-strings-fabric";
import { useBenefitsView } from "../hooks/use-benefits-view";
import { benefitsViewStrings as strings } from "./benefits-view-strings-fabric";

/**
 * BenefitsView Component
 * @returns A rendered instance of this component
 */
export const BenefitsViewFabric: React.FC = function BenefitsViewFabric() {
  const { useCommonStyles, useStaticCommonStyles, useBenefitsViewStyles } = StylesConfig.instance;

  const { onClickNextHandler, onClickBackHandler, showBackButton } = useBenefitsView();

  const {
    title,
    description,
    listTitle,
    childAccountDescription,
    accountManagementBenefit,
    safetyBenefit,
    reviewBenefit,
  } = strings;

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();
  const benefitStyles = useBenefitsViewStyles();

  const canGoBack = useBackButtonControl();

  useActivateView(ViewId.Benefits, FlowId.Login, {
    showBackButtonOnActiveView: canGoBack,
    showIdentityBanner: false,
  });

  useDocumentTitle(title);

  const benefitValues = [
    { urls: settingsIcon, description: accountManagementBenefit },
    { urls: timerIcon, description: safetyBenefit },
    { urls: multiplayerJoinIcon, description: reviewBenefit },
  ];

  const benefitList: { [Key: string]: JSX.Element } = {};
  benefitValues.forEach((item, index) => {
    benefitList[`benefit${index}`] = (
      <div className={benefitStyles.childAccountBenefit}>
        <AccessibleImage
          urls={item.urls}
          className={benefitStyles.benefitIcon}
          dataTestId={`benefitIcon${index}`}
        />
        <DescriptionFabric descriptionClass={benefitStyles.benefitDescription}>
          {item.description}
        </DescriptionFabric>
      </div>
    );
  });

  return (
    <ViewContainerFabric
      title={{ title, titleId: "benefitsTitle" }}
      primaryButton={{
        label: commonLoginStrings.nextButton,
        type: "submit",
        onClick: onClickNextHandler,
        hasFocus: true,
      }}
      secondaryButton={
        showBackButton
          ? {
              label: commonLoginStrings.backButton,
              type: "button",
              onClick: onClickBackHandler,
            }
          : undefined
      }
      image={{ urls: peopleShield, dataTestId: "peopleShield", role: "presentation" }}
      description={{ description, descriptionId: "addToFamilyGroup" }}
      applyAriaLive
    >
      <div className={benefitStyles.benefits} id="BenefitsList">
        <TitleFabric title={listTitle} titleId="listTitle" />
        <ListContainer className={benefitStyles.listBenefits} listItems={benefitList} />
        <DescriptionFabric
          descriptionId="childAccountDescription"
          descriptionClass={commonStyles.subText}
        >
          {childAccountDescription}
        </DescriptionFabric>
      </div>
    </ViewContainerFabric>
  );
};
