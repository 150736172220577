import { makeStyles } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type BackgroundStylesFabric, useBackgroundStylesFabric } from "../fabric";
import * as styleConstants from "./constants-win11-oobe-fabric.styles";

const useOverrideBackgroundStyles = makeStyles({
  alwaysShow: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: { display: "inherit" },
  },

  baseBackground: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: { display: "none" },
  },
});

export type BackgroundStylesWin11OobeFabric = BackgroundStylesFabric &
  Partial<ReturnType<typeof useOverrideBackgroundStyles>>;

export const useBackgroundStylesWin11OobeFabric = () =>
  useMergedStyles(
    useBackgroundStylesFabric(),
    useOverrideBackgroundStyles(),
  ) as BackgroundStylesWin11OobeFabric;
