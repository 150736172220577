import { BindProvider } from "../../../constants";
import { type RedirectPostParams } from "../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";

type FederatedRedirectUrls = {
  linkedInFedUrl: string;
  githubFedUrl: string;
  googleFedUrl: string;
  facebookFedUrl: string;
};

export type BindProviderProperties = {
  title: string;
  redirectUrl: string;
  redirectPostParams?: RedirectPostParams;
};

/**
 * Returns the appropriate properties for the view to display based on the redirect provider supplied by the server.
 * @param idpRedirectUrl the url to redirect to
 * @param idpRedirectPostParams the post params for the redirection
 * @param idpRedirectProvider the bind provider
 * @param fedUrls the urls to redirect to for specific federated sign-ins
 * @returns The corresponding properties for the input redirect provider.
 */
export const getBindProviderProperties = (
  idpRedirectUrl: string,
  idpRedirectPostParams: RedirectPostParams,
  idpRedirectProvider: BindProvider,
  fedUrls: FederatedRedirectUrls,
): BindProviderProperties => {
  let title = getLocalString("Login_Redirect_Title");
  let redirectUrl = idpRedirectUrl;
  let redirectPostParams: RedirectPostParams | undefined = idpRedirectPostParams;

  const { linkedInFedUrl, githubFedUrl, googleFedUrl, facebookFedUrl } = fedUrls;

  switch (idpRedirectProvider) {
    case BindProvider.LinkedIn:
      redirectUrl = redirectUrl || linkedInFedUrl;
      redirectPostParams = {};
      title = getLocalString("Login_Redirect_RedirectingToLinkedIn");
      break;

    case BindProvider.GitHub:
      redirectUrl = redirectUrl || githubFedUrl;
      redirectPostParams = {};
      title = getLocalString("Login_Redirect_RedirectingToGitHub");
      break;

    case BindProvider.Google:
      redirectUrl = redirectUrl || googleFedUrl;
      redirectPostParams = {};
      title = getLocalString("Login_Redirect_RedirectingToGoogle");
      break;

    case BindProvider.Facebook:
      redirectUrl = redirectUrl || facebookFedUrl;
      redirectPostParams = {};
      // @AAD-TODO: use local string once available / when we work on AAD Login
      title = "Redirecting you to Facebook to sign in...";
      break;

    default:
      break;
  }

  return { title, redirectUrl, redirectPostParams };
};
