import { useCallback } from "react";
import { ViewId } from "../../constants";
import { useGlobalContext } from "../../global-context";
import { GlobalActionType } from "../../global-reducer";
import { htmlEscape, replaceTokens } from "../../utilities/strings-helper";
import { type AgreementType } from "./agreement-view-interface";
import { buildAccessibleHtml, getExternalLink } from "./agreement-view-util";
import { useFetchAgreementText } from "./use-fetch-agreement-text";

export type UseGetAgreementHtmlParams = {
  agreementType: AgreementType;
  footerA11yConformeUrl: string;
  footerImpressumUrl: string;
  generalVisitLink: string;
  privacyUrl: string;
  termsOfUseUrl: string;
  isExternalLink: boolean;
  setDownloadError: (error: boolean) => void;
  setAgreementHtml: (html: string) => void;
};

export const useGetAgreementHtml = (params: UseGetAgreementHtmlParams): (() => void) => {
  const {
    agreementType,
    footerA11yConformeUrl,
    footerImpressumUrl,
    privacyUrl,
    termsOfUseUrl,
    generalVisitLink,
    isExternalLink,
    setDownloadError,
    setAgreementHtml,
  } = params;

  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();

  const fetchAgreementText = useFetchAgreementText({
    agreementType,
    privacyUrl,
    termsOfUseUrl,
    setDownloadError,
    setAgreementHtml,
  });

  const externalLink = getExternalLink({
    agreementType,
    footerA11yConformeUrl,
    footerImpressumUrl,

    privacyUrl,
    termsOfUseUrl,
  });

  const generalVisitLinkText = isExternalLink
    ? replaceTokens(generalVisitLink, htmlEscape(externalLink))
    : "";

  const getAgreementHtml = useCallback((): void => {
    // If an external link is present for Privacy or Terms of Use, or if the agreement link
    // is for Impressum or A11yConforme/French Decree, then show a string with the agreement link
    if (isExternalLink) {
      const accessibleHtml = buildAccessibleHtml(generalVisitLinkText);

      setAgreementHtml(accessibleHtml);
    } else {
      // Make a request for the agreement text file if the hosted url link is present for Terms of Use or Privacy
      fetchAgreementText();
    }

    dispatchGlobal({
      type: GlobalActionType.DataLoaded,
      view: ViewId.ViewAgreement,
    });
  }, [isExternalLink, dispatchGlobal, generalVisitLinkText, setAgreementHtml, fetchAgreementText]);

  return getAgreementHtml;
};
