import { useCallback } from "react";
import { useAuthContext } from "../../../../authentication-context";
import { AuthenticationActionType } from "../../../../authentication-reducer";
import GlobalConfig from "../../../../global-config";
import { ProofType } from "../../../../model/proof";
import { type IUser } from "../../../../model/user/user-types";
import { getOneTimeCode } from "../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcChannel,
  OtcPurposes,
} from "../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { useLoginContext } from "../../login-context";
import { LoginActionType } from "../../login-reducer";
import { type IPushNotificationsViewStrings } from "../push-notifications-view-interface";

/**
 * This hook is used to setup the OneTimeCode api request with the required callbacks.
 * @param flowTokenValue The flow token value
 * @param sessionIdentifier The session identifier
 * @param user The IUser object
 * @param startPolling The method to start the session polling
 * @param setTitle The method to set the title
 * @param setError The method to set the error
 * @param setIsRequestPendingAndHideBackButton The method to update the isRequestPending state and back arrow button
 * @param viewStrings The remote ngc or push notifications view strings
 * @returns A method that handles the Get OneTimeCode request.
 */
export const useGetOtcForPushNotifications = (
  flowTokenValue: string,
  sessionIdentifier: string,
  user: IUser,
  startPolling: () => void,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsRequestPendingAndHideBackButton: (isPending: boolean) => void,
  viewStrings: IPushNotificationsViewStrings,
) => {
  // Login state
  const { dispatchStateChange: dispatchLoginStateChange } = useLoginContext();
  // Authentication state
  const { dispatchStateChange: dispatchAuthenticationStateChange } = useAuthContext();
  // Global config
  const { getOtcJsonUrl } = GlobalConfig.instance;

  const { requestSendError, requestSendErrorTitle } = viewStrings;

  const updateFlowToken = useCallback(
    (flowToken?: string) => {
      if (flowToken) {
        dispatchAuthenticationStateChange({
          type: AuthenticationActionType.SetFlowTokenValue,
          payload: flowToken,
        });
      }
    },
    [dispatchAuthenticationStateChange],
  );

  const otcOnSuccess = useCallback(
    (res: OtcSuccessParams) => {
      updateFlowToken(res.flowToken);
      dispatchLoginStateChange({
        type: LoginActionType.SetRemoteNgcParams,
        payload: { entropy: res.displaySign ?? "", devices: res.devices ?? [] },
      });
      setIsRequestPendingAndHideBackButton(false);
      startPolling();
      // TO-DO: AAD/ESTS - add request handler for JSON post request success
    },
    [dispatchLoginStateChange, startPolling, setIsRequestPendingAndHideBackButton, updateFlowToken],
  );

  const otcOnFailure = useCallback(
    (res: OtcFailureParams) => {
      updateFlowToken(res.flowToken);
      setIsRequestPendingAndHideBackButton(false);
      setTitle(requestSendErrorTitle);
      setError(requestSendError);
      // TO-DO: AAD/ESTS - add logic for handling JSON post request failure
    },
    [
      setTitle,
      setError,
      setIsRequestPendingAndHideBackButton,
      updateFlowToken,
      requestSendError,
      requestSendErrorTitle,
    ],
  );

  const triggerOtcRequest = useCallback(() => {
    dispatchLoginStateChange({
      type: LoginActionType.SetRemoteNgcParams,
      payload: { entropy: "", devices: [] },
    });
    setIsRequestPendingAndHideBackButton(true);
    // For MSA scenario, we use the sessionIdentifier as the flow token value.
    const flowTokenParam = getOtcJsonUrl ? flowTokenValue : sessionIdentifier;
    getOneTimeCode({
      onSuccess: otcOnSuccess,
      onFailure: otcOnFailure,
      canaryFlowToken: flowTokenValue,
      channel: OtcChannel.authenticator,
      flowToken: flowTokenParam,
      proofType: ProofType.TOTPAuthenticatorV2,
      purpose: OtcPurposes.remoteNGC,
      username: user.username,
    });
  }, [
    dispatchLoginStateChange,
    flowTokenValue,
    getOtcJsonUrl,
    otcOnFailure,
    otcOnSuccess,
    sessionIdentifier,
    user.username,
    setIsRequestPendingAndHideBackButton,
  ]);

  return triggerOtcRequest;
};
