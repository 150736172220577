import { type IProofConfirmationStrings } from "../proof-confirmation-interface";

/**
 * @returns Proof Confirmation view strings for Fabric
 */
export const proofConfirmationStringsFabric: IProofConfirmationStrings = {
  alreadyHaveCode: getLocalString("AlreadyHaveCode"),
  sendCode: getLocalString("TFA_SendCodeButtonText"),
  inputAriaLabel: getLocalString("EmailTextbox_Placeholder"),
  verifyEmailTitle: getLocalString("General_VerifyEmail_Title"),
  verifyPhoneTitle: getLocalString("General_VerifyPhone_Title"),
  verifyEmailDescription: getLocalString("General_VerifyEmail_Description"),
  verifyPhoneDescription: getLocalString("General_VerifyPhone_Description"),
  exampleEmailPlaceholder: getLocalString("ExampleEmail"),
  examplePhonePlaceholder: getLocalString("Sms_Proof_Input_Placeholder"),
  emailInputMismatch: getLocalString("Email_Proof_Input_MismatchError"),
  phoneInputMismatch: getLocalString("Sms_Proof_Input_MismatchError"),
  sessionExpiredError: getLocalString("OneTimeCode_SessionExpiredError"),
  sendOtcFailed: getLocalString("OneTimeCode_SendFailedError"),
};
