import { makeStyles } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

export const useBenefitsViewStylesFabric = makeStyles({
  childAccountBenefit: {
    display: "flex",
    alignItems: "flex-start",
  },

  benefitDescription: {
    paddingLeft: "12px",
  },

  benefitIcon: {
    marginTop: `${styleConstants.FORM_GROUP_MARGIN}px`,
  },

  listBenefits: {
    marginTop: "0px",
    "& li": {
      marginTop: "0px",
      marginBottom: "0px",
    },
  },

  benefits: {
    "& #listTitle": {
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
    },
  },
});

export type BenefitsViewStylesFabric = ReturnType<typeof useBenefitsViewStylesFabric>;
