import { type IPhoneDisambiguationViewStrings } from "../phone-disambiguation-view-interface";

export interface IPhoneDisambiguationViewStringsFabric extends IPhoneDisambiguationViewStrings {
  alternateDescription: string;
  signInWithDifferentUser: string;
  backLabel: string;
  nextLabel: string;
}

export const phoneDisambiguationViewStringsFabric: IPhoneDisambiguationViewStringsFabric = {
  title: getLocalString("Login_PhoneDisambiguation_PageTitle"),
  description: getLocalString("Login_Description_VerifyCountryCodeAndPhone"),
  alternateDescription: getLocalString("Login_Error_PhoneUser_NeedHelpToLookUpAccount"),
  placeholder: getLocalString("General_PhoneNumber"),
  inputAriaLabel: getLocalString("Enter_PhoneNumber"),
  badCharactersErrorMessage: getLocalString("Login_PhoneNumber_ForbiddenCharacters_Error"),
  signInWithDifferentUser: getLocalString("PhoneUser_SigninWithDifferentUsername"),
  createNewAccountLabel: getLocalString("PhoneUser_CreateNewAccount"),
  backLabel: getLocalString("General_Back"),
  nextLabel: getLocalString("General_Buttons_Next"),
};
