import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";

/**
 * SecondaryContentContainer component
 * This component is used to apply specific font size and layout styles to secondary content elements on a view. Secondary content refers to links and text that
 * are typically displayed below input fields on the lightbox.
 * @param props The props for this component
 * @param props.children The child elements to render inside this component
 * @returns an instance of the secondary content container component
 */
export const SecondaryContentContainerFabric: React.FC = function SecondaryContentContainerFabric({
  children,
}) {
  const { useSecondaryContentStyles } = StylesConfig.instance;
  const styles = useSecondaryContentStyles();
  const mergedClasses = mergeClasses(styles.smallFontSize, styles.containerLayout);

  return (
    <div className={mergedClasses} data-testid="secondaryContent">
      {children}
    </div>
  );
};
