import { isIENewerThan10 } from "../../../../utilities/browser-helper";
import { replaceTokens } from "../../../../utilities/strings-helper";
import { PhoneNumberConfirmationLength, UsernameMaxLength } from "../../login-constants";
import { type IProofConfirmationStrings } from "./proof-confirmation-interface";

export interface ITitleDescStrings {
  title: string;
  description: string;
}

export interface ITextInputAttributes {
  maxLength: number;
  placeholder: string;
  type?: string;
}

/**
 * Given a flag indicating whether the proof is an email, the displayable proof string and the clear characters
 * in the proof string, returns the appropriate title and description strings specific to these inputs.
 * @param isEmailProof whether the proof is an email
 * @param display the proof string with the unknown characters obfuscated with a "*". For example, for emails this is "te******@live.com"
 * @param clearDigits the characters of the proof string that are clear and not obfuscated with a "*"
 * @param strings strings for the Proof Confirmation View
 * @returns the appropriate title and description strings specific to the inputs
 */
export const getTitleAndDescription = (
  isEmailProof: boolean,
  display: string,
  clearDigits: string,
  strings: IProofConfirmationStrings,
): ITitleDescStrings => {
  const title = isEmailProof ? strings.verifyEmailTitle : strings.verifyPhoneTitle;

  // Note that only the description for the phone / SMS proof contains a placeholder for clear digits
  const descriptionWithPlaceholders = isEmailProof
    ? strings.verifyEmailDescription
    : strings.verifyPhoneDescription;
  const description = replaceTokens(descriptionWithPlaceholders, display, clearDigits);

  return { title, description };
};

/**
 * Given a flag indicating whether the proof is an email, returns the appropriate maximum allowable length, placeholder
 * text and type for the input textbox element
 * @param isEmailProof whether the proof is an email
 * @param strings strings for the Proof Confirmation View
 * @returns the appropriate maximum allowable length, placeholder text and type for the input textbox element
 */
export const getTextInputAttributes = (
  isEmailProof: boolean,
  strings: IProofConfirmationStrings,
): ITextInputAttributes => {
  const maxLength = isEmailProof ? UsernameMaxLength : PhoneNumberConfirmationLength;

  // Note that only the placeholder text for the phone/SMS proof contains a placeholder for the maximum input length
  const placeholderWithoutDigits = isEmailProof
    ? strings.exampleEmailPlaceholder
    : strings.examplePhonePlaceholder;
  const placeholder = replaceTokens(placeholderWithoutDigits, maxLength.toString());

  const type = isEmailProof ? "email" : "tel";

  // specify the input type only if changing it is supported
  return { maxLength, placeholder, ...(isIENewerThan10() ? { type } : {}) };
};

/**
 * Given a flag indicating whether the proof is an email and the clear characters in the proof string,
 * returns the invalid proof error message specific to these inputs.
 * @param isEmailProof whether the proof is an email
 * @param clearDigits the characters of the proof string that are clear and not obfuscated with a "*"
 * @param strings strings for the Proof Confirmation View
 * @returns the invalid proof error message specific to the proof type and the clear characters from the proof
 */
export const getInvalidProofError = (
  isEmailProof: boolean,
  clearDigits: string,
  strings: IProofConfirmationStrings,
): string => {
  const errorWithoutDigits = isEmailProof ? strings.emailInputMismatch : strings.phoneInputMismatch;
  return replaceTokens(errorWithoutDigits, clearDigits);
};
