import GlobalConfig from "../../../../../global-config";
import { useBackButtonControl } from "../../../../../hooks/use-navigate-direction";

/**
 * @returns flag indicating whether the back (arrow) button should be shown.
 */
export const useShowBackArrowButton = (): boolean => {
  // Global config data
  const { showButtons } = GlobalConfig.instance;

  const canGoBack = useBackButtonControl();
  const showBackButton = showButtons && canGoBack;

  return showBackButton;
};
