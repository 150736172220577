import type { IInlineLegalMessagingStrings } from "../inline-legal-messaging-interface";

export const inlineLegalMessagingStringsFabric: IInlineLegalMessagingStrings = {
  touPrivacyAgreementWithoutConsent: getLocalString("TOU_Privacy_Agreement_AgreeOnNext_AXIS"),
  touPrivacyAgreementWithConsent: getLocalString("TOU_Privacy_Agreement_Services_AgreeOnNext_AXIS"),
  touServicesConsentWithoutOneDrive: getLocalString("TOU_Services_Information_AgreeOnNext_AXIS"),
  touServicesConsentWithOneDrive: getLocalString("TOU_Services_Agreement_AgreeOnNext_AXIS"),
  touUnifiedConsentWithoutOneDrive: getLocalString(
    "TOU_Unified_Consent_Non_OneDrive_AgreeOnNext_AXIS",
  ),
  touUnifiedConsentWithOneDrive: getLocalString("TOU_Unified_Consent_Agreement_AgreeOnNext_AXIS"),
};
