import { useContext } from "react";
import GlobalConfig from "../global-config";
import { GlobalContext } from "../global-context";
import { ServiceDiagEventNames } from "../telemetry-helpers/service-diag-event-names";
import { useTelemetry } from "../telemetry-helpers/use-telemetry";
import { trimQsParams } from "../utilities/strings-helper";

export const redirectWithHistory = (redirectUrl: string) => {
  window.location.assign(redirectUrl);
};

export const redirectWithoutHistory = (redirectUrl: string) => {
  window.location.replace(redirectUrl);
};

export const redirect = (redirectUrl: string, keepHistory: boolean = true) =>
  keepHistory ? redirectWithHistory(redirectUrl) : redirectWithoutHistory(redirectUrl);

/**
 * Hook that returns a method for navigating to a return URL using a GET method.
 * Logs the base redirect URL
 * @returns a method for redirecting to an external URL.
 */
export const useRedirect = () => {
  const { activeFlavor, telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow },
  } = useContext(GlobalContext);

  const { logServiceDiagEvent } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  return (redirectUrl: string, keepHistory = true) => {
    logServiceDiagEvent({
      metricName: ServiceDiagEventNames.Redirect,
      metricValue: trimQsParams(redirectUrl),
    });
    return redirect(redirectUrl, keepHistory);
  };
};
