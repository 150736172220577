import { type AuthenticationState } from "./authentication-context";

/**
 * Action types that are accepted by the authentication reducer
 */
export enum AuthenticationActionType {
  SetFlowTokenValue,
  SetSessionLookupKey,
}

type SetFlowTokenValueAction = {
  type: AuthenticationActionType.SetFlowTokenValue;
  payload: string;
};

type SetSessionLookupKeyAction = {
  type: AuthenticationActionType.SetSessionLookupKey;
  payload: string;
};

/**
 * Union of all actions that can be dispatched to the authentication reducer to update authentication state
 */
export type AuthenticationActions = SetFlowTokenValueAction | SetSessionLookupKeyAction;

export const flowTokenUpdated = (payload: string) => ({
  type: AuthenticationActionType.SetFlowTokenValue,
  payload,
});

export const sessionLookupKeyUpdated = (payload: string) => ({
  type: AuthenticationActionType.SetSessionLookupKey,
  payload,
});

/**
 * Authentication state reducer
 * @param state The current state
 * @param action The action to perform on the current state
 * @returns The new state
 */
export default function authenticationReducer(
  state: AuthenticationState,
  action: AuthenticationActions,
): AuthenticationState {
  const { type } = action;
  switch (type) {
    case AuthenticationActionType.SetFlowTokenValue:
      return { ...state, flowTokenValue: action.payload };
    case AuthenticationActionType.SetSessionLookupKey:
      return { ...state, sessionLookupKey: action.payload };

    default:
      throw new Error(`AuthenticationReducer received unexpected action ${type}`);
  }
}
