import { makeStyles, shorthands } from "@griffel/react";

export const usePushNotificationsViewStylesFabric = makeStyles({
  listItems: {
    listStyleType: "disc",
    marginLeft: "1em",
    paddingLeft: "1em",
  },

  authenticatorImage: {
    width: "352px",
    height: "176px",
  },

  descriptionPadding: {
    ...shorthands.padding("7px"),
  },

  pullLeft: {
    float: "left",
  },
});

export type PushNotificationsViewStylesFabric = ReturnType<
  typeof usePushNotificationsViewStylesFabric
>;
