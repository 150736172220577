import { UserFlowType } from "../../../../../constants";
import { useCustomizationContext } from "../../../../../context/customization-context";
import FeaturesConfig from "../../../../../features-config";
import GlobalConfig from "../../../../../global-config";
import { useGlobalContext } from "../../../../../global-context";
import { htmlUnescape } from "../../../../../utilities/strings-helper";
import { useBrandingDescriptionProperties, useFidoProperties } from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import { type CommonLoginStrings } from "../../../login-interface";
import { getCommonDocumentTitle, getCommonLightboxTitle } from "../../../login-util";
import { type IUsernameViewProperties } from "../username-view-interface";
import { type IUsernameViewStringsFabric } from "./username-view-strings-fabric";

/**
 * @returns username view properties
 * @param strings Flavored strings that are used by this hook
 * @param strings.usernameViewStrings Strings that are specific to the username view
 * @param strings.commonLoginStrings Strings that are common to all login views
 */
export const useUsernameViewPropertiesFabric = ({
  usernameViewStrings,
  commonLoginStrings,
}: {
  usernameViewStrings: IUsernameViewStringsFabric;
  commonLoginStrings: CommonLoginStrings;
}): IUsernameViewProperties => {
  // Global context data
  const {
    globalState: { user, userFlowType },
  } = useGlobalContext();

  // Customization context data
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  // AAD-TODO: CustomStrings
  const {
    loginHelpFidoAriaLabel,
    loginHelpHelloOrFidoAriaLabel,
    loginSignupLink,
    placeholder,
    signupLinkAriaLabel,
  } = usernameViewStrings;

  // config data
  const { checkProofForAliases } = GlobalConfig.instance;
  const {
    appBrandedSignInHeader,
    defaultSignInHeader,
    doIfExists,
    hideLoginDescription,
    loginMode,
    loginStringsVariant,
    showRemoteConnectLocationPage,
    showSignup,
    isPasskeySupported,
  } = LoginConfig.instance;
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  const title = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);

  const headerId = "usernameTitle";
  const header = getCommonLightboxTitle(
    loginStringsVariant,
    defaultSignInHeader,
    appBrandedSignInHeader,
  );

  // In the username view, we do not pay attention to `serverData.oAppCobranding.showDescOnCredViews` when
  // deciding whether to render the branding description. So we can ignore the `renderBrandingDescription`
  // flag returned by this hook
  const { brandingDescriptionId: descriptionId, brandingDescription: description } =
    useBrandingDescriptionProperties(commonLoginStrings);
  // If we render the remote connect location view, the branding description is shown there instead of here
  // in the username view
  const unsafeDescription = htmlUnescape(description);
  const renderDescription =
    !!unsafeDescription && !showRemoteConnectLocationPage && !hideLoginDescription;

  const ariaDescribedBy = `${headerId}${renderDescription ? ` ${descriptionId}` : ""}`;

  const { fidoLinkText, fidoDialogHeader, fidoDialogDescription } =
    useFidoProperties(commonLoginStrings);

  // sign up link related properties
  const signupLinkText = showSignup && !doIfExists && !checkProofForAliases ? loginSignupLink : "";

  const { parentSignInPageTitle, parentSignInPageDescription, parentSignInPagePlaceholder } =
    commonLoginStrings;
  const isParentSignIn = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;
  const parentSignInHeaderId = "usernameParentSignInTitle";
  const parentHeader = parentSignInPageTitle;

  const showCreateAccountDropdown =
    !!signupLinkText && isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Unknown;

  return {
    title,
    header: isParentSignIn ? parentHeader : header,
    headerId: isParentSignIn ? parentSignInHeaderId : headerId,
    descriptionProperties: isParentSignIn
      ? {
          renderDescription: isParentSignIn,
          descriptionId: "parentSignInPageDescription",
          description: parentSignInPageDescription,
        }
      : {
          renderDescription,
          descriptionId,
          description: unsafeDescription,
        },
    ariaDescribedBy,
    fidoDialogDescription,
    fidoDialogHeader,
    fidoLinkText,
    placeholder: isParentSignIn ? parentSignInPagePlaceholder : placeholder,
    signupLinkAriaLabel,
    signupLinkText,
    username: user?.username?.safeHtmlEscapedString,
    loginHelpFidoAriaLabel,
    loginHelpHelloOrFidoAriaLabel,
    isPasskeySupported,
    showCreateAccountDropdown,
    isParentSignIn,
  };
};
