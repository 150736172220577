import React, { type ReactNode } from "react";
import { EnvironmentName } from "../../constants/environmentbanner-constants";
import GlobalConfig from "../../global-config";
import { FormattedTextWithBindings } from "../../utilities/formatted-text-with-bindings";

export type EnvironmentBannerProps = {
  styles: {
    envBanner: string;
    envBannerInner: string;
    envBannerDescription: string;
  };
};

/**
 * EnvironmentBanner component
 * @param props EnvironmentBannerProps
 * @returns Returns a div container the a different banner based on environment
 */
const EnvironmentBanner: React.FC<EnvironmentBannerProps> = function EnvironmentBanner(props) {
  const { bannerEnvironment, bannerEnvironmentUrl } = GlobalConfig.instance;
  const { styles } = props;

  const environmentBannerDescription = (chunks: ReactNode) => (
    <div className={styles.envBannerDescription}>{`${chunks}`}</div>
  );
  const environmentBannerLink = (chunks: ReactNode) => <a href={bannerEnvironmentUrl}>{chunks}</a>;

  let string;
  switch (bannerEnvironment) {
    case EnvironmentName.Internal:
      string = getLocalString("Login_Environment_Banner_Internal");
      break;
    case EnvironmentName.TestSlice:
      string = getLocalString("Login_Environment_Banner_Testslice");
      break;
    case EnvironmentName.FirstSlice:
      string = getLocalString("Login_Environment_Banner_Firstslice");
      break;
    default:
      string = "";
      break;
  }

  return (
    <div className={styles.envBanner} data-testid="environmentBanner" role="banner">
      <div className={styles.envBannerInner}>
        <FormattedTextWithBindings
          text={string}
          embeddedBindings={{
            environmentBannerDescription,
            environmentBannerLink,
          }}
        />
      </div>
    </div>
  );
};

export default EnvironmentBanner;
