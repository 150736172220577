import { ConfigWrapper } from "../../config-wrapper";
import { gctStringsFabric } from "./fabric/gct-strings-fabric";

// This set of gct error strings is flavor-agnostic, but does use the gct fabric strings as a base.
export const defaultGctStrings = {
  ...gctStringsFabric,
};

export type GctStrings = typeof defaultGctStrings;

// eslint-disable-next-line deprecation/deprecation
export const gctStringsConfig = new ConfigWrapper({
  gctStrings: defaultGctStrings,
});
