import React from "react";
import { SecondaryContentContainerFabric } from "../../../../../components";
import { DescriptionFabric } from "../../../../../components/description/fabric/description-fabric";
import { InputFabric as Input } from "../../../../../components/inputs/input/fabric/input-fabric";
import { InputContainer } from "../../../../../components/inputs/input-container";
import { AtLeastOneCharacterRegex } from "../../../../../components/inputs/input-regex-constants";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../../components/title/fabric/title-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants/routing-constants";
import { useActivateView } from "../../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../../hooks/use-document-title";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { simpleInputValidation } from "../../../../../utilities/input-helper";
import { CredentialSwitchContentFabric } from "../../../components/fabric/credential-switch-content-fabric";
import {
  commonLoginStringsFabric,
  loginInputValidationErrorStringsFabric,
} from "../../../fabric/common-login-strings-fabric";
import { useShowCredentialSwitchContent } from "../../../hooks/login-hooks";
import { useInputWithServerDataErrorsFabric } from "../../../hooks/use-input-with-server-data-errors-fabric";
import { LoginFlowPostHiddenInputs } from "../../../login-flow-post-hidden-inputs";
import { useCxhHostLoginAction } from "../hooks/use-cxh-host-login-action";
import { usePasswordHandler } from "../hooks/use-password-handler";
import { usePasswordLogEvents } from "../hooks/use-password-log-events";
import { usePasswordParentSigninHandler } from "../hooks/use-password-parent-signin-handler";
import { useShowBackArrowButton } from "../hooks/use-password-show-back-arrow-button";
import { passwordViewStringsFabric } from "./password-view-strings-fabric";
import { usePasswordViewPropertiesFabric } from "./use-password-view-properties-fabric";

/**
 * PasswordView component
 * @flavor Fabric
 * @returns A rendered instance of this component
 */
export const PasswordViewFabric: React.FC = function PasswordViewFabric() {
  const { useCommonStyles } = StylesConfig.instance;
  const {
    title,
    formRef,
    header,
    headerId,
    descriptionProperties: { renderDescription, descriptionId, description },
    ariaDescribedBy,
    ariaLabel,
    placeholder,
    loginPostProps,
    noPasswordErrorMessage,
    primaryButtonLabel,
    postUrl,
    resetPasswordUrl,
    forgotPasswordLinkText,
    credentialSwitchLinksProps,
    allowPhoneDisambiguation,
    navigateToPhoneDisambig,
    showChangeUserLink,
    selectAccount,
    isRequestPending,
    isParentSignIn,
  } = usePasswordViewPropertiesFabric({
    commonLoginStrings: commonLoginStringsFabric,
    passwordViewStrings: passwordViewStringsFabric,
  });
  const commonStyles = useCommonStyles();
  const { showSwitchToEvictedCredPicker, showCredentialSwitchContent } =
    useShowCredentialSwitchContent();

  useActivateView(ViewId.Password, FlowId.Login);
  useDocumentTitle(title);

  const validationMethod = simpleInputValidation(
    noPasswordErrorMessage,
    AtLeastOneCharacterRegex,
    noPasswordErrorMessage,
  );
  const inputState = useInputWithServerDataErrorsFabric({
    validationMethod,
    hasInitialFocus: true,
    loginInputValidationErrorStrings: loginInputValidationErrorStringsFabric,
  });
  const {
    hasFocus,
    value,
    onBlur,
    onFocus,
    onChange,
    error: { showErrorMessage, errorMessage },
  } = inputState;

  const hostLoginAction = useCxhHostLoginAction(String(value), formRef);
  const onSubmitBase = usePasswordHandler(postUrl, inputState, { hostLoginAction });
  const onSubmitParentSignIn = usePasswordParentSigninHandler(postUrl, inputState, hostLoginAction);
  const onSubmit = isParentSignIn ? onSubmitParentSignIn : onSubmitBase;

  const onResetPasswordClick = useRedirect();

  /** This is an OOBE-specific function for logging CXH events */
  usePasswordLogEvents();

  useShowBackArrowButton();

  return (
    <form
      ref={formRef}
      name="f1"
      id="i0281"
      data-testid="passwordForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      onSubmit={onSubmit}
      action={postUrl}
    >
      <TitleFabric titleId={headerId} title={header} />
      {renderDescription && (
        <DescriptionFabric
          descriptionId={descriptionId}
          descriptionClass={commonStyles.wrapContent}
        >
          {description}
        </DescriptionFabric>
      )}

      <LoginFlowPostHiddenInputs
        canary={loginPostProps.canary}
        cleansedUsername={loginPostProps.cleansedUsername}
        context={loginPostProps.context}
        flowTokenName={loginPostProps.flowTokenName}
        flowTokenValue={loginPostProps.flowTokenValue}
        foundMsas={loginPostProps.foundMsas}
        isFidoSupported={loginPostProps.isFidoSupported}
        isKmsiChecked={loginPostProps.isKmsiChecked}
        loginOption={loginPostProps.loginOption}
        paginatedState={loginPostProps.paginatedState}
        postType={loginPostProps.postType}
        postedForceSignIn={loginPostProps.postedForceSignIn}
        randomBlob={loginPostProps.randomBlob}
        showCookieBanner={loginPostProps.showCookieBanner}
        displayUsername={loginPostProps.displayUsername}
        rngcDefaultType={loginPostProps.rngcDefaultType}
        rngcEntropy={loginPostProps.rngcEntropy}
        rngcSessionIdentifier={loginPostProps.rngcSessionIdentifier}
      />

      <Input
        id="i0118"
        name="passwd"
        placeholder={placeholder}
        type="password"
        showErrorMessage={showErrorMessage}
        errorMessage={errorMessage}
        value={value}
        onChange={onChange}
        aria-describedby={ariaDescribedBy}
        aria-label={ariaLabel}
        hasFocus={hasFocus}
        hasInitialFocus
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="current-password"
      />

      <div className={commonStyles.row}>
        <SecondaryContentContainerFabric>
          {/* MSA-Windows-TODO / AAD-TODO: switchToOfflineAccount link */}

          {forgotPasswordLinkText && (
            <div className={commonStyles.formGroup}>
              <LinkButton
                text={forgotPasswordLinkText}
                linkId="idA_PWD_ForgotPassword"
                onClick={() => onResetPasswordClick(resetPasswordUrl, true)}
              />
            </div>
          )}

          {allowPhoneDisambiguation && (
            <div className={commonStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.phoneDisambiguationLinkText}
                onClick={navigateToPhoneDisambig}
              />
            </div>
          )}

          {showCredentialSwitchContent && (
            <CredentialSwitchContentFabric
              credentialSwitchProps={credentialSwitchLinksProps}
              showSwitchToEvictedCredPicker={showSwitchToEvictedCredPicker}
            />
          )}

          {showChangeUserLink && (
            <div className={commonStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.signInDifferentAccount}
                onClick={selectAccount}
              />
            </div>
          )}
        </SecondaryContentContainerFabric>
      </div>

      <InputContainer>
        <div className={commonStyles.winButtonPinBottom}>
          <div className={commonStyles.row}>
            <TextButtonContainer>
              <TextButtonFabric
                buttonId="idSIButton9"
                label={primaryButtonLabel}
                disabled={isRequestPending}
                isPrimary
                type="submit"
              />
            </TextButtonContainer>
          </div>
        </div>
      </InputContainer>
    </form>
  );
};
