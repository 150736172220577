import React, { useCallback, useEffect, useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../../config/styles-config";
import { FOCUS_TIMEOUT } from "../../../../styles/fabric";
import { type ICountryInfo } from "../../../../utilities/country-helper";
import { downArrowIcon } from "../../../../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "../../../accessible-image";
import { type PhoneNumberDropdownProps } from "../phone-number-types";
import { createCountryMap } from "../phone-utils";

/**
 * @deprecated
 * PhoneCountryDropdown component for Fabric
 * @param props The properties for this component.
 * @param props.defaultCountryData The default country information.
 * @param props.countryData The array of country information.
 * @param props.useInlinePhoneNumber Whether the country code and phone number are shown inline. If true, the component will show the label text (e.g., +1).
 * If false, the component will show the full country display text (e.g., United States (+1)).
 * @param props.hasError Whether the country code dropdown is showing error.
 * @param props.hasInitialFocus Whether the country code dropdown is focused by default.
 * @param props.hasFocus Whether the country code dropdown is currently focused.
 * @param props.onChange The value change callback. Enables the external component to do something with the selected country value.
 * @returns A dropdown component with a list of phone country codes.
 */
export const PhoneCountryDropdownFabricDeprecated: React.FC<PhoneNumberDropdownProps> =
  function PhoneCountryDropdownFabricDeprecated(props) {
    const {
      value,
      countryData,
      useInlinePhoneNumber,
      displayErrorStyling = false,
      hasFocus = false,
      hasInitialFocus = false,
      onChange = () => {},
    } = props;

    const { useCommonStyles, usePhoneCountryDropdownStylesDeprecated } = StylesConfig.instance;
    const commonStyles = useCommonStyles();
    const phoneCountryDropdownStyles = usePhoneCountryDropdownStylesDeprecated();

    const dropdownRef = useRef<HTMLSelectElement>(null);

    const countryMap = createCountryMap(countryData);

    const [labelCss, setLabelCss] = useState(phoneCountryDropdownStyles.phoneCountryCodeLabel);

    const onDropdownValueChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      handleDropdownValueChange: (countryValue: ICountryInfo) => void,
    ) => {
      const currentCountry = countryMap[e.target.value];
      // Call the callback passed in as props by consuming component
      handleDropdownValueChange(currentCountry);
    };

    const focusCss = displayErrorStyling
      ? mergeClasses(
          phoneCountryDropdownStyles.phoneCountryCodeLabel,
          phoneCountryDropdownStyles.labelHasErrorFocusBox,
        )
      : mergeClasses(
          phoneCountryDropdownStyles.phoneCountryCodeLabel,
          phoneCountryDropdownStyles.labelHasFocus,
        );

    const errorCss = mergeClasses(
      phoneCountryDropdownStyles.phoneCountryCodeLabel,
      phoneCountryDropdownStyles.labelHasError,
    );

    const getLabelCss = useCallback(() => {
      if (hasFocus) {
        return focusCss;
      }

      if (displayErrorStyling) {
        return errorCss;
      }

      return phoneCountryDropdownStyles.phoneCountryCodeLabel;
    }, [
      focusCss,
      displayErrorStyling,
      hasFocus,
      errorCss,
      phoneCountryDropdownStyles.phoneCountryCodeLabel,
    ]);

    // When useInlinePhoneNumber is true, the component displays the label text (e.g., +1) and hides the
    // select/dropdown display text (e.g., United States (+1)). The mouse focus and blur handler are used to apply the
    // CSS focus styles to the label element for dropdown mouse click events.
    const onFocusHandler = () => {
      setLabelCss(focusCss);
    };

    const onBlurHandler = () => {
      setLabelCss(
        mergeClasses(
          phoneCountryDropdownStyles.phoneCountryCodeLabel,
          displayErrorStyling ? errorCss : "",
        ),
      );
    };

    useEffect(() => {
      if (hasFocus) {
        dropdownRef?.current?.focus();
      }
    }, [hasFocus]);

    useEffect(() => {
      if (hasInitialFocus) {
        setTimeout(() => {
          dropdownRef?.current?.focus();
        }, FOCUS_TIMEOUT);
      }

      setLabelCss(getLabelCss());
    }, [hasInitialFocus, getLabelCss]);

    const renderDropdown = () => (
      <select
        id="phoneCountry"
        name="PhoneCountry"
        aria-label={getLocalString("General_CountryCode")}
        value={value.iso}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onDropdownValueChange(e, onChange)}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className={
          displayErrorStyling
            ? mergeClasses(commonStyles.formControl, "has-error")
            : commonStyles.formControl
        }
        ref={dropdownRef}
        data-testid="phoneCountryDropdown"
      >
        {countryData.map((country) => (
          <option key={country.iso} value={country.iso}>
            {country.displayValue}
          </option>
        ))}
      </select>
    );

    return useInlinePhoneNumber ? (
      <div className={mergeClasses(phoneCountryDropdownStyles.phoneCountryBoxInline)}>
        <label
          htmlFor="phoneCountry"
          aria-hidden="true"
          className={labelCss}
          data-testid="phoneCountryLabel"
        >
          {`\u200E+${value.code}`}
        </label>
        <AccessibleImage
          urls={downArrowIcon}
          role="presentation"
          className={phoneCountryDropdownStyles.downArrow}
        />
        {renderDropdown()}
      </div>
    ) : (
      <div
        className={mergeClasses(
          commonStyles.formGroup,
          phoneCountryDropdownStyles.phoneCountryBoxNotInline,
        )}
      >
        {renderDropdown()}
      </div>
    );
  };
