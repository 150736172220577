import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import GlobalConfig from "../../../global-config";
import { isCssAnimationSupported, isHighContrast } from "../../../utilities/browser-helper";
import { marchingAntsGif } from "../../../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "../../accessible-image";

/**
 * ProgressIndicator component
 * This component is mainly shown
 * 1. on the upper edge of the lightbox when the user is switching views OR
 * 2. inside the lightbox itself when a request is pending.
 * @returns An instance of the ProgressIndicatorFabric component
 */
const ProgressIndicatorFabric: React.FC = function ProgressIndicatorFabric() {
  const { useProgressIndicatorStyles } = StylesConfig.instance;
  const progressIndicatorStyles = useProgressIndicatorStyles();

  const { supportWindowsStyles } = GlobalConfig.instance;

  const useCssAnimation = isCssAnimationSupported() && !isHighContrast();

  const progressBarClassName = supportWindowsStyles
    ? progressIndicatorStyles.slidingLineAnimation
    : progressIndicatorStyles.marchingAntsAnimation;

  return (
    <div
      className={progressBarClassName}
      role="progressbar"
      aria-label={getLocalString("General_PleaseWait")}
    >
      {supportWindowsStyles && (
        <>
          <div
            className={mergeClasses(progressIndicatorStyles.subline, progressIndicatorStyles.inc)}
          />
          <div
            className={mergeClasses(progressIndicatorStyles.subline, progressIndicatorStyles.dec)}
          />
        </>
      )}

      {!supportWindowsStyles &&
        (useCssAnimation ? (
          <>
            <div />
            <div />
            <div />
            <div />
            <div />
          </>
        ) : (
          <AccessibleImage urls={marchingAntsGif} role="presentation" />
        ))}
    </div>
  );
};

export default ProgressIndicatorFabric;

/**
 * ProgressIndicatorContainerFabric component
 * This is mainly used to wrap the progress indicator component when rendering the indicator inside
 * (NOT on the upper edge of) the lightbox.
 * @param props The properties for the container component
 * @param props.children The child elements to render inside the container
 * @returns an instance of the ProgressIndicatorContainerFabric component
 */
export const ProgressIndicatorContainerFabric: React.FC =
  function ProgressIndicatorContainerFabric({ children }) {
    const { useCommonStyles, useProgressIndicatorStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();
    const progressIndicatorStyles = useProgressIndicatorStyles();
    return (
      <div
        className={mergeClasses(
          commonStyles.row,
          progressIndicatorStyles.progressIndicatorContainer,
        )}
        data-testid="progressIndicatorContainer"
      >
        {children}
      </div>
    );
  };
