import { type IBenefitsViewStrings } from "../benefits-view-interface";

export const benefitsViewStrings: IBenefitsViewStrings = {
  title: getLocalString("SetUpChildAccount"),
  description: getLocalString("SetUpChildAccount_Benefits"),
  listTitle: getLocalString("FamilyGroup_Benefits_Header"),
  childAccountDescription: getLocalString("ChildAccount_Description"),
  accountManagementBenefit: getLocalString("FamilyGroup_AccountManagement_Benefit"),
  safetyBenefit: getLocalString("FamilyGroup_DigitalSafety_Benefit"),
  reviewBenefit: getLocalString("FamilyGroup_ReviewFriends_Benefit"),
};
