import { useCallback } from "react";
import { type IFormSubmissionProps } from "../../../../../hooks/use-text-input-form-deprecated";
import { type OneTimeCodeCredential } from "../../../../../model/credential/credential-types";
import { getOneTimeCode } from "../../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import { type IProofConfirmationStrings } from "../proof-confirmation-interface";
import { useGetOneTimeCodeParams } from "./use-get-one-time-code-params";

/**
 * @param otcCredential the current one time code credential
 * @param isRequestPending whether a request is currently pending
 * @param setIsRequestPending dispatch function used to set the `isRequestPending` boolean
 * @param strings strings for the Proof Confirmation View
 * @returns an async callback that makes the request to send the one time code
 */
export const useSendOtcForProofConfirmation = (
  otcCredential: OneTimeCodeCredential,
  isRequestPending: boolean,
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>,
  strings: IProofConfirmationStrings,
) => {
  const getOtcParams = useGetOneTimeCodeParams(otcCredential, setIsRequestPending, strings);

  return useCallback(
    async (formSubmissionProps: IFormSubmissionProps) => {
      const { value, errorHandler } = formSubmissionProps;
      const proofConfirmation = String(value);

      if (!isRequestPending) {
        setIsRequestPending(true);

        const otcParams = getOtcParams(proofConfirmation, errorHandler);

        await getOneTimeCode(otcParams);
      }
    },
    [getOtcParams, isRequestPending, setIsRequestPending],
  );
};
