import FooterConfig from "../../components/footer/footer-config";
import { QueryString } from "../../constants";
import GlobalConfig from "../../global-config";
import { addQueryStrings } from "../../utilities/strings-helper";
import { AgreementType } from "./agreement-view-interface";

/**
 * This method is used to generate the query.aspx url for the given command
 * This is used to load AgreementView text from AccountUX
 * @param command The command to be used in the query.aspx url (AgreementType)
 * @returns The query.aspx url with the given command
 */
export const getQueryUrl = function getQueryUrl(command: AgreementType): string {
  const { complianceQueryUrl } = GlobalConfig.instance;
  const queryStringParams = new Map<string, string>();
  queryStringParams.set(QueryString.command, command);
  return addQueryStrings(complianceQueryUrl, queryStringParams);
};

/**
 * @param type AgreementType - the enum value of Agreement that has been selected to display.
 * @returns true if the given AgreementType is a korea agreement type
 */
export const isKoreaAgreementType = function isKoreaAgreementType(type: AgreementType) {
  return (
    type === AgreementType.KoreaConsentDataCollection ||
    type === AgreementType.KoreaConsentDataProvision
  );
};

/**
 * @param type AgreementType - the enum value of Agreement that has been selected to display.
 * @returns true if the given AgreementType is a china agreement type
 */
export const isChinaAgreementType = function isChinaAgreementType(type: AgreementType) {
  return type === AgreementType.ChinaPiplLearnMore || type === AgreementType.ChinaPrivacy;
};

/**
 * @param type AgreementType - the enum value of Agreement that has been selected to display.
 * @returns true if the given AgreementType is an external link that will be displayed rather
 * than the agreement text being displayed in the view.
 */
export const isExternalAgreementLink = function isExternalAgreementLink(type: AgreementType) {
  const { footerImpressumUrl, footerA11yConformeUrl, privacyLinkExternal, termsOfUseLinkExternal } =
    FooterConfig.instance;

  return !!(
    (type === AgreementType.Impressum && footerImpressumUrl) ||
    (type === AgreementType.A11yConforme && footerA11yConformeUrl) ||
    (type === AgreementType.Privacy && privacyLinkExternal) ||
    (type === AgreementType.TermsOfUse && termsOfUseLinkExternal)
  );
};

/**
 * @param type AgreementType - the enum value of Agreement that has been selected to display.
 * @returns true if the given AgreementType has child styling to apply
 */
export const hasChildStyling = function hasChildStyling(type: AgreementType) {
  return isKoreaAgreementType(type) || type === AgreementType.ChinaPiplLearnMore;
};

/**
 * This method is used to determine which buttons to show for a given AgreementType
 * @param type AgreementType - the enum value of Agreement that has been selected to display.
 * @returns an object with showTopButton and showBottomButton properties
 */
export const getButtonUsage = function getButtonUsage(type: AgreementType) {
  const { hideTopViewAgreementBackButton } = GlobalConfig.instance;
  const hideTopButton = isExternalAgreementLink(type) || hideTopViewAgreementBackButton;
  const hideBottomButton = isKoreaAgreementType(type) || isChinaAgreementType(type);
  return {
    showTopButton: !hideTopButton,
    showBottomButton: !hideBottomButton,
  };
};

export const buildAccessibleHtml = (text: string): string => {
  const result = text.trim();
  const htmlpTagFirst = '<p id="agreementTitle" tabindex="-1">';
  const htmlpTagOpen = '<p tabindex="-1">';
  const htmlpTagClose = "</p>";
  const newLineRegex = /(?:\r\n|\r|\n)/g;
  const emptyParagraphRegex = new RegExp(htmlpTagOpen + htmlpTagClose, "g");

  // Wrap agreement text in <p id="agreementTitle">...</p>
  const baseHtml = htmlpTagFirst + result + htmlpTagClose;

  // Replace newline characters with </p><p tabindex="-1">
  const withNewline = baseHtml.replace(newLineRegex, htmlpTagClose + htmlpTagOpen);

  // Remove any empty paragraphs
  const htmlResult = withNewline.replace(emptyParagraphRegex, "");

  return htmlResult;
};

export type GetExternalLinkParams = {
  agreementType: AgreementType;
  footerA11yConformeUrl: string;
  footerImpressumUrl: string;
  privacyUrl: string;
  termsOfUseUrl: string;
};

/**
 * This method is used to get the external link for the given AgreementType
 * @param params The parameters to get the external link
 * @returns The external link for the given AgreementType
 */
export const getExternalLink = (params: GetExternalLinkParams): string => {
  const { agreementType, footerA11yConformeUrl, footerImpressumUrl, privacyUrl, termsOfUseUrl } =
    params;

  switch (agreementType) {
    case AgreementType.Impressum:
      return footerImpressumUrl;
    case AgreementType.A11yConforme:
      return footerA11yConformeUrl;
    case AgreementType.Privacy:
      return privacyUrl;
    case AgreementType.TermsOfUse:
      return termsOfUseUrl;
    default:
      return "";
  }
};
