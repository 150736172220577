import { useAuthContext } from "../../../../../authentication-context";
import { type ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { type IFormSubmissionProps } from "../../../../../hooks/use-text-input-form-deprecated";
import { cleanseUsername } from "../../../../../model/user";
import {
  type IGetCredentialTypeRequestHelperFlags,
  sendAsync,
} from "../../../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import { useHandleGctResponse } from "../../../hooks/use-handle-gct-response";
import LoginConfig from "../../../login-config";
import { useLoginContext } from "../../../login-context";
import { LoginActionType } from "../../../login-reducer";

/**
 * Represents the properties for the useGct hook.
 */
export type UseGctProps = {
  /**
   * The active view ID to navigate from
   */
  viewId: ViewId;
  /**
   * The flags to pass to the GCT API. Most of them belongs to eSTS
   */
  flags?: IGetCredentialTypeRequestHelperFlags;
  /**
   * Function that returns an error message (either a string or element) that should be displayed if the GCT call fails.
   * @param unsafeUsername The username that was entered by the user.
   * @param gctError The error message from the GCT API
   * @returns The formatted error message to display to the user if the GCT call fails.
   */
  getErrorMessage: (unsafeUsername: string, gctError: string) => string | JSX.Element;
};

/**
 * @returns handler for calling GCT
 * @param props Represents the properties for the useGct hook.
 * @example
 * const getErrorMessage = (unsafeUsername: string, gctError?: string) =>
 *  (`Error: ${gctError || "unknown"}. The username ${unsafeUsername} is invalid.`);
 * const gctHandler = useGCT(getErrorMessage);
 */
export const useGct = (props: UseGctProps) => {
  const {
    authState: { flowTokenValue: flowToken },
  } = useAuthContext();

  const {
    viewState: { otherIdpRedirectUrl },
    dispatchStateChange,
  } = useLoginContext();

  const { flags, viewId, getErrorMessage } = props;
  const { resetPasswordUrl } = GlobalConfig.instance;

  const {
    allowedIdentities,
    changePasswordUrl,
    doIfExists,
    isFidoSupportedHint,
    fedQs,
    gctFederationFlags,
    improvePhoneDisambiguation,
    isFederationDisabled,
    isExternalFederationDisallowed,
    isRemoteNGCSupported,
    isOtcLoginDisabled: otclogindisallowed,
    getCredentialTypeUrl,
    postProofType,
    showSignup,
    signupUrl,
    signupUrlPostParams,
    useResetPasswordUrlForPasswordRequiredError,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  const handleGctResponse = useHandleGctResponse();

  return async (formSubmissionProps: IFormSubmissionProps) => {
    const { value, errorHandler } = formSubmissionProps;
    const unsafeUsername = String(value);

    dispatchStateChange({
      type: LoginActionType.SetIsSignupPost,
      payload: !!flags?.isSignup,
    });

    const gctResult = await sendAsync(
      {
        allowedIdentities,
        country: "", // for now, country is not needed in MSA
        fedQs,
        flowToken,
        gctFederationFlags: gctFederationFlags || 0,
        getCredentialTypeUrl,
        isExternalFederationDisallowed,
        isFederationDisabled,
        isFidoSupported,
        isRemoteNGCSupported,
        otclogindisallowed,
        otherIdpRedirectUrl,
        unsafeUsername,
      },
      {
        changePasswordUrl,
        doIfExists,
        improvePhoneDisambiguation,
        postProofType,
        resetPasswordUrl,
        showSignup,
        signupUrl,
        signupUrlPostParams,
        useResetPasswordUrlForPasswordRequiredError,
      },
      flags,
    );
    const errorHandlerFunction = () => {
      const errorMessage = gctResult.error ? getErrorMessage(unsafeUsername, gctResult.error) : "";
      errorHandler(errorMessage);
    };

    handleGctResponse(
      viewId,
      gctResult,
      cleanseUsername(unsafeUsername),
      gctResult.sharedData?.displayName || unsafeUsername,
      errorHandlerFunction,
    );
  };
};
