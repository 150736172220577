import { type FormEvent } from "react";
import { type ViewId } from "../../../constants";
import { useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { type IFormSubmissionProps } from "../../../hooks";
import { type InputValue } from "../input-interface";
import { type InputErrorState } from "./use-input";

export type SubmitTask<T extends IFormSubmissionProps> = (args: T) => Promise<void>;

export type FormInputState = {
  error: InputErrorState;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  setUserHasSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  value: NonNullable<InputValue>;
  elementRef?: React.RefObject<HTMLInputElement>;
};

export type FormSubmissionProps<T extends IFormSubmissionProps> = {
  inputState: FormInputState | Array<FormInputState>;
  viewId: ViewId;
  submitParams?: T;
  submitTask: SubmitTask<T>;
};

/**
 * This hook is meant to be used to submit forms built using the Input component.
 * It shows any error messages on the input(s) and execute the submitTask if there are no errors.
 * It also manages global state and input focus during submission.
 * @param options Options for form submission
 * - `inputState` - The state of the input(s) to be submitted
 * - `viewId` - The viewId of the form being submitted
 * - `submitParams` - Optional parameters to be passed to the submitTask
 *    By default, this will be the value and errorHandler of the first input.
 *    Multiple-input forms should provide this parameter if needed.
 * - `submitTask` - The task to be executed upon submission
 * @returns the callback to be invoked upon form submission
 */
export function useFormSubmission<T extends IFormSubmissionProps>(options: FormSubmissionProps<T>) {
  const { inputState, viewId, submitParams, submitTask } = options;
  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();
  const inputStates = inputState instanceof Array ? inputState : [inputState];

  if (inputStates.length === 0) {
    throw new Error("No input state provided");
  }

  return async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // show progress indicator during call
    dispatchGlobal({
      type: GlobalActionType.BeginNavigate,
      source: viewId,
    });

    let formHasError = false;
    inputStates.forEach((input) => {
      const {
        setFocus,
        error: { serverError, validationError, setShowErrorMessage },
        setUserHasSubmitted,
        elementRef,
      } = input;
      // initiate validation only upon submission
      setUserHasSubmitted(true);
      setShowErrorMessage(true);

      const inputHasError = serverError || validationError;
      if (inputHasError && !formHasError) {
        formHasError = true;

        if (elementRef) {
          elementRef?.current?.focus();
        } else {
          setFocus(true);
        }
      }
    });

    if (!formHasError) {
      const firstInputState = inputStates[0];

      if (firstInputState.elementRef) {
        firstInputState.elementRef.current?.focus();
      } else {
        firstInputState.setFocus(true);
      }

      if (submitParams) {
        await submitTask(submitParams);
      } else {
        // if no SubmitParams type was specified, then submitTask must accept an argument of type IFormSubmissionProps, since that is the only thing this handler knows to construct
        await (submitTask as SubmitTask<IFormSubmissionProps>)({
          value: firstInputState.value,
          errorHandler: firstInputState.error.setServerError,
        });
      }
    }

    // re-enable after api completion
    dispatchGlobal({
      type: GlobalActionType.DataLoaded,
      view: viewId,
    });
  };
}
