/**
 * @returns Challenge view strings
 */
export const challengeStringsFabric = {
  sessionExpiredError: getLocalString("OneTimeCode_SessionExpiredError"),
  sendFailedError: getLocalString("OneTimeCode_SendFailedError"),
  invalidProofError: getLocalString("Sms_Proof_Input_MismatchError"),
  hipInvalidAnswer: getLocalString("Error_1043"),
  nextButton: getLocalString("General_Buttons_Next"),
  solveTitle: getLocalString("Captcha_SolveChallenge_Title"),
  blockTitle: getLocalString("VerificationMethod_NotWorking_Title"),
  blockDescription: getLocalString("VerificationMethod_NotWorking_Description"),
  errorContainerMessage: getLocalString("Generic_Service_Error"),
};
