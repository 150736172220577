import { useRedirect } from "../../../../hooks/use-redirect";
import {
  type EmbeddedBinding,
  createEmbeddedLinkButtonFabric,
  createEmbeddedLinkFabric,
} from "../../../../utilities/formatted-text-with-bindings";
import { useResetPasswordUrl } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";

type ILoginErrorEmbeddedBindings = {
  federatedLink?: EmbeddedBinding;
  resetPasswordLink?: EmbeddedBinding;
  signupLink?: EmbeddedBinding;
};

/**
 * This hook creates the embedded bindings for the FormattedTextWithBindings component required for Login errors.
 * @returns an object containing the embedded bindings for the FormattedTextWithBindings component.
 */
export const useEmbeddedBindings = () => {
  const { fedUrl, signupUrl } = LoginConfig.instance;
  const resetPasswordUrl = useResetPasswordUrl();
  const redirect = useRedirect();

  const embeddedBindings: ILoginErrorEmbeddedBindings = {};

  if (resetPasswordUrl) {
    const onClick = () => redirect(resetPasswordUrl, true);
    embeddedBindings.resetPasswordLink = createEmbeddedLinkButtonFabric(onClick);
  }

  if (fedUrl) {
    embeddedBindings.federatedLink = createEmbeddedLinkFabric(fedUrl);
  }

  if (signupUrl) {
    embeddedBindings.signupLink = createEmbeddedLinkFabric(signupUrl);
  }

  return embeddedBindings;
};
