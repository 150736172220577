import { type CommonLoginStrings, type LoginInputValidationErrorStrings } from "../login-interface";

export const commonLoginStringsFabric: CommonLoginStrings = {
  switchAccountsPageTitle: getLocalString("SwitchAccounts_PageTitle"),
  signInPageTitle: getLocalString("SignIn_PageTitle"),
  signInToAppTokenized: getLocalString("Login_SignInToApp"),
  toContinueToApp: getLocalString("Login_ToContinueToApp"),
  continueToApp: getLocalString("Login_ContinueToApp"),
  fidoHelpDialogDesc2: getLocalString("Login_HelpDialog_Fido_Desc2"),
  loginCredPickerOptionFidoNoHelloKnownUser: getLocalString(
    "Login_CredPicker_Option_Fido_NoHello_KnownUser",
  ),
  loginCredPickerOptionFidoNoHello: getLocalString("Login_CredPicker_Option_Fido_NoHello"),
  loginCredPickerOptionFidoKnownUser: getLocalString("Login_CredPicker_Option_Fido_KnownUser"),
  loginCredPickerOptionFido: getLocalString("Login_CredPicker_Option_Fido"),
  fidoHelpWithSecurityKey: getLocalString("Login_CredPicker_Option_Help_Fido_NoHello"),
  fidoHelpWithWindowsHelloAndSecurityKey: getLocalString("Login_CredPicker_Option_Help_Fido"),
  fidoHelpDescWithWindowsHelloAndSecurityKey: getLocalString("Login_HelpDialog_Fido_Desc"),
  fidoHelpDescWithSecurityKey: getLocalString("Login_HelpDialog_Fido_Desc_NoHello"),
  fidoPasskeyLinkText: getLocalString("Login_CredPicker_Option_Passkey"),
  fidoPasskeyHelpText: getLocalString("Login_CredPicker_Option_Help_Passkey"),
  fidoPassKeyDialogHeaderText: getLocalString("Login_CredPicker_Option_Passkey"),
  fidoPasskeyDialogText: getLocalString("Login_HelpDialog_Passkey_Desc"),
  fidoPasskeyDialogDescription: getLocalString("Login_HelpDialog_Passkey_Desc2"),
  invalidMemberName: getLocalString("SignIn_Error_EmailMissing"),
  incorrectOneTimeCodeError: getLocalString("IncorrectOTC_Error"),
  wrongPasswordError: getLocalString("Login_WrongPasswordError"),
  wrongPasswordLockedUserError: getLocalString("Login_WrongPasswordError_LockedUser"),
  oldSkypePasswordError: getLocalString("Login_Password_OldSkypePasswordError"),
  aliasDisabledError: getLocalString("Login_AliasDisabled_Error"),
  fedNotAllowedError: getLocalString("Login_FederationNotAllowed_Error"),
  missingPasswordError: getLocalString("Login_MissingPassword_Error"),
  linkedInFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_LinkedIn"),
  googleFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_Google"),
  githubFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_GitHub"),
  evictedCredPickerLinkText: getLocalString("Login_SwitchToCredPicker_Link_EvictedAcct"),
  phoneDisambiguationLinkText: getLocalString("Login_PhoneDisambiguation_Link"),
  signInDifferentAccount: getLocalString("Login_SignInWithAnotherAccount"),
  loginEnterPasswordDescription: getLocalString("Login_EnterPassword_Description"),
  loginMinecraftRemoteConnectUserNamePageDesc: getLocalString(
    "Login_RemoteConnect_UserNamePage_Desc",
  ),
  nextButton: getLocalString("General_Buttons_Next"),
  backButton: getLocalString("General_Back"),
  addMicrosoftAccountPageTitleOobe: getLocalString("ROOBE_Title"),
  oneAccountConnectsAllAppsPageDescriptionOobe: getLocalString("ROOBE_SubTitle"),
  skipThisStep: getLocalString("SkipThisStep"),
  parentSignInPageTitle: getLocalString("Login_ParentSignIn"),
  parentSignInPageDescription: getLocalString("ParentSignIn_Description"),
  parentSignInPagePlaceholder: getLocalString("SignIn_ParentUserName_Label"),
};

export const loginInputValidationErrorStringsFabric: LoginInputValidationErrorStrings = {
  ...commonLoginStringsFabric,
  accountPasswordIncorrectError: getLocalString("Login_AccountPasswordIncorrect_Error"),
  aliasAuthCollisionError: getLocalString("Login_AliasAuthCollision_Error"),
  bindingExistsSamsungError: getLocalString("BindFailed_AlreadyBound_Desc_Samsung"),
  fedNotAllowedErrorWithPartner: getLocalString("Login_Fed_LoginNotAllowed_Error"),
  fidoPasskeyError: getLocalString("Login_Fido_Passkey_Error"),
  invalidEmailError: getLocalString("Login_AltEmailInvalid_Error"),
  memberDoesNotExistError: getLocalString("Login_Error_LoginFailure_BadUsername"),
  ngcAuthFailedError: getLocalString("Login_FIDO_AuthFailed_Error"),
  notOverSslError: getLocalString("NotOverSSL_Error"),
  ottInvalidPurposeError: getLocalString("OTC_WrongPurpose_Error"),
  realmNotFederatedError: getLocalString("Login_Fed_RealmNotFederated_Error"),
  reservedUsernameError: getLocalString("Login_EmailPasswordIncorrect_Error"),
  sessionExpiredError: getLocalString("SessionExpired_Error"),
  tooManyRequestsError: getLocalString("Login_TooManyRequests_Error"),
  uniqueIdExistsSamsungError: getLocalString("BindFailed_UniqueIdExists_Desc_Samsung"),
};
