import { type DeepPartial } from "../utilities/deep-partial-type";

/**
 * Check if the object is a record with string keys and unknown values
 * @param obj The unknown object to check
 * @returns Whether the object is a record with string keys and unknown values
 */
export function isUnknownRecord(obj: unknown): obj is Record<string, unknown> {
  return (
    obj !== undefined &&
    typeof obj === "object" &&
    obj !== null &&
    !Array.isArray(obj) &&
    Object.keys(obj as object).length !== 0
  );
}

/*
 * This function will recursively check the object passed into the init fuction for
 *   undefined values and will fall back to default values instead.
 *   Assumes that the default object contains all possible config values.
 * @param initObj The object passed in to the initialize function OR
 *   the part of the object that is being recursively checked
 * @param defaultObj The object that contains the fallback default values
 * @return The object with all undefined values replaced with default values
 */
const replaceUndefinedWithDefault = (
  initObj: Record<string, unknown>,
  defaultObj: Record<string, unknown>,
) => {
  let obj: Record<string, unknown> = {};

  Object.entries(defaultObj).forEach(([key, value]) => {
    const objDefault = defaultObj[key];
    const objInit = initObj[key];

    // If the value is undefined in the object passed in, use the default value
    if (objInit === undefined || objInit === null) {
      obj = { ...obj, [key]: value };

      // If we're dealing with a nested object, recursively check the for undefined values
    } else if (isUnknownRecord(objDefault) && isUnknownRecord(objInit)) {
      const childObj = replaceUndefinedWithDefault(objInit, objDefault);

      obj = { ...obj, [key]: childObj };
    } else {
      // use the value passed into the init function for the config
      obj = { ...obj, [key]: objInit };
    }
  });

  return obj;
};

/**
 * This class creates a singleton wrapper around a config object, ensuring that the object is not modified after initialization. Config Wrappers should be
 * initialized near the root of the app, outside of the component render cycle
 */
export class ConfigWrapper<T extends Record<string, unknown>> {
  private isInitialized: boolean = false;

  private defaultConfigValues: T = {} as T;

  /**
   * Construct a ConfigWrapper instance
   * @param config The *default* config object that should be used until initialize is called
   */
  constructor(private config: T) {
    this.defaultConfigValues = { ...config };
  }

  /**
   * Initialize the config wrapper
   * @param config The config object that should be wrapped (replacing the default object passed to the constructor)
   */
  initialize(config: DeepPartial<T>) {
    // Don't throw on Storybook since multiple apps are loaded in the one webpack bundle of Storybook
    if (!IS_STORYBOOK && this.isInitialized) {
      throw new Error("Config already initialized");
    }

    this.isInitialized = true;

    const obj = replaceUndefinedWithDefault(config, this.defaultConfigValues);
    this.config = { ...this.config, ...config, ...obj };
  }

  /**
   * Get the current config. Config properties are READ-ONLY unless you are very sure you know what you are doing.
   * @returns The wrapped config object
   */
  get instance(): T {
    return this.config;
  }

  /**
   * Clean up the config object. This should be called once the config is no longer needed.
   */
  cleanup() {
    this.config = this.defaultConfigValues; // Create an instance of type T
    this.isInitialized = false;
  }
}
