import { type BindProvider, type ViewId } from "../../constants";
import { type ILoginCredentials } from "../../model/credential";
import { type RedirectPostParams } from "../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import type { IRemoteLoginPollingParams, IRemoteNgcParams, LoginState } from "./login-context";

export enum LoginActionType {
  SetChallengeData,
  SetDesktopSsoDomain,
  SetHasIdpDisambigError,
  SetIdpRedirectData,
  SetInitialViewId,
  SetLocation,
  SetRemoteLoginPollingParams,
  SetRemoteNgcParams,
  SetFidoAllowList,
  SetIsSignupPost,
  SetServerErrorShown,
  SetShowCredViewBrandingDesc,
  UpdateCredentials,
}

export type SetDesktopSsoDomainAction = {
  type: LoginActionType.SetDesktopSsoDomain;
  payload: string;
};

export type SetHasIdpDisambigErrorAction = {
  type: LoginActionType.SetHasIdpDisambigError;
  payload: boolean;
};

export type SetIdpRedirectDataAction = {
  type: LoginActionType.SetIdpRedirectData;
  payload: { url?: string; postParams?: RedirectPostParams; provider?: BindProvider };
};

export type SetLocationAction = {
  type: LoginActionType.SetLocation;
  payload: string;
};

export type SetRemoteLoginPollingParamsAction = {
  type: LoginActionType.SetRemoteLoginPollingParams;
  payload: Partial<IRemoteLoginPollingParams>;
};

export type SetRemoteNgcParamsAction = {
  type: LoginActionType.SetRemoteNgcParams;
  payload: Partial<IRemoteNgcParams>;
};

export type SetFidoAllowListAction = {
  type: LoginActionType.SetFidoAllowList;
  payload: string[];
};

export type SetServerDataShownAction = {
  type: LoginActionType.SetServerErrorShown;
  payload: boolean;
};

export type SetShowCredViewBrandingDescAction = {
  type: LoginActionType.SetShowCredViewBrandingDesc;
  payload: boolean;
};

export type SetIsSignupPostAction = {
  type: LoginActionType.SetIsSignupPost;
  payload: boolean;
};

export type SetInitialViewId = {
  type: LoginActionType.SetInitialViewId;
  payload: ViewId;
};

export type UpdateCredentials = {
  type: LoginActionType.UpdateCredentials;
  payload: Partial<ILoginCredentials>;
};

export type LoginActions =
  | SetDesktopSsoDomainAction
  | SetHasIdpDisambigErrorAction
  | SetIdpRedirectDataAction
  | SetInitialViewId
  | SetLocationAction
  | SetRemoteLoginPollingParamsAction
  | SetRemoteNgcParamsAction
  | SetFidoAllowListAction
  | SetIsSignupPostAction
  | SetServerDataShownAction
  | SetShowCredViewBrandingDescAction
  | UpdateCredentials;

/**
 * Login state reducer.
 * @param state The current Login state
 * @param action The action to perform on the current state
 * @returns The updated Login state
 */
export default function loginReducer(state: LoginState, action: LoginActions): LoginState {
  const actionType = action.type;
  switch (actionType) {
    case LoginActionType.SetDesktopSsoDomain:
      return { ...state, unsafeDesktopSsoDomainToUse: action.payload };
    case LoginActionType.SetHasIdpDisambigError:
      return { ...state, hasIdpDisambigError: action.payload };
    case LoginActionType.SetIdpRedirectData: {
      const {
        url = state.idpRedirectUrl,
        postParams = state.idpRedirectPostParams,
        provider = state.idpRedirectProvider,
      } = action.payload;

      return {
        ...state,
        idpRedirectPostParams: postParams,
        idpRedirectProvider: provider,
        idpRedirectUrl: url,
      };
    }

    case LoginActionType.SetInitialViewId:
      return { ...state, initialViewId: action.payload };
    case LoginActionType.SetLocation:
      return { ...state, location: action.payload };
    case LoginActionType.SetRemoteLoginPollingParams:
      return {
        ...state,
        remoteLoginPollingParams: {
          ...state.remoteLoginPollingParams,
          ...action.payload,
        },
      };
    case LoginActionType.SetRemoteNgcParams:
      return {
        ...state,
        remoteNgcParams: {
          ...state.remoteNgcParams,
          ...action.payload,
        },
      };
    case LoginActionType.SetFidoAllowList:
      return { ...state, fidoAllowList: action.payload };
    case LoginActionType.SetServerErrorShown:
      return { ...state, serverErrorShown: action.payload };
    case LoginActionType.SetShowCredViewBrandingDesc:
      return { ...state, showCredViewBrandingDesc: action.payload };
    case LoginActionType.SetIsSignupPost:
      return { ...state, isSignupPost: action.payload };
    case LoginActionType.UpdateCredentials:
      return {
        ...state,
        credentials: { ...state.credentials, ...action.payload },
      };
    default:
      throw new Error(`LoginReducer received unexpected action ${actionType}`);
  }
}
