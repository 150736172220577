import React from "react";
import { type HiddenFormInput, HiddenFormInputs } from "./hidden-form-inputs";

export type ViewFormProps = {
  name?: string;
  dataTestId?: string;
  method?: string;
  onSubmit?: React.FormEventHandler;
  action?: string;
  hiddenFormInputs?: HiddenFormInput[];
  ref?: React.Ref<HTMLFormElement>;
  className?: string;
};

export type ViewFormContainerProps = {
  form: ViewFormProps;
};

/**
 * Form View Component that renders a form with hidden inputs
 * @param props ViewContainerProps
 * @param props.children Any child component
 * @param props.form Contains the form props
 * @returns instance of the view
 */
export const ViewFormContainer: React.FC<ViewFormContainerProps> = function ViewFormContainer({
  children,
  form,
}) {
  const { name, dataTestId, method, onSubmit, action, hiddenFormInputs, ref, className } = form;
  return (
    <form
      name={name}
      data-testid={dataTestId}
      noValidate
      spellCheck="false"
      method={method || "post"}
      autoComplete="false"
      onSubmit={onSubmit}
      action={action}
      target="_top"
      ref={ref}
      className={className}
    >
      {children}
      {hiddenFormInputs && <HiddenFormInputs hiddenFormInputs={hiddenFormInputs} />}
    </form>
  );
};
