import { FlowId } from "../../../constants";
import { commonLoginStringsFabric } from "../../../flows/login/fabric/common-login-strings-fabric";
import type { IPageTitleStrings } from "../../page-title/page-title-interface";

// This map contains flow-specific page titles and descriptions for the PageTitleFabric shared component
// that is rendered in the LayoutContent component. If this map does not contain an entry for a certain
// flow, an empty page title and description will be returned by the utility function.
const FlowIdToPageTitleStringsMap = new Map<FlowId, IPageTitleStrings>([
  [
    FlowId.AliasAccrual,
    {
      pageDescription: commonLoginStringsFabric.oneAccountConnectsAllAppsPageDescriptionOobe,
      pageTitle: commonLoginStringsFabric.addMicrosoftAccountPageTitleOobe,
    },
  ],
  [
    FlowId.Login,
    {
      pageDescription: commonLoginStringsFabric.oneAccountConnectsAllAppsPageDescriptionOobe,
      pageTitle: commonLoginStringsFabric.addMicrosoftAccountPageTitleOobe,
    },
  ],
  [
    FlowId.ResetPassword,
    {
      pageDescription: commonLoginStringsFabric.oneAccountConnectsAllAppsPageDescriptionOobe,
      pageTitle: commonLoginStringsFabric.addMicrosoftAccountPageTitleOobe,
    },
  ],
]);

/**
 * @param activeFlow The active flow
 * @returns The page title and description strings for the fabric flavor of the active flow.
 */
export const getFlowBasedPageTitleStrings = (activeFlow: FlowId): IPageTitleStrings =>
  FlowIdToPageTitleStringsMap.get(activeFlow) || { pageDescription: "", pageTitle: "" };
