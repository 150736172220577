import React from "react";

export type HiddenFormInput = React.HTMLProps<HTMLInputElement> & {
  "data-testid"?: string;
};

/**
 * Helper component that renders a list of hidden input tags. This component should always be inside a form tag.
 * Most new views should use a ViewContainer, which allows you to specify hidden form inputs in
 * props.form.hiddenFormInputs, rather than using this component directly.
 * @param hiddenFormInputs Array of hidden form inputs that should be rendered on the page
 * @param hiddenFormInputs.hiddenFormInputs Form inputs that should be rendered on the page
 * @returns A set of input tags representing the hidden inp uts
 */
export const HiddenFormInputs: React.FC<{ hiddenFormInputs: HiddenFormInput[] }> =
  function HiddenFormInputs({ hiddenFormInputs }) {
    return (
      <div data-testid="hiddenInputs">
        {hiddenFormInputs.map((input) => (
          <input {...input} key={input.id || input.name} type="hidden" />
        ))}
      </div>
    );
  };
