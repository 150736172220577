import { useState } from "react";
import { UserFlowType, ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useGlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { useEffectOnce, useNavigateDirection } from "../../../../../hooks";
import { useTelemetry } from "../../../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../../../telemetry-helpers/user-action-name";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";

export const useBenefitsView = () => {
  const { activeFlavor, telemetry } = GlobalConfig.instance;

  const {
    globalState: { activeView, activeFlow, userFlowType },
    dispatchStateChange,
  } = useGlobalContext();

  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const navigate = useNavigateDirection();

  const [showBackButton, setShowBackButton] = useState(true);

  useEffectOnce(() => {
    const isChild = userFlowType === UserFlowType.Child;
    if (isChild) {
      setShowBackButton(false);
    }
  });

  const onClickNextHandler = () => {
    logUserAction({
      actionName: UserActionName.BenefitsViewNextClicked,
    });
    dispatchStateChange({
      type: GlobalActionType.SetUserFlowType,
      payload: UserFlowType.Parent.toString(),
    });
    dispatchStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.Benefits,
      destination: ViewId.Username,
      displayOptions: { navigationDirection: "forward" },
    });
    navigate(ViewId.Benefits, getRouteFromViewId(ViewId.Username));
  };

  const onClickBackHandler = () => {
    logUserAction({
      actionName: UserActionName.BenefitsViewBackClicked,
    });
    dispatchStateChange({
      type: GlobalActionType.SetUserFlowType,
      payload: UserFlowType.Unknown.toString(),
    });
    navigate(ViewId.Benefits, -1);
  };

  return { onClickNextHandler, onClickBackHandler, showBackButton };
};
