import { useEffect } from "react";

/**
 * Sets the favorites icon for the page
 * @param favIconUrl The URL of the favicon
 */
export const useFavIcon = (favIconUrl: string) => {
  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (favicon && favIconUrl) {
      favicon.href = favIconUrl;
    }
  }, [favIconUrl]);
};
