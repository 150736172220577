import React, { createContext, useContext, useMemo, useReducer } from "react";
import { type RedirectPostParams } from "./utilities/api-helpers/get-credential-type/get-credential-type-helper";
import postRedirectReducer, { type PostRedirectActions } from "./post-redirect-reducer";

export type PostRedirectState = {
  url: string;
  postParams: RedirectPostParams;
  target: string;
  submitForm: boolean;
};

export interface IPostRedirectContext {
  postRedirectState: PostRedirectState;
  dispatchStateChange: React.Dispatch<PostRedirectActions>;
}

export const initialPostRedirectState: PostRedirectState = {
  url: "",
  postParams: {},
  target: "_top",
  submitForm: false,
};

export const PostRedirectContext = createContext<IPostRedirectContext>({
  postRedirectState: initialPostRedirectState,
  dispatchStateChange: () => {
    throw new Error("PostRedirectContext not initialized");
  },
});

export const usePostRedirectContext = () => useContext(PostRedirectContext);

/**
 * @param props The input props for the post redirect provider
 * @param props.initialState The initial state that the post redirect provider should be initialized with
 * @param props.children The child components to render inside this provider
 * @returns The instantiated Provider component
 */
export const PostRedirectProvider: React.FC<{ initialState: PostRedirectState }> =
  function PostRedirectProvider({ initialState, children }) {
    const [state, dispatch] = useReducer(postRedirectReducer, initialState);
    const value: IPostRedirectContext = useMemo(
      () => ({
        postRedirectState: state,
        dispatchStateChange: dispatch,
      }),
      [state],
    );

    return <PostRedirectContext.Provider value={value}>{children}</PostRedirectContext.Provider>;
  };
