import { DefaultBrandingStyles, LayoutTemplateType } from "../constants";
import {
  type AccentColors,
  type AppBranding,
  type BrandingStyles,
  type CustomizationFiles,
  type CustomLayoutConfig,
  type LocalizedTenantBranding,
  type PageBranding,
  type TenantBranding,
} from "./customization-types";
import {
  getAppBackgroundImageSource,
  getAppLogoImageSource,
  getBackgroundImageSource,
} from "./images-helper";
import { type ServerData } from "./server-data";
import { htmlUnescape } from "./strings-helper";

export const defaultAccentColors: AccentColors = {
  primaryButtonDefaultColor: "",
  primaryButtonHoverColor: "",
  primaryButtonFocusColor: "",
  primaryButtonStringColor: "",
};

export const brandingProperties = [
  "BoilerPlateText",
  "UserIdLabel",
  "TileLogo",
  "TileDarkLogo",
  "BannerLogo",
  "BackgroundColor",
  "Illustration",
  "KeepMeSignedInDisabled",
  "UseTransparentLightBox",
  "LayoutTemplateConfig",
  "CustomizationFiles",
  "AccessRecoveryLink",
  "CantAccessYourAccountText",
  "ForgotPasswordText",
  "FooterTOULink",
  "FooterTOUText",
  "FooterPrivacyLink",
  "FooterPrivacyText",
  "Favicon",
];

export const defaultLayoutConfig: CustomLayoutConfig = {
  showHeader: false,
  headerLogo: "",
  layoutType: LayoutTemplateType.Lightbox,
  showFooter: true,
  hideTOU: false,
  hidePrivacy: false,
  hideAccountResetCredentials: false,
};

/**
 * @private
 */
export function createLayoutConfig(
  tenantBranding: LocalizedTenantBranding | undefined,
  isCiamUserFlow?: boolean,
  useDefaultCiamBranding?: boolean,
): CustomLayoutConfig {
  let layoutConfig = tenantBranding?.LayoutTemplateConfig;
  if (!layoutConfig || Object.entries(layoutConfig).length === 0) {
    layoutConfig = {
      ...defaultLayoutConfig,
    };

    if (useDefaultCiamBranding && isCiamUserFlow) {
      layoutConfig.showFooter = false;
    }
  }

  return layoutConfig;
}

/**
 * @private
 */
export function getPageBranding(
  tenantBranding: LocalizedTenantBranding,
  appBranding: AppBranding | undefined,
  defaultImageIndex?: number,
  isCiamUserFlow?: boolean,
  companyDisplayName?: string,
  useDefaultCiamBranding?: boolean,
): PageBranding {
  const pageBranding: PageBranding = {
    useDarkFooter: false,
    alwaysShowBackground: false,
  };

  if (tenantBranding.BannerLogo) {
    pageBranding.bannerLogoUrl = tenantBranding.BannerLogo;
  } else if (useDefaultCiamBranding && isCiamUserFlow && companyDisplayName) {
    pageBranding.bannerLogoText = companyDisplayName.toUpperCase();
  }

  if (tenantBranding.BackgroundColor || tenantBranding.Illustration) {
    // If tenant branding is present, it always takes precedence
    pageBranding.color = tenantBranding.BackgroundColor;
    pageBranding.backgroundImageUrl = tenantBranding.Illustration;
    pageBranding.useTransparentLightBox = tenantBranding.UseTransparentLightBox;

    pageBranding.useImageMask = useDefaultCiamBranding ? !isCiamUserFlow : true;

    pageBranding.useDarkFooter = true;
  } else if (
    (appBranding?.backgroundImageIndex !== undefined && appBranding.backgroundImageIndex >= 0) ||
    (appBranding?.backgroundLogoIndex !== undefined && appBranding.backgroundLogoIndex >= 0) ||
    appBranding?.backgroundColor ||
    appBranding?.friendlyAppName
  ) {
    pageBranding.useDarkFooter = true;

    // If no tenant branding is present but app branding is present, it takes precedence over our default branding
    if (appBranding.backgroundImageIndex !== undefined && appBranding.backgroundImageIndex >= 0) {
      pageBranding.backgroundImageUrl = getAppBackgroundImageSource(
        appBranding.backgroundImageIndex.toString(),
      );
    }

    if (appBranding.backgroundLogoIndex !== undefined && appBranding.backgroundLogoIndex >= 0) {
      pageBranding.backgroundLogoUrl = getAppLogoImageSource(
        appBranding.backgroundLogoIndex.toString(),
      );
      pageBranding.alwaysShowBackground = true;
    }

    pageBranding.color = appBranding.backgroundColor;
    pageBranding.friendlyAppName = appBranding.friendlyAppName;
    pageBranding.partnerTag = appBranding.partnerTag;

    if (appBranding.accentColors) {
      pageBranding.accentColors = {
        primaryButtonDefaultColor: appBranding.accentColors.primaryButtonColor || "",
        primaryButtonHoverColor: appBranding.accentColors.primaryButtonHoverColor || "",
        primaryButtonFocusColor: appBranding.accentColors.primaryButtonFocusColor || "",
        primaryButtonStringColor: appBranding.accentColors.primaryButtonStringColor || "",
      };
    }
  } else if (useDefaultCiamBranding && isCiamUserFlow) {
    pageBranding.color = DefaultBrandingStyles.BackgroundColor;
  } else if (defaultImageIndex !== undefined && defaultImageIndex >= 0) {
    pageBranding.backgroundImageUrl = getBackgroundImageSource(defaultImageIndex.toString());
  }

  return pageBranding;
}

/**
 * @private
 */
export function convertBranding(
  tenantBranding: LocalizedTenantBranding,
  layoutConfig: CustomLayoutConfig,
  pageBranding: PageBranding,
): Partial<BrandingStyles> {
  return {
    // TenantBranding
    boilerPlateText: tenantBranding.BoilerPlateText || "",
    customizationFiles: tenantBranding.CustomizationFiles || {},
    faviconUrl: tenantBranding.Favicon || "",
    privacyText: tenantBranding.FooterPrivacyText || "",
    privacyUrl: tenantBranding.FooterPrivacyLink || "",
    termsOfUseText: tenantBranding.FooterTOUText || "",
    termsOfUseUrl: tenantBranding.FooterTOULink || "",
    accessRecoveryUrl: tenantBranding.AccessRecoveryLink || "",
    cantAccessYourAccountText: tenantBranding.CantAccessYourAccountText || "",
    forgotPasswordText: tenantBranding.ForgotPasswordText || "",
    // LayoutConfig
    headerLogoUrl: layoutConfig.headerLogo,
    layoutTemplate: layoutConfig.layoutType,
    showAccountResetCredentials: !layoutConfig.hideAccountResetCredentials,
    showFooter: layoutConfig.showFooter,
    showHeader: layoutConfig.showHeader,
    showPrivacy: !layoutConfig.hidePrivacy,
    showTermsOfUse: !layoutConfig.hideTOU,
    // PageBranding
    accentColors: pageBranding.accentColors ?? defaultAccentColors,
    alwaysShowBackground: pageBranding.alwaysShowBackground || false,
    backgroundColor: pageBranding.color || "",
    backgroundImageUrl: pageBranding.backgroundImageUrl || "",
    backgroundLogoUrl: pageBranding.backgroundLogoUrl || "",
    bannerLogoUrl: pageBranding.bannerLogoUrl || "",
    bannerLogoText: pageBranding.bannerLogoText || "",
    friendlyAppName: pageBranding.friendlyAppName || "",
    partnerTag: pageBranding.partnerTag || "",
    useBackgroundImageMask: !!pageBranding.useImageMask,
    useDarkFooter: pageBranding.useDarkFooter || false,
    useTransparentLightbox: !!pageBranding.useTransparentLightBox,
  };
}

/**
 * Takes the tenant branding array from ServerData and converts it to a localized tenant branding object.
 * @param brandingToUse - The tenant branding array from ServerData with the global default (fallback) and the localized branding (preferred).
 * @param useDefaultCiamBranding - Whether to use the CIAM branding fallback defaults.
 * @returns Localized tenant branding object.
 */
export function getLocalizedTenantBranding(
  brandingToUse: TenantBranding | undefined,
  useDefaultCiamBranding?: boolean,
): LocalizedTenantBranding {
  let tenantBranding: LocalizedTenantBranding = {};

  if (brandingToUse) {
    const fallbackBranding: LocalizedTenantBranding = brandingToUse[0] ?? {};
    const preferredBranding: LocalizedTenantBranding = brandingToUse[1] ?? {};

    // Merge the fallback and the preferred branding objects into the branding object.
    // The preferred branding object takes precedence over the fallback branding object.
    tenantBranding = brandingProperties.reduce((branding: LocalizedTenantBranding, prop) => {
      const propertyName = prop as keyof LocalizedTenantBranding;

      if (prop === "LayoutTemplateConfig") {
        const currentPreferredValue: CustomLayoutConfig =
          (preferredBranding[propertyName] as CustomLayoutConfig) || {};
        const currentFallbackValue: CustomLayoutConfig =
          (fallbackBranding[propertyName] as CustomLayoutConfig) || {};

        const layoutConfig: CustomLayoutConfig = {
          ...currentFallbackValue,
          ...currentPreferredValue,
        };

        if (Object.entries(layoutConfig).length > 0) {
          return {
            ...branding,
            LayoutTemplateConfig: layoutConfig,
          };
        }

        return branding;
      }

      if (prop === "CustomizationFiles") {
        const currentPreferredValue: CustomizationFiles =
          (preferredBranding[propertyName] as CustomizationFiles) || {};
        const currentFallbackValue: CustomizationFiles =
          (fallbackBranding[propertyName] as CustomizationFiles) || {};

        const customizationFiles: CustomizationFiles = {
          ...currentFallbackValue,
          ...currentPreferredValue,
        };

        if (Object.entries(customizationFiles).length > 0) {
          return {
            ...branding,
            CustomizationFiles: {
              ...customizationFiles,
              strings: { ...currentFallbackValue.strings, ...currentPreferredValue.strings },
            },
          };
        }

        return branding;
      }

      const valueToAssign = preferredBranding[propertyName] ?? fallbackBranding[propertyName];

      if (valueToAssign !== undefined) {
        return {
          ...branding,
          [propertyName]: valueToAssign,
        };
      }

      return branding;
    }, tenantBranding);

    if (!tenantBranding.TileDarkLogo && tenantBranding.TileLogo) {
      tenantBranding.TileDarkLogo = tenantBranding.TileLogo;
    }

    // We want this for all traffic, not just CIAM. Using the default CIAM feature flag to rollout at the same time.
    if (useDefaultCiamBranding && tenantBranding.UserIdLabel) {
      tenantBranding.Unsafe_UserIdLabel = htmlUnescape(tenantBranding.UserIdLabel);
    }
  }

  return tenantBranding;
}

/**
 * Takes the localized home and resource tenant branding objects and converts them into a merged tenant branding object.
 * @param staticTenantBranding - The tenant branding corresponding to the resource tenant.
 * @param dynamicTenantBranding - The tenant branding corresponding to the home tenant.
 * @param isGlobalTenant - Whether the resource tenant is the global tenant (common).
 * @param isCiamUserFlow - Whether the request is a CIAM user flow.
 * @param useDefaultCiamBranding - Whether to use the default CIAM branding.
 * @param fallbackToResourceBranding - Whether to fallback to resource tenant branding for properties inside the sign in box.
 * @returns Merged tenant branding object.
 */
export function getMergedTenantBranding(
  staticTenantBranding?: TenantBranding,
  dynamicTenantBranding?: TenantBranding,
  isGlobalTenant?: boolean,
  isCiamUserFlow?: boolean,
  useDefaultCiamBranding?: boolean,
  fallbackToResourceBranding?: boolean,
): LocalizedTenantBranding {
  let mergedTenantBranding: LocalizedTenantBranding;
  const localizedDynamicTenantBranding: LocalizedTenantBranding = getLocalizedTenantBranding(
    dynamicTenantBranding,
    useDefaultCiamBranding,
  );
  const localizedStaticTenantBranding: LocalizedTenantBranding = getLocalizedTenantBranding(
    staticTenantBranding,
    useDefaultCiamBranding,
  );

  if (isGlobalTenant) {
    // for the global tenant, all of the branding is dynamic
    mergedTenantBranding = localizedDynamicTenantBranding ?? {};
  } else {
    // For tenanted endpoints, the resource tenant branding is default,
    // but the branding properties inside the lightbox (BannerLogo, BoilerPlateText, KeepMeSignedInDisabled,
    // AccessRecoveryLink, CantAccessYourAccountText, ForgotPasswordText, hideAccountResetCredentials & CustomStrings)
    // should be assigned either home tenant branding or MS default values.
    mergedTenantBranding = localizedStaticTenantBranding ?? {};

    if (fallbackToResourceBranding) {
      if (localizedDynamicTenantBranding?.BannerLogo) {
        mergedTenantBranding.BannerLogo = localizedDynamicTenantBranding.BannerLogo;
      }

      if (localizedDynamicTenantBranding?.BoilerPlateText) {
        mergedTenantBranding.BoilerPlateText = localizedDynamicTenantBranding.BoilerPlateText;
      }

      if (localizedDynamicTenantBranding?.KeepMeSignedInDisabled) {
        mergedTenantBranding.KeepMeSignedInDisabled =
          localizedDynamicTenantBranding.KeepMeSignedInDisabled;
      }

      if (localizedDynamicTenantBranding?.AccessRecoveryLink) {
        mergedTenantBranding.AccessRecoveryLink = localizedDynamicTenantBranding.AccessRecoveryLink;
      }

      if (localizedDynamicTenantBranding?.CantAccessYourAccountText) {
        mergedTenantBranding.CantAccessYourAccountText =
          localizedDynamicTenantBranding.CantAccessYourAccountText;
      }

      if (localizedDynamicTenantBranding?.ForgotPasswordText) {
        mergedTenantBranding.ForgotPasswordText = localizedDynamicTenantBranding.ForgotPasswordText;
      }

      if (localizedDynamicTenantBranding?.ForgotPasswordText) {
        mergedTenantBranding.ForgotPasswordText = localizedDynamicTenantBranding.ForgotPasswordText;
      }
    } else {
      mergedTenantBranding.BannerLogo = localizedDynamicTenantBranding?.BannerLogo || "";
      mergedTenantBranding.BoilerPlateText = localizedDynamicTenantBranding?.BoilerPlateText || "";
      mergedTenantBranding.KeepMeSignedInDisabled =
        localizedDynamicTenantBranding?.KeepMeSignedInDisabled || false;
      mergedTenantBranding.AccessRecoveryLink =
        localizedDynamicTenantBranding?.AccessRecoveryLink || "";
      mergedTenantBranding.CantAccessYourAccountText =
        localizedDynamicTenantBranding?.CantAccessYourAccountText || "";
      mergedTenantBranding.ForgotPasswordText =
        localizedDynamicTenantBranding?.ForgotPasswordText || "";
    }

    let hideAccountResetCredentials = false;

    // If the home tenant has configured hideAccountResetCredentials, we will use that value.
    // Otherwise, use the value from resource tenant only if fallbackToResourceBranding is true.
    if (
      localizedDynamicTenantBranding?.LayoutTemplateConfig?.hideAccountResetCredentials !==
      undefined
    ) {
      hideAccountResetCredentials =
        localizedDynamicTenantBranding?.LayoutTemplateConfig?.hideAccountResetCredentials;
    } else if (fallbackToResourceBranding) {
      hideAccountResetCredentials =
        !!localizedStaticTenantBranding?.LayoutTemplateConfig?.hideAccountResetCredentials;
    }

    mergedTenantBranding.LayoutTemplateConfig =
      mergedTenantBranding.LayoutTemplateConfig ||
      createLayoutConfig(undefined, isCiamUserFlow, useDefaultCiamBranding);
    mergedTenantBranding.LayoutTemplateConfig.hideAccountResetCredentials =
      hideAccountResetCredentials;

    if (localizedDynamicTenantBranding?.CustomizationFiles) {
      // Create new customization files override object.
      const newCustomizationFiles = {
        strings: localizedDynamicTenantBranding.CustomizationFiles?.strings,
        customCssUrl: localizedDynamicTenantBranding.CustomizationFiles?.customCssUrl,
      };

      // If both resource and home tenants have configured custom CSS we won't use any
      // until there is a final agreement on which takes precedence.

      if (
        mergedTenantBranding?.CustomizationFiles?.customCssUrl !==
        newCustomizationFiles.customCssUrl
      ) {
        newCustomizationFiles.customCssUrl = undefined;
      }

      mergedTenantBranding.CustomizationFiles = newCustomizationFiles;
    }
  }

  return mergedTenantBranding;
}

/**
 * This method takes the various pieces of branding from ServerData along with the tenant branding overrides, and converts them into a branding interface.
 * @param serverData - window.ServerData from the IDP.
 * @param mergedTenantBranding - The merged tenant branding that combines home and resource tenant branding settings.
 * @returns Branding styles to use.
 */
export function getBrandingStyles(
  serverData: ServerData,
  mergedTenantBranding: LocalizedTenantBranding,
): Partial<BrandingStyles> {
  const {
    oAppCobranding: appCoBranding,
    iBackgroundImage: defaultImageIndex,
    fIsCiamUserFlowUx: isCiamUserFlow,
    fUseNonMicrosoftDefaultBrandingForCiam: useDefaultCiamBranding,
    sCompanyDisplayName: companyDisplayName,
  } = serverData;

  const layoutConfig = createLayoutConfig(
    mergedTenantBranding,
    isCiamUserFlow,
    useDefaultCiamBranding,
  );
  const pageBranding = getPageBranding(
    mergedTenantBranding,
    appCoBranding,
    defaultImageIndex,
    isCiamUserFlow,
    companyDisplayName,
    useDefaultCiamBranding,
  );
  return convertBranding(mergedTenantBranding, layoutConfig, pageBranding);
}

/**
 * @param property A property which may have app branding values
 * @returns {boolean} Whether the property is an object, number, string, or boolean
 */
export const checkAppBrandingProperties = function checkAppBrandingProperties(
  property: Object | number | string | boolean,
) {
  // Currently we only check for Objects, numbers, strings and booleans since these
  // are the only data types contained in AppBrandingInfo
  if (Object.prototype.toString.call(property) === "[object Object]") {
    return Object.values(property).some(checkAppBrandingProperties);
  }

  if (typeof property === "number") {
    return property >= 0;
  }

  // handle the remaining types - strings and booleans
  return !!property;
};
