import React from "react";
import { useCustomizationContext } from "../../../context/customization-context";
import { BackgroundImageFabric, useBackgroundStylesFabric } from "./background-image-fabric";

/**
 * Background component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const BackgroundFabric: React.FC = function BackgroundFabric() {
  const {
    customizationState: {
      styles: { backgroundColor, alwaysShowBackground, layoutTemplate },
    },
  } = useCustomizationContext();
  const backgroundStyles = useBackgroundStylesFabric(alwaysShowBackground, layoutTemplate);

  return (
    <div
      id="background-container"
      data-testid="background-container"
      className={backgroundStyles.background}
      role="presentation"
      style={{ backgroundColor }}
    >
      <BackgroundImageFabric />
    </div>
  );
};

export default BackgroundFabric;
