import { extractQueryStringParam } from "./strings-helper";

/**
 * Gets a list of query string parameter values from the current URL.
 * @param properties A dictionary of property names and their corresponding qu
 * @param urlOrQueryString A full URL in which to search or just the query string part including "?".
 * Defaults to `window.location.search`.
 * @returns A dictionary of property names and their corresponding query string parameter values
 */
export function extractQueryStringParams(
  properties: { [key: string]: string },
  urlOrQueryString?: string,
): {
  [key: string]: string;
} {
  const result: { [key: string]: string } = {};
  Object.entries(properties).forEach(([propName, queryName]) => {
    const value = extractQueryStringParam(queryName, urlOrQueryString);
    if (value) {
      result[propName] = value;
    }
  });
  return result;
}
