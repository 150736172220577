import { useContext, useState } from "react";
import { ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import { useNavigateDirection } from "../../../../../hooks";
import type { UserCredential } from "../../../../../model/credential/credential-types";
import { useTelemetry } from "../../../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../../../telemetry-helpers/user-action-name";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import { signInOptionsIcon } from "../../../../../utilities/image-helpers/accessible-images";
import LoginConfig from "../../../login-config";
import { getAvailableCredsWithoutUsername } from "../../../login-util";
import type { IUsernameViewSignInOptionsProperties } from "../username-view-interface";
import { type IUsernameViewStringsFabric } from "./username-view-strings-fabric";

/**
 * @returns sign-in options properties
 * @param usernameViewStrings Flavored strings that are specific to the username view
 */
export const useSignInOptionsPropertiesFabric = (
  usernameViewStrings: IUsernameViewStringsFabric,
): IUsernameViewSignInOptionsProperties => {
  const { signInOptionsText } = usernameViewStrings;

  // Config data
  const { showSignInOptionsAsButton, activeFlavor, telemetry } = GlobalConfig.instance;
  const {
    isFidoSupportedHint,
    showForgotUsernameLink,
    showOfflineAccount,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;
  const {
    globalState: { activeView, activeFlow },
  } = useContext(GlobalContext);

  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const navigate = useNavigateDirection();

  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  const credentials = getAvailableCredsWithoutUsername(isFidoSupported);

  const atLeastOneSignInOptionAvailable =
    credentials.length > 0 || showForgotUsernameLink || showOfflineAccount;
  const showCredsOnlyOnPicker = credentials.length === 1 && !!credentials[0].shownOnlyOnPicker;
  const showSignInOptions = atLeastOneSignInOptionAvailable || showCredsOnlyOnPicker;

  const [, setRequestPendingFlag] = useState<boolean>(false);
  const signInOptionsLinkProperties = !showSignInOptionsAsButton
    ? {
        sourceViewId: ViewId.Username,
        availableCredentials: credentials,
        // Since this is the username view, the user isn't known yet and the current credential type is undefined
        currentCredential: {} as UserCredential,
        // This isn't really used when rendering the sign-in options link but is a required property when rendering
        // the CredentialSwitchContentFabric component
        setRequestPendingFlag,
        showForgotUsername: showForgotUsernameLink,
      }
    : undefined;

  const signInOptionsButtonProperties = showSignInOptionsAsButton
    ? {
        image: { urls: signInOptionsIcon },
        text: signInOptionsText,
        onClick: () => {
          logUserAction({ actionName: UserActionName.SigninOptionsClicked });
          navigate(ViewId.Username, ViewId.SignInOptions);
        },
      }
    : undefined;

  return {
    showSignInOptions,
    signInOptionsLinkProperties,
    signInOptionsButtonProperties,
  };
};
