import {
  type RepMapMetadataAccount,
  ChallengeActionType,
  useChallengeContext,
} from "../../../challenge-context";
import { ApiNames } from "../../../constants";
import {
  type CreatePassportsRepMapErrorResponse,
  type CreatePassportsRepMapRequest,
  type SendOttRepMapErrorResponse,
  type SendOttRepMapRequest,
  type SendOttRepMapSuccessResponse,
} from "../challenge-types";

/**
 * @param apiData a record of data from SendOtt and CreateAccount APIs
 * @param apiName the api name in use for challenge scenarios
 * @returns the aggregated data from the api response
 */
export const errorResponseAggregator = (apiData: Record<string, unknown>, apiName?: ApiNames) => {
  const isJson = (input: string) => input && input[0] === "{";
  const error = (apiData?.error as Record<string, unknown>) || {};
  const errorData = (error?.data as string) || "";
  const jsonData = isJson(errorData) ? JSON.parse(errorData) : {};
  const result: Record<string, unknown> = {};

  // merge undefined paths between error.data and error
  Object.keys(jsonData).forEach((key: string) => {
    if (jsonData[key]) {
      result[key] = jsonData[key];
    }
  });
  Object.keys(error).forEach((key: string) => {
    // grab the prop, skip data prop since it was proccessed already
    if (error[key] && !(isJson(errorData) && key === "data")) {
      result[key] = error[key];
    }
  });

  /**
   * Invalidate the solved PhoneRep challenge score,
   * since server will rely on Risk Assessment Score here on
   */
  if (apiName === ApiNames.CreateAccount && result.phoneRepRiskScoreDetails) {
    result.phoneRepRiskScoreDetails = "";
  }

  return result;
};

/**
 * @returns handler for the Repmap success and failure responses on SendOtt and CreateAccount APIs
 */
export const useSaveRepMapResponse = () => {
  const { dispatchChallengeStateChange } = useChallengeContext();
  return (
    response: Partial<
      CreatePassportsRepMapErrorResponse & SendOttRepMapSuccessResponse & SendOttRepMapErrorResponse
    >,
  ) => {
    const repmapParams: Partial<RepMapMetadataAccount> = {};
    if (response.riskAssessmentDetails) {
      repmapParams.riskAssessmentDetails = response.riskAssessmentDetails;
    }

    if (response.phoneRepRiskScoreDetails) {
      repmapParams.phoneRepRiskScoreDetails = response.phoneRepRiskScoreDetails;
    }

    if (response.recommendedChallengeRiskScore) {
      repmapParams.recommendedChallengeRiskScore = response.recommendedChallengeRiskScore;
    }

    if (response.repMapRequestIdentifierDetails) {
      repmapParams.repMapRequestIdentifierDetails = response.repMapRequestIdentifierDetails;
    }

    if (response.dfpRequestId) {
      repmapParams.dfpRequestId = response.dfpRequestId;
    }

    if (response.encAttemptToken) {
      repmapParams.encAttemptToken = response.encAttemptToken;
    }

    dispatchChallengeStateChange({
      type: ChallengeActionType.SetRepMapMetadata,
      payload: { ...repmapParams },
    });
  };
};

/**
 * @returns handler for the Repmap params on SendOtt and CreateAccount APIs
 */
export const useBuildRepMapRequest = () => {
  const {
    challengeState: { repMapMetadata },
  } = useChallengeContext();

  return (apiName: ApiNames) => {
    const request: Partial<SendOttRepMapRequest & CreatePassportsRepMapRequest> = {};

    if (apiName === ApiNames.CreateAccount) {
      if (repMapMetadata.dfpRequestId) {
        request.dfpRequestId = repMapMetadata.dfpRequestId;
      }

      if (repMapMetadata.encAttemptToken) {
        request.encAttemptToken = repMapMetadata.encAttemptToken;
      }

      if (repMapMetadata.riskAssessmentDetails) {
        request.RiskAssessmentDetails = repMapMetadata.riskAssessmentDetails;
      }

      if (repMapMetadata.phoneRepRiskScoreDetails) {
        request.PhoneRepRiskScoreDetails = repMapMetadata.phoneRepRiskScoreDetails;
      }
    }

    if (apiName === ApiNames.SendOtt) {
      if (repMapMetadata.recommendedChallengeRiskScore) {
        request.RecommendedChallengeRiskScore = repMapMetadata.recommendedChallengeRiskScore;
      }
    }

    if (repMapMetadata.repMapRequestIdentifierDetails) {
      request.RepMapRequestIdentifierDetails = repMapMetadata.repMapRequestIdentifierDetails;
    }

    return request;
  };
};
