import appBackground_0_jpg from "../../images/AppBackgrounds/0.jpg";
import appBackground_1_jpg from "../../images/AppBackgrounds/1.jpg";
import appBackground_2_jpg from "../../images/AppBackgrounds/2.jpg";
import appBackground_3_jpg from "../../images/AppBackgrounds/3.jpg";
import appBackground_4_jpg from "../../images/AppBackgrounds/4.jpg";
import appBackground_5_jpg from "../../images/AppBackgrounds/5.jpg";
import appBackground_6_jpg from "../../images/AppBackgrounds/6.jpg";
import appBackground_7_jpg from "../../images/AppBackgrounds/7.jpg";
import appBackground_8_jpg from "../../images/AppBackgrounds/8.jpg";
import appBackground_9_jpg from "../../images/AppBackgrounds/9.jpg";
import appBackground_10_jpg from "../../images/AppBackgrounds/10.jpg";
import appBackground_11_jpg from "../../images/AppBackgrounds/11.jpg";
import appBackground_13_jpg from "../../images/AppBackgrounds/13.jpg";
import appBackground_14_jpg from "../../images/AppBackgrounds/14.jpg";
import appBackground_15_jpg from "../../images/AppBackgrounds/15.jpg";
import appBackground_16_jpg from "../../images/AppBackgrounds/16.jpg";
import appBackground_17_jpg from "../../images/AppBackgrounds/17.jpg";
import appBackground_18_jpg from "../../images/AppBackgrounds/18.jpg";
import appBackground_19_jpg from "../../images/AppBackgrounds/19.jpg";
import appBackground_20_jpg from "../../images/AppBackgrounds/20.jpg";
import appBackground_21_jpg from "../../images/AppBackgrounds/21.jpg";
import appBackground_22_jpg from "../../images/AppBackgrounds/22.jpg";
import appBackground_23_jpg from "../../images/AppBackgrounds/23.jpg";
import appBackground_24_jpg from "../../images/AppBackgrounds/24.jpg";
import appBackground_25_jpg from "../../images/AppBackgrounds/25.jpg";
import appBackground_26_jpg from "../../images/AppBackgrounds/26.jpg";
import appBackground_27_jpg from "../../images/AppBackgrounds/27.jpg";
import appBackground_28_jpg from "../../images/AppBackgrounds/28.jpg";
import appBackground_29_jpg from "../../images/AppBackgrounds/29.jpg";
import appBackground_30_jpg from "../../images/AppBackgrounds/30.jpg";
import appBackground_31_jpg from "../../images/AppBackgrounds/31.jpg";
import appBackground_32_jpg from "../../images/AppBackgrounds/32.jpg";
import appBackground_33_jpg from "../../images/AppBackgrounds/33.jpg";
import appBackground_34_jpg from "../../images/AppBackgrounds/34.jpg";
import appBackground_35_jpg from "../../images/AppBackgrounds/35.jpg";
import appBackground_36_jpg from "../../images/AppBackgrounds/36.jpg";
import appBackground_37_jpg from "../../images/AppBackgrounds/37.jpg";
import appBackground_38_jpg from "../../images/AppBackgrounds/38.jpg";
import appBackground_39_jpg from "../../images/AppBackgrounds/39.jpg";
import appBackground_40_jpg from "../../images/AppBackgrounds/40.jpg";
import appBackground_41_jpg from "../../images/AppBackgrounds/41.jpg";
import appBackground_42_jpg from "../../images/AppBackgrounds/42.jpg";
import appBackground_43_jpg from "../../images/AppBackgrounds/43.jpg";
import appBackground_44_jpg from "../../images/AppBackgrounds/44.jpg";
import appBackground_45_jpg from "../../images/AppBackgrounds/45.jpg";
import appBackground_46_jpg from "../../images/AppBackgrounds/46.jpg";
import appBackground_47_jpg from "../../images/AppBackgrounds/47.jpg";
import appBackground_48_jpg from "../../images/AppBackgrounds/48.jpg";
import appBackground_49_jpg from "../../images/AppBackgrounds/49.jpg";
import appBackground_50_jpg from "../../images/AppBackgrounds/50.jpg";
import appBackground_51_jpg from "../../images/AppBackgrounds/51.jpg";
import appBackground_52_jpg from "../../images/AppBackgrounds/52.jpg";
import appBackground_53_jpg from "../../images/AppBackgrounds/53.jpg";
import appBackground_54_jpg from "../../images/AppBackgrounds/54.jpg";
import appBackground_55_jpg from "../../images/AppBackgrounds/55.jpg";
import appBackground_56_jpg from "../../images/AppBackgrounds/56.jpg";
import appBackground_57_jpg from "../../images/AppBackgrounds/57.jpg";
import appBackground_58_jpg from "../../images/AppBackgrounds/58.jpg";
import appBackground_59_jpg from "../../images/AppBackgrounds/59.jpg";
import appBackground_60_jpg from "../../images/AppBackgrounds/60.jpg";
import appBackground_61_jpg from "../../images/AppBackgrounds/61.jpg";
import appBackground_62_jpg from "../../images/AppBackgrounds/62.jpg";
import appBackground_63_jpg from "../../images/AppBackgrounds/63.jpg";
import appBackground_64_jpg from "../../images/AppBackgrounds/64.jpg";
import appBackground_65_jpg from "../../images/AppBackgrounds/65.jpg";
import appBackground_66_jpg from "../../images/AppBackgrounds/66.jpg";
import appBackground_67_jpg from "../../images/AppBackgrounds/67.jpg";
import appBackground_68_jpg from "../../images/AppBackgrounds/68.jpg";
import appBackground_69_jpg from "../../images/AppBackgrounds/69.jpg";
import appBackground_70_jpg from "../../images/AppBackgrounds/70.jpg";
import appBackground_71_jpg from "../../images/AppBackgrounds/71.jpg";
import appBackground_72_jpg from "../../images/AppBackgrounds/72.jpg";
import appBackground_73_jpg from "../../images/AppBackgrounds/73.jpg";
import appBackground_74_jpg from "../../images/AppBackgrounds/74.jpg";
import appBackground_75_jpg from "../../images/AppBackgrounds/75.jpg";
import appBackground_76_jpg from "../../images/AppBackgrounds/76.jpg";

export const appBackgrounds = new Map<string, string>([
  ["appBackground_0_jpg", appBackground_0_jpg],
  ["appBackground_1_jpg", appBackground_1_jpg],
  ["appBackground_2_jpg", appBackground_2_jpg],
  ["appBackground_3_jpg", appBackground_3_jpg],
  ["appBackground_4_jpg", appBackground_4_jpg],
  ["appBackground_5_jpg", appBackground_5_jpg],
  ["appBackground_6_jpg", appBackground_6_jpg],
  ["appBackground_7_jpg", appBackground_7_jpg],
  ["appBackground_8_jpg", appBackground_8_jpg],
  ["appBackground_9_jpg", appBackground_9_jpg],
  ["appBackground_10_jpg", appBackground_10_jpg],
  ["appBackground_11_jpg", appBackground_11_jpg],
  ["appBackground_13_jpg", appBackground_13_jpg],
  ["appBackground_14_jpg", appBackground_14_jpg],
  ["appBackground_15_jpg", appBackground_15_jpg],
  ["appBackground_16_jpg", appBackground_16_jpg],
  ["appBackground_17_jpg", appBackground_17_jpg],
  ["appBackground_18_jpg", appBackground_18_jpg],
  ["appBackground_19_jpg", appBackground_19_jpg],
  ["appBackground_20_jpg", appBackground_20_jpg],
  ["appBackground_21_jpg", appBackground_21_jpg],
  ["appBackground_22_jpg", appBackground_22_jpg],
  ["appBackground_23_jpg", appBackground_23_jpg],
  ["appBackground_24_jpg", appBackground_24_jpg],
  ["appBackground_25_jpg", appBackground_25_jpg],
  ["appBackground_26_jpg", appBackground_26_jpg],
  ["appBackground_27_jpg", appBackground_27_jpg],
  ["appBackground_28_jpg", appBackground_28_jpg],
  ["appBackground_29_jpg", appBackground_29_jpg],
  ["appBackground_30_jpg", appBackground_30_jpg],
  ["appBackground_31_jpg", appBackground_31_jpg],
  ["appBackground_32_jpg", appBackground_32_jpg],
  ["appBackground_33_jpg", appBackground_33_jpg],
  ["appBackground_34_jpg", appBackground_34_jpg],
  ["appBackground_35_jpg", appBackground_35_jpg],
  ["appBackground_36_jpg", appBackground_36_jpg],
  ["appBackground_37_jpg", appBackground_37_jpg],
  ["appBackground_38_jpg", appBackground_38_jpg],
  ["appBackground_39_jpg", appBackground_39_jpg],
  ["appBackground_40_jpg", appBackground_40_jpg],
  ["appBackground_41_jpg", appBackground_41_jpg],
  ["appBackground_42_jpg", appBackground_42_jpg],
  ["appBackground_43_jpg", appBackground_43_jpg],
  ["appBackground_44_jpg", appBackground_44_jpg],
  ["appBackground_45_jpg", appBackground_45_jpg],
  ["appBackground_46_jpg", appBackground_46_jpg],
  ["appBackground_47_jpg", appBackground_47_jpg],
  ["appBackground_48_jpg", appBackground_48_jpg],
  ["appBackground_49_jpg", appBackground_49_jpg],
  ["appBackground_50_jpg", appBackground_50_jpg],
  ["appBackground_51_jpg", appBackground_51_jpg],
  ["appBackground_52_jpg", appBackground_52_jpg],
  ["appBackground_53_jpg", appBackground_53_jpg],
  ["appBackground_54_jpg", appBackground_54_jpg],
  ["appBackground_55_jpg", appBackground_55_jpg],
  ["appBackground_56_jpg", appBackground_56_jpg],
  ["appBackground_57_jpg", appBackground_57_jpg],
  ["appBackground_58_jpg", appBackground_58_jpg],
  ["appBackground_59_jpg", appBackground_59_jpg],
  ["appBackground_60_jpg", appBackground_60_jpg],
  ["appBackground_61_jpg", appBackground_61_jpg],
  ["appBackground_62_jpg", appBackground_62_jpg],
  ["appBackground_63_jpg", appBackground_63_jpg],
  ["appBackground_64_jpg", appBackground_64_jpg],
  ["appBackground_65_jpg", appBackground_65_jpg],
  ["appBackground_66_jpg", appBackground_66_jpg],
  ["appBackground_67_jpg", appBackground_67_jpg],
  ["appBackground_68_jpg", appBackground_68_jpg],
  ["appBackground_69_jpg", appBackground_69_jpg],
  ["appBackground_70_jpg", appBackground_70_jpg],
  ["appBackground_71_jpg", appBackground_71_jpg],
  ["appBackground_72_jpg", appBackground_72_jpg],
  ["appBackground_73_jpg", appBackground_73_jpg],
  ["appBackground_74_jpg", appBackground_74_jpg],
  ["appBackground_75_jpg", appBackground_75_jpg],
  ["appBackground_76_jpg", appBackground_76_jpg],
]);
