import React, { useMemo, useReducer } from "react";
import {
  type ChallengeActions,
  type ChallengeState,
  type IChallengeContext,
  ChallengeActionType,
  ChallengeContext,
} from "./challenge-context";
import { HipType } from "./constants";
import {
  FraudProtectionChallengeType,
  FraudProtectionChallengeTypeToHipTypeMapping,
} from "./model";

/**
 * Challenge state reducer
 * @param state the current challenge state
 * @param action the challenge data action
 * @returns the updated challenge state
 */
export default function challengeReducer(
  state: ChallengeState,
  action: ChallengeActions,
): ChallengeState {
  switch (action.type) {
    case ChallengeActionType.SetRepMapMetadata: {
      return {
        ...state,
        repMapMetadata: {
          ...state.repMapMetadata,
          ...action.payload,
        },
      };
    }

    case ChallengeActionType.SetArkoseAction: {
      const {
        dataExchangeBlob = state.arkose.dataExchangeBlob,
        phoneRepMetadata = state.phoneRepMetadata,
      } = action.payload;
      return {
        ...state,
        type: HipType.Enforcement,
        errorMessage: "",
        phoneRepMetadata,
        arkose: {
          ...state.arkose,
          dataExchangeBlob,
        },
      };
    }

    case ChallengeActionType.SetHipAction: {
      const { phoneRepMetadata = state.phoneRepMetadata } = action.payload;
      return {
        ...state,
        type: HipType.Visual,
        phoneRepMetadata,
        errorMessage: "",
        hip: {
          ...state.hip,
        },
      };
    }

    case ChallengeActionType.SetNativeHipAction: {
      const { challengeType = FraudProtectionChallengeType.visual } = action.payload;
      return {
        ...state,
        type: FraudProtectionChallengeTypeToHipTypeMapping[challengeType],
        errorMessage: "",
        nativeHip: {
          ...state.nativeHip,
          ...action.payload,
        },
      };
    }

    case ChallengeActionType.SetAbuseBlockAction: {
      const { phoneRepMetadata = state.phoneRepMetadata } = action.payload;
      return {
        ...state,
        errorMessage: "",
        type: HipType.Block,
        phoneRepMetadata,
      };
    }

    case ChallengeActionType.SetCommonAction: {
      const {
        type = state.type,
        scenarioId = state.scenarioId,
        phoneRepMetadata = state.phoneRepMetadata,
        errorMessage = state.errorMessage,
        apiName = state.apiName,
      } = action.payload;
      return {
        ...state,
        type,
        scenarioId,
        phoneRepMetadata,
        errorMessage,
        apiName,
      };
    }

    default:
      throw new Error(`challengeReducer received unexpected action ${action}`);
  }
}

/**
 * Initiates the challenge state and provides the app with access
 * to all of the challengeProperties in the reducer
 * @param props the props of the provider
 * @param props.initialState the initial state passed to the provider
 * @param props.children the elements inside the provider
 * @returns the provider
 */
export const ChallengeProvider: React.FC<{ initialState: ChallengeState }> =
  function ChallengeProvider({ initialState, children }) {
    const [state, dispatch] = useReducer(challengeReducer, initialState);
    const value: IChallengeContext = useMemo(
      () => ({
        challengeState: state,
        dispatchChallengeStateChange: dispatch,
      }),
      [state],
    );

    return <ChallengeContext.Provider value={value}>{children}</ChallengeContext.Provider>;
  };
