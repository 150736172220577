import { type FormEvent } from "react";
import { type InputState } from "../../../../../components/inputs/hooks/use-input";
import { ViewId } from "../../../../../constants";
import { useGlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { trimQsParams } from "../../../../../utilities/strings-helper";

/**
 * Hook that returns the onSubmit handler for password submission.
 * @param postUrl URL to navigate to
 * @param inputState Input state for the password input
 * @param options Options for handling password submission
 * @param options.hostLoginAction A function for implementing host-specific functionality upon successful sign-in
 * @param options.keepQsParams Whether to include the query string parameters when logging the post URL to IDUX telemetry
 * @returns The onSubmit handler for the sign in button on password view
 */
export const usePasswordHandler = (
  postUrl: string,
  inputState: InputState,
  options?: {
    hostLoginAction?: (event?: FormEvent<HTMLFormElement>) => void;
    keepQsParams?: boolean;
  },
) => {
  const {
    setFocus,
    error: { setShowErrorMessage, validationError, serverError },
    setUserHasSubmitted,
    elementRef,
  } = inputState;

  const {
    globalState: { showProgressIndicator: isRequestPending },
    dispatchStateChange: dispatchGlobal,
  } = useGlobalContext();

  return (event: FormEvent<HTMLFormElement>) => {
    setUserHasSubmitted(true);
    setShowErrorMessage(true);

    if (elementRef) {
      elementRef?.current?.focus();
    } else {
      setFocus(true);
    }

    // Block submission only when there is a client-side or server-side error or if an existing
    // request is pending.
    const blockSubmission = validationError || serverError || isRequestPending;
    if (blockSubmission) {
      event.preventDefault();
      return;
    }

    // After validation has been completed and submission has not been blocked, complete host login
    // actions (e.g. CXH) and begin redirection to the password post url
    if (options?.hostLoginAction) {
      options.hostLoginAction(event);
    }

    dispatchGlobal({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.Password,
      destination: options?.keepQsParams ? postUrl : trimQsParams(postUrl),
    });
  };
};
