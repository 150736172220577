import { initFooterConfig } from "../../components/footer/footer-config";
import { type FooterConfigOverrides } from "../../components/footer/footer-config-overrides";
import { type Flavors } from "../../constants";
import { initFeaturesConfig } from "../../features-config";
import { initGlobalConfig } from "../../global-config";
import { type ServerData } from "../../utilities/server-data";

/**
 * Parse the ServerData object sent from the IDP and return the initial config for common configs.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The IDP-specific ServerData object sent from the server and read from the window object.
 * @param activeFlavor Rendered flavor
 * @param isHistorySupported Whether the flow supports history context or not **This is a workaround for a
 *    known issue. This should be set to TRUE unless it is explicitly known that the flow does not support history.**
 * @param footerOverrides Optional overrides for the footer URLs.
 */

export const initBaseSisuConfig = (
  serverData: ServerData,
  activeFlavor: Flavors,
  isHistorySupported = true,
  footerOverrides?: FooterConfigOverrides,
) => {
  // initialize global configs
  initGlobalConfig(serverData, activeFlavor, { isHistorySupported });
  initFeaturesConfig(serverData);
  initFooterConfig(serverData, footerOverrides);
};
