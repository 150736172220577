import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { isHighContrast } from "../../../utilities/browser-helper";
import { type IButtonProps } from "../button-interface-fabric";
import { useSetFocusWithDelay } from "../hooks/use-set-focus-with-delay";
import { type IButtonFabricProps } from "./button-fabric-interface";

export type ButtonFabricProps = IButtonProps & IButtonFabricProps;

/**
 * Button component for fabric
 * @param props The properties for this component
 * @param forwardRef Optionally pass down a reference to a DOM element created by a parent component. This enables the parent to modify actions, such as setting focus on the element.
 * @returns an instance of the shared button component
 */
export const ButtonFabric = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonFabricProps>
>((props, forwardRef) => {
  const { children, ...buttonParams } = props;
  const {
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    ariaExpanded,
    ariaHasPopup,
    ariaRole,
    ariaActiveDescendant,
    ariaControls,
    buttonId,
    dataTestId,
    disabled = false,
    onClick,
    className,
    style = {},
    hasFocus = false,
    type = "button",
    onMouseOver = () => {},
    onMouseOut = () => {},
    onFocus = () => {},
    onBlur = () => {},
    highContrastClassName,
  } = buttonParams;
  const { highContrast } = StylesConfig.instance.useButtonStyles();
  const buttonStyles = isHighContrast()
    ? mergeClasses(className, highContrast, highContrastClassName)
    : className;
  const elementReference = useSetFocusWithDelay(forwardRef, hasFocus);
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
      aria-controls={ariaControls}
      role={ariaRole}
      aria-activedescendant={ariaActiveDescendant}
      ref={forwardRef ?? elementReference}
      id={buttonId}
      data-testid={dataTestId}
      className={buttonStyles}
      style={style}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
    </button>
  );
});
