import { useEffect } from "react";

interface UseExternalScriptsParams {
  url: string;
  elementId?: string;
}

/**
 * Hook to load external scripts. Used in the HipChallenge component as an example of external
 * script loading.
 * @param params params for the hook
 * @param params.url url of the script to load
 * @param params.elementId element id where script should be added, if empty head will be used
 */
export const useExternalScripts = ({ url, elementId }: UseExternalScriptsParams) => {
  useEffect(() => {
    const container = elementId
      ? document.getElementById(elementId)
      : document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", url);
    container?.appendChild(script);

    return () => {
      script.parentElement?.removeChild?.(script);
    };
  }, [url, elementId]);
};
