import { type InputValue } from "../../../../../components/inputs/input/input-types";
import GlobalConfig from "../../../../../global-config";
import { isEmailAddress } from "../../../../../model/alias";
import { type OneTimeCodeCredential } from "../../../../../model/credential";
import { ProofType } from "../../../../../model/proof";
import { isCredentialEmailProofType } from "../../../../../utilities/credential-helper";
import { trim } from "../../../../../utilities/strings-helper";
import { PhoneNumberConfirmationLength } from "../../../login-constants";
import { type IProofConfirmationStrings } from "../proof-confirmation-interface";
import { getInvalidProofError } from "../proof-confirmation-view-util";

/**
 * If the proof tied to the one-time code credential is an email, we check that
 * 1. the user input value is a valid email address (cannot contain spaces, must have a domain etc.) and
 * 2. the first two characters of the user input value matches the clear digits we received from the server
 *
 * If the proof tied to the one-time code credential is a phone number, we check that
 * 1. the user input value is a number containing 4 digits
 * 2. the last two characters of the user input value matches the clear digits we received from the server
 *
 * @param otcCredential the preferred one time code credential for this user
 * @param proofConfirmationStrings strings for Proof Confirmation view
 * @returns a function that validates the value entered into the input element rendered in the proof confirmation view
 * and returns the error string to display to the user (null if all validation checks pass)
 */
export const useInputValidationFunction = (
  otcCredential: OneTimeCodeCredential,
  proofConfirmationStrings: IProofConfirmationStrings,
) => {
  const {
    proof: { clearDigits = "", type },
  } = otcCredential;

  const isEmailProof = isCredentialEmailProofType(otcCredential);
  const invalidProofError = getInvalidProofError(
    isEmailProof,
    clearDigits,
    proofConfirmationStrings,
  );

  return (input: InputValue): string => {
    const { environment } = GlobalConfig.instance;

    let proofConfirmation = String(input) || "";

    if (proofConfirmation) {
      proofConfirmation = trim(proofConfirmation);
    }

    if (proofConfirmation === "") {
      return invalidProofError;
    }

    if (["int", "onebox"].includes(environment.toLowerCase())) {
      if (proofConfirmation === "0") {
        return "";
      }
    }

    if (type === ProofType.Email) {
      // If email, verify it's valid
      if (
        !isEmailAddress(proofConfirmation) ||
        proofConfirmation.substring(0, clearDigits.length).toLowerCase() !==
          clearDigits.toLowerCase()
      ) {
        return invalidProofError;
      }
    } else if (
      Number.isNaN(proofConfirmation) ||
      proofConfirmation.length !== PhoneNumberConfirmationLength ||
      proofConfirmation.substring(proofConfirmation.length - 2) !== clearDigits
    ) {
      // If phone, verify it's a valid number of length 4
      return invalidProofError;
    }

    return "";
  };
};
