import React from "react";
import { DEFAULT_HIP_CONFIG } from "./hooks/use-hip-content";

export interface HipContentProps {
  styles?: string;
}

/**
 * Renders captcha provided by Microsoft HIP Challenge-service.
 * This component should be used through captcha-component, and should not be used directly.
 * @param props HipContent properties
 * @param props.styles class name for hip content style
 * @returns Visual tree to render and interact with captcha provided by HIP Challenge-service
 */
export const HipChallengeContent = function HipChallengeContent({ styles }: HipContentProps) {
  const { holder: containerId, scriptHolder: scriptId } = DEFAULT_HIP_CONFIG;

  /// Two hidden div are required to load hip content and hip script. hipContent and hipScript.
  /// The following UI elements are required to render HIP:
  /// #hipImageContainer : This is the holder for hip image
  /// #hipSection input : This is the hip solution input.
  return (
    <>
      <div className={styles} id={containerId} data-testid={containerId} />
      <div id={scriptId} data-testid={scriptId} />
    </>
  );
};
