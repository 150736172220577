import { makeStyles, shorthands } from "@griffel/react";
import { isIENewerThan10 } from "../../utilities/browser-helper";
import { getImageSource } from "../../utilities/images-helper";
import * as styleConstants from "./input-constants-fabric.styles";

export const useCheckboxStylesFabric = makeStyles({
  checkbox: {
    marginTop: "12px",
    position: "relative",
    display: "block",
    "& input[type='checkbox']": {
      position: "absolute",
      marginLeft: "-28px",
    },
    ":global([dir='rtl'])": {
      "& input[type='checkbox']": {
        marginRight: "-28px",
      },
      "& label": {
        paddingRight: "28px",
      },
    },
    "& label": {
      minHeight: "20px",
      paddingLeft: "28px",
      cursor: "pointer",
      display: "inline-block",
      maxWidth: "100%",
    },
  },
});

export type CheckboxStylesFabric = ReturnType<typeof useCheckboxStylesFabric>;

export const useTextInputStylesFabric = makeStyles({
  textbox: {
    width: "100%",
    position: "relative",
    display: "block",
    "& input": {
      height: `${styleConstants.HEIGHT}`,
      width: "100%",
      outlineStyle: "none",
      backgroundColor: "transparent",
      ...shorthands.borderStyle(`${styleConstants.BORDER_STYLE}`),
      ...shorthands.borderRadius(`${styleConstants.BORDER_RADIUS}`),
      ...shorthands.borderWidth(
        `${styleConstants.BORDER_WIDTH}`,
        `${styleConstants.BORDER_WIDTH}`,
        `${styleConstants.BORDER_BOTTOM_WIDTH}`,
        `${styleConstants.BORDER_WIDTH}`,
      ),
      ...shorthands.borderColor(`${styleConstants.BORDER_COLOR}`),
      ...shorthands.padding(
        `${styleConstants.PADDING_VERTICAL}`,
        `${styleConstants.PADDING_RIGHT}`,
        `${styleConstants.PADDING_VERTICAL}`,
        `${styleConstants.PADDING_LEFT}`,
      ),
      // @TODO: Once Edge browser is available for integration testing, disable other browsers and remove this check
      ...(isIENewerThan10()
        ? {
            "::-ms-reveal": {
              height: "100%",
              ...shorthands.padding("4px", "8px"),
              marginLeft: "4px",
              marginRight: "-8px",
              ":hover": { color: styleConstants.BORDER_COLOR_FOCUS },
              ":active": { color: "#FFF", backgroundColor: styleConstants.BORDER_COLOR_FOCUS },
            },
          }
        : {}),
    },
    "& input:hover": {
      ...shorthands.borderColor(styleConstants.BORDER_COLOR_HOVER),
    },
    "& input:focus": {
      ...shorthands.borderColor(styleConstants.BORDER_COLOR_FOCUS),
    },
    "& input.has-error": {
      ...shorthands.borderColor(styleConstants.BORDER_COLOR_FOCUS_HAS_ERROR),
      // @TODO: Once Edge browser is available for integration testing, disable other browsers and remove this check
      ...(isIENewerThan10()
        ? {
            "::-ms-reveal": {
              ":hover": { color: styleConstants.REVEAL_ICON_ERROR_COLOR },
              ":active": {
                color: "#FFFFFF",
                backgroundColor: styleConstants.REVEAL_ICON_ERROR_COLOR,
              },
            },
          }
        : {}),
    },
  },
  inlineInput: {
    "@media only screen and (max-width: 320px)": { marginBottom: "8px" },
  },
  withInlineComponentWrapper: {
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 320px)": { flexDirection: "column" },
  },
  emailInputDomainWrapper: {
    display: "flex",
    alignItems: "end",
    marginBottom: "16px",
    marginLeft: "8px",
    whiteSpace: "nowrap",
    ":global([dir='rtl'])": {
      marginLeft: "unset",
      marginRight: "8px",
    },
    "@media only screen and (max-width: 320px)": {
      width: "100%",
      ":global([dir='rtl'])": {
        marginRight: "0px",
      },
      ":global([dir='ltr'])": {
        marginLeft: "0px",
      },
    },
  },
  emailInputDomainSpan: {
    height: `${styleConstants.HEIGHT}`,
    ...shorthands.borderBottom(
      styleConstants.BORDER_FOCUS_WIDTH,
      "solid",
      styleConstants.BORDER_COLOR,
    ),
    ...shorthands.padding(
      "8px",
      `${styleConstants.PADDING_RIGHT}`,
      `${styleConstants.PADDING_VERTICAL}`,
      `${styleConstants.PADDING_LEFT}`,
    ),
  },
  emailInputDomainSelect: {
    height: `${styleConstants.HEIGHT}`,
    ...shorthands.borderBottom(
      styleConstants.BORDER_FOCUS_WIDTH,
      "solid",
      styleConstants.BORDER_COLOR,
    ),
    MozAppearance: "none",
    WebkitAppearance: "none",
    appearance: "none",
    backgroundImage: `url("${getImageSource("dropdown_caret", "svg")}")`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    backgroundPositionY: "8px",
    backgroundSize: "24px",
    paddingRight: "20px !important",
    paddingLeft: "5px !important",
    ":focus": {
      backgroundColor: `${styleConstants.BACKGROUND_COLOR_FOCUS}`,
      ...shorthands.borderWidth(`${styleConstants.BORDER_FOCUS_WIDTH}`),
      ...shorthands.borderColor(`${styleConstants.BORDER_COLOR_FOCUS}`),
    },
    ":global([dir='rtl'])": {
      paddingLeft: "20px !important",
      paddingRight: "5px !important",
      backgroundPositionX: "left",
    },
    "@media only screen and (max-width: 320px)": {
      paddingLeft: "0px !important",
      ":global([dir='rtl'])": {
        paddingRight: "0px !important",
      },
    },
  },
  maskedInput: {
    "-webkit-text-security": "disc",
  },
});

export type TextInputStylesFabric = ReturnType<typeof useTextInputStylesFabric>;

export const useRadioButtonStylesFabric = makeStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "6px",
    marginBottom: "12px",
    cursor: "pointer",
    "& > *": {
      cursor: "pointer",
    },
  },
  hiddenContainer: {
    display: "none",
  },
  button: {
    width: "20px",
    height: "20px",
    color: "blue",
  },
  label: {
    marginLeft: "8px",
    marginRight: "8px",
  },
  disabledLabel: {
    color: styleConstants.DISABLED_LABEL,
  },
});

export type RadioButtonStylesFabric = ReturnType<typeof useRadioButtonStylesFabric>;
