import { NonRetryableError, RetryableError } from "../custom-errors";
import { postApiRequest } from "../request/request-helper";
import {
  type ISessionPollingProps,
  type SessionResponse,
  AuthorizationState,
  updateSessionPollingUrl,
} from "./session-polling-util";
import { usePolling } from "./use-polling";

export const useSessionPolling = (props: ISessionPollingProps) => {
  const {
    pollingUrl,
    onSessionApproved,
    onSessionTimeout,
    onSessionDenied = () => {
      throw NonRetryableError("notImplemented");
    },
    onSessionError = onSessionTimeout,
    onSessionVisited = () => {
      throw NonRetryableError("notImplemented");
    },
    maxPollingErrors,
    pollingIntervalMs,
    pollingTimeoutMs,
    sessionLookupKey,
    sessionLookupKeyType,
  } = props;

  const updatedPollingUrl = updateSessionPollingUrl(
    pollingUrl,
    sessionLookupKey,
    sessionLookupKeyType,
  );

  const body = sessionLookupKey ? JSON.stringify({ DeviceCode: sessionLookupKey }) : null;

  const { startPolling, stopPolling } = usePolling({
    maxPollingErrors,
    pollingIntervalMs,
    pollingTimeoutMs,
  });

  /**
   *  startPolling initiates the polling interval
   * @returns a Promise
   */
  async function startSessionPoll() {
    const sessionUpdateResponse = async () => {
      const res: SessionResponse = await postApiRequest(updatedPollingUrl, { body });
      return res;
    };

    const getSessionState = async () => {
      const result = await sessionUpdateResponse();

      if (!result || result.AuthorizationState === undefined) {
        throw NonRetryableError("noResponse");
      }

      switch (result.AuthorizationState) {
        case AuthorizationState.Pending:
        case AuthorizationState.LPTConsumed:
          throw new RetryableError("pending");

        case AuthorizationState.Approved:
          onSessionApproved();
          return;

        case AuthorizationState.Visited:
          if (result.Code) {
            onSessionVisited(result.Code);
          } else {
            onSessionError();
          }

          return;

        case AuthorizationState.Declined:
          onSessionDenied();
          return;

        case AuthorizationState.Invalid:
        default:
          throw new RetryableError("invalidSession");
      }
    };

    try {
      stopPolling();
      await startPolling(getSessionState);
    } catch (err) {
      if (err instanceof NonRetryableError) {
        onSessionTimeout();
      } else {
        onSessionError();
      }
    }
  }

  return { startPolling: startSessionPoll, stopPolling };
};
