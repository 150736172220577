import { useFormSubmission } from "../../../../../../components/inputs/hooks/use-form-submission";
import { useUsernameInputValidation } from "../../../../../../components/inputs/input/username/use-username-input-validation";
import { type FlowId, ViewId } from "../../../../../../constants";
import {
  type ActivateViewOptions,
  useActivateViewWithDocumentTitle,
} from "../../../../../../hooks";
import { useInputWithServerDataErrorsFabric } from "../../../../hooks/use-input-with-server-data-errors-fabric";
import { type LoginInputValidationErrorStrings } from "../../../../login-interface";
import { useGct } from "../../hooks/use-gct";

export type UseUsernameViewPropsFabric = {
  strings: {
    documentTitle: string;
    errorStringInvalidMemberName: string;
    errorStringInvalidPhoneNumber: string;
    loginInputValidationErrorStrings: LoginInputValidationErrorStrings;
  };
  activateViewOptions: ActivateViewOptions;
  activeFlowId: FlowId;
  getErrorMessage: (unsafeUsername: string, gctError: string) => JSX.Element;
  allowPhone?: boolean;
  allowSkype?: boolean;
  username?: string;
};

export const useUsernameViewFabric = (props: UseUsernameViewPropsFabric) => {
  const activeViewId = ViewId.Username;

  const {
    activateViewOptions,
    activeFlowId,
    getErrorMessage,
    allowPhone = false,
    allowSkype = false,
    username = "",
    strings: {
      documentTitle,
      errorStringInvalidMemberName,
      errorStringInvalidPhoneNumber,
      loginInputValidationErrorStrings,
    },
  } = props;

  useActivateViewWithDocumentTitle(documentTitle, activeViewId, activeFlowId, activateViewOptions);

  const validationMethod = useUsernameInputValidation({
    allowSkype,
    allowPhone,
    errorStringInvalidMemberName,
    errorStringInvalidPhoneNumber,
  });

  const inputState = useInputWithServerDataErrorsFabric({
    hasInitialFocus: true,
    validationMethod,
    initialValue: username,
    loginInputValidationErrorStrings,
  });

  const onSubmit = useFormSubmission({
    inputState,
    viewId: activeViewId,
    submitTask: useGct({
      getErrorMessage,
      viewId: activeViewId,
    }),
  });

  const { value } = inputState;

  const usernameString = String(value);

  return { inputState, onSubmit, usernameString };
};
