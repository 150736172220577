import React, { useContext, useRef } from "react";
import { ExperimentFeature, TreatmentFlight } from "../../../config/experiments";
import StylesConfig from "../../../config/styles-config";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { useExperiment, useNavigateOrRedirectBack } from "../../../hooks";
import { isCssAnimationSupported } from "../../../utilities/browser-helper";
import {
  type IBackArrowButtonPropsFabric,
  BackArrowButtonFabric,
} from "../../back-arrow-button/fabric/back-arrow-button-fabric";
import { useRouteAnimationFabric } from "../../route-animation/fabric/hooks/use-route-animation-fabric";

export type IIdentityBannerProps = {
  /** The props for the back arrow button */
  backArrowButtonProps?: Partial<IBackArrowButtonPropsFabric>;
};

/**
 * React IdentityBanner component
 * @param props IIdentityBannerProps
 * @returns an instance of the shared identity banner component
 */
const IdentityBannerFabric: React.FC<IIdentityBannerProps> = function IdentityBannerFabric(props) {
  const { useIdentityBannerStyles } = StylesConfig.instance;
  const { backArrowButtonProps = {} } = props;
  const { buttonId, ariaDescribedBy, hasFocus } = backArrowButtonProps;

  const { identityBanner, identityBannerResponsive } = useIdentityBannerStyles();
  const {
    globalState: { user, showBackButtonOnActiveView, backButtonHandler },
  } = useContext(GlobalContext);

  const { onAnimationEnd, fadeInOnceAnimationStyles } = useRouteAnimationFabric();
  const animationClassName = isCssAnimationSupported() ? fadeInOnceAnimationStyles : "";

  const backArrowButtonRef = useRef<HTMLButtonElement>(null);
  const { isRtlMarket } = GlobalConfig.instance;

  const defaultClickHandler = useNavigateOrRedirectBack();

  const { treatment } = useExperiment(ExperimentFeature.EnableIdentityBannerResponsiveExperiment);
  const useResponsiveSizing =
    treatment === TreatmentFlight.EnableIdentityBannerResponsiveExperimentTreatment;

  return (
    <div
      data-testid="identityBanner"
      className={animationClassName}
      onAnimationEnd={onAnimationEnd}
    >
      {showBackButtonOnActiveView && (
        <BackArrowButtonFabric
          ref={backArrowButtonRef}
          buttonId={buttonId}
          ariaDescribedBy={ariaDescribedBy}
          hasFocus={hasFocus}
          isRtlMarket={isRtlMarket}
          onClick={backButtonHandler || defaultClickHandler}
        />
      )}
      <div
        id="userDisplayName"
        className={useResponsiveSizing ? identityBannerResponsive : identityBanner}
      >
        {user.displayUsername.unsafeUnescapedString}
      </div>
    </div>
  );
};

export default IdentityBannerFabric;
