import { useState } from "react";
import GlobalConfig from "../../global-config";
import { useLoadTelemetry } from "../../hooks/use-load-telemetry";
import { useOnPopStateEventListener } from "../../hooks/use-on-pop-state";
import { ExceptionHelper } from "../../telemetry-helpers/exception-helper";
import { useTelemetryState } from "../../telemetry-helpers/use-telemetry-state";
import { useUnhandledRejection } from "../../telemetry-helpers/use-unhandled-rejection";

/**
 * Common logic for the shell components
 * @returns Common properties used by the shell
 */
export const useShell = () => {
  const [isPageBlank, setIsPageBlank] = useState(false);

  const { bannerEnvironment } = GlobalConfig.instance;

  const telemetryState = useTelemetryState();
  useUnhandledRejection(telemetryState); // Set up the unhandled rejection handler window event listener
  useOnPopStateEventListener();

  /** @private */
  function checkPageBlank() {
    const coreElement = document.getElementById("outer");

    if (
      coreElement?.offsetHeight === undefined ||
      coreElement?.offsetWidth === undefined ||
      coreElement?.getClientRects().length === undefined
    ) {
      ExceptionHelper.logPageIsBlankError(telemetryState);
      setIsPageBlank(true);
    }
  }

  useLoadTelemetry(() => {
    checkPageBlank();
  });

  return { bannerEnvironment, isPageBlank };
};
