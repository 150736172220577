import React, { useRef } from "react";
import { useChallengeContext } from "../../challenge-context";
import { useLoadResourceTelemetry } from "../../hooks/use-load-resource-telemetry";
import { ServiceDiagEventNames } from "../../telemetry-helpers/service-diag-event-names";
import { useDfpIframeStyles } from "./dfp-iframe.styles";

/**
 * @param props the props of the component
 * @param props.url the optional url to override globalconfig/SharedServerData
 * @returns the device fingerprinting component
 */
export const DfpIframe = function DfpIframe(props: { url?: string }) {
  const {
    challengeState: {
      repMapMetadata: { dfpUrl: dfpUrlFromChallengeContext },
    },
  } = useChallengeContext();
  const { url } = props;
  const dfpIframeStyles = useDfpIframeStyles();
  const ref = React.useRef<HTMLIFrameElement>(null);
  const finalUrl = url || dfpUrlFromChallengeContext;
  const iframeLoadStartTimeRef = useRef<number>(new Date().getTime());

  // add load telemetry here for the iframe
  useLoadResourceTelemetry(
    ref,
    iframeLoadStartTimeRef,
    ServiceDiagEventNames.IframeLoadDurationDFP,
  );

  if (finalUrl) {
    return (
      <iframe
        title="DeviceFingerPrinting"
        data-testid="deviceFingerPrinting"
        className={dfpIframeStyles.iframe}
        src={finalUrl}
        ref={ref}
      />
    );
  }

  return null;
};
