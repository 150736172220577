import { type CredentialType } from "../../model/credential";
import { cleanseUsername } from "../../model/user";
import {
  type GctResult,
  parseGetCredentialTypeResponse,
} from "../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { getFidoSupport } from "../../utilities/browser-helper";
import { ConfigWrapper } from "../../utilities/config-wrapper";
import { type ICountryInfo, parseCountryList } from "../../utilities/country-helper";
import type { ServerData } from "../../utilities/server-data";
import { htmlUnescape } from "../../utilities/strings-helper";
import { isInitialViewAccountPicker } from "./initial-view-picker/model/is-initial-view-account-picker";
import { LoginMode, LoginState, transformCredentialFlags } from "./login-constants";

export interface IOobeProperties {
  oobeDisplayConsentWithoutOneDrive?: boolean;
  oobeDisplayServicesConsent?: boolean;
  oobeDisplayUnifiedConsent?: boolean;
  oobeServicesInformationLink?: string;
}

export const defaultLoginConfig = {
  allowPhone: true,
  allowSkype: true,
  allowedIdentities: 0,
  appBrandedSignInHeader: "",
  arrPromotedCreds: [] as CredentialType[],
  breakBrandingSigninString: false,
  canaryValidationUrl: "",
  changePasswordUrl: "",
  countryList: [] as ICountryInfo[],
  credentialTypeResult: {} as Partial<GctResult>,
  defaultCountry: "",
  defaultLoginOptions: 0,
  defaultSignInHeader: "",
  disambigRenameUrl: "",
  doIfExists: false,
  emailOtcLength: 8,
  enableAriaLiveUpdates: false,
  endAuthUrl: "",
  externalCanary: "",
  facebookFedUrl: "",
  fedPartnerName: "",
  fedQs: "",
  fedState: 0,
  fedUrl: "",
  fidoHelpUrl: "",
  fidoChallenge: "",
  fidoUseAllowedIdentities: false,
  flowTokenCookieName: "",
  forgetUrl: "",
  forgotUsernameUrl: "",
  foundMsas: "",
  gctFederationFlags: 0,
  getCredentialTypeUrl: "",
  getOneTimeCodeUrl: "",
  githubFedUrl: "",
  googleFedUrl: "",
  hasWizardBehavior: false,
  hideInvalidTiles: false,
  hideLoginDescription: false,
  hideOfflineAccountNewTitleString: false,
  hideOfflineAccountWithNoSkipString: false,
  idpRedirectTimeoutMs: 2000,
  improvePhoneDisambiguation: false,
  ipv6ExperimentUrl: "",
  isExternalFederationDisallowed: false,
  isFederationDisabled: false,
  isFidoSupportedHint: false,
  isOobe: false,
  isOtcLoginDisabled: false,
  isOverTheShoulder: false,
  isPasskeySupported: false,
  isRemoteConnectSignup: false,
  isRemoteNGCSupported: false,
  isScoobe: false,
  isSelfServiceSignupUxEnabled: false,
  isTokenBroker: false,
  linkedInFedUrl: "",
  lockUsername: false,
  loginDescription: "",
  loginMode: 0,
  loginStringsVariant: 0,
  logoutUrl: "",
  logSkipToOfflineAccountAction: false,
  lostAuthenticatorUrl: "",
  msaServerLottieId: 0,
  noCookiesUrl: "",
  noLossAversion: false,
  offlineAccountVisible: false,
  oobeProperties: {
    oobeDisplayConsentWithoutOneDrive: false,
    oobeDisplayServicesConsent: false,
    oobeDisplayUnifiedConsent: false,
    oobeServicesInformationLink: "",
  } as Partial<IOobeProperties>,
  otherIdpPostParams: {},
  passwordViewHeader: "",
  postedForceSignIn: false,
  postedLoginState: 0,
  postedUsername: "",
  postProofType: 0,
  preferAssociate: false,
  prefillUsername: "",
  promotedFedCredType: 0,
  protocolRefreshUrl: "",
  // random sequence of characters for padding passport posts
  randomBlob: "",
  rawQueryString: "",
  remoteConnectAppName: "",
  remoteConnectLocation: "",
  remoteConnectUserCode: "",
  resetPasswordPrefillParam: "mn",
  resetPasswordUrl: "",
  riskySignIn: false,
  serverErrorText: "",
  serverValidationErrors: [] as string[],
  showCantAccessAccountLink: false,
  showFidoInline: false,
  showForgotUsernameLink: false,
  showLegalMessagingInline: false,
  showOfflineAccount: false,
  showOfflineAccountLearnMore: false,
  showOnlyGithubOnCredPicker: false,
  showRemoteConnectLocationPage: false,
  showResendCodeDelay: 90000,
  showSignup: true,
  showSwitchUser: false,
  signupUrl: "",
  signupUrlPostParams: {},
  staySignInUrl: "",
  strongAuthOtcLength: 8,
  switchDisambig: false,
  upgradeRedirectWithUsernameUrl: "",
  useForgetUserIframe: false,
  useInlinePhoneNumber: true,
  useResetPasswordUrlForPasswordRequiredError: false,
  useWebviewFidoCustomProtocol: false,
  writePasswordToPropertyBag: false,
  ztdUpnHint: "",
};

export type LoginConfigType = typeof defaultLoginConfig;

// eslint-disable-next-line deprecation/deprecation
const configWrapper = new ConfigWrapper(defaultLoginConfig);

/**
 * The config wrapper that should be used to access Login config properties
 */
export default configWrapper;

/* ********* ServerData helpers ********** */

/**
 * Initialize the Login config object from ServerData
 * @param serverData The IDP-specific server data object that should be used to initialize the Login config
 */
export function initLoginConfig(serverData: ServerData) {
  const mappedServerData: Partial<typeof defaultLoginConfig> = {};

  if (serverData?.iLoginMode) {
    mappedServerData.loginMode = serverData.iLoginMode;
  }

  if (serverData?.oAppCobranding) {
    const appBranding = serverData.oAppCobranding;

    if (appBranding?.signinDescription) {
      mappedServerData.loginDescription = appBranding.signinDescription;
    }

    if (appBranding?.signinPasswordTitle) {
      mappedServerData.passwordViewHeader = appBranding.signinPasswordTitle;
    }

    if (appBranding?.signinTitle) {
      mappedServerData.appBrandedSignInHeader = appBranding.signinTitle;
    }
  }

  if (serverData?.iPromotedFedCredType) {
    mappedServerData.promotedFedCredType = serverData.iPromotedFedCredType;
  }

  if (serverData?.urlLinkedInFed) {
    mappedServerData.linkedInFedUrl = serverData.urlLinkedInFed;
  }

  if (serverData?.urlGitHubFed) {
    mappedServerData.githubFedUrl = serverData.urlGitHubFed;
  }

  if (serverData?.urlGoogleFed) {
    mappedServerData.googleFedUrl = serverData.urlGoogleFed;
  }

  if (serverData?.urlFacebookFed) {
    mappedServerData.facebookFedUrl = serverData.urlFacebookFed;
  }

  if (serverData?.fAllowPhoneSignIn !== undefined || serverData?.fAllowPhoneInput !== undefined) {
    mappedServerData.allowPhone = !!(serverData?.fAllowPhoneSignIn || serverData?.fAllowPhoneInput);
  }

  if (serverData?.fAllowSkypeNameLogin !== undefined) {
    mappedServerData.allowSkype = serverData?.fAllowSkypeNameLogin;
  }

  if (serverData?.fShowSignInWithFidoOnUsernameView !== undefined) {
    mappedServerData.showFidoInline = serverData.fShowSignInWithFidoOnUsernameView;
  }

  if (serverData?.fShowForgotUsernameLink !== undefined) {
    mappedServerData.showForgotUsernameLink = serverData.fShowForgotUsernameLink;
  }

  if (serverData?.urlForgotUsername) {
    mappedServerData.forgotUsernameUrl = serverData.urlForgotUsername;
  }

  if (serverData?.fOfflineAccountVisible !== undefined) {
    mappedServerData.showOfflineAccount = serverData.fOfflineAccountVisible;
  }

  if (serverData?.fShowSignInWithGitHubOnlyOnCredPicker !== undefined) {
    mappedServerData.showOnlyGithubOnCredPicker = serverData.fShowSignInWithGitHubOnlyOnCredPicker;
  }

  if (serverData?.fIsFidoSupported !== undefined) {
    mappedServerData.isFidoSupportedHint = serverData.fIsFidoSupported;
  }

  if (serverData?.urlNoCookies) {
    mappedServerData.noCookiesUrl = serverData.urlNoCookies;
  }

  if (serverData?.iFedState) {
    mappedServerData.fedState = serverData.iFedState;
  }

  if (serverData?.iDefaultLoginOptions) {
    mappedServerData.defaultLoginOptions = serverData.iDefaultLoginOptions;
  }

  if (serverData?.urlFed) {
    mappedServerData.fedUrl = serverData.urlFed;
  }

  if (serverData?.sFedQS) {
    mappedServerData.fedQs = serverData.sFedQS;
  }

  if (serverData?.iLoginStringsVariantId) {
    mappedServerData.loginStringsVariant = serverData.iLoginStringsVariantId;
  }

  if (serverData?.sRemoteConnectAppName) {
    mappedServerData.remoteConnectAppName = serverData.sRemoteConnectAppName;
  }

  if (serverData?.urlIPv6Experiment) {
    mappedServerData.ipv6ExperimentUrl = serverData.urlIPv6Experiment;
  }

  if (serverData?.sFedPartnerName) {
    mappedServerData.fedPartnerName = serverData.sFedPartnerName;
  }

  if (serverData?.urlStaySignIn) {
    mappedServerData.staySignInUrl = serverData.urlStaySignIn;
  }

  if (serverData?.strSignInHeader) {
    mappedServerData.defaultSignInHeader = serverData.strSignInHeader;
  }

  if (serverData?.fBreakBrandingSigninString !== undefined) {
    mappedServerData.breakBrandingSigninString = serverData.fBreakBrandingSigninString;
  }

  if (serverData?.iGctFederationFlags) {
    mappedServerData.gctFederationFlags = serverData.iGctFederationFlags;
  }

  if (serverData?.fIsExternalFederationDisallowed !== undefined) {
    mappedServerData.isExternalFederationDisallowed = !!serverData.fIsExternalFederationDisallowed;
  }

  if (serverData?.fIsFedDisabled !== undefined) {
    mappedServerData.isFederationDisabled = serverData.fIsFedDisabled;
  }

  if (serverData?.fIsRemoteNGCSupported !== undefined) {
    mappedServerData.isRemoteNGCSupported = serverData.fIsRemoteNGCSupported;
  }

  if (serverData?.fIsOtcLoginDisabled !== undefined) {
    mappedServerData.isOtcLoginDisabled = serverData.fIsOtcLoginDisabled;
  }

  if (serverData?.urlGetCredentialType) {
    mappedServerData.getCredentialTypeUrl = serverData.urlGetCredentialType;
  }

  if (serverData?.sSMSCtryPhoneData) {
    mappedServerData.countryList = parseCountryList(serverData.sSMSCtryPhoneData, true);
  }

  if (serverData?.sPrefSMSCountry) {
    mappedServerData.defaultCountry = serverData.sPrefSMSCountry;
  } else if (serverData?.country !== undefined) {
    mappedServerData.defaultCountry = serverData.country;
  }

  if (serverData?.sProofType) {
    mappedServerData.postProofType = parseInt(serverData.sProofType, 10);
  }

  if (serverData?.fUseInlinePhoneNumber !== undefined) {
    mappedServerData.useInlinePhoneNumber = serverData.fUseInlinePhoneNumber;
  }

  if (serverData?.fCBShowSignUp !== undefined) {
    mappedServerData.showSignup = serverData.fCBShowSignUp;
  }

  if (serverData?.urlSignUp) {
    mappedServerData.signupUrl = serverData.urlSignUp;
  }

  if (serverData?.sRandomBlob) {
    mappedServerData.randomBlob = serverData.sRandomBlob;
  }

  if (serverData?.sFoundMSAs) {
    mappedServerData.foundMsas = serverData.sFoundMSAs;
  }

  if (serverData?.sRawQueryString) {
    mappedServerData.rawQueryString = serverData.sRawQueryString;
  }

  if (serverData?.fPOST_ForceSignin !== undefined) {
    mappedServerData.postedForceSignIn = serverData.fPOST_ForceSignin;
  }

  if (serverData?.fUseWizardBehavior !== undefined) {
    mappedServerData.hasWizardBehavior = serverData.fUseWizardBehavior;
  }

  if (serverData?.oSignUpPostParams) {
    mappedServerData.signupUrlPostParams = serverData.oSignUpPostParams;
  }

  if (serverData?.fDoIfExists !== undefined) {
    mappedServerData.doIfExists = serverData.fDoIfExists;
  }

  if (serverData?.urlResetPassword) {
    mappedServerData.resetPasswordUrl = serverData.urlResetPassword;
  }

  if (serverData?.urlFidoHelp) {
    mappedServerData.fidoHelpUrl = serverData.urlFidoHelp;
  }

  if (serverData?.sExternalCanary) {
    mappedServerData.externalCanary = serverData.sExternalCanary;
  }

  if (serverData?.urlCanaryValidation) {
    mappedServerData.canaryValidationUrl = serverData.urlCanaryValidation;
  }

  if (serverData?.fIsRemoteConnectSignup !== undefined) {
    mappedServerData.isRemoteConnectSignup = serverData.fIsRemoteConnectSignup;
  }

  if (serverData?.urlDisambigRename) {
    mappedServerData.disambigRenameUrl = serverData.urlDisambigRename;
  }

  if (serverData?.fShowSwitchUser !== undefined) {
    mappedServerData.showSwitchUser = serverData.fShowSwitchUser;
  }

  // we have logic dependent on this property being undefined
  mappedServerData.otherIdpPostParams = serverData.oUrlOtherIdpPostParams;

  if (serverData?.fSwitchDisambig !== undefined) {
    mappedServerData.switchDisambig = serverData.fSwitchDisambig;
  }

  if (serverData?.iAllowedIdentities) {
    mappedServerData.allowedIdentities = serverData.iAllowedIdentities;
  }

  if (serverData?.sFidoChallenge) {
    mappedServerData.fidoChallenge = serverData.sFidoChallenge;
  }

  if (serverData?.fFidoUseAllowedIdentities !== undefined) {
    mappedServerData.fidoUseAllowedIdentities = serverData.fFidoUseAllowedIdentities;
  }

  if (serverData?.urlLostAuthenticator) {
    mappedServerData.lostAuthenticatorUrl = serverData.urlLostAuthenticator;
  }

  if (serverData?.sResetPasswordPrefillParam) {
    mappedServerData.resetPasswordPrefillParam = serverData.sResetPasswordPrefillParam;
  }

  if (serverData?.urlLogin) {
    mappedServerData.protocolRefreshUrl = serverData.urlLogin;
  }

  if (serverData?.urlUpgradeRedirectWithUsernane) {
    mappedServerData.upgradeRedirectWithUsernameUrl = serverData.urlUpgradeRedirectWithUsernane;
  }

  if (serverData?.sRemoteConnectUserCode) {
    mappedServerData.remoteConnectUserCode = serverData.sRemoteConnectUserCode;
  }

  if (serverData?.fImprovePhoneDisambig !== undefined) {
    mappedServerData.improvePhoneDisambiguation = serverData.fImprovePhoneDisambig;
  }

  if (serverData?.sFTCookieName) {
    mappedServerData.flowTokenCookieName = serverData.sFTCookieName;
  }

  if (serverData?.urlForget) {
    mappedServerData.forgetUrl = serverData.urlForget;
  }

  if (serverData?.urlLogout) {
    mappedServerData.logoutUrl = serverData.urlLogout;
  }

  if (serverData?.fUseForgetUserIframe !== undefined) {
    mappedServerData.useForgetUserIframe = serverData.fUseForgetUserIframe;
  }

  if (serverData?.fIsRiskySignInDetected !== undefined) {
    mappedServerData.riskySignIn = serverData.fIsRiskySignInDetected;
  }

  if (serverData?.fLockUsername !== undefined) {
    mappedServerData.lockUsername = serverData.fLockUsername;
  }

  if (serverData?.sPOST_PaginatedLoginState) {
    mappedServerData.postedLoginState =
      parseInt(serverData.sPOST_PaginatedLoginState, 10) || LoginState.Unknown;
  }

  if (serverData?.sErrTxt) {
    mappedServerData.serverErrorText = serverData.sErrTxt;
  }

  if (serverData?.arrValErrs) {
    mappedServerData.serverValidationErrors = serverData.arrValErrs;
  }

  if (serverData?.sPOST_Username) {
    mappedServerData.postedUsername = serverData.sPOST_Username;
  }

  if (serverData?.sPrefillUsername) {
    mappedServerData.prefillUsername = serverData.sPrefillUsername;
  }

  if (serverData?.sZtdUpnHint) {
    mappedServerData.ztdUpnHint = serverData.sZtdUpnHint;
  }

  if (serverData?.fShowRemoteConnectLocationPage !== undefined) {
    mappedServerData.showRemoteConnectLocationPage = serverData.fShowRemoteConnectLocationPage;
  }

  if (serverData?.oGetCredTypeResult) {
    const unsafeUsername = htmlUnescape(
      serverData.sPOST_Username ||
        serverData.sSignInUsername ||
        serverData.sPrefillUsername ||
        serverData.sZtdUpnHint ||
        "",
    );

    const isFidoSupported = getFidoSupport(
      !!serverData.fIsFidoSupported,
      !!serverData.fUseWebviewFidoCustomProtocol,
    );

    const isForceSignIn =
      serverData.iLoginMode === LoginMode.ForceSignin ||
      serverData.iLoginMode === LoginMode.ForceSigninMobile ||
      serverData.iLoginMode === LoginMode.ForceSigninHost ||
      serverData.fPOST_ForceSignin ||
      false;

    const isAccountPickerView = serverData.fHideInvalidTiles
      ? isInitialViewAccountPicker(
          serverData.iLoginMode || defaultLoginConfig.loginMode,
          serverData.arrSessions?.length || 0,
          isForceSignIn,
        )
      : false;

    mappedServerData.credentialTypeResult = parseGetCredentialTypeResponse(
      serverData?.urlGoToAADError || "",
      serverData.oGetCredTypeResult,
      {
        showSignup: serverData?.fCBShowSignUp,
        isAccountPickerView,
      },
      cleanseUsername(unsafeUsername),
      isFidoSupported,
      false,
      "",
      { ...transformCredentialFlags, isPhoneNumberFullyQualified: false },
      serverData?.iAllowedIdentities,
      serverData?.oUrlOtherIdpPostParams,
    );
  }

  if (serverData?.fHideLoginDesc !== undefined) {
    mappedServerData.hideLoginDescription = serverData.fHideLoginDesc;
  }

  if (serverData?.fUseResetPwdUrlForPwdRequiredErr !== undefined) {
    mappedServerData.useResetPasswordUrlForPasswordRequiredError =
      serverData.fUseResetPwdUrlForPwdRequiredErr;
  }

  if (serverData?.urlChangePassword) {
    mappedServerData.changePasswordUrl = serverData.urlChangePassword;
  }

  if (serverData?.fWritePasswordToPropertyBag !== undefined) {
    mappedServerData.writePasswordToPropertyBag = serverData.fWritePasswordToPropertyBag;
  }

  if (serverData?.fIsScoobe !== undefined) {
    mappedServerData.isScoobe = serverData.fIsScoobe;
  }

  if (serverData?.fIsTokenBroker !== undefined) {
    mappedServerData.isTokenBroker = serverData.fIsTokenBroker;
  }

  if (serverData?.fIsPasskeySupportEnabled !== undefined) {
    mappedServerData.isPasskeySupported = serverData.fIsPasskeySupportEnabled;
  }

  if (serverData?.fShowOfflineAccountLearnMore !== undefined) {
    mappedServerData.showOfflineAccountLearnMore = serverData.fShowOfflineAccountLearnMore;
  }

  if (serverData?.fShowLegalMessagingInline !== undefined) {
    mappedServerData.showLegalMessagingInline = serverData.fShowLegalMessagingInline;
  }

  if (serverData?.iMsaServerLottieId) {
    mappedServerData.msaServerLottieId = serverData.iMsaServerLottieId;
  }

  if (
    serverData?.fOobeConsentWithoutOneDrive !== undefined ||
    serverData?.fOobeDisplayServicesConsent !== undefined ||
    serverData?.fOobeDisplayUnifiedConsent !== undefined ||
    serverData?.urlOobeServicesInformationLink
  ) {
    mappedServerData.oobeProperties = {
      oobeDisplayConsentWithoutOneDrive: !!serverData.fOobeConsentWithoutOneDrive,
      oobeDisplayServicesConsent: !!serverData.fOobeDisplayServicesConsent,
      oobeDisplayUnifiedConsent: !!serverData.fOobeDisplayUnifiedConsent,
      oobeServicesInformationLink: serverData.urlOobeServicesInformationLink,
    };
  }

  if (serverData?.fOfflineAccountVisible !== undefined) {
    mappedServerData.offlineAccountVisible = serverData.fOfflineAccountVisible;
  }

  if (serverData?.fIsOOBE !== undefined) {
    mappedServerData.isOobe = serverData.fIsOOBE;
  }

  if (serverData?.fIsOverTheShoulder !== undefined) {
    mappedServerData.isOverTheShoulder = serverData?.fIsOverTheShoulder;
  }

  if (serverData?.sRemoteConnectLocation !== undefined) {
    mappedServerData.remoteConnectLocation = serverData.sRemoteConnectLocation;
  }

  if (serverData?.fNoLossAversion !== undefined) {
    mappedServerData.noLossAversion = serverData.fNoLossAversion;
  }

  if (serverData?.fPreferAssociate !== undefined) {
    mappedServerData.preferAssociate = serverData.fPreferAssociate;
  }

  if (serverData?.fHideOfflineAccountWithNoSkipString !== undefined) {
    mappedServerData.hideOfflineAccountWithNoSkipString =
      !!serverData.fHideOfflineAccountWithNoSkipString;
  }

  if (serverData?.fLogSkipToOfflineAccountAction !== undefined) {
    mappedServerData.logSkipToOfflineAccountAction = serverData.fLogSkipToOfflineAccountAction;
  }

  if (serverData?.fHideOfflineAccountNewTitleString !== undefined) {
    mappedServerData.hideOfflineAccountNewTitleString =
      !!serverData.fHideOfflineAccountNewTitleString;
  }

  if (serverData?.fUseWebviewFidoCustomProtocol !== undefined) {
    mappedServerData.useWebviewFidoCustomProtocol = !!serverData.fUseWebviewFidoCustomProtocol;
  }

  if (serverData?.fEnableAriaLiveUpdates) {
    mappedServerData.enableAriaLiveUpdates = serverData.fEnableAriaLiveUpdates;
  }

  if (serverData?.fHideInvalidTiles) {
    mappedServerData.hideInvalidTiles = serverData.fHideInvalidTiles;
  }

  if (serverData?.iEmailOtcLength !== undefined) {
    mappedServerData.emailOtcLength = serverData.iEmailOtcLength;
  }

  if (serverData?.iShowResendCodeDelay !== undefined) {
    mappedServerData.showResendCodeDelay = serverData.iShowResendCodeDelay;
  }

  if (serverData?.iSAOtcLength !== undefined) {
    mappedServerData.strongAuthOtcLength = serverData.iSAOtcLength;
  }

  if (serverData?.urlEndAuth !== undefined) {
    mappedServerData.endAuthUrl = serverData.urlEndAuth;
  }

  if (serverData?.urlGetOneTimeCode !== undefined) {
    mappedServerData.getOneTimeCodeUrl = serverData.urlGetOneTimeCode;
  }

  if (serverData?.fIsSelfServiceSignupUxEnabled) {
    mappedServerData.isSelfServiceSignupUxEnabled = serverData.fIsSelfServiceSignupUxEnabled;
  }

  if (serverData?.showCantAccessAccountLink) {
    mappedServerData.showCantAccessAccountLink = serverData.showCantAccessAccountLink;
  }

  if (serverData?.arrPromotedFedCredTypes) {
    mappedServerData.arrPromotedCreds = serverData.arrPromotedFedCredTypes;
  }

  if (serverData?.urlLinkedInFed) {
    mappedServerData.linkedInFedUrl = serverData.urlLinkedInFed;
  }

  if (serverData?.urlGitHubFed) {
    mappedServerData.githubFedUrl = serverData.urlGitHubFed;
  }

  if (serverData?.urlGoogleFed) {
    mappedServerData.googleFedUrl = serverData.urlGoogleFed;
  }

  if (serverData?.urlFacebookFed) {
    mappedServerData.facebookFedUrl = serverData.urlFacebookFed;
  }

  configWrapper.initialize(mappedServerData);
}
