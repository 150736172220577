export enum ServiceDiagEventNames {
  None = "", // Keep None at the top

  // Add new events below in alphabetical order
  AccountTransferDenied = "AccountTransfer_Denied",
  AccountTransferErrorMissingOtc = "AccountTransferError_MissingOtc",
  AccountTransferErrorUnknownError = "AccountTransferError_UnknownError",
  AccountTransferMobileDenied = "AccountTransfer_MobileDenied",
  AccountTransferSuccess = "AccountTransfer_Success",
  AccountTransferTimeout = "AccountTransfer_Timeout",
  AcmaApiError = "Acma_ApiError",
  AcwSSOBridgeLoadFailed = "Acw_SSOBridge_Load_Failed",
  AcwSSOBridgeLoadSuccess = "Acw_SSOBridge_Load_Success",
  AcwSSOTokenRequestFailed = "Acw_SSOTokenRequest_Failed",
  AcwSSOTokenRequestStart = "Acw_SSOTokenRequest_Start",
  AcwSSOTokenRequestSuccess = "Acw_SSOTokenRequest_Success",
  AcwSSOUrlOpenFailed = "Acw_SSOUrlOpen_Failed",
  AcwSSOUrlOpenSuccess = "Acw_SSOUrlOpen_Success",
  CobrandingError = "CobrandingError",
  CobrandingNotFoundInMap = "CobrandingNotFoundInMap",
  IframeLoadDurationDFP = "IframeLoadDuration_DFP",
  IframeLoadDurationHuman = "IframeLoadDuration_Human",
  ImageNotFound = "Image_NotFound",
  InvalidClientId = "InvalidClientId",
  InvalidCobrandId = "InvalidCobrandId",
  InvalidCobrandIdForClient = "InvalidCobrandIdForClient",
  InvalidTenantList = "InvalidTenantList",
  OtcCredentialUndefined = "OtcCredential_Undefined",
  QrLogoNotFound = "QrLogo_NotFound",
  Redirect = "Redirect",
  RiskVerifyApiError = "RiskVerify_ApiError",
  RiskVerifyBlock = "RiskVerifyBlock",
  RiskVerifyChallengeRequired = "RiskVerify_ChallengeRequired",
}
