import { useCallback, useEffect } from "react";
import { doOriginsMatch } from "../utilities/origins";

/**
 * Use Message hook to listen to events on Windows-object.
 * @param callback callback to be called when event is received
 * @param config config object
 * @param config.iFrameUrl url of the iframe to listen to
 * @param eventName eventName of the event to listen to
 */
export const useMessage = <PayloadType>(
  callback: (payload: PayloadType) => void,
  config?: { iFrameUrl: string },
  eventName?: string,
): void => {
  // If no iFrameUrl is provided, bypass this check
  const areSameOrigin = useCallback(
    (origin: string) => !config?.iFrameUrl || doOriginsMatch(config?.iFrameUrl, origin),
    [config?.iFrameUrl],
  );

  const watcher = useCallback(
    ({ data, origin }: MessageEvent) => {
      let parsedData;

      // If data is a string, try to parse it to JSON
      // If data is an object, use it as is
      // If data is neither, return(e.g. "". JSON.parse("") throws an error)
      if (typeof data === "string") {
        try {
          parsedData = JSON.parse(data);
        } catch (e) {
          return;
        }
      } else if (typeof data === "object") {
        parsedData = data;
      } else {
        return;
      }

      if ((eventName && parsedData.eventId !== eventName) || !areSameOrigin(origin)) {
        return;
      }

      callback(parsedData);
    },
    [eventName, areSameOrigin, callback],
  );

  useEffect(() => {
    window.addEventListener("message", watcher);
    return () => window.removeEventListener("message", watcher);
  }, [eventName, watcher]);
};
