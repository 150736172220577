import { useContext } from "react";
import { ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { type RedirectPostParams } from "../../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import { LoginContext } from "../../login-context";
import { LoginActionType } from "../../login-reducer";

/**
 * @returns a function that contains all shared logic to run before navigating to the
 * input destination view
 */
export const useOnSwitchView = () => {
  // Global context data
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);

  // Login flow context data
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  const navigate = useNavigateDirection();

  return (
    destination: ViewId,
    redirectingToIdp: boolean,
    idpRedirectUrl: string,
    idpRedirectPostParams: RedirectPostParams,
    unsafeDesktopSsoDomain: string,
  ) => {
    if (redirectingToIdp) {
      dispatchLoginStateChange({
        type: LoginActionType.SetIdpRedirectData,
        payload: { url: idpRedirectUrl, postParams: idpRedirectPostParams },
      });
    }

    dispatchLoginStateChange({
      type: LoginActionType.SetDesktopSsoDomain,
      payload: unsafeDesktopSsoDomain,
    });

    dispatchGlobalStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.IdpDisambiguation,
      destination,
      displayOptions: { navigationDirection: "forward" },
    });

    navigate(ViewId.IdpDisambiguation, getRouteFromViewId(destination));
  };
};
