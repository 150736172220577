/* eslint-disable deprecation/deprecation */
import { useContext, useState } from "react";
import { AuthenticationContext } from "../../../authentication-context";
import { AuthenticationActionType } from "../../../authentication-reducer";
import { type Flavors, ApiNames } from "../../../constants";
import { ViewId } from "../../../constants/routing-constants";
import { useFidoPostRedirect, useLoginFlowRedirect } from "../../../flows/login/hooks/login-hooks";
import LoginConfig from "../../../flows/login/login-config";
import { LoginContext } from "../../../flows/login/login-context";
import { LoginActionType } from "../../../flows/login/login-reducer";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { useEffectOnce } from "../../../hooks";
import { useNavigateDirection } from "../../../hooks/use-navigate-direction";
import {
  type OneTimeCodeCredential,
  type UserCredential,
  CredentialType,
  isFederationCredentialType,
} from "../../../model/credential";
import { type OneTimeCodeProof, ProofType } from "../../../model/proof";
import { cleanseUsername } from "../../../model/user";
import { type SystemActionName } from "../../../telemetry-helpers/system-action-name";
import {
  type OtcFormApiTelemetryProps,
  proofTypeToChannel,
} from "../../../utilities/api-helpers/one-time-code/get-one-time-code-form";
import {
  type GetOtcParams,
  getOneTimeCode,
  getProofConfirmation,
  isChallengeViewSupported,
} from "../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcPurposes,
  OtcStatus,
} from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { useOtcTelemetry } from "../../../utilities/api-helpers/one-time-code/use-one-time-code-telemetry";
import { isPlatformAuthenticatorAvailable } from "../../../utilities/browser-helper";
import { getRouteFromViewId } from "../../../utilities/routing-helper";
import { replaceTokens } from "../../../utilities/strings-helper";
import { type UntrustedExternalInputText } from "../../../utilities/untrusted-external-input-text";
import { useTriggerChallenge } from "../../../views/challenge/hooks/use-trigger-challenge";
import { useCredentialPickerClickHandler } from "../hooks/use-credential-picker-click-handler";
import { type ICredentialSwitchLinkProperties } from "../model/credential-switch-links-types";
import { getAlternativeCredentials } from "../model/get-alternative-credentials";

/**
 * @param isPlatformFidoSupported flag to indicate whether fido platform credentials are supported
 * @returns map of credential type to the properties to be used in the credential switch link
 * @deprecated use getSupportedCredentialMap instead
 */
export const getSupportedCredentialMapFabric = (
  isPlatformFidoSupported: boolean,
): {
  [key in CredentialType]?: ICredentialSwitchLinkProperties;
} => {
  const { isPasskeySupported } = LoginConfig.instance;

  const credentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties } = {};

  let fidoLinkText = isPlatformFidoSupported
    ? getLocalString("Login_CredPicker_Option_Fido")
    : getLocalString("Login_CredPicker_Option_Fido_NoHello");

  if (isPasskeySupported) {
    fidoLinkText = getLocalString("SwitchToPasskey");
  }

  credentialMap[CredentialType.Password] = {
    viewId: ViewId.Password,
    linkId: "idA_PWD_SwitchToPassword",
    linkText: getLocalString("Login_SwitchToPasswordLink"),
  };

  credentialMap[CredentialType.RemoteNGC] = {
    viewId: ViewId.PushNotifications,
    linkId: "idA_PWD_SwitchToRemoteNGC",
    linkText: getLocalString("Login_PushNotifications_CredSwitch_Option_App_Generic"),
  };

  credentialMap[CredentialType.Fido] = {
    linkId: "idA_PWD_SwitchToFido",
    linkText: fidoLinkText,
  };

  credentialMap[CredentialType.Certificate] = {
    linkId: "idA_PWD_SwitchToCertificate",
    linkText: getLocalString("SwitchToCertOrSmartCard"),
  };

  credentialMap[CredentialType.OtherMicrosoftIdpFederation] = {
    linkId: "useMicrosoftLink",
    linkText: getLocalString("SignIn_Microsoft"),
  };

  credentialMap[CredentialType.LinkedIn] = {
    linkId: "useLinkedInLink",
    linkText: getLocalString("Login_UseLinkedIn_Link"),
  };

  credentialMap[CredentialType.GitHub] = {
    linkId: "useGitHubLink",
    linkText: getLocalString("Login_UseGitHub_Link"),
  };

  credentialMap[CredentialType.Google] = {
    linkId: "useGoogleLink",
    linkText: getLocalString("Login_UseGoogle_Link"),
  };

  credentialMap[CredentialType.Facebook] = {
    linkId: "useFacebookLink",
    linkText: getLocalString("Login_UseFacebook_Link"),
  };

  credentialMap[CredentialType.Federation] = {
    viewId: ViewId.IdpRedirect,
    linkId: "redirectToIdpLink",
    linkText: getLocalString("SignIn_Password"),
  };

  credentialMap[CredentialType.RemoteLogin] = {
    // @TODO - add viewId: ViewId.RemoteLoginPolling,
    linkId: "remoteLoginLink",
    linkText: getLocalString("SignIn_AnotherDevice"),
  };

  credentialMap[CredentialType.OneTimeCode] = {
    viewId: ViewId.OneTimeCode,
    linkId: "otcLoginLink",
    linkText: getLocalString("SignIn_SingleUseCode"),
  };

  credentialMap[CredentialType.AccessPass] = {
    // @TODO - add viewId: ViewId.AccessPass,
    linkId: "accessPassLink",
    linkText: getLocalString("SwitchToAccessPass"),
  };

  return credentialMap;
};

/**
 * build request params for the one time code API call
 * @param selectedCredential credential selected for the switch link
 * @param flowToken flow token
 * @param username current user (if available)
 * @param onSuccess success handler for the OTC call
 * @param onFailure failure handler for the OTC call
 * @param activeFlavor active flavor
 * @param telemetryCallback telemetry callback for logging request/response data
 * @returns built params object
 * @deprecated use getCredentialSwitchLinksSendOtcParams instead
 */
export const getOneTimeCodeRequestParamsFabric = (
  selectedCredential: OneTimeCodeCredential,
  flowToken: string,
  username: UntrustedExternalInputText,
  onSuccess: (successParams: OtcSuccessParams) => void,
  onFailure: (failureParams: OtcFailureParams) => void,
  activeFlavor: Flavors,
  telemetryCallback?: (
    actionName: SystemActionName,
    telemetryProps: OtcFormApiTelemetryProps,
  ) => void,
) => {
  const proof: OneTimeCodeProof = selectedCredential.proof!;

  const params: GetOtcParams = {
    username,
    flowToken,
    isEncrypted: proof.isEncrypted,
    proofData: proof.data,
    proofType: proof.type,
    purpose: proof.isNopa ? OtcPurposes.noPassword : OtcPurposes.otcLogin,
    channel: proofTypeToChannel(proof.type)!,
    onSuccess,
    onFailure,
    challengeViewSupported: isChallengeViewSupported(activeFlavor),
    telemetryCallback,
  };

  if (selectedCredential.proof?.isEncrypted) {
    params.proofConfirmation = getProofConfirmation(
      selectedCredential,
      selectedCredential.proof.type,
    );
  }

  return params;
};

/**
 * @param sourceViewId view where the credential switch links component resides
 * @param selectedCredential credential selected for the switch link
 * @param supportedCredentialMap map for all the supported credentials
 * @param setErrorMessage callback for setting error message when error occurs in one time code call
 * @param setRequestPendingFlag callback for informing wrapper component when an external request is in progress
 * @param shouldUpdateOtcCredential flag that indicates whether to update the `otcCredential` login context property. This should usually be set to true
 * unless the current view does not need to store the next one-time code credential - for example when it redirects to a different flow or website
 * @returns handler for when credential switch link is clicked
 * @deprecated use useCredentialSwitchClickHandler instead
 */
export const useCredentialSwitchClickHandlerFabric = (
  sourceViewId: ViewId,
  selectedCredential: UserCredential | undefined,
  supportedCredentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties },
  setErrorMessage: (message: string) => void,
  setRequestPendingFlag: (isRequestPending: boolean) => void,
  shouldUpdateOtcCredential?: boolean,
) => {
  const navigator = useNavigateDirection();
  const fidoRedirectHandler = useFidoPostRedirect();
  const loginFlowRedirect = useLoginFlowRedirect();
  const telemetryCallback = useOtcTelemetry();
  const triggerChallenge = useTriggerChallenge();

  const { activeFlavor } = GlobalConfig.instance;

  const {
    globalState: {
      user: { username },
    },
  } = useContext(GlobalContext);

  const {
    authState: { flowTokenValue },
    dispatchStateChange,
  } = useContext(AuthenticationContext);

  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  const updateOtcCredential = () => {
    if (shouldUpdateOtcCredential) {
      dispatchLoginStateChange({
        type: LoginActionType.UpdateCredentials,
        payload: {
          otcCredential: {
            ...selectedCredential,
            credentialType: CredentialType.OneTimeCode,
            proof: (selectedCredential as OneTimeCodeCredential).proof,
          },
        },
      });
    }
  };

  const otcRequestSuccessHandler = (successParams: OtcSuccessParams) => {
    setRequestPendingFlag(false);
    if (successParams?.flowToken) {
      dispatchStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: successParams.flowToken,
      });
    }

    updateOtcCredential();
    navigator(sourceViewId, getRouteFromViewId(ViewId.OneTimeCode));
  };

  const otcRequestFailureHandler = (failureParams: OtcFailureParams) => {
    const errorId: number = failureParams.otcStatus || -1;

    setRequestPendingFlag(false);
    if (failureParams?.flowToken) {
      dispatchStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: failureParams.flowToken,
      });
    }

    if (errorId === OtcStatus.ftError) {
      setErrorMessage(getLocalString("OneTimeCode_SessionExpiredError"));
    } else if (errorId === OtcStatus.challengeRequiredError || errorId === OtcStatus.userBlocked) {
      // challenge view uses otc credential in the context for calling otc
      updateOtcCredential();
      triggerChallenge({ response: failureParams, apiName: ApiNames.GetOtc });
    } else {
      setErrorMessage(getLocalString("OneTimeCode_SendFailedError"));
    }
  };

  return async () => {
    const credentialType = selectedCredential?.credentialType || CredentialType.Password;

    // clear error before another call
    setErrorMessage("");

    switch (credentialType) {
      case CredentialType.OneTimeCode:
        if (selectedCredential?.proof?.clearDigits) {
          updateOtcCredential();
          navigator(sourceViewId, getRouteFromViewId(ViewId.ProofConfirmation));
        } else {
          const oneTimeCodeCredential = selectedCredential as OneTimeCodeCredential;
          const otcParams = getOneTimeCodeRequestParamsFabric(
            oneTimeCodeCredential,
            flowTokenValue,
            username,
            otcRequestSuccessHandler,
            otcRequestFailureHandler,
            activeFlavor,
            telemetryCallback,
          );

          setRequestPendingFlag(true);
          await getOneTimeCode(otcParams);
        }

        break;
      case CredentialType.Fido:
        fidoRedirectHandler({ username: cleanseUsername(username.unsafeUnescapedString) });
        break;
      case CredentialType.OtherMicrosoftIdpFederation:
      case CredentialType.LinkedIn:
      case CredentialType.GitHub:
      case CredentialType.Google:
      case CredentialType.Facebook:
        // It should be fine to use the non-null assertion operator (!) on redirectUrl here since,
        // if the selected credential is of federation type, we show the credential switch link
        // only when it has a redirect URL (which means redirectUrl should always be defined here).
        loginFlowRedirect(selectedCredential?.redirectUrl!, selectedCredential?.redirectPostParams);

        break;
      case CredentialType.Certificate:
        // @AAD-TODO - implement CBA redirect when migrating AAD Login to AXIS
        break;
      default:
        navigator(
          sourceViewId,
          getRouteFromViewId(supportedCredentialMap[credentialType]!.viewId!),
        );
    }
  };
};

/**
 * @param sourceViewId parent view of the component
 * @param currentCredential credential the user is currently using
 * @param alternativeCredentials list of alternative credentials available to user
 * @param showForgotUsername flag that indicates whether to show forgot username link
 * @param showCredPickerLinkForSingleAltCred flag that indicates whether to show credential picker link for single alternative credential
 * @returns all properties needed for displaying credential picker link
 * @deprecated use useCredentialPickerLinkProperties instead
 */
const useCredentialPickerLinkPropertiesFabric = (
  sourceViewId: ViewId,
  currentCredential: UserCredential,
  alternativeCredentials: UserCredential[],
  showForgotUsername?: boolean,
  showCredPickerLinkForSingleAltCred?: boolean,
) => {
  // when the current credential is known, user has been identified
  const isUserKnown = !!currentCredential?.credentialType;
  const { showSignInOptionsAsButton } = GlobalConfig.instance;
  const credentialPickerLinkText = isUserKnown
    ? getLocalString("Login_SwitchToCredPicker_Link")
    : getLocalString("Login_CredPicker_Title_NoUser");

  // When the feature to show credential picker link on username view as a tile is false,
  // credential picker link will be shown only when user information is present AND
  // when alternate credentials are > 1 OR when UX can display forgot username link OR when
  // the credential can be displayed only on the credential picker view
  const showCredentialPickerLink =
    (!showSignInOptionsAsButton || isUserKnown) &&
    (alternativeCredentials.length > 1 ||
      (alternativeCredentials.length === 1 &&
        (!!showForgotUsername ||
          !!alternativeCredentials[0].shownOnlyOnPicker ||
          !!showCredPickerLinkForSingleAltCred)));

  return {
    showCredentialPickerLink,
    credentialPickerLinkText,
    credentialPickerLinkOnClickHandler: useCredentialPickerClickHandler(sourceViewId, isUserKnown),
  };
};

/**
 * @param sourceViewId parent view of the component
 * @param alternativeCredentials list of alternative credentials available to user
 * @param setErrorMessage handler for one time code request errors
 * @param setRequestPendingFlag callback for informing wrapper component when an external request is in progress
 * @param supportedCredentialMap map for all the supported credentials
 * @param showForgotUsername flag that indicates whether to show forgot username link
 * @param shouldUpdateOtcCredential flag that indicates whether to update the `otcCredential` login context property. This should usually be set to true
 * unless the current view does not need to store the next one-time code credential - for example when it redirects to a different flow or website
 * @returns all properties needed for displaying credential switch link
 * @deprecated use useCredentialSwitchLinkProperties instead
 */
const useCredentialSwitchLinkPropertiesFabric = (
  sourceViewId: ViewId,
  alternativeCredentials: UserCredential[],
  setErrorMessage: (message: string) => void,
  setRequestPendingFlag: (isRequestPending: boolean) => void,
  supportedCredentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties },
  showForgotUsername?: boolean,
  shouldUpdateOtcCredential?: boolean,
) => {
  let credentialSwitchLinkId = "";
  let credentialSwitchLinkText = "";
  let showCredentialSwitchLink = false;
  const selectedCredential = alternativeCredentials[0];

  // when the alternative credentials list only contains one choice
  // then we will display a convenient link that would redirect the
  // user to that option
  if (alternativeCredentials.length === 1 && selectedCredential) {
    const credentialToSwitchTo =
      supportedCredentialMap[selectedCredential.credentialType] ||
      supportedCredentialMap[CredentialType.Password];

    credentialSwitchLinkId = credentialToSwitchTo!.linkId;
    credentialSwitchLinkText = credentialToSwitchTo!.linkText;

    // special text for one time code, depending where the code was sent
    if (
      selectedCredential.credentialType === CredentialType.OneTimeCode &&
      selectedCredential.proof?.display
    ) {
      switch (selectedCredential.proof?.type) {
        case ProofType.Email:
          credentialSwitchLinkText = replaceTokens(
            getLocalString("SwitchToOtc_Email"),
            selectedCredential.proof.display,
          );
          break;
        case ProofType.SMS:
          credentialSwitchLinkText = replaceTokens(
            getLocalString("SwitchToOtc_Sms"),
            selectedCredential.proof.display,
          );
          break;
        case ProofType.Voice:
          credentialSwitchLinkText = replaceTokens(
            getLocalString("SwitchToOtc_Voice"),
            selectedCredential.proof.display,
          );
          break;
        default:
        // do nothing
      }
    }

    const selectedCredentialIsFederation = isFederationCredentialType(
      selectedCredential.credentialType,
    );
    const selectedCredentialIsValid =
      !selectedCredentialIsFederation ||
      (selectedCredentialIsFederation && !!selectedCredential.redirectUrl);
    showCredentialSwitchLink =
      !showForgotUsername && !selectedCredential.shownOnlyOnPicker && selectedCredentialIsValid;
  }

  return {
    showCredentialSwitchLink,
    credentialSwitchLinkId,
    credentialSwitchLinkText,
    credentialSwitchLinkOnClickHandler: useCredentialSwitchClickHandlerFabric(
      sourceViewId,
      selectedCredential,
      supportedCredentialMap,
      setErrorMessage,
      setRequestPendingFlag,
      shouldUpdateOtcCredential,
    ),
  };
};

/**
 * @param alternativeCredentials list of alternative credentials available to user
 * @returns all properties needed for displaying the forgot username link
 * @deprecated use useForgotUsernameLinkProperties instead
 */
export const useForgotUsernameLinkPropertiesFabric = (alternativeCredentials: UserCredential[]) => {
  const { forgotUsernameUrl, showForgotUsernameLink } = LoginConfig.instance;
  const loginFlowRedirect = useLoginFlowRedirect();

  return {
    showForgotUsernameLink: showForgotUsernameLink && alternativeCredentials.length === 0,
    forgotUsernameLinkId: "forgotUsernameLink",
    forgotUsernameLinkText: getLocalString("Login_ForgotUsername_Link_Alt"),
    forgotUsernameLinkOnClick: () => loginFlowRedirect(forgotUsernameUrl),
  };
};

/**
 * the main hook for credential switch links that returns all the necessary
 * properties for the component to render
 * @param sourceViewId parent view of the component
 * @param availableCredentials credentials available to the user
 * @param currentCredential credential the user is currently using
 * @param setRequestPendingFlag callback for informing wrapper component when an external request is in progress
 * @param showForgotUsername flag that indicates whether to show forgot username link
 * @param shouldUpdateOtcCredential flag that indicates whether to update the `otcCredential` login context property. This should usually be set to true
 * @param showCredPickerLinkForSingleAltCred flag that indicates whether to show credential picker link for single alternative credential
 * unless the current view does not need to store the next one-time code credential - for example when it redirects to a different flow or website
 * @returns all properties needed for rendering credential switch links component
 * @deprecated use useCredentialSwitchLinks instead
 */
export const useCredentialSwitchLinksFabric = (
  sourceViewId: ViewId,
  availableCredentials: UserCredential[],
  currentCredential: UserCredential,
  setRequestPendingFlag: (isRequestPending: boolean) => void,
  showForgotUsername?: boolean,
  shouldUpdateOtcCredential?: boolean,
  showCredPickerLinkForSingleAltCred?: boolean,
) => {
  const { useWebviewFidoCustomProtocol } = LoginConfig.instance;
  const [errorMessage, setErrorMessage] = useState("");
  const [isPlatformFidoSupported, setIsPlatformSupported] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffectOnce(() => {
    isPlatformAuthenticatorAvailable(useWebviewFidoCustomProtocol).then((isAvailable) => {
      setIsPlatformSupported(isAvailable);
      setReadyToRender(true);
    });
  });

  const supportedCredMap = getSupportedCredentialMapFabric(isPlatformFidoSupported);

  const alternativeCredentials = getAlternativeCredentials(
    availableCredentials,
    currentCredential,
    supportedCredMap,
  );

  const { showCredentialPickerLink, credentialPickerLinkText, credentialPickerLinkOnClickHandler } =
    useCredentialPickerLinkPropertiesFabric(
      sourceViewId,
      currentCredential,
      alternativeCredentials,
      showForgotUsername,
      showCredPickerLinkForSingleAltCred,
    );

  const {
    showCredentialSwitchLink,
    credentialSwitchLinkId,
    credentialSwitchLinkText,
    credentialSwitchLinkOnClickHandler,
  } = useCredentialSwitchLinkPropertiesFabric(
    sourceViewId,
    alternativeCredentials,
    setErrorMessage,
    setRequestPendingFlag,
    supportedCredMap,
    showForgotUsername,
    shouldUpdateOtcCredential,
  );

  const {
    showForgotUsernameLink,
    forgotUsernameLinkId,
    forgotUsernameLinkText,
    forgotUsernameLinkOnClick,
  } = useForgotUsernameLinkPropertiesFabric(alternativeCredentials);

  return {
    errorMessage,
    showCredentialSwitchLink,
    credentialSwitchLinkId,
    credentialSwitchLinkText,
    credentialSwitchLinkOnClickHandler,
    showCredentialPickerLink,
    credentialPickerLinkText,
    credentialPickerLinkOnClickHandler,
    showForgotUsernameLink,
    forgotUsernameLinkId,
    forgotUsernameLinkText,
    forgotUsernameLinkOnClick,
    readyToRender,
  };
};
