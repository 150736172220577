/* eslint-disable @msidentity/authx/no-inline-eslint-disable */
import React, { useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { gamepadMainContentAttributes } from "../../../../constants";
import { useFocusElement } from "../../../../hooks/use-focus-element";
import { useGamepadCancel } from "../../../../hooks/use-gamepad-cancel";
import useFloatingDropdownStyles from "../../../../styles/fabric/floating-dropdown-fabric.styles";
import { chevronClosedIcon } from "../../../../utilities/image-helpers/accessible-images";
import { type AccessibleImageProps } from "../../../accessible-image";
import { type IButtonProps } from "../../../button/button-interface-fabric";
import { FocusLocker } from "../../../focus-locker";
import { ImageButtonFabric } from "../../../image-button/fabric/image-button-fabric";
import { LinkButton } from "../../../link-button";
import { useCloseOnOutsideClick } from "../../../options-menu-button/hooks/use-close-on-outside-click";
import { type IInputProps, type SelectElement } from "../../input-interface";

export type FloatingDropdownMenuOptionProps = {
  /** The display text for the option */
  text: string;
  /** The value for the option */
  value: string;
  /** The onClick for the option */
  onClick: () => void;
};

export type FloatingDropdownMenuProps = IInputProps<SelectElement> & {
  /** The list of dropdown options */
  selectOptions: FloatingDropdownMenuOptionProps[];
  /** Whether or not the dropdown is initially open. (default is false) */
  isInitialOpen?: boolean;
  /** Optional data-test id for testing purposes. Set to floatingDropdownMenu by default */
  dataTestId?: string;
};

export type FloatingDropdownFabricProps = {
  /** The props of floating dropdown menu */
  dropdownMenu: FloatingDropdownMenuProps;
  /** Optional data-test id for testing purposes. Set to floatingDropdown by default */
  dataTestId?: string;
  /** The props of button button */
  dropdownButton: IButtonProps;
};

/**
 * FloatingDropdownFabric component
 * @param props The properties for this component
 * @returns A floating dropdown component for Fabric
 */
export const FloatingDropdownFabric: React.FC<FloatingDropdownFabricProps> =
  function FloatingDropdownFabric(props) {
    const { children, dropdownMenu, dropdownButton, dataTestId = "floatingDropdown" } = props;
    const {
      "aria-describedby": ariaDescribedBy,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      customCss,
      isInitialOpen = false,
      id = "floatingDropdownMenu",
      selectOptions,
      dataTestId: menuDataTestId = "floatingDropdownMenu",
    } = dropdownMenu;
    const {
      ariaDescribedBy: buttonAriaDescribedBy,
      ariaLabel: buttonAriaLabel,
      ariaLabelledBy: buttonAriaLabelledBy,
      dataTestId: buttonDataTestId = "floatingDropdownButton",
      className: buttonClassName,
      buttonId = buttonDataTestId,
      hasFocus = false,
    } = dropdownButton;
    const optionsMenu = useRef<HTMLInputElement>(null);
    const menuButton = useRef<HTMLButtonElement>(null);
    const optionsList = useRef<HTMLDivElement>(null);
    const dropdownStyles = useFloatingDropdownStyles();
    const [openMenu, setOpenMenu] = useState(isInitialOpen);
    const [activeDescendant, setActiveDescendant] = useState<string | undefined>(undefined);
    const icon: AccessibleImageProps = {
      urls: chevronClosedIcon,
      className: dropdownStyles.topButtonImg,
      role: "presentation",
    };
    // call hook that closes menu when user clicks outside of it
    useCloseOnOutsideClick(optionsMenu, setOpenMenu, () => {});

    const onCancel = () => {
      setOpenMenu(false);
      menuButton.current?.focus();
    };

    /**
     * Handle when Escape is pressed on the menu items pop-up
     * @param ev the keyboard event that triggered this function
     */
    const handleEscape = (ev: React.KeyboardEvent<HTMLUListElement>) => {
      if (ev.key === "Escape") {
        onCancel();
      }
    };

    const onOptionClick = (
      event: React.SyntheticEvent<HTMLSpanElement, Event>,
      onClick?: () => void,
    ) => {
      event.stopPropagation();
      if (onClick) {
        onClick();
      }

      setOpenMenu(false);
    };

    useGamepadCancel(optionsList, onCancel);
    useFocusElement(menuButton, hasFocus);

    return (
      <div ref={optionsMenu} className={dropdownStyles.mainContainer} data-testid={dataTestId}>
        <ImageButtonFabric
          image={icon}
          dataTestId={buttonDataTestId}
          ariaHasPopup="menu"
          ariaLabel={buttonAriaLabel}
          ariaLabelledBy={buttonAriaLabelledBy}
          ariaDescribedBy={buttonAriaDescribedBy}
          ariaRole="combobox"
          ariaActiveDescendant={openMenu ? activeDescendant : undefined}
          ariaControls={id}
          buttonId={buttonId}
          className={mergeClasses(dropdownStyles.topButton, buttonClassName)}
          onClick={() => setOpenMenu(!openMenu)}
          ref={menuButton}
          imgAfterContent
          ariaExpanded={openMenu}
        >
          {children}
        </ImageButtonFabric>
        {openMenu && (
          <FocusLocker wrapperRef={optionsList} className={dropdownStyles.menuFocusLocker}>
            <div ref={optionsList} className={dropdownStyles.menu}>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <ul
                aria-describedby={ariaDescribedBy}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledBy}
                className={mergeClasses(dropdownStyles.menuList, customCss)}
                data-testid={menuDataTestId}
                id={id}
                onKeyDown={handleEscape}
                {...gamepadMainContentAttributes}
                role="menu"
                aria-live="polite"
              >
                {selectOptions.map((option) => (
                  <li key={option.value} role="presentation" className={dropdownStyles.menuMargin}>
                    <LinkButton
                      linkId={option.value}
                      linkDataTestId={option.value}
                      text={option.text}
                      buttonRole="menuitem"
                      onClick={(e) => onOptionClick(e, option.onClick)}
                      aria-label={option.text}
                      onFocus={(e) => {
                        setActiveDescendant(e.currentTarget.id);
                      }}
                      className={dropdownStyles.menuLink}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </FocusLocker>
        )}
      </div>
    );
  };
