import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { type AccessibleImageProps, AccessibleImage } from "../../accessible-image";
import {
  type IDescriptionFabricProps,
  DescriptionFabric as Description,
} from "../../description/fabric/description-fabric";
import { InputContainer } from "../../inputs/input-container";
import {
  type TextButtonFabricProps,
  TextButtonContainer,
  TextButtonFabric as TextButton,
} from "../../text-button/fabric/text-button-fabric";
import { type ITitleFabricProps, TitleFabric as Title } from "../../title/fabric/title-fabric";
import { type ViewFormProps, ViewFormContainer } from "../view-form-container";

export type IDescriptionViewContainerProps = Omit<IDescriptionFabricProps, "children"> & {
  description: string | React.ReactElement;
};

export type ViewContainerProps = {
  title?: ITitleFabricProps;
  description?: IDescriptionViewContainerProps | IDescriptionViewContainerProps[];
  primaryButton?: TextButtonFabricProps;
  secondaryButton?: TextButtonFabricProps;
  image?: AccessibleImageProps;
  form?: ViewFormProps;
  rowClassName?: string;
  applyAriaLive?: boolean;
  isSecondary?: boolean;
};

/**
 * View Container that contains the standard components for a typical view.
 * @param props ViewContainerProps
 * @param props.children Any child component
 * @param props.secondaryButton Prop contains name and function of the button, eg: Cancel
 * @param props.primaryButton Prop contains name and function of the button, eg: Next/Submit
 * @param props.title Contains the title props
 * @param props.description Contains the description props for 1 or more descriptions
 * @param props.image Contains the image props
 * @param props.form Contains the form props
 * @param props.rowClassName Optional class name for the row
 * @param props.applyAriaLive Use aria-live instead of adding the view title + description as aria-labels on other elements to
 *   give the page context when the view loads for the first time.
 * @returns instance of the view
 */
export const ViewContainerFabric: React.FC<ViewContainerProps> = function ViewContainerFabric({
  children,
  primaryButton,
  secondaryButton,
  title,
  description,
  image: imageProps,
  form,
  rowClassName = "",
  applyAriaLive = false,
}) {
  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  let descriptions;
  if (description) {
    descriptions = description instanceof Array ? description : [description];
  }

  const content = (
    <>
      {applyAriaLive && title && (
        <div aria-live="polite" data-testid="titleAriaLive">
          <Title {...title} />
        </div>
      )}
      {!applyAriaLive && title && <Title {...title} />}
      {imageProps && (
        <div className={commonStyles.imageCenter}>
          <AccessibleImage {...imageProps} />
        </div>
      )}
      {descriptions &&
        descriptions.map((descriptionProps) => (
          <Description {...descriptionProps} key={descriptionProps.description.toString()}>
            {descriptionProps.description}
          </Description>
        ))}
      <div className={mergeClasses(commonStyles.row, rowClassName)}>
        <div className={commonStyles.textBlockBody}>{children}</div>
      </div>
      {(primaryButton || secondaryButton) && (
        <InputContainer>
          <div className={commonStyles.winButtonPinBottom}>
            <div className={commonStyles.row}>
              <TextButtonContainer>
                {secondaryButton && <TextButton {...secondaryButton} />}
                {primaryButton && <TextButton isPrimary {...primaryButton} />}
              </TextButtonContainer>
            </div>
          </div>
        </InputContainer>
      )}
    </>
  );

  if (form) {
    return <ViewFormContainer form={form}>{content}</ViewFormContainer>;
  }

  return content;
};
