import { useCallback } from "react";
import { useAuthContext } from "../authentication-context";
import { AuthenticationActionType } from "../authentication-reducer";
import { type HipType } from "../constants";
import { type ProofType } from "../model/proof";
import { type IUser } from "../model/user";
import { type SystemActionName } from "../telemetry-helpers/system-action-name";
import { type OtcFormApiTelemetryProps } from "../utilities/api-helpers/one-time-code/get-one-time-code-form";
import { getOneTimeCode } from "../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcChannel,
  type OtcFailureParams,
  type OtcPurposes,
  type OtcSuccessParams,
} from "../utilities/api-helpers/one-time-code/one-time-code-types";

export type BaseOtcParams = {
  user: IUser;
  channel: OtcChannel | null;
  purpose: OtcPurposes;
  proofType: ProofType;
  requestIsPending: boolean;
  setRequestIsPending:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((isPending: boolean) => void);
  proofData?: string;
  proofConfirmation?: string;
  errorOnPublicIdentifier?: boolean;
  isEncrypted?: boolean;
  sessionIdentifier?: string;
  challengeSolution?: string;
  challengeType?: HipType;
  challengeId?: string;
  challengeViewSupported?: string;
  phoneRepMetadata?: string;
  setHasError?: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess?: (params: Partial<OtcSuccessParams>) => void;
  onFailure?: (params: Partial<OtcFailureParams>) => void;
  telemetryCallback?: (
    actionName: SystemActionName,
    telemetryProps: OtcFormApiTelemetryProps,
  ) => void;
};

/**
 * This is the base hook used to provide a callback to make a OneTimeCode API call.
 * @param params The parameters required for the hook. These contain values to use as well as callbacks required for success/failure.
 * @returns A callback used to make a OneTimeCode API call.
 * This callback has "debouncing", so subsequent calls won't trigger another request while the current request is in progress.
 */
export const useSendOneTimeCode = (params: BaseOtcParams) => {
  const {
    user,
    requestIsPending,
    setRequestIsPending,
    setHasError,
    onFailure,
    onSuccess,
    errorOnPublicIdentifier = false,
    channel,
    purpose,
    proofType,
    proofData,
    proofConfirmation,
    isEncrypted,
    sessionIdentifier,
    challengeSolution,
    phoneRepMetadata,
    challengeType,
    challengeId,
    challengeViewSupported,
    telemetryCallback,
  } = params;
  const {
    authState: { flowTokenValue },
    dispatchStateChange: updateAuthContext,
  } = useAuthContext();

  const updateFlowToken = useCallback(
    (flowToken: string) => {
      updateAuthContext({ type: AuthenticationActionType.SetFlowTokenValue, payload: flowToken });
    },
    [updateAuthContext],
  );

  const otcFailure = useCallback(
    (props: Partial<OtcFailureParams>) => {
      const { flowToken: returnedFlowToken } = props;
      if (returnedFlowToken) {
        updateFlowToken(returnedFlowToken);
      }

      if (setHasError) {
        setHasError(true);
      }

      setRequestIsPending(false);

      if (onFailure) {
        onFailure(props);
      }
    },
    [setHasError, setRequestIsPending, onFailure, updateFlowToken],
  );

  const otcSuccess = useCallback(
    (successParams: OtcSuccessParams) => {
      const { flowToken: returnedFlowToken, response } = successParams;
      const success = !!response.SasParams?.Success;

      // If the preferred credential is PublicIdentifierCode and the request wasn't a success, we'll treat this like a failure.
      // This is because the endpoint wasn't returning a 4xx status code for this use-case like the rest.
      // AAD-TODO: Follow-up with Paulo to see if this has been fixed and we can remove this logic
      if (errorOnPublicIdentifier && !success) {
        otcFailure({ flowToken: returnedFlowToken, responseBody: response });
      } else {
        if (returnedFlowToken) {
          updateFlowToken(returnedFlowToken);
        }

        setRequestIsPending(false);

        if (onSuccess) {
          onSuccess(successParams);
        }
      }
    },
    [errorOnPublicIdentifier, onSuccess, otcFailure, setRequestIsPending, updateFlowToken],
  );

  const sendOneTimeCode = useCallback(() => {
    if (!requestIsPending) {
      setRequestIsPending(true);
      getOneTimeCode({
        flowToken: flowTokenValue,
        username: user.displayUsername,
        onSuccess: otcSuccess,
        onFailure: otcFailure,
        canaryFlowToken: flowTokenValue,
        channel,
        purpose,
        proofType,
        proofData,
        proofConfirmation,
        isEncrypted,
        sessionIdentifier,
        challengeSolution,
        challengeType,
        challengeId,
        challengeViewSupported,
        phoneRepMetadata,
        telemetryCallback,
      });
    }
  }, [
    requestIsPending,
    setRequestIsPending,
    flowTokenValue,
    user.displayUsername,
    otcSuccess,
    otcFailure,
    channel,
    purpose,
    proofType,
    proofData,
    proofConfirmation,
    isEncrypted,
    sessionIdentifier,
    challengeSolution,
    challengeType,
    challengeId,
    challengeViewSupported,
    phoneRepMetadata,
    telemetryCallback,
  ]);

  return sendOneTimeCode;
};
