import React from "react";
import StylesConfig from "../../../config/styles-config";
import ErrorContainerFabric from "../../error-container/fabric/error-container-fabric";
import { ArkoseEnforcementIframe } from "../arkose-enforcement-iframe";
import { type UseArkoseEnforceParams, useArkoseEnforce } from "../hooks/use-arkose-enforce";

export type ArkoseEnforceProps = UseArkoseEnforceParams & {
  strings: {
    iframeTitle: string;
  };
  errorMessage?: string | JSX.Element;
};

/**
 * Renders captcha provided by Arkose Enforce-service.
 * This component should be used through captcha-component, and should not be used directly.
 * @param props Props to configure Arkose Enforce and interact with the captcha.
 * @returns Visual tree to render and interact with captcha provided by Arkose Enforce-service
 */
export const ArkoseEnforceFabric = function ArkoseEnforceFabric(props: ArkoseEnforceProps) {
  const { arkoseUrl, iframeRef } = useArkoseEnforce(props);
  const { useArkoseEnforcementStyles } = StylesConfig.instance;
  const { iframe } = useArkoseEnforcementStyles();
  const { strings, errorMessage } = props;

  return (
    <div>
      <ArkoseEnforcementIframe
        url={arkoseUrl}
        iframeRef={iframeRef}
        className={iframe}
        title={strings.iframeTitle}
      />
      {errorMessage && (
        <ErrorContainerFabric id="arkoseEnforcementError" errorCode="arkoseEnforcementError">
          {errorMessage}
        </ErrorContainerFabric>
      )}
    </div>
  );
};
