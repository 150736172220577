import React from "react";
import { mergeClasses } from "@griffel/react";
import ErrorContainerFabric from "../../../../../components/error-container/fabric/error-container-fabric";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import { TileListFabric } from "../../../../../components/tile-list/fabric/tile-list-fabric";
import { TitleFabric } from "../../../../../components/title/fabric/title-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import {
  useActivateView,
  useBackButtonControl,
  useNavigateOrRedirectBack,
} from "../../../../../hooks";
import {
  commonLoginStringsFabric,
  loginInputValidationErrorStringsFabric,
} from "../../../fabric/common-login-strings-fabric";
import { useSetServerDataErrorOnViewFabric } from "../../../hooks/use-server-data-error-fabric";
import { useCredentialPickerTileListBuilder, useCredentialPickerViewProperties } from "../hooks";
import { credentialPickerStringsFabric } from "./credential-picker-strings-fabric";

/**
 * CredentialPicker component
 * @returns A rendered instance of this component
 */
export const CredentialPickerViewFabric: React.FC = function CredentialPickerViewFabric() {
  // Styles config data
  const { useCommonStyles, useStaticCommonStyles, useTileStyles } = StylesConfig.instance;

  const { allowCancel, showButtons } = GlobalConfig.instance;
  const isInitialView = !useBackButtonControl();

  const { availableCredentials, backLabel, redirect, title, setError, error } =
    useCredentialPickerViewProperties({
      commonLoginStrings: commonLoginStringsFabric,
      credentialPickerStrings: credentialPickerStringsFabric,
    });

  useActivateView(ViewId.CredentialPicker, FlowId.Login);

  const backButtonClickHandler = useNavigateOrRedirectBack();

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();
  const tileStyles = useTileStyles();

  const credentials = useCredentialPickerTileListBuilder(
    availableCredentials,
    redirect,
    {
      commonLoginStrings: commonLoginStringsFabric,
      credentialPickerStrings: credentialPickerStringsFabric,
    },
    setError,
  );

  // If there is an error from server in server data, show it on initialize
  useSetServerDataErrorOnViewFabric(
    {
      strings: loginInputValidationErrorStringsFabric,
    },
    setError,
  );

  return (
    <>
      <TitleFabric titleId="credentialPickerTitle" title={title} />

      {error && (
        <ErrorContainerFabric
          id="errorText"
          aria-relevant="text"
          aria-atomic="true"
          className={mergeClasses(
            commonStyles.row,
            commonStyles.textBody,
            commonStyles.textBlockBody,
            tileStyles.tileError,
            commonStyles.alertError,
          )}
        >
          {error}
        </ErrorContainerFabric>
      )}

      <div className={commonStyles.formGroup}>
        <TileListFabric
          ariaLabelledBy={`errorText ${title}`}
          ariaDescribedBy={title}
          tileList={credentials}
        />
      </div>

      {showButtons && (!isInitialView || !!allowCancel) && (
        <div className={commonStyles.winButtonPinBottom}>
          <div className={commonStyles.buttonMargin}>
            <TextButtonContainer>
              <TextButtonFabric
                buttonId="backButton"
                label={backLabel}
                isPrimary={false}
                ariaLabel={backLabel}
                onClick={backButtonClickHandler}
              />
            </TextButtonContainer>
          </div>
        </div>
      )}
    </>
  );
};
