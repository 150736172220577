import { useAuthContext } from "../../../authentication-context";
import { AuthenticationActionType } from "../../../authentication-reducer";
import { ChallengeActionType, useChallengeContext } from "../../../challenge-context";
import { ViewId } from "../../../constants";
import GlobalConfig from "../../../global-config";
import { useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { useNavigateDirection } from "../../../hooks";
import { cleanseUsername } from "../../../model/user";
import {
  type OtcFormParams,
  getOneTimeCode,
} from "../../../utilities/api-helpers/one-time-code/get-one-time-code-form";
import { isChallengeViewSupported } from "../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import { type OtcSuccessParams } from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { getRouteFromViewId } from "../../../utilities/routing-helper";

export const useGetOtcOnSuccess = () => {
  const navigate = useNavigateDirection();
  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();
  const { dispatchStateChange: dispatchAuthStateChange } = useAuthContext();
  const { dispatchChallengeStateChange } = useChallengeContext();

  return (otcParams: OtcSuccessParams) => {
    if (otcParams.flowToken) {
      dispatchAuthStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: otcParams.flowToken,
      });
    }

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: false,
    });

    dispatchChallengeStateChange({
      type: ChallengeActionType.SetCommonAction,
      payload: {
        errorMessage: "",
      },
    });

    navigate(ViewId.Challenge, getRouteFromViewId(ViewId.OneTimeCode), false, { replace: true });
  };
};

export const useGetOtcParams = () => {
  const {
    activeFlavor,
    unauthenticatedSessionId,
    siteId,
    clientId,
    localeId,
    forwardedClientId,
    noPaBubbleVersion,
  } = GlobalConfig.instance;

  const {
    globalState: {
      user: { username },
    },
  } = useGlobalContext();

  const cleansedUsername = cleanseUsername(username.unsafeUnescapedString);

  const {
    authState: { flowTokenValue: flowToken },
  } = useAuthContext();

  const {
    challengeState: { phoneRepMetadata },
  } = useChallengeContext();

  return {
    clientId,
    flowToken,
    forwardedClientId,
    localeId,
    noPaBubbleVersion,
    siteId,
    unauthSessionId: unauthenticatedSessionId,
    username: cleansedUsername,
    challengeViewSupported: isChallengeViewSupported(activeFlavor),
    phoneRepMetadata,
  };
};

/**
 *  A wrapper so we can match the UseValidateChallenge request signature between Idps
 * @param url the url of the request. Pass empty string for GetOtc
 * @param params the params for the request
 * @returns the getOneTimeCode handler
 */
export const getOtcRequest = (url: string, params: OtcFormParams) => getOneTimeCode(params);
