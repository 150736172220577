import { Flavors } from "../../constants";
import GlobalConfig from "../../global-config";
import { handleBackButtonEvent } from "../../utilities/cxh-handlers/cxh-handler";
import { useEffectOnce } from "../use-effect-once";

/**
 * @param onBackButtonClick The back button click handler to register with CXH
 */
export const useBackButtonHandlerWithCxh = (
  onBackButtonClick?: (event: React.SyntheticEvent<HTMLElement>) => void,
) => {
  const { activeFlavor } = GlobalConfig.instance;

  useEffectOnce(() => {
    // if Windows, register CXH back button event listener
    if (activeFlavor === Flavors.Win11OobeFabric) {
      handleBackButtonEvent(onBackButtonClick);
    }
  });
};
