import React from "react";
import { SecondaryContentContainerFabric } from "../../../../components";
import { ViewContainerFabric } from "../../../../components/view-container/fabric/view-container-fabric";
import StylesConfig from "../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateViewWithDocumentTitle } from "../../../../hooks/use-activate-view";
import { passkeyGif } from "../../../../utilities/image-helpers/accessible-images";
import { CredentialSwitchContentFabric } from "../../components/fabric/credential-switch-content-fabric";
import { usePasskeyInterstitialViewProperties } from "../hooks/use-passkey-interstitial-view-properties";

/**
 * PasskeyInterstitialView component
 * @returns A rendered instance of this component
 */
export const PasskeyInterstitialViewFabric: React.FC = function PasskeyInterstitialViewFabric() {
  const { useCommonStyles } = StylesConfig.instance;
  const { passkeyImage, row } = useCommonStyles();

  const {
    title,
    description,
    nextLabel,
    showSwitchToEvictedCredPicker,
    showCredentialSwitchContent,
    credentialSwitchLinksProps,
    fidoRedirect,
  } = usePasskeyInterstitialViewProperties();

  useActivateViewWithDocumentTitle(title, ViewId.LoginPasskeyInterstitial, FlowId.Login, {
    showBackButtonOnActiveView: true,
  });

  return (
    <ViewContainerFabric
      title={{ title, titleId: "passkeyTitle" }}
      primaryButton={{
        label: nextLabel,
        type: "submit",
        onClick: fidoRedirect,
      }}
      image={{
        urls: passkeyGif,
        role: "presentation",
        dataTestId: "passkey",
        className: passkeyImage,
      }}
      description={{ description, descriptionId: "passkeyInterstitialViewDescription" }}
    >
      {showCredentialSwitchContent && (
        <div className={row}>
          <SecondaryContentContainerFabric>
            <CredentialSwitchContentFabric
              credentialSwitchProps={credentialSwitchLinksProps}
              showSwitchToEvictedCredPicker={showSwitchToEvictedCredPicker}
            />
          </SecondaryContentContainerFabric>
        </div>
      )}
    </ViewContainerFabric>
  );
};
