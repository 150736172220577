/**
 * Console-based telemetry provider
 */
var ConsoleTelemetryProvider = /** @class */ (function () {
    function ConsoleTelemetryProvider() {
    }
    // This needs to be an instance method to implement the ITelemetryProvider interface
    /* eslint-disable class-methods-use-this */
    /**
     * Logs an event to the console
     * @param event The event to log
     */
    ConsoleTelemetryProvider.prototype.addEvent = function (event) {
        // Allow writing to the console here since that is the whole purpose of this logger
        // eslint-disable-next-line no-console
        console.log("Logged event: ".concat(JSON.stringify(event, undefined, 2)));
    };
    return ConsoleTelemetryProvider;
}());
export { ConsoleTelemetryProvider };
