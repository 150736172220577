import { makeStyles, shorthands } from "@griffel/react";

export const useCaptchaViewStylesFabric = makeStyles({
  targetImageContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    marginBottom: "8px",
  },
  targetImage: {
    backgroundColor: "#fff",
    display: "inline",
    height: "96px",
    width: "216px",
    ...shorthands.border("0px", "solid", "#fff"),
  },
  menuItems: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
  },
  imageButton: {
    backgroundColor: "#fff",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.border("0px", "solid", "#fff"),
  },
  imageButtonText: {
    marginLeft: "8px",
  },
});

export type CaptchaViewStylesFabric = ReturnType<typeof useCaptchaViewStylesFabric>;
