import { useContext } from "react";
import { useCustomizationContext } from "../../../../context/customization-context";
import { GlobalContext } from "../../../../global-context";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { type CommonLoginStrings } from "../../login-interface";
import { getCommonDocumentTitle } from "../../login-util";
import { getPageDescription } from "../idp-disambiguation-view-util";

export interface IIdpDisambiguationViewProperties {
  documentTitle: string;
  pageDescription: string;
}

/**
 * @returns Login IDP disambiguation view properties
 * @param strings Flavored strings used by this hook
 * @param strings.commonLoginStrings Common login strings
 */
export const useIdpDisambiguationViewProperties = (strings: {
  commonLoginStrings: CommonLoginStrings;
}): IIdpDisambiguationViewProperties => {
  const { commonLoginStrings } = strings;

  // Global context data
  const {
    globalState: {
      user: { displayUsername },
    },
  } = useContext(GlobalContext);
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  // Login flow context data
  const {
    viewState: { hasIdpDisambigError },
  } = useContext(LoginContext);

  // Login config data
  const { loginMode } = LoginConfig.instance;

  const documentTitle = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);
  const pageDescription = getPageDescription(displayUsername, hasIdpDisambigError);

  return {
    documentTitle,
    pageDescription,
  };
};
