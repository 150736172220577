import React from "react";
import { mergeClasses } from "@griffel/react";
import ErrorContainerFabric from "../../../../../components/error-container/fabric/error-container-fabric";
import StylesConfig from "../../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../../constants/routing-constants";
import { useActivateView, useDocumentTitle } from "../../../../../hooks";
import {
  commonBindingsFabric,
  FormattedTextWithBindings,
} from "../../../../../utilities/formatted-text-with-bindings";
import { LoginTitleDescriptionFabric } from "../../../components/fabric/login-title-description-fabric";
import { commonLoginStringsFabric } from "../../../fabric/common-login-strings-fabric";
import { useErrorViewProperties, useRenderManageCredentialsLink } from "../hooks";
import { errorViewStringsFabric } from "./error-view-strings-fabric";

/**
 * ErrorView component
 * @returns A rendered instance of this component
 */
export const ErrorViewFabric: React.FC = function ErrorViewFabric() {
  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  const {
    documentTitle,
    lightboxTitle,
    lightboxTitleId,
    loginModeErrorDesc,
    loginModeErrorDescId,
    errorCodeDescription,
    usernameDescription,
    isBindFailedMode,
    isGenericErrorMode,
    isSwitchUserMode,
    isHipLockedMode,
    manageCredsLink,
    resetPasswordLink,
    staySignInLink,
    switchUserLink,
  } = useErrorViewProperties({
    commonLoginStrings: commonLoginStringsFabric,
    errorViewStrings: errorViewStringsFabric,
  });

  const { lockoutReasonIncorrectPasswordTooManyTimes, lockoutReasonOrgAccountNotReady } =
    errorViewStringsFabric;

  const { boldText } = commonBindingsFabric;
  const commonStyles = useCommonStyles();

  useActivateView(ViewId.Error, FlowId.Login);
  useDocumentTitle(documentTitle);
  useStaticCommonStyles();

  const renderManageCredentialsLink = useRenderManageCredentialsLink(manageCredsLink);

  return (
    <>
      <LoginTitleDescriptionFabric
        titleId={lightboxTitleId}
        title={lightboxTitle}
        showSubtitle={!isBindFailedMode}
      />
      <div className={commonStyles.textBlockBody}>
        {loginModeErrorDesc && (
          <ErrorContainerFabric
            id={loginModeErrorDescId}
            // TODO: add external error class when we tackle ESTS
            className={mergeClasses(commonStyles.textBody, commonStyles.error)}
          >
            {loginModeErrorDesc}
          </ErrorContainerFabric>
        )}
        {errorCodeDescription && (
          <ErrorContainerFabric
            id="error_Info"
            // TODO: add external error class when we tackle ESTS
            className={mergeClasses(commonStyles.textBody, commonStyles.error)}
          >
            <FormattedTextWithBindings
              text={errorCodeDescription}
              embeddedBindings={{
                manageCredsUrl: renderManageCredentialsLink,
              }}
            />
          </ErrorContainerFabric>
        )}
        {usernameDescription && (
          <div id="username" className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
            <FormattedTextWithBindings text={usernameDescription} embeddedBindings={{ boldText }} />
          </div>
        )}
        {isHipLockedMode && (
          <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
            <div className={commonStyles.formGroup}>
              {lockoutReasonIncorrectPasswordTooManyTimes}
            </div>
            <div className={commonStyles.formGroup}>{lockoutReasonOrgAccountNotReady}</div>
          </div>
        )}
      </div>
      {!isGenericErrorMode && (
        <div className={commonStyles.row}>
          {isHipLockedMode && (
            <div className={commonStyles.formGroup}>
              <a
                ref={resetPasswordLink.ref}
                id={resetPasswordLink.id}
                aria-describedby={resetPasswordLink.ariaDescribedBy}
                href={resetPasswordLink.href}
              >
                {resetPasswordLink.text}
              </a>
            </div>
          )}
          {isSwitchUserMode && (
            <div className={commonStyles.formGroup}>
              <a
                ref={staySignInLink.ref}
                id={staySignInLink.id}
                aria-describedby={staySignInLink.ariaDescribedBy}
                href={staySignInLink.href}
              >
                {staySignInLink.text}
              </a>
            </div>
          )}
          <div className={commonStyles.formGroup}>
            <a
              ref={switchUserLink.ref}
              id={switchUserLink.id}
              aria-describedby={switchUserLink.ariaDescribedBy}
              href={switchUserLink.href}
            >
              {switchUserLink.text}
            </a>
          </div>
        </div>
      )}
    </>
  );
};
