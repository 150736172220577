import { useEffect, useRef } from "react";
import { HipType } from "../../../constants";
import { useMessage } from "../../../hooks/use-message";

/**
 * Configuration of Arkose Enforcement and callback methods to listen to events.
 */
export type ArkoseEnforceConfig = {
  /**
   * Partner Id of ALC with Arkose Enforce-service.
   */
  partnerId?: string;

  /**
   * Data provided by Arkose Enforce to identify connection.
   */
  connectionData: string | undefined;

  /**
   * Url of Arkose Enforce service.
   */
  url: string;

  /**
   * Timeout in milliseconds after which to act.
   */
  timeout: string;
};

/**
 * Event payload emitted by Arkose Enforce.
 */
export type ArkoseMessageEventData = {
  payload: {
    frameHeight: number;
    frameWidth: number;
    sessionToken: string;
  };
};

export type UseArkoseEnforceParams = {
  config: ArkoseEnforceConfig;
  onLoadingStateChange: (isLoading: boolean) => void;
  /** Challenge solved */
  onSuccess: (params: { solution: string; type: HipType; token: string }) => void;
  /** Challenge failed to load */
  onTimeout: () => void;
  onBack: () => void;
  /** Challenge loaded */
  onSessionStart: (params: { sessionToken: string; type: HipType }) => void;
};

type ArkoseEnforceResponse = {
  arkoseUrl: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
};

/**
 * Hook to handle Arkose Enforce iFrame init and listen to events. This is where most of the logic for Arkose lives.
 * @param params params for the iFrame
 * @param params.config config for Arkose Enforce events
 * @param params.config.connectionData any extra data required by arkose iFrame
 * @param params.config.timeout timeout in milliseconds for frame to load
 * @param params.config.url url for the arkose service
 * @param params.onLoadingStateChange callback when loading is complete
 * @param params.onSuccess callback when challenege complete
 * @param params.onTimeout callback when timeout is reached
 * @param params.onBack callback when back button is clicked
 * @param params.onSessionStart callback when session starts
 * @returns Url for frame and ref for iFrame
 */
export const useArkoseEnforce = ({
  config: { connectionData, timeout, url },
  onLoadingStateChange,
  onSuccess,
  onTimeout,
  onBack,
  onSessionStart,
}: UseArkoseEnforceParams): ArkoseEnforceResponse => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const loadTimeoutRef = useRef<number>();
  const arkoseUrl = `${url}${connectionData ? `&data=${connectionData}` : ""}`;

  // onMount, set timeout and loading state
  useEffect(() => {
    onLoadingStateChange(true);

    const loadTimeout = parseInt(timeout as string, 10);
    loadTimeoutRef.current = setTimeout(() => {
      onLoadingStateChange(false);
      onTimeout();
    }, loadTimeout) as unknown as number;

    return () => clearTimeout(loadTimeoutRef.current);
  }, [onLoadingStateChange, onTimeout, timeout]);

  const resizeEnforcement = ({ payload: { frameHeight, frameWidth } }: ArkoseMessageEventData) => {
    const { current: iframe } = iframeRef;
    if (!iframe) {
      return;
    }

    iframe.style.height = `${frameHeight + 40}px`; // for unknown reason frameHeight comes back 40px too small
    iframe.style.width = `${frameWidth}px`;
  };

  /** Message listeners from Arkose iFrame */
  useMessage<ArkoseMessageEventData>(
    (event: ArkoseMessageEventData) => {
      onLoadingStateChange(false);
      resizeEnforcement(event);
      clearTimeout(loadTimeoutRef.current);
      onSessionStart({ sessionToken: event.payload.sessionToken, type: HipType.Enforcement });
    },
    { iFrameUrl: arkoseUrl },
    "challenge-loaded",
  );

  useMessage<ArkoseMessageEventData>(
    ({ payload: { sessionToken } }: ArkoseMessageEventData) => {
      onSuccess({
        solution: sessionToken,
        type: HipType.Enforcement,
        token: sessionToken,
      });
    },
    { iFrameUrl: arkoseUrl },
    "challenge-complete",
  );

  useMessage<ArkoseMessageEventData>(
    resizeEnforcement,
    {
      iFrameUrl: arkoseUrl,
    },
    "challenge-iframeSize",
  );

  useMessage<ArkoseMessageEventData>(onBack, { iFrameUrl: arkoseUrl }, "back_key_pressed");

  return { arkoseUrl, iframeRef };
};
