import { makeStyles } from "@griffel/react";

export const usePhoneNumberStylesFabric = makeStyles({
  phoneNumberContainer: {
    position: "relative",
  },
  phoneNumberContainerDeprecated: {
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
    width: "100%",
    marginBottom: "9px",
  },
  inlineInput: {
    display: "inline-block",
    paddingLeft: "16px",
    width: "79.16667%",
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingRight: "2px",
    "@media only screen and (max-width: 320px)": {
      width: "100%",
      display: "block",
      paddingLeft: "0px",
      paddingRight: "0px",
      ":global([dir='rtl'])": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    ":global([dir='rtl'])": {
      paddingRight: "16px",
      paddingLeft: "0px",
      float: "right",
    },
  },
  nonInlineInput: {
    width: "100%",
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingRight: "2px",
  },
  errorContainer: {
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
  },
});

export type PhoneNumberStylesFabric = ReturnType<typeof usePhoneNumberStylesFabric>;
