import { useGlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { useRedirect } from "../../../../hooks";
import { type IUser } from "../../../../model/user";
import { appendOrReplaceQueryStringParams, trim } from "../../../../utilities/strings-helper";
import LoginConfig from "../../login-config";

/**
 * This hook is used when the lost authenticator link is clicked.
 * @param user The IUser object
 * @returns The click handler for the lost authenticator link.
 */
export const useLostAuthenticatorLink = (user: IUser) => {
  const onRedirect = useRedirect();
  const { lostAuthenticatorUrl, resetPasswordPrefillParam } = LoginConfig.instance;
  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();

  return () => {
    const lostAuthenticatorLinkUrl = appendOrReplaceQueryStringParams(
      lostAuthenticatorUrl,
      {
        [resetPasswordPrefillParam]: encodeURIComponent(
          trim(user.displayUsername.unsafeUnescapedString),
        ),
      },
      true,
    );

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: true,
    });
    onRedirect(lostAuthenticatorLinkUrl);
  };
};
