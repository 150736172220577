import { Flavors } from "../../constants";
import { loginStringsFabric } from "./fabric/login-strings-fabric";
import { type LoginStrings, loginStringsConfig } from "./login-strings-config";

/**
 * This function maps a flavor (i.e Xbox, Fabric) to a set of flavored login strings.
 * @param flavor The active flavor.
 */
const loginStringsMap = new Map<Flavors, LoginStrings>([[Flavors.Fabric, loginStringsFabric]]);

/**
 * Initialize Login strings
 * @param flavor The active flavor.
 */
export function initLoginStrings(flavor: Flavors) {
  loginStringsConfig.initialize({
    loginStrings: loginStringsMap.get(flavor),
  });
}
