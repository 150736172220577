import { useCallback } from "react";
import { useTelemetryState } from "../../telemetry-helpers/use-telemetry-state";
import { getText } from "../../utilities/request/request-helper";
import { AgreementType } from "./agreement-view-interface";
import { buildAccessibleHtml, getQueryUrl } from "./agreement-view-util";

export type UseFetchAgreementTextParams = {
  agreementType: AgreementType;
  privacyUrl: string;
  termsOfUseUrl: string;
  setDownloadError: (error: boolean) => void;
  setAgreementHtml: (html: string) => void;
};

export const useFetchAgreementText = (params: UseFetchAgreementTextParams) => {
  const { agreementType, privacyUrl, termsOfUseUrl, setDownloadError, setAgreementHtml } = params;

  const telemetryState = useTelemetryState();

  const fetchAgreementText = useCallback(async () => {
    try {
      const processingStartTime = new Date();
      let url: string;
      let processHtml = true;
      switch (agreementType) {
        case AgreementType.Privacy:
          url = privacyUrl;
          break;
        case AgreementType.ChinaPrivacy:
          // ChinaPrivacy is simply Privacy, but it's always loaded via the query.aspx API for the ChinaPIPL view in SignUp.
          // Privacy itself may configure `privacyUrl` to be the query.aspx API, but not for the ChinaPIPL view in SignUp.
          url = getQueryUrl(AgreementType.Privacy);
          break;
        case AgreementType.KoreaConsentDataCollection:
        case AgreementType.KoreaConsentDataProvision:
        case AgreementType.ChinaPiplLearnMore:
          url = getQueryUrl(agreementType);
          processHtml = false;
          break;
        case AgreementType.TermsOfUse:
        default:
          url = termsOfUseUrl;
          break;
      }

      const responseText = await getText(url, {
        processingStartTime,
        telemetryState,
      });

      const htmlText = processHtml ? buildAccessibleHtml(responseText) : responseText;
      setAgreementHtml(htmlText);
    } catch {
      setDownloadError(true);
    }
  }, [
    agreementType,
    telemetryState,
    setAgreementHtml,
    privacyUrl,
    termsOfUseUrl,
    setDownloadError,
  ]);

  return fetchAgreementText;
};
