import { makeStaticStyles, makeStyles, shorthands } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { isIENewerThan10 } from "../../utilities/browser-helper";
import {
  type CommonStylesFabric,
  useCommonStylesFabric,
  useStaticCommonStylesFabric,
} from "../fabric";
import * as fabricStyleConstants from "../fabric/constants-fabric.styles";
import * as commonStyleConstants from "./constants-win11-oobe-fabric.styles";
import * as textButtonConstants from "./text-button-constants-win11-oobe-fabric.styles";
import * as titleStyleConstants from "./title-constants-win11-oobe-fabric.styles";

const useOverrideStaticStyles = makeStaticStyles({
  "html, body": { ...shorthands.margin(0), height: "100%" },

  body: {
    backgroundColor: "transparent",
    fontFamily: "Segoe UI Variable, Segoe UI, Selawik, Tahoma, Verdana, Arial, sans-serif",
    fontSize: "87.5%",
    overflow: "hidden",

    "& > div, & > form": { height: "100%" },
  },

  [[
    'input[type="color"]',
    'input[type="date"]',
    'input[type="datetime"]',
    'input[type="datetime-local"]',
    'input[type="email"]',
    'input[type="month"]',
    'input[type="number"]',
    'input[type="password"]',
    'input[type="search"]',
    'input[type="tel"]',
    'input[type="text"]',
    'input[type="time"]',
    'input[type="url"]',
    'input[type="week"]',
    "textarea",
  ].join(", ")]: { backgroundColor: "transparent", ":focus": { backgroundColor: "transparent" } },

  [['input[type="button"]', 'input[type="submit"]', 'input[type="reset"]'].join(", ")]: {
    minHeight: "40px",
    minWidth: "128px",
    width: "auto !important",
    ...shorthands.padding("8px", "12px", "8px", "12px"),
    lineHeight: "normal",
    backgroundColor: "#0067C0",
    color: "#FFF",

    ":active": {
      backgroundColor: "#0078D4",
      outline: "none",
      textDecoration: "none",
      "-ms-transform": "scale(1)",
      WebkitTransform: "scale(1)",
      transform: "scale(1)",
    },

    ":focus": { textDecoration: "none", outline: "none" },
  },

  [[
    'input[type="color"]',
    'input[type="date"]',
    'input[type="datetime"]',
    'input[type="datetime-local"]',
    'input[type="email"]',
    'input[type="month"]',
    'input[type="number"]',
    'input[type="password"]',
    'input[type="search"]',
    'input[type="tel"]',
    'input[type="text"]',
    'input[type="time"]',
    'input[type="url"]',
    'input[type="week"]',
    "textarea",
    "select",
  ].join(", ")]: {
    height: "36px !important",
    borderRadius: "3px !important",
    paddingLeft: "12px !important",
    background: "rgba(255, 255, 255, 0.7)",
    border: "1px solid rgba(0, 0, 0, 0.0578)",
    boxShadow: commonStyleConstants.INPUT_BOX_SHADOW,
    WebkitBoxShadow: commonStyleConstants.INPUT_BOX_SHADOW,
    "-moz-box-shadow": commonStyleConstants.INPUT_BOX_SHADOW,
  },

  [[
    'input[type="color"]:hover',
    'input[type="date"]:hover',
    'input[type="datetime"]:hover',
    'input[type="datetime-local"]:hover',
    'input[type="email"]:hover',
    'input[type="month"]:hover',
    'input[type="number"]:hover',
    'input[type="password"]:hover',
    'input[type="search"]:hover',
    'input[type="tel"]:hover',
    'input[type="text"]:hover',
    'input[type="time"]:hover',
    'input[type="url"]:hover',
    'input[type="week"]:hover',
    "textarea:hover",
    "select:hover",
  ].join(", ")]: {
    background: "#F9F9F9",
    border: "1px solid rgba(0, 0, 0, 0.0578)",
  },

  [[
    'input[type="color"]:focus',
    'input[type="date"]:focus',
    'input[type="datetime"]:focus',
    'input[type="datetime-local"]:focus',
    'input[type="email"]:focus',
    'input[type="month"]:focus',
    'input[type="number"]:focus',
    'input[type="password"]:focus',
    'input[type="search"]:focus',
    'input[type="tel"]:focus',
    'input[type="text"]:focus',
    'input[type="time"]:focus',
    'input[type="url"]:focus',
    'input[type="week"]:focus',
    "textarea:focus",
    "select:focus",

    'input[type="color"]:active',
    'input[type="date"]:active',
    'input[type="datetime"]:active',
    'input[type="datetime-local"]:active',
    'input[type="email"]:active',
    'input[type="month"]:active',
    'input[type="number"]:active',
    'input[type="password"]:active',
    'input[type="search"]:active',
    'input[type="tel"]:active',
    'input[type="text"]:active',
    'input[type="time"]:active',
    'input[type="url"]:active',
    'input[type="week"]:active',
    "textarea:active",
    "select:active",
  ].join(", ")]: {
    background: "#FFF",
    boxShadow: "0px 2px 0px #0067C0",
  },

  // @TODO: Once Edge browser is available for integration testing, disable other browsers and remove this check
  ...(isIENewerThan10()
    ? {
        [[
          'input[type="color"]::-ms-reveal',
          'input[type="date"]::-ms-reveal',
          'input[type="datetime"]::-ms-reveal',
          'input[type="datetime-local"]::-ms-reveal',
          'input[type="email"]::-ms-reveal',
          'input[type="month"]::-ms-reveal',
          'input[type="number"]::-ms-reveal',
          'input[type="password"]::-ms-reveal',
          'input[type="search"]::-ms-reveal',
          'input[type="tel"]::-ms-reveal',
          'input[type="text"]::-ms-reveal',
          'input[type="time"]::-ms-reveal',
          'input[type="url"]::-ms-reveal',
          'input[type="week"]::-ms-reveal',
          "textarea::-ms-reveal",
          "select::-ms-reveal",
        ].join(", ")]: {
          background: "transparent",
          color: "#000",
          marginRight: "-4px",
          marginTop: "-4px",
        },

        [[
          'input[type="color"]::-ms-reveal:checked',
          'input[type="date"]::-ms-reveal:checked',
          'input[type="datetime"]::-ms-reveal:checked',
          'input[type="datetime-local"]::-ms-reveal:checked',
          'input[type="email"]::-ms-reveal:checked',
          'input[type="month"]::-ms-reveal:checked',
          'input[type="number"]::-ms-reveal:checked',
          'input[type="password"]::-ms-reveal:checked',
          'input[type="search"]::-ms-reveal:checked',
          'input[type="tel"]::-ms-reveal:checked',
          'input[type="text"]::-ms-reveal:checked',
          'input[type="time"]::-ms-reveal:checked',
          'input[type="url"]::-ms-reveal:checked',
          'input[type="week"]::-ms-reveal:checked',
          "textarea::-ms-reveal:checked",
          "select::-ms-reveal:checked",
        ].join(", ")]: {
          background: "#003E92",
          color: "#FFF",
          borderRadius: "4px",
        },

        'input[type="password"]::-ms-reveal': {
          background: "transparent",
          color: "#000",
          marginRight: "-4px",
          marginTop: "-4px",
        },

        'input[type="password"]::-ms-reveal:checked': {
          background: "#003E92",
          color: "#FFF",
          borderRadius: "4px",
        },
      }
    : {}),

  'input[type="checkbox"], input[type="radio"]': { outline: "none !important" },

  // @TODO: Once Edge browser is available for integration testing, disable other browsers and remove this check
  ...(isIENewerThan10()
    ? {
        'input[type="checkbox"]::-ms-check': {
          background: "rgba(0, 0, 0, 0.0241)",
          border: "1px solid rgba(0, 0, 0, 0.6063)",
          borderRadius: "3px",
        },

        'input[type="checkbox"]:hover::-ms-check': { background: "rgba(0, 0, 0, 0.0578)" },

        'input[type="checkbox"]:disabled::-ms-check': { border: "1px solid rgba(0, 0, 0, 0.2169)" },

        'input[type="checkbox"]:focus::-ms-check': {
          border: "2px solid rgba(0, 0, 0, 0.8956)",
          boxSizing: "border-box",
          borderRadius: "7px",
        },

        'input[type="checkbox"]:checked::-ms-check': {
          color: "#FFFFFF",
          background: "#005FB8",
          border: "1px solid #005FB8",
          borderRadius: "3px",
        },

        'input[type="checkbox"]:checked:focus::-ms-check': {
          border: "2px solid rgba(0, 0, 0, 0.8956)",
          boxSizing: "border-box",
          borderRadius: "7px",
        },
      }
    : {}),

  a: {
    color: "#006CBE",
    textDecoration: "none",
    margin: "1px 0px",
    outline: "none",
    padding: "2px 0px",

    "&:active, &:hover, &:focus": { textDecoration: "underline" },
  },

  "::selection": {
    color: "#FFF",
    background: "#0067C0",
  },
});

export const useStaticCommonStylesWin11OobeFabric = () => {
  useStaticCommonStylesFabric();
  useOverrideStaticStyles();
};

const useOverrideCommonStyles = makeStyles({
  buttonMargin: {
    display: "block",
  },

  flexColumn: { display: "flex", flexDirection: "column", height: "100%" },

  flexContainer: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    ...shorthands.overflow("hidden", "hidden"),
  },

  flexIFrame: {
    flexGrow: 1,
    ...shorthands.border("none", "none", "none"),
    ...shorthands.margin(0),
    ...shorthands.padding(0),
    width: "100%",
  },

  formControl: { maxWidth: "390px" },

  formGroup: {
    paddingLeft: "0px",
    paddingRight: "0px",

    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1024P]: {
      marginBottom: "8px",
    },

    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
      marginBottom: "12px",
    },
  },

  fullHeight: { height: "100%" },

  inner: {
    backgroundColor: "transparent",
    maxWidth: `${commonStyleConstants.LIGHTBOX_MAX_WIDTH}px`,

    [commonStyleConstants.MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(`${commonStyleConstants.LIGHTBOX_MOBILE_PADDING}px`),
      marginTop: `${commonStyleConstants.LIGHTBOX_MOBILE_MARGIN_TOP}px`,
      marginBottom: `${commonStyleConstants.LIGHTBOX_MOBILE_MARGIN_BOTTOM}px`,
      width: "100vw",
      minWidth: "auto",
      "-webkit-box-shadow": "none",
      "-moz-box-shadow": "none",
      "box-shadow": "none",
      ...shorthands.border(0),
    },

    // need to overwrite fabric media query explicitly because otherwise it gets selected first for screens <= 600px wide
    [fabricStyleConstants.MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(`${commonStyleConstants.LIGHTBOX_MOBILE_PADDING}px`),
      marginTop: `${commonStyleConstants.LIGHTBOX_MOBILE_MARGIN_TOP}px`,
      marginBottom: `${commonStyleConstants.LIGHTBOX_MOBILE_MARGIN_BOTTOM}px`,
    },

    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
      marginTop: "0px",
    },
  },

  lightboxCover: {
    backgroundColor: "transparent",
  },

  middle: { display: "flex", flexDirection: "column", height: "calc(100% - 78px)" },

  msaAgreementLayout: { "& > p": { maxWidth: "none" } },

  outer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    ...shorthands.overflow("hidden"),
  },

  positionButtons: {
    "& > div:nth-child(1)": {
      [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
        marginBottom: "0px",
      },
    },
  },

  positionButtonsWithBottomMargin: {
    "& > div:nth-child(1)": {
      [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
        marginBottom: "0px",
      },
    },
  },

  topMarginAfterSecondaryContent: {
    marginTop: `${textButtonConstants.MINHEIGHT + commonStyleConstants.SECTION_PADDING}px`,

    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
      marginTop: "0px",
    },
  },

  inputContainerWithoutBoilerPlate: {
    "& > div:nth-child(1)": {
      marginBottom: "0px",
    },
  },

  textCaption: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
    marginTop: "8px",
    marginRight: "",
    marginBottom: "8px",
    marginLeft: "",
    paddingTop: "unset",
    paddingBottom: "unset",

    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1024P]: {
      fontSize: "0.7rem",
      lineHeight: "0.95rem",
      marginBottom: "0px",
      marginTop: "4px",
      paddingBottom: "0px",
    },

    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
      fontSize: "0.5625rem",
      lineHeight: "0.9rem",
      marginTop: "2px",
    },
  },

  wide: {
    maxWidth: `${commonStyleConstants.LIGHTBOX_MAX_WIDTH}px`,
  },

  winButtonPinBottom: { bottom: "4px", paddingRight: "15px", position: "fixed", width: "100%" },

  winFidoDescription: {
    fontSize: ".9375rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    marginTop: "12px",
    marginBottom: "32px",
    ...shorthands.padding(0),
  },

  winFidoImage: {
    height: "24px",
    width: "24px",
    marginTop: titleStyleConstants.TITLE_MARGIN_TOP,
    marginBottom: titleStyleConstants.TITLE_MARGIN_BOTTOM,
    marginRight: "6px",
  },

  winScroll: {
    flexBasis: "auto",
    flexGrow: 1,
    flexShrink: 1,
    height: "calc(100% - 12px)",
    marginTop: "12px",
    "-ms-overflow-style": "scrollbar",
    overflowX: "hidden",
    overflowY: "auto",
    paddingRight: "12px",

    [commonStyleConstants.LOW_RESOLUTION_DEVICE_SIZE_1440P]: {
      marginTop: "0px",
      marginBottom: "-30px",
    },
  },

  rtlMarginLeft: {
    ":global([dir='rtl'])": {
      marginLeft: "15px",
    },
  },
});

export type CommonStylesWin11OobeFabric = CommonStylesFabric &
  Partial<ReturnType<typeof useOverrideCommonStyles>>;

export const useCommonStylesWin11OobeFabric = () =>
  useMergedStyles(
    useCommonStylesFabric(),
    useOverrideCommonStyles(),
  ) as CommonStylesWin11OobeFabric;
