import { makeStyles, shorthands } from "@griffel/react";

export const useButtonStylesFabric = makeStyles({
  highContrast: {
    msHighContrastAdjust: "none",
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor("ButtonText"),
    textDecorationLine: "none",
    backgroundColor: "ButtonFace",
    color: "ButtonText",
    forcedColorAdjust: "none",
    ":hover": {
      ...shorthands.borderWidth("1px"),
      ...shorthands.borderColor("Highlight"),
      backgroundColor: "HighlightText",
      color: "Highlight",
      textDecorationLine: "none",
      ":focus": {
        outlineWidth: "1px",
        outlineStyle: "solid",
        outlineColor: "WindowText",
        ...shorthands.borderColor("Highlight"),
        backgroundColor: "HighlightText",
        color: "Highlight",
        textDecorationLine: "none",
      },
    },
    ":focus": {
      outlineWidth: "1px",
      outlineStyle: "solid",
      outlineColor: "WindowText",
      ...shorthands.borderWidth("1px"),
      ...shorthands.borderColor("Highlight"),
      textDecorationLine: "none",
      backgroundColor: "Highlight",
      color: "HighlightText",
    },
    ":disabled": {
      backgroundColor: "Window",
      color: "GrayText",
    },
  },
});

export type ButtonStylesFabric = ReturnType<typeof useButtonStylesFabric>;
