import { Flavors } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useBackButtonControl, useEffectOnce } from "../../../../../hooks";
import { useUpdateBackArrowButton } from "../../../../../hooks/use-update-back-arrow-button";
import { useIsBackButtonSupportedOnInitialView } from "../../../../../hooks/win11-oobe-fabric/use-is-back-button-supported-on-initial-view";

export const useShowBackArrowButton = () => {
  const isInitialView = !useBackButtonControl();

  const { activeFlavor } = GlobalConfig.instance;

  const updateBackArrowButton = useUpdateBackArrowButton();
  const isBackButtonSupportedOnInitialView = useIsBackButtonSupportedOnInitialView();

  useEffectOnce(() => {
    if (isInitialView) {
      if (activeFlavor === Flavors.Win11OobeFabric) {
        isBackButtonSupportedOnInitialView((isSupported: boolean) => {
          updateBackArrowButton(isSupported);
        });
      }
    }
  });
};
