import { makeStaticStyles, makeStyles, shorthands } from "@griffel/react";
import { isIENewerThan10 } from "../../utilities/browser-helper";
import * as styleConstants from "./input-constants-fabric.styles";

export const useStaticHipChallengeStylesFabric = makeStaticStyles({
  "img.cssWLSPHIPAudio": {
    marginTop: "2px !important",
    marginLeft: "2px !important",
  },
});

export const useHipChallengeStylesFabric = makeStyles({
  hipChallengeMenuButtonStyle: {
    float: "left",
    marginRight: "5px",
  },
  hipChallengeLinks: {
    display: "inline-block",
  },
  hipChallengeMenuButtonStyleWithBorder: {
    float: "left",
    marginRight: "5px",
    paddingRight: "5px",
    ...shorthands.borderRight("2px", "solid", "black"),
  },
  hipChallengeNextButtonContainerStyle: {
    marginTop: "20px",
    float: "right",
    position: "relative",
  },
  hipContentStyle: {
    "& table": { display: "grid" },
    "& table tr": {
      display: "grid",
      marginBottom: "2px",
      "& input": {
        height: `${styleConstants.HEIGHT}`,
        width: "100%",
        outlineStyle: "none",
        backgroundColor: "transparent",
        ...shorthands.borderStyle(`${styleConstants.BORDER_STYLE}`),
        ...shorthands.borderRadius(`${styleConstants.BORDER_RADIUS}`),
        ...shorthands.borderWidth(
          `${styleConstants.BORDER_WIDTH} !important`,
          `${styleConstants.BORDER_WIDTH} !important`,
          `${styleConstants.BORDER_BOTTOM_WIDTH} !important`,
          `${styleConstants.BORDER_WIDTH} !important`,
        ),
        ...shorthands.borderColor(`${styleConstants.BORDER_COLOR}`),
        ...shorthands.padding(
          `${styleConstants.PADDING_VERTICAL}`,
          `${styleConstants.PADDING_RIGHT}`,
          `${styleConstants.PADDING_VERTICAL}`,
          `${styleConstants.PADDING_LEFT}`,
        ),
        ":hover": {
          ...shorthands.borderColor(`${styleConstants.BORDER_COLOR_HOVER}`),
        },
        ":focus": {
          ...shorthands.borderColor(`${styleConstants.BORDER_COLOR_FOCUS}`),
        },
        // @TODO: Once Edge browser is available for integration testing, disable other browsers and remove this check
        ...(isIENewerThan10()
          ? {
              "::-ms-reveal": {
                height: "100%",
                ...shorthands.padding("4px", "8px"),
                marginLeft: "4px",
                marginRight: "-8px",
                ":hover": { color: styleConstants.BORDER_COLOR_FOCUS },
                ":active": { color: "#FFF", backgroundColor: styleConstants.BORDER_COLOR_FOCUS },
              },
            }
          : {}),
      },
    },
    "& div [id*=wlspispContent]": {
      "& td": {
        display: "flex !important",
        justifyContent: "center",
        width: "100% !important",
      },
    },
  },
});

export type HipChallengeStylesFabric = ReturnType<typeof useHipChallengeStylesFabric>;
