// Allow dangling underscore for the _table property to indicate that it is a metadata property rather than part of the event)
/* eslint-disable no-underscore-dangle */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var OneDsLoadState;
(function (OneDsLoadState) {
    OneDsLoadState[OneDsLoadState["NotStarted"] = 0] = "NotStarted";
    OneDsLoadState[OneDsLoadState["InProgress"] = 1] = "InProgress";
    OneDsLoadState[OneDsLoadState["Done"] = 2] = "Done";
    OneDsLoadState[OneDsLoadState["Failed"] = 3] = "Failed";
})(OneDsLoadState || (OneDsLoadState = {}));
/**
 * OneDS-based telemetry provider
 */
var OneDSTelemetryProvider = /** @class */ (function () {
    function OneDSTelemetryProvider() {
        this.eventBuffer = [];
        this.oneDsLoadState = OneDsLoadState.NotStarted;
    }
    /**
     * Initialize the provider
     * @param props The properties to initialize the provider with
     * @returns A promise which will resolve or reject based on whether initialization succeeded
     */
    OneDSTelemetryProvider.prototype.initialize = function (props) {
        var _this = this;
        if (this.oneDsLoadState !== OneDsLoadState.NotStarted) {
            // TODO: we should use strongly typed errors for easier debugging/stack trace analysis
            return Promise.reject(new Error("OneDsTelemetryProvider has already been initialized"));
        }
        var coreConfig = __assign(__assign({ instrumentationKey: props.instrumentationKey }, (props.endpointUrl && { endpointUrl: props.endpointUrl })), { extensions: [], extensionConfig: [], channelConfiguration: {
                eventsLimitInMem: 50,
            }, propertyConfiguration: {
                hashIdentifiers: true, // this prevents the SDK from sending PII
                gpcDataSharingOptIn: false, // this sets the GPC flag to false (compliance requirement)
            }, cookieCfg: {
                ignoreCookies: ["ANON"], // this prevents the SDK from sending ANID which is a PII
            } });
        var loadPromise = import(
        /* webpackChunkName: "oneds-analytics-js" */
        "@microsoft/1ds-analytics-js").then(
        // resolve
        function (oneDsLib) {
            _this.oneDs = new oneDsLib.ApplicationInsights();
            _this.oneDs.initialize(coreConfig);
            _this.oneDsLoadState = OneDsLoadState.Done;
            _this.eventBuffer.forEach(function (event) {
                _this.oneDs.track(OneDSTelemetryProvider.formatEvent(event));
            });
            _this.eventBuffer = [];
        }, 
        // reject
        function () {
            // TODO: should we retry here?
            _this.oneDsLoadState = OneDsLoadState.Failed;
        });
        this.oneDsLoadState = OneDsLoadState.InProgress;
        return loadPromise;
    };
    /**
     * Add an event to the queue to be sent to OneDS
     * @param event The telemetry event to add
     */
    OneDSTelemetryProvider.prototype.addEvent = function (event) {
        var _this = this;
        // If the load failed, there is nothing we can do here
        if (this.oneDsLoadState === OneDsLoadState.Failed) {
            return;
        }
        // If the load is done, pass the event to OneDS immediately
        try {
            if (this.oneDsLoadState === OneDsLoadState.Done) {
                this.oneDs.track(OneDSTelemetryProvider.formatEvent(event));
                // If there are still any pending events, take the opportunity to flush them now
                if (this.eventBuffer.length > 0) {
                    this.eventBuffer.forEach(function (pendingEvent) {
                        _this.oneDs.track(OneDSTelemetryProvider.formatEvent(pendingEvent));
                    });
                    this.eventBuffer = [];
                }
                return;
            }
            // If the load is still pending, add the event to the buffer for now -- this will be flushed when the load finishes
            this.eventBuffer.push(event);
        }
        catch (e) {
            // Allow writing to the console here; the telemetry call failed, so this is our only way to write the information for future debugging
            // eslint-disable-next-line no-console
            console.warn("Failed to log telemetry: ".concat(e instanceof Error ? e.message : e));
        }
    };
    /**
     * Format a given event to align with OneDS conventions
     * @param event The event to format
     * @returns The formatted event
     */
    OneDSTelemetryProvider.formatEvent = function (event) {
        // OneDS requires all "Part C" data to live under the "data" key, so reformat the event accordingly. Clone (using spread operator) before reformatting
        // to ensure that the original event is not modified in a way that the caller might not expect.
        var eventData = __assign({}, event);
        eventData._table = undefined;
        eventData = OneDSTelemetryProvider.removeQueryParams(eventData);
        var formattedEvent = {
            name: event._table,
            data: eventData,
        };
        // Always send events asynchronously to prevent issues with the window locking up in hosted scenarios
        formattedEvent.sync = false;
        // TODO: annotate Part B properties
        formattedEvent.baseData = {};
        return formattedEvent;
    };
    /**
     * removes query params for the given event data
     * @param eventData The event data to update
     * @returns updated event data
     */
    OneDSTelemetryProvider.removeQueryParams = function (eventData) {
        try {
            var data = JSON.stringify(eventData);
            // matches query params in the form of ?key=value&key=value
            data = data.replace(/\?([a-zA-Z0-9~+\-._%:/]+=[a-zA-Z0-9~+\-._%:/]+&?)+/g, "?**");
            return JSON.parse(data);
        }
        catch (e) {
            return eventData;
        }
    };
    return OneDSTelemetryProvider;
}());
export { OneDSTelemetryProvider };
