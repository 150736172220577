import { type FormEvent, useState } from "react";
import { useAuthContext } from "../../../../../authentication-context";
import { type InputState } from "../../../../../components/inputs/hooks/use-input";
import {
  loginOption as LoginOption,
  postType as PostType,
  UserFlowType,
} from "../../../../../constants";
import { FlowId, ViewId } from "../../../../../constants/routing-constants";
import { useCustomizationContext } from "../../../../../context/customization-context";
import FeaturesConfig from "../../../../../features-config";
import GlobalConfig from "../../../../../global-config";
import { useGlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { type OneTimeCodeCredential, CredentialType } from "../../../../../model/credential";
import { cleanseUsername } from "../../../../../model/user";
import { ServiceDiagEventNames } from "../../../../../telemetry-helpers/service-diag-event-names";
import { useTelemetry } from "../../../../../telemetry-helpers/use-telemetry";
import { isNoPassword } from "../../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";
import { copyQueryStringParameters, trimQsParams } from "../../../../../utilities/strings-helper";
import { useBrandingDescriptionProperties, useShowBackButton } from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import { LoginState } from "../../../login-constants";
import { useLoginContext } from "../../../login-context";
import { type CommonLoginStrings } from "../../../login-interface";
import { type LoginPostProps } from "../../../login-types";
import {
  getCommonDocumentTitle,
  getParentSignInPostUrl,
  getShowChangeUserLink,
  getShowPhoneDisambigLink,
} from "../../../login-util";
import {
  type IOneTimeCodeViewProperties,
  type IOneTimeCodeViewStrings,
} from "../one-time-code-view-interface";
import {
  processOtcCredential,
  unsafePageDescription as getUnsafePageDescription,
} from "../one-time-code-view-util";

/**
 * @returns Properties for the phone disambiguation link
 * (on click handler, flag to show the link or not)
 */
export const usePhoneDisambiguationLinkProps = () => {
  const {
    globalState: { user },
  } = useGlobalContext();
  const displayUsername = user.displayUsername.unsafeUnescapedString;
  const navigate = useNavigateDirection();

  const showPhoneDisambiguationLink = getShowPhoneDisambigLink(displayUsername);

  return {
    switchToPhoneDisambiguationOnClick: () =>
      navigate(ViewId.OneTimeCode, getRouteFromViewId(ViewId.PhoneDisambiguation)),
    showPhoneDisambiguationLink,
  };
};

/**
 * @param otcCredential The current one time code credential
 * @returns Properties for login form post submission
 */
export const useGetLoginPostProps = (otcCredential: OneTimeCodeCredential): LoginPostProps => {
  // Config properties
  const {
    context,
    canaryTokenValue: canary,
    flowTokenName,
    showCookieBanner,
  } = GlobalConfig.instance;

  const {
    defaultLoginOptions,
    foundMsas,
    isFidoSupportedHint,
    postedForceSignIn,
    randomBlob,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  // Context properties
  const {
    globalState: { user },
  } = useGlobalContext();

  const {
    viewState: {
      credentials: { availableCredentials },
      remoteNgcParams,
    },
  } = useLoginContext();

  const {
    authState: { flowTokenValue },
  } = useAuthContext();

  const cleansedUsername = cleanseUsername(user.username.unsafeUnescapedString);
  const displayUsername = user.displayUsername.unsafeUnescapedString;
  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  const isKmsiChecked = defaultLoginOptions === LoginOption.rememberPwd;
  const loginOption = isKmsiChecked ? LoginOption.rememberPwd : LoginOption.nothingChecked;
  const postType = isNoPassword(otcCredential, availableCredentials)
    ? PostType.otcNoPassword
    : PostType.otc;

  return {
    canary,
    cleansedUsername,
    context,
    displayUsername,
    flowTokenName,
    flowTokenValue,
    foundMsas,
    isFidoSupported,
    isKmsiChecked,
    loginOption,
    paginatedState: LoginState.OneTimeCode,
    postType,
    postedForceSignIn,
    randomBlob,
    rngcDefaultType: remoteNgcParams.defaultType,
    rngcEntropy: remoteNgcParams.entropy,
    rngcSessionIdentifier: remoteNgcParams.sessionIdentifier,
    showCookieBanner,
  };
};

/**
 * @returns One time code view properties
 * @param strings Flavored strings that are used by this hook
 * @param strings.oneTimeCodeViewStrings Strings that are specific to the OneTimeCode view
 * @param strings.commonLoginStrings Strings that are common to all login views
 */
export const useOneTimeCodeViewProperties = (strings: {
  oneTimeCodeViewStrings: IOneTimeCodeViewStrings;
  commonLoginStrings: CommonLoginStrings;
}): IOneTimeCodeViewProperties => {
  const { commonLoginStrings, oneTimeCodeViewStrings } = strings;
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  // Config properties
  const { activeFlavor, postUrl, telemetry } = GlobalConfig.instance;
  const { loginMode, rawQueryString } = LoginConfig.instance;

  // Context properties
  const {
    globalState: { user, userFlowType },
  } = useGlobalContext();
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  const {
    viewState: {
      credentials: { availableCredentials, otcCredential, proofConfirmation },
    },
  } = useLoginContext();

  // Method to log telemetry in case we throw an error
  const { logServiceDiagEvent } = useTelemetry(telemetry, {
    activeView: ViewId.OneTimeCode,
    activeFlow: FlowId.Login,
    activeFlavor,
  });

  // Safe check if otcCredential is defined. It should always be defined on this view.
  if (otcCredential === undefined) {
    logServiceDiagEvent({ metricName: ServiceDiagEventNames.OtcCredentialUndefined });

    throw Error("LoginContext.credentials.otcCredential must be defined on the OneTimeCode view.");
  }

  // Process the current otc credential to get otc max length and updated proof confirmation
  const { otcMaxLength, updatedProofConfirmation } = processOtcCredential(
    otcCredential,
    proofConfirmation,
  );

  // Strings
  const { enterCodeAriaLabel, placeholder, primaryButtonLabel, title } = oneTimeCodeViewStrings;
  const documentTitle = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);
  const unsafeDisplayName = user.displayUsername.unsafeUnescapedString;
  const unsafePageDescription = getUnsafePageDescription(
    otcCredential,
    updatedProofConfirmation,
    unsafeDisplayName,
    oneTimeCodeViewStrings,
  );
  const { brandingDescription, brandingDescriptionId, renderBrandingDescription } =
    useBrandingDescriptionProperties(commonLoginStrings);
  const enterCodeAriaDescribedBy = [
    "oneTimeCodeTitle",
    renderBrandingDescription ? brandingDescriptionId : "",
    "oneTimeCodeDescription",
  ].join(" ");

  // Post url for form submission
  const updatedPostUrl = rawQueryString
    ? copyQueryStringParameters(rawQueryString, postUrl)
    : postUrl;

  const isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;

  const parentPostUrl = getParentSignInPostUrl(
    updatedPostUrl,
    user.displayUsername?.safeHtmlEscapedString || "",
    userFlowType,
  );

  // One time code props
  const proofData =
    otcCredential.credentialType === CredentialType.OneTimeCode
      ? otcCredential.proof?.data
      : cleanseUsername(user.username.unsafeUnescapedString);
  // AAD-TODO: set based on PublicIdentifierCode
  const otcInputName = isNoPassword(otcCredential, availableCredentials) ? "npotc" : "otc";

  // Cred switch link component props
  const [isRequestPending, setIsRequestPending] = useState(false);
  const credentialSwitchLinksProps = {
    sourceViewId: ViewId.OneTimeCode,
    availableCredentials,
    currentCredential: otcCredential,
    setRequestPendingFlag: setIsRequestPending,
    shouldUpdateOtcCredential: true,
  };

  const canGoBack = useShowBackButton();
  const showChangeUserLink = getShowChangeUserLink(!canGoBack);

  return {
    brandingDescriptionProperties: {
      brandingDescription,
      brandingDescriptionId,
      renderBrandingDescription,
    },
    canGoBack,
    credentialSwitchLinksProps,
    documentTitle,
    enterCodeAriaDescribedBy,
    enterCodeAriaLabel,
    // This flag will be used for Win10InclusiveOOBE flavor view
    isRequestPending,
    otcCredential,
    otcInputName,
    otcMaxLength,
    placeholder,
    primaryButtonLabel,
    proofConfirmation: updatedProofConfirmation,
    proofData,
    postUrl: isParentFlow ? parentPostUrl : updatedPostUrl,
    showChangeUserLink,
    title,
    unsafePageDescription,
  };
};

/**
 * @param postUrl The url to send the Post request to
 * @param inputState The InputState
 * @returns The submit handler for one time code form submission
 */
export const useOtcSubmitHandler = (postUrl: string, inputState: InputState) => {
  const {
    error: { serverError, validationError, setShowErrorMessage },
    setUserHasSubmitted,
    setFocus,
    value,
    setValue,
  } = inputState;
  const {
    globalState: { userFlowType },
    dispatchStateChange: dispatchGlobal,
  } = useGlobalContext();
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  const isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;

  return (event: FormEvent<HTMLFormElement>) => {
    setValue(value?.toString().trim());

    if (!serverError && !validationError) {
      dispatchGlobal({
        type: GlobalActionType.BeginNavigate,
        source: ViewId.OneTimeCode,
        destination: isParentFlow ? postUrl : trimQsParams(postUrl),
      });
    } else {
      // Don't submit the form, stay on page
      event.preventDefault();

      // Initiate validation - show error
      setUserHasSubmitted(true);
      setShowErrorMessage(true);
      setFocus(true);
    }
  };
};
