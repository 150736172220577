// alphabetically sorted with None at the top
export enum ViewId {
  None = "None",
  AccountPicker = "AccountPicker",
  AccountTransferError = "AccountTransferError",
  AccountTransferOtc = "AccountTransferOtc",
  AccountTransferQrCode = "AccountTransferQrCode",
  AcwAddEmail = "AcwAddEmail",
  AcwAddPhone = "AcwAddPhone",
  AddPassword = "AddPassword",
  AddVerificationCode = "AddVerificationCode",
  AliasAccrualCollectEmail = "AliasAccrualCollectEmail",
  AliasAccrualCollectPassword = "AliasAccrualCollectPassword",
  AliasAccrualCollectPhone = "AliasAccrualCollectPhone",
  AliasAccrualEvictionInfo = "AliasAccrualEvictionInfo",
  AliasAccrualLearnMore = "AliasAccrualLearnMore",
  AliasAccrualSuggestions = "AliasAccrualSuggestions",
  AliasAccrualVerifyEmail = "AliasAccrualVerifyEmail",
  AliasAccrualVerifyPhone = "AliasAccrualVerifyPhone",
  AppConsent = "AppConsent",
  AppStoreRedirect = "AppStoreRedirect",
  Benefits = "Benefits",
  Challenge = "Challenge",
  ChangePin = "ChangePin",
  ConfirmSend = "ConfirmSend",
  ContactEmail = "ContactEmail",
  CountryBirthdate = "CountryBirthdate",
  CredentialPicker = "CredentialPicker",
  EnterRecoveryCode = "EnterRecoveryCode",
  Error = "Error",
  FetchSessionsProgress = "FetchSessionsProgress",
  Fido = "Fido",
  IdpDisambiguation = "IdpDisambiguation",
  IdpRedirect = "IdpRedirect",
  IdpRedirectSpeedbump = "IdpRedirectSpeedbump",
  Kmsi = "Kmsi",
  LearnAboutParentalConsent = "LearnAboutParentalConsent",
  LearnMoreOfflineAccount = "LearnMoreOfflineAccount",
  LoginPasskeyInterstitial = "LoginPasskeyInterstitial",
  LostProofs = "LostProofs",
  MarkLost = "MarkLost",
  NewPassword = "NewPassword",
  OneTimeCode = "OneTimeCode",
  OwnershipChallenge = "OwnershipChallenge",
  ParentalHandover = "ParentalHandover",
  PasskeyEnroll = "PasskeyEnroll",
  PasskeyError = "PasskeyError",
  PasskeyLanding = "PasskeyLanding",
  PasskeySuccess = "PasskeySuccess",
  Password = "Password",
  PhoneDisambiguation = "PhoneDisambiguation",
  PhoneNumberCollection = "PhoneNumberCollection",
  ProofConfirmation = "ProofConfirmation",
  PushNotifications = "PushNotifications",
  RecoveryChallenge = "Recovery/Challenge",
  RecoveryCode = "RecoveryCode",
  RecoveryCredentialPicker = "Recovery/CredentialPicker",
  RecoveryOneTimeCode = "Recovery/OneTimeCode",
  RecoverySetNewPassword = "Recovery/Password",
  RecoverySuccess = "Recovery/Success",
  RemoteConnectCanaryValidation = "RemoteConnectCanaryValidation",
  RemoteLoginPolling = "RemoteLoginPolling",
  ResetPassword = "ResetPassword",
  ResetPasswordAbuseBlocked = "ResetPasswordAbuseBlocked",
  ResetPasswordCaptcha = "ResetPasswordCaptcha",
  ResetPasswordProofPicker = "ResetPasswordProofPicker",
  ResetPasswordProofPickerTfa = "ResetPasswordProofPickerTfa",
  ResetPasswordSignInName = "ResetPwdSignInName",
  ResetPasswordSuccess = "ResetPasswordSuccess",
  SecurityInfoUpdated = "SecurityInfoUpdated",
  SeeHowDataIsManaged = "SeeHowDataIsManaged",
  ServiceAbuseEnterCode = "ServiceAbuseEnterCode",
  ServiceAbuseError = "ServiceAbuseError",
  ServiceAbuseLanding = "ServiceAbuseLanding",
  ServiceAbusePhoneNumberCollection = "ServiceAbusePhoneNumberCollection",
  ServiceAbuseSuccess = "ServiceAbuseSuccess",
  SessionApproval = "SessionApproval",
  SessionDenied = "SessionDenied",
  SessionTimeout = "SessionTimeout",
  SetSinglePassword = "SetSinglePassword",
  SetupAuthenticator = "SetupAuthenticator",
  SignInOptions = "SignInOptions",
  SignUp = "SignUp",
  SignUpAlternateMembernameSuggestions = "SignUpAlternateMembernameSuggestions",
  SignUpBlocked = "SignUpBlocked",
  SignUpChinaPipl = "SignUpChinaPipl",
  SignUpEvictionError = "SignUpEvictionError",
  SignUpEvictionSpeedbump = "SignUpEvictionSpeedbump",
  SignUpKnownEmailAlias = "SignUpKnownEmailAlias",
  SignUpMembernameTakenLearnMore = "SignUpMembernameTakenLearnMore",
  SignUpPasswordCollection = "SignUpPasswordCollection",
  SignUpRiskApiBlocked = "SignUpRiskApiBlocked",
  SignUpUsernamePrefillSpeedbump = "SignUpUsernamePrefillSpeedbump",
  SignUpUsernameRecoverySpeedbump = "SignUpUsernameRecoverySpeedbump",
  SignUpVerification = "SignUpVerification",
  SignupKoreaPrivacyConsent = "SignupKoreaPrivacyConsent",
  SignupNameCollection = "SignupNameCollection",
  SkipAutoCsr = "SkipAutoCsr",
  SkypeGoodNews = "SkypeGoodNews",
  SmsNudge = "SmsNudge",
  SsoConsent = "SsoConsent",
  StartWaitFlow = "StartWaitFlow",
  SubmitRequest = "SubmitRequest",
  TOUAccrualLanding = "TOUAccrualLanding",
  TenantPicker = "TenantPicker",
  TransferLogin = "TransferLogin",
  Username = "Username",
  UsernameCollection = "UsernameCollection",
  UsernameRecoveryBlocked = "UsernameRecoveryBlocked",
  UsernameRecoveryCollect = "UsernameRecoveryCollect",
  UsernameRecoveryLegacyCode = "UsernameRecoveryLegacyCode",
  UsernameRecoveryPhoneSpeedbump = "UsernameRecoveryPhoneSpeedbump",
  UsernameRecoveryResult = "UsernameRecoveryResult",
  UsernameRecoverySelectAlias = "UsernameRecoverySelectAlias",
  UsernameRecoveryVoiceCode = "UsernameRecoveryVoiceCode",
  ValidateAccount = "ValidateAccount",
  VerifyEmail = "VerifyEmail",
  VerifyIdentity = "VerifyIdentity",
  ViewAgreement = "ViewAgreement",
  WaitFlow = "WaitFlow",
}

export enum FlowId {
  None = "None",
  AccountTransfer = "AccountTransfer",
  Acw = "Acw",
  AliasAccrual = "AliasAccrual",
  AppConsent = "AppConsent",
  AppStoreRedirect = "AppStoreRedirect",
  AttributeCollection = "AttributeCollection",
  AuthMethodCollection = "AuthMethodCollection",
  ChangePin = "ChangePin",
  CredentialCollection = "CredentialCollection",
  Kmsi = "Kmsi",
  Login = "Login",
  OwnershipChallenge = "OwnershipChallenge",
  ParentalConsent = "ParentalConsent",
  PasskeyInterrupt = "PasskeyInterrupt",
  ResetPassword = "ResetPassword",
  ResetPasswordSignInName = "ResetPasswordSignInName",
  ServiceAbuse = "ServiceAbuse",
  Signup = "Signup",
  SsoConsent = "SsoConsent",
  TOUAccrual = "TOUAccrual",
  TenantPicker = "TenantPicker",
  Tfa = "Tfa",
  TransferLogin = "TransferLogin",
  UnifiedRecovery = "Recovery",
  UsernameRecovery = "UsernameRecovery",
  VerifyEmail = "VerifyEmail",
}

export const AgreementViewRoute = `/${ViewId.ViewAgreement}/*`;
export const RecoveryFlowRoute = `/${FlowId.UnifiedRecovery}/*`;
