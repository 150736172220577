import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { type DialogProps, Dialog } from "./dialog";
import { FocusLocker } from "./focus-locker";

// Modal example from https://testing-library.com/docs/example-react-modal
const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal-root");
document.body.appendChild(modalRoot);

/**
 * This component uses a React Portal to render a Dialog component inside a FocusLocker component.
 * This will create a Modal Dialog that will trap the focus inside the dialog.
 * The reason the ModalDialog is added to the "modal-root" on the body is for two reasons:
 * 1. The "inner" element the views are nested inside uses "overflow: hidden", which cuts off the dialog in certain browsers (Webkit/Safari).
 * 2. The dialog is getting unintentional styling from parents that isn't desired.
 * @param props DialogProps - Dialog properties
 * @returns ModalDialog component
 */
export const ModalDialog: React.FC<DialogProps> = function ModalDialog(props) {
  const el = document.createElement("div");
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modalRoot.appendChild(el);

    return () => {
      modalRoot.removeChild(el);
    };
  });

  const modalContent = (
    <FocusLocker wrapperRef={dialogRef}>
      <Dialog {...props} ref={dialogRef} />
    </FocusLocker>
  );

  return ReactDOM.createPortal(modalContent, el);
};
