import { type FormEvent, type MouseEvent, useState } from "react";
import { type HipType } from "../../../constants";
import { type MenuButton, useHipContent } from "./use-hip-content";

export interface HipChallengeConfig {
  url: string;
  flowId?: string;
  market: string;
}

export interface HipChallengeMenuItem {
  text: string;
  action: () => void;
  ariaLabel: string;
  key: string;
}

export interface UseHipChallengeParams {
  initialType: HipType;
  onSessionStart: (params: { sessionToken: string; type: HipType }) => void;
  onSuccess: (params: {
    solution: string;
    type: HipType;
    token: string;
    reloadHIP?: () => void;
  }) => void;
  onValidate: (params: { type: HipType; sessionToken: string }) => void;
  config: HipChallengeConfig;
}

interface UseHipChallengeResponse {
  isLoading: boolean;
  errorMessage: string;
  instruction: string;
  menuItems: HipChallengeMenuItem[];
  onSubmit: (event: FormEvent<HTMLFormElement> | MouseEvent) => void;
}

/**
 * Hook that provides the business logic for the HipChallenge component.
 * Consumers will use this hook to get the necessary callbacks to effectively use the HipChallenge component.
 * @param params Hip Challenge Props
 * @param params.initialType type of HIP to load
 * @param params.onSessionStart callback when HIP has started
 * @param params.onSuccess callback when HIP is solved
 * @param params.onValidate callback when HIP gets validated
 * @param params.config config for HIP service
 * @returns data for surrounding component to effectively use the HipChallenge component
 */
export const useHipChallenge = ({
  initialType,
  onSessionStart,
  onSuccess,
  onValidate,
  config,
}: UseHipChallengeParams): UseHipChallengeResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [instruction, setInstruction] = useState<string>("");
  const [menuItems, setMenuItems] = useState<HipChallengeMenuItem[]>([]);

  const createMenuButtons = (menuButtons: Array<MenuButton>) => {
    const results: HipChallengeMenuItem[] = menuButtons.map(({ text, tip, trigger }) => ({
      text,
      ariaLabel: tip,
      action: trigger,
      key: `HipChallengeButton_${text}`,
    }));

    setMenuItems(results);
  };

  const { reloadHIP, validateSolution } = useHipContent({
    config,
    initialType,
    onLoadingStatusChange: setIsLoading,
    onErrorMessageChange: setErrorMessage,
    onInstructionMessageChange: setInstruction,
    onMenuButtonsChange: createMenuButtons,
    onSessionStart,
  });

  return {
    isLoading,
    errorMessage,
    instruction,
    menuItems,
    onSubmit: (event: FormEvent<HTMLFormElement> | MouseEvent) => {
      event.preventDefault();

      const { token, solution, error } = validateSolution();
      onValidate({ sessionToken: token, type: initialType });

      if (error) {
        setErrorMessage(error);
        return;
      }

      onSuccess({ token, type: initialType, solution, reloadHIP });
    },
  };
};
