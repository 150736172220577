import { OtcChannel } from "../../utilities/api-helpers/one-time-code/one-time-code-types";
import { isEmailAddress } from "../alias";
import { type ConfirmSendCredential, CredentialType } from "../credential";
import { type IUser, cleanseUsername } from "../user";
import { ProofType } from "./proof-types";
/**
 * @param currentCredential The current credential being used
 * @param user The user reference
 * @returns The correct ProofType that correlates to the user's current credential
 */
export const getProofType = (currentCredential: ConfirmSendCredential, user: IUser) => {
  if (currentCredential.credentialType === CredentialType.OneTimeCode) {
    return currentCredential.proof.type;
  }

  return isEmailAddress(user.username.unsafeUnescapedString) ? ProofType.Email : ProofType.SMS;
};

/**
 * @param currentCredential The current credential being used
 * @param user The user reference
 * @returns The proof data if the current credential is OneTimeCode.
 * Otherwise returns the displayUsername, cleansed
 */
export const getProofData = (currentCredential: ConfirmSendCredential, user: IUser) =>
  currentCredential.credentialType === CredentialType.OneTimeCode
    ? currentCredential.proof.data
    : cleanseUsername(user.displayUsername.unsafeUnescapedString);

/**
 * @param proofType The ProofType for the user's current credential
 * @returns The EmailAddress OtcChannel if the proof type is Email.
 * Otherwise returns the MobileSMS OtcChannel.
 */
export const getChannel = (proofType: ProofType) =>
  proofType === ProofType.Email ? OtcChannel.emailAddress : OtcChannel.mobileSms;
