import { BindProvider } from "../../../constants";
import { CredentialType } from "../../../model/credential";
import { type RedirectPostParams } from "../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";

type FederatedRedirectUrls = {
  linkedInFedUrl: string;
  githubFedUrl: string;
  googleFedUrl: string;
  facebookFedUrl: string;
};

export type BindProviderSpeedbumpProperties = {
  title: string;
  description?: string;
  currentCredentialType: CredentialType;
  redirectUrl: string;
  redirectPostParams?: RedirectPostParams;
};

/**
 * Returns the appropriate properties for the view to display based on the redirect provider supplied by the server.
 * @param idpRedirectUrl the url to redirect to
 * @param idpRedirectPostParams the post params for the redirection
 * @param idpRedirectProvider the bind provider
 * @param fedUrls the urls to redirect to for specific federated sign-ins
 * @returns The corresponding properties for the input redirect provider.
 */
export const getBindProviderSpeedbumpProperties = (
  idpRedirectUrl: string,
  idpRedirectPostParams: RedirectPostParams,
  idpRedirectProvider: BindProvider,
  fedUrls: FederatedRedirectUrls,
): BindProviderSpeedbumpProperties => {
  let title = getLocalString("Login_Redirect_Title");
  let description: string | undefined;
  let currentCredentialType = CredentialType.Federation;

  let redirectUrl = idpRedirectUrl;
  let redirectPostParams: RedirectPostParams | undefined = idpRedirectPostParams;

  const { linkedInFedUrl, githubFedUrl, googleFedUrl, facebookFedUrl } = fedUrls;

  switch (idpRedirectProvider) {
    case BindProvider.LinkedIn:
      redirectUrl = redirectUrl || linkedInFedUrl;
      redirectPostParams = undefined;
      title = getLocalString("Login_Redirect_Title_MoreOptions_LinkedIn");
      description = getLocalString("Login_Redirect_Description_LinkedIn");
      currentCredentialType = CredentialType.LinkedIn;
      break;

    case BindProvider.GitHub:
      redirectUrl = redirectUrl || githubFedUrl;
      redirectPostParams = undefined;
      title = getLocalString("Login_Redirect_Title_MoreOptions_GitHub");
      description = getLocalString("Login_Redirect_Description_GitHub");
      currentCredentialType = CredentialType.GitHub;
      break;

    case BindProvider.Google:
      redirectUrl = redirectUrl || googleFedUrl;
      redirectPostParams = undefined;
      title = getLocalString("Login_Redirect_Title_MoreOptions_Google");
      description = getLocalString("Login_Redirect_Description_Google");
      currentCredentialType = CredentialType.Google;
      break;

    case BindProvider.Facebook:
      redirectUrl = redirectUrl || facebookFedUrl;
      redirectPostParams = undefined;
      // @AAD-TODO: use localized strings when we work on AAD Login
      title = "Sign in on Facebook";
      description =
        "Since your account is backed by Facebook credentials, we'll send you to Facebook to sign in.";
      currentCredentialType = CredentialType.Facebook;
      break;

    default:
      break;
  }

  return { title, description, currentCredentialType, redirectUrl, redirectPostParams };
};
