import React, { createContext, useContext, useMemo, useReducer } from "react";
import { type AcmaLinksObject } from "../model/api/acma-types";
import { type AuthenticationMethod, type ChallengeAuthenticationMethod } from "../model/credential";
import acmaReducer, { type AcmaActions } from "./acma-reducer";

export type AcmaState = {
  activeCredential?: AuthenticationMethod;
  availableRecoveryCredentials?: Array<ChallengeAuthenticationMethod>;
  continuationToken: string;
  links?: {
    next?: AcmaLinksObject;
  };
};

export const initialAcmaState = {
  continuationToken: "",
};

export interface IAcmaContext {
  acmaState: AcmaState;
  dispatchStateChange: React.Dispatch<AcmaActions>;
}

export const AcmaContext = createContext<IAcmaContext>({
  acmaState: initialAcmaState,
  dispatchStateChange: () => {
    throw new Error("ApiContext not initialized");
  },
});

/**
 * @param params The params for this component
 * @param params.initialState initial state that the provider should be initialized with
 * @param params.children Child components that this provider wraps around
 * @returns An instance of the ACMA context provider
 */
export const AcmaProvider: React.FC<{ initialState: AcmaState }> = function AcmaProvider({
  initialState,
  children,
}) {
  const [state, dispatch] = useReducer(acmaReducer, initialState);
  const value: IAcmaContext = useMemo(
    () => ({
      acmaState: state,
      dispatchStateChange: dispatch,
    }),
    [state],
  );

  return <AcmaContext.Provider value={value}>{children}</AcmaContext.Provider>;
};

export const useAcmaContext = () => useContext(AcmaContext);
