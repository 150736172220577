/**
 * Checks if an object has a matching property name
 * @param object The object to search
 * @param propertyName The property name to search
 * @param ignoreCase Whether or not to ignore case when searching for a match
 * @returns The matching object key or null
 */
export const findOwnProperty = (
  object: Record<string, unknown>,
  propertyName: string,
  ignoreCase: boolean,
): string | null => {
  let matchedKey = null;
  Object.keys(object).forEach((key: string) => {
    if (key === propertyName || (ignoreCase && key.toLowerCase() === propertyName.toLowerCase())) {
      matchedKey = key;
    }
  });

  return matchedKey;
};

/**
 * Joins the key-value pairs of an object into a string
 * @param object The object to join
 * @param delimiter The string separating each key-value pair
 * @param separator The string separating a key and its value
 * @returns A string with the key-value pairs of the object
 */
export const doubleJoin = (
  object: Record<string, unknown> | null | undefined,
  delimiter: string,
  separator: string,
): string => {
  let result = "";
  if (object) {
    Object.keys(object).forEach((key: string) => {
      if (result) {
        result += delimiter;
      }

      result += key + separator + ((object as Record<string, unknown>)[key] || "");
    });
  }

  return result;
};

/**
 * This method takes an object and returns a string containing the key-value pairs of the object
 * in the format "key1=value1&key2=value2&..."
 * The values are encoded using encodeURIComponent unless otherwise specified.
 * @param obj The object to serialize
 * @param skipEncoding Whether to skip encoding the value
 * @returns The serialized object
 */
export const serializeObject = (
  obj: Record<string, string | number | boolean>,
  skipEncoding: boolean,
) => {
  const values: string[] = [];

  Object.entries(obj).forEach(([name, value]) => {
    values.push(name, "=", skipEncoding ? String(value) : encodeURIComponent(value), "&");
  });

  if (values.length > 0) {
    // Pop off the trailing deliminator
    values.pop();
  }

  return values.join("");
};

/**
 * This method takes an object and removes any properties that have invalid values
 * including values which are undefined, non-strings, or empty strings.
 * @param obj The object to iterate over
 * @returns A new object with invalid properties removed
 */
export const getValidStringProps = (obj: Record<string, unknown>): Record<string, string> => {
  const result = {} as Record<string, string>;

  Object.entries(obj).forEach(([key, value]) => {
    if (value && typeof value === "string") {
      result[key] = value;
    }
  });

  return result;
};
