import clientExperiments, {
  type ControlFlight,
  type ExperimentFeature,
  type IExperimentDefinition,
  type IFlowDefinition,
  type TreatmentFlight,
} from "../../config/experiments";
import { type Flavors, type FlowId, ViewId } from "../../constants";
import GlobalConfig from "../../global-config";
import { ExceptionHelper } from "../../telemetry-helpers/exception-helper";
import {
  type IStandardDimensions,
  getStandardDimensions,
  TelemetryEventType,
} from "../../telemetry-helpers/telemetry-helper";
import { postApiRequest } from "../request/request-helper";

export enum ClientExperimentLoadState {
  NotStarted,
  InProgress,
  Done,
  Failed,
}

/**
 * Class representing the client experiment context
 */
export class ClientExperimentHelper {
  private static singletonInstance: ClientExperimentHelper;

  private experimentMap: Map<string, IExperimentDefinition>;

  private experimentsByFlowId: Map<FlowId, IExperimentDefinition[]>;

  private activeFlights: string[];

  private standardDimensions: IStandardDimensions | undefined;

  public loadPromise: Promise<void>;

  private loadPromiseResolve!: () => void;

  private hasFetchedExperiments: boolean;

  private clientExperimentLoadState: ClientExperimentLoadState =
    ClientExperimentLoadState.NotStarted;

  /**
   * Construct an instance of this class
   */
  private constructor() {
    this.experimentMap = new Map<string, IExperimentDefinition>();
    this.experimentsByFlowId = new Map<FlowId, IExperimentDefinition[]>();
    this.activeFlights = [];
    this.loadPromise = new Promise<void>((resolve) => {
      this.loadPromiseResolve = resolve;
    });
    this.hasFetchedExperiments = false;
  }

  /**
   * Gets an insance of the ClientExperimentContext
   * @returns An instance of the ClientExperimentContext
   */
  public static get instance() {
    if (!this.singletonInstance) {
      this.singletonInstance = new ClientExperimentHelper();
    }

    return this.singletonInstance;
  }

  /**
   * Function to add an experiment to the context
   * @param experiment The experiment to add
   */
  addExperiment(experiment: IExperimentDefinition) {
    this.experimentMap.set(experiment.parallaxFeature, experiment);
  }

  /**
   * Function to get an experiment from the context
   * @param parallax The parallax feature name
   * @returns The experiment if it exists, otherwise null
   */
  getExperimentByParallax(parallax: ExperimentFeature): IExperimentDefinition | null {
    const experiment = this.experimentMap.get(parallax);
    return experiment || null;
  }

  /**
   * Function to load experiment definitions and add them to the context
   * @param experimentDefinitions The experiment definitions to load
   */
  loadExperiments(experimentDefinitions: IFlowDefinition) {
    if (
      this.clientExperimentLoadState === ClientExperimentLoadState.Done ||
      this.clientExperimentLoadState === ClientExperimentLoadState.InProgress
    ) {
      return;
    }

    try {
      this.clientExperimentLoadState = ClientExperimentLoadState.InProgress;

      Object.keys(experimentDefinitions).forEach((flowId) => {
        const experimentArray = experimentDefinitions[flowId as FlowId];

        if (!experimentArray) {
          throw new Error(`Experiment definition not found for FlowId: ${flowId}`);
        }

        experimentArray.map(
          (definition: {
            parallaxFeature: ExperimentFeature;
            controlFlight: ControlFlight;
            treatmentFlights: Array<TreatmentFlight>;
          }) => {
            const experiment: IExperimentDefinition = {
              parallaxFeature: definition.parallaxFeature,
              controlFlight: definition.controlFlight,
              treatmentFlights: definition.treatmentFlights,
            };
            this.addExperiment(experiment);

            if (!this.experimentsByFlowId.has(flowId as FlowId)) {
              this.experimentsByFlowId.set(flowId as FlowId, []);
            }

            this.experimentsByFlowId.get(flowId as FlowId)?.push(experiment);

            return experiment;
          },
        );
      });
      this.clientExperimentLoadState = ClientExperimentLoadState.Done;
    } catch (error) {
      this.clientExperimentLoadState = ClientExperimentLoadState.Failed;
    }
  }

  /**
   * Function to get Experiment Assignments from the API
   * @param flavor Flavor of the current flow
   * @param flowId Flow id of the current flow
   * @returns A promise which will resolve when experimentation initialization completes
   */
  async fetchExperimentAssignments(flavor: Flavors, flowId: FlowId): Promise<void> {
    const { clientExperimentUrl } = GlobalConfig.instance;
    this.standardDimensions = getStandardDimensions({
      activeFlow: flowId,
      activeView: ViewId.None,
      activeFlavor: flavor,
    });

    this.loadPromise = new Promise<void>((resolve) => {
      this.loadPromiseResolve = resolve;
    });

    try {
      if (!clientExperimentUrl?.trim()) {
        this.setActiveFlights([]);
        this.hasFetchedExperiments = false;
        return this.loadPromiseResolve();
      }

      if (this.clientExperimentLoadState === ClientExperimentLoadState.NotStarted) {
        this.loadExperiments(clientExperiments);
      }

      if (
        this.clientExperimentLoadState !== ClientExperimentLoadState.Done ||
        !this.isExperimentDefined(flowId)
      ) {
        this.setActiveFlights([]);
        this.hasFetchedExperiments = false;
        return this.loadPromiseResolve();
      }

      const experimentArray = this.experimentsByFlowId.get(flowId) || [];

      const requestBody = {
        clientExperiments: experimentArray.map((experiment) => ({
          parallax: experiment.parallaxFeature,
          control: experiment.controlFlight,
          treatments: experiment.treatmentFlights,
        })),
      };

      const response = await postApiRequest(clientExperimentUrl, {
        body: JSON.stringify(requestBody),
      });

      if (
        "FlightAssignments" in response &&
        Array.isArray(response.FlightAssignments) &&
        response.FlightAssignments.length > 0
      ) {
        this.setActiveFlights(response.FlightAssignments as unknown as string[]);
        this.hasFetchedExperiments = true;
        return this.loadPromiseResolve();
      }

      this.hasFetchedExperiments = false;
      this.setActiveFlights([]);
      return this.loadPromiseResolve();
    } catch (error) {
      if (typeof error === "string" || error instanceof Error || error instanceof ErrorEvent) {
        ExceptionHelper.logException(error);
      }

      this.hasFetchedExperiments = false;
      this.setActiveFlights([]);
      return this.loadPromiseResolve();
    }
  }

  /**
   * Function to get treatment assignment for the experiment
   * @param experiment The experiment to get the treatment flight for
   * @returns Name of the treatment flight if it is active for the experiment
   */
  getTreatmentAssignment(experiment: IExperimentDefinition | null): TreatmentFlight | undefined {
    if (!this.hasFetchedExperiments || !experiment) {
      return undefined;
    }

    try {
      const activeFlightsLower = this.activeFlights.map((flight) => flight.toLowerCase());

      if (activeFlightsLower.includes(experiment.controlFlight.toLowerCase())) {
        this.logFlight(experiment.controlFlight.toLowerCase());
        return undefined;
      }

      const activeTreatment = experiment.treatmentFlights.find((treatment) =>
        activeFlightsLower.includes(treatment.toLowerCase()),
      );

      if (activeTreatment) {
        this.logFlight(activeTreatment.toLowerCase());
        return activeTreatment;
      }

      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Function to check if experiments are loaded
   * @returns True if experiments are loaded, otherwise false
   */
  areExperimentsLoaded(): boolean {
    return this.clientExperimentLoadState === ClientExperimentLoadState.Done;
  }

  /**
   * Don't call it - Only for unit-tests
   * Function to set experiments loaded variable
   * @param value Value to be set
   */
  setExperimentsLoaded(value: ClientExperimentLoadState) {
    this.clientExperimentLoadState = value;
  }

  /**
   * Function to set the active flights
   * @param response The active flights
   */
  setActiveFlights(response: string[]) {
    this.activeFlights = response;
  }

  /**
   * Function to get the active flights
   * @returns The active flights
   */
  getActiveFlights(): string[] {
    return this.activeFlights;
  }

  /**
   * Function to set standart dimensions
   * @param standardDimensions Standard dimenstions for current flow
   */
  setStandardDimensions(standardDimensions: IStandardDimensions) {
    this.standardDimensions = standardDimensions;
  }

  /**
   * Function to check if experiments are defined for the flowID
   * @param flowId Flow Id being checked
   * @returns Boolean value indicating if experiments are defined or not
   */
  isExperimentDefined(flowId: FlowId): boolean {
    return this.experimentsByFlowId.has(flowId);
  }

  /**
   * Function to log flight
   * @param flightToLog Flight to be logged
   */
  logFlight(flightToLog: string) {
    if (
      flightToLog &&
      flightToLog !== "" &&
      this.standardDimensions &&
      typeof this.standardDimensions === "object"
    ) {
      const telemetryProvider = GlobalConfig.instance.telemetry;

      telemetryProvider?.addEvent({
        _table: TelemetryEventType.FlightAssignments,
        flight: flightToLog,
        dimensions: this.standardDimensions,
      });
    }
  }
}

export const initExp = (flavor: Flavors, flowId: FlowId) => {
  try {
    ClientExperimentHelper.instance.fetchExperimentAssignments(flavor, flowId);
  } catch (error) {
    const errorMessage = `Exception while trying to initialize ExP: ${error}`;
    ExceptionHelper.logException(errorMessage);
  }
};
