import { type UserCredential } from "../model/credential";
import { ProofType } from "../model/proof";

/**
 * Given a user credential, returns true if the proof of the input credential is of email type and false otherwise
 * @param credential the user credential
 * @returns true if the proof of the input credential is of email type and false otherwise
 */
export const isCredentialEmailProofType = (credential: UserCredential): boolean => {
  const proofType = credential.proof?.type;
  return proofType === ProofType.AltEmail || proofType === ProofType.Email;
};
