export const ALERT_PADDING = "12px";
export const BASE_FONT_SIZE = 16;
export const ERROR_COLOR = "#e81123";
export const ERROR_MARGIN = "0px";
export const FOOTER_HEIGHT = 28;
export const EXTENDED_FOOTER_HEIGHT = 48;
export const FORM_GROUP_MARGIN = 16;
export const IDENTITY_BANNER_HEIGHT = 24;
export const IDENTITY_BANNER_MARGIN_BOTTOM = -4;
export const LIGHTBOX_BACKGROUND_COLOR = "#fff";
export const LIGHTBOX_MAX_WIDTH = 440;
export const LIGHTBOX_MIN_HEIGHT = 338;
export const LIGHTBOX_MIN_WIDTH = 320;
export const LIGHTBOX_MOBILE_MARGIN_BOTTOM = 28; // Mobile footer can span up to 4 lines on certain markets - @footer-height(28) + 20 + 20 + 20
export const LIGHTBOX_MOBILE_MARGIN_TOP = 0;
export const LIGHTBOX_MOBILE_PADDING = 24;
export const LIGHTBOX_PADDING = 44;
export const LIGHTBOX_SHADOW = "0px 2px 6px rgba(0, 0, 0, 0.2)";
export const LIGHTBOX_WIDE_MAX_WIDTH = 640;
export const LOGO_HEIGHT = 24;
export const MOBILE_MAX_WIDTH = 600; // At a width of 600px, the background goes away and the footer changes to be left aligned
export const SECTION_PADDING = 20;
export const TILE_PADDING = 12;

// Calculated
export const LAYOUT_MAX_HEIGHT = LIGHTBOX_MIN_HEIGHT + FOOTER_HEIGHT;

// Media queries
export const MOBILE_LAYOUT_SIZE = `@media screen and (max-width: 600px), screen and (max-height: ${LAYOUT_MAX_HEIGHT}px)`;
