import React from "react";
import { LinkButton } from "../../../../../../components/link-button";
import type { ViewId } from "../../../../../../constants";
import { useCustomizationContext } from "../../../../../../context/customization-context";
import GlobalConfig from "../../../../../../global-config";
import { useNavigateDirection } from "../../../../../../hooks";
import {
  commonBindingsFabric,
  FormattedTextWithBindings,
} from "../../../../../../utilities/formatted-text-with-bindings";
import { getAgreementViewRoute } from "../../../../../../utilities/routing-helper";
import { AgreementType } from "../../../../../../views";
import { getPrivacyAgreementMessage } from "../../inline-legal-messaging-util";
import { inlineLegalMessagingStringsFabric } from "../inline-legal-messaging-strings-fabric";

export interface IPrivacyAgreementMessageLinkProps {
  /** The type of agreement view that the link navigates to */
  agreementType: AgreementType;
  /** The ID of the link */
  linkId: string;
  /** The text body of the link */
  linkText: string;
  /** The external url that the link takes the user to if the agreement view is not hosted */
  linkUrl: string;
  /** The ID of the view leading to the agreements view - this should be the view hosting the link */
  hostingViewId: ViewId;
  /** Whether the link takes the user to a view that hosts the agreement text (called hosted) or to an external site */
  renderHostedLinks: boolean;
}

/**
 * TODO: Relocate this component to a more global scope so that the fabric footer component can use it as well
 *
 * PrivacyAgreementMessageLink component for the embedded link bindings in the privacy agreement message string.
 * @param props The input props for the PrivacyAgreementMessageLink component
 * @returns an instance of this component
 */
export const PrivacyAgreementMessageLink: React.FC<IPrivacyAgreementMessageLinkProps> =
  function PrivacyAgreementMessageLink(props) {
    const { agreementType, linkId, linkText, linkUrl, hostingViewId, renderHostedLinks } = props;

    const navigate = useNavigateDirection();
    const destinationViewId = getAgreementViewRoute(agreementType);

    return renderHostedLinks ? (
      <LinkButton
        linkId={linkId}
        text={linkText}
        onClick={() => {
          navigate(hostingViewId, destinationViewId);
        }}
      />
    ) : (
      <a id={linkId} href={linkUrl} target="_blank" rel="noreferrer noopener">
        {linkText}
      </a>
    );
  };

/**
 * @param sourceViewId The view hosting the consent message.
 * @returns a JSX element for the privacy agreement message where the embedded bindings contained in the
 *   message are replaced with their corresponding JSX elements
 */
export const usePrivacyAgreementMessage = (sourceViewId: ViewId): JSX.Element => {
  const { isHosted, isChinaDc } = GlobalConfig.instance;

  const {
    customizationState: {
      styles: { privacyUrl, termsOfUseUrl },
    },
  } = useCustomizationContext();

  const renderHostedLinks = isHosted && !isChinaDc;
  const privacyAgreementString = getPrivacyAgreementMessage(inlineLegalMessagingStringsFabric);

  const { boldText } = commonBindingsFabric;
  const servicesAgreementLink = (chunks: string[]) => (
    <PrivacyAgreementMessageLink
      agreementType={AgreementType.TermsOfUse}
      linkId="servicesAgreementLinkId"
      linkText={chunks[0]}
      linkUrl={termsOfUseUrl}
      hostingViewId={sourceViewId}
      renderHostedLinks={renderHostedLinks}
    />
  );
  const privacyStatementLink = (chunks: string[]) => (
    <PrivacyAgreementMessageLink
      agreementType={AgreementType.Privacy}
      linkId="privacyLinkId"
      linkText={chunks[0]}
      linkUrl={privacyUrl}
      hostingViewId={sourceViewId}
      renderHostedLinks={renderHostedLinks}
    />
  );

  return (
    <FormattedTextWithBindings
      text={privacyAgreementString}
      embeddedBindings={{ boldText, servicesAgreementLink, privacyStatementLink }}
    />
  );
};
