import { useCallback, useContext, useEffect, useState } from "react";
import { type HipType, FlowId } from "../../../constants";
import { GlobalContext } from "../../../global-context";
import { useExternalScripts } from "../../../hooks/use-external-scripts";
import { type HipChallengeConfig } from "./use-hip-challenge";

export interface MenuButton {
  text: string;
  tip: string;
  trigger: () => void;
}

export interface ChallengeControl {
  /**
   * Left margin of contents rendered by HipChallenge service in pixels without px-metrics.
   */
  left: string;
  /**
   * HIP Challenge-service error code
   */
  error?: number;
  /**
   * Error message to be shown on the UX.
   */
  errorMessage: string;
  /**
   * Whether HipChallenge should display its own error messages (true) or not (false).
   * We render our own error message container through Captcha-Component for visual alignment and accessibility.
   * The error message to render gets provided through showErrorCallback.
   */
  showError: boolean;
  /**
   * Whether HipChallenge should display its own menu (true) or not (false).
   * We render our own menu through Captcha-component for visual alignment and accessibility.
   */
  showMenu: boolean;
  /**
   * Whether HipChallenge should display its own instructions (true) or not (false).
   * We render our own instruction container through Captcha-Component for visual alignment and accessibility.
   * The instruction message to render gets provided through instructionOutsideCallback.
   */
  instructionsInside: boolean;
  /**
   * Same as instructionsInside.
   */
  showInstruction: boolean;
  /**
   * The width of the input field used to enter captcha solutions in pixels without px-metrics.
   */
  inputWidth: string;
  /**
   * Whether HipChallenge captcha has successfully validated client side.
   * Additional service-side validation is needed.
   */
  done: boolean;
  /**
   * HTML tag id to render HIP Challenge UX in.
   */
  holder: string;
  /**
   * HTML tag id for HIP Challenge-service to dynamically inject scripts into.
   */
  scriptHolder: string;
  /**
   * Type of captcha to render ("audio", "visual").
   */
  type: string;
  /**
   * ll-cc value for localization.
   */
  market: string;
  /**
   * Called when a menu is supposed to be shown. This shows the buttons to choose captcha options.
   * @param menuItems Menu items with visual text, tooltip-text and trigger-callback.
   * @returns void
   */
  menuOutsideCallback: (menuItems: Array<MenuButton>) => void;
  /**
   * Called when HIP Challenge-service finished loading.
   * @returns void
   */
  postLoad: () => void;
  /**
   *
   * @param instruction Called when an instruction message is supposed to be shown.
   * @returns void
   */
  instructionOutsideCallback: (instruction: string) => void;
  /**
   * Triggers process to verify a solution client side.
   * @param callback Callback triggered on successful client side verification.
   * @param param Unknown use.
   * @returns void
   */
  verify?: (
    callback: (solution: string, token: string, param: unknown) => void,
    param: unknown,
  ) => void;
  /**
   * Verifies a solution client side. Only to be used by verify callback.
   * @returns void
   */
  clientValidation?: () => void;
  /**
   * Called when an error message is supposed to be shown.
   * Not every error message during validation triggers a showErrorCallback,
   * we need to check for getError too.
   * @param errorMessage Error message to be shown
   * @returns void
   */
  showErrorCallback: (errorMessage?: string) => void;
  /**
   * Called when no error message is supposed to be shown.
   * @returns void
   */
  removeErrorCallback: () => void;
  /**
   * Internal use by HIP Challenge. Checks and sets error messages.
   * @returns void.
   */
  setError?: () => string;
  /**
   * Retrieves error message to be shown.
   * @returns error message
   */
  getError?: () => string;

  /**
   * method that refreshes the current challenge
   * @param showError
   * @param flowId
   * @param dataCenter
   * @returns
   */
  reloadHIP?: (showError: boolean, flowId: string, dataCenter: string) => void;
}

export interface UseHipContentParams {
  config: HipChallengeConfig;
  initialType: HipType;
  onLoadingStatusChange: (isLoading: boolean) => void;
  onErrorMessageChange: (errorMessage: string) => void;
  onInstructionMessageChange: (instruction: string) => void;
  onMenuButtonsChange: (menuItems: Array<MenuButton>) => void;
  onSessionStart: (params: { sessionToken: string; type: HipType }) => void;
}

export interface UseHipContentResponse {
  validateSolution: () => { token: string; solution: string; error: string };
  reloadHIP?: () => void;
}

export const DEFAULT_HIP_CONFIG: Partial<ChallengeControl> = {
  left: "10",
  errorMessage: "",
  showMenu: false,
  showError: false,
  instructionsInside: false,
  showInstruction: false,
  inputWidth: "100%",
  done: false,
  holder: "hipContent",
  scriptHolder: "hipScriptContainer",
};

export const getHipGlobalObjectName = (flowId: FlowId) => {
  switch (flowId) {
    case FlowId.Login:
      return "HIP";
    case FlowId.Acw:
    default:
      return "ChallengeControl";
  }
};

/**
 * This hook contains business logic for HipChallenge-component, to deal with challenges provided by Hip Challenge-service.
 * It communicates with HipChallenge-service via window.ChallengeControl-object. It can generate a traditional captcha or SMS challenge.
 * The flow is:
 * 1. This component here creates the object Window.ChallengeControl
 * 2. This component here creates a script HTML-tag to load, inject and run code from HipChallenge-service
 * 3. The injected code modifies the Window.ChallengeControl-object and renders inside HTML container with containerId.
 * 4. Callbacks on Window.ChallengeControl are used to pass state from HipChallenge-service back to this component here.
 * @param params Hip Challenge Props
 * @param params.config config for HIP service
 * @param params.config.url URL to load hip services assets
 * @param params.config.market market of user
 * @param params.config.flowId Hip Challenge flow Id
 * @param params.initialType type of HIP to load
 * @param params.onLoadingStatusChange callback when loading the captcha
 * @param params.onErrorMessageChange callback to set error message
 * @param params.onMenuButtonsChange callback to set menu items
 * @param params.onInstructionMessageChange callback to set the instruction to user
 * @param params.onSessionStart callback to start the session
 * @returns Hip Challenge component visual tree
 */
export const useHipContent = ({
  config: { url, market, flowId = "" },
  initialType,
  onLoadingStatusChange,
  onErrorMessageChange,
  onMenuButtonsChange,
  onInstructionMessageChange,
  onSessionStart,
}: UseHipContentParams): UseHipContentResponse => {
  const {
    globalState: { activeFlow },
  } = useContext(GlobalContext);
  const [scriptUrl, setScriptUrl] = useState<string>("");
  const hipGlobalObjectName = getHipGlobalObjectName(activeFlow);

  /**
   * Runs after HipChallenge-service captcha has loaded.
   */
  const postLoad = useCallback(() => {
    onLoadingStatusChange(false);
    /**
     * Whenever the HipChallenge component finished loading a new captcha, we treat this as the start of a new Hip Challenge-session,
     * analog to how Arkose Enforcement signals a session start.
     * The sessionToken is set to the value of Hip Challenge flowId, as expected by ALC backend Hip Challenge verification logic (e.g., through SendOttModel.cs).
     */
    const { type } = window[hipGlobalObjectName];
    const hipType = type as HipType; // parse string into Enum HipType
    onSessionStart({ sessionToken: flowId, type: hipType });

    if (window[hipGlobalObjectName]) {
      // setError function from Challenge Control is used to get the input id to focus the user on
      const { setError: setErrorAndReturnInputId } = window[hipGlobalObjectName];
      const inputField = setErrorAndReturnInputId?.();
      if (inputField) {
        const inputFieldElement = document.getElementById(inputField);
        if (inputFieldElement) {
          inputFieldElement.focus();
        }
      }
    }
  }, [flowId, hipGlobalObjectName, onLoadingStatusChange, onSessionStart]);

  useExternalScripts({ url: scriptUrl, elementId: DEFAULT_HIP_CONFIG.scriptHolder });

  useEffect(() => {
    if (!window[hipGlobalObjectName]) {
      // ensures this only happens once when the component is on visual tree, and blocks parallel Hip Challenge captchas.
      onLoadingStatusChange(true);

      window[hipGlobalObjectName] = {
        ...DEFAULT_HIP_CONFIG,
        type: initialType.toString(),
        market,
        postLoad,
        showErrorCallback: onErrorMessageChange,
        removeErrorCallback: onErrorMessageChange,
        menuOutsideCallback: onMenuButtonsChange,
        instructionOutsideCallback: onInstructionMessageChange,
      };

      setScriptUrl(url);
    }
  }, [
    initialType,
    market,
    onErrorMessageChange,
    onInstructionMessageChange,
    onLoadingStatusChange,
    onMenuButtonsChange,
    url,
    postLoad,
    activeFlow,
    hipGlobalObjectName,
  ]);

  // Reset onUnmount of component
  useEffect(
    () => () => {
      window[hipGlobalObjectName] = undefined;
    },
    [hipGlobalObjectName],
  );

  return {
    /**
     * Validates captcha solution client side.
     * Emits onErrorMessageChange or onSuccess. Solution needs to be validated service-side,
     * i.e. by passing parameters of onSuccess to ALC API that determined to challenge customer.
     * @returns token, solution and error
     */
    validateSolution: () => {
      const captchaControl = window[hipGlobalObjectName];
      let validationResponse = { token: "", solution: "", error: "" };

      if (captchaControl) {
        captchaControl.verify?.((solution: string, token: string) => {
          captchaControl.clientValidation?.();

          validationResponse = {
            error: captchaControl.error ? captchaControl.getError?.() : "",
            token,
            solution,
          };
        });
      }

      return validationResponse;
    },
    reloadHIP: () => {
      const captchaControl = window[hipGlobalObjectName];

      if (captchaControl) {
        captchaControl.reloadHIP(
          captchaControl.showError,
          captchaControl.urlFid,
          captchaControl.dataCenter,
        );
      }
    },
  };
};
