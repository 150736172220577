import { getCxh } from "../cxh-handler";

/**
 * Informs the host that the current flow is completed.
 * @param isScoobe is second chance oobe flow
 * @param isTokenBroker is token broker
 * @param preferAssociate prefer associate
 */
export const handleFinishState = async (
  isScoobe: boolean,
  isTokenBroker: boolean,
  preferAssociate: boolean,
) => {
  const CXH = await getCxh();

  if (isScoobe) {
    CXH.finish(CXH.FinishStates.ExitCxhSuccess);
  } else if (isTokenBroker) {
    const hostEventName = preferAssociate
      ? "MSA.AssociateUpsell.BackClicked"
      : "MSA.ConnectUpsell.BackClicked";
    CXH.logEvent(hostEventName);

    CXH.setAuthErrorAndComplete(
      "80048842" /* PPCRL_REQUEST_E_USER_CANCELED */,
      "800434F5" /* PP_E_LOGINPROTOCOL_CANCEL */,
    );
  } else {
    CXH.finish(CXH.FinishStates.Cancel);
  }
};
