import { useCallback } from "react";
import { useGlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";

/**
 * This hook is used to update the request pending and hide/show the back arrow button.
 * @param setIsRequestPending The setIsRequestPending callback
 * @param showBackArrowButton Whether or not the back arrow button is shown on the view, regardless of the request pending state. Used to determine if the
 * back arrow button state should be updated.
 * @returns A method for setting the request pending state and hiding/showing the back arrow button.
 */
export const useIsRequestPendingAndHideBackButton = (
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>,
  showBackArrowButton: boolean,
) => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();
  return useCallback(
    (isPending: boolean) => {
      setIsRequestPending(isPending);
      if (showBackArrowButton) {
        dispatchGlobalStateChange({
          type: GlobalActionType.HideBackArrowButton,
          payload: isPending,
        });
      }
    },
    [setIsRequestPending, showBackArrowButton, dispatchGlobalStateChange],
  );
};
