import React from "react";
import {
  type ICredentialSwitchLinkComponentProperties,
  CredentialSwitchLinksComponent,
} from "../../../../components/credential-switch-links/fabric/credential-switch-links-fabric";
import { LinkButton } from "../../../../components/link-button";
import StylesConfig from "../../../../config/styles-config";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { useSwitchToEvictedCredPicker } from "../../hooks/login-hooks";

export interface CredentialSwitchContentFabricProps {
  credentialSwitchProps: ICredentialSwitchLinkComponentProperties;
  showSwitchToEvictedCredPicker: boolean;
  evictedCredPickerLinkText?: string;
}

/**
 * This component is used to share the common content for switching credentials.
 * The CredentialSwitchLinksComponent is rendered in a form group.
 * If the showSwitchToEvictedCredPicker param is true, then a LinkButton to switch to the evicted credential picker is rendered in a form group.
 * Clicking this LinkButton will navigate to the CredentialPicker view.
 * This component is intended to be consumed with the `useShowCredentialSwitchContent` hook in order to determine whether it should be rendered and provide the
 * showSwitchToEvictedCredPicker param.
 * @param props The indicator to show the switch to evicted credentials link and the properties for the CredentialSwitchLinksComponent.
 * @returns A rendered instance of this component
 */
export const CredentialSwitchContentFabric: React.FC<CredentialSwitchContentFabricProps> =
  function CredentialSwitchContentFabric(props) {
    const {
      credentialSwitchProps,
      evictedCredPickerLinkText = commonLoginStringsFabric.evictedCredPickerLinkText,
      showSwitchToEvictedCredPicker,
    } = props;
    const { useCommonStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();

    const switchToEvictedCredPicker = useSwitchToEvictedCredPicker(
      credentialSwitchProps.sourceViewId,
    );

    return (
      <>
        <div className={commonStyles.formGroup}>
          <CredentialSwitchLinksComponent {...credentialSwitchProps} />
        </div>
        {showSwitchToEvictedCredPicker && (
          <div className={commonStyles.formGroup}>
            <LinkButton text={evictedCredPickerLinkText} onClick={switchToEvictedCredPicker} />
          </div>
        )}
      </>
    );
  };
