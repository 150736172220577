import { useContext, useMemo } from "react";
import GlobalConfig from "../global-config";
import { type TelemetryState, GlobalContext } from "../global-context";
import { getStandardDimensions } from "./telemetry-helper";

/**
 * React hook used to update and get the telemetry state.
 * @param currentState Current data to give more context for telemetry
 * @returns Telemetry state
 */
export function useTelemetryState(currentState?: {}): TelemetryState {
  const { activeFlavor } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow },
  } = useContext(GlobalContext);

  return useMemo(() => {
    const dimensions = getStandardDimensions({ activeView, activeFlow, activeFlavor });
    return { dimensions, currentState };
  }, [activeView, activeFlow, activeFlavor, currentState]);
}
