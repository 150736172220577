import { useEffect, useState } from "react";
import { type Location, useLocation } from "react-router-dom";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../../config/styles-config";
import { useGlobalContext } from "../../../../global-context";

interface INavigationStageMap {
  slideIn: string;
  slideOut: string;
}

const navigationStages: Record<string, INavigationStageMap> = {
  forward: {
    slideIn: "slideInNext",
    slideOut: "slideOutNext",
  },
  back: {
    slideIn: "slideInBack",
    slideOut: "slideOutBack",
  },
};

export type RouteAnimationResponseFabric = {
  currentLocation: Location;
  slideAnimationStyles: string;
  fadeInOnceAnimationStyles: string;
  onAnimationEnd: () => void;
};

/**
 * React hook used for applying view transition animations based on location change.
 * @returns RouteAnimationResponseFabric - The response object containing the current location, slide and fade animation styles, and the onAnimationEnd function
 */
export const useRouteAnimationFabric = (): RouteAnimationResponseFabric => {
  const {
    globalState: { navigationDirection },
  } = useGlobalContext();
  const { animate, slideInBack, slideInNext, slideOutBack, slideOutNext, fadeIn, fadeOut } =
    StylesConfig.instance.useLayoutAnimateStyles();
  const newLocation = useLocation();
  const slideOutNextAnimation = mergeClasses(animate, slideOutNext);
  const slideOutBackAnimation = mergeClasses(animate, slideOutBack);
  const slideInNextAnimation = mergeClasses(animate, slideInNext);
  const slideInBackAnimation = mergeClasses(animate, slideInBack);

  const [currentLocation, setCurrentLocation] = useState(newLocation);
  const [animationStage, setAnimationStage] = useState(
    navigationStages[navigationDirection].slideIn,
  );
  const [fadeInOnceAnimationStyles, setFadeInOnceAnimationStyles] = useState(fadeIn);
  const [slideAnimationStyles, setSlideAnimationStyles] = useState(slideInNextAnimation);

  useEffect(() => {
    // when location changes, set the outgoing animations of the current view based on the navigation direction
    if (
      newLocation.pathname !== currentLocation.pathname &&
      animationStage !== navigationStages[navigationDirection].slideOut
    ) {
      setAnimationStage(navigationStages[navigationDirection].slideOut);
      setSlideAnimationStyles(
        navigationDirection === "forward" ? slideOutNextAnimation : slideOutBackAnimation,
      );
    }
  }, [
    newLocation,
    currentLocation,
    navigationDirection,
    slideOutNextAnimation,
    slideOutBackAnimation,
    fadeOut,
    fadeInOnceAnimationStyles,
    animationStage,
  ]);

  const onAnimationEnd = () => {
    // once the outgoing animations are complete, add the incoming animations to the new view based on the navigation direction
    if (animationStage === navigationStages[navigationDirection].slideOut) {
      setAnimationStage(navigationStages[navigationDirection].slideIn);
      setSlideAnimationStyles(
        navigationDirection === "forward" ? slideInNextAnimation : slideInBackAnimation,
      );
      setCurrentLocation(newLocation);
    }

    if (fadeInOnceAnimationStyles !== "") {
      setFadeInOnceAnimationStyles("");
    }
  };

  return {
    currentLocation,
    slideAnimationStyles,
    onAnimationEnd,
    fadeInOnceAnimationStyles,
  };
};
