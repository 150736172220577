import React from "react";
import LoginConfig from "../../../flows/login/login-config";
import { CredentialType } from "../../../model/credential/credential-types";
import {
  type AccessibleImageUrls,
  facebookCredIcon,
  githubCredIcon,
  googleCredIcon,
  linkedInCredIcon,
} from "../../../utilities/image-helpers/accessible-images";
import { TileListFabric } from "../../tile-list/fabric/tile-list-fabric";
import { promotedCredsStringsFabric } from "./model/promoted-creds-strings-fabric";
import { usePromotedCredsHandlers } from "./model/use-promoted-creds-handlers";

// list of NASCAR credentials
const nascarCredList = [
  CredentialType.LinkedIn,
  CredentialType.GitHub,
  CredentialType.Google,
  CredentialType.Facebook,
];

/**
 *
 * @param text The input props for the global provider
 * @param urls The urls for the accessible image
 * @param handler The handler for the tile
 * @returns The tile representing the fedcred type
 */
function createFedCred(text: string, urls: AccessibleImageUrls, handler: () => void) {
  return {
    ariaLabel: text,
    isTilesUnderLightbox: true,
    mainText: text,
    image: { urls },
    onClick: handler,
  };
}

/**
 *
 * @param fedcredtype The input props for the global provider
 * @param onLinkedInClick The handler for the tile
 * @param onGitHubClick The handler for the tile
 * @param onGoogleClick The handler for the tile
 * @param onFacebookClick The handler for the tile
 * @returns The tile representing the fedcred type
 */
function createFedCreds(
  fedcredtype: CredentialType,
  onLinkedInClick: () => void,
  onGitHubClick: () => void,
  onGoogleClick: () => void,
  onFacebookClick: () => void,
) {
  switch (fedcredtype) {
    case CredentialType.LinkedIn:
      return createFedCred(
        promotedCredsStringsFabric.linkedinText,
        linkedInCredIcon,
        onLinkedInClick,
      );
    case CredentialType.GitHub:
      return createFedCred(promotedCredsStringsFabric.githubText, githubCredIcon, onGitHubClick);
    case CredentialType.Google:
      return createFedCred(promotedCredsStringsFabric.googleText, googleCredIcon, onGoogleClick);
    case CredentialType.Facebook:
      return createFedCred(
        promotedCredsStringsFabric.facebookText,
        facebookCredIcon,
        onFacebookClick,
      );
    default:
      throw new Error("Invalid nascar federated credential type");
  }
}

/**
 * PromotedCreds component
 * @returns an instance of this component
 */
const PromotedCredsFabric = function PromotedCredsFabric() {
  const { arrPromotedCreds } = LoginConfig.instance;
  const { onClickLinkedIn, onClickGitHub, onClickGoogle, onClickFacebook } =
    usePromotedCredsHandlers();
  const promotedCreds = arrPromotedCreds
    .filter((cred) => nascarCredList.includes(cred))
    .map((credType) =>
      createFedCreds(credType, onClickLinkedIn, onClickGitHub, onClickGoogle, onClickFacebook),
    );

  return <TileListFabric tileList={promotedCreds} />;
};

export default PromotedCredsFabric;
