import React from "react";
import StylesConfig from "../../../config/styles-config";
import { LayoutTemplateType } from "../../../constants";
import { useCustomizationContext } from "../../../context/customization-context";
import { useRenderPromises } from "../../../hooks";
import { initialPostRedirectState, PostRedirectProvider } from "../../../post-redirect-context";
import { useTelemetryState } from "../../../telemetry-helpers/use-telemetry-state";
import AppBoundary from "../../app-boundary/app-boundary";
import CriticalErrorPageFabric from "../../cricital-error-page/fabric/critical-error-page-fabric";
import LightboxLayout from "../../layout/fabric/lightbox-layout-fabric";
import VerticalSplitLayout from "../../layout/fabric/vertical-split-layout-fabric";
import { PostRedirectForm } from "../../post-redirect-form";
import { useFavIcon } from "../use-fav-icon";
import { useShell } from "../use-shell";

interface IShellFabricProps {
  customStyles?: Record<string, string>;
}

/**
 * Shell component for Fabric flavor
 * @param props The properties for this component
 * @param props.children The child elements to render inside this layout
 * @param props.customStyles The custom styles to apply to various parts of this component
 * @returns The rendered component (either vertical split or lightbox style depending on the layout template)
 */
const ShellFabric: React.FC<IShellFabricProps> = function ShellFabric({ children, customStyles }) {
  const { isPageBlank } = useShell();
  const telemetryState = useTelemetryState();

  const {
    customizationState: {
      styles: { faviconUrl, layoutTemplate },
    },
  } = useCustomizationContext();

  const LayoutComponent =
    layoutTemplate === LayoutTemplateType.VerticalSplit ? VerticalSplitLayout : LightboxLayout;

  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;
  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  // Get all the render promises that need to be executed before the initial view is rendered.
  // Render promises are used to load async content and update the customization state with the loaded content.
  useRenderPromises();

  useFavIcon(faviconUrl);

  return isPageBlank ? (
    <CriticalErrorPageFabric />
  ) : (
    <AppBoundary telemetryState={telemetryState} CriticalErrorPage={<CriticalErrorPageFabric />}>
      <LayoutComponent styles={commonStyles} customStyles={customStyles}>
        <PostRedirectProvider initialState={initialPostRedirectState}>
          <PostRedirectForm />
          {children}
        </PostRedirectProvider>
      </LayoutComponent>
    </AppBoundary>
  );
};

export default ShellFabric;
