import React from "react";
import { Routes } from "react-router-dom";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { useCustomizationContext } from "../../../context/customization-context";
import { useRouteAnimationFabric } from "./hooks/use-route-animation-fabric";

/**
 * A shared component for applying view transition animation properties with the `useRouteAnimation` hook.
 * @param props The component properties
 * @param props.children The child elements to render inside this component. This element must be declared inside a route component.
 * @returns An instance of the RouteAnimationFabric component
 */
const RouteAnimationFabric: React.FC = function RouteAnimationFabric({ children }) {
  const {
    customizationState: {
      styles: { boilerPlateText },
    },
  } = useCustomizationContext();
  const { currentLocation, slideAnimationStyles, onAnimationEnd } = useRouteAnimationFabric();
  const styles = StylesConfig.instance.useRouteAnimationStyles();
  const classNames = mergeClasses(
    boilerPlateText ? styles.routeContent : "",
    styles.paginationViewMinHeight,
    slideAnimationStyles,
  );

  return (
    <div data-testid="routeAnimation" className={classNames} onAnimationEnd={onAnimationEnd}>
      <Routes location={currentLocation}>{children}</Routes>
    </div>
  );
};

export default RouteAnimationFabric;
