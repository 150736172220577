import { type ICredentialSwitchLinkComponentProperties } from "../../../../components/credential-switch-links/fabric/credential-switch-links-fabric";
import { useGlobalContext } from "../../../../global-context";
import { CredentialType } from "../../../../model/credential";
import { cleanseUsername } from "../../../../model/user";
import { useFidoPostRedirect, useShowCredentialSwitchContent } from "../../hooks/login-hooks";
import { loginStringsConfig } from "../../login-strings-config";
import { useGetCredentialSwitchProperties } from "./use-get-credential-switch-properties";

export interface IPasskeyInterstitialViewProperties {
  title: string;
  description: string;
  nextLabel: string;
  showSwitchToEvictedCredPicker: boolean;
  showCredentialSwitchContent: boolean;
  credentialSwitchLinksProps: ICredentialSwitchLinkComponentProperties;
  fidoRedirect: () => void;
}

export const usePasskeyInterstitialViewProperties = (): IPasskeyInterstitialViewProperties => {
  const {
    globalState: {
      user: { username },
    },
  } = useGlobalContext();

  const {
    loginStrings: {
      passkeyInterstitialViewStrings: { title, description, nextLabel },
    },
  } = loginStringsConfig.instance;

  const { showSwitchToEvictedCredPicker, showCredentialSwitchContent } =
    useShowCredentialSwitchContent();

  const { credentialSwitchLinksProps } = useGetCredentialSwitchProperties(CredentialType.Fido);

  const fidoRedirectCallback = useFidoPostRedirect();

  return {
    title,
    description,
    nextLabel,
    showSwitchToEvictedCredPicker,
    showCredentialSwitchContent,
    credentialSwitchLinksProps,
    fidoRedirect: () =>
      fidoRedirectCallback({ username: cleanseUsername(username.unsafeUnescapedString) }),
  };
};
