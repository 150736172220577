import {
  type ApiResponseError,
  type ResponseError,
  BindProvider,
  IfExistsResult,
  QueryString,
  RemoteNgcType,
  ThrottleStatus,
  ViewId,
} from "../../../constants";
import FeaturesConfig from "../../../features-config";
import { AllowedIdentitiesType, Error, LoginState } from "../../../flows/login/login-constants";
import {
  type IRemoteLoginPollingParams,
  type IRemoteNgcParams,
} from "../../../flows/login/login-context";
import GlobalConfig from "../../../global-config";
import { isEmailAddress, isPhoneNumber } from "../../../model/alias";
import {
  type OneTimeCodeCredential,
  type UserCredential,
  type UserCredentials,
  CredentialType,
} from "../../../model/credential";
import { type OneTimeCodeProof, ProofType } from "../../../model/proof";
import { cleanseUsername } from "../../../model/user";
import { postApiRequest } from "../../request/request-helper";
import {
  appendOrReplaceQueryStringParams,
  removeQueryStringParameters,
  replaceTokens,
  stripQueryStringAndFragment,
} from "../../strings-helper";
import { gctStringsConfig } from "./gct-strings-config";

const cache: Map<string, RawGctResponse> = new Map();

/**
 * For testing only!
 * @param key username
 * @param value RawGctResponse
 */
export const addCache = (key: string, value: RawGctResponse) => {
  cache.set(key, value);
};

/**
 * For testing only!
 */
export const clearCache = () => {
  cache.clear();
};

export enum GctResultAction {
  ShowError = 1,
  SwitchView = 2,
  Redirect = 3,
}

export enum DomainType {
  Unknown = 1,
  Consumer = 2,
  Managed = 3,
  Federated = 4,
  CloudFederated = 5,
}

export type FederationRedirectParams = {
  idpRedirectPostParams?: RedirectPostParams;
  idpRedirectProvider?: BindProvider;
  idpRedirectUrl?: string;
};

export type ViewParams = FederationRedirectParams & {
  desktopSsoEnabled?: boolean;
  hasIdpDisambigError?: boolean;
  phoneDisambigError?: string;
};

export type AcmaProperties = {
  SupportsModernRecovery?: boolean;
  RecoveryContinuationToken?: string;
  PasswordRecoveryUrl?: string;
};

export type GctResult = {
  action: GctResultAction;
  bypassCache: boolean;
  error: string;
  flowToken: string | null;
  isBlockingError: boolean;
  sharedData: Partial<CredentialProperties>;
  viewId: ViewId;
  viewParams: ViewParams;
  redirectUrl: string;
  redirectPostParams: RedirectPostParams;
  isIdpRedirect: boolean;
  acmaProperties?: AcmaProperties;
};

// parameters for building request payload
export interface IGetCredentialTypeRequestParams {
  allowedIdentities: number;
  country: string;
  fedQs: string;
  flowToken: string;
  gctFederationFlags: number;
  getCredentialTypeUrl: string;
  isExternalFederationDisallowed: boolean;
  isFederationDisabled: boolean;
  isFidoSupported: boolean;
  isRemoteNGCSupported: boolean;
  otclogindisallowed: boolean;
  otherIdpRedirectUrl: string;
  unsafeUsername: string;
}

export interface IParseGetCredentialTypeResponseParams {
  changePasswordUrl?: string;
  doIfExists?: boolean;
  improvePhoneDisambiguation?: boolean;
  isAccountPickerView?: boolean;
  loginStateForPostBack?: number;
  postProofType?: ProofType;
  resetPasswordUrl?: string;
  showSignup?: boolean;
  signupUrl?: string;
  signupUrlPostParams?: RedirectPostParams;
  useResetPasswordUrlForPasswordRequiredError?: boolean;
}

export interface IGetCredentialTypeRequestHelperFlags {
  checkCurrentIdpOnly?: boolean;
  disableDesktopSsoPreferredCred?: boolean;
  disableAutoSend?: boolean;
  forceOtcLogin?: boolean;
  isPhoneNumberFullyQualified?: boolean;
  isPostRequest?: boolean;
  isSignup?: boolean;
}

export type CredentialProperties = UserCredentials & {
  displayName?: string;
  fedRedirectParams: FederationRedirectParams;
  fidoParams?: FidoParams;
  location: string;
  remoteLoginPollingParams?: IRemoteLoginPollingParams;
  remoteNgcParams: IRemoteNgcParams;
  username?: string;
};

export type EstsProperties = {
  DomainType?: number;
  RelayState?: string;
  SamlRequest?: string;
  UserTenantBranding?: {};
};

export type ResponseDeviceProperties = {
  Name?: string;
  Application: string;
};

export type RemoteLoginPollingParams = {
  OneTimeCode?: string;
  DeviceIdentifier?: string;
};

export type RemoteNgcParams = {
  DefaultType?: number;
  Entropy?: string;
  SessionIdentifier?: string;
  ShowAnimatedGifWhilePolling?: boolean;
  StyleCredSwitchLinkAsButton?: boolean;
  Devices?: ResponseDeviceProperties[];
};

export type FidoParams = {
  AllowList?: string[];
  ShowInterstitial?: boolean;
};

export type SasParams = {
  Success?: boolean;
};

export type LinkedInParams = {
  LinkedInRedirectUrl?: string;
};

export type GitHubParams = {
  GithubRedirectUrl?: string;
};

export type GoogleParams = {
  GoogleRedirectUrl?: string;
};

export type FacebookParams = {
  FacebookRedirectUrl?: string;
};

export type CertAuthParams = {
  CertAuthUrl?: string;
};

export type RawGctCredentials = {
  CertAuthParams?: CertAuthParams;
  CobasiApp?: boolean;
  FacebookParams?: FacebookParams;
  FederationRedirectUrl?: string;
  GitHubParams?: GitHubParams;
  GoogleParams?: GoogleParams;
  HasAccessPass?: number;
  HasCertAuth?: number;
  HasFacebookFed?: number;
  HasFido?: number;
  HasGitHubFed?: number;
  HasGoogleFed?: number;
  HasLinkedInFed?: number;
  HasPassword?: number;
  HasPhone?: number;
  HasRemoteNGC?: number;
  LinkedInParams?: LinkedInParams;
  OtcLoginEligibleProofs?: OneTimeCodeProof[];
  PrefCredential?: number;
  RemoteLoginPollingParams?: RemoteLoginPollingParams;
  RemoteNgcParams?: RemoteNgcParams;
  SasParams?: SasParams;
  FidoParams?: FidoParams;
  SendOTTHR?: string;
  PossibleEviction?: boolean;
  HasOtc?: boolean;
  OTCNotAutoSent?: number;
};

// Payload received from GCT request.
export type RawGctResponse = ApiResponseError & {
  AliasDisabledForLogin?: boolean;
  Credentials?: Partial<RawGctCredentials>;
  Display?: string;
  ErrorHR?: string;
  EstsProperties?: EstsProperties;
  FlowToken?: string;
  IfExistsResult?: IfExistsResult;
  IsProofForAlias?: boolean;
  IsSignupDisallowed?: boolean;
  Location?: string;
  RequiresPhoneDisambiguation?: boolean;
  IsUnmanaged?: boolean;
  ThrottleStatus?: number;
  Username?: string;
  ShowRemoteConnect?: boolean;
  AcmaProperties?: AcmaProperties;
};

export type RedirectPostParams =
  | {
      RelayState?: string;
      SAMLRequest?: string;
      username?: string;
    }
  | Record<string, string>;

type EstsOnlyGctBody = {
  /**
   * The country the request originated from
   */
  country?: string;
  /**
   * The proof of possession authenticator sent with the request.
   */
  cpa?: string;
  /**
   * The proof of possession script error string sent with the request.
   */
  cpa_error?: string;
  /**
   * Flag indicating that users that exist in a viral tenant should be treated
   * default = false
   */
  ignoreViralUsers?: boolean;
  /**
   * Flag indicating whether Access Pass is supported
   * default = false
   */
  isAccessPassSupported?: boolean;
  /**
   * Flag indicating that the user signing in must have a domain included in the current resource tenant
   * in order to sign in. This blocks passthrough user signin and guest user signin when using webcredprov.
   * default = false
   */
  isPassthroughDisallowed?: boolean;
  /**
   * Flag indicating that this request is made in the context of the user attempting to sign up for
   * a new account on the resource tenant.
   * default = false
   */
  isSignup: boolean;
  /**
   * The original STS request. This is supported in EVO only.
   */
  originalRequest: string;
};

type MsaOnlyGctBody = {
  /** default = false */
  checkPhones: boolean;
  /** default = None */
  federationFlags: number;
  /** default = false */
  forceotclogin: boolean;
  /** default = false */
  isExternalFederationDisallowed: boolean;
  /** default = false */
  isFederationDisabled: boolean;
  /** default = false */
  isPhoneNumberSignupDisallowed?: boolean;
  /** default = false */
  isRemoteConnectSupported: boolean;
  /** default = false */
  noPaOtcDisallowed?: boolean;
  /** default = false */
  otclogindisallowed: boolean;
  /** optional for telemetry */
  uaid: string;
};

export type GetCredentialTypeBody = EstsOnlyGctBody &
  MsaOnlyGctBody & {
    /**
     * Required (not empty) for both MSA and ESTS
     */
    username: string;
    /**
     * Flag indicating whether the check proof for aliases is enabled for the user
     * default = false
     */
    checkProofForAliases?: boolean;
    /**
     * Optional for MSA/ESTS
     */
    flowToken: string;
    /**
     * Flag indicating whether the cookie disclosure banner was shown to the user
     * default = false
     */
    isCookieBannerShown: boolean;
    /**
     * Flag indicating whether the Fido is supported
     * default = false
     */
    isFidoSupported: boolean;
    /**
     * Flag indicating whether or not we should query to see if the user exists in
     * Microsoft's other public IDP (MSA vs ESTS).
     * default = false
     */
    isOtherIdpSupported: boolean;
    /**
     * Flag indicating whether the remote NGC is supported
     * MSA default = false; ESTS default = true
     */
    isRemoteNGCSupported: boolean;
  };

/**
 * @param cleansedUnsafeUsername The cleansed unsafe username, which is used as a key to the cache
 * @returns The cached entry, or null if not found
 */
export const checkCache = (cleansedUnsafeUsername: string) => {
  const entry = cache.get(cleansedUnsafeUsername);
  return entry || null;
};

/**
 * Builds data to format that will be consumed by GCT endpoint
 * @param requestParams Parameters needed for request
 * @param flags The optional GTC request helper flags
 * @returns Post data
 */
export const buildGetCredentialTypeRequest = (
  requestParams: IGetCredentialTypeRequestParams,
  flags?: IGetCredentialTypeRequestHelperFlags,
): GetCredentialTypeBody => {
  const {
    context,
    showCookieBanner,
    unauthenticatedSessionId,
    remoteConnectEnabled,
    checkProofForAliases,
    isNoPasswordOneTimeCodeDisabled,
    isPhoneNumberSignupDisallowed,
  } = GlobalConfig.instance;

  const {
    allowedIdentities,
    country,
    flowToken,
    gctFederationFlags,
    isExternalFederationDisallowed,
    isFederationDisabled,
    isFidoSupported,
    isRemoteNGCSupported,
    otclogindisallowed,
    unsafeUsername,
  } = requestParams;

  const cleansedUnsafeUsername = cleanseUsername(
    unsafeUsername,
    true /* preserve leading plus sign */,
  );

  const postData: GetCredentialTypeBody = {
    checkPhones: isPhoneNumber(cleansedUnsafeUsername),
    country,
    federationFlags: gctFederationFlags,
    flowToken,
    forceotclogin: !!flags?.forceOtcLogin,
    isCookieBannerShown: showCookieBanner,
    isExternalFederationDisallowed,
    isFederationDisabled,
    isFidoSupported,
    isOtherIdpSupported:
      !flags?.checkCurrentIdpOnly && allowedIdentities === AllowedIdentitiesType.Both,
    isRemoteConnectSupported: remoteConnectEnabled,
    isRemoteNGCSupported,
    isSignup: !!flags?.isSignup,
    originalRequest: context,
    otclogindisallowed,
    uaid: unauthenticatedSessionId,
    username: cleansedUnsafeUsername,
  };

  if (checkProofForAliases) {
    postData.checkProofForAliases = true;
  }

  if (isNoPasswordOneTimeCodeDisabled) {
    postData.noPaOtcDisallowed = true;
  }

  if (isPhoneNumberSignupDisallowed) {
    postData.isPhoneNumberSignupDisallowed = true;
  }

  // AAD-TODO:
  // Add cpa/cpa_err (CookiePopAuthenticator)
  // Add isPassthroughDisallowed - fIsPassthroughDisallowed
  // Add ignoreViralUsers - ServerData.fIgnoreViralUsers
  // Add isAccessPassSupported - ServerData.fAccessPassSupported

  return postData;
};

/**
 * Returns an object that specifies to switch view, with necessary view ID and parameters
 * @param viewId View ID to switch to
 * @param viewParams View parameters needed
 * @returns Switch view action object
 */
export const buildGctResultActionSwitchView = (
  viewId: ViewId,
  viewParams?: ViewParams,
): Partial<GctResult> => ({
  action: GctResultAction.SwitchView,
  viewId,
  viewParams,
});

/**
 * Returns an object that specifies the redirect action. This is used when a redirect action is needed (e.g., when signup link is clicked)
 * or when the GCT response is to do a redirect.
 * @param redirectUrl The redirct url
 * @param redirectPostParams The redirect post parameters
 * @param isIdpRedirect Whether or not it is an IDP redirect
 * @returns Redirect action object
 */
export const buildGctResultRedirectAction = (
  redirectUrl: string,
  redirectPostParams: RedirectPostParams,
  isIdpRedirect: boolean,
): Partial<GctResult> => ({
  action: GctResultAction.Redirect,
  redirectUrl,
  redirectPostParams,
  isIdpRedirect,
});

/**
 * Returns an object that specifies to show an error
 * @param error The error returned from the response
 * @param isBlockingError Whether or not it's a blocking error
 * @param bypassCache Whether or not it should bypass cache
 * @returns Show error action object
 */
export const buildGctResultActionShowError = (
  error: string,
  isBlockingError?: boolean,
  bypassCache?: boolean,
): Partial<GctResult> => ({
  action: GctResultAction.ShowError,
  error,
  isBlockingError,
  bypassCache,
});

/**
 * Gets the preferred credential of user from response (or defaults to password)
 * @param response Response from request
 * @param isFidoSupported Whether or not FIDO is supported
 * @param loginStateForPostBack  The login state returned by the server on a postback.
 *   Note that this is argument is undefined if and only if the response is not a result of a POST request
 * @returns The preferred credential of user
 */
export const getPreferredCredential = (
  response: Partial<RawGctResponse>,
  isFidoSupported: boolean,
  loginStateForPostBack?: number,
) => {
  let preferredCredential = CredentialType.Password; // default to password
  const credentials = response.Credentials;

  if (credentials && credentials.PrefCredential) {
    preferredCredential = credentials.PrefCredential;

    // On postbacks, the server doesn't return a correct PrefCredential for the OneTimeCode view.
    // As a result, we need to infer the correct preferred credential type from the posted login state.
    if (loginStateForPostBack === LoginState.OneTimeCode) {
      if (
        ![CredentialType.PublicIdentifierCode, CredentialType.NoPreferredCredential].includes(
          preferredCredential,
        )
      ) {
        preferredCredential = CredentialType.OneTimeCode;
      }
    } else if (preferredCredential === CredentialType.Fido && !isFidoSupported) {
      // If FIDO is not available we will attempt to use Remote NGC; otherwise we use password
      preferredCredential =
        credentials.RemoteNgcParams && credentials.RemoteNgcParams.SessionIdentifier
          ? CredentialType.RemoteNGC
          : CredentialType.Password;
    }
  }

  return preferredCredential;
};

/**
 * Determines if the username is an AAD verified domain that can be used for Signup
 * @param response The GCT response
 * @returns A boolean stating if the given username can be used for Signup
 */
export const isSignupAllowedForUsername = (response: RawGctResponse) =>
  // TODO AAD/ESTS: Add logic for implementing AAD domain verification
  !response.IsSignupDisallowed;

/**
 * Builds the signup GCT redirect action. This is used by other GCT helper methods.
 * @param cleansedUnsafeUsername The username
 * @param response The GCT response
 * @param signupUrl The signup URL
 * @param signupUrlPostParams The signup URL post parameters
 * @returns An object that specifies the redirect action
 */
export const getSignupRedirectAction = (
  cleansedUnsafeUsername: string,
  response: RawGctResponse | null,
  signupUrl: string,
  signupUrlPostParams: RedirectPostParams,
): Partial<GctResult> => {
  let updatedSignupUrl = signupUrl;
  updatedSignupUrl = removeQueryStringParameters(signupUrl, ["username", "login_hint"]);

  const signupPostParams = signupUrlPostParams;

  // Only prefill the current name when we've determined it doesn't exist
  if (
    response &&
    (response.IfExistsResult === IfExistsResult.NotExist ||
      (response.IsUnmanaged && response.IfExistsResult === IfExistsResult.Exists))
  ) {
    if (isSignupAllowedForUsername(response)) {
      if (Object.keys(signupPostParams).length) {
        signupPostParams.username = cleansedUnsafeUsername;
      } else {
        updatedSignupUrl = appendOrReplaceQueryStringParams(updatedSignupUrl, {
          username: encodeURIComponent(cleansedUnsafeUsername),
          login_hint: encodeURIComponent(cleansedUnsafeUsername),
        });
      }
    }
  }

  return buildGctResultRedirectAction(
    updatedSignupUrl,
    signupPostParams,
    false /* isIdpRedirect */,
  );
};

/**
 * Gets the redirect action when the signup link is clicked. This is used in the `useSignupClickHandler` hook.
 * When there is a cached response and username does not exist, it checks if signup is allowed and updates the signup URL or post parameters with the username.
 * When there isn't a cached response, the signup URL is not modified. It also sets `idpRedirect` to false.
 * @param unsafeUsername The username
 * @param signupUrl The signup URL
 * @param signupUrlPostParams The signup URL post parameters
 * @returns An object that specifies the redirect action
 */
export const getSignupRedirectGctResult = (
  unsafeUsername: string,
  signupUrl: string,
  signupUrlPostParams: RedirectPostParams,
) => {
  const cleansedUnsafeUsername = cleanseUsername(
    unsafeUsername,
    true /* preserve leading plus sign */,
  );

  const cachedResponse = checkCache(cleansedUnsafeUsername);

  return getSignupRedirectAction(
    cleansedUnsafeUsername,
    cachedResponse,
    signupUrl,
    signupUrlPostParams,
  );
};

/**
 * If the username is not found, return the correct error
 * @param response gct response
 * @param parseResponseParams params for parsing response
 * @param cleansedUnsafeUsername username entered that is not found
 * @param flags The optional GTC request helper flags
 * @returns The correct error based on information from response
 */
export const getUsernameNotFoundGctResult = (
  response: RawGctResponse,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  cleansedUnsafeUsername: string,
  flags?: IGetCredentialTypeRequestHelperFlags,
) => {
  let errorString: string;
  const { showSignup, doIfExists, signupUrl, signupUrlPostParams, isAccountPickerView } =
    parseResponseParams;
  const signupAllowedForUsername = isSignupAllowedForUsername(response);

  const { gctStrings } = gctStringsConfig.instance;

  if (showSignup && signupAllowedForUsername && doIfExists) {
    return getSignupRedirectAction(
      cleansedUnsafeUsername,
      response,
      signupUrl!,
      signupUrlPostParams!,
    );
  }

  if (flags?.isPhoneNumberFullyQualified) {
    errorString = parseResponseParams.improvePhoneDisambiguation
      ? gctStrings.usernameNotExistPhoneDisambig
      : gctStrings.invalidNumber;
  } else if (isAccountPickerView) {
    errorString = replaceTokens(
      gctStrings.accountDoesNotExistError,
      response.Display || cleansedUnsafeUsername,
    );
  } else {
    errorString = showSignup ? gctStrings.badUsername : gctStrings.usernameNotExistSignup;
  }

  return buildGctResultActionShowError(errorString);
};

export const getOtherIdpRedirectGctResult = (
  otherIdpRedirectUrl: string,
  unsafeUsername: string,
  otherIdpPostParams?: RedirectPostParams,
) => {
  const encodedUsername = encodeURIComponent(unsafeUsername).replace(/'/g, "%27");
  let updatedOtherIdpRedirectUrl = otherIdpRedirectUrl;
  // Add/update both username and login_hint so it can be understood by all protocols on both ESTS and MSA
  updatedOtherIdpRedirectUrl = appendOrReplaceQueryStringParams(otherIdpRedirectUrl, {
    username: encodedUsername,
    login_hint: encodedUsername,
  });

  const updatedOtherIdpPostParams = otherIdpPostParams
    ? { ...otherIdpPostParams, username: unsafeUsername }
    : {};

  return buildGctResultRedirectAction(updatedOtherIdpRedirectUrl, updatedOtherIdpPostParams, true);
};

/**
 * If phone number is fully qualified, returns the error action; otherwise, returns the switch view action.
 * @param errorCode The error code from the response
 * @param parseResponseParams Parameters for parsing GCT response
 * @param flags The GCT request helper flags
 * @returns The error action or view action
 */
export const getInvalidPhoneNumberGctResult = (
  errorCode: string,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  flags?: IGetCredentialTypeRequestHelperFlags,
): Partial<GctResult> => {
  const { gctStrings } = gctStringsConfig.instance;

  if (flags?.isPhoneNumberFullyQualified) {
    const errorString = parseResponseParams.improvePhoneDisambiguation
      ? gctStrings.usernameNotExistPhoneDisambig
      : gctStrings.invalidNumber;
    return buildGctResultActionShowError(errorString, true);
  }

  return buildGctResultActionSwitchView(ViewId.PhoneDisambiguation, {
    phoneDisambigError: errorCode,
  });
};

/**
 * Returns the OTC credentials available
 * @param response Response from the request
 * @param parseResponseParams Params for parsing response
 * @param isDefault Whether or not it is the default scenario (user is not evicted)
 * @param isFidoSupported Whether or not FIDO is supported
 * @param flags GCT request helper flags
 * @returns The available OTC credentials parsed from the response
 */
export const getOtcCredentials = (
  response: RawGctResponse,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  isDefault: boolean,
  isFidoSupported: boolean,
  flags?: IGetCredentialTypeRequestHelperFlags,
): UserCredential[] => {
  const otcCredentials: UserCredential[] = [];
  const otcLoginEligibleProofs: OneTimeCodeProof[] | undefined =
    response.Credentials?.OtcLoginEligibleProofs;

  if (otcLoginEligibleProofs) {
    // Cobasi = code based sign in (phone or email OTC as single/first factor sign in)
    // check if we want to fully support Cobasi on the current app hosting our service
    // unless this flag is set, we will hide phone Cobasi when possible to save costs
    const isCobasiApp =
      response.Credentials && response.Credentials.HasPhone && response.Credentials.CobasiApp;

    otcLoginEligibleProofs.forEach((eligibleProof: OneTimeCodeProof) => {
      // show evicted creds for evicted scenarios, and default creds for non-evicted scenarios
      if (eligibleProof.isDefault !== isDefault) {
        return;
      }

      const otcCredential: UserCredential = {
        credentialType: CredentialType.OneTimeCode,
        proof: eligibleProof,
      };

      if (otcCredential.proof) {
        // hide the proof data (phone number or email) from being displayed on the page
        otcCredential.proof.isEncrypted = true;

        switch (eligibleProof.type) {
          case ProofType.SMS:
          case ProofType.Voice:
            if (!eligibleProof.isVoiceOnly) {
              // check if the OTC has already been sent
              if (
                otcCredential.proof.otcSent &&
                flags?.isPostRequest &&
                parseResponseParams.postProofType === ProofType.Voice
              ) {
                otcCredential.proof.otcSent = false;
              }

              // show this phone OTC only on the cred picker ('Other ways to sign in')
              if (!isCobasiApp) {
                otcCredential.shownOnlyOnPicker = true;
              }

              otcCredential.proof.type = ProofType.SMS;
              otcCredentials.push(otcCredential);
            }

            if (eligibleProof.voiceEnabled) {
              // check if the OTC has already been sent
              if (
                otcCredential.proof.otcSent &&
                !(flags?.isPostRequest && parseResponseParams.postProofType === ProofType.Voice)
              ) {
                otcCredential.proof.otcSent = false;
              }

              otcCredential.proof.type = ProofType.Voice;
              otcCredentials.push(otcCredential);
            }

            break;

          case ProofType.Email:
            otcCredentials.push(otcCredential);
            break;

          default:
            break;
        }
      }
    });
  }

  // add the username as the OTC credential if there are no other OTCs and OTC is preferred
  if (
    isDefault &&
    otcCredentials.length === 0 &&
    getPreferredCredential(response, isFidoSupported) === CredentialType.OneTimeCode
  ) {
    const hasPassword = response.Credentials && response.Credentials.HasPassword;
    const otcCredential: UserCredential = {
      credentialType: CredentialType.OneTimeCode,
      proof: {
        display: response.Display,
        data: cleanseUsername(response.Display || ""),
        otcSent: true,
        isEncrypted: false,
        isDefault: true,
        isNopa: !hasPassword,
        type: isEmailAddress(response.Username || "") ? ProofType.Email : ProofType.SMS,
      },
    };

    otcCredentials.push(otcCredential);
  }

  return otcCredentials;
};

/**
 * Returns the certificate authentication params that are used to sign users in to tenants that have certificate-based
 * authentication enabled. When cert-based auth is enabled, we show
 * 1. a `Use certificate` tile in the credential picker view and
 * 2. a `Use certificate` link button in the password view that redirects the user to sign in with a certificate
 * @param flowToken The current flow token value
 * @returns An object that contains the certificate authentication params
 */
export const getCertAuthParams = (flowToken?: string) => {
  const { context } = GlobalConfig.instance;

  return {
    ctx: context,
    flowToken,
  };
};

/**
 * Returns the available credentials
 * @param response Response from the request
 * @param parseResponseParams Params for parsing response
 * @param isFidoSupported Whether or not FIDO is supported
 * @param flags The optional GTC request helper flags
 * @returns The available credentials parsed from the shared data object
 */
export const getAvailableCreds = (
  response: RawGctResponse,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  isFidoSupported: boolean,
  flags?: IGetCredentialTypeRequestHelperFlags,
): UserCredential[] => {
  const credentials = response.Credentials || {};
  const sasParams = credentials.SasParams;
  const linkedInParams = credentials.LinkedInParams;
  const gitHubParams = credentials.GitHubParams;
  const googleParams = credentials.GoogleParams;
  const facebookParams = credentials.FacebookParams;
  const certAuthParams = credentials.CertAuthParams;
  const estsProperties = response.EstsProperties || {};

  // available credentials include any credentials associated with a user for authentication purposes
  let availableCredentials: UserCredential[] = [];
  availableCredentials = availableCredentials.concat(
    credentials.HasPassword && estsProperties.DomainType !== DomainType.Federated
      ? { credentialType: CredentialType.Password }
      : [],
    credentials.FederationRedirectUrl && estsProperties.DomainType === DomainType.Federated
      ? { credentialType: CredentialType.Federation }
      : [],
    credentials.FederationRedirectUrl && estsProperties.DomainType === DomainType.CloudFederated
      ? { credentialType: CredentialType.CloudFederation }
      : [],
    credentials.HasRemoteNGC ? { credentialType: CredentialType.RemoteNGC } : [],
    credentials.HasFido && isFidoSupported ? { credentialType: CredentialType.Fido } : [],
    credentials.HasPhone && sasParams
      ? { credentialType: CredentialType.PublicIdentifierCode }
      : [],
    credentials.HasLinkedInFed && linkedInParams
      ? { credentialType: CredentialType.LinkedIn, redirectUrl: linkedInParams.LinkedInRedirectUrl }
      : [],
    credentials.HasGitHubFed && gitHubParams
      ? { credentialType: CredentialType.GitHub, redirectUrl: gitHubParams.GithubRedirectUrl }
      : [],
    credentials.HasGoogleFed && googleParams
      ? { credentialType: CredentialType.Google, redirectUrl: googleParams.GoogleRedirectUrl }
      : [],
    credentials.HasFacebookFed && facebookParams
      ? { credentialType: CredentialType.Facebook, redirectUrl: facebookParams.FacebookRedirectUrl }
      : [],
    credentials.HasAccessPass ? { credentialType: CredentialType.AccessPass } : [],
    credentials.HasCertAuth
      ? {
          credentialType: CredentialType.Certificate,
          redirectUrl: certAuthParams?.CertAuthUrl,
          redirectPostParams: getCertAuthParams(response.FlowToken),
        }
      : [],
  );

  const otcCredentials: UserCredential[] = getOtcCredentials(
    response,
    parseResponseParams,
    true,
    isFidoSupported,
    flags,
  );

  if (otcCredentials.length > 0) {
    availableCredentials = availableCredentials.concat(otcCredentials);
  }

  return availableCredentials;
};

/**
 * This method is used to transform the GCT response into an array of evicted credentials
 * @param response Response from the request
 * @param parseResponseParams Params for parsing response
 * @param isFidoSupported Whether or not FIDO is supported
 * @param flags The optional GTC request helper flags
 * @returns The evicted credentials transformed from the GCT response
 */
export const getEvictedCredentials = (
  response: RawGctResponse,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  isFidoSupported: boolean,
  flags?: IGetCredentialTypeRequestHelperFlags,
) => {
  // AAD-TODO: Ensure this functionality is okay for AAD's GCT response (this logic wasn't reachable before React)
  let evictedCredentials: UserCredential[] = [];
  const otcCreds = getOtcCredentials(response, parseResponseParams, false, isFidoSupported, flags);

  if (otcCreds.length) {
    evictedCredentials = evictedCredentials.concat(otcCreds);

    // When an evicted otc credential present does NOT have a 'no password' proof
    // add a non-default evicted credential for password
    if (otcCreds.find((userCred: UserCredential) => !userCred.proof?.isNopa)) {
      evictedCredentials = evictedCredentials.concat({
        credentialType: CredentialType.Password,
        isDefault: false,
      });
    }
  }

  return evictedCredentials;
};

/**
 * Builds and returns the federation redirect url
 * @param fedQs The federation qs
 * @param initialFedUrl The initial federation url
 * @param unsafeUsername The unescaped username returned by the server as part of the response
 * @returns The federation redirect url
 */
export const buildFederationRedirectUrl = (
  fedQs: string,
  initialFedUrl: string,
  unsafeUsername: string,
) => {
  const serverSearchParams = new URLSearchParams(fedQs);

  const clientSearchParams = new URLSearchParams(window.location.search);

  const federationUrl = new URL(initialFedUrl);
  const federationSearchParams = federationUrl.searchParams;

  // Adding all server provided query params to the federation url
  serverSearchParams.forEach((value, key) => {
    federationSearchParams.set(key, value);
  });

  // Note that the get and set methods will implicitly call decode and encode respectively
  // All values in the object below should be explicitly decoded if not obtained using the get method
  Object.entries({
    [QueryString.webServicesFederationContext]: `LoginOptions=3&${
      serverSearchParams.get(QueryString.webServicesFederationContext) ||
      federationSearchParams.get(QueryString.webServicesFederationContext) ||
      ""
    }`,
    [QueryString.cobrandingContext]: clientSearchParams.get(QueryString.cobrandingContext) || "",
    [QueryString.username]: unsafeUsername,
    [QueryString.market]: clientSearchParams.get(QueryString.market) || "",
    [QueryString.languageCodeId]: clientSearchParams.get(QueryString.languageCodeId) || "",
  }).forEach(([key, value]) => {
    federationSearchParams.set(key, value);
  });

  federationUrl.search = federationSearchParams.toString();

  return federationUrl.href;
};

/**
 * Returns the federation redirect parameters such as the redirect url, post params and provider
 * @param unsafeUsername The unescaped username returned by the server as part of the response
 * @param response Response from request
 * @param isFidoSupported Whether or not FIDO is supported
 * @param fedQs The federation qs
 * @returns The federation redirect parameters
 */
export const getFederationRedirectParams = (
  unsafeUsername: string,
  response: RawGctResponse,
  isFidoSupported: boolean,
  fedQs: string,
): FederationRedirectParams => {
  const fedRedirectParams: FederationRedirectParams = {};
  const preferredCredential = getPreferredCredential(response, isFidoSupported);
  const estsProperties = response.EstsProperties || {};

  if (
    !response.Credentials?.FederationRedirectUrl &&
    !response.Credentials?.LinkedInParams &&
    !response.Credentials?.GitHubParams &&
    !response.Credentials?.GoogleParams &&
    !response.Credentials?.FacebookParams
  ) {
    // We were not given a federation redirect URL, so bail out now
    return fedRedirectParams;
  }

  switch (preferredCredential) {
    case CredentialType.RemoteNGC:
    case CredentialType.Federation:
    case CredentialType.AccessPass:
    case CredentialType.NoPreferredCredential:
      // We were not given a federation redirect URL, so bail out now
      if (!response.Credentials?.FederationRedirectUrl) {
        break;
      }

      if (estsProperties && estsProperties.SamlRequest && estsProperties.RelayState) {
        fedRedirectParams.idpRedirectUrl = response.Credentials.FederationRedirectUrl;
        fedRedirectParams.idpRedirectPostParams = {
          SAMLRequest: estsProperties.SamlRequest,
          RelayState: estsProperties.RelayState,
          username: unsafeUsername,
        };
      } else {
        fedRedirectParams.idpRedirectUrl = buildFederationRedirectUrl(
          fedQs,
          response.Credentials.FederationRedirectUrl || "",
          unsafeUsername,
        );
      }

      break;

    case CredentialType.CloudFederation:
      fedRedirectParams.idpRedirectUrl = response.Credentials.FederationRedirectUrl;
      break;

    case CredentialType.LinkedIn:
      fedRedirectParams.idpRedirectUrl = response.Credentials.LinkedInParams?.LinkedInRedirectUrl;
      fedRedirectParams.idpRedirectProvider = BindProvider.LinkedIn;
      break;

    case CredentialType.GitHub:
      fedRedirectParams.idpRedirectUrl = response.Credentials.GitHubParams?.GithubRedirectUrl;
      fedRedirectParams.idpRedirectProvider = BindProvider.GitHub;
      break;

    case CredentialType.Google:
      fedRedirectParams.idpRedirectUrl = response.Credentials.GoogleParams?.GoogleRedirectUrl;
      fedRedirectParams.idpRedirectProvider = BindProvider.Google;
      break;

    case CredentialType.Facebook:
      fedRedirectParams.idpRedirectUrl = response.Credentials.FacebookParams?.FacebookRedirectUrl;
      fedRedirectParams.idpRedirectProvider = BindProvider.Facebook;
      break;

    default:
      break;
  }

  return fedRedirectParams;
};

/**
 * Returns the user credential properties transformed from the GCT response
 * @param response Response from the request
 * @param parseResponseParams Params for parsing response
 * @param isFidoSupported Whether or not FIDO is supported
 * @param isCachedResponse Whether or not the response is cached
 * @param flags The optional GTC request helper flags
 * @returns A shared data object from the parsed response
 */
export const transformCredentialResponse = (
  response: RawGctResponse,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  isFidoSupported: boolean,
  isCachedResponse: boolean,
  flags?: IGetCredentialTypeRequestHelperFlags,
): CredentialProperties => {
  const { loginStateForPostBack } = parseResponseParams;
  const preferredCredential = getPreferredCredential(
    response,
    isFidoSupported,
    loginStateForPostBack,
  );
  const responseCreds = response.Credentials || {};
  const loginPollingParams = responseCreds.RemoteLoginPollingParams;
  const ngcParams = responseCreds.RemoteNgcParams;
  const fidoParams = responseCreds.FidoParams;
  const remoteNgcDefaultType = ngcParams?.DefaultType || null;
  const remoteNgcShowAnimatedGifWhilePolling = !!ngcParams?.ShowAnimatedGifWhilePolling;
  const remoteNgcStyleCredSwitchLinkAsButton = !!ngcParams?.StyleCredSwitchLinkAsButton;
  const fedRedirectParams = getFederationRedirectParams(
    response.Username || "",
    response,
    isFidoSupported,
    "",
  );

  const remoteNgcRequestSent = !!(
    !flags?.disableAutoSend &&
    !isCachedResponse &&
    preferredCredential === CredentialType.RemoteNGC &&
    ngcParams &&
    Object.prototype.hasOwnProperty.call(ngcParams, "Entropy")
  );
  const devices = ngcParams?.Devices || [];
  // Transform the property keys to lowercase
  const formattedDevicesList = devices.map((device: ResponseDeviceProperties) => ({
    name: device.Name ?? "",
    application: device.Application,
  }));

  const remoteLoginPollingParams = {
    oneTimeCode: loginPollingParams?.OneTimeCode ?? "",
    deviceIdentifier: loginPollingParams?.DeviceIdentifier ?? "",
  };

  const remoteNgcParams = {
    requestSent: remoteNgcRequestSent,
    sessionIdentifier: ngcParams?.SessionIdentifier ?? "",
    entropy: ngcParams?.Entropy ?? "",
    defaultType: remoteNgcDefaultType,
    showAnimatedGifWhilePolling: remoteNgcShowAnimatedGifWhilePolling,
    styleCredSwitchLinkAsButton: remoteNgcStyleCredSwitchLinkAsButton,
    devices: formattedDevicesList,
  };

  const availableCredentials = getAvailableCreds(
    response,
    parseResponseParams,
    isFidoSupported,
    flags,
  );

  const credentialProps: Partial<CredentialProperties> = {
    location: response.Location || "",
    remoteLoginPollingParams,
    remoteNgcParams,
    availableCredentials,
    preferredCredential,
    evictedCredentials: getEvictedCredentials(
      response,
      parseResponseParams,
      isFidoSupported,
      flags,
    ),
    useEvictedCredentials: false,
    fedRedirectParams,
  };

  if (response.Display) {
    credentialProps.displayName = response.Display;
  }

  if (preferredCredential === CredentialType.OneTimeCode) {
    let otcCred = availableCredentials.find(
      (cred: UserCredential) =>
        cred.credentialType === CredentialType.OneTimeCode && cred.proof?.otcSent,
    );

    // auto-send block experiment could prevent the `proof.otcSent` flag from being set
    // so in that case, find the first OTC credential
    if (!otcCred && response.Credentials?.OTCNotAutoSent) {
      otcCred = availableCredentials.find(
        (cred: UserCredential) => cred.credentialType === CredentialType.OneTimeCode,
      );
    }

    if (otcCred) {
      credentialProps.otcCredential = otcCred as OneTimeCodeCredential;
    }
  }

  // @TODO: Add credentialProps.otcParams.requestSent when we use the isRequestSent flag in the OTC view

  if (fidoParams) {
    credentialProps.fidoParams = {
      AllowList: fidoParams.AllowList,
      ShowInterstitial: fidoParams.ShowInterstitial,
    };
  }

  return credentialProps as CredentialProperties;
};

/**
 * If the username is found, get the preferred credential of user and determine action based on it
 * @param response Response from request
 * @param parseResponseParams Parameters for parsing GCT response
 * @param cleansedUnsafeUsername Username from request
 * @param isFidoSupported Whether or not FIDO is supported
 * @param fedRedirectParams The federation redirect parameters
 * @param isProofConfirmationRequired Whether or not proof confirmation is required
 * @param flags The optional GCT request helper flags
 * @returns The correct action/view based on the preferred credential
 */
export const getUsernameFoundGctResult = (
  response: RawGctResponse,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  cleansedUnsafeUsername: string,
  isFidoSupported: boolean,
  fedRedirectParams: FederationRedirectParams,
  isProofConfirmationRequired: boolean,
  flags?: IGetCredentialTypeRequestHelperFlags,
): Partial<GctResult> => {
  const { signupUrl = "", signupUrlPostParams = {} } = parseResponseParams;
  const preferredCredential = getPreferredCredential(response, isFidoSupported);

  const idpRedirectViewParams = {
    idpRedirectUrl: fedRedirectParams.idpRedirectUrl,
    idpRedirectPostParams: fedRedirectParams.idpRedirectPostParams,
    idpRedirectProvider: fedRedirectParams.idpRedirectProvider,
  };

  switch (preferredCredential) {
    case CredentialType.OneTimeCode: {
      // when OTCNotAutoSent flag is true it means SMS auto-send is disabled
      // on the server side to combat spam attacks. Redirecting users to confirm
      // send or proof confirmation to continue with the otc flow
      if (flags?.disableAutoSend || response.Credentials?.OTCNotAutoSent) {
        return buildGctResultActionSwitchView(
          isProofConfirmationRequired ? ViewId.ProofConfirmation : ViewId.ConfirmSend,
        );
      }

      return buildGctResultActionSwitchView(ViewId.OneTimeCode);
    }

    // TO-DO: AAD/ESTS - Add case for PublicIdentifierCode credential type to switch to ConfirmSend or OneTimeCode views.

    case CredentialType.RemoteNGC: {
      const isPushNotification =
        response.Credentials?.RemoteNgcParams?.DefaultType === RemoteNgcType.PushNotification;
      return buildGctResultActionSwitchView(
        flags?.disableAutoSend && isPushNotification
          ? ViewId.ConfirmSend
          : ViewId.PushNotifications,
        idpRedirectViewParams,
      );
    }

    case CredentialType.Fido: {
      if (response.Credentials?.FidoParams?.ShowInterstitial) {
        return buildGctResultActionSwitchView(ViewId.LoginPasskeyInterstitial);
      }

      // @TODO: Return redirect action instead of a switch view action to the Fido view (that does not really exist)
      return buildGctResultActionSwitchView(ViewId.Fido);
    }

    case CredentialType.Federation:
    case CredentialType.CloudFederation:
      return buildGctResultActionSwitchView(ViewId.IdpRedirect, idpRedirectViewParams);

    case CredentialType.LinkedIn:
    case CredentialType.GitHub:
    case CredentialType.Google:
    case CredentialType.Facebook:
      return buildGctResultActionSwitchView(
        getAvailableCreds(response, parseResponseParams, isFidoSupported, flags).length > 1 ||
          getEvictedCredentials(response, parseResponseParams, isFidoSupported, flags).length > 0
          ? ViewId.IdpRedirectSpeedbump
          : ViewId.IdpRedirect,
        idpRedirectViewParams,
      );

    case CredentialType.NoPreferredCredential:
      return buildGctResultActionSwitchView(ViewId.CredentialPicker, idpRedirectViewParams);

    case CredentialType.OtherMicrosoftIdpFederation:
      // @AAD-TODO: Use serverData.urlMsaSignUp instead of the signupUrl from LoginConfig
      return getSignupRedirectAction(
        cleansedUnsafeUsername,
        response,
        signupUrl,
        signupUrlPostParams,
      );

    case CredentialType.Password:
    default:
      return buildGctResultActionSwitchView(ViewId.Password);
  }
};

/**
 * Parses GCT response for needed information and determine the action (view, redirect, error)
 * @param otherIdpRedirectUrl url for other idp redirect
 * @param response Response from request
 * @param parseResponseParams Parameters for parsing GCT response
 * @param cleansedUnsafeUsername Username from request
 * @param isFidoSupported Whether or not FIDO is supported
 * @param isCachedResponse Whether or not the response is cached
 * @param fedQs The federation qs
 * @param flags GCT request helper flags
 * @param allowedIdentities indicates which IDPs are allowed
 * @param otherIdpRedirectPostParams optional post params for other idp redirect
 * @returns Parsed response
 */
export const parseGetCredentialTypeResponse = (
  otherIdpRedirectUrl: string,
  response: RawGctResponse,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  cleansedUnsafeUsername: string,
  isFidoSupported: boolean,
  isCachedResponse: boolean,
  fedQs: string,
  flags?: IGetCredentialTypeRequestHelperFlags,
  allowedIdentities?: number,
  otherIdpRedirectPostParams?: RedirectPostParams,
) => {
  const {
    changePasswordUrl = "",
    resetPasswordUrl = "",
    useResetPasswordUrlForPasswordRequiredError = false,
  } = parseResponseParams;

  let gctResult: Partial<GctResult> = {};
  // @AAD-TODO: Build out the logic for `desktopSsoEnabled` when we tackle ESTS
  const desktopSsoEnabled = false;
  const errorHr = response.ErrorHR;
  const isOtherIdpSupported =
    !flags?.checkCurrentIdpOnly && allowedIdentities === AllowedIdentitiesType.Both;

  const sharedData = transformCredentialResponse(
    response,
    parseResponseParams,
    isFidoSupported,
    isCachedResponse,
    flags,
  );
  const fedRedirectParams = getFederationRedirectParams(
    cleansedUnsafeUsername,
    response,
    isFidoSupported,
    fedQs,
  );

  sharedData.username = cleansedUnsafeUsername;

  const { gctStrings } = gctStringsConfig.instance;
  const { fixPhoneDisambigSignupRedirect } = FeaturesConfig.instance;

  if (
    errorHr === Error.PP_E_INVALID_PHONENUMBER ||
    errorHr === Error.PP_E_LIBPHONENUMBERINTEROP_NUMBERPARSE_EXCEPTION
  ) {
    gctResult = getInvalidPhoneNumberGctResult(errorHr, parseResponseParams, flags);
  } else if (errorHr === Error.PP_E_NAME_INVALID || errorHr === Error.PP_E_INVALIDARG) {
    gctResult = buildGctResultActionShowError(gctStrings.emptyOrInvalidEmail);
  } else if (errorHr === Error.PP_E_FEDERATION_INLINELOGIN_DISALLOWED) {
    gctResult = buildGctResultActionShowError(
      gctStrings.federationNotAllowed,
      true /* isBlockingError */,
    );
  } else if (errorHr === Error.PP_E_LOGIN_NOPA_USER_PASSWORD_REQUIRED) {
    const errorString = gctStrings.noPaUserNeedsPassword;

    if (useResetPasswordUrlForPasswordRequiredError) {
      gctResult = buildGctResultActionShowError(
        replaceTokens(errorString, stripQueryStringAndFragment(resetPasswordUrl)),
      );
    } else {
      gctResult = buildGctResultActionShowError(
        replaceTokens(errorString, stripQueryStringAndFragment(changePasswordUrl)),
      );
    }
  } else if (response.RequiresPhoneDisambiguation) {
    gctResult = buildGctResultActionSwitchView(ViewId.PhoneDisambiguation);
  } else if (response.AliasDisabledForLogin) {
    return buildGctResultActionShowError(gctStrings.disabledAlias, true /* isBlockingError */);
  } else if (response.IfExistsResult === IfExistsResult.NotExist) {
    gctResult = getUsernameNotFoundGctResult(
      response,
      parseResponseParams,
      cleansedUnsafeUsername,
      flags,
    );
  } else if (response.IfExistsResult === IfExistsResult.ExistsBothIDPs) {
    gctResult = buildGctResultActionSwitchView(ViewId.IdpDisambiguation, {
      desktopSsoEnabled,
      ...fedRedirectParams,
    });
  } else if (
    response.IfExistsResult === IfExistsResult.ExistsInOtherMicrosoftIDP &&
    otherIdpRedirectUrl.length > 0
  ) {
    gctResult = getOtherIdpRedirectGctResult(
      otherIdpRedirectUrl,
      cleansedUnsafeUsername,
      otherIdpRedirectPostParams,
    );
  } else if (
    isOtherIdpSupported &&
    (response.IfExistsResult === IfExistsResult.Error ||
      response.IfExistsResult === IfExistsResult.Throttled ||
      response.ThrottleStatus === ThrottleStatus.MsaThrottled)
  ) {
    // TODO: We default to Password below when the other IDP is not supported and GCT hits an error or throttling. We
    // need to revisit this as the number of passwordless accounts increases.
    gctResult = buildGctResultActionSwitchView(ViewId.IdpDisambiguation, {
      hasIdpDisambigError: true,
      desktopSsoEnabled,
      ...fedRedirectParams,
    });
  } else if (response.ShowRemoteConnect) {
    gctResult = buildGctResultActionSwitchView(ViewId.RemoteLoginPolling);
  } else {
    const isProofConfirmationRequired = !!(
      sharedData.otcCredential && sharedData.otcCredential.proof.clearDigits
    );
    gctResult = getUsernameFoundGctResult(
      response,
      parseResponseParams,
      cleansedUnsafeUsername,
      isFidoSupported,
      fedRedirectParams,
      isProofConfirmationRequired,
      flags,
    );
  }

  gctResult.flowToken = response.FlowToken || null;

  if (!gctResult.bypassCache) {
    // Don't cache the response if the username is a phone number
    // to ensure we only cache fully qualified phone numbers.
    if (
      (fixPhoneDisambigSignupRedirect &&
        !flags?.isPhoneNumberFullyQualified &&
        isPhoneNumber(cleansedUnsafeUsername)) ||
      !fixPhoneDisambigSignupRedirect
    ) {
      const storedResponse = response;
      // make sure we don't cache the flow token so we don't return a stale one in the future
      delete storedResponse.FlowToken;
      cache.set(cleansedUnsafeUsername, storedResponse);
    }
  }

  gctResult.sharedData = sharedData;

  // Retrieve the Emerald City recover URL from the response and store it in the shared data object.
  gctResult.acmaProperties = response.AcmaProperties;

  return gctResult;
};

/**
 * Function that handles error from GCT endpoint
 * @param error The error thrown from postJSON, which might have a responseBody
 * @returns Error object that specifies error
 */
export const handleGetCredentialTypeError = (error: ResponseError): Partial<GctResult> => {
  let gctResult: Partial<GctResult> = {};
  const { responseBody } = error;

  const { gctStrings } = gctStringsConfig.instance;

  gctResult = buildGctResultActionShowError(gctStrings.genericGctFailure);

  gctResult.flowToken = (responseBody?.FlowToken as string) || null;

  return gctResult;
};

/**
 * Sends a request to GCT endpoint and returns response
 * @param requestParams Parameters needed to build request
 * @param flags The GCT request helper flags
 * @returns A promise that either resolves to the parsed response body or rejects with an error.
 */
export const callGetCredentialTypeAsync = async (
  requestParams: IGetCredentialTypeRequestParams,
  flags?: IGetCredentialTypeRequestHelperFlags,
) => {
  const { getCredentialTypeUrl } = requestParams;
  const postData = buildGetCredentialTypeRequest(requestParams, flags);
  const options = { body: JSON.stringify(postData) };
  return postApiRequest<RawGctResponse>(getCredentialTypeUrl, options);
};

/**
 * Main function that will call the GCT endpoint, parse the response, return view/error/redirect, and check for cached responses
 * @param requestParams Parameters needed to build request
 * @param parseResponseParams Parameters for parsing GCT response
 * @param flags The GCT request helper flags
 * @returns Parsed response that will determine view, redirect, error
 */
export const sendAsync = async (
  requestParams: IGetCredentialTypeRequestParams,
  parseResponseParams: IParseGetCredentialTypeResponseParams,
  flags?: IGetCredentialTypeRequestHelperFlags,
): Promise<Partial<GctResult>> => {
  const { allowedIdentities, fedQs, isFidoSupported, otherIdpRedirectUrl, unsafeUsername } =
    requestParams;

  const cleansedUnsafeUsername = cleanseUsername(
    unsafeUsername,
    true /* preserve leading plus sign */,
  );
  const cachedResponse = checkCache(cleansedUnsafeUsername);
  const isCachedResponse = !!cachedResponse;
  const gctPromise = cachedResponse
    ? Promise.resolve(cachedResponse)
    : callGetCredentialTypeAsync(requestParams, flags);

  return gctPromise
    .then((results) => {
      const response = results;
      return parseGetCredentialTypeResponse(
        otherIdpRedirectUrl,
        response,
        parseResponseParams,
        cleansedUnsafeUsername,
        isFidoSupported,
        isCachedResponse,
        fedQs,
        flags,
        allowedIdentities,
      );
    })
    .catch((err) => handleGetCredentialTypeError(err));
};
