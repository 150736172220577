import React from "react";
import { type AccessibleImageProps, AccessibleImage } from "../../components/accessible-image";
import { TitleFabric } from "../../components/title/fabric/title-fabric";
import { type UserFlowType, Flavors, ViewId } from "../../constants";
import FeaturesConfig from "../../features-config";
import GlobalConfig from "../../global-config";
import { isEmailAddress, isPhoneNumber, isSkypeName } from "../../model/alias";
import { type UserCredential, CredentialType } from "../../model/credential";
import {
  type GctResult,
  GctResultAction,
} from "../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { getFidoSupport, isPlatformAuthenticatorAvailable } from "../../utilities/browser-helper";
import { logCxhEvent } from "../../utilities/cxh-handlers/cxh-handler";
import {
  appendOrReplaceQueryStringParams,
  replaceTokens,
  trim,
} from "../../utilities/strings-helper";
import LoginConfig from "./login-config";
import { Error, LoginMode, LoginState, LoginStringsVariant } from "./login-constants";
import {
  type CommonLoginErrorStrings,
  type CommonLoginStrings,
  type LoginInputValidationErrorStrings,
} from "./login-interface";

export type LoginFlowRedirectParams = Partial<
  Pick<GctResult, "redirectUrl" | "redirectPostParams" | "isIdpRedirect">
>;

export type LoginGetServerErrorTextProps = {
  strings: LoginInputValidationErrorStrings;
  errorCode: string;
};

/**
 * @returns Whether the loginMode is a ForceSignin mode or postedForceSignIn
 */
export const isForceSignIn = () => {
  const { loginMode, postedForceSignIn } = LoginConfig.instance;
  return (
    loginMode === LoginMode.ForceSignin ||
    loginMode === LoginMode.ForceSigninMobile ||
    loginMode === LoginMode.ForceSigninHost ||
    postedForceSignIn
  );
};

/**
 * Gets the common title for the login flow (used in username, password and many more views)
 * @param loginMode - current login mode supplied by the server.
 * @param friendlyAppName app name
 * @param commonLoginStrings Common login strings that are used to construct the title
 * @returns title
 */
export const getCommonDocumentTitle = (
  loginMode: number,
  friendlyAppName: string,
  commonLoginStrings: CommonLoginStrings,
): string => {
  let title = "";

  const { switchAccountsPageTitle, signInPageTitle, signInToAppTokenized } = commonLoginStrings;
  const signInToApp = replaceTokens(signInToAppTokenized, friendlyAppName);

  switch (loginMode) {
    case LoginMode.SwitchUser:
    case LoginMode.SwitchUserMobile:
    case LoginMode.SwitchUserHost:
    case LoginMode.InviteBlocked:
    case LoginMode.ServiceBlocked:
    case LoginMode.IDPFailed:
    case LoginMode.BindFailed:
      title = switchAccountsPageTitle;
      break;
    default:
      title = signInPageTitle;
      break;
  }

  if (friendlyAppName) {
    title = signInToApp;
  }

  return title;
};

/**
 * Gets the common lightbox title for the login flow (used in username, password and many more views)
 * @param stringVariantId identifier for the type of login is in progress
 * @param defaultSignInHeader the default, non-branded sign-in header supplied by the server
 * @param appBrandedSignInHeader the app-branded sign-in header supplied by the server
 * @returns the common lightbox title
 */
export const getCommonLightboxTitle = (
  stringVariantId: LoginStringsVariant,
  defaultSignInHeader: string,
  appBrandedSignInHeader: string,
): string => {
  let lightboxTitle = defaultSignInHeader;

  if (
    appBrandedSignInHeader &&
    ![LoginStringsVariant.SkypeMoveAlias, LoginStringsVariant.RemoteConnectLogin].includes(
      stringVariantId,
    )
  ) {
    lightboxTitle = appBrandedSignInHeader;
  }

  return lightboxTitle;
};

/**
 * Gets the common subtitle for the login flow (used in username, password and a few more views)
 * @param friendlyAppName app name
 * @param breakBrandingSigninString determines which subtitle string to use
 * @param commonLoginStrings Common login strings that are used to construct the subtitle
 * @returns subtitle
 */
export const getCommonSubtitle = (
  friendlyAppName: string,
  breakBrandingSigninString: boolean,
  commonLoginStrings: CommonLoginStrings,
): string => {
  const { toContinueToApp, continueToApp } = commonLoginStrings;
  const appTitleString = breakBrandingSigninString ? toContinueToApp : continueToApp;
  return friendlyAppName ? replaceTokens(appTitleString, friendlyAppName) : "";
};

/**
 * Gets the common description for the login flow based on the variant ID (used in username, password and a few more views)
 * @param stringVariantId identifier for the type of login is in progress
 * @param signInDescription existing sign in description
 * @param commonLoginStrings Common login strings that are used to construct the description
 * @returns final view description
 */
export const getCommonDescription = (
  stringVariantId: LoginStringsVariant,
  signInDescription: string,
  commonLoginStrings: CommonLoginStrings,
) => {
  const { loginEnterPasswordDescription, loginMinecraftRemoteConnectUserNamePageDesc } =
    commonLoginStrings;
  const { remoteConnectAppName } = LoginConfig.instance;

  let description: string | JSX.Element = "";

  switch (stringVariantId) {
    case LoginStringsVariant.SkypeMoveAlias:
      description = loginEnterPasswordDescription;
      break;

    case LoginStringsVariant.RemoteConnectLogin:
      description = replaceTokens(
        loginMinecraftRemoteConnectUserNamePageDesc,
        remoteConnectAppName,
      );
      break;

    default:
      break;
  }

  if (
    signInDescription &&
    ![LoginStringsVariant.SkypeMoveAlias, LoginStringsVariant.RemoteConnectLogin].includes(
      stringVariantId,
    )
  ) {
    description = signInDescription;
  }

  return description;
};

/**
 * Gets available credentials based on server data configurations
 * @param isFidoSupported flag indicating whether FIDO is supported
 * @returns available credentials
 */
export const getAvailableCredsWithoutUsername = (isFidoSupported: boolean) => {
  let availableCredsWithoutUsername: UserCredential[] = [];
  const {
    promotedFedCredType,
    linkedInFedUrl,
    githubFedUrl,
    googleFedUrl,
    facebookFedUrl,
    showOfflineAccount,
    showOnlyGithubOnCredPicker,
  } = LoginConfig.instance;

  availableCredsWithoutUsername = availableCredsWithoutUsername.concat(
    isFidoSupported ? [{ credentialType: CredentialType.Fido }] : [],

    linkedInFedUrl && promotedFedCredType !== CredentialType.LinkedIn
      ? [{ credentialType: CredentialType.LinkedIn, redirectUrl: linkedInFedUrl }]
      : [],

    githubFedUrl && promotedFedCredType !== CredentialType.GitHub
      ? [
          {
            credentialType: CredentialType.GitHub,
            redirectUrl: githubFedUrl,
            shownOnlyOnPicker: showOnlyGithubOnCredPicker,
          },
        ]
      : [],

    googleFedUrl ? [{ credentialType: CredentialType.Google, redirectUrl: googleFedUrl }] : [],

    facebookFedUrl
      ? [{ credentialType: CredentialType.Facebook, redirectUrl: facebookFedUrl }]
      : [],

    showOfflineAccount ? [{ credentialType: CredentialType.OfflineAccount }] : [],
  );

  return availableCredsWithoutUsername;
};

/**
 * Gets fido texts
 * @param commonLoginStrings common login strings
 * @param activeFlavor flavor param used for logging
 * @param isUserKnown whether or not the username is known
 * @returns texts to be displayed for fido
 */
export const getFidoTexts = async (
  commonLoginStrings: CommonLoginStrings,
  activeFlavor: Flavors,
  isUserKnown: boolean = false,
) => {
  // config data
  const {
    showFidoInline,
    showForgotUsernameLink,
    showOfflineAccount,
    isFidoSupportedHint,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  const activeFlavorWin11OobeFabric = activeFlavor === Flavors.Win11OobeFabric;
  // fido related properties
  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  if (activeFlavorWin11OobeFabric) {
    logCxhEvent("Identity.Fido.Supported", isFidoSupported);
  }

  const credentials = getAvailableCredsWithoutUsername(isFidoSupported);
  const showFidoLink =
    showFidoInline &&
    isFidoSupported &&
    (credentials.length >= 2 || showForgotUsernameLink || showOfflineAccount);

  const {
    loginCredPickerOptionFido,
    loginCredPickerOptionFidoKnownUser,
    loginCredPickerOptionFidoNoHello,
    loginCredPickerOptionFidoNoHelloKnownUser,
    fidoHelpWithSecurityKey,
    fidoHelpWithWindowsHelloAndSecurityKey,
    fidoHelpDescWithWindowsHelloAndSecurityKey,
    fidoHelpDescWithSecurityKey,
  } = commonLoginStrings;

  const fidoWithSecurityKey = isUserKnown
    ? loginCredPickerOptionFidoNoHelloKnownUser
    : loginCredPickerOptionFidoNoHello;
  const fidoWithWindowsHelloAndSecurityKey = isUserKnown
    ? loginCredPickerOptionFidoKnownUser
    : loginCredPickerOptionFido;

  let fidoLinkText = "";
  let fidoHelpText = "";
  let fidoDialogText = "";

  if (showFidoLink) {
    const platformAuthenticatorAvailable = await isPlatformAuthenticatorAvailable(
      useWebviewFidoCustomProtocol,
    );
    if (activeFlavorWin11OobeFabric) {
      logCxhEvent("Identity.Fido.PlatformAuthenticatorAvailable", platformAuthenticatorAvailable);
    }

    if (platformAuthenticatorAvailable) {
      fidoLinkText = fidoWithWindowsHelloAndSecurityKey;
      fidoHelpText = fidoHelpWithWindowsHelloAndSecurityKey;
      fidoDialogText = fidoHelpDescWithWindowsHelloAndSecurityKey;
    } else {
      fidoLinkText = fidoWithSecurityKey;
      fidoHelpText = fidoHelpWithSecurityKey;
      fidoDialogText = fidoHelpDescWithSecurityKey;
    }
  }

  return [fidoLinkText, fidoHelpText, fidoDialogText];
};

/**
 * Create help dialog header
 * @param image image for header
 * @param headerText text for header
 * @param imageStyles styles for the image
 * @returns a help dialog header
 */
export const createHelpDialogHeader = (
  image: AccessibleImageProps,
  headerText: string,
  imageStyles?: string,
) => (
  <>
    <AccessibleImage {...image} className={imageStyles} />
    <TitleFabric title={headerText} titleId="dialogHeader" />
  </>
);

/**
 * Create help dialog description
 * @param firstDesc first line in dialog
 * @param secondDesc second line in dialog
 * @param styles styling
 * @returns a help dialog header
 */
export const createHelpDialogDescription = (
  firstDesc: string,
  secondDesc: string | JSX.Element,
  styles?: string,
) => (
  <>
    <div className={styles}>{firstDesc}</div>
    <div className={styles}>{secondDesc}</div>
  </>
);

/**
 * Given an object containing post parameters, sanitizes the keys in the object by
 * removing "unsafe_" prefixes from the keys.
 * @param postParams object containing post parameters
 * @returns copy of the input object with the keys sanitized
 */
export const sanitizePostParams = (postParams: Record<string, string>): Record<string, string> => {
  const sanitizedPostParams: Record<string, string> = {};

  Object.entries(postParams).forEach(([name, value]) => {
    // The parameter name might start with the "unsafe_" prefix which indicates that the value
    // comes from untrusted user input. We'll remove the prefix here since we will be writing the
    // value to a hidden input element attribute, which is safe.
    const safeName = name.substring(0, 7) === "unsafe_" ? name.substring(7) : name;

    sanitizedPostParams[safeName] = value;
  });

  return sanitizedPostParams;
};

/**
 * Note that the view ID returned by this map is used by the initial view picker in login flow to map to
 * the actual View component. If you are adding an entry to this map, make sure that the ViewIdToViewMap
 * map in the initial view picker component contains a corresponding entry where appropriate.
 */
export const LoginStateToViewIdMap = {
  [LoginState.Username]: ViewId.Username,
  [LoginState.Password]: ViewId.Password,
  [LoginState.OneTimeCode]: ViewId.OneTimeCode,
  [LoginState.RemoteNGC]: ViewId.PushNotifications,
  [LoginState.PhoneDisambiguation]: ViewId.PhoneDisambiguation,
  [LoginState.IdpDisambiguation]: ViewId.IdpDisambiguation,
  [LoginState.IdpRedirect]: ViewId.IdpRedirect,
  [LoginState.ViewAgreement]: ViewId.ViewAgreement,
  [LoginState.Tiles]: ViewId.AccountPicker,
  [LoginState.ConfirmSend]: ViewId.ConfirmSend,
  [LoginState.CredentialPicker]: ViewId.CredentialPicker,
  [LoginState.Error]: ViewId.Error,
  [LoginState.IdpRedirectSpeedbump]: ViewId.IdpRedirectSpeedbump,
  [LoginState.ProofConfirmation]: ViewId.ProofConfirmation,
  [LoginState.FetchSessionsProgress]: ViewId.FetchSessionsProgress,
  [LoginState.Benefits]: ViewId.Benefits,
};

/**
 * Get the (react) view id from the (KO) login state
 * @param loginState the login state
 * @returns the view id
 */
export function getViewIdFromLoginState(loginState: number) {
  return LoginStateToViewIdMap[loginState] || ViewId.None;
}

/**
 * This method is used to check if the highest priority errors for MSA are present.
 * Highest priority simply refers to the order of execution and means these errors should be checked first, before any other errors.
 * @param props LoginGetServerErrorTextProps
 * @returns the error string if a P0 error is present, otherwise null
 */
export const checkMsaErrorsP0 = function checkMsaErrorsP0(
  props: LoginGetServerErrorTextProps,
): string | null {
  const { fedPartnerName } = LoginConfig.instance;
  const {
    strings: {
      aliasAuthCollisionError,
      bindingExistsSamsungError,
      fedNotAllowedError,
      fedNotAllowedErrorWithPartner,
      fidoPasskeyError,
      incorrectOneTimeCodeError,
      invalidEmailError,
      missingPasswordError,
      ngcAuthFailedError,
      notOverSslError,
      ottInvalidPurposeError,
      realmNotFederatedError,
      reservedUsernameError,
      sessionExpiredError,
      tooManyRequestsError,
      uniqueIdExistsSamsungError,
    },
    errorCode,
  } = props;

  switch (errorCode) {
    case Error.PP_E_MISSING_PASSWORD:
      return missingPasswordError;

    case Error.PP_E_NOT_EMAIL_INPUT:
      return invalidEmailError;

    case Error.PP_E_RESERVED_USERNAME:
      return reservedUsernameError;

    case Error.PP_E_REALM_NOT_FEDERATED:
      return realmNotFederatedError;

    case Error.PP_E_FEDERATION_WLIDLOGIN_DISALLOWED: // Note: This error code has extra conditionals we want in the future to enforce besides just this error code
    case Error.PP_E_ALIAS_AUTH_NOTPERMITTED:
      return replaceTokens(fedNotAllowedErrorWithPartner, fedPartnerName);

    case Error.PP_E_ALIAS_AUTH_COLLISION:
      return aliasAuthCollisionError;

    case Error.PP_E_OTT_DATA_INVALID:
    case Error.PP_E_OTT_ALREADY_CONSUMED:
    case Error.PP_E_PPSA_RPT_NOTOADDRESS:
      return incorrectOneTimeCodeError;

    case Error.PP_E_OTT_INVALID_PURPOSE:
      return ottInvalidPurposeError;

    case Error.PP_E_SRVCRYPT_EXPIRED_BLOB:
      return sessionExpiredError;

    case Error.PP_E_FEDERATION_INLINELOGIN_DISALLOWED:
      return fedNotAllowedError;

    case Error.PP_E_NGC_LOGIN_NONCE_EXPIRED:
    case Error.PP_E_NGC_LOGIN_KEY_NOT_FOUND:
    case Error.PP_E_NGC_KEY_DISABLED:
      return ngcAuthFailedError;

    case Error.PP_E_TOOMANYREQUESTS:
      return tooManyRequestsError;

    case Error.PP_E_NOT_OVER_SSL:
      return notOverSslError;

    case Error.PP_E_IDP_UNIQUEID_EXISTS_SAMSUNG:
      return uniqueIdExistsSamsungError;

    case Error.PP_E_IDP_BINDING_EXISTS_SAMSUNG:
      return bindingExistsSamsungError;

    case Error.PP_E_PASSKEY_AUTH_INTERRUPTED:
      return fidoPasskeyError;

    default:
      return null;
  }
};

/**
 * This method is used to check if the second highest priority errors for MSA are present.
 * Second highest priority simply refers to the order of execution and means these errors should be checked after P0 errors.
 * @param props LoginGetServerErrorTextProps
 * @returns the error string if a P1 error is present, otherwise null
 */
export const checkMsaErrorsP1 = function checkMsaErrorsP1(props: LoginGetServerErrorTextProps) {
  const { fedUrl, fedPartnerName } = LoginConfig.instance;
  const {
    strings: {
      fedNotAllowedError,
      fedNotAllowedErrorWithPartner,
      memberDoesNotExistError,
      oldSkypePasswordError,
      wrongPasswordError,
    },
    errorCode,
  } = props;

  // Note: Fed Login Disallowed Error has a different message for Mobile/Host
  if (Error.PP_E_FEDERATION_WLIDLOGIN_DISALLOWED === errorCode) {
    return fedNotAllowedError;
  }

  // If the user is in a federated namespace, show a different error.
  if (fedUrl) {
    return replaceTokens(fedNotAllowedErrorWithPartner, fedPartnerName);
  }

  if (Error.PP_E_DB_MEMBERDOESNOTEXIST === errorCode) {
    return memberDoesNotExistError;
  }

  if (Error.PP_E_BAD_PASSWORD === errorCode) {
    return wrongPasswordError;
  }

  if (Error.PP_E_OLD_SKYPE_PASSWORD === errorCode) {
    return oldSkypePasswordError;
  }

  return null;
};

/**
 * This method is used to determine the correct text to show for a certain error.
 * The IDPs return strings that do not work for embedded links in the React framework, so we need to convert them to the correct strings.
 * Currently this is specific to MSA errors, but should be expanded for AAD errors too.
 * @param props LoginGetServerErrorTextProps
 * @returns the error string to show
 */
export const getTextFromErrorCode = function getTextFromErrorCode(
  props: LoginGetServerErrorTextProps,
): string {
  let errorText = checkMsaErrorsP0(props);
  if (errorText !== null) {
    return errorText;
  }

  errorText = checkMsaErrorsP1(props);
  if (errorText !== null) {
    return errorText;
  }

  return props.strings.accountPasswordIncorrectError;
};

/**
 * This method is used to determine the error string to use when a Server Validation Error is present.
 * @param commonLoginStrings the common login strings
 * @param validationError the first entry in serverValidationErrors from LoginConfig
 * @returns the error string to use for the first validation error, or null if no error is present
 */
export const handleValidationError = function handleValidationError(
  commonLoginStrings: CommonLoginErrorStrings,
  validationError: string,
) {
  const { lockUsername } = LoginConfig.instance;

  // AAD-TODO: add ESTS errors
  switch (validationError) {
    case Error.EmptyFields.toString():
    case Error.UsernameInvalid.toString():
    case Error.PP_E_MISSING_MEMBERNAME:
    case Error.PP_E_NAME_INVALID:
    case Error.PP_E_EMAIL_RIGHT_TOO_LONG:
    case Error.PP_E_NAME_TOO_LONG:
    case Error.PP_E_INVALID_PHONENUMBER:
    case Error.PP_E_LIBPHONENUMBERINTEROP_NUMBERPARSE_EXCEPTION:
      return commonLoginStrings.invalidMemberName;
    case Error.PP_E_EXCLUDED:
    case Error.PP_E_BAD_PASSWORD:
    case Error.PP_E_PREVIOUS_PASSWORD:
    case Error.PP_E_INVALID_MEMBERNAME:
    case Error.PP_E_DB_MEMBERDOESNOTEXIST:
    case Error.PP_E_PE_RULEFALSE:
      return lockUsername
        ? commonLoginStrings.wrongPasswordLockedUserError
        : commonLoginStrings.wrongPasswordError;
    case Error.PP_E_OLD_SKYPE_PASSWORD:
      return commonLoginStrings.oldSkypePasswordError;
    case Error.PP_E_ALIAS_AUTH_NOTPERMITTED:
      return commonLoginStrings.aliasDisabledError;
    case Error.PP_E_FEDERATION_INLINELOGIN_DISALLOWED:
      return commonLoginStrings.fedNotAllowedError;
    case Error.PasswordEmpty.toString():
    case Error.PP_E_MISSING_PASSWORD:
      return commonLoginStrings.missingPasswordError;
    case Error.PP_E_IDP_LINKEDIN_BINDING_NOT_ALLOWED:
      return commonLoginStrings.linkedInFedUserNotFoundError;
    case Error.PP_E_IDP_GOOGLE_BINDING_NOT_ALLOWED:
      return commonLoginStrings.googleFedUserNotFoundError;
    case Error.PP_E_IDP_GITHUB_BINDING_NOT_ALLOWED:
      return commonLoginStrings.githubFedUserNotFoundError;
    case Error.PP_E_OTT_DATA_INVALID:
    case Error.PP_E_OTT_ALREADY_CONSUMED:
    case Error.PP_E_OTT_INVALID_PURPOSE:
    case Error.PP_E_PPSA_RPT_NOTOADDRESS:
      return commonLoginStrings.incorrectOneTimeCodeError;
    default:
      return null;
  }
};

/**
 * Get the server send error text from serverData
 * @param props LoginGetServerErrorTextProps
 * @returns the server error text
 */
export const getServerErrorText = function getServerErrorText(
  props: LoginGetServerErrorTextProps,
): string | null {
  const { credentialTypeResult, serverErrorText, serverValidationErrors } = LoginConfig.instance;
  const { strings, errorCode } = props;

  if (serverErrorText) {
    // AAD-TODO: Move ESTS strings from Server to front-end
    // The strings from the back-end won't have the correct formatting for embedded links, so we need to replace them using the error code
    if (errorCode) {
      return getTextFromErrorCode(props);
    }

    return serverErrorText;
  }

  if (serverValidationErrors && serverValidationErrors.length > 0) {
    return handleValidationError(strings, serverValidationErrors[0]);
  }

  if (credentialTypeResult) {
    const gctResult = credentialTypeResult;
    if (gctResult.action === GctResultAction.ShowError) {
      return gctResult.error || null;
    }
  }

  return null;
};

/**
 * Get whether server sent error text from serverData
 * @returns true or false depending on if server error is present
 */
export function hasServerError() {
  const { serverErrorText, serverValidationErrors } = LoginConfig.instance;

  return serverErrorText || (serverValidationErrors && serverValidationErrors.length > 0);
}

/**
 * Function to determine the initial view from the posted state like postedLoginState, postedUsername, etc.
 * Note: LoginMode.FetchSessionsProgress only applies to AAD
 * @param currentViewId the current view ID
 * @returns initial view id, or params needed to do a login redirect
 */
export const getNextActionFromPostedState = (currentViewId?: ViewId) => {
  const { loginMode, postedLoginState, postedUsername, credentialTypeResult } =
    LoginConfig.instance;

  let initialViewId = ViewId.None;
  let doLoginFlowRedirect = false;
  let loginFlowRedirectParams: LoginFlowRedirectParams = {};

  if (
    postedLoginState !== LoginState.Unknown &&
    postedLoginState !== LoginState.FetchSessionsProgress
  ) {
    initialViewId = getViewIdFromLoginState(postedLoginState);
  } else if (credentialTypeResult && Object.keys(credentialTypeResult).length !== 0) {
    const gctResult = credentialTypeResult;

    switch (gctResult.action) {
      case GctResultAction.ShowError:
        if (loginMode === LoginMode.FetchSessionsProgress) {
          initialViewId = ViewId.FetchSessionsProgress;
        } else {
          // display error on view
          initialViewId = currentViewId || initialViewId;
        }

        break;

      case GctResultAction.SwitchView:
        if (loginMode === LoginMode.FetchSessionsProgress) {
          initialViewId = ViewId.FetchSessionsProgress;
        } else {
          // display correct view returned from gct
          initialViewId = gctResult.viewId ? gctResult.viewId : initialViewId;
        }

        break;

      case GctResultAction.Redirect:
        if (loginMode !== LoginMode.FetchSessionsProgress) {
          doLoginFlowRedirect = true;
          loginFlowRedirectParams = {
            redirectUrl: gctResult.redirectUrl,
            redirectPostParams: gctResult.redirectPostParams,
            isIdpRedirect: gctResult.isIdpRedirect,
          };
        }

        break;

      default:
    }
  }
  // Otherwise, if there is a posted username and has server error, show the error username view
  else if (postedUsername && hasServerError() !== null) {
    initialViewId = ViewId.Username;
  }

  return {
    initialViewId,
    doLoginFlowRedirect,
    loginFlowRedirectParams,
  };
};

/**
 * Function to determine if we should add the username to the initial entries on the MemoryRouter for the Login app.
 * This will help to add it to the 'history' stack and allow us to show the back arrow or button
 * even without having shown the username view.
 * This gives the user an 'out' in case if they wanted to change the username on the post request and the error was on another view.
 * @returns true if we should add the username to the initial entries, otherwise false
 */
export const shouldAddUserNameToInitialEntries = () => {
  const { lockUsername, prefillUsername, ztdUpnHint } = LoginConfig.instance;

  const isForceSignin = isForceSignIn();

  const nextAction = getNextActionFromPostedState();

  // Username should be locked when we have a prefill from the query string, when we are in
  // force signin, when the password is locked out, or when the view has explicitly requested
  // the username to be locked. In this case, the current view will also be the initial view,
  // which will prevent the user from navigating back from the current view.
  if (
    nextAction.initialViewId !== ViewId.None &&
    nextAction.initialViewId !== ViewId.Username &&
    !(prefillUsername || isForceSignin || lockUsername || ztdUpnHint)
  ) {
    return true;
  }

  return false;
};

/**
 * @param isInitialView Whether the view is the first view rendered to a user
 * @returns Whether the "change user link" should be shown
 */
export const getShowChangeUserLink = (isInitialView: boolean) => {
  const { lockUsername, showSwitchUser } = LoginConfig.instance;
  const { switchUserUrl } = GlobalConfig.instance;

  return showSwitchUser && ((lockUsername && !!switchUserUrl) || isInitialView);
};

/**
 * @param displayName The unsafe displayName for a user to determine if the disambig link should be shown
 * @returns Indicator that the Phone Disambiguation link should be shown
 */
export const getShowPhoneDisambigLink = (displayName: string) => {
  const { lockUsername } = LoginConfig.instance;

  return (
    !lockUsername &&
    !isEmailAddress(displayName) &&
    !isSkypeName(displayName) &&
    isPhoneNumber(displayName)
  );
};

/**
 * This method is used to add the "mn" (member name) parameter for the reset password link
 * It only adds the parameter if the value is
 * @param rootUrl The URL to update
 * @param queryParam The query parameter to update
 * @param queryParamValue The query param value to add to the URL
 * @returns the updated url
 */
export const updateMemberNamePrefill = (
  rootUrl: string,
  queryParam: string,
  queryParamValue: string,
) => {
  if (queryParamValue) {
    return appendOrReplaceQueryStringParams(rootUrl, {
      [queryParam]: encodeURIComponent(trim(queryParamValue)),
    });
  }

  return rootUrl;
};

/**
 * Gets postUrl for parent sign in
 * @param postUrl - current postUrl supplied by the server
 * @param parentUsername - encoded username for the parent signing in
 * @param userFlowType - current user flow type
 * @returns parentSigninPostUrl
 */
export const getParentSignInPostUrl = (
  postUrl: string,
  parentUsername: string,
  userFlowType: UserFlowType,
) => {
  const { isGamingFlow, isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  let parentSignInPostUrl = postUrl;

  // Persist QS params for simplified child account creation and gaming flow
  // Update QS params for parent username and interrupt suppression
  parentSignInPostUrl = appendOrReplaceQueryStringParams(parentSignInPostUrl, {
    scac: isSimplifiedChildAccountCreation ? "1" : "0",
    gf: isGamingFlow ? "1" : "0",
    uft: userFlowType.toString(),
    pusername: parentUsername,
    scacsi: "1",
  });

  return parentSignInPostUrl;
};
