import { type ChangeEvent, useRef, useState } from "react";
import { useEffectOnce } from "../../../hooks/use-effect-once";
import { type InputValue } from "../input/input-types";

export type InputErrorState = {
  // Server-side error message. This might come from ServerData, a failed API request, etc
  serverError: string | JSX.Element;
  // A callback to set the submit error message.
  setServerError: (error: string | JSX.Element) => void;
  // Client-side validation error message. This is generally set to the value returned from the `validationMethod` callback
  validationError: string;
  // A callback to set the validation error message.
  setValidationError: (error: string) => void;
  // An indicator that an error should be shown in the UX.
  showErrorMessage: boolean;
  // A callback to set the `showError` indicator.
  setShowErrorMessage: (show: boolean) => void;
  // The value of the error message to display. If both errors are present, the submit error will be returned.
  errorMessage: string | JSX.Element;
  // An indicator that there is an error to display. This is true if either error is present.
  hasError: boolean;
};

export const useErrorState = (initialServerError: string | JSX.Element = ""): InputErrorState => {
  const hasInitialServerError = !!initialServerError;
  const [serverError, setServerError] = useState(initialServerError);
  const [validationError, setValidationError] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(hasInitialServerError);

  const errorMessage = serverError || validationError;
  const hasError = !!errorMessage;

  return {
    serverError,
    setServerError,
    validationError,
    setValidationError,
    showErrorMessage,
    setShowErrorMessage,
    errorMessage,
    hasError,
  };
};

export const useFocusState = (hasInitialFocus = false) => {
  const [hasFocus, setFocus] = useState(hasInitialFocus);

  const onBlur = () => setFocus(false);
  const onFocus = () => setFocus(true);

  return {
    hasFocus,
    setFocus,
    onBlur,
    onFocus,
  };
};

export const useValueState = (initialValue: InputValue = "") => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
  };
};

export type InputStateOptions = {
  initialServerError?: JSX.Element | string;
  hasInitialFocus?: boolean;
  initialValue?: InputValue;
  clearServerErrorOnChange?: boolean;
  immediatelyShowValidationErrorsAfterSubmit?: boolean;
  /** An optional callback that happens when a user changes either input. Happens before validation. */
  onChangeCallback?: (value: InputValue) => void;
  validationMethod?: (value: InputValue) => string;
  useElementRef?: boolean;
};

/**
 * This hook is meant to be used to create the state required for an Input component.
 * An `onChange` callback is returned as well, which can be used with the Input component to update the state based on user input.
 * @param options Options for the Input UX behavior
 * - `clearServerErrorOnChange`: A boolean flag to clear the server error when the user changes the input value. Defaults to true.
 * - `hasInitialFocus`: An initial focus state for the Input. Defaults to false.
 * - `initialServerError`: An initial server error. A truthy value will default `showError` to true. Defaults to an empty string.
 * - `initialValue`: An initial value for the Input. Defaults to an empty string.
 * - `immediatelyShowValidationErrorsAfterSubmit` A boolean flag to show validation errors immediately after the user submits the
 *      form the first time. Defaults to true.
 * - `onChangeCallback`: An optional callback that happens when a user changes the input. Happens before validation.
 * - `validationMethod`: A callback function to validate the input value.
 * - `useElementRef`: A boolean flag that enables an input ref to the return object, used to manage focus instead of the focus state.
 *      Defaults to false. Note that `useInputFluent` sets this to true.
 *   If provided, this method is run once with the initial value and again whenever the input value changes.
 *   When executed, the validation error will be updated to the string returned from this callback.
 * @returns Input state and an `onChange` callback
 */
export const useInput = (options: InputStateOptions) => {
  const {
    initialServerError = "",
    hasInitialFocus = false,
    initialValue = "",
    clearServerErrorOnChange = true,
    immediatelyShowValidationErrorsAfterSubmit = true,
    validationMethod,
    useElementRef = false,
    onChangeCallback,
  } = options;

  const errorState = useErrorState(initialServerError);
  const focusState = useFocusState(hasInitialFocus);
  const valueState = useValueState(initialValue);
  const [userHasSubmitted, setUserHasSubmitted] = useState(false);
  const elementRef = useRef<HTMLInputElement>(null);

  const validateText = (value: InputValue) => {
    if (validationMethod) {
      const validationError = validationMethod(value);
      errorState.setValidationError(validationError);
      if (userHasSubmitted && !!validationError) {
        errorState.setShowErrorMessage(immediatelyShowValidationErrorsAfterSubmit);
      }
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement> | string) => {
    // When the user enters text, we'll clear any provided errors from the IDP/parent
    const value = typeof event === "string" ? event : event.target.value;
    valueState.setValue(value);

    if (clearServerErrorOnChange && errorState.serverError) {
      errorState.setServerError("");
      errorState.setShowErrorMessage(false);
    }

    if (onChangeCallback) {
      onChangeCallback(value);
    }

    validateText(value);

    return value;
  };

  useEffectOnce(() => {
    validateText(initialValue);
  });

  return {
    error: errorState,
    ...focusState,
    ...valueState,
    onChange,
    userHasSubmitted,
    setUserHasSubmitted,
    elementRef: useElementRef ? elementRef : undefined,
  };
};

export type InputState = ReturnType<typeof useInput>;
