import { useContext } from "react";
import { AuthenticationContext } from "../../../../../authentication-context";
import { ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { cleanseUsername } from "../../../../../model/user";
import { sendAsync } from "../../../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import { useHandleGctResponse } from "../../../hooks/use-handle-gct-response";
import LoginConfig from "../../../login-config";
import { LoginContext } from "../../../login-context";
/**
 * @returns function for the GCT request, called when user signs out, goes back to account picker view, and tries to sign in with the same tile again
 */
export const useGctRequest = () => {
  const {
    authState: { flowTokenValue: flowToken },
  } = useContext(AuthenticationContext);

  const { resetPasswordUrl } = GlobalConfig.instance;

  const {
    allowedIdentities,
    isFidoSupportedHint,
    gctFederationFlags,
    isFederationDisabled,
    isExternalFederationDisallowed,
    isRemoteNGCSupported,
    isOtcLoginDisabled: otclogindisallowed,
    getCredentialTypeUrl,
    postProofType,
    showSignup,
    signupUrl,
    fedQs,
    changePasswordUrl,
    improvePhoneDisambiguation,
    signupUrlPostParams,
    useResetPasswordUrlForPasswordRequiredError,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  const {
    viewState: { otherIdpRedirectUrl },
  } = useContext(LoginContext);

  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);

  const handleGctResponse = useHandleGctResponse();

  return async (unsafeUsername: string, errorHandler: (error: string | JSX.Element) => void) => {
    const gctResult = await sendAsync(
      {
        allowedIdentities,
        unsafeUsername,
        flowToken,
        isFidoSupported,
        country: "",
        gctFederationFlags: gctFederationFlags || 0,
        isExternalFederationDisallowed,
        isFederationDisabled,
        isRemoteNGCSupported,
        otclogindisallowed,
        getCredentialTypeUrl,
        otherIdpRedirectUrl,
        fedQs,
      },
      {
        changePasswordUrl,
        improvePhoneDisambiguation,
        postProofType,
        resetPasswordUrl,
        showSignup,
        signupUrl,
        signupUrlPostParams,
        useResetPasswordUrlForPasswordRequiredError,
      },
      { checkCurrentIdpOnly: true },
    );

    handleGctResponse(
      ViewId.AccountPicker,
      gctResult,
      cleanseUsername(unsafeUsername),
      unsafeUsername,
      errorHandler,
    );
  };
};
