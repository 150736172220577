import React from "react";
import { replaceTokens } from "../../../utilities/strings-helper";
import { type TileFabricComponent, TileFabric } from "../../tile/fabric/tile-fabric";
import { type ITileListProps } from "../tile-list-interface";

export type TileListFabricProps = ITileListProps<TileFabricComponent>;

export const findIndexToInitialFocus = (tileList: TileFabricComponent[]): number => {
  const hasInitialFocusIndex = tileList.findIndex((tileProp) => tileProp.hasInitialFocus);

  return hasInitialFocusIndex === -1 ? 0 : hasInitialFocusIndex;
};

/**
 * Tile List component for fabric
 * @param props TileListFabricProps properties for this component
 * @returns A list of tiles
 */
export const TileListFabric: React.FC<TileListFabricProps> = function TileListFabric(props) {
  const { tileList, ariaLabelledBy, ariaDescribedBy } = props;

  const indexToInitialFocus = findIndexToInitialFocus(tileList);

  const tileListItems = tileList.map((tileProp, index) => (
    <TileFabric
      key={
        tileProp.mainTextReplaceValue
          ? replaceTokens(tileProp.mainText, tileProp.mainTextReplaceValue)
          : tileProp.mainText
      }
      mainText={tileProp.mainText}
      helpText={tileProp.helpText}
      subText={tileProp.subText}
      image={tileProp.image}
      onClick={tileProp.onClick}
      hasInitialFocus={index === indexToInitialFocus}
      ariaDescribedBy={index === 0 ? ariaDescribedBy : undefined}
      ariaLabel={tileProp.ariaLabel}
      helpDialogIcon={tileProp.helpDialogIcon}
      helpDialogHeader={tileProp.helpDialogHeader}
      helpDialogDescription={tileProp.helpDialogDescription}
      mainTextReplaceValue={tileProp.mainTextReplaceValue}
      optionsMenuButton={tileProp.optionsMenuButton}
      dataTestId={tileProp.dataTestId}
      isTilesUnderLightbox={tileProp.isTilesUnderLightbox}
    />
  ));

  return (
    <div role="list" id="tileList" aria-labelledby={ariaLabelledBy} data-testid="tileList">
      {tileListItems}
    </div>
  );
};
