import { useGlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";
import { useRedirect } from "./use-redirect";

/**
 * Hook to show a progress indicator and then initiating an external redirect
 * @returns hook to show a progress indicator while doing a redirect
 */
export const useShowProgressAndRedirect = () => {
  const onRedirect = useRedirect();
  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();

  return (url: string) => {
    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: true,
    });
    onRedirect(url);
  };
};
