import { useContext } from "react";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { type SystemActionName } from "../../../telemetry-helpers/system-action-name";
import { useTelemetry } from "../../../telemetry-helpers/use-telemetry";
import { type OtcFormApiTelemetryProps } from "./get-one-time-code-form";

/**
 * hook for generating a callback for logging otc telemetries
 * @returns telemetry callback for logging the request/response data
 */
export const useOtcTelemetry = () => {
  const { activeFlavor } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow },
  } = useContext(GlobalContext);

  const { logUserAction } = useTelemetry(GlobalConfig.instance.telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  return (actionName: SystemActionName, telemetryProps: OtcFormApiTelemetryProps) => {
    logUserAction({
      actionName,
      actionValue: telemetryProps,
    });
  };
};
