import { type CxhContext, type CXHType } from "./cxh-handler-types";

/**
 * Imports the CXH js file. The purpose of this function is so that we can load CXH asynchorounsly.
 * Instead of calling CXH on intial load, we only call this function/import CXH in scenarios
 * where it is needed
 * @returns CXH
 */
export const getCxh = async function getCxh() {
  // @ts-ignore
  const { CXH } = await import(/* webpackChunkName: "cxh" */ "../../js/cxh/cxh");
  return CXH as CXHType;
};

/**
 * This method is meant to be provided by for the SSOConsentView for Win10Host on MSA, which we are calling WindowsFabric.
 * It will prevent the form submission and instead call the CXH API to cancel the login flow.
 */
export const cancelLogin = async () => {
  const CXH = await getCxh();
  CXH.setAuthErrorAndComplete(CXH.ClientHRs.UserCanceled, CXH.ServerErrors.LoginCanceled);
};

/**
 * This method clears the userpassword from the property bag from previous requests
 */
export const handleClearPassword = async () => {
  const CXH = await getCxh();

  CXH.propertyBagSetAsync(CXH.PropertyBagItemNames.EncryptedPassword, "");
};

/**
 * Encrypts the provided password and sets encrypted value in the public property bag
 * @param password password to encrypt
 * @param onSuccessHandler on success handler
 */
export const handleOnPasswordUpdate = async (password: string, onSuccessHandler: () => void) => {
  const CXH = await getCxh();
  CXH.encryptUserDataAsync(password, (valueEncrypted: string) => {
    CXH.propertyBagSetAsync(
      CXH.PropertyBagItemNames.EncryptedPassword,
      valueEncrypted,
      onSuccessHandler,
    );
  });
};

/**
 * Function that determines back button visibility and functionality
 * @param isOobe isOobe from server data
 * @param postForceSignIn postForceSignIn from server data
 * @param lockUsername lockUsername from server data
 * @param isOverTheShoulder isOverTheShoulder from server data
 * @param onSuccessHandler on success handler
 */
export const isBackButtonVisible = async (
  isOobe: boolean,
  postForceSignIn: boolean,
  lockUsername: boolean,
  isOverTheShoulder: boolean,
  onSuccessHandler: (isBackButtonVisible: boolean) => void,
) => {
  const CXH = await getCxh();

  if (isOobe && !postForceSignIn && !lockUsername) {
    CXH.publicPropertyBagGetAsync(
      CXH.PropertyBagItemNames.WasDisambiguationPageShown,
      (propertyValue) => {
        onSuccessHandler(!isOverTheShoulder && !!propertyValue);
      },
    );
  } else {
    onSuccessHandler(false);
  }
};

/**
 * Handles back button event either through a provided back event or through CXH
 * @param onBackButton Handler for back button
 */
export const handleBackButtonEvent = async (
  onBackButton?: (event: React.SyntheticEvent<HTMLElement>) => void,
) => {
  const CXH = await getCxh();

  if (onBackButton) {
    CXH.showBackButton(true);
    CXH.handleBackButton(onBackButton);
  } else {
    CXH.showBackButton(false);
  }
};

/**
 * Give CXH a hint that we are now navigating to a third party website, so that it can handle the back button sanely.
 */
export const setBackNavigationCheckpointBeforeRedirect = async () => {
  const CXH = await getCxh();

  CXH.setBackNavigationCheckpoint();
};

/**
 * Show the given Lottie file for Win11 OOBE.
 * @param graphicAnimationSource Lottie file to be shown alongside the webapp in OOBE
 */
export const showGraphicAnimationFromSource = async (graphicAnimationSource: string) => {
  const CXH = await getCxh();
  CXH.showGraphicAnimation(graphicAnimationSource);
};

/**
 * Sets a value in the volatile property bag using the provided name asynchronously.
 */
export const setOobeMsaConsentStringDisplayed = async () => {
  const CXH = await getCxh();

  CXH.volatilePropertyBagSetAsync(
    "Identity",
    CXH.PropertyBagItemNames.WasMsaConsentDisplayed,
    "true",
  );
};

/**
 * Checks CXH capability details from context
 * @param capability capability type
 * @param resultHandler handler function
 */
export const hasCapability = async (
  capability: string,
  resultHandler: (result: boolean) => void,
) => {
  const CXH = await getCxh();

  if (resultHandler) {
    CXH.getContext((context: CxhContext) => {
      // If context.capabilities is a string, parsing into an object must have failed earlier,
      // so assume capability does not exist
      resultHandler(
        typeof context.capabilities !== "string" &&
          (context.capabilities as Record<string, unknown>)[capability] === 1,
      );
    });
  }
};

/**
 * Checks if back button is supported on initial view
 * @param isScoobe isScoobe from server data
 * @param offlineAccountVisible offlineAccountVisible from server data
 * @param isOobe isOobe from server data
 * @param postForceSignIn postForceSignIn from server data
 * @param lockUsername lockUsername from server data
 * @param isOverTheShoulder isOverTheShoulder from server data
 * @param onSuccessHandler on success handler
 */
export const isBackButtonSupportedOnInitialView = async (
  isScoobe: boolean,
  offlineAccountVisible: boolean,
  isOobe: boolean,
  postForceSignIn: boolean,
  lockUsername: boolean,
  isOverTheShoulder: boolean,
  onSuccessHandler: (isSupported: boolean, useCancelText?: boolean) => void,
) => {
  const CXH = await getCxh();

  if (isScoobe) {
    onSuccessHandler(true, true);
  } else if (!offlineAccountVisible) {
    onSuccessHandler(true, false);
  } else if (isOobe && !postForceSignIn && !lockUsername && !isOverTheShoulder) {
    CXH.publicPropertyBagGetAsync(
      CXH.PropertyBagItemNames.WasDisambiguationPageShown,
      (propertyValue) => {
        onSuccessHandler(!!propertyValue, false);
      },
    );
  } else {
    hasCapability(CXH.Capabilities.ChromelessUI, (result: boolean) => {
      onSuccessHandler(!!result, !!result);
    });
  }
};

/**
 * handleOnSkip for CXH when user clicks offline account or a skip link.
 * @param isOobe isOobe from server data that checks if it's an OOBE environment
 * @param noLossAversion noLossAversion from server data
 * @param preferAssociate preferAssociate from server data
 */
export const handleOnSkip = async (
  isOobe: boolean,
  noLossAversion: boolean,
  preferAssociate: boolean,
) => {
  const CXH = await getCxh();
  // Oobe scenario:
  // For MSA, the CXH abortID node points to OobeMSAOffer (Loss Aversion flow) which ultimately transfers to the local account flow, so we use CXH.finish.
  // For AAD, the CXH abortID node points to OobeIDPS (Disambiguation page), hence we have to continue using CXH.loadIdentityProvider.
  if (isOobe) {
    CXH.logEvent("Identity.OfflineAccount.Clicked");

    if (!noLossAversion) {
      CXH.finish(CXH.FinishStates.Abort);
    } else {
      CXH.loadIdentityProvider(CXH.SignInIdentityProviders.Local);
    }
    // AAD-TODO: ADD ESTS properties & condition- isMobileWindows && urlLocalAccountSetup
  } else {
    const hostEventName = preferAssociate
      ? "MSA.AssociateUpsell.SkipClicked"
      : "MSA.ConnectUpsell.SkipClicked";
    CXH.logEvent(hostEventName);

    CXH.loadIdentityProvider(CXH.SignInIdentityProviders.Local);
  }
};

/**
 * Logs events with specified event name or data so that we can track them in Event Viewer
 * @param eventName name of event
 * @param data data associated with event
 */
export const logCxhEvent = async (eventName: string, data?: string | number | boolean) => {
  const CXH = await getCxh();

  CXH.logEvent(eventName, data);
};
