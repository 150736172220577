import React from "react";
import StylesConfig from "../../../config/styles-config";
import { mergeClasses } from "../../../utilities/merge-classes";
import type { IPageTitleFabricProps } from "../page-title-interface";

/**
 * Page Title Fabric component
 * @param props IPageTitleFabricProps properties for this component
 * @returns An instance of this component
 */
export const PageTitleFabric: React.FC<IPageTitleFabricProps> = function PageTitleFabric(props) {
  const {
    ariaLevel = 1,
    pageDescription,
    pageTitle,
    pageTitleId,
    role = "heading",
    customStyles,
  } = props;

  const { useTitleStyles } = StylesConfig.instance;
  const titleStyles = useTitleStyles();

  return (
    <div id={pageTitleId} aria-level={ariaLevel} role={role}>
      {pageTitle && (
        <div aria-label={pageTitle} className={titleStyles.pageTitle}>
          <h1>{pageTitle}</h1>
        </div>
      )}

      {pageDescription && (
        <div className={mergeClasses(titleStyles.pageDescription, customStyles?.pageDescription)}>
          {pageDescription}
        </div>
      )}
    </div>
  );
};
