import {
  type HipChallengeNativeMenuItem,
  type HipChallengeNativeStrings,
} from "../../components/hip-challenge-native";
import { type ResponseBody, type ResponseError, HipType } from "../../constants";
import { type NativeApiError } from "../../utilities/error";
import { type BindingMethodType, AuthMethodChallengeType } from "../auth-method/auth-method-types";

/**
 * Enum representing the types of fraud protection challenges.
 * This enum can be more specific like 'jpeg', 'png', 'mp4' or more generic like 'visual', 'audio'.
 */
export enum FraudProtectionChallengeType {
  visual = AuthMethodChallengeType.visual,
  audio = AuthMethodChallengeType.audio,
  none = AuthMethodChallengeType.none,
}

/**
 * Represents the type of binding method for fraud protection and hip type.
 */
export const FraudProtectionChallengeTypeToHipTypeMapping: { [key: string]: HipType } = {
  visual: HipType.NativeVisual,
  audio: HipType.NativeAudio,
};

/**
 * Enum representing the types of fraud protection challenge artifacts.
 * This represents the content type for the challenge
 * Values : Audio/mp3, Image/Jpeg etc.
 */
export const enum FraudProtectionChallengeArtifactType {
  imageJPEG = "image/jpeg",
  audioMP3 = "audio/mp3",
}

/**
 * Representing the parameters for a fraud protection challenge request.
 */
export type FraudProtectionChallengeRequestParams = {
  challenge_method: BindingMethodType;
  challenge_types: Array<FraudProtectionChallengeType>;
  challenge_type_requested: FraudProtectionChallengeType;
  continuation_token: string;
};

/**
 * Representing the parameters for a fraud protection challenge response.
 */
export type FraudProtectionChallengeResponseParams = ResponseBody & {
  /** This represents the content of the challenge that needs to be completed by the end user */
  challenge_artifact: string;
  /** The method used to challenge the user */
  challenge_method: BindingMethodType;
  /** This represents the list of content types for the challenge that the client supports. */
  challenge_type: FraudProtectionChallengeType;
  /** This represents the content type for the challenge Values : Audio/mp3, Image/Jpeg etc. */
  challenge_artifact_type?: FraudProtectionChallengeArtifactType;
  /** The state artifact returned to the client for it to use in subsequent requests. */
  continuation_token: string;
};

/**
 * Representing the parameters for a fraud protection challenge failure.
 */
export type FraudProtectionChallengeFailureParams = ResponseError &
  NativeApiError & {
    responseBody?: FraudProtectionChallengeResponseParams;
  };

/**
 * Representing the parameters for a fraud protection continue request.
 */
export type FraudProtectionContinueRequestParams = {
  challenge_input: string;
  continuation_token: string;
};

/**
 * Representing the parameters for a fraud protection continue response.
 */
export type FraudProtectionContinueResponseParams = ResponseBody & {
  /** The state artifact returned to the client for it to use in subsequent requests. */
  continuation_token: string;
};

/**
 * Representing the parameters for a fraud protection continue failure.
 */
export type FraudProtectionContinueFailureParams = ResponseError &
  NativeApiError & {
    responseBody?: FraudProtectionContinueResponseParams;
  };

/**
 * Representing the parameters for a fraud protection transform response.
 */
export type FraudProtectionTransformResponseParams = ResponseBody & {
  success: boolean;
  flowtoken: string;
};

/**
 * Representing the parameters for a fraud protection transform failure.
 */
export type FraudProtectionTransformFailureParams = ResponseError &
  NativeApiError & {
    responseBody?: FraudProtectionTransformResponseParams;
  };

/**
 * Represents the show native hip hook params.
 */
export type FraudProtectionShowNativeHipParams = {
  request: FraudProtectionChallengeRequestParams;
  errorHandler: (error: string | JSX.Element) => void;
  onChallengeSuccess?: (successResponse: FraudProtectionChallengeResponseParams) => void;
  onChallengeFailure?: (failureResponse: FraudProtectionChallengeFailureParams) => void;
};

/**
 * Represents the parameters for a single hip menu item
 */
export type FraudProtectionMenuItemParams = Omit<HipChallengeNativeMenuItem, "onClick"> &
  Omit<FraudProtectionShowNativeHipParams, "request"> & {
    request: FraudProtectionPayload;
    onShowCaptcha: (params: FraudProtectionShowNativeHipParams) => void;
  };

/**
 * Represents the parameters for the hip menu items
 */
export type FraudProtectionMenuItemsParams = Pick<
  FraudProtectionMenuItemParams,
  "request" | "errorHandler" | "onShowCaptcha"
> & {
  /** Strings needed for the native hip menu items. */
  strings: HipChallengeNativeStrings;
  /** Clears the server error after a successful action, such as 'Refresh' or 'Use Audio'. */
  clearNativeHipServerErrorOnSuccess: () => void;
};

/**
 * Representing the payload for a hip challenge.
 */
export type FraudProtectionPayload = {
  /** This represents the content of the challenge that needs to be completed by the end user */
  challengeArtifact: string;
  /** The method used to challenge the user */
  challengeMethod: BindingMethodType;
  /** This represents the list of content types for the challenge that the client supports. */
  challengeType: FraudProtectionChallengeType;
  /** This represents the content type for the challenge Values : Audio, Visual, Audio/mp3, Image/Jpeg etc. */
  challengeTypes: Array<FraudProtectionChallengeType>;
  /** The state artifact returned to the client for it to use in subsequent requests. */
  continuationToken: string;
  /** Indicates to skip the captcha when going back from a view, which mean the captcha has already been solved. */
  skipCaptchaOnBack?: boolean;
};
