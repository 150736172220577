import type React from "react";
import { useContext } from "react";
import { AuthenticationContext } from "../../../../../authentication-context";
import { AuthenticationActionType } from "../../../../../authentication-reducer";
import { ApiNames } from "../../../../../constants";
import { ViewId } from "../../../../../constants/routing-constants";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { type OneTimeCodeCredential } from "../../../../../model/credential";
import { ProofType } from "../../../../../model/proof";
import { isChallengeViewSupported } from "../../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcChannel,
  OtcPurposes,
  OtcStatus,
} from "../../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { useOtcTelemetry } from "../../../../../utilities/api-helpers/one-time-code/use-one-time-code-telemetry";
import { isCredentialEmailProofType } from "../../../../../utilities/credential-helper";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";
import { useTriggerChallenge } from "../../../../../views/challenge/hooks/use-trigger-challenge";
import { type IProofConfirmationStrings } from "../proof-confirmation-interface";
import { getInvalidProofError } from "../proof-confirmation-view-util";

/**
 * @param otcCredential the current one time code credential
 * @param setIsRequestPending dispatch function to set the `isRequestPending` state variable
 * @param strings strings for the Proof Confirmation view
 * @returns a function that returns the input params required to make a one time code request
 */
export const useGetOneTimeCodeParams = (
  otcCredential: OneTimeCodeCredential,
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>,
  strings: IProofConfirmationStrings,
) => {
  const { activeFlavor } = GlobalConfig.instance;

  const {
    globalState: {
      user: { username },
    },
  } = useContext(GlobalContext);

  const {
    authState: { flowTokenValue: initialFlowToken },
    dispatchStateChange: dispatchAuthStateChange,
  } = useContext(AuthenticationContext);

  const {
    proof: { clearDigits = "", data: proofData, isNopa, type: proofType },
  } = otcCredential;
  const isEmailProof = isCredentialEmailProofType(otcCredential);

  const navigate = useNavigateDirection();
  const telemetryCallback = useOtcTelemetry();
  const triggerChallenge = useTriggerChallenge();

  return (input: string, errorHandler: (error: string | JSX.Element) => void) => {
    const onSuccess = (successValues: OtcSuccessParams) => {
      const finalFlowToken =
        successValues.flowToken || successValues.response.FlowToken || initialFlowToken;
      dispatchAuthStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: finalFlowToken,
      });

      setIsRequestPending(false);

      navigate(ViewId.ProofConfirmation, getRouteFromViewId(ViewId.OneTimeCode));
    };

    const onFailure = (error: OtcFailureParams) => {
      let externalError: string;

      setIsRequestPending(false);

      const finalFlowToken = error.flowToken || initialFlowToken;
      dispatchAuthStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: finalFlowToken,
      });

      const errorNumericId = error.otcStatus;

      if (errorNumericId === OtcStatus.ftError) {
        externalError = strings.sessionExpiredError;
        errorHandler(externalError);
      } else if (errorNumericId === OtcStatus.inputError) {
        externalError = getInvalidProofError(isEmailProof, clearDigits, strings);
        errorHandler(externalError);
      } else if (
        errorNumericId === OtcStatus.challengeRequiredError ||
        errorNumericId === OtcStatus.userBlocked
      ) {
        triggerChallenge({ response: error, apiName: ApiNames.GetOtc });
      } else {
        externalError = strings.sendOtcFailed;
        errorHandler(externalError);
      }
    };

    const otcParams = {
      onSuccess,
      onFailure,
      channel: proofType === ProofType.Email ? OtcChannel.emailAddress : OtcChannel.mobileSms,
      flowToken: initialFlowToken,
      ...(input ? { proofConfirmation: input } : {}),
      proofData,
      proofType,
      purpose: isNopa ? OtcPurposes.noPassword : OtcPurposes.otcLogin,
      username,
      challengeViewSupported: isChallengeViewSupported(activeFlavor),
      telemetryCallback,
    };

    return otcParams;
  };
};
