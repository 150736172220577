import { type InputValue } from "../../../../components/inputs/input/input-types";
import {
  oneTimeCodeDefaultMaxLength,
  oneTimeCodeMaxLength,
} from "../../../../constants/onetimecode-constants";
import { type OneTimeCodeCredential, CredentialType } from "../../../../model/credential";
import { ProofType } from "../../../../model/proof";
import {
  getProofConfirmation,
  isProofEncrypted,
} from "../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import { replaceTokens } from "../../../../utilities/strings-helper";
import { type IOneTimeCodeViewStrings } from "./one-time-code-view-interface";

/**
 * @param proofType the One Time Code proof type
 * @param proofDisplay the safe display value for the proof
 * @param oneTimeCodeViewStrings Flavored strings for the One Time Code view
 * @returns description for the one time code view when the proof has been confirmed
 * ex. "We emailed a code to testuser@live.com..."
 */
export const codeSentDescription = (
  proofType: ProofType,
  proofDisplay: string,
  oneTimeCodeViewStrings: IOneTimeCodeViewStrings,
): string => {
  const { codeSentDescriptionEmail, codeSentDescriptionSms, codeSentDescriptionVoice } =
    oneTimeCodeViewStrings;
  let description = "";

  if (proofType === ProofType.Email) {
    description = codeSentDescriptionEmail;
  } else if (proofType === ProofType.SMS) {
    description = codeSentDescriptionSms;
  } else if (proofType === ProofType.Voice) {
    description = codeSentDescriptionVoice;
  }

  return replaceTokens(description, `${proofDisplay}\u200e`);
};

/**
 * @param proofType The one time code proof type
 * @param proofConfirmation confirmation of the proof value (safe to display)
 * @param oneTimeCodeViewStrings Flavored strings for the One Time Code view
 * @returns Description for the one time code view when the full proof has not been confirmed
 * ex. "If 1234 matches the last 4 digits of the phone number on your account..."
 */
export const codeSentIfMatchDescription = (
  proofType: ProofType,
  proofConfirmation: string,
  oneTimeCodeViewStrings: IOneTimeCodeViewStrings,
): string => {
  const {
    codeSentIfMatchDescriptionEmail,
    codeSentIfMatchDescriptionSms,
    codeSentIfMatchDescriptionVoice,
  } = oneTimeCodeViewStrings;
  let description = "";

  if (proofType === ProofType.Email) {
    description = codeSentIfMatchDescriptionEmail;
  } else if (proofType === ProofType.SMS) {
    description = codeSentIfMatchDescriptionSms;
  } else if (proofType === ProofType.Voice) {
    description = codeSentIfMatchDescriptionVoice;
  }

  // First arg is the proof, second arg is the number of digits (used only for Sms/Voice)
  const replaceArgs = [`${proofConfirmation}\u200e`, "4"];

  return replaceTokens(description, ...replaceArgs);
};

/**
 * @param otcCredential The current otc credential
 * @param proofConfirmation Confirmation of the proof value (safe to display)
 * @param unsafeDisplayName The unsafe display username
 * @param oneTimeCodeViewStrings Flavored strings for the One Time Code view
 * @returns The page description based on whether it is safe to display the entire proof or not.
 * If not safe, we encrypt the proof in the description (ex. de*****@live.com)
 */
export const unsafePageDescription = (
  otcCredential: OneTimeCodeCredential,
  proofConfirmation: string,
  unsafeDisplayName: string,
  oneTimeCodeViewStrings: IOneTimeCodeViewStrings,
) => {
  const otcProof = otcCredential.proof;
  const { enterCodeDescription } = oneTimeCodeViewStrings;
  let description = replaceTokens(enterCodeDescription, `${unsafeDisplayName}\u200e`);

  if (otcCredential.credentialType === CredentialType.OneTimeCode) {
    if (otcProof.clearDigits && !proofConfirmation) {
      description = replaceTokens(enterCodeDescription, `${otcProof.display}\u200e`);
    } else if (otcProof.clearDigits) {
      description = codeSentIfMatchDescription(
        otcProof.type,
        proofConfirmation,
        oneTimeCodeViewStrings,
      );
    } else {
      description = codeSentDescription(otcProof.type, otcProof.display, oneTimeCodeViewStrings);
    }
  }

  return description;
};

/**
 * @param isOtc Whether or not the credential has type OneTimeCode
 * @param oneTimeCodeViewStrings Flavored strings for the One Time Code view
 * @returns The current (client-side) validation error for one time code input
 */
export const otcInputValidation = (
  isOtc: boolean,
  oneTimeCodeViewStrings: IOneTimeCodeViewStrings,
) => {
  const { emptyCodeError, invalidCodeError } = oneTimeCodeViewStrings;

  return (rawValue: InputValue): string => {
    const value = String(rawValue);

    if (!value || value.trim() === "") {
      return emptyCodeError;
    }

    // For a regular one time code, check that it is all digits
    if (isOtc && !/^\d+$/.test(value)) {
      return invalidCodeError;
    }

    return "";
  };
};

/**
 * @param otcCredential The current one time code credential
 * @param proofConfirmation Confirmation of the proof value (safe to display)
 * @returns An object with the updated proof confirmation and otc max length
 * @summary Updates the otc max input length and proof confirmation values
 * based on the data in otcCredential
 */
export const processOtcCredential = (
  otcCredential: OneTimeCodeCredential,
  proofConfirmation: string,
) => {
  // AAD-TODO: add behavior for recovery scenario and isRequestSent flag
  let otcMaxLength = oneTimeCodeDefaultMaxLength;
  let updatedProofConfirmation = proofConfirmation;

  if (otcCredential.credentialType === CredentialType.OneTimeCode) {
    otcMaxLength = oneTimeCodeMaxLength;

    const otcProof = otcCredential.proof;

    if (otcProof.display && !otcProof.clearDigits && isProofEncrypted(otcCredential)) {
      updatedProofConfirmation = getProofConfirmation(otcCredential, otcProof.type) || "";
    }
  }

  return { otcMaxLength, updatedProofConfirmation };
};
