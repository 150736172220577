import React from "react";
import { LinkButton } from "../../../../../../components/link-button";
import { ViewId } from "../../../../../../constants";
import { useNavigateDirection } from "../../../../../../hooks";
import { FormattedTextWithBindings } from "../../../../../../utilities/formatted-text-with-bindings";
import { getConsentTypeAndMessage } from "../../inline-legal-messaging-util";
import { inlineLegalMessagingStringsFabric } from "../inline-legal-messaging-strings-fabric";

/**
 * @param sourceViewId The view hosting the consent message.
 * @returns The JSX element for the formatted consent message text with bindings.
 */
export const useConsentMessage = (sourceViewId: ViewId): JSX.Element | null => {
  const navigate = useNavigateDirection();
  const { consentLinkBindingTagName, consentLinkId, consentMessage } = getConsentTypeAndMessage(
    inlineLegalMessagingStringsFabric,
  );
  const consentLinkElement = (chunks: string[]) => (
    <LinkButton
      text={chunks[0]}
      onClick={() => navigate(sourceViewId, ViewId.SeeHowDataIsManaged)}
      linkId={consentLinkId}
    />
  );

  return consentMessage ? (
    <FormattedTextWithBindings
      text={consentMessage}
      embeddedBindings={{ [consentLinkBindingTagName]: consentLinkElement }}
    />
  ) : null;
};
