import { useCallback, useState } from "react";
import { useLoginContext } from "../../login-context";

/**
 * This hook determines whether to start polling or make the OneTimeCode api request and start polling. It is used on initial view load and when the user clicks the Next button when there is an error.
 * @param setTitle The method to set the title
 * @param setError The method to set the error
 * @param triggerOtcRequest The method to inovke the Get OneTimeCode request
 * @param startPolling The method to start the session polling
 * @returns A method that handles the polling and OTC api request for the remote ngc view.
 */
export const useSetupAndStartPolling = (
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  triggerOtcRequest: () => void,
  startPolling: () => void,
) => {
  // Login state data
  const {
    viewState: {
      remoteNgcParams: { requestSent },
    },
  } = useLoginContext();

  // During initialization, check if an OTC request needs to be made before polling.
  // On subsequent attempts (e.g., primary button is clicked), we skip this check and make the request before polling.
  const [isInitialRequest, setIsInitialRequest] = useState(true);
  const setupAndStartPolling = useCallback(() => {
    setTitle("");
    setError("");
    if (isInitialRequest) {
      if (!requestSent) {
        triggerOtcRequest();
      } else {
        startPolling();
      }

      setIsInitialRequest(false);
    } else {
      triggerOtcRequest();
    }
  }, [
    requestSent,
    startPolling,
    setTitle,
    setError,
    setIsInitialRequest,
    isInitialRequest,
    triggerOtcRequest,
  ]);

  return setupAndStartPolling;
};
