import { Flavors } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useEffectOnce } from "../../../../../hooks";
import { logCxhEvent } from "../../../../../utilities/cxh-handlers/cxh-handler";
import LoginConfig from "../../../login-config";

export const usePasswordLogEvents = () => {
  const { activeFlavor } = GlobalConfig.instance;

  const {
    hideOfflineAccountWithNoSkipString,
    logSkipToOfflineAccountAction,
    serverValidationErrors,
  } = LoginConfig.instance;

  useEffectOnce(() => {
    if (activeFlavor === Flavors.Win11OobeFabric) {
      logCxhEvent(
        "Identity.Flight.IsEnabledHideOfflineAccountWithNoSkipString",
        hideOfflineAccountWithNoSkipString,
      );

      if (logSkipToOfflineAccountAction) {
        // CXH telemetry required to evaluate, how many users are able to see these errors and they are able to skip, to go through local account
        const errorcode = serverValidationErrors.length > 0 ? serverValidationErrors[0] : ""; // To log the error code value, fetching the error code
        logCxhEvent("Identity.OOBE.Login.ErrorSkipToLocalVisible", `[ErrorCode] ${errorcode}`);
      }
    }
  });
};
