import { makeStyles, shorthands } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type BoilerPlateStylesFabric, useBackgroundStylesFabric } from "../fabric";

const useOverrideBoilerPlateStyles = makeStyles({
  boilerPlateText: {
    backgroundColor: "transparent",
    ...shorthands.margin(0),
    ...shorthands.padding(0),
  },
});

export type BoilerPlateStylesWin11OobeFabric = BoilerPlateStylesFabric &
  Partial<ReturnType<typeof useOverrideBoilerPlateStyles>>;

export const useBoilerPlateStylesWin11OobeFabric = () =>
  useMergedStyles(
    useBackgroundStylesFabric(),
    useOverrideBoilerPlateStyles(),
  ) as BoilerPlateStylesWin11OobeFabric;
