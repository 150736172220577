import LoginConfig from "../../../flows/login/login-config";
import GlobalConfig from "../../../global-config";
import { type UserOptions } from "../../../global-reducer";
import { cleanseUsername } from "../../../model/user";
import { htmlUnescape } from "../../../utilities/strings-helper";
import { setUntrustedExternalInputText } from "../../../utilities/untrusted-external-input-text";

/**
 * This method checks GlobalConfig and LoginConfig for the most appropriate username to use.
 * @returns the username to use for Login, safe and HTML escaped
 */
export function getUserOptions(): UserOptions {
  const { postUsername, signInUsername } = GlobalConfig.instance;
  const { prefillUsername, ztdUpnHint } = LoginConfig.instance;

  const unsafeUsername = htmlUnescape(
    postUsername.safeHtmlEscapedString || signInUsername || prefillUsername || ztdUpnHint,
  );

  return {
    username: setUntrustedExternalInputText(cleanseUsername(unsafeUsername), false),
    displayUsername: setUntrustedExternalInputText(unsafeUsername, false),
  };
}
