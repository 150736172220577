import { type MutableRefObject, useEffect } from "react";
import { ViewId } from "../constants";
import GlobalConfig from "../global-config";
import { type ServiceDiagEventNames } from "../telemetry-helpers/service-diag-event-names";
import { useTelemetry } from "../telemetry-helpers/use-telemetry";
import { useTelemetryState } from "../telemetry-helpers/use-telemetry-state";

export type HTMLResourceType = HTMLElement | HTMLIFrameElement | HTMLImageElement | null;

export const useLoadResourceTelemetry = (
  resourceRef: MutableRefObject<HTMLResourceType>,
  timestampRef: MutableRefObject<number | 0>,
  resourceMetricName: ServiceDiagEventNames,
) => {
  const { activeFlavor, telemetry } = GlobalConfig.instance;

  const currentResourceRef = resourceRef.current;

  const {
    dimensions: { view, flow },
  } = useTelemetryState();

  const { logServiceDiagEvent } = useTelemetry(telemetry, {
    activeView: view,
    activeFlow: flow,
    activeFlavor,
  });

  useEffect(() => {
    /** @internal */
    function logResourceLoadTime() {
      if (view !== ViewId.None) {
        const resourceLoadStartTimeStamp = timestampRef.current;
        const resourceLoadEndTimeStamp = new Date().getTime();
        const resourceLoadTime = resourceLoadEndTimeStamp - resourceLoadStartTimeStamp;

        logServiceDiagEvent({ metricName: resourceMetricName, metricValue: resourceLoadTime });
      }
    }

    if (document.readyState === "complete") {
      logResourceLoadTime();
    } else {
      currentResourceRef?.addEventListener("load", logResourceLoadTime);
    }

    return () => {
      currentResourceRef?.removeEventListener("load", logResourceLoadTime);
    };
  }, [
    telemetry,
    resourceRef,
    activeFlavor,
    timestampRef,
    resourceMetricName,
    view,
    logServiceDiagEvent,
    currentResourceRef,
  ]);
};
