import { extractDomain, isEmailAddress } from "../../../../../model/alias";
import { replaceTokens } from "../../../../../utilities/strings-helper";
import { type LoginStringsVariant, Error, LoginMode } from "../../../login-constants";
import { getCommonLightboxTitle } from "../../../login-util";
import { type IErrorViewStrings } from "../error-view-interface";

/**
 * Extracts the domain name from the input username and replaces the placeholder portion of the input string with the extracted domain name.
 * @param str - The string to format.
 * @param username - The username to grab the domain name from.
 * @returns The input string with the placeholder text replaced with the domain name from the username.
 */
const replaceFedDomain = (str: string, username: string): string =>
  username && isEmailAddress(username)
    ? replaceTokens(str, extractDomain(username, false, true))
    : str;

/**
 * Replaces the placeholder portion of the input string with the input federated partner name.
 * @param str - The string to format.
 * @param fedPartnerName - The federated partner name.
 * @returns The input string with the placeholder text replaced with the federated partner name.
 */
const replaceFedPartner = (str: string, fedPartnerName: string): string =>
  fedPartnerName ? replaceTokens(str, fedPartnerName) : str;

/**
 * Returns the appropriate lightbox title to display based on the login mode supplied by the server and the string variant ID.
 * @param errorViewStrings - Strings for the error view
 * @param isBindFailedMode - whether the login mode is LoginMode.BindFailed.
 * @param stringVariantId identifier for the type of login is in progress.
 * @param defaultSignInHeader - the default title to use as supplied by the server.
 * @param appBrandedSignInHeader - the app-branded title to use as supplied by the server.
 * @returns The corresponding lightbox title for the current login mode and string variant ID combination.
 * TODO @mjg-flavors: This function should be "unflavored", which means extracting static strings. The strings should be passed as a
 * parameter which implements a common IRemoteNgcStrings interface. That way, this function can be used for all flavors (each of which will
 * pass their own version of the strings that implements the interface)
 */
export const getLightboxTitle = (
  errorViewStrings: IErrorViewStrings,
  isBindFailedMode: boolean,
  stringVariantId: LoginStringsVariant,
  defaultSignInHeader: string,
  appBrandedSignInHeader: string,
): string => {
  if (isBindFailedMode) {
    return errorViewStrings.accountUnavailableTitle;
  }

  return getCommonLightboxTitle(stringVariantId, defaultSignInHeader, appBrandedSignInHeader);
};

/**
 * Returns the appropriate error description based on the input login mode.
 * @param errorViewStrings - Strings for the error view
 * @param loginMode - The current login mode supplied by the server.
 * @param username - The username used to grab the federated domain name.
 * @param fedPartnerName - The federated partner name.
 * @returns The corresponding error description for the current login mode.
 */
export const getLoginModeErrorDesc = (
  errorViewStrings: IErrorViewStrings,
  loginMode: number,
  username: string,
  fedPartnerName: string,
): string => {
  const showGenericIdpFailed = !username || !fedPartnerName;

  switch (loginMode) {
    case LoginMode.BindFailed:
      return "";
    case LoginMode.SwitchUser:
    case LoginMode.SwitchUserMobile:
    case LoginMode.SwitchUserHost:
      return errorViewStrings.alreadySignedInTitle;
    case LoginMode.InviteBlocked:
      return replaceFedDomain(errorViewStrings.inviteBlockedError, username);
    case LoginMode.ServiceBlocked:
      return replaceFedDomain(errorViewStrings.serviceBlockedError, username);
    case LoginMode.IDPFailed:
      return showGenericIdpFailed
        ? errorViewStrings.idpFailedGenericError
        : replaceFedPartner(errorViewStrings.idpFailedError, fedPartnerName);
    case LoginMode.HIP_Lockout:
    case LoginMode.HIP_LockoutMobile:
    case LoginMode.HIP_LockoutHost:
      return errorViewStrings.lockoutTitle;
    default:
      return errorViewStrings.genericErrorTitle;
  }
};

/**
 * Returns the appropriate error description to display based on the login mode and error code supplied by the server.
 * @param errorViewStrings - Strings for the error view
 * @param errorText - the error text to use as supplied by the server.
 * @param errorCode - the error code supplied by the server.
 * @returns The corresponding error description for the current login mode and error code.
 */
export const getErrorCodeDescription = (
  errorViewStrings: IErrorViewStrings,
  errorText: string,
  errorCode: string,
): string => {
  if (errorText && errorCode === Error.PP_E_IDP_BINDING_EXISTS_SAMSUNG) {
    // Return the new AXIS string instead of the server-supplied string so we can use the FormattedTextWithBindings component to
    // replace the embedded `manageCredsUrl` child with an anchor tag.
    return errorViewStrings.bindFailedAlreadyBoundDescSamsung;
  }

  // @TODO: Use serverData.sErrorCode and other (possibly new) server data properties to determine which error text to show instead of
  //        relying on the string supplied by the server.
  return errorText;
};

/**
 * Returns the appropriate text to display for the username div based on the login mode supplied by the server.
 * @param errorViewStrings - Strings for the error view
 * @param username - the username supplied by the server.
 * @param isHipLockedMode - whether the login mode is one of the HIP locked modes.
 * @returns The corresponding username div text for the current login mode.
 */
export const getUsernameDescription = (
  errorViewStrings: IErrorViewStrings,
  username: string,
  isHipLockedMode: boolean,
): string => (isHipLockedMode ? replaceTokens(errorViewStrings.lockoutDesc, username) : username);

/**
 * Returns the appropriate text to display for the switch user URL based on the login mode supplied by the server.
 * @param errorViewStrings - Strings for the error view
 * @param isHipLockedMode - whether the login mode is one of the HIP locked modes.
 * @param isSwitchUserMode - whether the login mode is one of the switch user modes.
 * @returns The corresponding switch user URL text for the current login mode.
 */
export const getSwitchUrlText = (
  errorViewStrings: IErrorViewStrings,
  isHipLockedMode: boolean,
  isSwitchUserMode: boolean,
): string => {
  if (isHipLockedMode) {
    return errorViewStrings.lockoutAnotherIdText;
  }

  if (isSwitchUserMode) {
    return errorViewStrings.switchUserSignOutThenSignIn;
  }

  return errorViewStrings.switchUserDifferentId;
};
