import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName } from "../../../constants";
import { useCustomizationContext } from "../../../context/customization-context";
import LoginConfig from "../../../flows/login/login-config";
import { type GlobalState, GlobalContext } from "../../../global-context";
import { isCssAnimationSupported } from "../../../utilities/browser-helper";
import { getUTCString } from "../../../utilities/datetime-helper";
import BackgroundLogo from "../../background-logo";
import BannerLogoFabric from "../../banner-logo/fabric/banner-logo-fabric";
import BoilerPlateText from "../../boilerplate-text";
import DebugDetailsBannerFabric from "../../debug-details/fabric/debug-details-banner-fabric";
import IdentityBannerFabric from "../../identity-banner/fabric/identity-banner-fabric";
import ProgressIndicatorFabric from "../../loading-progress/fabric/progress-indicator-fabric";
import { PageTitleFabric } from "../../page-title/fabric/page-title-fabric";
import PromotedCredsFabric from "../../promoted-creds/fabric/promoted-creds-fabric";
import { TileFabric } from "../../tile/fabric/tile-fabric";
import { getFlowBasedPageTitleStrings } from "./layout-content-util-fabric";

export type LayoutContentStylesFabric = {
  flexColumnClassName?: string;
  innerClassName: string;
  lightboxCoverClassName: string;
  outermostDivClassName?: string;
  paginationViewClassName: string;
  winScrollClassName?: string;
};

type LayoutContentPropsFabric = {
  customStyles?: Record<string, string>;
  styles: LayoutContentStylesFabric;
  showDebugInfo: boolean;
  setShowDebugInfo: (showDebugInfo: boolean) => void;
  focusOnDebugCopyLink: boolean;
  setFocusOnEllipsis: (focus: boolean) => void;
};

/**
 * This method provides the business logic to define the lightboxCover class name(s) and paginationView class name(s)
 * @param globalState - GlobalState
 * @param innerClassName - The class name(s) to give the "inner" element
 * @param showHeader - Style property that indicates if the header should be shown
 * @returns LayoutContent classnames
 */
export const useLayoutContentStylesFabric = function useLayoutContentStylesFabric(
  globalState: GlobalState,
  innerClassName: string,
  showHeader: boolean = false,
): LayoutContentStylesFabric {
  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const { allowGrayOutLightbox, showIdentityBanner, showProgressIndicator, user } = globalState;

  let lightboxCoverClassName = commonStyles.lightboxCover;
  if (showProgressIndicator && allowGrayOutLightbox) {
    lightboxCoverClassName = mergeClasses(lightboxCoverClassName, commonStyles.disableLightbox);
  }

  const paginationViewClassName =
    !!user?.displayUsername?.unsafeUnescapedString && showIdentityBanner
      ? commonStyles.paginationViewWithIdentityBanner
      : commonStyles.paginationView;

  const outermostDivClassName = mergeClasses(
    showHeader && commonStyles.headerMargin,
    commonStyles.fullHeight,
  );

  return {
    flexColumnClassName: commonStyles.flexColumn,
    innerClassName,
    lightboxCoverClassName,
    outermostDivClassName,
    paginationViewClassName,
    winScrollClassName: commonStyles.winScroll,
  };
};

/**
 * TODO: Move this component into a fabric directory
 * @param props - Component properties
 * @param props.styles - Component CSS classes to add to specific DOM elements
 * @param props.showDebugInfo - Indicator that the debug information should be shown
 * @param props.setShowDebugInfo - Callback to update the `showDebugInfo` state
 * @param props.focusOnDebugCopyLink - Indicator that focus should be added to the debug copy link
 * @param props.setFocusOnEllipsis - Callback to update `focusOnEllipsis` state
 * @param props.customStyles - Custom styles to apply to various parts of this component
 * @returns LayoutContent component containing the shared components/HTML between the LightboxLayout and VerticalSplitLayout components
 */
const LayoutContentFabric: React.FC<LayoutContentPropsFabric> = function LayoutContentFabric(
  props,
) {
  const {
    customStyles,
    focusOnDebugCopyLink,
    setFocusOnEllipsis,
    showDebugInfo,
    setShowDebugInfo,
    styles,
    children,
  } = props;

  const {
    globalState: {
      activeFlow,
      debugInfo,
      hideBannerLogo,
      showIdentityBanner,
      showProgressIndicator,
      showMoreOptions,
      user,
      showPageTitle,
    },
  } = useContext(GlobalContext);

  const { arrPromotedCreds } = LoginConfig.instance;

  const {
    customizationState: {
      styles: { backgroundColor, backgroundImageUrl, alwaysShowBackground },
    },
  } = useCustomizationContext();

  // @TODO: Deprecate this utility function and use the strings config pattern instead
  const { pageDescription, pageTitle } = getFlowBasedPageTitleStrings(activeFlow);

  const animationSupported = isCssAnimationSupported();
  // When animation is supported, this is set to false. It is updated to true when the lightbox fade-in animation is done.
  const [isLayoutReady, setIsLayoutReady] = useState(!animationSupported);

  let onAnimateLightboxEnd;
  // Add animation styles for lightbox/inner container when animation is supported
  if (animationSupported) {
    onAnimateLightboxEnd = () => {
      setIsLayoutReady(true);
    };
  }

  const displayIdBanner =
    !!user?.displayUsername?.unsafeUnescapedString && showIdentityBanner && isLayoutReady;
  const innerClassNames = mergeClasses(styles.innerClassName, ExternalClassName.signInBox);

  debugInfo.timeStamp = debugInfo.timeStamp ? debugInfo.timeStamp : getUTCString();

  return (
    <div className={styles.outermostDivClassName}>
      <div className={styles.flexColumnClassName}>
        <BackgroundLogo />
        {showPageTitle && (
          <PageTitleFabric
            pageDescription={pageDescription}
            pageTitle={pageTitle}
            pageTitleId="layoutContentPageTitle"
            customStyles={customStyles}
          />
        )}
        <div className={styles.winScrollClassName}>
          <div
            id="inner"
            data-testid="inner"
            className={innerClassNames}
            onAnimationEnd={onAnimateLightboxEnd}
          >
            <div id="lightbox-cover" className={styles.lightboxCoverClassName} />
            <div>
              {showProgressIndicator && <ProgressIndicatorFabric />}
              {!hideBannerLogo && <BannerLogoFabric />}
              {displayIdBanner && <IdentityBannerFabric />}
              {isLayoutReady && (
                <div
                  id="pageContent"
                  data-testid="paginationView"
                  className={styles.paginationViewClassName}
                >
                  {children}
                  <BoilerPlateText />
                </div>
              )}
            </div>
          </div>
          {showMoreOptions && (
            <TileFabric
              isMoreOptions
              // When a background color or image is rendered, apply custom styles to the sign-in options tile
              // to align it's width with the width of the lightbox / vertical split layout
              isAppBranded={!!(backgroundColor || backgroundImageUrl) && alwaysShowBackground}
              mainText={showMoreOptions.text}
              image={showMoreOptions.image}
              ariaLabel={showMoreOptions.text}
              onClick={showMoreOptions.onClick}
              debugInfoShown={showDebugInfo}
            />
          )}
          {arrPromotedCreds.length > 0 && <PromotedCredsFabric />}
        </div>
      </div>
      {showDebugInfo && (
        <DebugDetailsBannerFabric
          debugInfo={debugInfo}
          focusOnCopyLink={focusOnDebugCopyLink}
          onDebugCloseClick={() => {
            setFocusOnEllipsis(true);
            setShowDebugInfo(!showDebugInfo);
          }}
        />
      )}
    </div>
  );
};

export default LayoutContentFabric;
