import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { ExperimentFeature, TreatmentFlight } from "../../../config/experiments";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName, gamepadMainContentAttributes } from "../../../constants";
import { EnvironmentName } from "../../../constants/environmentbanner-constants";
import { useCustomizationContext } from "../../../context/customization-context";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { useExperiment } from "../../../hooks";
import { isCssAnimationSupported } from "../../../utilities/browser-helper";
import BackgroundFabric from "../../background/fabric/background-fabric";
import { DirectionWrapper } from "../../direction-wrapper";
import EnvironmentBannerFabric from "../../environment-banner/fabric/environment-banner-fabric";
import Footer from "../../footer/fabric/footer-fabric";
import FooterConfig from "../../footer/footer-config";
import GamepadNavigationWrapper from "../../gamepad-navigation-wrapper";
import Header from "../../header";
import LayoutContentFabric, { useLayoutContentStylesFabric } from "./layout-content-fabric";

type LightboxLayoutPropsFabric = {
  customStyles?: Record<string, string>;
  styles: {
    disableLightbox: string;
    inner: string;
    innerAlwaysShowBackground: string;
    innerWithAppBranding: string;
    innerPrivateBrowsingWithAppBranding: string;
    innerWithPrivateBrowsingLink: string;
    innerWithPopup: string;
    lightboxCover: string;
    middle: string;
    outer: string;
    paginationView: string;
    paginationViewWithIdentityBanner: string;
    wide: string;
    transparent: string;
  };
};

/**
 * LightboxLayout component
 * @param props The properties for this component
 * @param props.styles The styles to apply to various parts of this component
 * @param props.children The child elements to render inside this component
 * @param props.customStyles The custom styles to apply to various parts of this component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const LightboxLayoutFabric: React.FC<LightboxLayoutPropsFabric> = function LightboxLayoutFabric({
  styles,
  children,
  customStyles,
}) {
  const {
    globalState,
    globalState: { showMoreOptions, isWideView },
  } = useContext(GlobalContext);

  const {
    customizationState: {
      styles: { alwaysShowBackground, backgroundLogoUrl, useTransparentLightbox, showHeader },
    },
  } = useCustomizationContext();

  const { treatment } = useExperiment(ExperimentFeature.AddPrivateBrowsingTextToFabricFooter);
  const usePrivateBrowsingTextFooter =
    treatment === TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment;

  const { bannerEnvironment, isHosted } = GlobalConfig.instance;
  const { hideFooter } = FooterConfig.instance;

  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [focusOnDebugCopyLink, setFocusOnDebugCopyLink] = useState(false);
  const [focusOnEllipsis, setFocusOnEllipsis] = useState(false);

  const { useLayoutAnimateStyles, useCommonStyles } = StylesConfig.instance;
  const animateStyles = useLayoutAnimateStyles();
  const animationSupported = isCssAnimationSupported();
  const { directionWrapper } = useCommonStyles();
  const appBrandingStyles =
    !isHosted && usePrivateBrowsingTextFooter && !showMoreOptions
      ? styles.innerPrivateBrowsingWithAppBranding
      : styles.innerWithAppBranding;

  const middleClassNames = mergeClasses(styles.middle, ExternalClassName.middle);
  let innerClassName = mergeClasses(
    !isHosted && usePrivateBrowsingTextFooter && !showDebugInfo
      ? styles.innerWithPrivateBrowsingLink
      : styles.inner,
    isWideView ? styles.wide : "",
    useTransparentLightbox ? styles.transparent : "",
    alwaysShowBackground ? styles.innerAlwaysShowBackground : "",
    showMoreOptions ? styles.innerWithPopup : "",
    // @TODO: Figure out a way to detect app-branding based on all app-branding server data properties
    //        and not just the background url logo. Currently this is responsible for a CSS bug where
    //        there is no spacing between the lightbox / vertical split layout and the sign-in options
    //        tile if the branding for the app does not include a logo but does include a friendly app
    //        name and/or sign-in/sign-up descriptions.
    backgroundLogoUrl ? appBrandingStyles : "",
  );

  // Add animation styles for lightbox/inner container when animation is supported
  if (animationSupported) {
    innerClassName = mergeClasses(innerClassName, animateStyles.fadeIn);
  }

  const shouldShowBanner = bannerEnvironment !== EnvironmentName.None && bannerEnvironment;

  return (
    <DirectionWrapper directionWrapperClassName={directionWrapper} testID="lightbox-layout">
      <GamepadNavigationWrapper>
        <BackgroundFabric />
        <div
          id="outer"
          data-testid="outer"
          className={styles.outer}
          role="presentation"
          {...gamepadMainContentAttributes}
        >
          {shouldShowBanner && <EnvironmentBannerFabric />}
          <Header />
          <div id="middle" className={middleClassNames} role="main">
            <LayoutContentFabric
              focusOnDebugCopyLink={focusOnDebugCopyLink}
              setFocusOnEllipsis={setFocusOnEllipsis}
              showDebugInfo={showDebugInfo}
              setShowDebugInfo={setShowDebugInfo}
              styles={useLayoutContentStylesFabric(globalState, innerClassName, showHeader)}
              customStyles={customStyles}
            >
              {children}
            </LayoutContentFabric>
          </div>
          {!hideFooter && (
            <Footer
              isDebugInfoShown={showDebugInfo}
              focusOnEllipsis={focusOnEllipsis}
              onDebugItemClick={(e) => {
                e.preventDefault();
                setFocusOnDebugCopyLink(true);
                setShowDebugInfo(!showDebugInfo);
              }}
            />
          )}
        </div>
      </GamepadNavigationWrapper>
    </DirectionWrapper>
  );
};

export default LightboxLayoutFabric;
