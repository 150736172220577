import { ViewId } from "../../../constants";
import { useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { useNavigateDirection } from "../../../hooks";
import {
  type GctResult,
  GctResultAction,
} from "../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { OtcPurposes } from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { getRouteFromViewId } from "../../../utilities/routing-helper";
import { defaultLoginState, useLoginContext } from "../login-context";
import { LoginActionType } from "../login-reducer";
import { type FidoPostParams } from "../login-types";
import { useFidoPostRedirect, useLoginFlowRedirect } from "./login-hooks";
import { useSendOtcForRemoteLoginPolling } from "./use-send-otc-for-remote-login-polling";

/**
 * This is a shared GCT response handler hook for views that use the GCT result
 * to determine what action to take next. Keeping this logic in a shared location
 * prevents future bugs and having to make changes in multiple locations.
 * @returns a function that determines next action based on the GCT response
 */
export const useHandleGctResponse = () => {
  const {
    dispatchStateChange,
    globalState: { user },
  } = useGlobalContext();
  const { dispatchStateChange: dispatchLoginStateChange } = useLoginContext();
  const fidoRedirectCallback = useFidoPostRedirect();
  const navigator = useNavigateDirection();
  const loginFlowRedirect = useLoginFlowRedirect();
  const sendOtcThenNavigate = useSendOtcForRemoteLoginPolling(
    null,
    OtcPurposes.xboxRemoteConnect,
    user,
  );

  return (
    navigateFrom: ViewId,
    gctResult: Partial<GctResult>,
    username: string,
    displayUsername: string,
    errorHandler: (error: string | JSX.Element) => void,
  ) => {
    switch (gctResult.action) {
      case GctResultAction.ShowError:
        if (gctResult.error) {
          errorHandler(gctResult.error);
        }

        break;
      case GctResultAction.SwitchView:
        if (gctResult.sharedData) {
          dispatchStateChange({
            type: GlobalActionType.SetUser,
            payload: {
              username,
              displayUsername,
            },
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetLocation,
            payload: gctResult.sharedData.location!,
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetRemoteNgcParams,
            payload: gctResult.sharedData.remoteNgcParams || defaultLoginState.remoteNgcParams,
          });

          // If GCT response has allow list in Fido params, update so that later views such as the
          // passkey interstitial view can use the correct params when redirecting to the Fido endpoint
          if (gctResult.sharedData.fidoParams?.AllowList) {
            dispatchLoginStateChange({
              type: LoginActionType.SetFidoAllowList,
              payload: gctResult.sharedData.fidoParams.AllowList,
            });
          }

          // Update login context with the availableCredentials from the GCT response
          dispatchLoginStateChange({
            type: LoginActionType.UpdateCredentials,
            payload: {
              preferredCredential: gctResult.sharedData.preferredCredential,
              availableCredentials: gctResult.sharedData.availableCredentials,
              otcCredential: gctResult.sharedData.otcCredential,
              evictedCredentials: gctResult.sharedData.evictedCredentials,
              useEvictedCredentials: gctResult.sharedData.useEvictedCredentials,
            },
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetIdpRedirectData,
            payload: {
              url: gctResult.viewParams?.idpRedirectUrl,
              postParams: gctResult.viewParams?.idpRedirectPostParams,
              provider: gctResult.viewParams?.idpRedirectProvider,
            },
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetHasIdpDisambigError,
            payload: gctResult.viewParams?.hasIdpDisambigError || false,
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetRemoteLoginPollingParams,
            payload:
              gctResult.sharedData.remoteLoginPollingParams ||
              defaultLoginState.remoteLoginPollingParams,
          });
        }

        if (gctResult.viewId === ViewId.Fido) {
          // Since we don't have a Fido view in React, we redirect directly.
          // Use the allow list received from the GCT response instead of server data
          const allowList = gctResult.sharedData?.fidoParams?.AllowList;
          const fidoParams: Partial<FidoPostParams> = { username };
          if (allowList) fidoParams.credentialsJson = allowList;

          fidoRedirectCallback(fidoParams);
        } else if (gctResult.viewId === ViewId.RemoteLoginPolling) {
          // This hook makes an OTC call and then, if successful, navigates to the RemoteLoginPolling View to display OTC and handles polling.
          sendOtcThenNavigate();
        } else {
          navigator(navigateFrom, getRouteFromViewId(gctResult.viewId!));
        }

        break;
      case GctResultAction.Redirect:
        loginFlowRedirect(
          gctResult.redirectUrl || "",
          gctResult.redirectPostParams,
          gctResult.isIdpRedirect,
        );

        break;
      default:
      // do nothing for now
    }
  };
};
