import { type FlowId } from "../constants";
import FeaturesConfig from "../features-config";
import { loadCustomCss, removeCustomCss } from "./custom-loader";
import { getStringCustomizationPromises } from "./custom-strings-loader";
import type { CustomizationFiles } from "./customization-types";

/**
 * This object represents the key and value pair for customer defined string overrides.
 */
export type CustomString = {
  key: string;
  value: string;
};

/**
 * This object contains the promises that should be resolved before rendering the page.
 * The `loadPromises` array contains void promises that load content without updating state.
 * The `stringPromises` array contains all promises that return strings to update string customization state.
 */
export type RenderPromises = {
  loadPromises: Promise<void>[];
  stringPromises: Promise<CustomString[]>[];
};

/**
 * This method is used to determine the actions that should be completed once on an initial render.
 * If custom CSS is required, a promise is returned in the array that resolves once the CSS is loaded and applied.
 * If custom CSS is not required, any existing custom CSS style tag is immediately removed.
 * @param customizationFiles The customization files in the styles.
 * @param flowId The flow ID to determine which custom string files are supported.
 * @returns The array of Render Promises.
 */
export function getRenderPromises(
  customizationFiles: CustomizationFiles,
  flowId?: FlowId,
): RenderPromises {
  const { loadStringCustomizationPromises } = FeaturesConfig.instance;
  const renderPromises: RenderPromises = {
    loadPromises: [],
    stringPromises: [],
  };

  // If custom CSS is defined, add the promise to the load promise array as no state update is expected.
  if (customizationFiles.customCssUrl) {
    renderPromises.loadPromises.push(loadCustomCss(customizationFiles.customCssUrl));
  } else {
    removeCustomCss();
  }

  // If the flow supports custom strings and they are defined, add the promise to the array of promises that loads strings.
  if (loadStringCustomizationPromises && flowId && customizationFiles.strings) {
    renderPromises.stringPromises = getStringCustomizationPromises(
      customizationFiles.strings,
      flowId,
    );
  }

  return renderPromises;
}
