import { useContext } from "react";
import { type TileFabricComponent } from "../../../../../components/tile/fabric/tile-fabric";
import { Flavors, ViewId } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useNavigateDirection, useShowProgressAndRedirect } from "../../../../../hooks";
import { useHandleOnSkip } from "../../../../../hooks/win11-oobe-fabric/use-handle-on-skip";
import {
  type OneTimeCodeCredential,
  type UserCredential,
  CredentialType,
  getCredentialPriority,
} from "../../../../../model/credential";
import { ProofType } from "../../../../../model/proof";
import {
  callIcon,
  emailIcon,
  genericAppCredIcon,
  githubCredIcon,
  linkedInCredIcon,
  offlineAccountIcon,
  passwordCredIcon,
  smsIcon,
} from "../../../../../utilities/image-helpers/accessible-images";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";
import { useFidoProperties } from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import { AllowedIdentitiesType } from "../../../login-constants";
import { LoginContext } from "../../../login-context";
import { type CommonLoginStrings } from "../../../login-interface";
import { type ICredentialPickerStrings } from "../credential-picker-interface";
import { useOneTimeCodeClickHandler } from "./use-otc-click-handler";

export interface ICredentialPickerViewProperties {
  availableCredentials: UserCredential[];
  backLabel: string;
  error: string | JSX.Element;
  redirect: () => void;
  setError: React.Dispatch<React.SetStateAction<string | JSX.Element>>;
  title: string;
}

/**
 * Processes the list of user credentials returned by the GCT response. First, the credentials list is filtered and sorted by
 * priority, then it is mapped to tile components which will be displayed in the tile list on the view.
 * @param credentials the available user credentials
 * @param fidoRedirect the Fido redirect method
 * @param strings strings for the current flavor
 * @param strings.credentialPickerStrings credential picker strings
 * @param strings.commonLoginStrings common login strings
 * @param setError the method for setting the error message in the credential picker view
 * @returns a list of tile components used to build the credential picker options
 */
export const useCredentialPickerTileListBuilder = (
  credentials: UserCredential[],
  fidoRedirect: () => void,
  {
    credentialPickerStrings,
    commonLoginStrings,
  }: {
    credentialPickerStrings: ICredentialPickerStrings;
    commonLoginStrings: CommonLoginStrings;
  },
  setError: React.Dispatch<React.SetStateAction<string | JSX.Element>>,
): TileFabricComponent[] => {
  const {
    githubAriaLabel,
    githubButtonLabel,
    linkedinLabel,
    microsoftAppLabel,
    offlineAccountLabel,
    oneTimeCodeEmailLabel,
    oneTimeCodeSMSLabel,
    oneTimeCodeVoiceLabel,
    passwordLabel,
    personalAccountsOnlyHelpText,
  } = credentialPickerStrings;

  const { fidoLinkText, fidoImage } = useFidoProperties(commonLoginStrings, true);

  const navigate = useNavigateDirection();
  const oneTimeCodeClickHandler = useOneTimeCodeClickHandler(credentialPickerStrings);
  const offlineAccountClickHandler = useHandleOnSkip(true);

  const {
    viewState: {
      credentials: { useEvictedCredentials },
    },
  } = useContext(LoginContext);
  const showDefaultCredentials = !useEvictedCredentials;

  const { activeFlavor } = GlobalConfig.instance;
  const { allowedIdentities, githubFedUrl, linkedInFedUrl, showOfflineAccount } =
    LoginConfig.instance;

  const showProgressAndRedirect = useShowProgressAndRedirect();

  const filteredCredentials = credentials.filter((credential) =>
    credential.proof ? credential.proof.isDefault === showDefaultCredentials : true,
  );

  if (showOfflineAccount && activeFlavor === Flavors.Win11OobeFabric) {
    filteredCredentials.push({ credentialType: CredentialType.OfflineAccount });
  }

  // credential priorities = importance of displaying them closer to the top of the list in the view
  // sort the credentials by priority so that they will be displayed accordingly.
  // if the credentials do not BOTH have a priority, they will be considered as equal.
  // ex. a has credentialType Fido, priorityA = 2.
  //     b has credentialType Password, priorityB = 4.
  //     priorityA - priorityB = -2 which is less than 0, so Fido is before Password in the list.
  filteredCredentials.sort((a, b) => {
    const priorityA = getCredentialPriority(a);
    const priorityB = getCredentialPriority(b);

    if (priorityA && priorityB) {
      return priorityA - priorityB;
    }

    return 0;
  });

  const tileList: TileFabricComponent[] = [];

  filteredCredentials.forEach((credential) => {
    switch (credential.credentialType) {
      case CredentialType.Fido:
        tileList.push({
          mainText: fidoLinkText,
          image: fidoImage,
          ariaLabel: fidoLinkText,
          onClick: fidoRedirect,
        });
        break;

      case CredentialType.RemoteNGC:
        tileList.push({
          mainText: microsoftAppLabel,
          image: {
            urls: genericAppCredIcon,
          },
          ariaLabel: microsoftAppLabel,
          onClick: () => {
            navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.PushNotifications));
          },
        });
        break;

      case CredentialType.Password:
        tileList.push({
          mainText: passwordLabel,
          image: { urls: passwordCredIcon },
          ariaLabel: passwordLabel,
          onClick: () => {
            navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.Password));
          },
        });
        break;

      case CredentialType.LinkedIn:
        tileList.push({
          mainText: linkedinLabel,
          helpText:
            allowedIdentities === AllowedIdentitiesType.Both
              ? personalAccountsOnlyHelpText
              : undefined,
          image: { urls: linkedInCredIcon },
          ariaLabel: linkedinLabel,
          onClick: () => showProgressAndRedirect(linkedInFedUrl),
        });
        break;

      case CredentialType.GitHub:
        tileList.push({
          mainText: githubButtonLabel,
          helpText:
            allowedIdentities === AllowedIdentitiesType.Both
              ? personalAccountsOnlyHelpText
              : undefined,
          image: { urls: githubCredIcon },
          ariaLabel: githubAriaLabel,
          onClick: () => showProgressAndRedirect(githubFedUrl),
        });
        break;

      case CredentialType.OneTimeCode: {
        const selectedCredential = credential as OneTimeCodeCredential;
        const { proof } = selectedCredential;

        if (proof.type === ProofType.Email) {
          tileList.push({
            mainText: oneTimeCodeEmailLabel,
            image: { urls: emailIcon },

            ariaLabel: oneTimeCodeEmailLabel,
            onClick: () => {
              oneTimeCodeClickHandler(selectedCredential, setError);
            },
            mainTextReplaceValue: proof.display,
            ariaLabelReplaceValue: proof.display,
          });
        } else if (proof.type === ProofType.SMS) {
          tileList.push({
            mainText: oneTimeCodeSMSLabel,
            image: { urls: smsIcon },
            ariaLabel: oneTimeCodeSMSLabel,
            onClick: () => {
              oneTimeCodeClickHandler(selectedCredential, setError);
            },
            mainTextReplaceValue: proof.display,
            ariaLabelReplaceValue: proof.display,
          });
        } else if (proof.type === ProofType.Voice) {
          tileList.push({
            mainText: oneTimeCodeVoiceLabel,
            image: { urls: callIcon },
            ariaLabel: oneTimeCodeVoiceLabel,
            onClick: () => {
              oneTimeCodeClickHandler(selectedCredential, setError);
            },
            mainTextReplaceValue: proof.display,
            ariaLabelReplaceValue: proof.display,
          });
        }

        break;
      }

      case CredentialType.OfflineAccount:
        tileList.push({
          mainText: offlineAccountLabel,
          image: { urls: offlineAccountIcon, dataTestId: "offline-account-img" },
          onClick: offlineAccountClickHandler,
          ariaLabel: offlineAccountLabel,
        });
        break;

      default:
        break;
    }
  });

  return tileList;
};
