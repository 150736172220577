import LoginConfig from "../../flows/login/login-config";
import { isBackButtonSupportedOnInitialView } from "../../utilities/cxh-handlers/cxh-handler";

export const useIsBackButtonSupportedOnInitialView = () => {
  const {
    isScoobe,
    offlineAccountVisible,
    isOobe,
    postedForceSignIn,
    lockUsername,
    isOverTheShoulder,
  } = LoginConfig.instance;

  return (onSuccessHandler: (isSupported: boolean, useCancelText?: boolean) => void) => {
    isBackButtonSupportedOnInitialView(
      isScoobe,
      offlineAccountVisible,
      isOobe,
      postedForceSignIn,
      lockUsername,
      isOverTheShoulder,
      onSuccessHandler,
    );
  };
};
