import LoginConfig from "../../login-config";
import type {
  IConsentMessageProperties,
  IInlineLegalMessagingStrings,
} from "./inline-legal-messaging-interface";

/**
 * @param inlineLegalMessagingStrings The flavor-specific inline legal messaging strings
 * @returns The appropriate string to render for the privacy agreement message
 */
export const getPrivacyAgreementMessage = (
  inlineLegalMessagingStrings: IInlineLegalMessagingStrings,
): string => {
  const {
    oobeProperties: { oobeDisplayUnifiedConsent },
  } = LoginConfig.instance;

  const { touPrivacyAgreementWithoutConsent, touPrivacyAgreementWithConsent } =
    inlineLegalMessagingStrings;

  return oobeDisplayUnifiedConsent
    ? touPrivacyAgreementWithConsent
    : touPrivacyAgreementWithoutConsent;
};

/**
 * @param inlineLegalMessagingStrings The flavor-specific inline legal messaging strings
 * @returns The appropriate type and content of consent message to render
 */
export const getConsentTypeAndMessage = (
  inlineLegalMessagingStrings: IInlineLegalMessagingStrings,
): IConsentMessageProperties => {
  const {
    oobeProperties: {
      oobeDisplayConsentWithoutOneDrive,
      oobeDisplayServicesConsent,
      oobeDisplayUnifiedConsent,
    },
  } = LoginConfig.instance;

  const {
    touServicesConsentWithoutOneDrive,
    touServicesConsentWithOneDrive,
    touUnifiedConsentWithoutOneDrive,
    touUnifiedConsentWithOneDrive,
  } = inlineLegalMessagingStrings;

  let consentMessageProperties = {
    consentLinkBindingTagName: "",
    consentLinkId: "",
    consentMessage: "",
  };

  if (oobeDisplayUnifiedConsent) {
    consentMessageProperties = {
      consentLinkBindingTagName: "learnMoreLink",
      consentLinkId: "learnMoreLinkId",
      consentMessage: oobeDisplayConsentWithoutOneDrive
        ? touUnifiedConsentWithoutOneDrive
        : touUnifiedConsentWithOneDrive,
    };
  } else if (oobeDisplayServicesConsent) {
    consentMessageProperties = {
      consentLinkBindingTagName: "seeHowDataIsManagedLink",
      consentLinkId: "seeHowDataIsManagedLinkId",
      consentMessage: oobeDisplayConsentWithoutOneDrive
        ? touServicesConsentWithoutOneDrive
        : touServicesConsentWithOneDrive,
    };
  }

  return consentMessageProperties;
};
