import { type RedirectPostParams } from "./utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { type PostRedirectState } from "./post-redirect-context";

/**
 * Action types that are accepted by the post redirect reducer
 */
export enum PostRedirectActionType {
  SubmitPostRedirect,
}

type SubmitPostRedirectAction = {
  type: PostRedirectActionType.SubmitPostRedirect;
  payload: { url?: string; postParams?: RedirectPostParams; target?: string; submitForm?: boolean };
};

/**
 * Union of all actions that can be dispatched to the post redirect reducer to update the post redirect state
 */
export type PostRedirectActions = SubmitPostRedirectAction;

/**
 * Post redirect state reducer
 * @param state The current state
 * @param action The action to perform on the current state
 * @returns The new state
 */
export default function postRedirectReducer(
  state: PostRedirectState,
  action: PostRedirectActions,
): PostRedirectState {
  const actionType = action.type;
  switch (actionType) {
    case PostRedirectActionType.SubmitPostRedirect:
      return {
        ...state,
        url: action.payload.url || state.url,
        postParams: action.payload.postParams || state.postParams,
        target: action.payload.target || state.target,
        submitForm: action.payload.submitForm || state.submitForm,
      };

    default:
      throw new Error(`PostRedirectReducer received unexpected action ${actionType}`);
  }
}
