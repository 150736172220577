import React from "react";
import StylesConfig from "../../../../config/styles-config";
import { FormattedTextWithBindings } from "../../../../utilities/formatted-text-with-bindings";

/**
 * Given a paragraph title, a description string and embedded HTML bindings for possible placeholders in the description strings,
 * replaces the placeholders with the supplied embedded bindings and returns the final JSX paragraph element containing the input
 * title in heavy type and the post-processed description.
 *
 * @param title A title string
 * @param description A description string with possible placeholders
 * @param embeddedBindings The bindings that should replace the placeholder bindings if the input description contains any
 * @returns A JSX paragraph element containing the input title and description
 */
export const useParagraphWithBindings = (
  title: string,
  description: string,
  embeddedBindings?: Record<string, (text: string) => JSX.Element>,
): JSX.Element => {
  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();

  return (
    <div tabIndex={-1} className={commonStyles.paragraphSpacing}>
      <div className={commonStyles.bold}>{title}</div>
      <FormattedTextWithBindings text={description} embeddedBindings={embeddedBindings ?? {}} />
    </div>
  );
};
