import { loginOption } from "../../../../constants";
import { QueryString } from "../../../../constants/querystring-constants";
import LoginConfig from "../../login-config";

const fedStateRealmDiscoveryAutoredirect = 2; // fedState 2 represents FEDSTATE_REALMDISCOVERY_AUTOREDIRECT

/**
 * Get the updated federated redirect URL with required query string parameters
 * @param username The username to be passed in the URL
 * @returns The updated federated redirect URL
 */
export const getFedRedirectUrl = (username: string) => {
  const { fedQs, fedUrl, defaultLoginOptions } = LoginConfig.instance;
  const federationQs = new URLSearchParams(fedQs); // fedQs object from ServerData
  const federationUrlQs = new URL(fedUrl).searchParams; // the query string terms from urlFed from ServerData

  // Set the KMSI checkbox option for after federation
  if (defaultLoginOptions === loginOption.nothingChecked) {
    federationQs.set(
      QueryString.webServicesFederationContext,
      `LoginOptions=3&${federationQs.get(QueryString.webServicesFederationContext)}`,
    );
  }

  // Resetting these values to make sure we decode/encode the values. URL set and get do so implictly
  federationUrlQs.set(
    QueryString.cobrandingContext,
    federationUrlQs.get(QueryString.cobrandingContext) || "",
  );
  federationUrlQs.set(QueryString.username, username);
  federationUrlQs.set(QueryString.market, federationUrlQs.get(QueryString.market) || "");
  federationUrlQs.set(
    QueryString.languageCodeId,
    federationUrlQs.get(QueryString.languageCodeId) || "",
  );

  // Add the updated fedQs to the final federationUrlQs
  federationQs.forEach((value, key) => {
    federationUrlQs.set(key, value);
  });

  const federationUrl = new URL(fedUrl);
  federationUrl.search = federationUrlQs.toString();

  return federationUrl.href;
};

/**
 * Check if need to redirect
 * 1. For iFrame busting
 * 2. Federation
 * 3. If cookies are disabled, redirect to page to show messaging for it
 */
export const redirectOnLoadIfNeeded = () => {
  const { fedState, fedUrl, noCookiesUrl } = LoginConfig.instance;

  // Check if the top.location is the same as the location.
  // Don't do this if we are in storybook since it is hosted within an iframe
  if (!IS_STORYBOOK && window.top !== window.self) {
    // We are in an iframe, try to bust out
    window?.top?.location.replace(window.self.location.href);
  }

  // Check if it is a Federation Redirection
  if (fedState === fedStateRealmDiscoveryAutoredirect && fedUrl) {
    const windowLocation = new URL(window.location.href);
    const federationUrl = getFedRedirectUrl(
      windowLocation.searchParams.get(QueryString.username) || "",
    );
    window.location.replace(federationUrl);
  }

  // Check if cookies are enabled
  if (!navigator.cookieEnabled) {
    window.location.replace(noCookiesUrl);
  }
};
