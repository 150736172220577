import { LoginMode } from "../../login-constants";

/**
 * @param loginMode The login mode from server data
 * @param sessionsLength The length of the array of user sessions from server data
 * @param isForceSignIn Whether the login mode is a force sign in
 * @returns Whether or not the initial view should be Account Picker
 */
export const isInitialViewAccountPicker = (
  loginMode: number,
  sessionsLength: number,
  isForceSignIn: boolean,
) => (!isForceSignIn && !!sessionsLength) || loginMode === LoginMode.Tiles;
