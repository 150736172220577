import {
  type IPushNotificationsDialogStrings,
  type IPushNotificationsViewStrings,
} from "../push-notifications-view-interface";

export const pushNotificationsViewStringsFabric: IPushNotificationsViewStrings = {
  heading: getLocalString("Login_PushNotifications_SessionPolling_Title"),
  headingGenericAppName: getLocalString("Login_PushNotifications_SessionPolling_Title_Generic"),
  descriptionFamiliarDevice: getLocalString("Login_PushNotifications_Description_FamiliarDevice"),
  descriptionFamiliarDeviceGenericAppName: getLocalString(
    "Login_PushNotifications_Description_FamiliarDevice_GenericAppName",
  ),
  descriptionFamiliarDeviceGenericDevice: getLocalString(
    "Login_PushNotifications_Description_FamiliarDevice_GenericDevice",
  ),
  descriptionFamiliarDeviceGenericAppNameGenericDevice: getLocalString(
    "Login_PushNotifications_Description_FamiliarDevice_GenericAppName_GenericDevice",
  ),
  descriptionUnfamiliarDevice: getLocalString(
    "Login_PushNotifications_Description_UnfamiliarDevice",
  ),
  descriptionUnfamiliarDeviceGenericAppName: getLocalString(
    "Login_PushNotifications_Description_UnfamiliarDevice_GenericAppName",
  ),
  descriptionUnfamiliarDeviceGenericDevice: getLocalString(
    "Login_PushNotifications_Description_UnfamiliarDevice_GenericDevice",
  ),
  descriptionUnfamiliarDeviceGenericAppNameGenericDevice: getLocalString(
    "Login_PushNotifications_Description_UnfamiliarDevice_GenericAppName_GenericDevice",
  ),
  lostAppLinkText: getLocalString("Login_PushNotifications_LostApp"),
  lostAppLinkTextGenericAppName: getLocalString("Login_PushNotifications_LostApp_Generic"),
  primaryButtonLabel: getLocalString("General_TryAgain"),
  requestDeniedError: getLocalString("Login_PushNotifications_SessionDenied_Description"),
  requestDeniedTitle: getLocalString("Login_PushNotifications_SessionDenied_Title"),
  requestSendError: getLocalString("Login_PushNotifications_SessionFailed_Description"),
  requestSendErrorTitle: getLocalString("SessionApproval_Failed_Title"),
  requestTimeoutError: getLocalString("Login_PushNotifications_SessionTimeout_Description"),
  requestTimeoutTitle: getLocalString("Request_Timeout"),
  resendNotificationText: getLocalString("Login_PushNotifications_ResendNotificationText"),
  seeAllDevicesLinkText: getLocalString("Login_PushNotifications_SeeAllDevices_LinkText"),
};

export const pushNotificationsDialogStringsFabric: IPushNotificationsDialogStrings = {
  accountManagementLinkText: getLocalString("Login_PushNotifications_Dialog_AccountManagement"),
  accountManagementLinkTextHosted: getLocalString(
    "Login_PushNotifications_Dialog_AccountManagement_Hosted",
  ),
  description: getLocalString("Login_PushNotifications_Dialog_Description"),
  listItem: getLocalString("Login_PushNotifications_Dialog_ListItem"),
  listItemGenericAppName: getLocalString("Login_PushNotifications_Dialog_ListItem_GenericAppName"),
  listItemGenericDevice: getLocalString("Login_PushNotifications_Dialog_ListItem_GenericDevice"),
  listItemGenericAppNameGenericDevice: getLocalString(
    "Login_PushNotifications_Dialog_ListItem_GenericAppName_GenericDevice",
  ),
  primaryButtonLabel: getLocalString("General_Close"),
  title: getLocalString("Login_PushNotifications_Dialog_Title"),
};
