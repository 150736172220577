import React, { type ReactNode, useEffect, useRef } from "react";
import { mergeClasses } from "@griffel/react";
import { ExperimentFeature, TreatmentFlight } from "../../../config/experiments";
import StylesConfig from "../../../config/styles-config";
import featuresConfig from "../../../features-config";
import GlobalConfig from "../../../global-config";
import { useExperiment } from "../../../hooks";
import { replaceTokens } from "../../../utilities/strings-helper";
import { type AccessibleImageProps, AccessibleImage } from "../../accessible-image";
import { ButtonFabric } from "../../button/fabric/button-fabric";
import { HelpButton } from "../../help-button";
import {
  type OptionsMenuButtonProps,
  OptionsMenuButton,
} from "../../options-menu-button/options-menu-button";
import { OptionsMenuButtonDeprecated } from "../../options-menu-button/options-menu-button-deprecated";
import { type ITileProps } from "../tile-interface";

export type ITileFabricProps = ITileProps & {
  /** Optional details text */
  helpText?: string;
  /** Optional sub text */
  subText?: string;
  /** optional className for other components */
  isMoreOptions?: boolean;
  /** optional boolean indicating whether the tile should have app-branded styles  */
  isAppBranded?: boolean;
  /** optional boolean indicating whether the tile should have app-branded styles  */
  isTilesUnderLightbox?: boolean;
  /** optional help icon of tile  */
  helpDialogIcon?: AccessibleImageProps;
  /** optional header for popup */
  helpDialogHeader?: ReactNode;
  /** optional description for popup */
  helpDialogDescription?: ReactNode;
  /** optional menu button for more options */
  optionsMenuButton?: OptionsMenuButtonProps;
  /** optional value for replacement value in the main text */
  mainTextReplaceValue?: string;
  /** optional value for replacement value in the aria label */
  ariaLabelReplaceValue?: string;
  /** optional value for identifing the button to select using automation */
  dataTestId?: string;
  /** optional value for identifing if the debug info popup is currently shown */
  debugInfoShown?: boolean;
};

export type TileFabricComponent = ITileFabricProps;

/**
 * Tile component for Fabric
 * @param props ITileFabricProps properties for this component
 * @returns tile
 */
export const TileFabric: React.FC<ITileFabricProps> = function TileFabric(props) {
  const {
    mainText,
    helpText,
    subText,
    image,
    onClick = () => {},
    ariaLabel,
    ariaDescribedBy,
    isMoreOptions = false,
    isAppBranded = false,
    isTilesUnderLightbox = false,
    hasInitialFocus = false,
    helpDialogIcon,
    helpDialogHeader,
    helpDialogDescription,
    mainTextReplaceValue,
    ariaLabelReplaceValue,
    optionsMenuButton,
    dataTestId,
    debugInfoShown = false,
  } = props;

  const tileUnderLightboxFormatting = isMoreOptions || isTilesUnderLightbox;

  const { supportWindowsStyles } = GlobalConfig.instance;

  const { useTileStyles } = StylesConfig.instance;
  const tileStyles = useTileStyles();

  const elementReference = useRef<HTMLButtonElement>(null);

  const { treatment } = useExperiment(ExperimentFeature.AddPrivateBrowsingTextToFabricFooter);
  const usePrivateBrowsingTextFooter =
    treatment === TreatmentFlight.AddPrivateBrowsingTextToFabricFooterTreatment;

  let tileClass = mergeClasses(tileStyles.tileContainer, tileStyles.list);
  if (tileUnderLightboxFormatting) {
    tileClass = mergeClasses(
      isMoreOptions ? tileStyles.moreOptionsTile : tileStyles.tilesUnderLightbox,
      usePrivateBrowsingTextFooter && !debugInfoShown
        ? tileStyles.moreOptionsWithExtendedFooter
        : "",
      supportWindowsStyles && tileStyles.list,
      isAppBranded && tileStyles.moreOptionsTileWithAppBranding,
    );
  }

  const tileImageClass = tileUnderLightboxFormatting
    ? mergeClasses(tileStyles.middle, tileStyles.moreOptionsTileImage)
    : mergeClasses(tileStyles.middle, tileStyles.tileImage);
  const tileTextClass = mergeClasses(
    tileStyles.middle,
    tileStyles.tileTextAlign,
    tileStyles.content,
  );
  image.className = mergeClasses(
    image.className || "",
    tileUnderLightboxFormatting ? tileStyles.moreOptionsTileImage : tileStyles.tileImage,
  );

  useEffect(() => {
    if (hasInitialFocus) {
      elementReference?.current?.focus();
    }
  }, [hasInitialFocus]);

  // TODO: Perhaps find a more robust way for identifying selectors instead of using data-testid.
  const { loadGamepadNavigationModule: useNewOptionsMenu } = featuresConfig.instance;
  let optionsMenuButtonComponent;
  if (optionsMenuButton) {
    const optionsMenuProps = {
      icon: optionsMenuButton.icon,
      menuButtonIconClassName: tileStyles.menuButton,
      menuItemList: optionsMenuButton.menuItemList,
      menuTestId: optionsMenuButton.menuTestId,
    };

    optionsMenuButtonComponent = useNewOptionsMenu ? (
      <OptionsMenuButton {...optionsMenuProps} />
    ) : (
      // eslint-disable-next-line deprecation/deprecation
      <OptionsMenuButtonDeprecated {...optionsMenuProps} />
    );
  }

  return (
    <div
      className={tileClass}
      role="listitem"
      aria-label={
        ariaLabelReplaceValue ? replaceTokens(ariaLabel, ariaLabelReplaceValue) : ariaLabel
      }
      aria-describedby={ariaDescribedBy}
    >
      <div
        className={tileUnderLightboxFormatting ? tileStyles.moreOptionsRowTile : tileStyles.rowTile}
      >
        <ButtonFabric
          className={mergeClasses(
            tileUnderLightboxFormatting
              ? tileStyles.moreOptionsTileButtonSettings
              : tileStyles.tileButtonSettings,
            supportWindowsStyles && mergeClasses(tileStyles.listItem, tileStyles.listItemInList),
          )}
          onClick={onClick}
          dataTestId={dataTestId}
          ref={elementReference}
        >
          <div className={tileImageClass} data-testid="mainImage">
            <AccessibleImage {...image} />
          </div>
          <div className={tileTextClass}>
            <div data-testid="mainText">
              {mainTextReplaceValue ? replaceTokens(mainText, mainTextReplaceValue) : mainText}
            </div>
            {helpText && (
              <div className={tileStyles.small} data-testid="helpText">
                {helpText}
              </div>
            )}
            {subText && (
              <div className={tileStyles.small} data-testid="subText">
                {subText}
              </div>
            )}
          </div>
        </ButtonFabric>
        {/* @TODO: Add an aria-label attribute to this help button component to fix a11y violation */}
        {helpDialogIcon && (
          <HelpButton
            helpDialogIcon={helpDialogIcon}
            helpDialogHeader={helpDialogHeader}
            helpDialogDescription={helpDialogDescription}
            helpDialogIconClassName={tileStyles.helpButton}
          />
        )}
        {optionsMenuButtonComponent}
      </div>
    </div>
  );
};
