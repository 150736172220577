import { initStylesConfig } from "../../../config/styles-config";
import { type Flavors, type FlowId } from "../../../constants";
import { createGlobalState } from "../../../global-context";
import { type UserOptions } from "../../../global-reducer";
import { initialHistoryState } from "../../../history-context";
import { type ServerData } from "../../../utilities/server-data";
import { initAgreementViewStringsFabric } from "../../../views/agreement-view/fabric/init-agreement-view-strings-fabric";
import { createCustomizationState } from "../../customization-context";
import { getInitialUser } from "../get-initial-user";
import { initBaseSisuConfig } from "../init-base-sisu-config";

/**
 * Parse the ServerData object sent from the IDP and return the initial config/state for the common app context and config
 * for fabric-based flavors.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The IDP-specific ServerData object sent from the server and read from the window object.
 * @param activeFlavor Rendered flavor
 * @param activeFlow Rendered flow
 * @param isHistorySupported Whether the flow supports history context or not **This is a workaround for a
 * known issue. This should be set to TRUE unless it is explicitly known that the flow does not support history.**
 * @param getUserOptions Opptional method to get the user for scenarios with additional requirements.
 * @returns The initial state for the global, history, and customization contexts.
 */
export const initBaseSisuAppDataFabric = (
  serverData: ServerData,
  activeFlavor: Flavors,
  activeFlow: FlowId,
  isHistorySupported = true,
  getUserOptions?: () => UserOptions,
) => {
  initBaseSisuConfig(serverData, activeFlavor, isHistorySupported);
  initStylesConfig(activeFlavor);
  initAgreementViewStringsFabric(activeFlavor);

  const initialGlobalState = createGlobalState(
    serverData,
    getInitialUser(getUserOptions),
    activeFlow,
  );

  const initialCustomizationState = createCustomizationState(serverData);

  return { initialGlobalState, initialHistoryState, initialCustomizationState };
};
