import { useMemo } from "react";
import { mergeClasses } from "@griffel/react";
import { type Styles } from "../styles/types.styles";

/**
 * A custom hook that merges two different styles' records by
 * 1) adding new classes to the merged record if they exist in the base record but not in the overrides record,
 * 2) adding new classes to the merged record if they exist in the overrides record but not in the base record and
 * 3) merging the classes in the merged record if they exist in both the base and overrides records.
 *
 * @param base - The base styles
 * @param overrides - The override styles
 * @returns The merged styles record
 */
export const useMergedStyles = (base: Styles, overrides: Styles): Styles =>
  useMemo(
    () => {
      // Start with base styles
      const merged = { ...base };

      // Then add override styles to base
      Object.keys(overrides).forEach((key: string) => {
        const className = key as keyof Styles;

        merged[className] = mergeClasses(
          className in base && base[className],
          className in overrides && overrides[className],
        );
      });

      return merged;
    },
    // Note that the input styles are static throughout the lifetime of an app or component,
    // so this useMemo should run only once
    [base, overrides],
  );
