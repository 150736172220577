/* eslint-disable @msidentity/authx/no-inline-eslint-disable */
import React, { useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../config/styles-config";
import { gamepadMainContentAttributes } from "../../constants";
import { useFocusElement } from "../../hooks/use-focus-element";
import { useGamepadCancel } from "../../hooks/use-gamepad-cancel";
import { type AccessibleImageProps } from "../accessible-image";
import { FocusLocker } from "../focus-locker";
import { ImageButtonFabric } from "../image-button/fabric/image-button-fabric";
import { LinkButton } from "../link-button";
import { useCloseOnOutsideClick } from "./hooks/use-close-on-outside-click";

export type OptionsMenuItemProps = {
  itemId: string;
  itemText: string;
  isVisible?: boolean;
  hasFocus?: boolean;
  skipHasFocusDelay?: boolean;
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
  ariaSelected?: boolean;
};

export type OptionsMenuLabelProps = {
  labelText: string;
  labelClassName?: string;
  labelTestId?: string;
};

export type OptionsMenuButtonProps = {
  icon: AccessibleImageProps;
  menuClassName?: string;
  menuButtonIconClassName?: string;
  menuItemList: OptionsMenuItemProps[];
  menuTestId?: string;
  ariaLabel?: string;
  menuLabel?: OptionsMenuLabelProps;
  onOutsideClick?: () => void;
  closeOnSelect?: boolean;
  hasFocus?: boolean;
  hasInitialFocus?: boolean;
  isComboBox?: boolean;
};

/**
 * Options Menu Button
 * @param props The properties required for this component
 * @returns OptionsMenuButton component
 */
export const OptionsMenuButton: React.FC<OptionsMenuButtonProps> = function OptionsMenuButton(
  props,
) {
  const {
    icon,
    menuClassName,
    menuButtonIconClassName,
    menuItemList,
    menuTestId,
    ariaLabel,
    menuLabel,
    onOutsideClick,
    closeOnSelect,
    hasFocus,
    hasInitialFocus,
    isComboBox = false,
  } = props;
  const { useOptionsMenuButtonStyles } = StylesConfig.instance;
  const menuButtonStyles = useOptionsMenuButtonStyles();
  const optionsMenu = useRef<HTMLInputElement>(null);
  const menuButton = useRef<HTMLButtonElement>(null);
  const optionsList = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  icon.className = mergeClasses(icon?.className || "", menuButtonStyles.accessibleImageAlign);
  const [activeDescendant, setActiveDescendant] = useState<string | undefined>(undefined);

  // call hook that closes menu when user clicks outside of it
  useCloseOnOutsideClick(optionsMenu, setOpenMenu, onOutsideClick);

  const onCancel = () => {
    setOpenMenu(false);
    menuButton.current?.focus();
  };

  /**
   * Handle when Escape is pressed on the menu items pop-up
   * @param ev the keyboard event that triggered this function
   */
  const handleEscape = (ev: React.KeyboardEvent<HTMLUListElement>) => {
    if (ev.key === "Escape") {
      onCancel();
    }
  };

  useGamepadCancel(optionsList, onCancel);
  useFocusElement(menuButton, hasInitialFocus, hasFocus);

  const listAriaRole = isComboBox ? "listbox" : "menu";
  const itemAriaRole = isComboBox ? "option" : "menuitem";

  const menuItems = menuItemList.map((item) => (
    <div key={item.itemId}>
      {item.isVisible && (
        <li role="presentation" className={menuButtonStyles.menuMargin}>
          <LinkButton
            buttonRole={itemAriaRole}
            linkId={item.itemId}
            className={menuButtonStyles.menuLink}
            text={item.itemText}
            onClick={(e) => {
              item.onClick(e);
              if (closeOnSelect) {
                setOpenMenu(false);
              }
            }}
            onFocus={(e) => {
              setActiveDescendant(e.currentTarget.id);
            }}
            hasFocus={item.hasFocus}
            skipHasFocusDelay={item.skipHasFocusDelay}
            ariaSelected={item.ariaSelected}
          />
        </li>
      )}
    </div>
  ));

  return (
    <div ref={optionsMenu}>
      <span className={menuButtonIconClassName} data-testid="iconImage">
        <ImageButtonFabric
          ref={menuButton}
          buttonId="menubutton"
          dataTestId={menuTestId || "options-menu-button"}
          ariaHasPopup={listAriaRole}
          ariaLabel={ariaLabel}
          ariaExpanded={openMenu}
          ariaRole={isComboBox ? "combobox" : undefined}
          ariaActiveDescendant={openMenu ? activeDescendant : undefined}
          ariaControls={isComboBox ? "menulist" : undefined}
          className={menuButtonStyles.menuButtonImageStyle}
          image={icon}
          onClick={() => setOpenMenu(!openMenu)}
          imgAfterContent={!!menuLabel}
        >
          {menuLabel && (
            <div
              className={menuLabel.labelClassName}
              data-testid={menuLabel.labelTestId || "options-menu-label"}
            >
              {menuLabel.labelText}
            </div>
          )}
        </ImageButtonFabric>
      </span>

      {openMenu && (
        <FocusLocker wrapperRef={optionsList}>
          <div className={menuClassName || menuButtonStyles.menu} ref={optionsList}>
            {/* Linter doesn't recognize dynamically set interactable roles */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <ul
              id="menulist"
              className={menuButtonStyles.menuList}
              role={listAriaRole}
              aria-labelledby="menubutton"
              onKeyDown={handleEscape}
              {...gamepadMainContentAttributes}
            >
              {menuItems}
            </ul>
          </div>
        </FocusLocker>
      )}
    </div>
  );
};
