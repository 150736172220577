import { useCustomizationContext } from "../../../../../context/customization-context";
import LoginConfig from "../../../login-config";
import { type CommonLoginStrings } from "../../../login-interface";
import { getCommonDocumentTitle } from "../../../login-util";
import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";
import { getHeaderText } from "./get-dynamic-strings";
import { useCredentials } from "./use-credentials";
import { useDisplayProperties } from "./use-display-properties";
import { useEventHandlers } from "./use-event-handlers";

/**
 * This hook is used to aggregate the `useCredentials`, `useDisplayProperties`, and `useEventHandlers` hooks into a single hook.
 * @param strings The strings to use for the ConfirmSendView
 * @param strings.commonLoginStrings Common Login strings
 * @param strings.confirmSendViewStrings ConfirmSendView strings
 * @returns An object containing hook-generated values and callbacks to use in the ConfirmSendView
 */
export const useConfirmSendViewProperties = (strings: {
  commonLoginStrings: CommonLoginStrings;
  confirmSendViewStrings: ConfirmSendViewStrings;
}) => {
  const { loginMode } = LoginConfig.instance;
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  const credentials = useCredentials();
  const {
    buttonText,
    description,
    requestIsPending,
    showChangeUserLink,
    showButton,
    setButtonText,
    setHasError,
    setRequestIsPending,
  } = useDisplayProperties(credentials, strings.confirmSendViewStrings);

  const eventHandlers = useEventHandlers(
    {
      credentials,
      requestIsPending,
      setButtonText,
      setHasError,
      setRequestIsPending,
    },
    strings.confirmSendViewStrings,
  );

  return {
    // Credentials
    ...credentials,
    // Display Props
    buttonText,
    description,
    documentTitle: getCommonDocumentTitle(loginMode, friendlyAppName, strings.commonLoginStrings),
    requestIsPending,
    setRequestIsPending,
    showButton,
    showChangeUserLink,
    // Event handlers
    ...eventHandlers,
    headerText: getHeaderText(),
  };
};
