import { mergeClasses } from "@griffel/react";
import {
  type ITileFabricProps,
  type TileFabricComponent,
} from "../../../../components/tile/fabric/tile-fabric";
import StylesConfig from "../../../../config/styles-config";
import { Flavors } from "../../../../constants";
import GlobalConfig from "../../../../global-config";
import { useRedirect } from "../../../../hooks/use-redirect";
import { useHandleOnSkip } from "../../../../hooks/win11-oobe-fabric/use-handle-on-skip";
import { type UserCredential, CredentialType } from "../../../../model/credential";
import {
  documentationIcon,
  forgotCredIcon,
  githubCredIcon,
  linkedInCredIcon,
  offlineAccountIcon,
} from "../../../../utilities/image-helpers/accessible-images";
import { useFidoPostRedirect, useFidoProperties } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import { type CommonLoginStrings, type ISignInOptionsStrings } from "../../login-interface";
import { createHelpDialogDescription, createHelpDialogHeader } from "../../login-util";

export interface ISignInOptions {
  fidoProps: ITileFabricProps;
  linkedInProps: ITileFabricProps;
  gitHubProps: ITileFabricProps;
  forgotUsernameProps: ITileFabricProps;
  offlineAccountProps: ITileFabricProps;
}

export const useSignInOptionsProperties = ({
  commonLoginStrings,
  signInOptionsStrings,
}: {
  commonLoginStrings: CommonLoginStrings;
  signInOptionsStrings: ISignInOptionsStrings;
}): ISignInOptions => {
  const {
    linkedInMainText,
    gitHubMainText,
    githubHelpDialogDesc,
    githubHelpDialogDesc2,
    forgotUsernameMainText,
    offlineAccountMainText,
  } = signInOptionsStrings;

  const onSignInOptionClick = useRedirect();
  const fidoRedirectCallback = useFidoPostRedirect();
  const fidoTileClickHandler = () => fidoRedirectCallback();
  const offlineAccountClickHandler = useHandleOnSkip(true);

  const { linkedInFedUrl, githubFedUrl, forgotUsernameUrl } = LoginConfig.instance;

  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();

  const { fidoLinkText, fidoHelpText, fidoDialogHeader, fidoDialogDescription, fidoImage } =
    useFidoProperties(commonLoginStrings);

  const githubCredImage = {
    urls: githubCredIcon,
    dataTestId: "github-img",
  };
  return {
    fidoProps: {
      mainText: fidoLinkText,
      helpText: fidoHelpText,
      image: fidoImage,
      onClick: fidoTileClickHandler,
      ariaLabel: fidoLinkText,
      helpDialogIcon: { urls: documentationIcon, dataTestId: "fido-help" },
      helpDialogHeader: fidoDialogHeader,
      helpDialogDescription: fidoDialogDescription,
    },
    linkedInProps: {
      mainText: linkedInMainText,
      image: { urls: linkedInCredIcon, dataTestId: "linkedin-img" },
      onClick: () => {
        onSignInOptionClick(linkedInFedUrl, true);
      },
      ariaLabel: linkedInMainText,
    },
    gitHubProps: {
      mainText: gitHubMainText,
      image: githubCredImage,
      onClick: () => {
        onSignInOptionClick(githubFedUrl, true);
      },
      ariaLabel: gitHubMainText,
      helpDialogIcon: { urls: documentationIcon, dataTestId: "github-help" },
      helpDialogHeader: createHelpDialogHeader(githubCredImage, gitHubMainText),
      helpDialogDescription: createHelpDialogDescription(
        githubHelpDialogDesc,
        githubHelpDialogDesc2,
        mergeClasses(commonStyles.row, commonStyles.textBody),
      ),
    },
    offlineAccountProps: {
      mainText: offlineAccountMainText,
      image: { urls: offlineAccountIcon, dataTestId: "offline-account-img" },
      onClick: offlineAccountClickHandler,
      ariaLabel: offlineAccountMainText,
    },
    forgotUsernameProps: {
      mainText: forgotUsernameMainText,
      image: { urls: forgotCredIcon, dataTestId: "forgot-username-help" },
      onClick: () => {
        onSignInOptionClick(forgotUsernameUrl, true);
      },
      ariaLabel: forgotUsernameMainText,
    },
  };
};

export const useSignInOptionsBuilder = (
  credentials: UserCredential[],
  {
    commonLoginStrings,
    signInOptionsStrings,
  }: { commonLoginStrings: CommonLoginStrings; signInOptionsStrings: ISignInOptionsStrings },
) => {
  const { activeFlavor } = GlobalConfig.instance;

  const { showForgotUsernameLink, forgotUsernameUrl } = LoginConfig.instance;

  const tileList: TileFabricComponent[] = [];

  const { fidoProps, linkedInProps, gitHubProps, forgotUsernameProps, offlineAccountProps } =
    useSignInOptionsProperties({
      commonLoginStrings,
      signInOptionsStrings,
    });

  credentials.forEach((item) => {
    if (item.credentialType === CredentialType.Fido) {
      tileList.push(fidoProps);
    } else if (item.credentialType === CredentialType.LinkedIn && item.redirectUrl) {
      tileList.push(linkedInProps);
    } else if (item.credentialType === CredentialType.GitHub && item.redirectUrl) {
      tileList.push(gitHubProps);
    } else if (
      activeFlavor === Flavors.Win11OobeFabric &&
      item.credentialType === CredentialType.OfflineAccount
    ) {
      tileList.push(offlineAccountProps);
    }
  });

  if (showForgotUsernameLink && forgotUsernameUrl) {
    tileList.push(forgotUsernameProps);
  }

  return tileList;
};
