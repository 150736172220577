import React from "react";
import { mergeClasses } from "@griffel/react";
import { PageTitleFabric } from "../../../../components/page-title/fabric/page-title-fabric";
import { ViewContainerFabric } from "../../../../components/view-container/fabric/view-container-fabric";
import StylesConfig from "../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../constants";
import { useCustomizationContext } from "../../../../context/customization-context";
import { useActivateView, useDocumentTitle } from "../../../../hooks";
import { commonBindingsFabric } from "../../../../utilities/formatted-text-with-bindings";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import LoginConfig from "../../login-config";
import { getCommonDocumentTitle } from "../../login-util";
import { useBackButtonProperties } from "../hooks/use-back-button-properties";
import { useParagraphWithBindings } from "../hooks/use-paragraph-with-bindings";
import { learnMoreOfflineAccountViewStringsFabric } from "./learn-more-offline-account-strings-fabric";

/**
 * The LearnMoreOfflineAccountViewFabric view is shown during the Login flow when the `Learn More` secondary button
 * on the Username view is clicked.
 *
 * The view uses a few paragraphs to describe what an online Microsoft account is and the benefits of having one. It
 * also informs users about the option to use a local offline account to sign in to Windows 11 instead.
 *
 * @returns A rendered instance of this component
 */
export const LearnMoreOfflineAccountViewFabric: React.FC =
  function LearnMoreOfflineAccountViewFabric() {
    // Styles config data
    const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

    // Login config data
    const { loginMode } = LoginConfig.instance;

    // Customization context properties
    const {
      customizationState: {
        styles: { friendlyAppName },
      },
    } = useCustomizationContext();

    const {
      whatIsAMicrosoftAccountPageTitle,
      oneAccountForAllThingsMicrosoftParagraphSubtitle,
      oneAccountForAllThingsMicrosoftParagraphDescription,
      yourAccountYourDataMoreSecureParagraphSubtitle,
      yourAccountYourDataMoreSecureParagraphDescription,
      notSureIfYouHaveAMicrosoftAccountParagraphSubtitle,
      notSureIfYouHaveAMicrosoftAccountParagraphDescription,
    } = learnMoreOfflineAccountViewStringsFabric;
    const { boldText } = commonBindingsFabric;

    const commonStyles = useCommonStyles();

    const documentTitle = getCommonDocumentTitle(
      loginMode,
      friendlyAppName,
      commonLoginStringsFabric,
    );

    const textSectionId = "learnMoreOfflineAccountSectionId";
    const secondaryButtonProps = useBackButtonProperties(textSectionId, commonLoginStringsFabric);

    useActivateView(
      ViewId.LearnMoreOfflineAccount,
      FlowId.Login,
      {
        isWideView: true,
        showIdentityBanner: false,
        showPageTitle: false,
      },
      { restoreShowPageTitle: true },
    );
    useDocumentTitle(documentTitle);
    useStaticCommonStyles();

    return (
      <ViewContainerFabric secondaryButton={secondaryButtonProps}>
        <div id={textSectionId} className={commonStyles.section}>
          <div
            className={mergeClasses(
              commonStyles.textBody,
              commonStyles.textBlockBody,
              commonStyles.msaAgreementLayout,
            )}
          >
            <PageTitleFabric
              pageTitle={whatIsAMicrosoftAccountPageTitle}
              pageTitleId="learnMoreOfflineAccountPageTitle"
            />

            {useParagraphWithBindings(
              oneAccountForAllThingsMicrosoftParagraphSubtitle,
              oneAccountForAllThingsMicrosoftParagraphDescription,
            )}
            {useParagraphWithBindings(
              yourAccountYourDataMoreSecureParagraphSubtitle,
              yourAccountYourDataMoreSecureParagraphDescription,
            )}
            {useParagraphWithBindings(
              notSureIfYouHaveAMicrosoftAccountParagraphSubtitle,
              notSureIfYouHaveAMicrosoftAccountParagraphDescription,
              { boldText },
            )}
          </div>
        </div>
      </ViewContainerFabric>
    );
  };
