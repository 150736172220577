import { ConfigWrapper } from "../../utilities/config-wrapper";
import { loginStringsFabric } from "./fabric/login-strings-fabric";

// default to fabric
export const defaultLoginStrings = {
  ...loginStringsFabric,
};

export type LoginStrings = typeof defaultLoginStrings;

// eslint-disable-next-line deprecation/deprecation
export const loginStringsConfig = new ConfigWrapper({
  loginStrings: defaultLoginStrings,
});
