import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";

export const confirmSendViewStringsFabric: ConfirmSendViewStrings = {
  changeUserLinkText: getLocalString("Login_SignInWithAnotherAccount"),
  confirmSendDescriptionRemoteNgcASLP: getLocalString("Confirm_Send_Description_RemoteNgc_ASLP"),
  confirmSendDescriptionRemoteNgcForceSignin: getLocalString(
    "Confirm_Send_Description_RemoteNgc_ForceSignin",
  ),
  confirmSendDescriptionRemoteNgc: getLocalString("Confirm_Send_Description_RemoteNgc"),
  confirmSendDescriptionOtcASLP: getLocalString("Confirm_Send_Description_Otc_ASLP"),
  confirmSendDescriptionOtcForceSignin: getLocalString("Confirm_Send_Description_Otc_ForceSignin"),
  confirmSendDescriptionOtcEmail: getLocalString("Confirm_Send_Description_Otc_Email"),
  confirmSendDescriptionPhoneDisambig: getLocalString("Confirm_Send_Description_PhoneDisambig"),
  confirmSendDescriptionRemoteNgcContinueSignin: getLocalString(
    "Confirm_Send_Description_RemoteNgc_ContinueSignin",
  ),
  confirmSendDescriptionRemoteNgcSendNotification: getLocalString(
    "Confirm_Send_Description_RemoteNgc_SendNotification",
  ),
  tfaSendCodeButtonText: getLocalString("TFA_SendCodeButtonText"),
  confirmSendDescriptionOtcSendErrorEmail: getLocalString(
    "Confirm_Send_Description_Otc_SendError_Email",
  ),
  confirmSendDescriptionOtcSendError: getLocalString("Confirm_Send_Description_Otc_SendError"),
  confirmSendNextButton: getLocalString("General_Buttons_Next"),
};
