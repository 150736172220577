import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { FormattedTextWithBindings } from "../../../utilities/formatted-text-with-bindings";
import { type DescriptionProps } from "../description-types";

interface IBaseFabricProps extends Omit<DescriptionProps, "children"> {}

export interface IDescriptionFabricProps extends IBaseFabricProps {
  children: string | React.ReactElement;
  embeddedBindings?: {};
  dataTestId?: string;
}

/**
 * Description component
 * @param props IDescriptionFabricProps
 * @returns a component that renders text styled as a description with embedded bindings, as needed.
 */
export const DescriptionFabric: React.FC<IDescriptionFabricProps> = function DescriptionFabric(
  props: IDescriptionFabricProps,
) {
  const { children, descriptionId, descriptionClass, embeddedBindings, dataTestId } = props;
  if (embeddedBindings && typeof children !== "string") {
    throw new Error("Embedded bindings must be used with a string");
  }

  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const description =
    embeddedBindings && typeof children === "string" ? (
      <FormattedTextWithBindings embeddedBindings={embeddedBindings} text={children} />
    ) : (
      children
    );

  return (
    <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
      <div
        id={descriptionId}
        data-testid={dataTestId}
        className={mergeClasses(
          commonStyles.textBlockBody,
          commonStyles.noMarginTop,
          descriptionClass,
        )}
      >
        {description}
      </div>
    </div>
  );
};
