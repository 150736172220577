import { useCallback, useEffect, useState } from "react";
import { type ExperimentFeature, type TreatmentFlight } from "../config/experiments";
import { ClientExperimentHelper } from "../utilities/client-experimentation/client-experiment-helper";

/**
 * Hook to check if experiment is loaded and if it's in treatment
 * @param parallax Parallax feature/flight value of the experiment
 * @returns treatment and isLoaded values
 */
export const useExperiment = (parallax: ExperimentFeature) => {
  const [treatment, setTreatment] = useState<TreatmentFlight | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchExperimentInfo = useCallback(async () => {
    try {
      const experiment = ClientExperimentHelper.instance.getExperimentByParallax(parallax);

      if (!experiment) {
        setTreatment(undefined);
        setIsLoaded(false);
        return;
      }

      setIsLoaded(false);

      await ClientExperimentHelper.instance.loadPromise;

      setTreatment(ClientExperimentHelper.instance.getTreatmentAssignment(experiment));
      setIsLoaded(true);
    } catch (error) {
      setTreatment(undefined);
      setIsLoaded(true);
    }
  }, [parallax]);

  useEffect(() => {
    fetchExperimentInfo();
  }, [fetchExperimentInfo]);

  return {
    treatment,
    isLoaded,
  };
};

/**
 * Async hook to check if experiment is loaded and if it's in treatment
 * @param parallax Parallax feature/flight value of the experiment
 * @returns treatment and isLoaded values
 */
export const useExperimentAsync = (parallax: ExperimentFeature) => {
  const fetchExperimentInfo = useCallback(
    async () =>
      new Promise((resolve) => {
        try {
          const experiment = ClientExperimentHelper.instance.getExperimentByParallax(parallax);

          if (!experiment) {
            resolve({
              treatment: "",
              isLoaded: false,
            });
            return;
          }

          ClientExperimentHelper.instance.loadPromise.then(() => {
            resolve({
              treatment: ClientExperimentHelper.instance.getTreatmentAssignment(experiment),
              isLoaded: true,
            });
          });
        } catch (error) {
          resolve({
            treatment: "",
            isLoaded: false,
          });
        }
      }),
    [parallax],
  );

  return fetchExperimentInfo;
};
