import { type FormEvent } from "react";
import { type InputState } from "../../../../../components/inputs/hooks/use-input";
import { useGlobalContext } from "../../../../../global-context";
import { getParentSignInPostUrl } from "../../../login-util";
import { usePasswordHandler } from "./use-password-handler";

/**
 * Hook for the handling password submission for Parent sign in
 * @param postUrl Url to navigate to
 * @param inputState Input state for the password input
 * @param hostLoginAction A function for implementing host-specific functionality upon successful sign-in
 * @returns Click handler for the sign in button on password view for Parent sign in
 */
export const usePasswordParentSigninHandler = (
  postUrl: string,
  inputState: InputState,
  hostLoginAction?: (event?: FormEvent<HTMLFormElement>) => void,
) => {
  const {
    globalState: { user, userFlowType },
  } = useGlobalContext();

  const updatedPostUrl = getParentSignInPostUrl(
    postUrl,
    user.displayUsername.safeHtmlEscapedString,
    userFlowType,
  );

  return usePasswordHandler(updatedPostUrl, inputState, { hostLoginAction, keepQsParams: true });
};
