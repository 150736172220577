import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../../config/styles-config";
import ErrorContainerFabric from "../../../error-container/fabric/error-container-fabric";
import { handleDefaultErrorParams } from "../../utilities/handle-default-error-params";
import { Input } from "../input";
import { type BaseInputWithErrorProps } from "../input-types";

export type InputTextDirection = "rtl" | "ltr";

export type InputFabricProps = BaseInputWithErrorProps & {
  /** Customized direction to override the global setting */
  dir?: InputTextDirection;
};

/**
 * This Input component provides an ErrorContainer to optionally display any submit/validation errors associated with the input.
 * @param props
 * Default values:
 * - `hasError`: false
 * - `hasFocus`: false
 * - `hasInitialFocus`: false
 * - `disabled`: false
 * - `maxLength`: 120
 * - `onChange`/`onFocus`/`onBlur`: () => {}
 * - `useBorderStyling`: false
 * - `value`: ""
 * @returns Fabric Input component
 */
export const InputFabric: React.FC<InputFabricProps> = function InputFabric(props) {
  const { dir, customCssWrapper, errorId, enableAriaLiveUpdates, ...textInputProps } = props;
  const { id, customCss } = textInputProps;
  const { useCommonStyles, useTextInputStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const inputStyles = useTextInputStyles();

  const { showErrorMessage, displayErrorStyling, errorMessage } = handleDefaultErrorParams(props);

  return (
    <div
      data-testid="inputComponentWrapper"
      className={mergeClasses(commonStyles.row, customCssWrapper)}
    >
      {showErrorMessage && (
        <ErrorContainerFabric
          id={errorId ?? `${id}Error`}
          enableAriaLiveUpdates={enableAriaLiveUpdates}
        >
          {errorMessage}
        </ErrorContainerFabric>
      )}
      <div dir={dir}>
        <div
          className={mergeClasses(
            commonStyles.formControl,
            commonStyles.formGroup,
            inputStyles.textbox,
            customCss,
          )}
        >
          <Input
            {...textInputProps}
            customCss={mergeClasses(displayErrorStyling ? "has-error" : "", customCss)}
            useBorderStyling
            displayErrorStyling={displayErrorStyling}
          />
        </div>
      </div>
    </div>
  );
};
