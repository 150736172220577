import React, { useEffect, useRef, useState } from "react";
import { useCustomizationContext } from "../../../context/customization-context";
import { useEffectOnce } from "../../../hooks";
import * as styleConstants from "../../../styles/fabric/input-constants-fabric.styles";
import type { BaseInputProps } from "./input-types";

export const INPUT_FOCUS_TIMEOUT = 200;

// The parameters for the base Input component
export type InputProps = BaseInputProps & {
  /** Whether to use the Fabric-style border effects */
  useBorderStyling?: boolean;
};

/**
 * @param props
 * Default values:
 * - `hasError`: false
 * - `hasFocus`: false
 * - `hasInitialFocus`: false
 * - `disabled`: false
 * - `maxLength`: 120
 * - `onChange`/`onFocus`/`onBlur`: () => {}
 * - `useBorderStyling`: false
 * - `value`: ""
 * @returns Flavorless Input component
 */
export const Input: React.FC<InputProps> = function Input(props) {
  const {
    id,
    name,
    placeholder,
    type,
    value = "",
    maxLength = 120,
    hasFocus = false,
    hasInitialFocus = hasFocus,
    displayErrorStyling = false,
    "aria-required": ariaRequired,
    "aria-describedby": ariaDescribedBy,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    disabled = false,
    customCss,
    autoComplete,
    autoCorrect,
    autoCapitalize,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onKeyUp = () => {},
    useBorderStyling = false,
    min,
    max,
    elementRef,
  } = props;

  let inputRef = useRef<HTMLInputElement>(null);
  if (elementRef) {
    inputRef = elementRef;
  }

  const [hover, setHover] = useState(false);

  // Grab the app-branded focus color for the primary button. This will also be the focus color of
  // the underline for the text input box
  const {
    customizationState: {
      styles: {
        accentColors: { primaryButtonDefaultColor },
      },
    },
  } = useCustomizationContext();

  // Determine which colors the text input component's border should be in default, focus and error states
  const borderColorDefault = styleConstants.BORDER_COLOR;
  const borderColorHover = styleConstants.BORDER_COLOR_HOVER;
  const borderColorFocus = primaryButtonDefaultColor || styleConstants.BORDER_COLOR_FOCUS;
  const borderColorError = styleConstants.BORDER_COLOR_FOCUS_HAS_ERROR;

  // These handler functions act as listeners for the `hover` state variable
  const mouseOverHandler = () => setHover(true);
  const mouseOutHandler = () => setHover(false);

  const [currentBorderColor, setBorderColor] = useState(
    hasInitialFocus ? borderColorFocus : borderColorDefault,
  );

  // TODO: Make this a hook
  // Provide initial focus, if requested
  useEffectOnce(() => {
    // Delay focus until view transition animations are completed.
    if (hasInitialFocus) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, INPUT_FOCUS_TIMEOUT);
    }
  });

  // Update focus to match "hasFocus" prop
  useEffect(() => {
    // Delay focus until view transition animations are completed.
    if (hasFocus) {
      const timeoutId = setTimeout(() => {
        inputRef?.current?.focus();
      }, INPUT_FOCUS_TIMEOUT);
      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [hasFocus]);

  useEffect(() => {
    // Apply border color according to component state. Note that the error color takes precedence
    // over all the other colors

    let borderColor = borderColorDefault;
    if (displayErrorStyling) {
      borderColor = borderColorError;
    } else if (hasFocus) {
      borderColor = borderColorFocus;
    } else if (hover) {
      borderColor = borderColorHover;
    }

    setBorderColor(borderColor);
  }, [
    borderColorError,
    hasFocus,
    borderColorFocus,
    hover,
    borderColorHover,
    borderColorDefault,
    displayErrorStyling,
  ]);

  const styleAttribute =
    useBorderStyling && !elementRef ? { borderColor: currentBorderColor } : undefined;

  return (
    <input
      ref={inputRef}
      id={id}
      data-testid={id}
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      maxLength={maxLength}
      disabled={disabled}
      aria-required={ariaRequired}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      className={customCss}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      autoCapitalize={autoCapitalize}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseOver={mouseOverHandler}
      onMouseOut={mouseOutHandler}
      onKeyUp={onKeyUp}
      style={styleAttribute}
      min={min}
      max={max}
    />
  );
};
