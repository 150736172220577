import { makeStyles } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type BackArrowButtonStylesFabric, useBackArrowButtonStylesFabric } from "../fabric";

const useOverrideBackArrowButtonStyles = makeStyles({
  default: {
    ":focus": {
      outlineColor: "none",
      outlineStyle: "none",
      outlineWidth: "none",
    },
  },
});

export type BackArrowButtonStylesWin11OobeFabric = BackArrowButtonStylesFabric &
  Partial<ReturnType<typeof useOverrideBackArrowButtonStyles>>;

export const useBackArrowButtonStylesWin11OobeFabric = () =>
  useMergedStyles(
    useBackArrowButtonStylesFabric(),
    useOverrideBackArrowButtonStyles(),
  ) as BackArrowButtonStylesWin11OobeFabric;
