import React, { useContext, useEffect, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { AuthenticationContext } from "../../../authentication-context";
import { type AccessibleImageProps } from "../../../components/accessible-image";
import StylesConfig from "../../../config/styles-config";
import { Flavors, UserFlowType, ViewId } from "../../../constants";
import featuresConfig from "../../../features-config";
import GlobalConfig from "../../../global-config";
import { GlobalContext, useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { useEffectOnce } from "../../../hooks/use-effect-once";
import { useLocationCache } from "../../../hooks/use-location-cache";
import { useBackButtonControl, useNavigateDirection } from "../../../hooks/use-navigate-direction";
import { redirect as getRedirectFunction, useRedirect } from "../../../hooks/use-redirect";
import { usePostRedirectContext } from "../../../post-redirect-context";
import { PostRedirectActionType } from "../../../post-redirect-reducer";
import { useTelemetry } from "../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../telemetry-helpers/user-action-name";
import {
  type RedirectPostParams,
  getSignupRedirectGctResult,
} from "../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { writeCookie } from "../../../utilities/cookie-helper";
import {
  handleClearPassword,
  logCxhEvent,
  setOobeMsaConsentStringDisplayed,
  showGraphicAnimationFromSource,
} from "../../../utilities/cxh-handlers/cxh-handler";
import { handleFinishState } from "../../../utilities/cxh-handlers/windows/cxh-handler-windows";
import { handleOnFinalBack } from "../../../utilities/cxh-handlers/xbox/cxh-handler-xbox";
import { FormattedTextWithBindings } from "../../../utilities/formatted-text-with-bindings";
import { fidoCredIcon, passkeyCredIcon } from "../../../utilities/image-helpers/accessible-images";
import {
  appendOrReplaceQueryStringParams,
  removeQueryStringParameters,
} from "../../../utilities/strings-helper";
import LoginConfig from "../login-config";
import { useLoginContext } from "../login-context";
import { type CommonLoginStrings } from "../login-interface";
import { LoginActionType } from "../login-reducer";
import { type FidoPostParams, type IBrandingDescriptionProperties } from "../login-types";
import {
  createHelpDialogDescription,
  createHelpDialogHeader,
  getCommonDescription,
  getFidoTexts,
  sanitizePostParams,
  updateMemberNamePrefill,
} from "../login-util";

/**
 * Login hook for making a get or post redirect request.
 * @returns a function that can be used to submit a redirect request (GET/POST)
 */
export const useLoginFlowRedirect = () => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);

  const { isCloudBuild } = GlobalConfig.instance;

  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  const { dispatchStateChange: dispatchRedirectStateChange } = usePostRedirectContext();

  const { flowTokenCookieName } = LoginConfig.instance;

  return (
    redirectUrl: string,
    postParams?: RedirectPostParams,
    isIdpRedirect?: boolean,
    useViewProgress?: boolean,
  ) => {
    if (isIdpRedirect && flowTokenCookieName && flowTokenValue) {
      // When redirecting to a federated IDP we write the flow token to a cookie so that the flow can be resumed after returning to ESTS.
      // We explicitly don't set a domain so that the page's domain is used. The server will clear the cookie after consumption.
      writeCookie(
        flowTokenCookieName,
        flowTokenValue,
        !isCloudBuild,
        false,
        false,
        false,
        undefined,
        undefined,
        true,
      );
    }

    if (postParams && Object.keys(postParams).length) {
      // Set the props of the shell-level PostRedirect component.
      // This will cause the post redirect form in the shell to re-render and submit.
      dispatchRedirectStateChange({
        type: PostRedirectActionType.SubmitPostRedirect,
        payload: {
          url: redirectUrl,
          postParams: sanitizePostParams(postParams || {}),
          submitForm: true,
        },
      });
    } else {
      getRedirectFunction(redirectUrl);
    }

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: !useViewProgress,
    });
  };
};

/**
 * Hook for the signup click handler
 * @returns A method for handling the signup redirect
 */
export const useSignupClickHandler = () => {
  const { activeFlavor, telemetry, clientId } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow, userFlowType },
  } = useGlobalContext();
  const { signupUrl, signupUrlPostParams } = LoginConfig.instance;
  const { isGamingFlow, isSimplifiedChildAccountCreation } = featuresConfig.instance;
  let updatedSignupUrl = signupUrl;
  let actionName = UserActionName.SignupLinkClicked;

  if (isSimplifiedChildAccountCreation) {
    const isParentFlow = userFlowType === UserFlowType.Parent;
    actionName = isParentFlow
      ? UserActionName.SignupForMyChildClicked
      : UserActionName.SignupForMyselfClicked;
    // reset birthdate QS params since new user will not use the child's birthdate from the age gate
    updatedSignupUrl = removeQueryStringParameters(signupUrl, ["bdate"]);

    // Persist QS params for simplified child account creation and gaming flow
    updatedSignupUrl = appendOrReplaceQueryStringParams(updatedSignupUrl, {
      scac: isSimplifiedChildAccountCreation ? "1" : "0",
      gf: isGamingFlow ? "1" : "0",
      uft: isParentFlow ? UserFlowType.AdultWithChild.toString() : userFlowType.toString(),
      client_id: clientId, // needed to pass allowlist experiment in signup
    });
  }

  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const loginFlowRedirect = useLoginFlowRedirect();

  return (unsafeUsername: string) => {
    logUserAction({ actionName });

    const gctResultAction = getSignupRedirectGctResult(
      unsafeUsername,
      updatedSignupUrl,
      signupUrlPostParams,
    );
    loginFlowRedirect(
      gctResultAction.redirectUrl || "",
      gctResultAction.redirectPostParams,
      gctResultAction.isIdpRedirect,
    );
  };
};

/**
 * @param commonLoginStrings common login strings
 * @param isUserKnown whether to show the fido strings for a known user, else show unknown user strings
 * @returns properties related to fido operations
 */
export const useFidoProperties = (
  commonLoginStrings: CommonLoginStrings,
  isUserKnown: boolean = false,
) => {
  const { useCommonStyles } = StylesConfig.instance;
  const { activeFlavor } = GlobalConfig.instance;
  const { fidoHelpUrl, isPasskeySupported } = LoginConfig.instance;

  const commonStyles = useCommonStyles();
  const [fidoTexts, setFidoTexts] = useState([""]);

  useEffect(() => {
    getFidoTexts(commonLoginStrings, activeFlavor, isUserKnown).then((texts) =>
      setFidoTexts(texts),
    );
  }, [commonLoginStrings, isUserKnown, activeFlavor]);

  let fidoLinkText = "";
  let fidoHelpText = "";
  let fidoDialogHeaderText = "";
  let fidoDialogText = "";
  let fidoDialogWithLink;
  const fidoImage: AccessibleImageProps = {
    urls: fidoCredIcon,
    dataTestId: "fido-img",
  };

  const fidoHelpLink = (chunks: string[]) => (
    <a
      id="fidoHelpLink"
      datatest-id="fidoHelpLink"
      href={fidoHelpUrl}
      target="_blank"
      rel="noreferrer"
    >
      {chunks}
    </a>
  );

  if (isPasskeySupported) {
    fidoLinkText = commonLoginStrings.fidoPasskeyLinkText;
    fidoHelpText = commonLoginStrings.fidoPasskeyHelpText;
    fidoDialogHeaderText = commonLoginStrings.fidoPassKeyDialogHeaderText;
    fidoDialogText = commonLoginStrings.fidoPasskeyDialogText;
    fidoImage.urls = passkeyCredIcon;
    fidoDialogWithLink = fidoHelpLink([commonLoginStrings.fidoPasskeyDialogDescription]);
  } else {
    [fidoLinkText, fidoHelpText, fidoDialogText] = [fidoTexts[0], fidoTexts[1], fidoTexts[2]];
    fidoDialogHeaderText = fidoLinkText;
    fidoDialogWithLink = (
      <FormattedTextWithBindings
        text={commonLoginStrings.fidoHelpDialogDesc2}
        embeddedBindings={{ fidoHelpLink }}
      />
    );
  }

  const fidoDialogHeader = createHelpDialogHeader(
    fidoImage,
    fidoDialogHeaderText,
    commonStyles.winFidoImage,
  );
  const fidoDialogDescription = createHelpDialogDescription(
    fidoDialogText,
    fidoDialogWithLink,
    mergeClasses(commonStyles.row, commonStyles.textBody, commonStyles.winFidoDescription),
  );

  return {
    fidoLinkText,
    fidoHelpText,
    fidoDialogHeader,
    fidoDialogDescription,
    fidoImage,
  };
};

/**
 * Hook for creating and returning a handler that submits the Fido post redirect.
 *
 * The handler optionally takes in Fido post params that will be used to override the param values read
 * from contexts/configs. This is helpful when we receive updated values for these params as a result of
 * some user action such as when the user enters an updated username on the username view or when the GCT
 * request returns an updated Fido allow list compared to the one received from the server on page load.
 *
 * @returns A helper method to trigger the Fido post redirect
 */
export const useFidoPostRedirect = () => {
  const {
    allowedIdentities,
    fidoChallenge: fidoChallengeFromServer,
    fidoUseAllowedIdentities,
  } = LoginConfig.instance;

  const {
    context,
    loginUrl,
    postUrl,
    msaPostUrl,
    aadPostUrl,
    resumeUrl,
    fidoLoginUrl,
    correlationId,
  } = GlobalConfig.instance;

  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  const {
    viewState: { fidoAllowList },
  } = useLoginContext();

  const loginFlowRedirect = useLoginFlowRedirect();

  const fidoChallenge = fidoUseAllowedIdentities ? fidoChallengeFromServer : flowTokenValue;

  const baseParams: FidoPostParams = {
    allowedIdentities: JSON.stringify(allowedIdentities),
    canary: fidoChallenge,
    serverChallenge: fidoChallenge,
    postBackUrl: postUrl,
    postBackUrlAad: aadPostUrl,
    postBackUrlMsa: msaPostUrl,
    cancelUrl: loginUrl,
    resumeUrl: resumeUrl || loginUrl,
    correlationId,
    credentialsJson: fidoAllowList,
    ctx: context,
  };

  return (overrideParams?: Partial<FidoPostParams>) => {
    const fidoPostParams = { ...baseParams, ...(overrideParams ?? {}) };
    loginFlowRedirect(fidoLoginUrl, fidoPostParams, false, false);
  };
};

/**
 * @returns flag indicating whether the back (arrow) button should be shown.
 */
export const useShowBackButton = (): boolean => {
  // Global config data
  const { allowCancel, backUrl, showButtons } = GlobalConfig.instance;

  const canGoBack = useBackButtonControl();
  const showBackButton = showButtons && (canGoBack || allowCancel || !!backUrl);

  return showBackButton;
};

/**
 * @returns function that determines logic for "Back" button
 */
export const useOnCancelOrBack = () => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);

  const { activeFlavor, allowCancel, backUrl, cancelUrl, signInUsername } = GlobalConfig.instance;
  const { isScoobe, isTokenBroker, preferAssociate } = LoginConfig.instance;
  const loginFlowRedirect = useLoginFlowRedirect();

  let url = cancelUrl;
  return () => {
    if (allowCancel && cancelUrl) {
      if (signInUsername) {
        // AAD-TODO: Add condition for cancelPostParams and pass in to loginFlowRedirect
        url = appendOrReplaceQueryStringParams(url, { username: signInUsername });
      }

      loginFlowRedirect(url);
    } else if (backUrl) {
      dispatchGlobalStateChange({
        type: GlobalActionType.SetShowProgressIndicator,
        payload: true,
      });
      getRedirectFunction(backUrl, true);
    } else if (activeFlavor === Flavors.Win11OobeFabric) {
      handleFinishState(isScoobe, isTokenBroker, preferAssociate);
    } else if (activeFlavor === Flavors.Xbox) {
      handleOnFinalBack(isTokenBroker, allowCancel);
    }
  };
};

/**
 * This hook is used to clear the password from previous requests
 * @returns function that clears password
 */
export const useHandleClearPassword = () => {
  const { activeFlavor } = GlobalConfig.instance;

  return () => {
    if (activeFlavor === Flavors.Win11OobeFabric) {
      handleClearPassword();
    }
  };
};

/**
 * This hook is used to add the member name to the reset password URL
 * @returns the reset password URL with the member name pre-filled
 */
export const useResetPasswordUrl = (): string => {
  const {
    globalState: {
      user: { displayUsername },
    },
  } = useGlobalContext();
  const { resetPasswordUrl, resetPasswordPrefillParam } = LoginConfig.instance;

  return updateMemberNamePrefill(
    resetPasswordUrl,
    resetPasswordPrefillParam,
    displayUsername.unsafeUnescapedString,
  );
};

/**
 * @param commonLoginStrings common login strings
 * @returns Branding description properties for one of the following views
 *   1. Username view
 *   2. Password view
 *   3. OTC view
 *   4. Remote NGC view
 *   5. IDP Redirect Speedbump view
 */
export const useBrandingDescriptionProperties = (
  commonLoginStrings: CommonLoginStrings,
): IBrandingDescriptionProperties => {
  // Login context data
  const {
    viewState: { showCredViewBrandingDesc },
    dispatchStateChange: dispatchLoginStateChange,
  } = useLoginContext();

  // Login config data
  const { loginDescription, loginStringsVariant } = LoginConfig.instance;

  const [renderBrandingDescription] = useState<boolean>(showCredViewBrandingDesc);
  const brandingDescriptionId = "credViewBrandingDesc";
  const brandingDescription = getCommonDescription(
    loginStringsVariant,
    loginDescription,
    commonLoginStrings,
  );

  // if the branding description is shown during the current render of the active view, it will not be shown
  // in succeeding views or even later renders of the active view.
  if (showCredViewBrandingDesc) {
    dispatchLoginStateChange({
      type: LoginActionType.SetShowCredViewBrandingDesc,
      payload: false,
    });
  }

  return {
    renderBrandingDescription,
    brandingDescriptionId,
    brandingDescription,
  };
};

/**
 * Hook that resets the remoteNgcParams.requestSent property in login context to false. This is used in the
 * password and IDP redirect speedbump views to set the requestSent property to false so that later if the
 * user lands on the remote NGC view we can trigger a code/pin request.
 */
export const useResetRemoteNgcRequestSent = (): void => {
  const {
    viewState: {
      remoteNgcParams: { requestSent },
    },
    dispatchStateChange: dispatchLoginStateChange,
  } = useLoginContext();

  if (requestSent) {
    dispatchLoginStateChange({
      type: LoginActionType.SetRemoteNgcParams,
      payload: { requestSent: false },
    });
  }
};

/**
 * @param currentViewId The current ViewId using the hook
 * @returns A callback that will update the LoginContext with useEvictedCredentials as true before navigating to the CredentialPickerView
 */
export const useSwitchToEvictedCredPicker = (currentViewId: ViewId) => {
  const navigate = useNavigateDirection();
  const { dispatchStateChange: updateLoginContext } = useLoginContext();

  return () => {
    updateLoginContext({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });
    navigate(currentViewId, ViewId.CredentialPicker);
  };
};

/**
 * This hook is used to trigger the "select account" action.
 * @param currentViewId The current ViewId using the hook
 * @returns A callback method that will redirect to the switchUserUrl or navigate to AccountPickerView or the UsernameView
 */
export const useSelectAccount = (currentViewId: ViewId) => {
  const {
    viewState: {
      credentials: { sessions },
    },
  } = useLoginContext();
  const navigate = useNavigateDirection();
  const redirect = useRedirect();

  return () => {
    const { switchUserUrl } = GlobalConfig.instance;
    const { lockUsername } = LoginConfig.instance;
    if (lockUsername && switchUserUrl) {
      redirect(switchUserUrl);
    } else if (sessions.length) {
      navigate(currentViewId, ViewId.AccountPicker);
    } else {
      navigate(currentViewId, ViewId.Username);
    }
  };
};

/**
 * This hook is used to determine whether the CredentialSwitchContentFabric should be rendered and provide the parameter to determine
 * whether the switch to evicted credentials link should be rendered.
 * This hooks using a location cache to store the value of useEvictedCredentials on the initial render of the location (not just the view, but an instance of the view).
 * This is done so that the switch to evicted credentials link is rendered the same when the user navigates back to the location from the CredentialPicker view.
 * Otherwise the view would have updated the Context for the CredentialPicker view and navigating back would not revert that state change (causing incorrect UI).
 * @returns Indicator that the CredentialSwitchContentFabric should be rendered and the indicator that the switch to evicted credentials link should be rendered.
 */
export const useShowCredentialSwitchContent = () => {
  const {
    viewState: {
      credentials: { evictedCredentials, useEvictedCredentials },
    },
  } = useLoginContext();
  const useEvictedCredentialsCache = useLocationCache(
    useEvictedCredentials,
    "useEvictedCredentials",
  );

  return {
    showSwitchToEvictedCredPicker: evictedCredentials.length > 0,
    showCredentialSwitchContent: !useEvictedCredentialsCache,
  };
};

/**
 * This method is used to note that a specific consent string has been displayed to the user on the username view.
 */
export const useSetMsaConsentStringDisplayed = () => {
  const { activeFlavor } = GlobalConfig.instance;
  const { oobeProperties } = LoginConfig.instance;

  const isInitialView = !useBackButtonControl();
  const stringDisplayed =
    isInitialView &&
    activeFlavor === Flavors.Win11OobeFabric &&
    (oobeProperties.oobeDisplayServicesConsent || oobeProperties.oobeDisplayUnifiedConsent);

  useEffectOnce(() => {
    if (stringDisplayed) {
      setOobeMsaConsentStringDisplayed();
    }
  });
};

export const getLottiePath = async (msaServerLottieId: number) => {
  let fullUrl = "";
  if (!Number.isNaN(msaServerLottieId)) {
    logCxhEvent("Identity.OOBE.Login.MsaServerDrivenLottieId", msaServerLottieId);
    try {
      // CXH needs the path of the JSON file, not the contents.
      // Here we use the "file-loader" in webpack to import the path of these JSON files and emit those files to the dist/images folder
      // By using the "eager" webpackMode, webpack will include the value of the path in the bundles rather than creating a separate chunk
      // for the JSON file path. We don't want to have to load a separate chunk just to get the path of the JSON file.
      const { default: lottiePath }: { default: string } = await import(
        /* webpackMode: "eager" */
        `../../../images/msaserversigninlottie_${msaServerLottieId}.json`
      );
      if (lottiePath) {
        if (window.location.hostname.toLowerCase() === "login.live-tst.com") {
          // When we are hosting locally, we receive the relative path to the JSON file, not the entire URL
          fullUrl = `https://${window.location.host}${lottiePath}`;
        } else {
          // When deployed in INT/PROD, the path resolves to the full CDN url
          fullUrl = lottiePath;
        }
      }
    } catch (error) {
      const thrownError = error as Error;
      // Log error if file doesn't exist or causes an error
      logCxhEvent("Identity.OOBE.Login.showGraphicAnimation", thrownError.message);
    }
  } else {
    // TODO: Add warning if msaServerLottieId isn't a number
  }

  return fullUrl;
};

/**
 * This method is used to get and display the given lottie file on the view
 */
export const useShowGraphicAnimation = () => {
  const { activeFlavor } = GlobalConfig.instance;
  const { msaServerLottieId } = LoginConfig.instance;

  useEffectOnce(() => {
    if (activeFlavor === Flavors.Win11OobeFabric) {
      (async () => {
        const url = await getLottiePath(msaServerLottieId);
        if (url) {
          await showGraphicAnimationFromSource(url);
        }
      })();
    }
  });
};
