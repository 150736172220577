import React from "react";
import StylesConfig from "../../../config/styles-config";
import CriticalErrorPage from "../critical-error-page";

/**
 * @returns a flavored component displaying a critical error that's displayed when the expected UX
 * cannot render due to an error.
 */
const CriticalErrorPageFabric: React.FC = function CriticalErrorPageFabric() {
  const { errorTitleStyles, errorComponentStyles } =
    StylesConfig.instance.useCriticalErrorPageStyles();

  const { outer } = StylesConfig.instance.useCommonStyles();

  return (
    <CriticalErrorPage
      errorTitle={getLocalString("General_SomethingWentWrong")}
      titleStyleName={errorTitleStyles}
      errorDescription={getLocalString("General_PleaseTryAgain")}
      containerStyleName={errorComponentStyles}
      outerClassName={outer}
    />
  );
};

export default CriticalErrorPageFabric;
