import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../global-context";
import { cleanseUsername } from "../../../../../model/user";
import { useFidoPostRedirect } from "../../../hooks/login-hooks";
import { LoginContext } from "../../../login-context";
import { type CommonLoginStrings } from "../../../login-interface";
import { type ICredentialPickerStrings } from "../credential-picker-interface";
import { type ICredentialPickerViewProperties } from "./use-credential-picker-tile-list-builder";

export const useCredentialPickerViewProperties = (strings: {
  commonLoginStrings: CommonLoginStrings;
  credentialPickerStrings: ICredentialPickerStrings;
}): ICredentialPickerViewProperties => {
  const {
    credentialPickerStrings: { credPickerTitle },
    commonLoginStrings: { backButton },
  } = strings;

  const {
    globalState: {
      user: { username },
    },
  } = useContext(GlobalContext);

  // login state data
  const {
    viewState: {
      credentials: { availableCredentials },
    },
  } = useContext(LoginContext);

  const fidoRedirectCallback = useFidoPostRedirect();
  const [error, setError] = useState<string | JSX.Element>("");

  return {
    availableCredentials,
    backLabel: backButton,
    redirect: () =>
      fidoRedirectCallback({ username: cleanseUsername(username.unsafeUnescapedString) }),
    title: credPickerTitle,
    error,
    setError,
  };
};
