import React from "react";
import GlobalConfig from "../global-config";

type DirectionWrapperProps = React.PropsWithChildren<{
  /** Optional class name to add height to the direction wrapper. */
  directionWrapperClassName?: string;
  /** Optional test ID to add to the div */
  testID?: string;
  /** Optional id to add to the div */
  id?: string;
}>;

/**
 * DirectionWrapper component
 * @param props DirectionWrapperProps properties for this component
 * @param props.children Child components to render in the direction wrapper
 * @param props.testID Optional test ID to add to the div
 * @param props.directionWrapperClassName Optional class name to add height to the direction wrapper.
 * @param props.id Optional id to add to the div
 * @returns DirectionWrapper component to provide `rtl` functionality
 */
export const DirectionWrapper: React.FC<DirectionWrapperProps> = function DirectionWrapper({
  children,
  directionWrapperClassName,
  testID,
  id,
}) {
  const { isRtlMarket } = GlobalConfig.instance;

  const testIds = ["directionWrapper"];
  if (testID) {
    testIds.push(testID);
  }

  return (
    <div
      id={id}
      dir={isRtlMarket ? "rtl" : ""}
      data-testid={testIds.join(" ")}
      className={directionWrapperClassName}
    >
      {children}
    </div>
  );
};
