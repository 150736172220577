import { makeStyles, shorthands } from "@griffel/react";

export const useOptionsMenuButtonStylesFabric = makeStyles({
  menu: {
    position: "absolute",
    backgroundColor: "#fff",
    backgroundClip: "paddingBox",
    ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.1)"),
    zIndex: 2,
    top: 0,
    right: "10px",
    width: "160px",
    marginTop: "20px",
    marginBottom: "20px",
    ...shorthands.padding("0px"),
    ":global([dir='rtl'])": {
      left: "10px",
      right: "unset",
    },
  },
  menuButtonImageStyle: {
    backgroundColor: "transparent",
    ...shorthands.borderWidth("0px"),
    cursor: "pointer",
    minWidth: "fit-content",
    ...shorthands.padding("0px"),
    width: "100%",
  },
  accessibleImageAlign: {
    verticalAlign: "middle",
  },
  menuMargin: {
    ...shorthands.margin("0px"),
  },
  menuList: {
    ...shorthands.margin("0px"),
    ...shorthands.padding("0px"),
  },
  menuLink: {
    ...shorthands.borderWidth("0px"),
    width: "100%",
    display: "block",
    paddingTop: "11px",
    paddingBottom: "13px",
    paddingLeft: "12px",
    paddingRight: "12px",
    backgroundColor: "rgba(0,0,0,.05)",
    color: "inherit",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#e6e6e6;",
      cursor: "pointer",
      color: "inherit",
    },
    ":focus": {
      backgroundColor: "rgba(0,0,0,.1)",
      outlineWidth: "1px",
      outlineColor: "#000",
      outlineStyle: "dashed",
    },
    ":active": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
});

export type OptionsMenuButtonStylesFabric = ReturnType<typeof useOptionsMenuButtonStylesFabric>;
