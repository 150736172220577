import { ViewId } from "../../../../constants/routing-constants";
import { useGlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import {
  type ICanaryValidationRequest,
  invokeCanaryValidation,
} from "../../../../utilities/api-helpers/canary-validation-request";
import LoginConfig from "../../login-config";
import {
  CanaryValidationActionId,
  CanaryValidationPageId,
  LoginState,
} from "../../login-constants";

/**
 * Validate external canary and return the result
 * @returns function for the external canary validation
 */
export const useValidateExternalCanary = () => {
  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();
  const { externalCanary, canaryValidationUrl, isRemoteConnectSignup } = LoginConfig.instance;

  const params: ICanaryValidationRequest = {
    PageId: CanaryValidationPageId,
    ActionId: CanaryValidationActionId,
    ConfirmationViewId: LoginState.RemoteConnectCanaryValidation,
    Canary: externalCanary,
  };

  return async () => {
    try {
      // Show the progress indicator until we validate the canary
      dispatchGlobal({
        type: GlobalActionType.BeginNavigate,
        source: ViewId.None,
      });

      await invokeCanaryValidation(canaryValidationUrl, params);

      // Succeded
      if (isRemoteConnectSignup) {
        return LoginState.PostRedirect;
      }

      return LoginState.Unknown;
    } catch (error) {
      // Return RemoteConnectCanary view
      return LoginState.RemoteConnectCanaryValidation;
    } finally {
      // Hide the progress indicator
      dispatchGlobal({
        type: GlobalActionType.DataLoaded,
        view: ViewId.None,
      });
    }
  };
};
