import { type RefObject, useEffect } from "react";
import type { GamepadButtonEvent } from "@gaming/react-gamepad-navigation-lite";
import { useGlobalContext } from "../global-context";

/**
 * Hook to subscribe a callback to the 'B' button being triggered for cancellation
 * @param element element listening for gamepad events
 * @param onCancel callback to execute when the 'B' button is pressed
 */

export const useGamepadCancel = (element: RefObject<HTMLElement>, onCancel: Function) => {
  const {
    globalState: { gamepadManager },
  } = useGlobalContext();

  useEffect(() => {
    const currentElement = element?.current;
    const gamepadEventName = gamepadManager?.getGamepadButtonEventType(
      gamepadManager?.GamepadAction.Down,
    );
    const onButtonEvent = (e: Event) => {
      const gamepadButtonEvent = e as GamepadButtonEvent;
      if (gamepadManager && gamepadButtonEvent?.button === gamepadManager!.GamepadButton.B) {
        onCancel();
      }
    };

    if (currentElement && gamepadManager && gamepadEventName) {
      currentElement.addEventListener(gamepadEventName, onButtonEvent);
    }

    return () => {
      if (currentElement && gamepadManager && gamepadEventName) {
        currentElement.removeEventListener(gamepadEventName, onButtonEvent);
      }
    };
  }, [gamepadManager, element, onCancel]);
};
