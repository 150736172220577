import { type ICredentialPickerStrings } from "../credential-picker-interface";

/**
 * @returns Credential picker view strings
 */
export const credentialPickerStringsFabric: ICredentialPickerStrings = {
  githubAriaLabel: getLocalString("Login_CredPicker_Help_Desc_GitHub"),
  githubButtonLabel: getLocalString("Login_UseGitHub_Link"),
  linkedinLabel: getLocalString("Login_UseLinkedIn_Link"),
  microsoftAppLabel: getLocalString("Login_PushNotifications_CredPicker_Option_App_Generic"),
  offlineAccountLabel: getLocalString("Login_UseOfflineAccount_Link"),
  oneTimeCodeEmailLabel: getLocalString("Send_Ott_Email"),
  oneTimeCodeSMSLabel: getLocalString("Send_Ott_Sms"),
  oneTimeCodeVoiceLabel: getLocalString("Send_Ott_Voice"),
  passwordLabel: getLocalString("Login_CredPicker_Option_Password"),
  personalAccountsOnlyHelpText: getLocalString("General_PersonalAccountsOnly"),
  remoteNgcLabel: getLocalString("Login_CredPicker_Option_App"),
  sessionExpiredError: getLocalString("OneTimeCode_SessionExpiredError"),
  sendFailedError: getLocalString("OneTimeCode_SendFailedError"),
  credPickerTitle: getLocalString("Login_CredPicker_Title"),
};
