import { useState } from "react";
import { type IDialogProperties } from "../push-notifications-view-interface";

/**
 * Returns properties for the push notification dialog that is shown when the "See all devices" link is clicked
 * @param setSeeAllDevicesLinkFocus Callback to update whether the "See all devices" link has focus
 * @returns Properties for rendering the push notifications dialog
 */
export const useDialogProperties = (
  setSeeAllDevicesLinkFocus: (seeAllDevicesLinkFocus: boolean) => void,
): IDialogProperties => {
  const [showDialog, setShowDialog] = useState(false);

  const onDialogClose = () => {
    setShowDialog(false);
    setSeeAllDevicesLinkFocus(true); // reset focus on the devices link when the dialog is closed
  };

  const seeAllDevicesLinkOnClick = () => {
    setShowDialog(true);
    setSeeAllDevicesLinkFocus(false);
  };

  return {
    seeAllDevicesLinkOnClick,
    onDialogClose,
    showDialog,
  };
};
