import { Flavors } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { useEffectOnce } from "../../../../../hooks";
import { logCxhEvent } from "../../../../../utilities/cxh-handlers/cxh-handler";
import LoginConfig from "../../../login-config";

export const useUsernameLogEvents = () => {
  const { activeFlavor } = GlobalConfig.instance;

  const {
    hideOfflineAccountNewTitleString,
    isTokenBroker,
    preferAssociate,
    offlineAccountVisible,
  } = LoginConfig.instance;

  useEffectOnce(() => {
    if (activeFlavor === Flavors.Win11OobeFabric) {
      logCxhEvent(
        "Identity.Flight.IsEnabledHideOfflineAccountNewTitleString",
        hideOfflineAccountNewTitleString,
      );

      if (isTokenBroker) {
        const hostEventName = preferAssociate
          ? "MSA.AssociateUpsell.UsernamePageLoad"
          : "MSA.ConnectUpsell.UsernamePageLoad";
        logCxhEvent(hostEventName);
      }

      if (!offlineAccountVisible) {
        logCxhEvent("Identity.OOBE.Login.OfflineAccountLink.Hidden");
      }
    }
  });
};
