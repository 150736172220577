import { useState } from "react";
import { useEffectOnce, useNavigateBack } from "../../../../hooks";
import LoginConfig from "../../login-config";
import type { CommonLoginStrings } from "../../login-interface";
import type {
  ISdxIFrameInlineStyles,
  ISeeHowDataIsManagedViewProperties,
} from "../see-how-data-is-managed-view-interface";
import { computeAdjustedIFrameHeight } from "../see-how-data-is-managed-view-util";

/**
 * @param commonLoginStrings Common login strings shared across login views
 * @returns The view properties for the SeeHowDataIsManaged view
 */
export const useSeeHowDataIsManagedViewProperties = (
  commonLoginStrings: CommonLoginStrings,
): ISeeHowDataIsManagedViewProperties => {
  const {
    oobeProperties: { oobeServicesInformationLink },
  } = LoginConfig.instance;

  const sdxIFrameSource = oobeServicesInformationLink || "";
  const [renderProgressIndicator, setRenderProgressIndicator] = useState<boolean>(true);
  const [sdxIFrameInlineStyles, setSdxIFrameInlineStyles] = useState<ISdxIFrameInlineStyles>({
    height: "0px",
    visibility: "hidden",
  });

  const secondaryButtonLabel = commonLoginStrings.backButton;
  const secondaryButtonClickHandler = useNavigateBack();

  // Flexbox doesn't work as expected in edgehtml and as a result, the iframe doesn't fill the container.
  // As a workaround, we simply have the contentDocument of the iframe calculate the height upon load,
  // and send it back via postMessage as a simple string. Note that the postMessage call exists in the
  // client-side code for the sdx.microsoft.com url serving the iframe content
  const iFrameMessageEventListener = (ev: MessageEvent): void => {
    const expectedOrigin = new URL(sdxIFrameSource).origin;
    // Only process events from the expected origin to be extra safe
    if (ev.origin === expectedOrigin) {
      setSdxIFrameInlineStyles({
        height: computeAdjustedIFrameHeight(ev.data as string),
        visibility: "visible",
      });

      // Disable the progress indicator once the iframe is ready and visible
      setRenderProgressIndicator(false);
    }
  };

  useEffectOnce(() => {
    window.addEventListener("message", iFrameMessageEventListener, false);

    // Remove the message event listener on unmount
    return () => window.removeEventListener("message", iFrameMessageEventListener, false);
  });

  return {
    renderProgressIndicator,
    sdxIFrameInlineStyles,
    sdxIFrameSource,
    secondaryButtonLabel,
    secondaryButtonClickHandler,
  };
};
