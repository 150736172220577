import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

/**
 * Convert pixels to em using a fixed base font size
 * This conversion prevents the page content from overlapping the banner when the zoom and font size are at maximum.
 * Without this conversion, visually impaired users who zoom into the OneDrive or other app will not see the username.
 * @param px the pixel value to convert
 * @param baseFontSize the base font size to use for the conversion
 * @returns the em value
 */
const pixelsToEm = (px: number, baseFontSize = styleConstants.BASE_FONT_SIZE) =>
  `${px / baseFontSize}em`;

export const useIdentityBannerStylesFabric = makeStyles({
  identityBanner: {
    height: `${styleConstants.IDENTITY_BANNER_HEIGHT}px`,
    ...shorthands.margin(
      `${styleConstants.FORM_GROUP_MARGIN}px`,
      0,
      `${styleConstants.FORM_GROUP_MARGIN}px`,
    ),
    lineHeight: `${styleConstants.IDENTITY_BANNER_HEIGHT}px`,
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
  identityBannerResponsive: {
    height: pixelsToEm(styleConstants.IDENTITY_BANNER_HEIGHT),
    ...shorthands.margin(
      pixelsToEm(styleConstants.FORM_GROUP_MARGIN),
      0,
      pixelsToEm(styleConstants.FORM_GROUP_MARGIN),
    ),
    lineHeight: pixelsToEm(styleConstants.IDENTITY_BANNER_HEIGHT),
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});

export type IdentityBannerStylesFabric = ReturnType<typeof useIdentityBannerStylesFabric>;
