import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName, gamepadMainContentAttributes } from "../../../constants";
import { EnvironmentName } from "../../../constants/environmentbanner-constants";
import { useCustomizationContext } from "../../../context/customization-context";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { isCssAnimationSupported } from "../../../utilities/browser-helper";
import BackgroundFabric from "../../background/fabric/background-fabric";
import { DirectionWrapper } from "../../direction-wrapper";
import EnvironmentBannerFabric from "../../environment-banner/fabric/environment-banner-fabric";
import Footer from "../../footer/fabric/footer-fabric";
import GamepadNavigationWrapper from "../../gamepad-navigation-wrapper";
import Header from "../../header";
import LayoutContentFabric, { useLayoutContentStylesFabric } from "./layout-content-fabric";

type VerticalSplitLayoutProps = {
  styles: {
    disableLightbox: string;
    inner: string;
    innerWithAppBranding: string;
    lightboxCover: string;
    middle: string;
    outer: string;
    paginationView: string;
    paginationViewWithIdentityBanner: string;
    wide: string;
    transparent: string;
  };
};

/**
 * @param root - Component properties
 * @param root.styles - CSS class names to apply to specific DOM elements
 * @param root.children - Child content for the layout, typically a view
 * @returns Vertical-split layout component
 */
const VerticalSplitLayoutFabric: React.FC<VerticalSplitLayoutProps> =
  function VerticalSplitLayoutFabric({ styles, children }) {
    const {
      globalState,
      globalState: { isWideView },
    } = useContext(GlobalContext);
    const {
      customizationState: {
        styles: { backgroundLogoUrl, showHeader, useTransparentLightbox },
      },
    } = useCustomizationContext();
    const { useVerticalSplitStyles, useLayoutAnimateStyles, useCommonStyles } =
      StylesConfig.instance;
    const [showDebugInfo, setShowDebugInfo] = useState(false);
    const [focusOnDebugCopyLink, setFocusOnDebugCopyLink] = useState(false);
    const [focusOnEllipsis, setFocusOnEllipsis] = useState(false);
    const splitStyles = useVerticalSplitStyles();
    const { directionWrapper } = useCommonStyles();

    const animateStyles = useLayoutAnimateStyles();
    const animationSupported = isCssAnimationSupported();

    const mainContainerClassNames = mergeClasses(
      splitStyles.mainContainer,
      ExternalClassName.verticalSplitMainSection,
    );
    const backgroundContainerClassNames = mergeClasses(
      splitStyles.backgroundContainer,
      ExternalClassName.verticalSplitBackgroundImageContainer,
    );
    let innerClassNames = mergeClasses(
      styles.inner,
      splitStyles.lightbox,
      isWideView ? styles.wide : "",
      useTransparentLightbox ? styles.transparent : "",
      // @TODO: Figure out a way to detect app-branding based on all app-branding server data properties
      //        and not just the background url logo. Currently this is responsible for a CSS bug where
      //        there is no spacing between the lightbox / vertical split layout and the sign-in options
      //        tile if the branding for the app does not include a logo but does include a friendly app
      //        name and/or sign-in/sign-up descriptions.
      backgroundLogoUrl ? styles.innerWithAppBranding : "",
    );

    // Add animation styles for lightbox/inner container when animation is supported
    if (animationSupported) {
      innerClassNames = mergeClasses(innerClassNames, animateStyles.fadeIn);
    }

    const { bannerEnvironment } = GlobalConfig.instance;
    const shouldShowBanner = bannerEnvironment !== EnvironmentName.None && bannerEnvironment;

    return (
      <DirectionWrapper directionWrapperClassName={directionWrapper} testID="vertical-split-layout">
        <GamepadNavigationWrapper>
          <div
            id="outer"
            data-testid="outer"
            className={styles.outer}
            role="presentation"
            {...gamepadMainContentAttributes}
          >
            {shouldShowBanner && <EnvironmentBannerFabric />}
            <Header />
            <div data-testid="main-section" className={splitStyles.mainSection}>
              <div data-testid="vertical-split-main-container" className={mainContainerClassNames}>
                <div
                  className={splitStyles.verticalContainer}
                  data-testid="vertical-split-main-section"
                >
                  <div
                    className={backgroundContainerClassNames}
                    data-testid="vertical-split-background-image-container"
                  >
                    <BackgroundFabric />
                  </div>
                  <div
                    className={splitStyles.lightboxContainer}
                    data-testid="vertical-lightbox-container"
                    role="main"
                  >
                    <LayoutContentFabric
                      focusOnDebugCopyLink={focusOnDebugCopyLink}
                      setFocusOnEllipsis={setFocusOnEllipsis}
                      showDebugInfo={showDebugInfo}
                      setShowDebugInfo={setShowDebugInfo}
                      styles={useLayoutContentStylesFabric(
                        globalState,
                        innerClassNames,
                        showHeader,
                      )}
                    >
                      {children}
                    </LayoutContentFabric>
                  </div>
                </div>
                <Footer
                  isDebugInfoShown={showDebugInfo}
                  focusOnEllipsis={focusOnEllipsis}
                  onDebugItemClick={(e) => {
                    e.preventDefault();
                    setFocusOnDebugCopyLink(true);
                    setShowDebugInfo(!showDebugInfo);
                  }}
                />
              </div>
            </div>
          </div>
        </GamepadNavigationWrapper>
      </DirectionWrapper>
    );
  };

export default VerticalSplitLayoutFabric;
