import { ViewId } from "../../../../../constants/routing-constants";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { type ISession } from "../../../../../model/user";
import { type LoginActions, LoginActionType } from "../../../login-reducer";

/**
 * Hook that forgets a session by removing it from sessions array, and also navigates back to username view if there are no more sessions
 * @returns hook for forgetting a session
 */
export const useForgetSessionHandler = () => {
  const navigator = useNavigateDirection();

  return async (
    sessions: ISession[],
    dispatchLoginStateChange: React.Dispatch<LoginActions>,
    sessionId?: string,
  ) => {
    const updatedSessions = sessions.filter((session) => session.id !== sessionId);

    dispatchLoginStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { sessions: updatedSessions },
    });

    if (updatedSessions.length === 0) {
      navigator(ViewId.AccountPicker, ViewId.Username);
    }
  };
};

/**
 * Hook that either signs out or signs out & forgets a session
 * @returns hook for signing out of a session
 */
export const useSignOutOrForgetSessionHandler = () => {
  const forgetSession = useForgetSessionHandler();

  return async (
    event: MessageEvent,
    sessions: ISession[],
    dispatchLoginStateChange: React.Dispatch<LoginActions>,
  ) => {
    const eventData = event.data;
    let updatedSessions = sessions;
    // This if condition specifically checks if it is an isolated "forget" event or part of a "sign out and forget" event.
    if ((eventData.messageType === "msaForgetMe" && eventData.forgotUser) || eventData.forgotUser) {
      // remove session completely for forget case
      await forgetSession(sessions, dispatchLoginStateChange, eventData.sessionId);
    } else {
      // update isSignedIn to false
      updatedSessions = sessions.map((session) =>
        session.id === eventData.sessionId ? { ...session, isSignedIn: false } : session,
      );

      dispatchLoginStateChange({
        type: LoginActionType.UpdateCredentials,
        payload: { sessions: updatedSessions },
      });
    }
  };
};
