import React, { useRef } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { useFocusElement } from "../hooks/use-focus-element";

type LinkButtonRole = "button" | "menuitem" | "option";

export interface ILinkButtonProps {
  /** The aria label for the link */
  ariaLabel?: string;
  /** The ids of the labels or elements that are shorter label for context on the link */
  ariaLabelledBy?: string;
  /** The ids of the labels or elements that are lengthier describes the link */
  ariaDescribedBy?: string;
  /** The role of the button */
  buttonRole?: LinkButtonRole;
  /** Whether the current item is selected. See aria-selected */
  ariaSelected?: boolean;
  /** The link button data test ID. */
  linkDataTestId?: string;
  /** The link button id. You probably don't need this. */
  linkId?: string;
  /** If the link has initial focus or not (false by default) */
  hasInitialFocus?: boolean;
  /** Whether the element currently has focus or not (false by default) */
  hasFocus?: boolean;
  /** Whether the element should immediately focus instead of delaying */
  skipHasFocusDelay?: boolean;
  /** Styles class to apply to the button */
  className?: string;
  /** Styles attribute */
  style?: Object;
  /** The link button display text */
  text: string;
  /** The click event handler */
  onClick: (event: React.SyntheticEvent<HTMLSpanElement>) => void;
  /** The focus event handler */
  onFocus?: (event: React.SyntheticEvent<HTMLSpanElement>) => void;
  /** The blur event handler */
  onBlur?: (event: React.SyntheticEvent<HTMLSpanElement>) => void;
}

/**
 * Link Button component
 * @param props The properties for this component
 * @returns an instance of the shared link button component
 */
export const LinkButton: React.FC<ILinkButtonProps> = function LinkButton(props: ILinkButtonProps) {
  const {
    text,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    buttonRole,
    ariaSelected,
    linkDataTestId,
    linkId,
    onClick,
    className,
    style = {},
    hasInitialFocus = false,
    hasFocus = false,
    skipHasFocusDelay = false,
    onFocus = () => {},
    onBlur = () => {},
  } = props;

  const { useLinkButtonStyles } = StylesConfig.instance;
  const linkStyles = useLinkButtonStyles();

  const linkClassName = mergeClasses(
    linkStyles.link,
    linkStyles.noPadding,
    linkStyles.highContrast,
    className,
  );

  const elementReference = useRef<HTMLSpanElement>(null);
  useFocusElement(elementReference, hasInitialFocus, hasFocus, skipHasFocusDelay);

  const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick(event);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      role={buttonRole || "button"}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-selected={ariaSelected}
      ref={elementReference}
      id={linkId}
      data-testid={linkDataTestId}
      className={linkClassName}
      style={style}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {text}
    </span>
  );
};
