import appLogo_0_png from "../../images/AppLogos/0.png";
import appLogo_1_png from "../../images/AppLogos/1.png";
import appLogo_2_png from "../../images/AppLogos/2.png";
import appLogo_3_png from "../../images/AppLogos/3.png";
import appLogo_4_png from "../../images/AppLogos/4.png";
import appLogo_5_png from "../../images/AppLogos/5.png";
import appLogo_6_png from "../../images/AppLogos/6.png";
import appLogo_7_png from "../../images/AppLogos/7.png";
import appLogo_8_png from "../../images/AppLogos/8.png";
import appLogo_10_png from "../../images/AppLogos/10.png";
import appLogo_11_png from "../../images/AppLogos/11.png";
import appLogo_12_png from "../../images/AppLogos/12.png";
import appLogo_13_png from "../../images/AppLogos/13.png";
import appLogo_14_png from "../../images/AppLogos/14.png";
import appLogo_15_png from "../../images/AppLogos/15.png";
import appLogo_17_png from "../../images/AppLogos/17.png";
import appLogo_18_png from "../../images/AppLogos/18.png";
import appLogo_19_png from "../../images/AppLogos/19.png";
import appLogo_20_png from "../../images/AppLogos/20.png";
import appLogo_21_png from "../../images/AppLogos/21.png";
import appLogo_22_png from "../../images/AppLogos/22.png";
import appLogo_23_png from "../../images/AppLogos/23.png";
import appLogo_24_png from "../../images/AppLogos/24.png";
import appLogo_25_png from "../../images/AppLogos/25.png";
import appLogo_26_png from "../../images/AppLogos/26.png";
import appLogo_27_png from "../../images/AppLogos/27.png";
import appLogo_28_png from "../../images/AppLogos/28.png";
import appLogo_29_png from "../../images/AppLogos/29.png";
import appLogo_30_png from "../../images/AppLogos/30.png";
import appLogo_31_png from "../../images/AppLogos/31.png";
import appLogo_32_png from "../../images/AppLogos/32.png";
import appLogo_33_png from "../../images/AppLogos/33.png";
import appLogo_34_png from "../../images/AppLogos/34.png";
import appLogo_35_png from "../../images/AppLogos/35.png";
import appLogo_36_png from "../../images/AppLogos/36.png";
import appLogo_37_png from "../../images/AppLogos/37.png";
import appLogo_38_png from "../../images/AppLogos/38.png";
import appLogo_39_png from "../../images/AppLogos/39.png";
import appLogo_40_png from "../../images/AppLogos/40.png";
import appLogo_41_png from "../../images/AppLogos/41.png";
import appLogo_42_png from "../../images/AppLogos/42.png";
import appLogo_43_png from "../../images/AppLogos/43.png";
import appLogo_44_png from "../../images/AppLogos/44.png";
import appLogo_45_png from "../../images/AppLogos/45.png";
import appLogo_46_png from "../../images/AppLogos/46.png";
import appLogo_47_png from "../../images/AppLogos/47.png";
import appLogo_48_png from "../../images/AppLogos/48.png";
import appLogo_49_png from "../../images/AppLogos/49.png";
import appLogo_50_png from "../../images/AppLogos/50.png";
import appLogo_51_png from "../../images/AppLogos/51.png";
import appLogo_52_png from "../../images/AppLogos/52.png";
import appLogo_53_png from "../../images/AppLogos/53.png";
import appLogo_54_png from "../../images/AppLogos/54.png";
import appLogo_55_png from "../../images/AppLogos/55.png";
import appLogo_56_png from "../../images/AppLogos/56.png";
import appLogo_57_png from "../../images/AppLogos/57.png";
import appLogo_58_png from "../../images/AppLogos/58.png";
import appLogo_59_png from "../../images/AppLogos/59.png";
import appLogo_60_png from "../../images/AppLogos/60.png";
import appLogo_61_png from "../../images/AppLogos/61.png";
import appLogo_62_png from "../../images/AppLogos/62.png";
import appLogo_63_png from "../../images/AppLogos/63.png";
import appLogo_64_png from "../../images/AppLogos/64.png";
import appLogo_65_png from "../../images/AppLogos/65.png";
import appLogo_66_png from "../../images/AppLogos/66.png";
import appLogo_67_png from "../../images/AppLogos/67.png";
import appLogo_68_png from "../../images/AppLogos/68.png";
import appLogo_69_png from "../../images/AppLogos/69.png";
import appLogo_70_png from "../../images/AppLogos/70.png";
import appLogo_71_png from "../../images/AppLogos/71.png";
import appLogo_72_png from "../../images/AppLogos/72.png";
import appLogo_73_png from "../../images/AppLogos/73.png";
import appLogo_74_png from "../../images/AppLogos/74.png";
import appLogo_75_png from "../../images/AppLogos/75.png";
import appLogo_76_png from "../../images/AppLogos/76.png";
import appLogo_77_png from "../../images/AppLogos/77.png";

export const appLogos = new Map<string, string>([
  ["appLogo_0_png", appLogo_0_png],
  ["appLogo_1_png", appLogo_1_png],
  ["appLogo_2_png", appLogo_2_png],
  ["appLogo_3_png", appLogo_3_png],
  ["appLogo_4_png", appLogo_4_png],
  ["appLogo_5_png", appLogo_5_png],
  ["appLogo_6_png", appLogo_6_png],
  ["appLogo_7_png", appLogo_7_png],
  ["appLogo_8_png", appLogo_8_png],
  ["appLogo_10_png", appLogo_10_png],
  ["appLogo_11_png", appLogo_11_png],
  ["appLogo_12_png", appLogo_12_png],
  ["appLogo_13_png", appLogo_13_png],
  ["appLogo_14_png", appLogo_14_png],
  ["appLogo_15_png", appLogo_15_png],
  ["appLogo_17_png", appLogo_17_png],
  ["appLogo_18_png", appLogo_18_png],
  ["appLogo_19_png", appLogo_19_png],
  ["appLogo_20_png", appLogo_20_png],
  ["appLogo_21_png", appLogo_21_png],
  ["appLogo_22_png", appLogo_22_png],
  ["appLogo_23_png", appLogo_23_png],
  ["appLogo_24_png", appLogo_24_png],
  ["appLogo_25_png", appLogo_25_png],
  ["appLogo_26_png", appLogo_26_png],
  ["appLogo_27_png", appLogo_27_png],
  ["appLogo_28_png", appLogo_28_png],
  ["appLogo_29_png", appLogo_29_png],
  ["appLogo_30_png", appLogo_30_png],
  ["appLogo_31_png", appLogo_31_png],
  ["appLogo_32_png", appLogo_32_png],
  ["appLogo_33_png", appLogo_33_png],
  ["appLogo_34_png", appLogo_34_png],
  ["appLogo_35_png", appLogo_35_png],
  ["appLogo_36_png", appLogo_36_png],
  ["appLogo_37_png", appLogo_37_png],
  ["appLogo_38_png", appLogo_38_png],
  ["appLogo_39_png", appLogo_39_png],
  ["appLogo_40_png", appLogo_40_png],
  ["appLogo_41_png", appLogo_41_png],
  ["appLogo_42_png", appLogo_42_png],
  ["appLogo_43_png", appLogo_43_png],
  ["appLogo_44_png", appLogo_44_png],
  ["appLogo_45_png", appLogo_45_png],
  ["appLogo_46_png", appLogo_46_png],
  ["appLogo_47_png", appLogo_47_png],
  ["appLogo_48_png", appLogo_48_png],
  ["appLogo_49_png", appLogo_49_png],
  ["appLogo_50_png", appLogo_50_png],
  ["appLogo_51_png", appLogo_51_png],
  ["appLogo_52_png", appLogo_52_png],
  ["appLogo_53_png", appLogo_53_png],
  ["appLogo_54_png", appLogo_54_png],
  ["appLogo_55_png", appLogo_55_png],
  ["appLogo_56_png", appLogo_56_png],
  ["appLogo_57_png", appLogo_57_png],
  ["appLogo_58_png", appLogo_58_png],
  ["appLogo_59_png", appLogo_59_png],
  ["appLogo_60_png", appLogo_60_png],
  ["appLogo_61_png", appLogo_61_png],
  ["appLogo_62_png", appLogo_62_png],
  ["appLogo_63_png", appLogo_63_png],
  ["appLogo_64_png", appLogo_64_png],
  ["appLogo_65_png", appLogo_65_png],
  ["appLogo_66_png", appLogo_66_png],
  ["appLogo_67_png", appLogo_67_png],
  ["appLogo_68_png", appLogo_68_png],
  ["appLogo_69_png", appLogo_69_png],
  ["appLogo_70_png", appLogo_70_png],
  ["appLogo_71_png", appLogo_71_png],
  ["appLogo_72_png", appLogo_72_png],
  ["appLogo_73_png", appLogo_73_png],
  ["appLogo_74_png", appLogo_74_png],
  ["appLogo_75_png", appLogo_75_png],
  ["appLogo_76_png", appLogo_76_png],
  ["appLogo_77_png", appLogo_77_png],
]);
