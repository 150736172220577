import { type FormEvent, type RefObject } from "react";
import { Flavors } from "../../../../../constants";
import GlobalConfig from "../../../../../global-config";
import { handleOnPasswordUpdate } from "../../../../../utilities/cxh-handlers/cxh-handler";
import LoginConfig from "../../../login-config";

/**
 * Hook which returns a callback that executes the required CXH host login actions before submitting the input password.
 * Note that these login actions should only be carried out after the entered password has been successfully validated
 * and is ready for submission.
 * @param password The password entered by the user for submission
 * @param formRef Reference object for the password submission form
 * @returns A callback that executes the required CXH host login actions before submitting the input password
 */
export const useCxhHostLoginAction = (password: string, formRef: RefObject<HTMLFormElement>) => {
  const { activeFlavor } = GlobalConfig.instance;
  const { writePasswordToPropertyBag } = LoginConfig.instance;

  return async (event?: FormEvent<HTMLFormElement>) => {
    if (activeFlavor === Flavors.Win11OobeFabric && writePasswordToPropertyBag) {
      // Since the password is to be encrypted and stored in the CXH property bag, we want to
      // block submission now and re-submit the form later after these CXH login actions have
      // been completed.
      event?.preventDefault();
      await handleOnPasswordUpdate(password, () => formRef.current?.submit());
    }
  };
};
