import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { isHighContrast } from "../utilities/browser-helper";
import { getAccessibleImagePath } from "../utilities/image-helpers/accessible-image-path";
import { type AccessibleImageUrls } from "../utilities/image-helpers/accessible-images";
import { getImageSource } from "../utilities/images-helper";

export type AccessibleImageProps = {
  /** The dark and light versions of an image that will change with High Contrast Theme */
  urls: AccessibleImageUrls;

  /** CSS class name used to style the image */
  className?: string;
  /**
   * If the AccessibleImageOptions are not checked in and instead are fetched via
   * external URL, isUrl should be set to true.
   */

  isUrl?: boolean;

  /** Alt text for the image */
  alt?: string;

  /**
   * Role will be set to "img" by default if not supplied to avoid
   * long-standing issues with screen readers that require this role for SVGs
   */
  role?: "presentation" | "img" | "";

  /** Optional data-test id for testing purposes Set to accessibleImg by default */
  dataTestId?: string;

  /**
   * Defaults to false. Use this flag to opt out of high contrast styles.
   * The high contrast styles will fix the background color of the image to ensure it is always accessible in high contrast mode
   * You may want to disable high contrast styles if the image already has a solid background color that works for both light and dark high contrast modes
   */
  disableHighContrastStyles?: boolean;
};

/**
 * AccessibleImage component
 * @param props AccessibleImageProps
 * @returns img with the appropriate source (dark or light) based on high contrast settings.
 */
export const AccessibleImage: React.FC<AccessibleImageProps> = function AccessibleImage(props) {
  const {
    urls,
    dataTestId = "accessibleImg",
    className,
    isUrl = false,
    alt = "",
    role = "",
    disableHighContrastStyles = false,
  } = props;
  const { accessibleImageHighContrastStyles } = StylesConfig.instance.useAccessibleImageStyles();
  const imageStyles =
    disableHighContrastStyles || !isHighContrast()
      ? className
      : mergeClasses(accessibleImageHighContrastStyles, className);

  const image = getAccessibleImagePath(urls);

  if (!image) {
    return null;
  }

  let src = image;

  if (!isUrl) {
    try {
      src = getImageSource(image, urls.extension);
    } catch {
      return null;
    }
  }

  return <img data-testid={dataTestId} className={imageStyles} src={src} alt={alt} role={role} />;
};
