import { type ICountryInfo } from "../../../utilities/country-helper";

// Maps the country iso code with the corresponding country data
// (e.g., "AF": { iso: "AF", displayValue: "Afghanistan (+93)", code: "93" })
export const createCountryMap = (
  countryData: Array<ICountryInfo>,
): Record<string, ICountryInfo> => {
  const map: Record<string, ICountryInfo> = {};
  countryData.forEach((country: ICountryInfo) => {
    map[country.iso] = country;
  });

  return map;
};
