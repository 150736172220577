import React from "react";
import { type ILinkButtonProps, LinkButton } from "../../../components/link-button";

export const commonBindingsFabric = {
  italicizedText: (text: string) => <i>{text}</i>,
  boldText: (text: string) => <b>{text}</b>,
  strong: (text: string) => <b>{text}</b>,
  lineBreak: () => <br />,
  registeredTrademark: () => <span>&#174;</span>,
  trademark: () => <span>&#8482;</span>,
};

/**
 * This method creates a dynamic React component for an anchor tag, to be used in the FormattedTextWithBindings component.
 * It expects the formatted text to have text content inside the link.
 * @param url The url to use in the anchor tag.
 * @returns a React functional component that renders a link with the given url.
 */
export const createEmbeddedLinkFabric = function createEmbeddedLinkFabric(url: string) {
  return function EmbeddedLinkFabric(chunks: string[]) {
    const [text] = chunks;
    return <a href={url}>{text}</a>;
  };
};

export type EmbeddedFC = (chunks: string[]) => JSX.Element;

/**
 * This method creates a dynamic React component for LinkButton, to be used in the FormattedTextWithBindings component.
 * It expects the formatted text to have text content inside the link.
 * @param onClick The function to call when the link is clicked.
 * @returns a React functional component that renders a link with the given url.
 */
export const createEmbeddedLinkButtonFabric = function createEmbeddedLinkButtonFabric(
  onClick: () => void,
): EmbeddedFC {
  return function EmbeddedLinkButtonFabric(chunks: string[]) {
    const [text] = chunks;
    return <LinkButton text={text} onClick={onClick} />;
  };
};

/**
 * This method creates a dynamic React component for LinkButton, to be used in the FormattedTextWithBindings component.
 * It expects the formatted text to have text content inside the link.
 * @param props The properties for the LinkButton component.
 * @returns a React functional component that renders a link with the given url.
 */
export const createEmbeddedLinkButtonWithPropsFabric =
  function createEmbeddedLinkButtonWithPropsFabric(
    props: Omit<ILinkButtonProps, "text">,
  ): EmbeddedFC {
    return function EmbeddedLinkButtonFabric(chunks: string[]) {
      const [text] = chunks;
      return <LinkButton {...props} text={text} />;
    };
  };
