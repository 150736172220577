import { makeStyles, shorthands } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type DialogStylesFabric, useDialogStylesFabric } from "../fabric";
import * as commonStyleConstants from "./constants-win11-oobe-fabric.styles";

const useOverrideDialogStyles = makeStyles({
  buttonContainer: {
    "& button": {
      minHeight: "32px",
      minWidth: "110px",
    },
  },

  inner: {
    maxWidth: `${commonStyleConstants.LIGHTBOX_MAX_WIDTH + 122}px`,
    backgroundColor: "#ffffff",
  },

  outer: {
    display: "table",
    position: "absolute",
    height: "100%",
    zIndex: 100,
    filter:
      "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#8C000000', endColorstr='#8C000000')",

    backgroundColor: "transparent",
    marginLeft: "5%",
    marginRight: "5%",
    overflowY: "auto",
    width: "90%",
  },

  winFlyout: {
    ...shorthands.borderRadius("8px"),
    ...shorthands.border("1px solid"),
    ...shorthands.borderColor("rgba(0, 0, 0, 0.0578) !important"),
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.14)",
    width: "100%",
  },
});

export type DialogStylesWin11OobeFabric = DialogStylesFabric &
  Partial<ReturnType<typeof useOverrideDialogStyles>>;

export const useDialogStylesWin11OobeFabric = () =>
  useMergedStyles(
    useDialogStylesFabric(),
    useOverrideDialogStyles(),
  ) as DialogStylesWin11OobeFabric;
