import React from "react";
import ErrorContainerFabric from "../../error-container/fabric/error-container-fabric";
import { InputFabric } from "../../inputs/input/fabric/input-fabric";
import { type HipChallengeNativeProps } from "../hip-challenge-native-types";
import { HipChallengeNativeMenusFabric } from "./hip-challenge-native-menus-fabric";
import { HipChallengeNativeTargetFabric } from "./hip-challenge-native-target-fabric";

/**
 * HipChallengeNativeFabric component is a native implementation of Hip Captcha which consists of
 * delegating all Captcha actions (image, audio and refresh or others) to a native API.
 * The API serves as an interface between the IDP and RepMap.
 * This gives more control over how the captcha works.
 * Unlike the @HipChallengeFabric version which calls a repMap URL and injects a script into the page.
 * @param props Native Hip Props
 * @returns HipChallengeNativeFabric component
 */
export const HipChallengeNativeFabric: React.FC<HipChallengeNativeProps> =
  function HipChallengeNativeFabric(props) {
    const {
      handlers: { onChange, onBlur, onFocus },
      hasFocus,
      nativeHipTarget,
      nativeHipType,
      menuItems,
      error: { showErrorMessage, errorMessage, externalError },
      strings,
      value,
      onClick,
    } = props;

    return (
      <>
        {externalError && (
          <ErrorContainerFabric id="errorText" aria-relevant="text" aria-atomic="true">
            {externalError}
          </ErrorContainerFabric>
        )}

        <HipChallengeNativeTargetFabric
          nativeHipTarget={nativeHipTarget}
          nativeHipType={nativeHipType}
          strings={strings}
          onClick={onClick}
        />

        <HipChallengeNativeMenusFabric menuItems={menuItems} />

        <InputFabric
          name="nativeHipInput"
          type="text"
          value={value}
          placeholder={strings.placeholder}
          onChange={onChange}
          showErrorMessage={showErrorMessage}
          errorMessage={errorMessage}
          aria-label={strings.placeholder}
          aria-describedby="nativeHipDescription"
          hasFocus={hasFocus}
          hasInitialFocus
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </>
    );
  };
