import React from "react";
import StylesConfig from "../../../config/styles-config";
import type { IErrorContainerProps } from "../error-container-interface";

type ErrorContainerFabricProps = IErrorContainerProps & {
  /** Enables real-time screen reader updates for error messages by streamlining HTML and adding aria attributes. */
  enableAriaLiveUpdates?: boolean;
  /** Optional error code for testing attribute */
  errorCode?: string;
};

/**
 * ErrorContainer component
 * @param { ErrorContainerFabricProps } props Props for displaying an error
 * @returns Returns a component for displaying errors
 */
const ErrorContainerFabric: React.FC<React.PropsWithChildren<ErrorContainerFabricProps>> =
  function ErrorContainerFabric(props) {
    const { children, id = "errorId", className, errorCode, enableAriaLiveUpdates } = props;
    const { useCommonStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();
    const styleName = className || commonStyles.alertError;

    if (!children) {
      return null;
    }

    if (enableAriaLiveUpdates) {
      return (
        <div
          id={id}
          className={styleName}
          data-testid={errorCode}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          aria-relevant="all"
        >
          {children}
        </div>
      );
    }

    return (
      <div role="alert" aria-live="assertive">
        <div id={id} className={styleName} data-testid={errorCode}>
          {children}
        </div>
      </div>
    );
  };

export default ErrorContainerFabric;
