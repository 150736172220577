import { useContext, useMemo } from "react";
import {
  type ConfirmSendCredential,
  type ConfirmSendCredentialTypes,
  type UserCredential,
  CredentialType,
  validateCredentials,
} from "../../../../../model/credential";
import { useTelemetryState } from "../../../../../telemetry-helpers/use-telemetry-state";
import { LoginContext } from "../../../login-context";

export type ConfirmSendCredentials = {
  availableCredentials: UserCredential[];
  currentCredential: ConfirmSendCredential;
  evictedCredentials: UserCredential[];
  preferredCredential: ConfirmSendCredentialTypes;
};

/**
 * This hook is used to get the credentials for ConfirmSendView
 * @returns The credentials for ConfirmSendView
 */
export const useCredentials = (): ConfirmSendCredentials => {
  const {
    viewState: { credentials },
  } = useContext(LoginContext);
  const telemetryState = useTelemetryState();

  // Validate the state properly, but memoize the execution so we don't throw more errors than necessary
  const validatedCreds = useMemo(
    () => validateCredentials(credentials, telemetryState),
    [credentials, telemetryState],
  );

  const { availableCredentials, evictedCredentials, preferredCredential, otcCredential } =
    validatedCreds;

  const currentCredential =
    preferredCredential === CredentialType.OneTimeCode
      ? (otcCredential as ConfirmSendCredential)
      : { credentialType: preferredCredential };

  return {
    availableCredentials,
    currentCredential,
    evictedCredentials,
    preferredCredential,
  };
};
