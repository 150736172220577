import React from "react";
import { type GlobalState, GlobalProvider } from "../../../global-context";
import { type HistoryState, HistoryProvider } from "../../../history-context";
import { AcmaProvider, initialAcmaState } from "../../acma-context";
import { type CustomizationState, CustomizationProvider } from "../../customization-context";

export type BaseSisuStateFabric = {
  initialGlobalState: GlobalState;
  initialCustomizationState: CustomizationState;
  initialHistoryState: HistoryState;
};

/**
 * @param props BaseSisuStateFabric - the initial states for the common providers
 * @param props.initialGlobalState initial global state
 * @param props.initialCustomizationState initial customization state
 * @param props.initialHistoryState initial history state
 * @param props.children children
 * @returns a shared provider component used by all fabric-based apps.
 */
export const BaseSisuProvidersFabric: React.FC<BaseSisuStateFabric> =
  function BaseSisuProvidersFabric({
    initialGlobalState,
    initialCustomizationState,
    initialHistoryState,
    children,
  }) {
    return (
      <GlobalProvider initialState={initialGlobalState}>
        <CustomizationProvider initialState={initialCustomizationState}>
          <AcmaProvider initialState={initialAcmaState}>
            <HistoryProvider initialState={initialHistoryState}>{children}</HistoryProvider>
          </AcmaProvider>
        </CustomizationProvider>
      </GlobalProvider>
    );
  };

export const BaseSisuProvidersXbox = BaseSisuProvidersFabric;
