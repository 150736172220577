import { type ChangeEvent, useContext } from "react";
import {
  type SubmitTask,
  useFormSubmission,
} from "../../../../../components/inputs/hooks/use-form-submission";
import { useInput } from "../../../../../components/inputs/hooks/use-input";
import { type InputValue } from "../../../../../components/inputs/input/input-types";
import { ViewId } from "../../../../../constants";
import { type IFormSubmissionProps } from "../../../../../hooks";
import { LoginContext } from "../../../login-context";
import { LoginActionType } from "../../../login-reducer";

/**
 * @param submitTask The async function that should be invoked when the form is submitted
 * @param validationMethod The method to validate the input value
 * @returns Properties used to render the text input form for the proof confirmation view
 */
export const useProofConfirmationInputForm = (
  submitTask: SubmitTask<IFormSubmissionProps>,
  validationMethod: (value: InputValue) => string,
) => {
  // Global context data
  const {
    viewState: {
      credentials: { proofConfirmation },
    },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  const inputState = useInput({
    initialValue: proofConfirmation,
    hasInitialFocus: true,
    validationMethod,
  });
  const { onChange: baseOnChange } = inputState;

  const onChange = (event: ChangeEvent<HTMLInputElement> | string) => {
    const newValue = typeof event === "string" ? event : event.target.value;

    baseOnChange(newValue);

    dispatchLoginStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { proofConfirmation: newValue },
    });
  };

  const onSubmit = useFormSubmission({
    inputState,
    viewId: ViewId.ProofConfirmation,
    submitTask,
  });

  return {
    ...inputState,
    onChange,
    onSubmit,
  };
};
