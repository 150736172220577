import { type IUsernameViewStrings } from "../username-view-interface";

export type IUsernameViewStringsFabric = IUsernameViewStrings & {
  backButtonLabel: string;
  cancelButtonLabel: string;
  createAccountDropdownAriaLabel: string;
  learnMoreButtonLabel: string;
  loginHelpFidoAriaLabel: string;
  loginHelpHelloOrFidoAriaLabel: string;
  loginSignupLink: string;
  signInOptionsText: string;
  signupLinkAriaLabel: string;
  signupForMyChildLink: string;
  signupForMyselfLink: string;
  usernameAriaLabel: string;
};

export const usernameViewStringsFabric: IUsernameViewStringsFabric = {
  backButtonLabel: getLocalString("General_Back"),
  badPhoneNumberErrorMessage: getLocalString("Error_1208"),
  cancelButtonLabel: getLocalString("General_Buttons_Cancel"),
  createAccountDropdownAriaLabel: getLocalString("Login_Signup_CreateAccountDropdown_AriaLabel"),
  errorInvalidMemberNameEmailPhoneSkype: getLocalString("SignIn_Error_EmailMissing"),
  header: getLocalString("SignIn_Title"),
  placeholder: getLocalString("SignIn_UserName_Hint"),
  learnMoreButtonLabel: getLocalString("General_LearnMore_CapM"),
  loginHelpFidoAriaLabel: getLocalString("Login_CredPicker_Help_Desc_Fido_NoHello"),
  loginHelpHelloOrFidoAriaLabel: getLocalString("Login_CredPicker_Help_Desc_WindowsHello"),
  loginSignupLink: getLocalString("Login_Signup_Link"),
  signInOptionsText: getLocalString("Login_CredPicker_Title_NoUser"),
  signupLinkAriaLabel: getLocalString("Login_Signup_CreateOneLink_AriaLabel"),
  signupForMyChildLink: getLocalString("Login_Signup_CreateAccountDropdown_ForMyChild"),
  signupForMyselfLink: getLocalString("Login_Signup_CreateAccountDropdown_ForMyself"),
  usernameAriaLabel: getLocalString("Login_UsernameAriaLabel_Generic"),
};
