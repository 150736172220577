import { type FormEvent } from "react";
import { ViewId } from "../../../../constants/routing-constants";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { usePostRedirectContext } from "../../../../post-redirect-context";
import { PostRedirectActionType } from "../../../../post-redirect-reducer";
import LoginConfig from "../../login-config";

/**
 * useSignupPostRedirectHandler hook. This hook is used to get the post redirect handler for redirecting to signup.
 * @returns The redirect handler for the post redirect form.
 */
export const useSignupPostRedirectHandler = () => {
  const { signupUrl, signupUrlPostParams } = LoginConfig.instance;
  const { dispatchStateChange } = usePostRedirectContext();
  return () => {
    dispatchStateChange({
      type: PostRedirectActionType.SubmitPostRedirect,
      payload: { url: signupUrl, postParams: signupUrlPostParams, submitForm: true },
    });
  };
};

/**
 * onSubmitHandler function to be called on submit and based on isRemoteConnectSignup flag, it would either redirect to post redirect form or navigate to next view.
 * @param redirect - redirect handler for the post redirect form.
 * @param navigator - navigate handler for the view.
 * @param nextView - next view to be navigated to in case of navigation instead of redirection.
 * @param event - Form event.
 */
export const onSubmitHandler = (
  redirect: Function,
  navigator: Function,
  nextView: string,
  event: FormEvent<HTMLFormElement>,
) => {
  const { isRemoteConnectSignup } = LoginConfig.instance;

  event.preventDefault();

  if (isRemoteConnectSignup) {
    // Navigate to post redirect form
    redirect();
  } else {
    navigator(ViewId.RemoteConnectCanaryValidation, nextView);
  }
};

/**
 * RemoteConnectCanaryValidationView hook which returns all the props required for the RemoteConnectCanaryValidationView component.
 * @returns props needed on the view
 */
export const useRemoteConnectCanaryValidationHook = () => {
  const navigator = useNavigateDirection();
  const redirect = useSignupPostRedirectHandler();

  return {
    redirect,
    navigator,
    onSubmitHandler,
  };
};
