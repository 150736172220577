export interface IAccountPickerStrings {
  accountPickerTitle: string;
  alreadySignedInText: string;
  useAnotherAccountText: string;
  signOutText: string;
  signOutAndForgetText: string;
  forgetText: string;
  signInWithAccountAriaLabel: string;
  forgetFailureErrorText: string;
  optionsMenuAriaLabel: string;
}

/**
 * @returns account picker strings
 */
export const accountPickerStringsFabric: IAccountPickerStrings = {
  accountPickerTitle: getLocalString("Login_Tiles_Title"),
  alreadySignedInText: getLocalString("Login_AlreadySignedIn"),
  useAnotherAccountText: getLocalString("UseAnotherAccount_Link"),
  signOutText: getLocalString("SignOut_Text"),
  signOutAndForgetText: getLocalString("Login_SignOutAndForget"),
  forgetText: getLocalString("Login_Forget"),
  signInWithAccountAriaLabel: getLocalString("Login_SignInWithAccountAriaLabel"),
  forgetFailureErrorText: getLocalString("Login_Err_ForgetFailure"),
  optionsMenuAriaLabel: getLocalString("TileMenu_AltText"),
};
