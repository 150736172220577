import React from "react";

export interface IListContainerProps {
  /** The list of strings with ids that need to be displayed */
  listItems: {
    [key: string]: string | JSX.Element;
  };
  /** Classname for the list container */
  className?: string;
  /** Indicates if the list should be ordered; default is unordered */
  isOrdered?: boolean;
}

/**
 * ListContainer component
 * @param props IListContainerProps properties for this component
 * @returns An ordered or unordered list of strings with associated ids
 */
export const ListContainer: React.FC<IListContainerProps> = function ListContainer(props) {
  const { listItems, className, isOrdered = false } = props;

  const items = Object.entries(listItems)
    .filter((entry) => entry[1])
    .map(([key, item]) => (
      <li id={key} key={key}>
        {item}
      </li>
    ));

  return isOrdered ? (
    <ol className={className}>{items.length > 0 ? items : null}</ol>
  ) : (
    <ul className={className}>{items.length > 0 ? items : null}</ul>
  );
};
