import { Flavors } from "../../../constants";
import { gctStringsFabric } from "./fabric/gct-strings-fabric";
import { gctStringsXbox } from "./xbox/gct-strings-xbox";
import { type GctStrings, gctStringsConfig } from "./gct-strings-config";

/**
 * This function this maps a flavor (i.e Xbox, Fabric) to a set of flavored gct error strings.
 * @param flavor The active flavor.
 */
const gctStringsMap = new Map<Flavors, GctStrings>([
  [Flavors.Fabric, gctStringsFabric],
  [Flavors.Win11OobeFabric, gctStringsFabric],
  [Flavors.Xbox, gctStringsXbox],
]);

/**
 * Initialize Get Credential Type API strings
 * @param flavor The active flavor.
 */
export function initGctStrings(flavor: Flavors) {
  gctStringsConfig.initialize({
    gctStrings: gctStringsMap.get(flavor),
  });
}
