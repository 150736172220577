import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import { SecondaryContentContainerFabric } from "../../../../components";
import { LinkButton } from "../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { type TileFabricComponent } from "../../../../components/tile/fabric/tile-fabric";
import { TileListFabric } from "../../../../components/tile-list/fabric/tile-list-fabric";
import StylesConfig from "../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import {
  useActivateView,
  useBackButtonControl,
  useDocumentTitle,
  useNavigateOrRedirectBack,
} from "../../../../hooks";
import { FormattedTextWithBindings } from "../../../../utilities/formatted-text-with-bindings";
import {
  pickerAccountAadIcon,
  pickerAccountMsaIcon,
} from "../../../../utilities/image-helpers/accessible-images";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { useShowBackButton } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import { useIdpDisambiguationViewProperties } from "../hooks/use-idp-disambiguation-view-properties";
import { useSelectAccountLinkClickHandler } from "../hooks/use-select-account-link-click-handler";
import { useTileClickHandlers } from "../hooks/use-tile-click-handlers";

/**
 * IdpDisambiguationView component
 *
 * This view is shown when the user owns accounts with the same username in both AAD and MSA
 * and needs to decide which IDP they want to log into.
 *
 * @returns A rendered instance of this component
 */
export const IdpDisambiguationViewFabric: React.FC = function IdpDisambiguationViewFabric() {
  // Global context data
  const {
    globalState: {
      user: {
        displayUsername: { unsafeUnescapedString: unsafeDisplayUsername },
      },
    },
  } = useContext(GlobalContext);

  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  // Login config data
  const { disambigRenameUrl, showSwitchUser } = LoginConfig.instance;

  const redirectingToIdp = false;
  const idpRedirectUrl = "";
  const idpRedirectPostParams = {};
  const unsafeDesktopSsoDomain = "";

  const { documentTitle, pageDescription } = useIdpDisambiguationViewProperties({
    commonLoginStrings: commonLoginStringsFabric,
  });

  const { aadTileClickHandler, msaTileClickHandler } = useTileClickHandlers(
    redirectingToIdp,
    idpRedirectUrl,
    idpRedirectPostParams,
    unsafeDesktopSsoDomain,
  );

  const selectAccountClickHandler = useSelectAccountLinkClickHandler(
    redirectingToIdp,
    idpRedirectUrl,
    idpRedirectPostParams,
    unsafeDesktopSsoDomain,
  );

  const commonStyles = useCommonStyles();
  const canGoBack = useBackButtonControl();
  const showBackButton = useShowBackButton();
  const backButtonClickHandler = useNavigateOrRedirectBack();

  useActivateView(ViewId.IdpDisambiguation, FlowId.Login, {
    showIdentityBanner: false,
  });
  useDocumentTitle(documentTitle);
  useStaticCommonStyles();

  const aadTileProps = {
    mainText: getLocalString("WorkOrSchoolAccount_Description"),
    helpText: getLocalString("CreatedByIT_HintText"),
    subText: unsafeDisplayUsername,
    image: { urls: pickerAccountAadIcon, role: "presentation" },
    onClick: aadTileClickHandler,
    hasInitialFocus: true,
    ariaLabel: getLocalString("WorkOrSchoolAccount_Description"),
  } as TileFabricComponent;

  const msaTileProps = {
    mainText: getLocalString("PersonalAccount_Description"),
    helpText: getLocalString("CreatedByYou_HintText"),
    subText: unsafeDisplayUsername,
    image: { urls: pickerAccountMsaIcon, role: "presentation" },
    onClick: msaTileClickHandler,
    ariaLabel: getLocalString("PersonalAccount_Description"),
  } as TileFabricComponent;

  const disambigRenameLink = (chunks: string[]) => (
    <a id="disambigRenameLink" href={disambigRenameUrl}>
      {chunks[0]}
    </a>
  );

  const backButtonLabel = getLocalString("General_Back");

  return (
    <>
      <div
        className={mergeClasses(
          commonStyles.row,
          commonStyles.textBody,
          commonStyles.textBlockBody,
        )}
      >
        <div id="loginDescription" role="heading" aria-level={1}>
          {pageDescription}
        </div>
      </div>

      <TileListFabric tileList={[aadTileProps, msaTileProps]} />

      <div className={commonStyles.buttonMargin}>
        <div className={commonStyles.row}>
          <SecondaryContentContainerFabric>
            <div className={commonStyles.formGroup}>
              <FormattedTextWithBindings
                text={getLocalString("RenamePersonalAccount_Description")}
                embeddedBindings={{ disambigRenameLink }}
              />
            </div>
            {showSwitchUser && !canGoBack && (
              <div className={commonStyles.formGroup}>
                <LinkButton
                  linkId="i1668"
                  text={getLocalString("Login_SignInWithAnotherAccount")}
                  onClick={selectAccountClickHandler}
                />
              </div>
            )}
          </SecondaryContentContainerFabric>
        </div>
      </div>

      <div className={commonStyles.winButtonPinBottom}>
        <div className={commonStyles.row}>
          <TextButtonContainer>
            {showBackButton && (
              <TextButtonFabric
                buttonId="backButton"
                label={backButtonLabel}
                isPrimary={false}
                ariaLabel={backButtonLabel}
                onClick={backButtonClickHandler}
              />
            )}
          </TextButtonContainer>
        </div>
      </div>
    </>
  );
};
