import { useContext } from "react";
import { AuthenticationContext } from "../../../../../authentication-context";
import { ViewId } from "../../../../../constants/routing-constants";
import { useCustomizationContext } from "../../../../../context/customization-context";
import GlobalConfig from "../../../../../global-config";
import { useGlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { type IPhoneNumberSubmitProps } from "../../../../../hooks/use-phone-number-deprecated";
import { sendAsync } from "../../../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import { type ICountryInfo, getDefaultCountry } from "../../../../../utilities/country-helper";
import { appendOrReplaceQueryStringParams } from "../../../../../utilities/strings-helper";
import { useHandleGctResponse } from "../../../hooks/use-handle-gct-response";
import LoginConfig from "../../../login-config";
import { LoginContext } from "../../../login-context";
import { type CommonLoginStrings } from "../../../login-interface";
import { getCommonDocumentTitle } from "../../../login-util";
import { type IPhoneDisambiguationViewStringsFabric } from "../fabric/phone-disambiguation-view-strings-fabric";

export interface IPhoneDisambiguationViewProperties {
  documentTitle: string;
  errorMessage: string;
  title: string;
  description: string;
  placeholder: string;
  ariaLabel: string;
  signInWithDifferentUser: string;
  createNewAccountLabel: string;
  backLabel: string;
  nextLabel: string;
}

/**
 * @returns Strings for the phone disambiguation view, Fabric based
 * @param strings Flavored strings used by this hook
 * @param strings.commonLoginStrings Common login strings
 * @param strings.phoneDisambiguationStrings Phone Disambiguation view strings
 * TODO @mjg-flavors: separate static localized strings (which should live in a static object) from dynamic strings like title (which need to be computed at runtime)
 */
export const usePhoneDisambiguationViewPropertiesFabric = (strings: {
  commonLoginStrings: CommonLoginStrings;
  phoneDisambiguationStrings: IPhoneDisambiguationViewStringsFabric;
}): IPhoneDisambiguationViewProperties => {
  const { commonLoginStrings } = strings;
  const {
    title,
    description,
    alternateDescription,
    placeholder,
    inputAriaLabel,
    badCharactersErrorMessage,
    signInWithDifferentUser,
    createNewAccountLabel,
    backLabel,
    nextLabel,
  } = strings.phoneDisambiguationStrings;

  const { improvePhoneDisambiguation, loginMode } = LoginConfig.instance;
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  return {
    documentTitle: getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings),
    errorMessage: badCharactersErrorMessage,
    title,
    description: improvePhoneDisambiguation ? alternateDescription : description,
    placeholder,
    ariaLabel: inputAriaLabel,
    signInWithDifferentUser,
    createNewAccountLabel,
    backLabel,
    nextLabel,
  };
};

/**
 * @returns Click handler for the "sign in as a different user" link
 */
export const useSignInDifferentUserHandler = () => {
  const { dispatchStateChange } = useGlobalContext();
  const navigate = useNavigateDirection();

  return (unsafeUsername: string) => {
    dispatchStateChange({
      type: GlobalActionType.SetUser,
      payload: { username: unsafeUsername },
    });

    dispatchStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.PhoneDisambiguation,
      destination: ViewId.Username,
      displayOptions: { navigationDirection: "forward" },
    });

    navigate(ViewId.PhoneDisambiguation, ViewId.Username);
  };
};

/**
 * @returns Default country
 */
export const useGetDefaultCountry = (): ICountryInfo => {
  const { countryList, defaultCountry: country } = LoginConfig.instance;

  const {
    viewState: { location },
  } = useContext(LoginContext);
  // Check if location context is available, else get default country from login config
  return getDefaultCountry(countryList, location || country);
};

/**
 * @returns function for the GCT request
 */
export const useGctRequest = () => {
  const {
    authState: { flowTokenValue: flowToken },
  } = useContext(AuthenticationContext);

  const {
    viewState: { otherIdpRedirectUrl },
  } = useContext(LoginContext);

  const { resetPasswordUrl } = GlobalConfig.instance;

  const {
    allowedIdentities,
    isFidoSupportedHint,
    gctFederationFlags,
    isFederationDisabled,
    isExternalFederationDisallowed,
    isRemoteNGCSupported,
    isOtcLoginDisabled: otclogindisallowed,
    getCredentialTypeUrl,
    showSignup,
    signupUrl,
    improvePhoneDisambiguation,
    fedQs,
    changePasswordUrl,
    postProofType,
    signupUrlPostParams,
    useResetPasswordUrlForPasswordRequiredError,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);

  const handleGctResponse = useHandleGctResponse();

  return async (params: IPhoneNumberSubmitProps) => {
    const { value, errorHandler, inputValue } = params;
    const unsafeUsername = String(value);

    const updatedIdpRedirectUrl = appendOrReplaceQueryStringParams(otherIdpRedirectUrl, {
      username: unsafeUsername,
      login_hint: unsafeUsername,
    });

    const gctResult = await sendAsync(
      {
        allowedIdentities,
        unsafeUsername,
        flowToken,
        isFidoSupported,
        country: "", // for now, country is not needed in MSA
        gctFederationFlags: gctFederationFlags || 0,
        isExternalFederationDisallowed,
        isFederationDisabled,
        isRemoteNGCSupported,
        otclogindisallowed,
        getCredentialTypeUrl,
        otherIdpRedirectUrl: updatedIdpRedirectUrl,
        fedQs,
      },
      {
        changePasswordUrl,
        improvePhoneDisambiguation,
        postProofType,
        resetPasswordUrl,
        showSignup,
        signupUrl,
        signupUrlPostParams,
        useResetPasswordUrlForPasswordRequiredError,
      },
      { isPhoneNumberFullyQualified: true, checkCurrentIdpOnly: true },
    );

    handleGctResponse(
      ViewId.PhoneDisambiguation,
      gctResult,
      inputValue || "",
      gctResult.sharedData?.displayName || unsafeUsername,
      errorHandler,
    );
  };
};
