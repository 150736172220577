import { useContext } from "react";
import { ViewId } from "../../../../../constants";
import { GlobalContext } from "../../../../../global-context";
import { useNavigateDirection } from "../../../../../hooks";
import { getProofType } from "../../../../../model/proof";
import { useSelectAccount } from "../../../hooks/login-hooks";
import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";
import { type ConfirmSendCredentials } from "./use-credentials";
import { useOnClickPrimaryButton } from "./use-on-click-primary-button";

export type EventHandlerProps = {
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setButtonText: React.Dispatch<React.SetStateAction<string>>;
  requestIsPending: boolean;
  setRequestIsPending: React.Dispatch<React.SetStateAction<boolean>>;
  credentials: ConfirmSendCredentials;
};

/**
 * This method takes the credentials from `useCredentials` and the display properties from `useDisplayProperties`
 * and returns the callbacks to use in the ConfirmSendView
 * @param props The event handler props required for this hook
 * @param confirmSendStrings Confirm Send View strings
 * @returns The callbacks to use in the ConfirmSendView
 */
export const useEventHandlers = (
  props: EventHandlerProps,
  confirmSendStrings: ConfirmSendViewStrings,
) => {
  const { setHasError, setButtonText, requestIsPending, setRequestIsPending, credentials } = props;
  const navigate = useNavigateDirection();
  const {
    globalState: { user },
  } = useContext(GlobalContext);
  const { availableCredentials, preferredCredential, currentCredential } = credentials;
  const proofType = getProofType(currentCredential, user);

  const selectAccount = useSelectAccount(ViewId.ConfirmSend);

  const onClickPrimaryButton = useOnClickPrimaryButton(
    {
      availableCredentials,
      currentCredential,
      preferredCredential,
      proofType,
      requestIsPending,
      setButtonText,
      setHasError,
      setRequestIsPending,
      user,
    },
    confirmSendStrings,
  );

  const navigateToPhoneDisambig = () => {
    navigate(ViewId.ConfirmSend, ViewId.PhoneDisambiguation);
  };

  return {
    onClickPrimaryButton,
    navigateToPhoneDisambig,
    selectAccount,
  };
};
