import { useContext, useState } from "react";
import { ViewId } from "../../../constants";
import { useNavigateDirection } from "../../../hooks";
import { type BaseOtcParams, useSendOneTimeCode } from "../../../hooks/use-send-otc";
import { ProofType } from "../../../model/proof";
import { type IUser } from "../../../model/user";
import {
  type OtcChannel,
  type OtcPurposes,
  type OtcSuccessParams,
} from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { LoginContext } from "../login-context";
import { LoginActionType } from "../login-reducer";

/**
 * This hook is used to provide a callback to make a OneTimeCode API call. onSuccess this hook navigates to RemoteLoginPolling View.
 * @param channel Used for the OTC API call
 * @param purpose Used for the OTC API call
 * @param user Used for the OTC API call
 * @param sourceViewId The view that is calling the hook (ViewId.Username is the default).
 * This hook will then navigate from the provided sourceViewId to the RemoteLoginPollingView
 * @returns A callback used to make a OneTimeCode API call.
 * This callback has "debouncing", so subsequent calls won't trigger another request while the current request is in progress.
 */
export const useSendOtcForRemoteLoginPolling = (
  channel: OtcChannel | null,
  purpose: OtcPurposes,
  user: IUser,
  sourceViewId: ViewId | string = ViewId.Username,
) => {
  // Login state
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  const navigate = useNavigateDirection();

  const [requestIsPending, setRequestIsPending] = useState(false);

  const otcSuccess = (result: Partial<OtcSuccessParams>) => {
    dispatchLoginStateChange({
      type: LoginActionType.SetRemoteLoginPollingParams,
      payload: {
        oneTimeCode: result.userCode,
        // TODO: AAD/ESTS - Incorporate `result.DeviceCode` in the deviceIdentifier as it's done in Knockout
        deviceIdentifier: result.sessionLookupKey,
      },
    });

    navigate(sourceViewId, ViewId.RemoteLoginPolling);
  };

  const otcParams: BaseOtcParams = {
    channel,
    purpose,
    proofType: ProofType.DeviceId,
    setRequestIsPending,
    requestIsPending,
    user,
    onSuccess: otcSuccess,
  };

  // otcSuccess callback will be called from useSendOneTimeCode, which will navigate to RemoteLoginPolling.
  const sendOneTimeCodeThenNavigate = useSendOneTimeCode(otcParams);
  return sendOneTimeCodeThenNavigate;
};
