import { getCxh } from "../cxh-handler";

/**
 * Requests the host to show UI.
 */
export const initXboxCXH = async () => {
  const CXH = await getCxh();
  CXH.show();
};

/**
 * Informs the host to show virtual keyboard.
 * @param show value indicating whether the keyboard should be shown. Default behavior is to show.
 */
export const showKeyboardForXbox = async (show: boolean) => {
  const CXH = await getCxh();
  CXH.showKeyboardForXbox(show);
};

/**
 * Informs the host that the current flow is being canceled.
 * @param isTokenBroker is token broker
 * @param allowCancel allow cancel
 */
export const handleOnFinalBack = async (isTokenBroker: boolean, allowCancel: boolean) => {
  const CXH = await getCxh();

  if (isTokenBroker) {
    CXH.logEvent("MSA.TokenBroker.BackClicked");
    CXH.setAuthErrorAndComplete(
      "80048842" /* PPCRL_REQUEST_E_USER_CANCELED */,
      "800434F5" /* PP_E_LOGINPROTOCOL_CANCEL */,
    );
  } else if (allowCancel) {
    CXH.finish(CXH.FinishStates.Cancel);
  }
};

/**
 * Creates a local user and connects it to the MSA account provided via the MSA username. This is meant to be used while connecting on Xbox only.
 * DA: Device association. This token is used to authenticate the device with a Microsoft account (MSA).
 * @param msaUser MSA username
 * @param daToken Device Association (DA) token
 * @param daCreationDate DA token creation date
 * @param daExpiryDate DA token expiration date
 * @param flowToken Flow token
 * @returns boolean
 */
export const loginUserForXbox = async (
  msaUser: string,
  daToken: string,
  daCreationDate: string,
  daExpiryDate: string,
  flowToken: string,
) => {
  const CXH = await getCxh();

  const onSuccess = () => CXH.finish(CXH.FinishStates.Success);
  const onError = () => CXH.finish(CXH.FinishStates.Error);

  CXH.associateXboxAccountAsync(
    msaUser,
    daToken,
    daCreationDate,
    daExpiryDate,
    flowToken,
    onSuccess,
    onError,
  );

  return true;
};
