import React from "react";
import StylesConfig from "../../../../../config/styles-config";
import { type ViewId } from "../../../../../constants";
import { useConsentMessage } from "./hooks/use-consent-message";
import { usePrivacyAgreementMessage } from "./hooks/use-privacy-agreement-message";

/**
 * InlineLegalMessagingFabric component that is shown in the win11-oobe-fabric flavor in place of the fabric footer.
 * @param props The input props for the InlineLegalMessagingFabric component
 * @param props.sourceViewId The view in the login flow that shows the InlineLegalMessagingFabric component
 * @returns an instance of this component
 */
export const InlineLegalMessagingFabric: React.FC<{ sourceViewId: ViewId }> =
  function InlineLegalMessagingFabric({ sourceViewId }) {
    const { useCommonStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();

    const consentMessage = useConsentMessage(sourceViewId);
    const privacyAgreementMessage = usePrivacyAgreementMessage(sourceViewId);

    return (
      <div
        data-testid="inlineLegalMessaging"
        className={commonStyles.topMarginAfterSecondaryContent}
      >
        {consentMessage && <div className={commonStyles.textCaption}>{consentMessage}</div>}

        <div className={commonStyles.textCaption}>{privacyAgreementMessage}</div>
      </div>
    );
  };
