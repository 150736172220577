import { useChallengeContext } from "../../../../challenge-context";
import GlobalConfig from "../../../../global-config";
import { useGlobalContext } from "../../../../global-context";
import { useTelemetry } from "../../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../../telemetry-helpers/user-action-name";
import { extractQueryStringParam } from "../../../../utilities/strings-helper";
import { type ChallengeSubmitParams, type ChallengeSwitchPropsFabric } from "../../challenge-types";
import { challengeStringsFabric as strings } from "../challenge-strings-fabric";

export const useChallengeViewPropertiesFabric = (
  onSubmitHandler: (challengeSubmitParams: ChallengeSubmitParams) => void,
): ChallengeSwitchPropsFabric => {
  const {
    challengeState: { type, errorMessage, arkose, hip },
  } = useChallengeContext();

  const {
    globalState: { activeView, activeFlow },
  } = useGlobalContext();

  const { activeFlavor, telemetry } = GlobalConfig.instance;

  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const challengeViewProperties: ChallengeSwitchPropsFabric = {
    challengeType: type,
    arkoseProps: {
      config: {
        connectionData: arkose.dataExchangeBlob,
        url: arkose.url,
        timeout: arkose.timeout,
      },
      strings: {
        iframeTitle: strings.solveTitle,
      },
      errorMessage,
      onTimeout: () => {
        logUserAction({
          actionName: UserActionName.ChallengeLoaded,
          actionValue: { type, result: false },
        });
      },
      onBack: () => {},
      onLoadingStateChange: () => {},
      onSessionStart: () => {
        logUserAction({
          actionName: UserActionName.ChallengeLoaded,
          actionValue: { type, result: true },
        });
      },
      onSuccess: ({ solution, token }) => {
        logUserAction({
          actionName: UserActionName.ChallengeSolved,
          actionValue: { type },
        });
        onSubmitHandler({ solution, type, token });
      },
    },
    hipProps: {
      initialType: type,
      config: { url: hip.url, market: extractQueryStringParam("mkt") },
      strings: {
        ariaPrimaryButtonLabel: strings.nextButton,
        primaryButton: strings.nextButton,
      },
      errorMessage,
      onSessionStart: () => {},
      onValidate: () => {},
      onSuccess: onSubmitHandler,
    },
    errorContainerMessage: strings.errorContainerMessage,
  };

  return challengeViewProperties;
};
