import { useCallback, useState } from "react";
import { useEffectOnce } from "../../../../hooks/use-effect-once";
import { getFullyQualifiedPhoneNumber } from "../../../../model/alias";
import { type ICountryInfo } from "../../../../utilities/country-helper";
import {
  type InputErrorState,
  type InputState,
  type InputStateOptions,
  useErrorState,
} from "../../hooks/use-input";

export type PhoneNumberValues = { inputValue: string; dropdownValue: ICountryInfo };

export type PhoneNumberValidationProps = PhoneNumberValues & {
  phoneNumber: string;
};

export type PhoneNumberOptions = Omit<
  InputStateOptions,
  "initialValue" | "hasInitialFocus" | "useElementRef" | "validationMethod" | "onChange"
> & {
  inputState: InputState;
  defaultCountry: ICountryInfo;
  /** An optional callback that happens when a user changes either input. Happens before validation. */
  onChange?: (values: PhoneNumberValues) => void;
  onChangeInputCallback?: (value: string) => void;
  onChangeDropdownCallback?: (value: ICountryInfo) => void;
  validationMethod?: (props: PhoneNumberValidationProps) => string;
};

export type PhoneNumberState = {
  error: InputErrorState;
  // We omit onChange here so folks don't accidentally use it instead of the onChangeInput method
  inputState: Omit<InputState, "onChange">;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement> | string) => string;
  dropdownValue: ICountryInfo;
  onChangeDropdown: (value: ICountryInfo) => void;
  value: string;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  userHasSubmitted: boolean;
  setUserHasSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
};

// This is a type that can be used to pass the props to the phone number component easier
export type CommonPhoneNumberProps = {
  inputProps: Pick<InputState, "value" | "onBlur" | "onFocus" | "hasFocus" | "onChange">;
  dropdownProps: {
    value: ICountryInfo;
    onChange: (country: ICountryInfo) => void;
    countryData: ICountryInfo[];
  };
  error: InputErrorState;
};

/**
 * Hook that provides handlers for the phone number component
 * @param props The properties for this hook
 * @returns Handlers for using the phone number component.
 */
export const usePhoneNumber = function usePhoneNumber(props: PhoneNumberOptions): PhoneNumberState {
  const {
    defaultCountry,
    validationMethod,
    clearServerErrorOnChange = true,
    initialServerError = "",
    inputState,
    immediatelyShowValidationErrorsAfterSubmit = true,
    onChange,
    onChangeInputCallback,
    onChangeDropdownCallback,
  } = props;
  const { value: inputStateValue, onChange: onChangeInputInner } = inputState;

  const errorState = useErrorState(initialServerError);
  const { serverError, setServerError, setValidationError, setShowErrorMessage } = errorState;
  const [userHasSubmitted, setUserHasSubmitted] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(defaultCountry);
  const [phoneNumberValue, setPhoneNumberValue] = useState(
    getFullyQualifiedPhoneNumber(String(inputStateValue), dropdownValue),
  );

  // This method updates the phone number based on the input and dropdown values
  // It will also clear the server error if the "clearServerErrorOnChange" prop is true
  // It returns the new phone number
  const updateNumber = useCallback(
    (currentValues: PhoneNumberValues) => {
      const { dropdownValue: ddValue, inputValue } = currentValues;
      const newPhoneNumber = getFullyQualifiedPhoneNumber(inputValue, ddValue);
      setPhoneNumberValue(newPhoneNumber);

      if (clearServerErrorOnChange && serverError) {
        setServerError("");
        setShowErrorMessage(false);
      }

      if (onChange) {
        onChange(currentValues);
      }

      return newPhoneNumber;
    },
    [clearServerErrorOnChange, serverError, setServerError, setShowErrorMessage, onChange],
  );

  const validate = useCallback(
    (validationObj: PhoneNumberValidationProps) => {
      if (validationMethod) {
        const validationError = validationMethod(validationObj);
        setValidationError(validationError);
        if (userHasSubmitted && !!validationError) {
          setShowErrorMessage(immediatelyShowValidationErrorsAfterSubmit);
        }
      }
    },
    [
      setValidationError,
      validationMethod,
      setShowErrorMessage,
      userHasSubmitted,
      immediatelyShowValidationErrorsAfterSubmit,
    ],
  );

  // By using the DOM's onChange events, we can update the internal values and determine if the value has changed and needs to be validated
  // If we only use the onChange events to update the internal state and ran validation with a "useEffect" based on those, the validation method would be called too frequently.

  const onChangeDropdown = useCallback(
    (val: ICountryInfo) => {
      const changed = val !== dropdownValue;
      setDropdownValue(val);

      if (changed) {
        const newValues = {
          dropdownValue: val,
          inputValue: String(inputStateValue),
        };
        const phoneNumber = updateNumber(newValues);
        validate({
          ...newValues,
          phoneNumber,
        });
      }

      if (onChangeDropdownCallback) {
        onChangeDropdownCallback(val);
      }
    },
    [dropdownValue, inputStateValue, updateNumber, validate, onChangeDropdownCallback],
  );

  const onChangeInput = useCallback(
    (event) => {
      const oldVal = inputStateValue;
      const newVal = onChangeInputInner(event);
      const changed = oldVal !== newVal;

      if (changed) {
        const newValues = {
          dropdownValue,
          inputValue: String(newVal),
        };
        const phoneNumber = updateNumber(newValues);
        validate({
          ...newValues,
          phoneNumber,
        });
      }

      if (onChangeInputCallback) {
        onChangeInputCallback(newVal);
      }

      return newVal;
    },
    [
      dropdownValue,
      inputStateValue,
      onChangeInputInner,
      updateNumber,
      validate,
      onChangeInputCallback,
    ],
  );

  // Validate the initial value
  useEffectOnce(() => {
    validate({
      dropdownValue,
      inputValue: String(inputStateValue),
      phoneNumber: phoneNumberValue,
    });
  });

  return {
    error: errorState,
    inputState,
    onChangeInput,
    dropdownValue,
    onChangeDropdown,
    value: phoneNumberValue,
    setFocus: inputState.setFocus,
    userHasSubmitted,
    setUserHasSubmitted,
  };
};
