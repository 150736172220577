import background_1_jpg from "../../images/Backgrounds/1.jpg";
import background_2_jpg from "../../images/Backgrounds/2.jpg";
import background_2_svg from "../../images/Backgrounds/2.svg";
// eslint-disable-next-line @msidentity/authx/no-fluent-imports
import fluent_web_dark from "../../images/Backgrounds/fluent_web_dark.jpg";
// eslint-disable-next-line @msidentity/authx/no-fluent-imports
import fluent_web_light from "../../images/Backgrounds/fluent_web_light.jpg";

export const backgrounds = new Map<string, string>([
  ["background_1_jpg", background_1_jpg],
  ["background_2_jpg", background_2_jpg],
  ["background_2_svg", background_2_svg],
  ["background_fluent_web_dark_jpg", fluent_web_dark],
  ["background_fluent_web_light_jpg", fluent_web_light],
]);
