import { type ResponseBody, type ResponseError } from "../../constants";
import { OtcChannel } from "../../utilities/api-helpers/one-time-code/one-time-code-types";
import { type NativeApiError } from "../../utilities/error";
import { type PhoneNumberCollectionChannel } from "../../views/phone-collection";

/**
 * Represents the type users can select to register their auth method.
 */
export type UserAuthMethod = {
  challenge_channel: ServerChannel;
  challenge_type: string;
  id: string;
  login_hint: string;
};

/**
 * Represents the channel type supported by the native API.
 * We provide a mapping to map server types to Ui supported types.
 */
export type ServerChannel = "sms" | "voice" | "email";

/**
 * Represents the auth collection UI channels mapping.
 * This type is to map the ServerChannel and return the OtcChannel.
 * The key represents a ServerChannel type.
 */
export const ServerToOtcChannelMapping: { [key: string]: OtcChannel } = {
  sms: OtcChannel.mobileSms,
  email: OtcChannel.emailAddress,
  voice: OtcChannel.voiceCall,
};

/**
 * Represents the type of binding method for authentication.
 */
export const enum BindingMethodType {
  captcha = "captcha",
  none = "none",
  prompt = "prompt",
}

/**
 * Represents the type of challenge for authentication method.
 */
export const enum AuthMethodChallengeType {
  continuationToken = "continuation_token",
  jpeg = "jpeg",
  mp4 = "mp4",
  none = "none",
  oob = "oob",
  preverified = "preverified",
  visual = "visual",
  audio = "audio",
}

/**
 * Represents the request parameters for authentication method.
 */
export const enum AuthMethodRequestParams {
  challengeChannel = "challenge_channel",
  challengeMethod = "challenge_method",
  challengeInput = "challenge_input",
  challengeTarget = "challenge_target",
  challengeType = "challenge_type",
  challengeTypeRequested = "challenge_type_requested",
  continuationToken = "continuation_token",
  grantType = "grant_type",
  oob = "oob",
}

/**
 * Represents the response parameters for authentication method challenge.
 */
export type AuthMethodChallengeResponseParams = ResponseBody & {
  binding_method?: BindingMethodType;
  challenge_channel: ServerChannel;
  challenge_target?: string;
  challenge_type: AuthMethodChallengeType;
  code_length?: number;
  continuation_token: string;
};

/**
 * Represents the failure parameters for authentication method challenge.
 */
export type AuthMethodChallengeFailureParams = ResponseError &
  NativeApiError & {
    responseBody?: AuthMethodChallengeResponseParams;
  };

/**
 * Represents the response parameters for continuing authentication method challenge.
 */
export type AuthMethodContinueResponseParams = ResponseBody & {
  continuation_token: string;
  grant_type: AuthMethodChallengeType;
  oob?: string;
};

/**
 * Represents the failure parameters for continuing authentication method challenge.
 */
export type AuthMethodContinueFailureParams = ResponseError &
  NativeApiError & {
    responseBody?: AuthMethodContinueResponseParams;
  };

/**
 * Represents the payload for authentication method.
 */
export type AuthMethodPayload = {
  continuationToken: string;
  availableChannels: Array<PhoneNumberCollectionChannel>;
};

/**
 * Represents the payload for challenge.
 */
export type ChallengePayload = {
  bindingMethod?: BindingMethodType;
  challengeChannel: OtcChannel;
  challengeTarget?: string;
  challengeType: AuthMethodChallengeType;
  codeLength?: number;
  continuationToken: string;
};

/**
 * Represents the payload for continue.
 */
export type ContinuePayload = {
  continuationToken: string;
  grantType: AuthMethodChallengeType;
  oob?: string;
};
