import { Flavors } from "../../../constants";
import {
  type AgreementViewStringsFabric,
  AgreementViewStringsConfigFabric,
  agreementViewStringsFabric,
} from "./agreement-view-strings-config-fabric";

/**
 * Maps a flavor (i.e Xbox, Fabric) to a set of flavored strings.
 * @param flavor The active flavor.
 */
const agreementViewStringsFabricMap = new Map<Flavors, AgreementViewStringsFabric>([
  [Flavors.Fabric, agreementViewStringsFabric],
  [Flavors.WindowsFabric, agreementViewStringsFabric],
  [Flavors.Win11OobeFabric, agreementViewStringsFabric],
  [Flavors.Xbox, agreementViewStringsFabric],
]);

/**
 * Initialize strings
 * @param flavor The active flavor.
 */
export function initAgreementViewStringsFabric(flavor: Flavors) {
  AgreementViewStringsConfigFabric.initialize({
    agreementViewStrings: agreementViewStringsFabricMap.get(flavor),
  });
}
