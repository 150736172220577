import { type IErrorViewStrings } from "../error-view-interface";

export const errorViewStringsFabric: IErrorViewStrings = {
  accountUnavailableTitle: getLocalString("AccountUnavailable_Title"),
  lockoutReasonIncorrectPasswordTooManyTimes: getLocalString(
    "Lockout_Reason_IncorrectPasswordTooManyTimes",
  ),
  lockoutReasonOrgAccountNotReady: getLocalString("Lockout_Reason_OrgAccountNotReady"),
  resetPasswordLinkText: getLocalString("Lockout_ResetPassword_LinkText"),
  remainSignedInText: getLocalString("SwitchUser_RemainSignedIn"),
  alreadySignedInTitle: getLocalString("AlreadySignedIn_Title"),
  inviteBlockedError: getLocalString("InviteBlocked_Error"),
  serviceBlockedError: getLocalString("ServiceBlocked_Error"),
  idpFailedGenericError: getLocalString("IDPFailed_GenericError"),
  idpFailedError: getLocalString("IDPFailed_Error"),
  lockoutTitle: getLocalString("Lockout_Title"),
  genericErrorTitle: getLocalString("GenericError_Title"),
  lockoutAnotherIdText: getLocalString("Lockout_AnotherID_Text"),
  switchUserSignOutThenSignIn: getLocalString("SwitchUser_SignOutThenSignIn"),
  switchUserDifferentId: getLocalString("SwitchUser_DifferentID"),
  bindFailedAlreadyBoundDescSamsung: getLocalString("BindFailed_AlreadyBound_Desc_Samsung"),
  lockoutDesc: getLocalString("Lockout_Desc"),
};
