import { useContext } from "react";
import { useNavigateBack } from "../../../../hooks/use-navigate-back";
import { useTimeout } from "../../../../hooks/use-timeout";
import { useLoginFlowRedirect } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { getBindProviderProperties } from "../idp-redirect-view-util";

/**
 * Hook that sets a timeout after which, a redirect request is made.
 * @param cxhCallback optional cxhCallBack function
 * @returns a function to cancel the timeout and hence the redirect
 */
export const useRedirectTimeout = (cxhCallback?: () => Promise<void>): (() => void) => {
  // Login context data
  const {
    viewState: { idpRedirectPostParams, idpRedirectProvider, idpRedirectUrl },
  } = useContext(LoginContext);

  // Login config data
  const { facebookFedUrl, githubFedUrl, googleFedUrl, idpRedirectTimeoutMs, linkedInFedUrl } =
    LoginConfig.instance;

  const { redirectUrl: updatedIdpRedirectUrl, redirectPostParams: updatedIdpRedirectPostParams } =
    getBindProviderProperties(idpRedirectUrl, idpRedirectPostParams, idpRedirectProvider, {
      linkedInFedUrl,
      githubFedUrl,
      googleFedUrl,
      facebookFedUrl,
    });

  const navigateBack = useNavigateBack();
  const loginFlowRedirect = useLoginFlowRedirect();
  const cancelRedirect = useTimeout(() => {
    if (cxhCallback) {
      cxhCallback();
    }

    loginFlowRedirect(updatedIdpRedirectUrl, updatedIdpRedirectPostParams, true, true);
  }, idpRedirectTimeoutMs);

  return () => {
    cancelRedirect();
    navigateBack();
  };
};
