import { makeStyles, shorthands } from "@griffel/react";
import { useMergedStyles } from "../../hooks/use-merged-styles";
import { type TileStylesFabric, useTileStylesFabric } from "../fabric";
import * as commonStyleConstants from "./constants-win11-oobe-fabric.styles";
import * as tileStyleConstants from "./tile-constants-win11-oobe-fabric.styles";

const useOverrideTileStyles = makeStyles({
  content: {
    "& > *": { wordWrap: "break-word" },

    lineHeight: "inherit",
    paddingLeft: "0px",
    paddingRight: "0px",
  },

  helpButton: {
    [commonStyleConstants.MOBILE_LAYOUT_SIZE]: {
      marginLeft: `${commonStyleConstants.LIGHTBOX_MOBILE_PADDING / 4}px`,
      marginRight: `${commonStyleConstants.LIGHTBOX_MOBILE_PADDING / 4}px`,
    },
  },

  list: {
    color: "#404040",
    "-ms-user-select": "none",

    textAlign: "left",
    ":global([dir='rtl'])": { textAlign: "right" },
  },

  listItem: {
    display: "block",
    listStyleImage: "circle",
    listStyleType: "disc",
    marginRight: "19px",
    marginBottom: "3px",
    marginLeft: "4px",
    marginTop: "0px",
    "-ms-high-contrast-adjust": "none",
    "-ms-user-select": "none",

    "& div": { textAlign: "left" },
    ":global([dir='rtl'])": { marginLeft: "19px", "& div": { textAlign: "right" } },

    ":focus": {
      boxShadow: "0px 0px 0px 1px #FFF, 0px 0px 0px 3px #000",
      outlineWidth: "none",
      outlineStyle: "none",
      outlineColor: "none",
    },

    ":focus:active": {
      outlineWidth: "none",
      outlineStyle: "none",
      outlineColor: "none",
    },
  },

  listItemInList: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.2169)"),
    ...shorthands.borderRadius("4px"),
    marginBottom: "8px",
    ...shorthands.border("1px", "solid", "#E9E9E9"),
  },

  menuButton: {
    width: "23px",
  },

  middle: {
    ":nth-child(1)": {
      "& + &": { width: "100%" },
    },
  },

  moreOptionsRowTile: {
    backgroundColor: "transparent",

    [commonStyleConstants.MOBILE_LAYOUT_SIZE]: {
      boxShadow: "none",
      paddingLeft: tileStyleConstants.TILE_ROW_MOBILE_MARGIN,
      paddingRight: tileStyleConstants.TILE_ROW_MOBILE_MARGIN,
      outlineWidth: tileStyleConstants.OUTLINE_WIDTH,
      outlineStyle: "solid",
      outlineColor: "rgba(0,0,0,.4)",
    },
  },

  moreOptionsTile: {
    backgroundColor: "transparent",
    maxWidth: `${commonStyleConstants.LIGHTBOX_MAX_WIDTH}px`,

    [commonStyleConstants.MOBILE_LAYOUT_SIZE]: {
      marginTop: "1px",
      marginBottom: "88px",
      paddingLeft: `${commonStyleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      paddingRight: `${commonStyleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      width: "100vw",
      minWidth: "auto",
    },
  },

  rowTile: {
    ":not(.no-pick)": {
      cursor: "pointer",

      ":active": { backgroundColor: "transparent", color: "inherit" },

      ":hover": { backgroundColor: "transparent", color: "inherit" },
    },

    color: "inherit",
    cursor: "pointer",
    display: "block",
    marginBottom: "0px",
    outlineColor: "none",
    outlineStyle: "none",
    outlineWidth: "none",

    [commonStyleConstants.MOBILE_LAYOUT_SIZE]: {
      marginLeft: commonStyleConstants.LIGHTBOX_MOBILE_PADDING,
      marginRight: commonStyleConstants.LIGHTBOX_MOBILE_PADDING,
    },
  },

  tileButtonSettings: {
    "&[role=listitem]": { display: "table", marginLeft: "0px" },

    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },

    ":focus": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      outlineColor: "none",
      outlineStyle: "none",
      outlineWidth: "none",
    },

    ":active": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },

    // Leave room for the help button on tiles
    ...shorthands.padding(
      `${commonStyleConstants.TILE_PADDING}px`,
      `${commonStyleConstants.TILE_PADDING + tileStyleConstants.HELP_BUTTON_WIDTH}px`,
      `${commonStyleConstants.TILE_PADDING}px`,
      `${commonStyleConstants.TILE_PADDING}px`,
    ),
    width: "calc(100% - 19px)",
  },

  tileContainer: {
    maxWidth: `${commonStyleConstants.LIGHTBOX_MAX_WIDTH}px`,
  },

  tileImage: {
    width: tileStyleConstants.TILE_IMAGE_SIZE,
    height: tileStyleConstants.TILE_IMAGE_SIZE,
  },
});

export type TileStylesWin11OobeFabric = TileStylesFabric &
  Partial<ReturnType<typeof useOverrideTileStyles>>;

export const useTileStylesWin11OobeFabric = () =>
  useMergedStyles(useTileStylesFabric(), useOverrideTileStyles()) as TileStylesWin11OobeFabric;
