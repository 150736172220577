import { useContext } from "react";
import { ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { type RedirectPostParams } from "../../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import {
  appendOrReplaceQueryStringParams,
  trim,
  trimQsParams,
} from "../../../../utilities/strings-helper";
import { useLoginFlowRedirect } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";

/**
 * @returns a handler for when the other IDP tile is clicked
 */
export const useOtherIdpTileClickHandler = (): (() => void) => {
  // Global context data
  const {
    globalState: {
      user: { displayUsername },
    },
    dispatchStateChange: dispatchGlobalStateChange,
  } = useContext(GlobalContext);

  // Login flow context data
  const {
    viewState: { otherIdpRedirectUrl: initialOtherIdpRedirectUrl },
  } = useContext(LoginContext);

  // Login config data
  const { otherIdpPostParams: initialOtherIdpPostParams } = LoginConfig.instance;

  const loginFlowRedirect = useLoginFlowRedirect();

  return () => {
    const { unsafeUnescapedString } = displayUsername;
    const unsafeDisplayUsername = trim(unsafeUnescapedString);

    const otherIdpRedirectUrl = appendOrReplaceQueryStringParams(initialOtherIdpRedirectUrl, {
      username: encodeURIComponent(unsafeDisplayUsername),
      login_hint: encodeURIComponent(unsafeDisplayUsername),
    });

    const otherIdpRedirectPostParams: RedirectPostParams | undefined = initialOtherIdpPostParams
      ? { ...initialOtherIdpPostParams }
      : undefined;
    if (otherIdpRedirectPostParams) {
      otherIdpRedirectPostParams.username = unsafeDisplayUsername;
    }

    loginFlowRedirect(otherIdpRedirectUrl, otherIdpRedirectPostParams, true);
    dispatchGlobalStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.IdpDisambiguation,
      destination: trimQsParams(otherIdpRedirectUrl),
    });
  };
};
