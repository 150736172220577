import { type FlowId } from "../../constants";

export type AgreementViewProps = {
  hostingFlow: FlowId;
};

export enum AgreementType {
  A11yConforme = "a11yConforme",
  ChinaPiplLearnMore = "chinapipllearnmore",
  ChinaPrivacy = "chinaprivacy",
  Impressum = "impressum",
  KoreaConsentDataCollection = "koreadatacollection",
  KoreaConsentDataProvision = "koreadataprovision",
  Privacy = "privacy",
  TermsOfUse = "tou",
}
