import { type AuthenticationMethod, type ChallengeAuthenticationMethod } from "../model/credential";
import type { AcmaState } from "./acma-context";

export const enum AcmaActionType {
  UpdateApiResultAction,
  UpdateAvailableRecoveryCredentialsAction,
  UpdateActiveCredentialAction,
}

type UpdateActiveCredentialAction = {
  type: AcmaActionType.UpdateActiveCredentialAction;
  activeCredential: AuthenticationMethod;
};

type UpdateApiResultAction = {
  type: AcmaActionType.UpdateApiResultAction;
  continuationToken?: string;
  links?: AcmaState["links"];
};

type UpdateAvailableRecoveryCredentialsAction = {
  type: AcmaActionType.UpdateAvailableRecoveryCredentialsAction;
  availableRecoveryCredentials: Array<ChallengeAuthenticationMethod>;
};

export type AcmaActions =
  | UpdateApiResultAction
  | UpdateAvailableRecoveryCredentialsAction
  | UpdateActiveCredentialAction;

/**
 * ACMA reducer
 * @param state The current reducer state
 * @param action The action to perform on the state
 * @returns The updated state
 */
export default function acmaReducer(state: AcmaState, action: AcmaActions): AcmaState {
  const actionType = action.type;
  switch (actionType) {
    case AcmaActionType.UpdateApiResultAction:
      return {
        ...state,
        continuationToken: action.continuationToken || "",
        links: { next: action.links?.next || state.links?.next },
      };
    case AcmaActionType.UpdateAvailableRecoveryCredentialsAction:
      return { ...state, availableRecoveryCredentials: action.availableRecoveryCredentials };
    case AcmaActionType.UpdateActiveCredentialAction:
      return { ...state, activeCredential: action.activeCredential };
    default:
      throw new Error(`AcmaReducer received unexpected action ${actionType}`);
  }
}
