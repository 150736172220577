import { makeStyles, shorthands } from "@griffel/react";

export const useCriticalErrorPageStylesFabric = makeStyles({
  errorComponentStyles: {
    width: "90%",
    ...shorthands.margin(0, "auto"),
  },

  errorTitleStyles: {
    fontSize: "34px",
    fontWeight: "200",
    lineHeight: "40px",
    ...shorthands.padding("28px, 0px"),
  },
});

export type CriticalErrorPageStylesFabric = ReturnType<typeof useCriticalErrorPageStylesFabric>;
