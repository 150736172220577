import { type ImagesExtension } from "../images-helper";

/**
 * If file extension is not supplied, SVG will be used if supported,
 *  else PNG will be used.
 */
export type AccessibleImageUrls = { dark: string; light: string; extension?: ImagesExtension };

/** Please keep alphabetical. */

/* Accessible images can be pre-defined here for ease of reuse, but it's not
 * required. AccessibleImageUrls() will generate one if passed the name of an image
 * from one of the image-helpers if it is not found in definedAccessibleImageUrls.
 * Note that if an accessible image is not defined here, thee dark/light images
 * must have the same base name in the appropriate image helper, the light image
 * suffixed with "_white".
 */

export const accessPassCredIcon: AccessibleImageUrls = {
  dark: "cred_option_accesspass",
  light: "cred_option_accesspass_white",
};

export const addUser: AccessibleImageUrls = {
  dark: "add_user",
  light: "add_user_white",
  extension: "png",
};

export const arrowLeft: AccessibleImageUrls = {
  dark: "arrow_left",
  light: "arrow_left_white",
};

export const arrowRight: AccessibleImageUrls = {
  dark: "arrow_right",
  light: "arrow_right_white",
};

export const authenticatorIcon: AccessibleImageUrls = {
  dark: "picker_verify_code",
  light: "picker_verify_code_white",
};

export const authenticatorCredIcon: AccessibleImageUrls = {
  dark: "cred_option_authenticator",
  light: "cred_option_authenticator_white",
};

export const authenticatorV2fluentIcon: AccessibleImageUrls = {
  dark: "picker_verify_fluent_authenticator",
  light: "picker_verify_fluent_authenticator_white",
  extension: "svg",
};

export const authenticatorV2Icon: AccessibleImageUrls = {
  dark: "picker_verify_authenticator",
  light: "picker_verify_authenticator_white",
};

export const blockVerifyIcon: AccessibleImageUrls = {
  dark: "block_verify",
  light: "block_verify_white",
  extension: "png",
};

export const callIcon: AccessibleImageUrls = {
  dark: "picker_verify_call",
  light: "picker_verify_call_white",
};

export const certificateCredIcon: AccessibleImageUrls = {
  dark: "cred_option_certificate",
  light: "cred_option_certificate_white",
};

export const challengeAudioIcon: AccessibleImageUrls = {
  dark: "challenge_audio",
  light: "challenge_audio",
};

export const challengeImageIcon: AccessibleImageUrls = {
  dark: "challenge_image",
  light: "challenge_image",
};

export const challengeRefreshIcon: AccessibleImageUrls = {
  dark: "challenge_refresh",
  light: "challenge_refresh",
};

export const challengeSpeakerIcon: AccessibleImageUrls = {
  dark: "challenge_speaker",
  light: "challenge_speaker",
};

export const checkMarkBadgeIcon: AccessibleImageUrls = {
  dark: "checkmark_badge",
  light: "checkmark_badge_white",
};

export const checkMarkIcon: AccessibleImageUrls = {
  dark: "check_small",
  light: "check_small_white",
};

export const checkMarkFilledIcon: AccessibleImageUrls = {
  dark: "check_filled",
  light: "check_filled",
};

export const closeIcon: AccessibleImageUrls = {
  dark: "close",
  light: "close_white",
};

export const chevronClosedIcon: AccessibleImageUrls = {
  dark: "chevron_closed",
  light: "chevron_closed_white",
};

export const deviceShieldCheckmark: AccessibleImageUrls = {
  dark: "device_shield_checkmark_white",
  light: "device_shield_checkmark",
  extension: "png",
};

export const documentationIcon: AccessibleImageUrls = {
  dark: "documentation",
  light: "documentation_white",
};

export const emailIcon: AccessibleImageUrls = {
  dark: "picker_verify_email",
  light: "picker_verify_email_white",
};

export const downArrowIcon: AccessibleImageUrls = {
  dark: "dropdown_caret",
  light: "dropdown_caret_white",
};

export const googleCredIcon: AccessibleImageUrls = {
  dark: "cred_option_google",
  light: "cred_option_google",
  extension: "svg",
};

export const facebookCredIcon: AccessibleImageUrls = {
  dark: "cred_option_facebook",
  light: "cred_option_facebook",
  extension: "png",
};

export const fidoCredIcon: AccessibleImageUrls = {
  dark: "cred_option_fido",
  light: "cred_option_fido_white",
};

export const passkeyCredIcon: AccessibleImageUrls = {
  dark: "cred_option_passkey",
  light: "cred_option_passkey_white",
};

export const forgotCredIcon: AccessibleImageUrls = {
  dark: "cred_option_forgot",
  light: "cred_option_forgot_white",
};

export const genericAppCredIcon: AccessibleImageUrls = {
  dark: "cred_option_generic_app",
  light: "cred_option_generic_app_white",
};

export const githubCredIcon: AccessibleImageUrls = {
  dark: "cred_option_github",
  light: "cred_option_github_white",
};

export const koreaConsentCheck: AccessibleImageUrls = {
  dark: "korea_consent_check",
  light: "korea_consent_check",
};

export const koreaConsentUncheck: AccessibleImageUrls = {
  dark: "korea_consent_uncheck",
  light: "korea_consent_uncheck",
};

export const linkedInCredIcon: AccessibleImageUrls = {
  dark: "cred_option_linkedin",
  light: "cred_option_linkedin_white",
};

export const marchingAntsGif: AccessibleImageUrls = {
  dark: "marching_ants",
  light: "marching_ants_white",
  extension: "gif",
};

export const msLogoIcon: AccessibleImageUrls = {
  dark: "microsoft_logo",
  light: "microsoft_logo_white",
};

export const multiplayerJoinIcon: AccessibleImageUrls = {
  dark: "multiplayer_join",
  light: "multiplayer_join",
};

export const m365ShieldBadgeImage: AccessibleImageUrls = {
  dark: "m365_shield",
  light: "m365_shield_white",
};

export const offlineAccountIcon: AccessibleImageUrls = {
  dark: "cred_option_offlineaccount",
  light: "cred_option_offlineaccount_white",
};

export const passkeyGif: AccessibleImageUrls = {
  dark: "passkey",
  light: "passkey",
  extension: "gif",
};
export const passwordCredIcon: AccessibleImageUrls = {
  dark: "cred_option_password",
  light: "cred_option_password_white",
};

export const passkeyError: AccessibleImageUrls = {
  dark: "passkey_error",
  light: "passkey_error",
};

export const passkeySuccess: AccessibleImageUrls = {
  dark: "passkey_success",
  light: "passkey_success",
};

export const peopleShield: AccessibleImageUrls = {
  dark: "people_shield",
  light: "people_shield_white",
  extension: "png",
};

export const personalAccountIcon: AccessibleImageUrls = {
  dark: "personal_account",
  light: "personal_account_white",
};

export const pickerAccountAadIcon: AccessibleImageUrls = {
  dark: "picker_account_aad",
  light: "picker_account_aad",
};

export const pickerAccountAddIcon: AccessibleImageUrls = {
  dark: "picker_account_add",
  light: "picker_account_add",
};

export const pickerAccountMsaIcon: AccessibleImageUrls = {
  dark: "picker_account_msa",
  light: "picker_account_msa",
};

export const pickerMoreIcon: AccessibleImageUrls = {
  dark: "picker_more",
  light: "picker_more_white",
};

export const settingsIcon: AccessibleImageUrls = {
  dark: "settings",
  light: "settings",
};

export const shieldExclamationMark: AccessibleImageUrls = {
  dark: "shield_exclamationmark_white",
  light: "shield_exclamationmark",
  extension: "png",
};

export const shieldPersonCheckmark: AccessibleImageUrls = {
  dark: "shield_person_checkmark",
  light: "shield_person_checkmark_white",
  extension: "png",
};

export const shieldPersonDismiss: AccessibleImageUrls = {
  dark: "shield_person_dismiss_white",
  light: "shield_person_dismiss",
  extension: "png",
};

export const signInOptionsIcon: AccessibleImageUrls = {
  dark: "signin_options",
  light: "signin_options_white",
};

export const smsIcon: AccessibleImageUrls = {
  dark: "picker_verify_sms",
  light: "picker_verify_sms_white",
};

export const timerIcon: AccessibleImageUrls = {
  dark: "timer",
  light: "timer",
};

export const userWithFamily: AccessibleImageUrls = {
  dark: "user_with_family_white",
  light: "user_with_family",
};

export const definedAccessibleImageUrls = new Map<string, AccessibleImageUrls>([
  ["accessPassCredIcon", accessPassCredIcon],
  ["addUser", addUser],
  ["arrowLeft", arrowLeft],
  ["arrowRight", arrowRight],
  ["authenticatorIcon", authenticatorIcon],
  ["authenticatorCredIcon", authenticatorCredIcon],
  ["authenticatorV2fluentIcon", authenticatorV2fluentIcon],
  ["authenticatorV2Icon", authenticatorV2Icon],
  ["callIcon", callIcon],
  ["certificateCredIcon", certificateCredIcon],
  ["checkMarkIcon", checkMarkIcon],
  ["checkMarkFilledIcon", checkMarkFilledIcon],
  ["chevronClosedIcon", chevronClosedIcon],
  ["closeIcon", documentationIcon],
  ["downArrowIcon", downArrowIcon],
  ["emailIcon", emailIcon],
  ["fidoCredIcon", fidoCredIcon],
  ["forgotCredIcon", forgotCredIcon],
  ["githubCredIcon", githubCredIcon],
  ["koreaConsentCheck", koreaConsentCheck],
  ["koreaConsentUncheck", koreaConsentUncheck],
  ["linkedInCredIcon", linkedInCredIcon],
  ["marchingAntsGif", marchingAntsGif],
  ["msLogoIcon", msLogoIcon],
  ["offlineAccountIcon", offlineAccountIcon],
  ["passkeyCredIcon", passkeyCredIcon],
  ["passkeyGif", passkeyGif],
  ["passwordCredIcon", passwordCredIcon],
  ["peopleShield", peopleShield],
  ["personalAccountIcon", personalAccountIcon],
  ["pickerAccountAad", pickerAccountAadIcon],
  ["pickerAccountAddIcon", pickerAccountAddIcon],
  ["pickerAccountMsaIcon", pickerAccountMsaIcon],
  ["pickerMoreIcon", pickerMoreIcon],
  ["shieldPersonCheckmark", shieldPersonCheckmark],
  ["smsIcon", smsIcon],
  ["userWithFamily", userWithFamily],
]);

/**
 * getAccessibleImage generates an accessible image for use with the AccessibleImage
 * component. This will retrieve a pre-defined accessible image from accessible-images
 * or generate one based on the file name passed in.
 * @param image (Required) Image index number or string name of the primary/dark image
 * @param ext (Optional) Image extension - defaults is svg if not set
 * @returns Object of type AccessibleImages
 */
export function getAccessibleImageUrls(
  image: number | string,
  ext?: ImagesExtension,
): AccessibleImageUrls {
  const darkImageName = typeof image === "number" ? image.toString() : image;

  if (definedAccessibleImageUrls.has(darkImageName)) {
    return definedAccessibleImageUrls.get(darkImageName)!;
  }

  const lightImageName = `${darkImageName}_white`;

  return {
    dark: darkImageName,
    light: lightImageName,
    extension: ext,
  };
}
