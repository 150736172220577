import React, { type ReactNode, forwardRef } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { gamepadMainContentAttributes } from "../constants";

export type DialogProps = {
  /** Header Component of the dialog. Default styling considers an image followed by a title */
  headerComponent?: ReactNode;
  /** Description component of the dialog. Default styling considers a separate div for every description */
  descriptionComponent?: ReactNode;
  /** Footer component of the dialog. At least one button with the initial focus set to true based on accessibility requirements  */
  footerComponent?: ReactNode;
  /**  Accessible name */
  labelledBy?: string;
  /** Accessible description  */
  describedBy?: string;
};

/**
 * This is a pop up dialog shown on the converged/fabric lightbox views
 * used to show useful informational info. See component story for an example
 * on how to implement visibility and focus-lock
 * @param props DialogProps
 * @returns the Dialog component
 */
export const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const { useDialogStyles, useCommonStyles: useLightboxFabricStyles } = StylesConfig.instance;
  const { headerComponent, descriptionComponent, footerComponent, labelledBy, describedBy } = props;
  const dialogStyles = useDialogStyles();
  const lightboxStyles = useLightboxFabricStyles();
  const outerClasses = mergeClasses(lightboxStyles.outer, dialogStyles.outer);
  const contentClasses = mergeClasses(dialogStyles.content, dialogStyles.font);

  return (
    <div className={outerClasses} ref={ref}>
      <div className={dialogStyles.middle}>
        <div
          className={mergeClasses(dialogStyles.inner, dialogStyles.winFlyout)}
          role="dialog"
          aria-labelledby={labelledBy}
          aria-describedby={describedBy}
          {...gamepadMainContentAttributes}
        >
          <div className={contentClasses}>
            <div className={dialogStyles.header} data-testid="header">
              {headerComponent}
            </div>
            <div data-testid="description">{descriptionComponent}</div>
            <div className={dialogStyles.buttonContainer} data-testid="footer">
              {footerComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
