import React from "react";

export type ArkoseEnforcementIframeProps = {
  url: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  className: string;
  title: string;
};

/**
 * Shared component to render the Arkose Enforcement Iframe
 * @param props Props for Arkose Enforcement Iframe
 * @returns Arkose enforcement iframe
 */
export const ArkoseEnforcementIframe: React.FC<ArkoseEnforcementIframeProps> =
  function ArkoseEnforcementIframe(props) {
    const { url, iframeRef, className, title } = props;
    return (
      <iframe
        src={url}
        ref={iframeRef}
        className={className}
        id="enforcementFrame"
        data-testid="enforcementFrame"
        sandbox="allow-scripts allow-forms allow-same-origin allow-popups"
        title={title}
      />
    );
  };
