import React, { useCallback, useEffect, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../../config/styles-config";
import { isHighContrast } from "../../../../utilities/browser-helper";
import { downArrowIcon } from "../../../../utilities/image-helpers/accessible-images";
import { OptionsMenuButton } from "../../../options-menu-button/options-menu-button";
import { type PhoneNumberDropdownProps } from "../phone-number-types";
import { createCountryMap } from "../phone-utils";

/**
 * PhoneCountryDropdown component for Fabric
 * @param props The properties for this component.
 * @param props.defaultCountryData The default country information.
 * @param props.countryData The array of country information.
 * @param props.useInlinePhoneNumber Whether the country code and phone number are shown inline. If true, the component will show the label text (e.g., +1).
 * If false, the component will show the full country display text (e.g., United States (+1)).
 * @param props.hasError Whether the country code dropdown is showing error.
 * @param props.hasInitialFocus Whether the country code dropdown is focused by default.
 * @param props.hasFocus Whether the country code dropdown is currently focused.
 * @param props.onChange The value change callback. Enables the external component to do something with the selected country value.
 * @returns A dropdown component with a list of phone country codes.
 */
export const PhoneCountryDropdownFabric: React.FC<PhoneNumberDropdownProps> =
  function PhoneCountryDropdownFabric(props) {
    const {
      value,
      countryData,
      useInlinePhoneNumber,
      displayErrorStyling = false,
      hasFocus = false,
      hasInitialFocus = false,
      onChange = () => {},
    } = props;
    const { useCommonStyles, usePhoneCountryDropdownStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();
    const phoneCountryDropdownStyles = usePhoneCountryDropdownStyles();

    const countryMap = createCountryMap(countryData);

    const [labelCss, setLabelCss] = useState(phoneCountryDropdownStyles.phoneCountryCodeLabel);

    const isHighContrastMode = isHighContrast();

    const focusStyling = displayErrorStyling
      ? phoneCountryDropdownStyles.labelHasErrorFocusBox
      : phoneCountryDropdownStyles.labelHasFocus;

    const focusCss = isHighContrastMode
      ? phoneCountryDropdownStyles.phoneCountryCodeLabel
      : mergeClasses(phoneCountryDropdownStyles.phoneCountryCodeLabel, focusStyling);

    const errorCss = isHighContrastMode
      ? phoneCountryDropdownStyles.phoneCountryCodeLabel
      : mergeClasses(
          phoneCountryDropdownStyles.phoneCountryCodeLabel,
          phoneCountryDropdownStyles.labelHasError,
        );

    const getLabelCss = useCallback(() => {
      if (hasFocus) {
        return focusCss;
      }

      if (displayErrorStyling) {
        return errorCss;
      }

      return phoneCountryDropdownStyles.phoneCountryCodeLabel;
    }, [
      focusCss,
      displayErrorStyling,
      hasFocus,
      errorCss,
      phoneCountryDropdownStyles.phoneCountryCodeLabel,
    ]);

    // When useInlinePhoneNumber is true, the component displays the label text (e.g., +1) and hides the
    // select/dropdown display text (e.g., United States (+1)). The mouse focus and blur handler are used to apply the
    // CSS focus styles to the label element for dropdown mouse click events.
    const onFocusHandler = () => {
      setLabelCss(focusCss);
    };

    const unfocusedCss = mergeClasses(
      phoneCountryDropdownStyles.phoneCountryCodeLabel,
      displayErrorStyling ? errorCss : "",
    );

    const onBlurHandler = (event: React.FocusEvent<HTMLDivElement>) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        setLabelCss(unfocusedCss);
      }
    };

    const onOutsideClickHandler = () => {
      setLabelCss(unfocusedCss);
    };

    useEffect(() => {
      setLabelCss(getLabelCss());
    }, [hasInitialFocus, getLabelCss]);

    const dropdownStyle = useInlinePhoneNumber
      ? phoneCountryDropdownStyles.phoneCountryBoxInline
      : mergeClasses(commonStyles.formGroup, phoneCountryDropdownStyles.phoneCountryBoxNotInline);

    const menuButtonClass = mergeClasses(
      phoneCountryDropdownStyles.phoneCountryCodeMenuButtonStyle,
      commonStyles.formControlRelative,
    );

    return (
      <div
        className={displayErrorStyling ? mergeClasses(dropdownStyle, "has-error") : dropdownStyle}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        data-testid="phoneCountryDropdown"
      >
        <OptionsMenuButton
          ariaLabel={getLocalString("General_CountryCode")}
          icon={{
            urls: downArrowIcon,
            className: phoneCountryDropdownStyles.downArrow,
            role: "presentation",
          }}
          menuClassName={phoneCountryDropdownStyles.dropdown}
          menuButtonIconClassName={menuButtonClass}
          menuTestId="phoneCountry"
          menuItemList={countryData.map((country) => ({
            itemId: country.iso,
            itemText: country.displayValue,
            isVisible: true,
            hasFocus: country.iso === value.iso,
            skipHasFocusDelay: true, // immediately set focus to active element
            ariaSelected: country.iso === value.iso,
            onClick: () => onChange(countryMap[country.iso]),
          }))}
          menuLabel={{
            labelText: useInlinePhoneNumber ? `\u200E+${value.code}` : value.displayValue,
            labelClassName: labelCss,
            labelTestId: "phoneCountryLabel",
          }}
          onOutsideClick={onOutsideClickHandler}
          closeOnSelect
          hasFocus={hasFocus}
          hasInitialFocus={hasInitialFocus}
          isComboBox
        />
      </div>
    );
  };
