import { useState } from "react";
import { Flavors, UserFlowType, ViewId } from "../../../../../constants";
import featuresConfig from "../../../../../features-config";
import GlobalConfig from "../../../../../global-config";
import {
  useBackButtonControl,
  useEffectOnce,
  useNavigateDirection,
  useNavigateOrRedirectBack,
} from "../../../../../hooks";
import { useBackButtonWithUserFlowTypeChangeHandler } from "../../../../../hooks/use-back-button-with-user-flow-type-change-handler";
import { useUpdateBackArrowButton } from "../../../../../hooks/use-update-back-arrow-button";
import { useIsBackButtonSupportedOnInitialView } from "../../../../../hooks/win11-oobe-fabric/use-is-back-button-supported-on-initial-view";
import { logCxhEvent } from "../../../../../utilities/cxh-handlers/cxh-handler";
import LoginConfig from "../../../login-config";
import { useLoginContext } from "../../../login-context";
import type { IUsernameViewSecondaryButtonProperties } from "../username-view-interface";
import type { IUsernameViewStringsFabric } from "./username-view-strings-fabric";

export const useSecondaryButtonPropertiesFabric = (
  usernameViewStrings: IUsernameViewStringsFabric,
): IUsernameViewSecondaryButtonProperties => {
  const { activeFlavor, allowCancel, showButtons } = GlobalConfig.instance;
  const { showOfflineAccountLearnMore } = LoginConfig.instance;
  const { backButtonLabel, learnMoreButtonLabel } = usernameViewStrings;
  const { isSimplifiedChildAccountCreation } = featuresConfig.instance;
  const {
    viewState: { initialViewId },
  } = useLoginContext();
  const isInitialView = !useBackButtonControl();

  const navigate = useNavigateDirection();
  const navigateOrRedirectBack = useNavigateOrRedirectBack();

  const [secondaryButtonProperties, setSecondaryButtonProperties] =
    useState<IUsernameViewSecondaryButtonProperties>({
      renderSecondaryButton: false,
      secondaryButtonClickHandler: undefined,
      secondaryButtonId: "",
      secondaryButtonLabel: "",
    });

  const updateBackArrowButton = useUpdateBackArrowButton();
  const isBackButtonSupportedOnInitialView = useIsBackButtonSupportedOnInitialView();
  const childFlowBackHandler = useBackButtonWithUserFlowTypeChangeHandler(
    navigateOrRedirectBack,
    UserFlowType.Child,
  );

  useEffectOnce(() => {
    if (showButtons) {
      if (isInitialView) {
        if (activeFlavor === Flavors.Win11OobeFabric) {
          isBackButtonSupportedOnInitialView((isSupported: boolean, useCancelText?: boolean) => {
            updateBackArrowButton(isSupported);
            if (useCancelText) {
              setSecondaryButtonProperties({
                renderSecondaryButton: true,
                secondaryButtonLabel: backButtonLabel,
                secondaryButtonClickHandler: navigateOrRedirectBack,
                secondaryButtonId: "backButtonId",
              });
            } else if (showOfflineAccountLearnMore) {
              logCxhEvent("Identity.OOBE.Login.LearnMoreOfflineAccount.Visible");
              setSecondaryButtonProperties({
                renderSecondaryButton: true,
                secondaryButtonLabel: learnMoreButtonLabel,
                secondaryButtonClickHandler: () => {
                  logCxhEvent("Identity.OOBE.Login.LearnMoreOfflineAccount.Clicked");
                  navigate(ViewId.Username, ViewId.LearnMoreOfflineAccount);
                },
                secondaryButtonId: "learnMoreButtonId",
              });
            }
          });
        } else if (allowCancel) {
          updateBackArrowButton(true);
          setSecondaryButtonProperties({
            renderSecondaryButton: true,
            secondaryButtonLabel: backButtonLabel,
            secondaryButtonClickHandler: navigateOrRedirectBack,
            secondaryButtonId: "backButtonId",
          });
        }
      } else {
        const secondaryButtonClickHandler =
          isSimplifiedChildAccountCreation && initialViewId === ViewId.Benefits
            ? childFlowBackHandler
            : navigateOrRedirectBack;
        updateBackArrowButton(true);
        setSecondaryButtonProperties({
          renderSecondaryButton: true,
          secondaryButtonLabel: backButtonLabel,
          secondaryButtonClickHandler,
          secondaryButtonId: "backButtonId",
        });
      }
    }
  });

  return secondaryButtonProperties;
};
