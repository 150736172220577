import React from "react";
import { MemoryRouter } from "react-router-dom";
import { AuthenticationProvider } from "../../authentication-context";
import { ChallengeProvider } from "../../challenge-reducer";
import { DfpIframe } from "../../components/device-finger-printing/dfp-iframe";
import Shell from "../../components/shell/fabric/shell-fabric";
import { Flavors, FlowId, ViewId } from "../../constants";
import { BaseSisuProvidersFabric } from "../../context/sisu-providers/fabric/base-sisu-providers-fabric";
import { LoginRoutesFabric } from "../../flows/login/fabric/login-flow-fabric";
import { LoginProvider } from "../../flows/login/login-context";
import { shouldAddUserNameToInitialEntries } from "../../flows/login/login-util";
import { initExp } from "../../utilities/client-experimentation/client-experiment-helper";
import { getRouteFromViewId } from "../../utilities/routing-helper";
import { initLoginAppFabric } from "./model/init-login-app-fabric";

const serverData = window.ServerData;
const activeFlavor = Flavors.Fabric;

const {
  initialGlobalState,
  initialLoginState,
  initialAuthenticationState,
  initialHistoryState,
  initialCustomizationState,
  initialChallengeState,
} = initLoginAppFabric(serverData, activeFlavor);

initExp(activeFlavor, FlowId.Login);

/**
 * The app encapsulating the Login flow. This is the outermost component
 * and will be rendered onto the page using ReactDOM.render.
 * @returns An instance of the Login app
 * NOTE: This will be eventually replaced by ./login-app-fabric.tsx
 */
export const LoginFabric: React.FC = function LoginFabric() {
  // This will add username view to the stack giving a back arrow and navigation to go 'back' to username
  // in case of an error on another view
  const initialEntries = shouldAddUserNameToInitialEntries()
    ? [getRouteFromViewId(ViewId.Username), "/"]
    : ["/"];

  return (
    <MemoryRouter initialEntries={initialEntries}>
      <AuthenticationProvider initialState={initialAuthenticationState}>
        <BaseSisuProvidersFabric
          initialGlobalState={initialGlobalState}
          initialHistoryState={initialHistoryState}
          initialCustomizationState={initialCustomizationState}
        >
          <LoginProvider initialState={initialLoginState}>
            <ChallengeProvider initialState={initialChallengeState}>
              <Shell>
                <LoginRoutesFabric />
                <DfpIframe />
              </Shell>
            </ChallengeProvider>
          </LoginProvider>
        </BaseSisuProvidersFabric>
      </AuthenticationProvider>
    </MemoryRouter>
  );
};
