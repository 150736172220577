import { ConfigWrapper } from "./utilities/config-wrapper";
import type { ServerData } from "./utilities/server-data";

export const defaultFeaturesConfig = {
  enableAcmaRecoveryFlow: false,
  fixPhoneDisambigSignupRedirect: false,
  fixUseDifferentMethod: false,
  isGamingFlow: false,
  isSimplifiedChildAccountCreation: false,
  loadGamepadNavigationModule: false,
  loadStringCustomizationPromises: false,
  useHighContrastDetectionMode: false,
  useNewPhoneNumberComponent: false,
};

// eslint-disable-next-line deprecation/deprecation
const featuresConfig = new ConfigWrapper(defaultFeaturesConfig);

export type FeaturesConfigType = typeof defaultFeaturesConfig;

/**
 * The config wrapper that should be used to access global config properties
 */
export default featuresConfig;

/* ********* ServerData helpers ********** */

/**
 * Initialize the features config object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * @param serverData The IDP-specific server data object that should be used to initialize the features config
 */
export function initFeaturesConfig(serverData: ServerData) {
  const mappedServerData: Partial<typeof defaultFeaturesConfig> = {};

  if (serverData?.fLoadGamepadNavigationModule !== undefined) {
    mappedServerData.loadGamepadNavigationModule = serverData.fLoadGamepadNavigationModule;
  }

  if (serverData?.fUseNewPhoneNumberComponent !== undefined) {
    mappedServerData.useNewPhoneNumberComponent = !!serverData.fUseNewPhoneNumberComponent;
  }

  if (serverData?.fIsGamingFlow !== undefined) {
    mappedServerData.isGamingFlow = serverData.fIsGamingFlow;
  }

  if (serverData?.fIsSimplifiedChildAccountCreation !== undefined) {
    mappedServerData.isSimplifiedChildAccountCreation =
      serverData.fIsSimplifiedChildAccountCreation;
  }

  if (serverData?.fFixPhoneDisambigSignupRedirect !== undefined) {
    mappedServerData.fixPhoneDisambigSignupRedirect = serverData.fFixPhoneDisambigSignupRedirect;
  }

  if (serverData?.fFixUseDifferentMethod !== undefined) {
    mappedServerData.fixUseDifferentMethod = serverData.fFixUseDifferentMethod;
  }

  if (serverData?.fEnableAcmaRecoveryFlow !== undefined) {
    mappedServerData.enableAcmaRecoveryFlow = serverData.fEnableAcmaRecoveryFlow;
  }

  if (serverData?.fUseHighContrastDetectionMode !== undefined) {
    mappedServerData.useHighContrastDetectionMode = serverData.fUseHighContrastDetectionMode;
  }

  if (serverData?.fLoadStringCustomizationPromises !== undefined) {
    mappedServerData.loadStringCustomizationPromises = serverData.fLoadStringCustomizationPromises;
  }

  featuresConfig.initialize(mappedServerData);
}
