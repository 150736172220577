import React from "react";
import ProgressIndicatorFabric from "../../../../components/loading-progress/fabric/progress-indicator-fabric";
import { ViewContainerFabric } from "../../../../components/view-container/fabric/view-container-fabric";
import StylesConfig from "../../../../config/styles-config";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useCustomizationContext } from "../../../../context/customization-context";
import { useDocumentTitle } from "../../../../hooks";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import LoginConfig from "../../login-config";
import { getCommonDocumentTitle } from "../../login-util";
import { useSeeHowDataIsManagedViewProperties } from "../hooks/use-see-how-data-is-managed-view-properties";

/**
 * The SeeHowDataIsManagedViewFabric view is shown during the Login flow when the `Learn More` link in the unified
 * consent message or the `See how your data is managed` link in the services consent message are clicked. Both of
 * these messages are displayed inline in the lightbox on the Username view.
 *
 * The view uses an iframe to display some paragraphs that describe how the user's data is managed by Microsoft. It
 * receives this content from a sdx.microsoft.com url that is supplied by the server.
 *
 * @returns A rendered instance of this component
 */
export const SeeHowDataIsManagedViewFabric: React.FC = function SeeHowDataIsManagedViewFabric() {
  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  // Login config data
  const { loginMode } = LoginConfig.instance;

  // Customization context properties
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  const commonStyles = useCommonStyles();

  const documentTitle = getCommonDocumentTitle(
    loginMode,
    friendlyAppName,
    commonLoginStringsFabric,
  );

  const {
    renderProgressIndicator,
    sdxIFrameInlineStyles,
    sdxIFrameSource,
    secondaryButtonLabel,
    secondaryButtonClickHandler,
  } = useSeeHowDataIsManagedViewProperties(commonLoginStringsFabric);

  useActivateView(
    ViewId.SeeHowDataIsManaged,
    FlowId.Login,
    {
      showIdentityBanner: false,
      showPageTitle: false,
    },
    { restoreShowPageTitle: true },
  );
  useDocumentTitle(documentTitle);
  useStaticCommonStyles();

  return (
    <div className={commonStyles.section}>
      {renderProgressIndicator && <ProgressIndicatorFabric />}

      {/* TODO: Use the heading of the iframe post-load as the title for the iframe below */}
      <div id="sdxContainer" className={commonStyles.flexContainer}>
        <iframe
          id="sdxIFrame"
          data-testid="sdxIFrame"
          className={commonStyles.flexIFrame}
          title={documentTitle}
          src={sdxIFrameSource}
          style={sdxIFrameInlineStyles}
        />
      </div>

      {/* TODO: Use the id of the heading of the iframe post-load as the aria-labelledby for the secondary button below */}
      <ViewContainerFabric
        secondaryButton={{
          hasFocus: true,
          label: secondaryButtonLabel,
          onClick: secondaryButtonClickHandler,
        }}
      />
    </div>
  );
};
