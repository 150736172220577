import { ViewId } from "../../../../constants";
import { CredentialType } from "../../../../model/credential/credential-types";
import { useLoginContext } from "../../login-context";

/**
 * This hook provides the properties used in the credential switch links components.
 * @param error The error displayed in the view. Used to determine if initial focus and ariaDescribedBy should be set.
 * @param displaySign The code displayed in the view. Used to determine if initial focus and ariaDescribedBy should be set.
 * @param setIsRequestPending The setIsRequestPending callback.
 * @param ariaDescribedBy The ariaDescribedBy string for the credential switch link.
 * @param showSeeAllDevicesLink Whether or not we show the see all devices link
 * @returns the credential switch links properties
 */
export const useCredentialSwitchProperties = (
  error: string,
  displaySign: string,
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>,
  ariaDescribedBy: string,
  showSeeAllDevicesLink: boolean,
) => {
  const {
    viewState: { credentials },
  } = useLoginContext();
  const { availableCredentials } = credentials;
  const currentCredential = { credentialType: CredentialType.RemoteNGC };

  // When we don't show the 'see all devices' link, and displaySign is not provided (familiar device) or there's an error, we set the
  // initial focus and ariaDescribedBy on the credentialSwitchLink component. Otherwise, initial focus and ariaDescribedBy is set on the displaySign.
  const hasFocus = (!displaySign || !!error) && !showSeeAllDevicesLink;
  const linkAriaDescribedBy = hasFocus ? ariaDescribedBy : "";

  const credSwitchProps = {
    sourceViewId: ViewId.PushNotifications,
    availableCredentials,
    currentCredential,
    setRequestPendingFlag: setIsRequestPending,
    showForgotUsername: false,
    hasFocus,
    linkAriaDescribedBy,
    shouldUpdateOtcCredential: true,
  };

  return credSwitchProps;
};
