import { useContext, useState } from "react";
import { type TileFabricComponent } from "../../../../../components/tile/fabric/tile-fabric";
import { defaultRequestTimeout, ViewId } from "../../../../../constants";
import { GlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { type ISession } from "../../../../../model/user";
import {
  pickerAccountAddIcon,
  pickerAccountMsaIcon,
  pickerMoreIcon,
} from "../../../../../utilities/image-helpers/accessible-images";
import { get } from "../../../../../utilities/request/request-helper";
import {
  appendOrReplaceQueryStringParams,
  replaceTokens,
} from "../../../../../utilities/strings-helper";
import { type UntrustedExternalInputText } from "../../../../../utilities/untrusted-external-input-text";
import LoginConfig from "../../../login-config";
import { LoginContext } from "../../../login-context";
import { type LoginActions } from "../../../login-reducer";
import { type IAccountPickerStrings } from "../fabric/account-picker-strings-fabric";
import { getAccountPickerIframeSource } from "../model/iframe-manager";
import { useGctRequest } from "./use-gct-request";
import { useForgetSessionHandler } from "./use-session-handlers";

/**
 * @returns function that makes a GET request with forget URL
 */
export const useForgetRequest = () => {
  const forgetSession = useForgetSessionHandler();

  const forgetRequest = async (
    forgetUrl: string,
    sessions: ISession[],
    dispatchLoginStateChange: React.Dispatch<LoginActions>,
    setError: React.Dispatch<React.SetStateAction<string | JSX.Element>>,
    accountPickerStrings: IAccountPickerStrings,
    sessionDisplayName?: string,
    sessionId?: string,
  ) => {
    const { forgetFailureErrorText } = accountPickerStrings;
    const forgetUrlWithSessionId = appendOrReplaceQueryStringParams(
      forgetUrl,
      {
        sessionId: encodeURIComponent(sessionId || ""),
      },
      true,
    );

    get(forgetUrlWithSessionId, {
      headers: { "Content-type": "application/json; charset=utf-8" },
      timeout: defaultRequestTimeout,
    }).then(
      () => {
        forgetSession(sessions, dispatchLoginStateChange, sessionId);
      },
      () => {
        setError(replaceTokens(forgetFailureErrorText, sessionDisplayName || ""));
      },
    );
  };

  return forgetRequest;
};

/**
 * @returns handler for sign out or forget clicks
 */
const useOnSignOutOrForgetClickHandler = () => {
  const { logoutUrl, forgetUrl, useForgetUserIframe } = LoginConfig.instance;
  let shouldInjectIframe = true;
  let iFrameSrc = "";
  const {
    viewState: { credentials },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);
  const forgetRequest = useForgetRequest();

  return async (
    option: string,
    setError: React.Dispatch<React.SetStateAction<string | JSX.Element>>,
    setIframeSource: React.Dispatch<React.SetStateAction<string>>,
    accountPickerStrings: IAccountPickerStrings,
    sessionId?: string,
    sessionDisplayName?: string,
  ) => {
    // AAD-TODO: add call to cookie write function based on ests userRoutingCookieConfig property

    if (option === "forget") {
      // if don't use iframe, make a GET request
      if (!useForgetUserIframe) {
        shouldInjectIframe = false;
        await forgetRequest(
          forgetUrl,
          credentials.sessions,
          dispatchLoginStateChange,
          setError,
          accountPickerStrings,
          sessionDisplayName,
          sessionId,
        );
      } else {
        // set iframe source
        iFrameSrc = getAccountPickerIframeSource(forgetUrl, sessionId);
      }
    } else if (option === "signout") {
      iFrameSrc = getAccountPickerIframeSource(logoutUrl, sessionId);
    } else if (option === "signoutForget") {
      iFrameSrc = getAccountPickerIframeSource(logoutUrl, sessionId, true);
    }

    if (shouldInjectIframe) {
      setIframeSource(iFrameSrc);
    }
  };
};

/**
 * Builds account picker tile list
 * @param sessions array of current active sessions
 * @param accountPickerStrings account picker strings for the current flavor
 * @returns account picker tile list
 */
export const useAccountPickerBuilder = (
  sessions: ISession[],
  accountPickerStrings: IAccountPickerStrings,
) => {
  const { protocolRefreshUrl, upgradeRedirectWithUsernameUrl } = LoginConfig.instance;
  const {
    alreadySignedInText,
    useAnotherAccountText,
    signOutText,
    signOutAndForgetText,
    forgetText,
    signInWithAccountAriaLabel,
    optionsMenuAriaLabel,
  } = accountPickerStrings;
  const navigate = useNavigateDirection();
  const userTileClick = useRedirect();
  const gctCall = useGctRequest();
  const menuOptionOnClick = useOnSignOutOrForgetClickHandler();
  let onClickFunction: () => void;
  const [error, setError] = useState<string | JSX.Element>("");
  const [iFrameSource, setIframeSource] = useState("");
  const { dispatchStateChange } = useContext(GlobalContext);

  const errorHandler = (gctError: string | JSX.Element) => {
    setError(gctError);
  };

  const accountPickerList: TileFabricComponent[] = [];
  sessions.forEach((session, index) => {
    let redirectUrl = "";

    // TODO: Add ESTS conditions/images and condition for WindowsSso when browserSSO/TokenBroker is implemented
    if (session.isSignedIn && session.id) {
      redirectUrl = appendOrReplaceQueryStringParams(protocolRefreshUrl, { sessionid: session.id });
      onClickFunction = () => userTileClick(redirectUrl, true);
    } else if (!session.isSignedIn && upgradeRedirectWithUsernameUrl) {
      redirectUrl = upgradeRedirectWithUsernameUrl;
      onClickFunction = () => userTileClick(redirectUrl, true);
    } else {
      onClickFunction = () => gctCall(session.name?.unsafeUnescapedString || "", errorHandler);
    }

    accountPickerList.push({
      mainText:
        (session.isSignedIn
          ? session.fullName?.unsafeUnescapedString
          : session.displayName?.unsafeUnescapedString) || "",
      helpText:
        session.isSignedIn && session.fullName ? session.displayName?.unsafeUnescapedString : "",
      subText: session.isSignedIn ? alreadySignedInText : "",
      image: { urls: pickerAccountMsaIcon, disableHighContrastStyles: true },
      optionsMenuButton: {
        ariaLabel: optionsMenuAriaLabel,
        icon: { urls: pickerMoreIcon },
        menuItemList: [
          {
            itemId: "ForgetLink",
            itemText: forgetText,
            onClick: () =>
              menuOptionOnClick(
                "forget",
                setError,
                setIframeSource,
                accountPickerStrings,
                session.id,
                session.displayName?.unsafeUnescapedString,
              ),
            isVisible: !session.isSignedIn && session.id !== undefined,
            hasFocus: !session.isSignedIn,
          },
          {
            itemId: "SignOutLink",
            itemText: signOutText,
            onClick: () =>
              menuOptionOnClick(
                "signout",
                setError,
                setIframeSource,
                accountPickerStrings,
                session.id,
              ),
            isVisible: session.isSignedIn && session.id !== undefined,
            hasFocus: session.isSignedIn,
          },
          {
            itemId: "SignOutAndForgetLink",
            itemText: signOutAndForgetText,
            onClick: () =>
              menuOptionOnClick(
                "signoutForget",
                setError,
                setIframeSource,
                accountPickerStrings,
                session.id,
              ),
            isVisible: session.isSignedIn,
          },
        ],
        menuTestId: `menu${index}`,
      },
      ariaLabel: replaceTokens(
        signInWithAccountAriaLabel,
        session.fullName?.unsafeUnescapedString || "",
      ),
      onClick: onClickFunction,
    });
  });

  // Use another account
  accountPickerList.push({
    mainText: useAnotherAccountText,
    image: { urls: pickerAccountAddIcon, disableHighContrastStyles: true },
    ariaLabel: useAnotherAccountText,
    onClick: () => {
      // Making sure to clear `user` if user choose to use another account.
      dispatchStateChange({
        type: GlobalActionType.SetUser,
        payload: {
          username: {} as UntrustedExternalInputText,
          displayUsername: {} as UntrustedExternalInputText,
        },
      });

      navigate(ViewId.AccountPicker, ViewId.Username);
    },
  });

  return { accountPickerList, error, setError, iFrameSource };
};
