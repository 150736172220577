import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { ExternalClassName, LayoutTemplateType } from "../constants";
import { useCustomizationContext } from "../context/customization-context";
import { isCssAnimationSupported } from "../utilities/browser-helper";
import { useRouteAnimationFabric } from "./route-animation/fabric/hooks/use-route-animation-fabric";

/**
 * Renders the BoilerPlateText if it is defined
 * @returns BoilerPlateText component
 */
const BoilerPlateText: React.FC = function BoilerPlateText() {
  // @ AAD-TODO: Add CXH logEvent for Win11OobeFabric flavor
  const {
    customizationState: {
      styles: { useTransparentLightbox, layoutTemplate, boilerPlateText },
    },
  } = useCustomizationContext();
  const { useBoilerPlateStyles } = StylesConfig.instance;

  const styles = useBoilerPlateStyles();
  const useAnimation = isCssAnimationSupported();
  const { slideAnimationStyles, onAnimationEnd } = useRouteAnimationFabric();
  const useVerticalSplit = layoutTemplate === LayoutTemplateType.VerticalSplit;
  const classNames = mergeClasses(
    ExternalClassName.boilerPlateText,
    styles.boilerPlateText,
    useTransparentLightbox ? styles.transparent : "",
    useTransparentLightbox && useVerticalSplit ? styles.transparentVerticalSplit : "",
    useVerticalSplit ? styles.verticalSplit : "",
    useAnimation ? slideAnimationStyles : "",
  );

  if (!boilerPlateText) return null;

  const html = { __html: boilerPlateText };

  return (
    <div
      data-testid="boilerPlateText"
      className={classNames}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={html}
      onAnimationEnd={onAnimationEnd}
    />
  );
};

export default BoilerPlateText;
