import { type TelemetryState } from "../../global-context";
import { type LoggedError, ExceptionHelper } from "../../telemetry-helpers/exception-helper";
import { ProofType } from "../proof/proof-types";
import {
  type ConfirmSendCredentialTypes,
  type ConfirmSendUserCredentials,
  type ILoginCredentials,
  type OneTimeCodeCredential,
  type UserCredential,
  CredentialType,
  ExpectedConfirmSendCredentialTypes,
  FederationCredentialTypes,
} from "./credential-types";

/**
 * @param preferredCredential the preferredCredential from LoginContext
 * @returns indicator the preferredCredential is a supported type for the ConfirmSend view
 */
export const isValidPreferredCredential = (
  preferredCredential: CredentialType,
): preferredCredential is ConfirmSendCredentialTypes =>
  ExpectedConfirmSendCredentialTypes.includes(preferredCredential);

/**
 * @param credentialType A user credential type
 * @returns whether the credential type is a federation credential type
 */
export const isFederationCredentialType = (credentialType: CredentialType): boolean =>
  FederationCredentialTypes.includes(credentialType);

/**
 * @param otcCredential the otcCredential from LoginContext
 * @returns indicator the otcCredential is a properly defined OneTimeCodeCredential type
 */
export const isOtcCredential = (
  otcCredential: UserCredential | undefined,
): otcCredential is OneTimeCodeCredential =>
  otcCredential !== undefined &&
  "proof" in otcCredential &&
  otcCredential.proof !== undefined &&
  "display" in otcCredential.proof;

/**
 * This method validates the Login credentials and logs/throws an error if they are not properly defined.
 * @param credentials The credentials from LoginContext
 * @param telemetryState The telemetry state, used for logging
 * @returns The Login credentials, validated as properly typed ConfirmSendUserCredentials
 */
export const validateCredentials = (
  credentials: ILoginCredentials,
  telemetryState: TelemetryState,
) => {
  const { preferredCredential, otcCredential } = credentials;
  let validationError: LoggedError | null = null;

  if (!isValidPreferredCredential(preferredCredential)) {
    validationError = new Error(
      `preferredCredential (${preferredCredential}) is not a supported type`,
    );
  }

  if (preferredCredential === CredentialType.OneTimeCode && !isOtcCredential(otcCredential)) {
    validationError = new Error(
      "otcCredential is not defined correctly for the preferredCredential, OneTimeCode",
    );
  }

  if (validationError) {
    ExceptionHelper.logException(validationError, telemetryState, "InvalidOtcCredentialError");
    validationError.isLogged = true;
    throw validationError;
  }

  return credentials as ConfirmSendUserCredentials;
};

/**
 * @param credentials the validated ConfirmSendUserCredentials
 * @returns The current credential to be used
 */
export const getCurrentCredential = (credentials: ConfirmSendUserCredentials) => {
  const { preferredCredential, otcCredential } = credentials;

  if (preferredCredential === CredentialType.OneTimeCode) {
    return otcCredential;
  }

  // RemoteNGC or PublicIdentifierCode
  return { credentialType: preferredCredential };
};

// ESTS-only options (access pass, certificate, federation, remote login) will be added later on

export type CredentialTypeMap = {
  [id in CredentialType]: number;
};

export const priorityMap: Partial<CredentialTypeMap> = {};
priorityMap[CredentialType.Fido] = 2;
priorityMap[CredentialType.RemoteNGC] = 3;
priorityMap[CredentialType.Password] = 4;
priorityMap[CredentialType.LinkedIn] = 6;
priorityMap[CredentialType.GitHub] = 8;
// Both priorities 11 and 12 are reserved for email OTC and SMS/Voice OTC respectively
priorityMap[CredentialType.OneTimeCode] = 12;
priorityMap[CredentialType.OfflineAccount] = 97;

/**
 * @param credential credential, i.e. password or Auth app
 * @returns Priority of the credential for displaying in a list of options
 */
export const getCredentialPriority = (credential: UserCredential): number | undefined => {
  const { credentialType, proof } = credential;

  if (credentialType === CredentialType.OneTimeCode && proof?.type === ProofType.Email) {
    return 11; // email has priority one higher than SMS and Voice OTC
  }

  return priorityMap[credentialType];
};
