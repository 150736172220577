import React from "react";
import { mergeClasses } from "@griffel/react";
import { ModalDialog } from "../../../../components/modal-dialog";
import { TextButtonFabric } from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import AccountManagementConfig from "../../../../config/account-management-config";
import StylesConfig from "../../../../config/styles-config";
import GlobalConfig from "../../../../global-config";
import {
  commonBindingsFabric,
  FormattedTextWithBindings,
} from "../../../../utilities/formatted-text-with-bindings";
import { replaceTokens } from "../../../../utilities/strings-helper";
import { type IDeviceProperties } from "../../login-context";
import { getDialogListItemText } from "../push-notifications-view-util";
import { pushNotificationsDialogStringsFabric } from "./push-notifications-view-strings-fabric";

export interface IPushNotificationsDialog {
  devices: IDeviceProperties[];
  onDialogClose: () => void;
}

/**
 * Renders the dialog that is shown when the "See all devices link" is clicked in the push notifications view.
 * @param props The properties required for the component
 * @param props.devices The list of device and app information to display in the dialog (e.g., "Authenticator app on Pixel 7")
 * @param props.onDialogClose The function to call when the dialog is closed
 * @returns A rendered instance of this component
 */
export const PushNotificationsDialogFabric: React.FC<IPushNotificationsDialog> =
  function PushNotificationsDialogFabric(props) {
    // Styles config data
    const { useCommonStyles, usePushNotificationsViewStyles } = StylesConfig.instance;

    const { devices, onDialogClose } = props;

    const {
      accountManagementLinkText,
      description,
      primaryButtonLabel,
      title,
      accountManagementLinkTextHosted,
    } = pushNotificationsDialogStringsFabric;

    const { boldText } = commonBindingsFabric;
    const { manageProofsUrl } = AccountManagementConfig.instance;
    const { isHosted } = GlobalConfig.instance;

    const pushNotificationStyles = usePushNotificationsViewStyles();
    const commonStyles = useCommonStyles();

    const dialogHeader = <TitleFabric titleId="dialogHeader" title={title} />;

    const accountLink = (chunks: string[]) => (
      <a id="accountManagementLink" href={manageProofsUrl} target="_blank" rel="noreferrer">
        {chunks[0]}
      </a>
    );

    // Each item in the array is a string that states what app and device the push notification
    // was sent to (e.g., "Outlook app on iPhone 14"). This will be displayed in a list on the dialog.
    const pushNotificationSentToStrings = devices.map((deviceInfo: IDeviceProperties) =>
      getDialogListItemText(deviceInfo, pushNotificationsDialogStringsFabric),
    );

    const dialogDescription = (
      <>
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>{description}</div>
        <ul className={pushNotificationStyles.listItems}>
          {pushNotificationSentToStrings.map((deviceAndAppText: string) => (
            <li key={deviceAndAppText}>{deviceAndAppText}</li>
          ))}
        </ul>
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
          {isHosted ? (
            <FormattedTextWithBindings
              text={replaceTokens(accountManagementLinkTextHosted, manageProofsUrl)}
              embeddedBindings={{ boldText }}
            />
          ) : (
            <FormattedTextWithBindings
              text={accountManagementLinkText}
              embeddedBindings={{ accountLink }}
            />
          )}
        </div>
      </>
    );

    const dialogFooter = (
      <TextButtonFabric hasFocus label={primaryButtonLabel} isPrimary onClick={onDialogClose} />
    );

    return (
      <ModalDialog
        headerComponent={dialogHeader}
        descriptionComponent={dialogDescription}
        footerComponent={dialogFooter}
      />
    );
  };
