import { type InputValue } from "../components/inputs/input/input-types";
import { NoValidationRegEx } from "../components/inputs/input-regex-constants";

/**
 * @param inputValue String value from input field
 * @param validationRegex Regex to match against
 * @returns false if regex is not required or matches
 *          true if the input is empty or does not match regex
 */
export function shouldShowValidationError(inputValue: string, validationRegex: string) {
  if (validationRegex === NoValidationRegEx) {
    return false;
  }

  if (!inputValue || inputValue.trim() === "") {
    return true;
  }

  return !inputValue.match(validationRegex);
}

/**
 * Provides a function for inline validation of an input value. Validation rule is based on the provided isValidMethod.
 * @param isValidMethod Function called to validate input value. Should return true if input is valid.
 * @param validationError Error string if value does not match validation regex
 * @param emptyInputError Error string if user attempts to submit a blank field
 * @returns A function for inline validation for input value
 */
export function getValidationMethod(
  isValidMethod: (value: string) => boolean,
  validationError: string = "",
  emptyInputError: string = getLocalString("Information_Required_Error"),
) {
  return (rawValue: InputValue): string => {
    const value = String(rawValue);

    if (!value || value.trim() === "") {
      return emptyInputError;
    }

    const isValid = isValidMethod(value);
    const errorMessage = !isValid ? validationError : "";
    return errorMessage;
  };
}

/**
 * Provides a function for inline validation of an input value. Validation rule is based on regex match and empty field.
 * @param validationError Error string if value does not match validation regex
 * @param validationRegex Regex for inline validation
 * @param emptyInputError Error string if user attempts to submit a blank field
 * @returns A function for inline validation for input value
 */
export function simpleInputValidation(
  validationError: string = "",
  validationRegex: string = NoValidationRegEx,
  emptyInputError: string = getLocalString("Information_Required_Error"),
): (value: InputValue) => string {
  return (rawValue: InputValue): string => {
    const value = String(rawValue);
    const isValid = (inputValue: string) => !!inputValue.match(validationRegex);
    return getValidationMethod(isValid, validationError, emptyInputError)(value);
  };
}

/**
 * Provides a function for inline validation of an input value with multiple regex. Validation rule is based on regex match and empty field.
 * @param validationRegex List of regex for inline validation
 * @param validationError List of error strings if value does not match regex at the same list index
 * @param emptyInputError Error string if user attempts to submit a blank field
 * @returns A function for inline validation against multiple regex for input value
 */
export function multipleRegexInputValidation(
  validationRegex: string[],
  validationError: string[],
  emptyInputError: string = getLocalString("Information_Required_Error"),
): (value: InputValue) => string {
  return (rawValue: InputValue): string => {
    const value = String(rawValue);

    if (!value || value.trim() === "") {
      return emptyInputError;
    }

    if (validationRegex.length !== validationError.length) {
      // Since client side validation is best effort, allow user to continue if
      // list lengths do not match. APIs should handle bad input if it is invalid.
      return "";
    }

    // Match the value against each regex and return corresponding error message if invalid
    for (let current = 0; current < validationRegex.length; current += 1) {
      const isValid = !!value.match(validationRegex[current]);
      if (!isValid) {
        return validationError[current];
      }
    }

    return "";
  };
}
