import React from "react";
import { MicrosoftChallengeBlockLearnMore } from "../../../constants";

/**
 * @returns The Challenge block component
 */
export function ChallengeBlockFabric() {
  return (
    <div id="BlockLearnMoreLink">
      <a
        id="learnMore"
        href={MicrosoftChallengeBlockLearnMore}
        rel="noreferrer noopener"
        target="_blank"
        tabIndex={0}
      >
        {getLocalString("General_LearnMore")}
      </a>
    </div>
  );
}
