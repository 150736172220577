import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HistoryContext } from "../history-context";
import { HistoryActionType } from "../history-reducer";
import { isHistorySupported } from "../utilities/browser-helper";

/**
 * Hook to listen for pop state events (back/forward browser button is clicked). Adds an event listener to the window
 * object which will determines whether to navigate back or forward based on comparing the state of the event to what's
 * currently stored as history index. Here, we also reset the value of the stored history index to either 0 or the one
 * returned by the state.
 */
export const useOnPopStateEventListener = () => {
  const navigate = useNavigate();
  const {
    historyState: { historyIndex: currentHistoryIndex },
    dispatchStateChange: dispatchHistoryStateChange,
  } = useContext(HistoryContext);

  useEffect(() => {
    const popStateEvent = async (event: PopStateEvent) => {
      if (!event || typeof event.state === "undefined" || event.state === null) {
        return;
      }

      const newHistoryIndex = event.state;
      // compare state from event to updated stored history index. If the pushed state is less than
      // the stored history index, then we know that the page was previously navigated forward and
      // we want to navigate back. Otherwise we want to navigate forward
      if (newHistoryIndex < currentHistoryIndex) {
        // navigate back
        navigate(-1);
      } else {
        // navigate forward
        navigate(1);
      }

      // reset the history index
      if (newHistoryIndex < 0) {
        dispatchHistoryStateChange({
          type: HistoryActionType.SetHistoryIndexAction,
          payload: 0,
        });
      } else {
        dispatchHistoryStateChange({
          type: HistoryActionType.SetHistoryIndexAction,
          payload: newHistoryIndex,
        });
      }
    };

    if (isHistorySupported()) {
      // make sure history state is updated with most recent history index
      window.history.replaceState(currentHistoryIndex, "");
      window.addEventListener("popstate", popStateEvent);
    }

    return () => {
      if (isHistorySupported()) {
        window.removeEventListener("popstate", popStateEvent);
      }
    };
  }, [navigate, currentHistoryIndex, dispatchHistoryStateChange]);
};
