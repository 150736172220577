import { type BaseInputWithErrorProps } from "../input/input-types";

/**
 * This method is meant to provide common error handling indicators for inputs that have an error UI.
 *
 * @param props The props object passed to the input component, containing `showError` and `errorMessage`.
 * @returns An object containing
 * - `errorMessage` - the error message to display (same value from the param, but defaults to an empty string)
 * - `showErrorMessage` - whether the error should be displayed inline with the input.
 * - `displayErrorStyling` - whether the input should be displayed with error styling. Use this when you are displaying the error elsewhere.
 * @example
 * handleDefaultErrorParams({});
 * // { showErrorMessage: false, displayErrorStyling: false, errorMessage: "" }
 * @example
 * handleDefaultErrorParams({ displayErrorStyling: true });
 * // { showErrorMessage: false, displayErrorStyling: true, errorMessage: "" }
 * @example
 * handleDefaultErrorParams({ errorMessage: "Error" });
 * // { showErrorMessage: true, displayErrorStyling: true, errorMessage: "Error" }
 * @example
 * handleDefaultErrorParams({ errorMessage: "Error", showErrorMessage: false });
 * // { showErrorMessage: false, displayErrorStyling: false, errorMessage: "Error" }
 */
export const handleDefaultErrorParams = (
  props: Partial<
    Pick<BaseInputWithErrorProps, "errorMessage" | "showErrorMessage" | "displayErrorStyling">
  >,
) => {
  const { errorMessage = "" } = props;
  const hasError = !!errorMessage;
  const { showErrorMessage = hasError } = props;
  const { displayErrorStyling = showErrorMessage && hasError } = props;

  return { showErrorMessage, displayErrorStyling, errorMessage };
};

export type InputErrorParams = ReturnType<typeof handleDefaultErrorParams>;
