import { type IPasswordViewStrings } from "./password-view-interface-fabric";

export const passwordViewStringsFabric: IPasswordViewStrings = {
  forgotPasswordLinkText: getLocalString("ForgotPasswordLink_Text"),
  loginPasswordPageTitle: getLocalString("Login_PasswordPage_Title"),
  loginSwitchToCredPickerLinkEvictedAcct: getLocalString(
    "Login_SwitchToCredPicker_Link_EvictedAcct",
  ),
  noPasswordError: getLocalString("NoPassword_Error"),
  passwordAriaLabel: getLocalString("Login_PasswordPage_Desc"),
  passwordHint: getLocalString("Password_Hint"),
  primaryButtonText: getLocalString("SignIn_Title"),
};
