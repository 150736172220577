import { makeStyles, shorthands } from "@griffel/react";

export const useAgreementViewStylesFabric = makeStyles({
  layout: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    overflowX: "hidden",
  },
  // The following is used to style the child content of the AgreementView component.
  childStyling: {
    "& .text-header": {
      marginTop: "1.25rem",
      marginBottom: "1.25rem",
      ...shorthands.padding(0),
    },
    "& .text-body, & p": {
      marginTop: "16px",
      marginBottom: "12px",
      ...shorthands.padding(0),
    },
    "& .no-margin": {
      ...shorthands.margin(0),
    },
    "& .bold, & strong": {
      fontWeight: "bold",
    },
  },
  chinaPipl: {
    whiteSpace: "unset",
  },
});

export type AgreementViewStylesFabric = ReturnType<typeof useAgreementViewStylesFabric>;
