import type { TextButtonFabricProps } from "../../../../components/text-button/fabric/text-button-fabric";
import GlobalConfig from "../../../../global-config";
import { useNavigateOrRedirectBack } from "../../../../hooks";
import { useBackButtonHandlerWithCxh } from "../../../../hooks/win11-oobe-fabric/use-back-button-handler";
import type { CommonLoginStrings } from "../../login-interface";

/**
 * @param textSectionId The ID of the div containing the view's text content - this is used for the aria-describedby attribute of the back button.
 * @param commonLoginStrings The common login strings
 * @returns The back button properties for the LearnMoreOfflineAccount view
 */
export const useBackButtonProperties = (
  textSectionId: string,
  commonLoginStrings: CommonLoginStrings,
): TextButtonFabricProps | undefined => {
  const { showButtons } = GlobalConfig.instance;

  const backButtonClickHandler = useNavigateOrRedirectBack();
  useBackButtonHandlerWithCxh(backButtonClickHandler);

  return showButtons
    ? {
        ariaDescribedBy: textSectionId,
        buttonId: "learnMoreOfflineAccountBackButton",
        label: commonLoginStrings.backButton,
        onClick: backButtonClickHandler,
        hasFocus: true,
      }
    : undefined;
};
