import { useContext } from "react";
import { ViewId } from "../../../../constants";
import { GlobalContext } from "../../../../global-context";
import { extractDomain } from "../../../../model/alias";
import { CredentialType } from "../../../../model/credential";
import { type RedirectPostParams } from "../../../../utilities/api-helpers/get-credential-type/get-credential-type-helper";
import { LoginContext } from "../../login-context";
import { useOnSwitchView } from "./use-on-switch-view";

/**
 * @param redirectingToIdp flag indicating whether the user is being redirected to another IDP
 * @param idpRedirectUrl the IDP redirect url
 * @param idpRedirectPostParams the IDP redirect post params
 * @param unsafeDesktopSsoDomain the unsafe desktop SSO domain
 * @returns a handler for when the current IDP tile is clicked
 */
export const useCurrentIdpTileClickHandler = (
  redirectingToIdp: boolean,
  idpRedirectUrl: string,
  idpRedirectPostParams: RedirectPostParams,
  unsafeDesktopSsoDomain: string,
): (() => void) => {
  // Global context data
  const {
    globalState: {
      user: { displayUsername },
    },
  } = useContext(GlobalContext);

  // Login flow context data
  const {
    viewState: {
      credentials: { preferredCredential },
      desktopSsoEnabled,
      desktopSsoExecuted,
      idpRedirectPostParams: federatedIdpRedirectPostParams,
      idpRedirectUrl: federatedIdpRedirectUrl,
    },
  } = useContext(LoginContext);

  const onSwitchView = useOnSwitchView();

  return () => {
    if (desktopSsoEnabled && !desktopSsoExecuted) {
      const { unsafeUnescapedString: unsafeDisplayUsername } = displayUsername;

      const domain = extractDomain(unsafeDisplayUsername, false, false);

      onSwitchView(
        ViewId.FetchSessionsProgress,
        redirectingToIdp,
        idpRedirectUrl,
        idpRedirectPostParams,
        domain,
      );
    } else {
      switch (preferredCredential) {
        case CredentialType.Fido:
          onSwitchView(
            ViewId.Fido,
            redirectingToIdp,
            idpRedirectUrl,
            idpRedirectPostParams,
            unsafeDesktopSsoDomain,
          );
          break;

        case CredentialType.OneTimeCode:
        case CredentialType.PublicIdentifierCode:
          onSwitchView(
            ViewId.OneTimeCode,
            redirectingToIdp,
            idpRedirectUrl,
            idpRedirectPostParams,
            unsafeDesktopSsoDomain,
          );
          break;

        case CredentialType.RemoteNGC:
          onSwitchView(
            ViewId.PushNotifications,
            redirectingToIdp,
            idpRedirectUrl,
            idpRedirectPostParams,
            unsafeDesktopSsoDomain,
          );
          break;

        case CredentialType.Password:
        default:
          if (federatedIdpRedirectUrl) {
            onSwitchView(
              ViewId.IdpRedirect,
              true,
              federatedIdpRedirectUrl,
              federatedIdpRedirectPostParams,
              unsafeDesktopSsoDomain,
            );
          } else {
            onSwitchView(
              ViewId.Password,
              redirectingToIdp,
              idpRedirectUrl,
              idpRedirectPostParams,
              unsafeDesktopSsoDomain,
            );
          }

          break;
      }
    }
  };
};
