import { trim } from "../../utilities/strings-helper";
import { isEmailAddress, isPhoneNumber, isSkypeName } from "../alias";

/**
 * Returns a new string equivalent to this String, but cleansed of leading, trailing and formatting characters (for phone numbers)
 * @param str The string to cleanse
 * @param preserveLeadingPlusSign bool that specifies to preserve leading plus sign or not
 * @returns A new cleansed string
 */
export function cleanseUsername(str: string, preserveLeadingPlusSign?: boolean) {
  if (!str) {
    return "";
  }

  const returnStr = trim(str).toLowerCase();

  if (!isEmailAddress(returnStr) && !isSkypeName(returnStr) && isPhoneNumber(returnStr)) {
    let prefix = "";
    if (preserveLeadingPlusSign && returnStr.charAt(0) === "+") {
      prefix = "+";
    }

    return prefix + returnStr.replace(/\D+/g, "");
  }

  return returnStr;
}
