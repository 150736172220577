import React from "react";
import { LinkButton } from "../../../../components/link-button";
import { FormattedTextWithBindings } from "../../../../utilities/formatted-text-with-bindings";
import { useSignupClickHandler } from "../../hooks/login-hooks";

export type FormattedSignupMessageProps = {
  id: string;
  username: string;
  text: string;
  arialLabel?: string;
  /** The test id for the sign up link  @default 'signUpLink' */
  linkTestId?: string;
};

/**
 * Create formatted sign up message with sign up link embedded
 * @param props properties for the sign up message (including text and id)
 * @returns sign up message component
 */
export const FormattedSignupMessageFabric: React.FC<FormattedSignupMessageProps> =
  function FormattedSignupMessageFabric(props) {
    const { id, username, text, arialLabel, linkTestId = "signUpLink" } = props;
    const onSignupClicked = useSignupClickHandler();
    const signupLink = (chunks: string[]) => (
      <LinkButton
        text={chunks[0]}
        onClick={() => onSignupClicked(username)}
        linkId={id}
        ariaLabel={arialLabel}
        linkDataTestId={linkTestId}
      />
    );

    return <FormattedTextWithBindings text={text} embeddedBindings={{ signupLink }} />;
  };
