import { type RefObject, useContext, useEffect, useRef } from "react";
import { useCustomizationContext } from "../../../../../context/customization-context";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import { FOCUS_TIMEOUT } from "../../../../../styles/fabric/layout-animate-fabric.styles";
import LoginConfig from "../../../login-config";
import { Error, LoginMode } from "../../../login-constants";
import { type CommonLoginStrings } from "../../../login-interface";
import { type IErrorViewLinkProperties, type IErrorViewProperties } from "../../../login-types";
import { getCommonDocumentTitle } from "../../../login-util";
import { type IErrorViewStrings } from "../error-view-interface";
import { errorViewStringsFabric } from "../fabric/error-view-strings-fabric";
import {
  getErrorCodeDescription,
  getLightboxTitle,
  getLoginModeErrorDesc,
  getSwitchUrlText,
  getUsernameDescription,
} from "./get-dynamic-strings";

/**
 * Creates the reference object for a link in the error view and uses the input hasFocus boolean and the
 * useEffect hook to ensure the link gains focus after it has been rendered.
 * @param hasFocus - whether the link should have focus after being rendered.
 * @returns The created link reference object.
 */
const useLinkRef = (hasFocus: boolean): RefObject<HTMLAnchorElement> => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (hasFocus) {
      setTimeout(() => {
        linkRef?.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  }, [hasFocus]);
  return linkRef;
};

/**
 * @returns Login error view properties
 * @param strings Flavored strings used by this hook
 * @param strings.commonLoginStrings Common login strings
 * @param strings.errorViewStrings Strings for the error view
 */
export const useErrorViewProperties = (strings: {
  commonLoginStrings: CommonLoginStrings;
  errorViewStrings: IErrorViewStrings;
}): IErrorViewProperties => {
  const {
    commonLoginStrings,
    errorViewStrings: { remainSignedInText, resetPasswordLinkText },
  } = strings;

  // Global context data
  const {
    globalState: {
      debugInfo: { errorCode },
    },
  } = useContext(GlobalContext);

  // Customization context data
  const {
    customizationState: {
      styles: { friendlyAppName },
    },
  } = useCustomizationContext();

  // Global config data
  const {
    errorText,
    manageCredsUrl,
    postUsername,
    resetPasswordUrl,
    signInUsername,
    switchUserUrl,
  } = GlobalConfig.instance;

  // Login config data
  const {
    fedPartnerName,
    loginMode,
    loginStringsVariant,
    defaultSignInHeader,
    appBrandedSignInHeader,
    staySignInUrl,
  } = LoginConfig.instance;

  const lightboxTitleId = "loginHeader";
  const loginModeErrorDescId = "idTD_Error";
  const documentTitle = getCommonDocumentTitle(loginMode, friendlyAppName, commonLoginStrings);

  const isBindFailedMode = loginMode === LoginMode.BindFailed;
  const lightboxTitle = getLightboxTitle(
    errorViewStringsFabric,
    isBindFailedMode,
    loginStringsVariant,
    defaultSignInHeader,
    appBrandedSignInHeader,
  );

  const isGenericErrorMode =
    loginMode === LoginMode.GenericError ||
    loginMode === LoginMode.GenericErrorMobile ||
    loginMode === LoginMode.GenericErrorHost;
  const isSwitchUserMode =
    loginMode === LoginMode.SwitchUser ||
    loginMode === LoginMode.SwitchUserMobile ||
    loginMode === LoginMode.SwitchUserHost;

  let username = "";
  if (!isGenericErrorMode) {
    username = isSwitchUserMode
      ? signInUsername
      : postUsername.unsafeUnescapedString || signInUsername;
  }

  const loginModeErrorDesc = getLoginModeErrorDesc(
    errorViewStringsFabric,
    loginMode,
    username,
    fedPartnerName,
  );
  const errorCodeDescription = getErrorCodeDescription(
    errorViewStringsFabric,
    errorText,
    errorCode,
  );

  const isHipLockedMode =
    loginMode === LoginMode.HIP_Lockout ||
    loginMode === LoginMode.HIP_LockoutMobile ||
    loginMode === LoginMode.HIP_LockoutHost;
  const usernameDescription = getUsernameDescription(
    errorViewStringsFabric,
    username,
    isHipLockedMode,
  );
  const switchUserUrlText = getSwitchUrlText(
    errorViewStringsFabric,
    isHipLockedMode,
    isSwitchUserMode,
  );

  const focusErrorTextLink = errorCode === Error.PP_E_IDP_BINDING_EXISTS_SAMSUNG;
  const linkAriaDescribedBy = `${lightboxTitleId} ${loginModeErrorDescId}`;
  const manageCredsLink: IErrorViewLinkProperties = {
    ref: useLinkRef(true),
    id: "manageCredsUrl",
    ariaDescribedBy: linkAriaDescribedBy,
    href: manageCredsUrl,
    // The text for this link is contained in the errorDescription and should be rendered using the
    // FormattedTextWithBindings component in the login error view.
    text: "",
  };

  const resetPasswordLink: IErrorViewLinkProperties = {
    ref: useLinkRef(!focusErrorTextLink && isHipLockedMode),
    id: "i1011",
    ariaDescribedBy: !focusErrorTextLink && isHipLockedMode ? linkAriaDescribedBy : undefined,
    href: resetPasswordUrl,
    text: resetPasswordLinkText,
  };

  const staySignInLink: IErrorViewLinkProperties = {
    ref: useLinkRef(!focusErrorTextLink && !isHipLockedMode && isSwitchUserMode),
    id: "i1052",
    ariaDescribedBy:
      !focusErrorTextLink && !isHipLockedMode && isSwitchUserMode ? linkAriaDescribedBy : undefined,
    href: staySignInUrl,
    text: remainSignedInText,
  };

  const switchUserLink: IErrorViewLinkProperties = {
    ref: useLinkRef(!focusErrorTextLink && !isHipLockedMode && !isSwitchUserMode),
    id: "i1051",
    ariaDescribedBy:
      !focusErrorTextLink && !isHipLockedMode && !isSwitchUserMode
        ? linkAriaDescribedBy
        : undefined,
    href: switchUserUrl,
    text: switchUserUrlText,
  };

  return {
    documentTitle,
    lightboxTitle,
    lightboxTitleId,
    loginModeErrorDesc,
    loginModeErrorDescId,
    errorCodeDescription,
    usernameDescription,
    isBindFailedMode,
    isGenericErrorMode,
    isSwitchUserMode,
    isHipLockedMode,
    manageCredsLink,
    resetPasswordLink,
    staySignInLink,
    switchUserLink,
  };
};
