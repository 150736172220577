import { ConfigWrapper } from "../../config/config-wrapper";
import type { ServerData } from "../../utilities/server-data";
import { type FooterConfigOverrides } from "./footer-config-overrides";

export type FooterConfigType = {
  footerA11yConformeUrl: string;
  footerImpressumUrl: string;
  helpUrl: string;
  helpUrlHosted: string;
  hideFooter: boolean;
  privacyLinkExternal: boolean;
  privacyUrl: string;
  termsOfUseLinkExternal: boolean;
  termsOfUseUrl: string;
};

export const defaultFooterConfig: FooterConfigType = {
  footerA11yConformeUrl: "",
  footerImpressumUrl: "",
  helpUrl: "",
  helpUrlHosted: "",
  hideFooter: false,
  privacyLinkExternal: false,
  privacyUrl: "",
  termsOfUseLinkExternal: false,
  termsOfUseUrl: "",
};

const configWrapper = new ConfigWrapper(defaultFooterConfig);

/**
 * The config wrapper that should be used to access Footer config properties
 */
export default configWrapper;

/* ********* ServerData helpers ********** */

/**
 * Initialize the Footer config object from ServerData
 * @param serverData The IDP-specific server data object that should be used to initialize the Footer config
 * @param overrides Values passed in to override default values based on IDP, flavor, etc.
 * @param overrides.urlHostedHelpLink URL for the help link when the request is hosted.
 * @param overrides.urlHelpLink URL for the help link when the request is not hosted.
 */
export function initFooterConfig(serverData: ServerData, overrides?: FooterConfigOverrides) {
  const mappedServerData: Partial<FooterConfigType> = {};

  mappedServerData.footerA11yConformeUrl = serverData.a11yConformeLink;
  mappedServerData.hideFooter = serverData.fHideFooter;
  mappedServerData.footerImpressumUrl = serverData.urlImpressum;

  if (
    (serverData.urlHostedTOULink && serverData.fIsHosted) ||
    (serverData.urlHostedTOULink && !serverData.urlFooterTOU)
  ) {
    mappedServerData.termsOfUseUrl = serverData.urlHostedTOULink;
  } else if (serverData.urlFooterTOU) {
    mappedServerData.termsOfUseLinkExternal = true;
    mappedServerData.termsOfUseUrl = serverData.urlFooterTOU;
  }

  if (
    (serverData.urlHostedPrivacyLink && serverData.fIsHosted) ||
    (serverData.urlHostedPrivacyLink && !serverData.urlFooterPrivacy)
  ) {
    mappedServerData.privacyUrl = serverData.urlHostedPrivacyLink;
  } else if (serverData.urlFooterPrivacy) {
    mappedServerData.privacyLinkExternal = true;
    mappedServerData.privacyUrl = serverData.urlFooterPrivacy;
  }

  mappedServerData.helpUrl = overrides?.urlHelpLink;
  mappedServerData.helpUrlHosted = overrides?.urlHelpLinkDisplay;

  configWrapper.initialize(mappedServerData);
}
