import { type RefObject, useEffect } from "react";
import { FOCUS_TIMEOUT } from "../styles/fabric";
import { useEffectOnce } from "./use-effect-once";

/**
 * Hook to focus an HTML element
 * @param element HTML element to be focused
 * @param hasInitialFocus the element initial's state and should only run effect once
 * @param hasFocus the element will re-focus on every re-render
 * @param skipHasFocusDelay when hasFocus is true, it should wait before setting it
 */

export const useFocusElement = (
  element: RefObject<HTMLElement>,
  hasInitialFocus?: boolean,
  hasFocus?: boolean,
  skipHasFocusDelay?: boolean,
) => {
  useEffectOnce(() => {
    if (hasInitialFocus) {
      // We delay focus until animation is nearly completed. This prevents the browser from immediately switching to the focused element and pulling
      // it into view, which causes the identity banner and logo to pull right and interrupts the animation.
      setTimeout(() => {
        element?.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  });

  useEffect(() => {
    const delay = skipHasFocusDelay ? 0 : FOCUS_TIMEOUT;
    let timeout: NodeJS.Timeout | undefined;
    if (hasFocus) {
      timeout = setTimeout(() => {
        element?.current?.focus();
      }, delay);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [element, hasFocus, skipHasFocusDelay]);
};
