export const FOOTER_HEIGHT = 28;
export const FORM_GROUP_MARGIN = 16;
export const INPUT_BOX_SHADOW = "0px 1px 0px rgba(0, 0, 0, 0.48)";
export const LIGHTBOX_BACKGROUND_COLOR = "transparent";
export const LIGHTBOX_MAX_WIDTH = 2000;
export const LIGHTBOX_MIN_HEIGHT = 338;
// Mobile footer can span up to 4 lines on certain markets - @footer-height(28) + 20 + 20 + 20
export const LIGHTBOX_MOBILE_MARGIN_BOTTOM = 0;
export const LIGHTBOX_MOBILE_MARGIN_TOP = 32;
export const LIGHTBOX_MOBILE_PADDING = 0;
export const LIGHTBOX_PADDING = 44;
// At a width of 2000px, the background goes away and the footer changes to be left aligned
export const MOBILE_MAX_WIDTH = 2000;
export const SECTION_PADDING = 20;
export const TILE_PADDING = 12;

// Calculated
export const LAYOUT_MAX_HEIGHT = LIGHTBOX_MIN_HEIGHT + FOOTER_HEIGHT;

// Media queries
export const HIGH_CONTRAST_MODE = "@media (-ms-high-contrast)";
export const LOW_RESOLUTION_DEVICE_SIZE_1440P = "@media screen and (max-device-width: 1440px)";
export const LOW_RESOLUTION_DEVICE_SIZE_1024P =
  "@media screen and (max-device-width: 1024px), screen and (max-device-height: 720px)";
export const MOBILE_LAYOUT_SIZE = `@media (max-width: ${MOBILE_MAX_WIDTH}px), (max-height: ${LAYOUT_MAX_HEIGHT}px)`;
