import { useEffect } from "react";
import { useCustomizationContext } from "../context/customization-context";
import { CustomizationActionType } from "../context/customization-reducer";
import { type CustomString } from "../utilities/render-promises-helper";

/**
 * This hook settles the render promises and makes necessary updates to customization context
 *  to get custom strings and CSS asynchronously.
 */
export const useRenderPromises = () => {
  const {
    customizationState: { renderPromises, readyToRender },
    dispatchStateChange: dispatchBrandingState,
  } = useCustomizationContext();

  useEffect(() => {
    // Get the list of render promises from the customization files.
    const { loadPromises, stringPromises } = renderPromises;

    // Don't bother executing if there are no promises to be resolved or if they have already been settled.
    if (readyToRender) {
      return;
    }

    // Create the array of custom strings once all string promises have settled.
    Promise.allSettled(stringPromises).then((promisesResults) => {
      let customStringsArray: CustomString[] = [];
      // Iterate through promises results to find all fetched custom strings.
      for (let i = 0; i < promisesResults.length; i += 1) {
        const promiseResult = promisesResults[i];
        if (promiseResult && promiseResult.status === "fulfilled" && promiseResult.value) {
          customStringsArray = customStringsArray.concat(promiseResult.value);
        }
      }

      // Call branding reducer to make custom strings result available.
      dispatchBrandingState({
        type: CustomizationActionType.SetStringPromisesResult,
        strings: customStringsArray,
      });
    });

    // Ensure both load and string promises have settled, then set the readyToRender flag to true to indicate the UX it's safe to render the view.
    Promise.allSettled([...loadPromises, ...stringPromises]).then(() => {
      // Call branding reducer to indicate all promises are loaded.
      dispatchBrandingState({
        type: CustomizationActionType.SetReadyToRender,
        readyToRender: true,
      });
    });
  }, [dispatchBrandingState, readyToRender, renderPromises]);
};
