import { useContext } from "react";
import { doOriginsMatch } from "../../../../../utilities/origins";
import LoginConfig from "../../../login-config";
import { LoginContext } from "../../../login-context";
import { useSignOutOrForgetSessionHandler } from "./use-session-handlers";

/**
 * @returns Hook used for event listener
 */
export const useOnSignOutOrForgetEventListener = () => {
  const { logoutUrl, forgetUrl } = LoginConfig.instance;
  const {
    viewState: { credentials },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);
  const signOutOrForgetSession = useSignOutOrForgetSessionHandler();
  return async (event: MessageEvent) => {
    // important security check
    // do not proceed if the iframe is from an unknown origin
    if (
      !event ||
      (!doOriginsMatch(logoutUrl, event.origin) && !doOriginsMatch(forgetUrl, event.origin))
    ) {
      return;
    }

    const eventData = event.data;
    if (eventData && eventData.sessionId) {
      await signOutOrForgetSession(event, credentials.sessions, dispatchLoginStateChange);
    }
  };
};
