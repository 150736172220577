import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { LinkButton } from "../../link-button";
import { TextButtonContainer, TextButtonFabric } from "../../text-button/fabric/text-button-fabric";
import { type HipChallengeProps } from "../hip-challenge-types";
import { HipChallengeContent } from "../hip-content";
import { type HipChallengeMenuItem, useHipChallenge } from "../hooks/use-hip-challenge";

/**
 * HipChallenge component abstracts business and rendering logic to deal with
 * audio and image captchas provided by RepMap team
 * @param props Captcha properties
 * @param props.onSessionStart onSessionStart callback
 * @param props.onSuccess onSuccess callback
 * @param props.onValidate onValidate callback
 * @param props.initialType initial type of hip to render
 * @param props.config config passed via ServerData
 * @param props.strings localized strings
 * @param props.errorMessage error message from external source, like SendOTT API e.g incorrect hip solution error messsage comes from API response
 * @returns Captcha component visual tree
 */
export const HipChallengeFabric = function HipChallengeFabric({
  strings,
  onSessionStart,
  onSuccess,
  onValidate,
  initialType,
  config,
  errorMessage,
}: HipChallengeProps) {
  const {
    isLoading,
    errorMessage: clientValidationError,
    instruction,
    menuItems,
    onSubmit,
  } = useHipChallenge({
    initialType,
    onSessionStart,
    onSuccess,
    onValidate,
    config,
  });
  const hipErrorMessage = clientValidationError || errorMessage;
  const { useCommonStyles, useHipChallengeStyles, useStaticHipChallengeStyles } =
    StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const hipChallengeStyles = useHipChallengeStyles();
  useStaticHipChallengeStyles();

  return (
    <form name="hipChallengeForm" noValidate method="post" onSubmit={onSubmit}>
      <div className={hipChallengeStyles.hipChallengeLinks}>
        {menuItems.map((item: HipChallengeMenuItem, index, { length }) => (
          <div
            className={
              index + 1 === length
                ? hipChallengeStyles.hipChallengeMenuButtonStyle
                : hipChallengeStyles.hipChallengeMenuButtonStyleWithBorder
            }
            key={item.key}
          >
            <LinkButton text={item.text} linkId={item.key} onClick={item.action} />
          </div>
        ))}
      </div>

      <HipChallengeContent styles={hipChallengeStyles.hipContentStyle} />

      {instruction && !hipErrorMessage && (
        <div className={commonStyles.linkButtonFontSize} data-testid="info-message">
          {instruction}
        </div>
      )}
      {hipErrorMessage && (
        <div
          className={mergeClasses(commonStyles.linkButtonFontSize, commonStyles.error)}
          data-testid="error-message"
        >
          {hipErrorMessage}
        </div>
      )}

      <div className={commonStyles.winButtonPinBottom}>
        <div className={commonStyles.row}>
          <TextButtonContainer customCss={hipChallengeStyles.hipChallengeNextButtonContainerStyle}>
            <TextButtonFabric
              isPrimary
              aria-label={strings.ariaPrimaryButtonLabel}
              label={strings.primaryButton}
              disabled={isLoading}
              type="submit"
              buttonId="nextButton"
            />
          </TextButtonContainer>
        </div>
      </div>
    </form>
  );
};
