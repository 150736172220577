import { type FormEvent } from "react";
import { ViewId } from "../../../../../constants";
import { useNavigateDirection } from "../../../../../hooks";
import { CredentialType } from "../../../../../model/credential";
import { type ConfirmSendViewStrings } from "../confirm-send-view-strings";
import { type UseOneTimeCodeParams } from "./use-description";
import { useSendOtcForConfirmSend } from "./use-send-otc-for-confirm-send";

/**
 * This hook is used when clicking the primary (only) button on the ConfirmSendView.
 * @param params The OneTimeCode parameters to pass to the "useSendOneTimeCode" hook
 * @param confirmSendStrings Strings for the Confirm Send View
 * @returns A callback that will navigate to the RemoteNGCView if the preferred credential is RemoteNGC.
 * Otherwise the callback will send a one time code request.
 */
export const useOnClickPrimaryButton = (
  params: UseOneTimeCodeParams,
  confirmSendStrings: ConfirmSendViewStrings,
) => {
  const sendOneTimeCode = useSendOtcForConfirmSend(params, confirmSendStrings);
  const navigate = useNavigateDirection();
  const { preferredCredential } = params;

  return (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (preferredCredential === CredentialType.RemoteNGC) {
      navigate(ViewId.ConfirmSend, ViewId.PushNotifications);
    } else {
      // OneTimeCode or PublicIdentifierCode
      sendOneTimeCode();
    }
  };
};
