import { createContext, useContext } from "react";
import { type ServerData } from "./utilities/server-data";
import { extractQueryStringParam } from "./utilities/strings-helper";
import { ApiNames, HipType } from "./constants";
import {
  type FraudProtectionPayload as NativeHipState,
  BindingMethodType,
  FraudProtectionChallengeType,
} from "./model";

type ArkoseState = {
  partnerId: string;
  url: string;
  timeout: string;
  dataExchangeBlob: string;
};

type HipState = {
  url: string;
  datacenter: string;
};

export type RepMapMetadataAccount = {
  riskAssessmentDetails: string;
  phoneRepRiskScoreDetails: string;
  encAttemptToken: string;
  dfpRequestId: string;
  dfpUrl: string;
  recommendedChallengeRiskScore: string;
  repMapRequestIdentifierDetails: string;
};

type CommonState = {
  type: HipType;
  flowId: string;
  scenarioId: string;
  errorMessage: string;
  apiName: ApiNames;
  // used on MSA
  phoneRepMetadata: string;
};

type ArkosePayload = ArkoseState & CommonState;

type HipPayload = HipState & CommonState;

export type ChallengeState = {
  arkose: ArkoseState;
  hip: HipState;
  nativeHip: NativeHipState;
  repMapMetadata: RepMapMetadataAccount;
} & CommonState;

export type ChallengeActions =
  | SetArkoseData
  | SetHipData
  | SetAbuseBlockData
  | SetCommonState
  | SetRepMapMetadata
  | SetNativeHipData;

type SetArkoseData = {
  type: ChallengeActionType.SetArkoseAction;
  payload: Partial<ArkosePayload>;
};

type SetHipData = {
  type: ChallengeActionType.SetHipAction;
  payload: Partial<HipPayload>;
};

type SetAbuseBlockData = {
  type: ChallengeActionType.SetAbuseBlockAction;
  payload: Partial<CommonState>;
};

type SetCommonState = {
  type: ChallengeActionType.SetCommonAction;
  payload: Partial<CommonState>;
};

type SetRepMapMetadata = {
  type: ChallengeActionType.SetRepMapMetadata;
  payload: Partial<RepMapMetadataAccount>;
};

type SetNativeHipData = {
  type: ChallengeActionType.SetNativeHipAction;
  payload: Partial<NativeHipState>;
};

export const defaultChallengeState: ChallengeState = {
  type: HipType.Unknown,
  flowId: "",
  scenarioId: "",
  phoneRepMetadata: "",
  repMapMetadata: {
    riskAssessmentDetails: "",
    phoneRepRiskScoreDetails: "",
    encAttemptToken: "",
    dfpRequestId: "",
    dfpUrl: "",
    recommendedChallengeRiskScore: "",
    repMapRequestIdentifierDetails: "",
  },
  errorMessage: "",
  apiName: ApiNames.Unknown,
  arkose: {
    partnerId: "",
    url: "",
    timeout: "",
    dataExchangeBlob: "",
  },
  hip: {
    url: "",
    datacenter: "",
  },
  nativeHip: {
    challengeArtifact: "",
    challengeMethod: BindingMethodType.captcha,
    challengeType: FraudProtectionChallengeType.visual,
    challengeTypes: [FraudProtectionChallengeType.visual, FraudProtectionChallengeType.audio],
    continuationToken: "",
  },
};

export enum ChallengeActionType {
  SetArkoseAction,
  SetHipAction,
  SetAbuseBlockAction,
  SetCommonAction,
  SetRepMapMetadata,
  SetNativeHipAction,
}

export interface IChallengeContext {
  challengeState: ChallengeState;
  dispatchChallengeStateChange: React.Dispatch<ChallengeActions>;
}

export const ChallengeContext = createContext<IChallengeContext>({
  challengeState: defaultChallengeState,
  dispatchChallengeStateChange: () => {
    throw new Error("Challenge context not initialized");
  },
});

/**
 * Initialize the Challenge config object from ServerData.
 * This function should be called once per App, outside of the component render cycle.
 * @param serverData the serverData
 * @returns the initial challengeState
 */
export function createChallengeState(serverData: ServerData): ChallengeState {
  let challengeState = {
    ...defaultChallengeState,
  };

  if (serverData?.oCaptchaInfo) {
    const {
      urlHipChallenge = "",
      urlArkoseEnforcement = "",
      urlDfp = "",
      sHipFid = "",
      sHipDc = "",
      sArkoseEnforcementPid = "",
      sArkoseEnforcementTimeout = "",
      sChallengeSid = "",
    } = serverData.oCaptchaInfo;

    let scenarioId = sChallengeSid;

    if (urlHipChallenge) {
      scenarioId = extractQueryStringParam("id", urlHipChallenge);
    }

    challengeState = {
      ...defaultChallengeState,
      flowId: sHipFid,
      scenarioId,
      repMapMetadata: {
        ...defaultChallengeState.repMapMetadata,
        dfpUrl: urlDfp,
      },
      arkose: {
        ...defaultChallengeState.arkose,
        partnerId: sArkoseEnforcementPid,
        url: urlArkoseEnforcement,
        timeout: sArkoseEnforcementTimeout,
      },
      hip: {
        ...defaultChallengeState.hip,
        url: urlHipChallenge,
        datacenter: sHipDc,
      },
    };
  }

  return challengeState;
}

/**
 * Challenge context
 * @returns The Challenge context
 */
export const useChallengeContext = () => useContext(ChallengeContext);
