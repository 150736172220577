import { type ILearnMoreOfflineAccountViewStrings } from "../learn-more-offline-account-view-interface";

export const learnMoreOfflineAccountViewStringsFabric: ILearnMoreOfflineAccountViewStrings = {
  whatIsAMicrosoftAccountPageTitle: getLocalString("WhatIsAMicrosoftAccount"),
  oneAccountForAllThingsMicrosoftParagraphSubtitle: getLocalString(
    "OneAccountForAllThingsMicrosoft",
  ),
  oneAccountForAllThingsMicrosoftParagraphDescription: getLocalString(
    "OneAccountConnectsAcrossMicrosoft_Description",
  ),
  yourAccountYourDataMoreSecureParagraphSubtitle: getLocalString("YourAccountYourDataMoreSecure"),
  yourAccountYourDataMoreSecureParagraphDescription: getLocalString(
    "KeepTabsOnSubscriptions_Description",
  ),
  notSureIfYouHaveAMicrosoftAccountParagraphSubtitle: getLocalString(
    "NotSureIfYouHaveAMicrosoftAccount",
  ),
  notSureIfYouHaveAMicrosoftAccountParagraphDescription: getLocalString(
    "UseAnOfflineAccountToSignIn_Description",
  ),
};
