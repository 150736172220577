import React from "react";
import { type TelemetryState, GlobalContext } from "../../global-context";
import { ExceptionHelper } from "../../telemetry-helpers/exception-helper";

export type AppBoundaryProps = {
  telemetryState: TelemetryState;
  CriticalErrorPage: JSX.Element;
};

/**
 * App level Error Boundary
 */
class AppBoundary extends React.Component<
  React.PropsWithChildren<AppBoundaryProps>,
  { hasError: boolean; error?: Error }
> {
  /**
   * Construct an AppBoundary instance
   * @param props AppBoundary properties
   */
  constructor(props: React.PropsWithChildren<AppBoundaryProps>) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * Lifecycle method invoked after an error has been thrown by a descendant component.
   * @param {Error} error The error that was thrown by descendant component.
   * @returns a value to update state.
   */
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  /**
   * Lifecycle method invoked after an error has been thrown by a descendant component.
   * @param {Error} error The error that was thrown by descendant component.
   */
  componentDidCatch(error: Error) {
    const { telemetryState } = this.props;
    if (!ExceptionHelper.isErrorLogged(error)) {
      ExceptionHelper.logException(error, telemetryState);
    }
  }

  /**
   * Required lifecycle method to update UI
   * @returns HTML elements
   */
  render() {
    const { hasError } = this.state;
    const { children, CriticalErrorPage } = this.props;

    return <div data-testid="app-boundary">{hasError ? CriticalErrorPage : children}</div>;
  }
}

AppBoundary.contextType = GlobalContext;
export default AppBoundary;
