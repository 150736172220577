import React, { createContext, useContext, useMemo, useReducer } from "react";
import { type AccessibleImageProps } from "./components/accessible-image";
import { FlowId, ViewId } from "./constants/routing-constants";
import { type IUser } from "./model/user";
import type { IStandardDimensions } from "./telemetry-helpers/telemetry-helper";
import type { ServerData } from "./utilities/server-data";
import { type UntrustedExternalInputText } from "./utilities/untrusted-external-input-text";
import { UserFlowType } from "./constants";
import globalReducer, {
  type GamepadNavigationManager,
  type GlobalActions,
  type UserOptions,
  getUpdatedUser,
} from "./global-reducer";

export interface IDebugInfo {
  errorCode: string;
  errorMessage: string;
  debugModeActive: boolean;
  timeStamp: string;
}

export interface TelemetryState {
  dimensions: IStandardDimensions;
  currentState?: {};
}

export interface IMoreOptionsProps {
  image: AccessibleImageProps;
  text: string;
  onClick: () => void;
}

export type NavigationDirection = "forward" | "back";

export type GlobalState = {
  activeFlow: FlowId;
  activeView: ViewId;
  previousView?: ViewId;
  allowGrayOutLightbox: boolean;
  backButtonHandler?: () => void;
  closeButtonHandler?: (event: React.SyntheticEvent<HTMLElement>) => void;
  debugInfo: IDebugInfo;
  disableHeightAnimation: boolean;
  hideBannerLogo: boolean;
  isWideView: boolean;
  navigationDirection: NavigationDirection;
  setDefaultFocusInFooter: boolean;
  showBackButtonOnActiveView: boolean;
  showCloseButtonOnActiveView: boolean;
  showIdentityBanner: boolean;
  showMoreOptions?: IMoreOptionsProps;
  showProgressIndicator: boolean;
  user: IUser;
  userFlowType: UserFlowType;
  gamepadManager?: GamepadNavigationManager;
  showPageTitle: boolean;
};

export const defaultDebugInfo: IDebugInfo = {
  errorCode: "",
  errorMessage: "",
  debugModeActive: false,
  timeStamp: "",
};

export const defaultUser: IUser = {
  displayUsername: {} as UntrustedExternalInputText,
  username: {} as UntrustedExternalInputText,
};

export interface IGlobalContext {
  globalState: GlobalState;
  dispatchStateChange: React.Dispatch<GlobalActions>;
}

// TODO: split out a display context and debug context
export const initialGlobalState: GlobalState = {
  activeFlow: FlowId.None,
  activeView: ViewId.None,
  previousView: undefined,
  allowGrayOutLightbox: true,
  debugInfo: defaultDebugInfo,
  disableHeightAnimation: false,
  hideBannerLogo: false,
  isWideView: false,
  navigationDirection: "forward",
  setDefaultFocusInFooter: false,
  showBackButtonOnActiveView: false,
  showCloseButtonOnActiveView: false,
  showIdentityBanner: true,
  showProgressIndicator: false,
  user: defaultUser,
  userFlowType: UserFlowType.Unknown,
  showPageTitle: false,
};

export const GlobalContext = createContext<IGlobalContext>({
  globalState: initialGlobalState,
  dispatchStateChange: () => {
    throw new Error("GlobalContext not initialized");
  },
});

export const useGlobalContext = () => useContext(GlobalContext);

/**
 *
 * @param props The input props for the global provider
 * @param props.initialState The initial state that the global provider should be initialized with. Note that this is NOT the default state
 * (fallback values provided at design time) but rather the state extracted from ServerData or otherwise representing the initial state of
 * the application at runtime
 * @param props.children The child components to render inside this provider
 * @returns The instantiated Provider component
 */
export const GlobalProvider: React.FC<{ initialState: GlobalState }> = function GlobalProvider({
  initialState,
  children,
}) {
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const value: IGlobalContext = useMemo(
    () => ({
      globalState: state,
      dispatchStateChange: dispatch,
    }),
    [state],
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

/* ********* ServerData helpers ********** */

/**
 * Create a global state object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * Any properties that are used inside a React component's lifecycle (hook or render function)
 * AND can change during the component's lifecycle should go into the state. Constant properties
 * or properties that are not used by a React component can be stored in config instead to improve
 * performance and prevent unnecessary re-rendering.
 * @param serverData The IDP-specific server data object that should be used to create the global state
 * @param userOptions UserOptions data to initialize the user object for this flow.
 * @param flowId The flow ID of the interrupt that is being rendered.
 * @returns The IDP-agnostic global state object created from the server data
 */
export function createGlobalState(
  serverData: ServerData,
  userOptions?: UserOptions,
  flowId?: FlowId,
): GlobalState {
  const globalState = {
    ...initialGlobalState,
    debugInfo: { ...defaultDebugInfo },
  };

  if (serverData?.sErrorCode) {
    globalState.debugInfo.errorCode = serverData.sErrorCode;
  }

  if (serverData?.strServiceExceptionMessage) {
    globalState.debugInfo.errorMessage = serverData.strServiceExceptionMessage;
  }

  if (serverData?.fIsDebugTracingEnabled) {
    globalState.debugInfo.debugModeActive = true;
  }

  if (serverData?.strTimestamp) {
    globalState.debugInfo.timeStamp = serverData.strTimestamp;
  }

  if (serverData?.fAllowGrayOutLightBox) {
    globalState.allowGrayOutLightbox = serverData.fAllowGrayOutLightBox;
  }

  if (serverData?.fShowPageLevelTitleAndDesc) {
    globalState.showPageTitle = serverData.fShowPageLevelTitleAndDesc;
  }

  if (userOptions) {
    globalState.user = getUpdatedUser(initialGlobalState.user, userOptions);
  }

  globalState.activeFlow = flowId || FlowId.None;

  return globalState;
}
